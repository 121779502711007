<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">车场收入月度统计</div>
    </div>

    <div class="charts">
      <ja-init-chart class="jachart" :options="options" />
      <div class="chartimg">
         <div class="chartData">
          <p>{{total}}</p>
          <p>总收入</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from '/node_modules/echarts'
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  props: {
    alarmList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    JaInitChart
  },
  data() {
    return {
      alarmList: [],
      xAxisData: [
        {
          name: '小时结费用',
          value: 0
        },
        {
          name: '美容充电',
          value: 0
        },
        {
          name: '日结费用',
          value: 0
        },
        {
          name: '月结费用',
          value: 0
        }
      ]
    }
  },
  created() {
    this.init()
  },
  computed:{
    total(){
      let total = 0
      this.xAxisData.forEach(item => {
        total += item.value
      })
      return total
    }
  },
  methods: {
    async init() {
      this.loading = false
      this.options = {
        color: ['#DDBF10', '#09C889', '#02B1FF','#53F4D6'],
        series: {
          type: 'pie',
          radius: ['50%', '60%'],
          top: '0%',
          left: '0%',
          hoverOffset: 0,
          label: {
            show: true,
            position: 'outside',
            formatter: function (data) {
              return (
                '{name|' +
                data.name +
                ':' +
                '}' +
                ' \n'+
                '{value|'+ data.value +'}'+'{bfb|'+'元 '+ data.percent.toFixed(0) + '%}'
              )
            },
            rich: {
              name: {
                fontSize: this.ChartSize(15),
                color: '#46D4FF'
              },
              value: {
                fontSize: this.ChartSize(20),
                color: '#ffffff'
              },
              bfb:{
                fontSize: this.ChartSize(14),
                color: '#ffffff'
              }
            }
          },
          labelLine: {
            show: false,
            normal: {
              length: 30,
              lineStyle: {
                width: 2
              }
            }
          },
          data: this.xAxisData,
          seriesIndex: 0
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  .charts {
    margin-top: 40px;
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    margin-top: 20px;
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 10px;
    height: 100%;
    position: relative;
    top: -5px;
    display: flex;
    .chartimg {
      z-index: 1;
      position: absolute;
      left: 145px;
      top: 25px;
      width: 150px;
      height: 150px;
      background-image: url('~@/assets/gd/chartbg5.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .chartData{
        z-index: 999;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100px;
        text-align: center;
        p:first-child{
          font-size: 20px;
          font-weight: 700;
          color: #FFFFFF;
          text-shadow: 0px 0px 14px rgba(18,253,240,0.5);
        }
        p:last-child{
          margin-top: 5px;
          font-size: 13px;
          color: #46D4FF;
        }
      }
    }
    .jachart {
      z-index: 2;
      width: 440px;
      height: 200px;
    }
  }
}
</style>