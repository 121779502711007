<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">室内温度趋势</div>
    </div>

    <div class="charts">
      <ja-init-chart ref="chart" class="jachart" :options="options" />
    </div>
  </div>
</template>

<script>
import echarts from '/node_modules/echarts';
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  props:{
    temperature:{
      type:Object,
      default:()=>{}
    }
  },
  components: {
    JaInitChart
  },
  data() {
    return {
      options: {},
      loading: true,
      title: '室内温度趋势',
      dataInterval:null
    }
  },
  created() {
    this.startDataInterval()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 
  },
  methods: {
    startDataInterval(){
      this.init()
      this.dataInterval = setInterval(() => {
            this.init()
          
      },5000);
    },
    handleResize() {
      this.$refs.chart.myChart.resize()
    },
    async init() {
      this.loading = false
      
      try {
        this.$refs.chart.myChart.clear();
          this.$refs.chart.drawInit()
      }catch(err) {

      }
     
      this.options = {
        grid: {
          left: '1%',
          right: '5%',
          bottom: '1%',
          height: '80%',
          containLabel: true
        },
        tooltip: {
          type: 'item',
          trigger: 'axis',
          position: function (point, params, dom, rect, size) {
            return [point[0]-30, point[1]-100] //返回x、y（横向、纵向）两个点的位置
          },
          backgroundColor: 'transparent',
          formatter: function (params) {
              let str = `<div style = "padding-top:5px;font-size: 12px;background-image:url(${require('../../../../../../assets/images/gd/bubble@2x.png')});background-size: 100% 100%;width: 100px;height: 80px;">
              <div style = "width: 90%;margin: 0 auto;">${params[0].name}<br/> 室外温度${params[0].data}℃<br/>室内温度${params[1].data}℃</div>
              </div>`
              return str
          },
          axisPointer: {
             lineStyle: {
                color: 'transparent',
            },
          },
        },
        legend: {
          textStyle: {
            color: '#fff', // 图例文字颜色
            fontSize: 14 // 图例文字大小
          },
          left: 'right', // 图例水平位置，可选值：'left'、'center'、'right'
          top: 'top',
          data: ['室外', '室内']
          // selectedMode: 'single'
        },
        graphic: {
          elements: [
            {
              type: 'text',
              left: '5',
              top: '10',
              style: {
                text: '单位°C',
                fontSize: 12,
                fill: '#fff'
              }
            }
          ]
        },
        xAxis: {
          type: 'category',
          data: this.temperature.timer,
          axisLine: {
            lineStyle: {
              color: '#1A3E47',
              width: 1
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 12
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
            inside: true,
            lineStyle: {
              // 设置刻度线样式
              color: '#fff', // 刻度线颜色
              width: 2 // 刻度线宽度
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisLabel: {
            // interval: 8,
            color: '#fff',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: '#1A3E47'
            }
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '室外',
            data: this.temperature.outdoor,
            animationDuration: 1500,
            type: 'line',
            smooth: true,
            symbol: 'circle',
            label: {
              normal: {
                show: false,
                color: '#ffffff',
                textStyle: {
                  fontSize: 14
                },
                formatter: function(data) {
                        return data.value + '°C'
                },
              }
            },
            emphasis: {
              focus: 'series', // 选中状态
              itemStyle: {
                color: '#04DA3A' // 选中时的颜色
              },
              label: {
                show:true,
                color: '#fff'
              }
            },
            itemStyle: {
              normal: {
                color: '#0CE3B3',
                borderColor: '#fff',
                borderType: 'solid',
                lineStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#0CE3B3' },
                    { offset: 1, color: '#00FF7F' }
                  ])
                }
              }
            }
          },
          {
            name: '室内',
            data: this.temperature.indoor,
            animationDuration: 1500,
            type: 'line',
            smooth: true,
            symbol: 'circle',
            label: {
              normal: {
                show: false,
                color: '#ffffff',
                textStyle: {
                  fontSize: 14
                },
                formatter: function(data) {
                        return data.value + '°C'
                },
              }
              
            },
            emphasis: {
              focus: 'series', // 选中状态
              itemStyle: {
                color: '#00FFF5' // 选中时的颜色
              },
              label: {
                show:true,
                color: '#fff'
              }
            },
            itemStyle: {
              normal: {
                color: '#0AD0FD',
                borderColor: '#fff',
                borderType: 'solid',
                lineStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#0AD0FD' },
                    { offset: 1, color: '#00A5FF' }
                  ])
                }
              }
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }
}
.jachart {
  z-index: 0;
}
.charts {
  width: 100%;
  height: 80%;
  display: inline-block;
}
// .entry_left {
.personStat_title {
  display: flex;
  color: #2eb5ee;
  font-size: 16px;
  padding-top: 10px;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  li {
    span {
      display: inline-block;
      width: 30px;
      background: #19236c;
      text-align: center;
      margin-right: 8px;
      height: 30px;
      border-radius: 4px;
      line-height: 28px;
      font-size: 14px;
      cursor: pointer;
    }
    .curr {
      background: #226ca3;
      color: #f8fdff;
    }
  }
}
// }
</style>