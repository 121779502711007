<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">视频巡更组总览</div>
    </div>
    <div class="mid"  ref="midContainer" @mouseover="onMouseOver" @mouseout="onMouseOut">
       <div class="videobox-container" :style="containerStyle">
        <div :class="['videobox',item.stateErr?'redFont':'']" v-for="item in TeamList" :key="item.id" @click="openDialog(item)">{{item.groupName}}</div>
       </div>
    </div>

    <Dialog
      ref="dialog"
      :title="diaTitle"
    >
    </Dialog>
  </div>
</template>

<script>
import Dialog from './videoDialog.vue'
import { patrolTeamList } from '@/api'
export default {
  components:{
    Dialog
  },
  data() {
    return {
      TeamList:[],
      itemHeight: 65, // 每个videobox的高度
      containerHeight: 0, // 容器的高度
      currentIndex: 0, // 当前显示的项目索引
      transitionDuration: "2s", // 过渡持续时间
      diaTitle:'',
      setTimer:null,
      dataInterval:null
    }
  },
  computed: {
    containerStyle() {
      return {
        transform: `translateY(-${this.currentIndex * this.itemHeight}px)`,
        transition: `transform ${this.transitionDuration}`,
      };
    },
    isOverflow() {
      return this.TeamList.length / 3 * this.itemHeight > this.containerHeight;
    },
    visibleItems() {
      return this.isOverflow
        ? this.TeamList
        : this.TeamList.slice(this.currentIndex);
    },
  },
  async mounted() {
    await this.startDataInterval()
    this.containerHeight = this.$refs.midContainer.clientHeight;
    this.startAutoScroll(); // 启动自动滚动
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
    // 在组件销毁前清除定时器
    clearInterval(this.setTimer);
  },
  methods: {
    startDataInterval(){
      this.init()
      this.dataInterval = setInterval(() => {
            this.init()
      }, this.TIMER_INTERVAL);
    },
    async init(){
      let {data:res} = await patrolTeamList()
      this.TeamList = res
      this.TeamList.forEach(item => {
        if(item.deviceOrderList.length > 0){
          item.deviceOrderList.forEach(state =>{
            if(state.runStatus !== '在线'){
              item.stateErr = true
              return
            }
          })
        }else{
          item.stateErr = true
        }
      })
      console.log( this.TeamList);
    },
    openDialog(item){
      if(item.stateErr){
        this.$message.error('设备异常')
        return
      }
      this.diaTitle = item.groupName
      this.$refs.dialog.showDialog(item.deviceOrderList);
    },
    startAutoScroll() {
      if (this.isOverflow) {
        this.setTimer = setInterval(() => {
          this.currentIndex++;
          if (this.currentIndex >= this.TeamList.length / 3) {
            this.currentIndex = 0;
          }
        }, 2000); // 1秒钟切换一次
      }
    },
    onMouseOver(){
      clearInterval(this.setTimer);
    },
    onMouseOut(){
      this.startAutoScroll()
    }
  }
}
</script>

<style lang="scss" scoped>
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }
  .mid{
    height: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative; /* 添加相对定位 */
    .videobox{
      float: left;
      margin: 5px;
      font-size: 11px;
      background-image: url('~@/assets/gd/dsvideo_btn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0 20px;
      width: 31%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .videobox:hover{
      cursor: pointer
    }
    .redFont{
      color: red!important;
    }

    .videobox-container {
      width: 100%;
      overflow: hidden; /* 隐藏溢出部分 */
      position: absolute; /* 添加绝对定位 */
      top: 0; /* 位于.mid的顶部 */
    }
  }
}
</style>