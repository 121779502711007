<template>
  <!-- 角色管理 -->
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :valueFormat="formatter"
      :editDataFormat="editDataFormat"
      @handleSelectionChange="handleSelectionChange"
      @rowEdit="rowEdit"
      @rowDataScope="rowDataScope"
      @rowDelete="rowDelete"
      @append="append"
      @reset="reset"
      @getInit="getInit"
      @swtichState="swtichState"
      @bindUser="bindUser"
      @search="search"
    >
      <template #img="{row}">
        <img
          :src="row.peoplePic"
          alt=""
        >
      </template>
    </ja-table>

    <!--新增/编辑 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="40%"
      :title="isAdd?'新增':'编辑'"
    >
      <ja-form
        :rules="rules"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
      </ja-form>
    </el-dialog>
    <!-- 分配角色数据权限对话框 -->
    <el-dialog v-dialogDrag
      :close-on-click-modal="false"
      title="权限"
      :visible.sync="openDataScope"
      width="500px"
      append-to-body
    >
      <div
        class="box"
        v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading"
      >
        <el-form
          :model="rowForm"
          label-width="80px"
        >
          <el-form-item label="授权平台">
            <el-select v-model="rowForm.dataScope">
              <el-option
                v-for="item in dataScopeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-button
              type="primary"
              @click="selectScope()"
              size="small"
              style="margin-left:6px"
            >查询</el-button>
          </el-form-item>
          <el-form-item label="">
            <div class='treeContent'>
              <el-tree
                :data="deptOptions"
                show-checkbox
                :default-expanded-keys="checkedKeys"
                ref="dept"
                node-key="id"
                empty-text="暂无数据"
              ></el-tree>
            </div>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button
            type="primary"
            size="small"
            @click="submitDataScope"
          >确 定</el-button>
          <el-button
            size="small"
            @click="openDataScope=false"
          >取 消</el-button>
        </div>
      </div>
    </el-dialog>
    
    <added-user :show.sync="show" ref="assignAdmin" v-if="show" :roleId="roleId" @selectUser="selectUser" @closeRole="closeRole"></added-user>
    <select-user :roleId="roleId" :show.sync="showUser" v-if="showUser" @closeUser="closeUser"></select-user>
  </div>
</template>

<script>
import {
  getRole,
  roleAdd,
  roleEdit,
  changeRoleStatus,
  delRole,
  rqRoleTreeList,
  modifyRoleTree
} from '@/api'
import {
  roleManagerQuery,
  roleManagerColumn,
  roleManagerFormData,
  roleManagerRules
} from '../systemSettingColumn'
import table from '@/views/mixins/table'
import addedUser from './components/addedUser.vue'
import selectUser from './components/selectUser.vue'
export default {
  title: '角色管理',
  name: 'index',
  mixins: [table],
  components:{
    addedUser,
    selectUser
  },
  data() {
    return {
      show: false,
      showUser:false,
      rules: roleManagerRules,
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      // 主页表格
      conditionData: roleManagerQuery,
      columns: roleManagerColumn,
      tableData: [],
      // 新增/编辑
      rowFormData: roleManagerFormData,
      checkedKeys: [],
      loading: false,
      total: 30,
      showDetail: false,
      labelWidth: `${this.util.setFontSize(120)}px`,
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      isAdd: true, // 新增/编辑
      openDataScope: false, // 是否显示弹出层（数据权限）
      // 部门列表
      deptOptions: [],
      // 数据范围选项
      dataScopeOptions: [
        {
          value: 1,
          label: 'Web管理端'
        },
        {
          value: 2,
          label: '小程序'
        },
        {
          value: 3,
          label: '大屏'
        }
      ],
      roleId:''
    }
  },
  created() {},
  methods: {
    handleSelectionChange(val) {
      console.log('val', val)
    },
    editDataFormat(row, btn) {
      if (row.isDefault === '1' && btn.text === '移除') {
        return false
      } else {
        return true
      }
    },
    formatter(val, key) {
      if (key === 'status') {
        return ['启用', '禁用'][val || 0]
      }
      return val || '-'
    },
    getInit() {
      this.loading = true
      // 获取角色
      getRole({
        pageIndex: this.filterData.pageIndex,
        pageSize: this.filterData.pageSize,
        name: this.filterData.name,
        status: this.filterData.status
      })
        .then((res) => {
          this.loading = false
          this.tableData = res.data.data
          this.total = res.data.total
          // console.log('获取角色>>', res)
        })
        .catch((error) => {
          console.log(error)
          // this.$message.error(error)
        })
    },
    // 新增
    append() {
      this.isAdd = true
      this.rowForm = {}
      this.showEditAll = true
    },
    selectScope() {
      // console.log('rowForm==', this.rowForm)
      this.rowDataScope(this.rowForm)
    },
    // 权限设置
    rowDataScope(row) {
      // console.log('权限设置= rowDataScope==》', row)
      if (!row.dataScope) {
        row.dataScope = 1
        this.$set(this.rowForm, 'dataScope', row.dataScope)
      }
      row.roleId = row.id
      this.openDataScope = true
      this.deptOptions = []
      this.rowForm.roleId = row.id
      this.rowForm = JSON.parse(JSON.stringify(row))
      this.loading_form.isShow = true
      rqRoleTreeList({
        roleId: row.roleId,
        sourceType: [this.rowForm.dataScope]
      })
        .then((response) => {
          // console.log('权限设置的 list===》', response)
          this.loading_form.isShow = false
          if (this.rowForm.dataScope == 2) {
            this.deptOptions = [
              {
                id: '',
                label: '访客管理',
                children: [
                  { id: 1, label: '访客审批' },
                ]
              },
              {
                id: '',
                label: '工单管理',
                children: [
                  { id: 2, label: '我的工单' },
                  { id: 3, label: '物业报修' }
                ]
              },
              {
                id: '',
                label: '能源管理',
                children: [
                  { id: 4, label: '能源查询' },
                ]
              },
            ]
          } else {
            this.deptOptions = response.data.menus
          }
          this.checkedKeys = response.data.checkedKeys
          this.$refs.dept.setCheckedKeys(response.data.checkedKeys)
        })
        .catch((err) => {
          this.$message.error(err)
          this.loading_form.isShow = false
        })
      // console.log('权限设置', row)
    },
    // 所有部门节点数据
    getDeptAllCheckedKeys() {
      // 目前被选中的部门节点
      const checkedKeys = this.$refs.dept.getCheckedKeys().filter(item => item !== '')
      return checkedKeys
    },
    /** 提交按钮（数据权限） */
    submitDataScope: function () {
      // if (this.form.roleId != undefined) {
      this.rowForm.deptIds = this.getDeptAllCheckedKeys()
      this.loading_form.isShow = true
      // return
      modifyRoleTree({
        // parameter:{
        roleId: this.rowForm.id,
        menuList: this.rowForm.deptIds,
        sourceType: [this.rowForm.dataScope]
        // }
      })
        .then((res) => {
          this.loading_form.isShow = false
          this.$message.success('修改成功')
          this.openDataScope = false
          this.getInit()
        })
        .catch((err) => {
          this.$message.error(err)
          this.loading_form.isShow = false
        })
    },
    // 编辑
    rowEdit(row) {
      this.isAdd = false
      // console.log('编辑')
      this.$nextTick(() => {
        this.rowFormData.forEach((item) => {
          this.$set(this.rowForm, item.key, row[item.key])
        })
        this.rowForm.id = row.id
      })
      this.showEditAll = true
      // console.log('编辑', row)
    },
    // 删除
    rowDelete(row) {
      this.$confirm('确认删除该角色吗，删除之后相关账户将会失去角色所涉及页面的相关权限！')
        .then((res) => {
          // console.log('删除>>', row)
          delRole(row.id).then((res) => {
            this.getInit()
            this.$message.success('已删除')
          })
        })
        .catch(() => {
          this.$message.info('已取消')
        })
    },
    // 保存
    saveForm() {
      // console.log('====>', this.rowForm)
      this.loading_form.isShow = true
      if (this.isAdd) {
        roleAdd(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success(res.msg)
            this.getInit()
            this.showEditAll = false
          })
          .catch((error) => {
            this.loading_form.isShow = false
            // this.$message.error(error)
          })
      } else {
        roleEdit(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success(res.msg)
            this.getInit()
            this.showEditAll = false
          })
          .catch((error) => {
            this.loading_form.isShow = false
            // this.$message.error(error)
          })
      }
    },
    // 角色状态修改
    swtichState(row) {
      // console.log(row)
      const status = row.status === false ? '1' : '0'
      // console.log(row.roleId)
      changeRoleStatus({
        roleId: row.roleId,
        status: status
      })
        .then((res) => {
          this.$message.success(res.msg)
        })
        .catch((error) => {
          this.$message.error(error)
        })
    },
    //绑定用户
    bindUser(val){
      console.log("绑定用户",val)
      this.roleId=val.id
      this.show=true
    },
    // 添加用户
    selectUser(){
      this.showUser=true
    },
    // 关闭已选择用户列表
    closeRole(show){
      this.show = show
    },
    // 关闭用户列表
    closeUser(show,key){
      if(key){
        this.$refs.assignAdmin.getInit()
      }
      this.showUser=show
    }
  },
  watch: {
    showEditAll(val) {
      if (!val) {
        this.$set(this, 'rowForm', {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.treeContent {
  max-height: 500px;
  overflow: auto;
}
.dialog-footer {
  // background-color: #1c1c34 !important;
  // padding: 0.521vw 1.042vw 1.042vw;
  display: flex;
  justify-content: flex-end;
}

.cell {
  img {
    height: 70px;
  }
}
</style>
