<template>
    <!--  结单 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="(isAdd?'增加':'编辑')+titleLast+'类型'">
        <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" @radioEvent="radioEvent" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
    </el-dialog>
</template>
<script>
import defferObj from '../defferPage'
import { addWorrkConfig, editWorrkConfig, getWorkConfigReciver } from '@/api'
export default {
    inject: ['labelWidth', 'getInit',],
    props: {
        activePage: String,
    },
    data () {
        return {
            defferObj,
            rowFormData: [],
            rowForm: {},
            rules: {},
            loadingForm: false,
            showEditAll: false, // 显示新增/编辑弹窗,
            isAdd: false,
            titleLast: '',
            rowFormData_indexObj: {},
            employees: [],
        }
    },
    // watch: {
    //     'rowFormData.sendOrderType': {
    //         handler (newVal, oldVal) {
    //             console.log(this.rowFormData);
    //         },
    //         deep: true,
    //         immediate: true
    //     },

    // },
    methods: {
        // 判断自动手动
        radioEvent(){
            let sendOrderType= this.rowForm.sendOrderType
            if(sendOrderType === 0){
                this.rules.takeOrderTimeLimit = []
                this.rowForm.takeOrderTimeLimit = ''
                this.rowFormData.map(e=>{
                    if(e.key == 'takeOrderTimeLimit') e.disabled = true
                    if(e.key == 'employeeIdList') e.hide = true
                })
            }else{
                this.rules.takeOrderTimeLimit = [{ required: true, message: '请设置时长', trigger: 'change' }]
                this.rowFormData.map(e=>{
                    if(e.key == 'takeOrderTimeLimit') e.disabled = false
                    if(e.key == 'employeeIdList') e.hide = false
                })
            }

            // if(this.rowForm.classify===0 || this.rowForm.classify==='0'){
            //     this.rowFormData.map(e=>{
            //         if(e.key == 'takeOrderTimeLimit') e.disabled = false
            //     })
            // }
        },
        dealDiffer () {
            let type = this.$parent.activePage
            this.rowFormData = [...this.defferObj[type].rowFormData],//表单数据
            this.rules = { ...this.defferObj[type].rules }
            this.rowFormData[0].options = [...this.$parent.conditionData[0].options]  //派单类型
            this.titleLast = this.defferObj[type].typeText //维修，维保，告警
            this.rowFormData_indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })//表单选项的key为对应这条数据的索引
        },
        // 编辑
        async rowEdit (row,) {
            // console.log(this.rowForm.sendOrderType);
            // console.log(row);
            this.rowForm = {...row}
            console.log(this.rowForm.sendOrderType);
             this.dealDiffer()
            this.radioEvent()
           
            this.isAdd = false
            this.showEditAll = true
            await this.resetEmployee(row.deptIds)
            // this.rowForm = {...row}
            // console.log(this.rowForm.sendOrderType);
        },

        // 新增
        async append () {
            
            if (this.$parent.deptIds.length == 0) {
                this.$message.warning('请先完善 “派单范围设置”')
                return
            }
            this.dealDiffer()
            this.isAdd = true
            this.showEditAll = true
            this.rowForm = {}
            this.radioEvent()
            await this.resetEmployee()
            this.$nextTick(() => {
                this.$refs.jaForm.$refs.formbox.clearValidate()
            })
        },
        // 更新 新增弹窗 的 接单人下拉列表
        resetEmployee (list) {
            return new Promise((resolve, reject) => {
                this.loadingForm = true
                let arr = this.$parent.deptIds
                getWorkConfigReciver([...arr]).then(res => {
                    this.loadingForm = false
                    this.rowFormData[this.rowFormData_indexObj.employeeIdList].options = (res.data || []).map(item => {
                        return {
                            label: `${item.deptName}_${item.username}`,
                            value: item.id
                        }
                    })
                    // 保存接单人信息
                    this.employees = res.data || []

                    resolve()
                }).catch(err => {
                    this.loadingForm = false
                    reject()
                })
            })
        },
        saveForm () {
            let obj = { ...this.rowForm }
            obj.employeeId = this.rowForm.employeeIdList.join(',')
           
            // 筛选出接单人的部门id，并去重。
            let selectDeptIds = this.employees.filter(item => {
                return obj.employeeIdList.includes(item.id)
            }).map(item => {
                return item.deptId
            }).filter((item, index, arr) => {
                return arr.indexOf(item) == index
            })
            obj.deptId = selectDeptIds.join(',')

            if (this.isAdd) {
                obj.classify = this.$parent.activePageValue
            }
            this.loadingForm = true
            delete obj.employeeIdList
            obj.workOrderTypeId = Number(obj.workOrderTypeId)
            console.log(obj);
            const rqMethod = this.isAdd ? addWorrkConfig : editWorrkConfig
            rqMethod(obj).then(res => {
                this.loadingForm = false
                this.$message.success('提交成功')
                this.showEditAll = false
                this.getInit()
            }).catch(error => {
                this.loadingForm = false
            })
        },
    }
}
</script>