<template>
  <div class="entry bg" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="mid">
      <div class="m_left">
        <indoor :indoorData="middleData.envSensors"></indoor>
      </div>
      <div class="m_right" @click="openDialog()">
        <admissionNum :realHuman="middleData.realHuman"></admissionNum>
        <powerConsumption :yesterdayEnergy="middleData.yesterdayEnergy"></powerConsumption>
        <carNum :realCar="middleData.realCar"></carNum>
      </div>
    </div>
    <div class="left">
      <left></left>
    </div>
    <div class="leftgif"></div>

    <div class="right">
      <right></right>
    </div>
    <div class="rightgif"></div>

    <div class="systembtn">
      <systemButton title="首页" />
    </div>
    <div class="bottom"></div>

    <chart ref="dialog" :middlePopUpsData="middlePopUpsData" v-if="middlePopUpsData.weatherVO" title="实时数据信息">
    </chart>
  </div>
</template>

<script>
import chart from './chart/index.vue'
import headers from './header/index.vue'
import left from './left/index.vue'
import right from './right/index.vue'
import admissionNum from './mid/admissionNum/index.vue'
import carNum from './mid/carNum/index.vue'
import indoor from './mid/indoor/index.vue'
import powerConsumption from './mid/powerConsumption/index.vue'
import systemButton from '../../home/visualManagement/wisdom/components/systemButton'
import { getRealTime, getPopUpRealTime } from '@/api'
import gsap from 'gsap'

export default {
  title: '德舜大厦可视化管理平台',
  name: 'officeBuilding',
  components: {
    headers,
    left,
    right,
    admissionNum,
    carNum,
    indoor,
    powerConsumption,
    chart,
    systemButton
  },
  data() {
    return {
      left: 'entryLeft',
      right: 'entryRight',
      middle: 'UpperMiddle',
      middleData: {}, // 中间数据
      middlePopUpsData: {}, // 中间弹窗数据
      dataInterval: null
    }
  },
  created() {
    this.startDataInterval()
  },
  methods: {
    openDialog() {
      this.$refs.dialog.showDialog();
    },
    // 获取环境测量、实时人数、昨日能耗、实时车辆
    getRealTime() {
      getRealTime().then(res => {
        this.middleData = res.data
        let oldrealHuman =  this.middleData.realHuman
        this.middleData.realHuman = this.middleData.realHuman        
        this.middleData.envSensors.co2 = this.middleData.envSensors.co2       
        this.middleData.envSensors.envHumidity = this.middleData.envSensors.envHumidity        
        this.middleData.envSensors.envTemperature = this.middleData.envSensors.envTemperature        
        this.middleData.envSensors.fineParticulateMatter = this.middleData.envSensors.fineParticulateMatter        
        this.middleData.envSensors.pm10 = this.middleData.envSensors.pm10        
        this.middleData.envSensors.tcov = this.middleData.envSensors.tcov
        gsap.to(this.middleData, {
          realHuman: oldrealHuman,
          duration:  (oldrealHuman * 3),//设置完成的时间
          roundProps: { realHuman: 1 },
        })
      })
    },
    // 中间弹窗园区人数走势、园区车辆走势、能耗对比
    getPopUpRealTime() {
      getPopUpRealTime().then(res => {
        this.middlePopUpsData = res.data
      })
    },
    startDataInterval() {
      this.getRealTime()
      this.getPopUpRealTime()
      this.dataInterval = setInterval(() => {
        this.getRealTime()
        this.getPopUpRealTime()
      }, 300000);
    }
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
  },
  mounted() {
    this.$emit('loading')
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';

.bg {
  height: 100%;
  position: relative;
  overflow: hidden;

  .scene {
    width: 100%;
    height: 100%;
    transform: scale(1);
  }
}

.entry {
  width: 100%;
  position: relative;

  // height: 5vw;
  // 导航栏
  .top {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.png');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .left {
    position: absolute;
    background: url('~@/assets/gd/left.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    left: 10px;
    top: 70px;
  }

  .leftgif {
    position: absolute;
    background: url('~@/assets/gd/left.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    left: 10px;
    top: 70px;
    pointer-events: none;
  }

  .right {
    position: absolute;
    background: url('~@/assets/gd/right.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    right: 10px;
    top: 70px;
  }

  .rightgif {
    position: absolute;
    background: url('~@/assets/gd/right.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    right: 10px;
    top: 70px;
    pointer-events: none;
  }

  .mid {
    width: 50;
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;

    .m_left {
      >div {
        width: 366px;
        height: 134px;
      }
    }

    .m_right {
      display: flex;

      >div {
        margin-left: 20px;
        width: 176px;
        height: 100px;
      }
    }
  }

  .bottom {
    height: 38px;
    width: 66.5%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }

  .systembtn {
    z-index: 0;
    width: 36%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 85px;
  }
}</style>
