import { render, staticRenderFns } from "./waterPolo.vue?vue&type=template&id=97bc661e&scoped=true&"
import script from "./waterPolo.vue?vue&type=script&lang=js&"
export * from "./waterPolo.vue?vue&type=script&lang=js&"
import style0 from "./waterPolo.vue?vue&type=style&index=0&id=97bc661e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97bc661e",
  null
  
)

export default component.exports