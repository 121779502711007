<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @passOrReject="passOrReject"
                  @lookRoom="lookRoom">
        </ja-table>
        <pass-or-reject ref="rejectRefs"></pass-or-reject>
        <show-details ref="detailRefs"></show-details>
    </div>
</template>

<script>
import { getDecApplyInfoList } from '@/api'
import table from '@/views/mixins/table'
import PassOrReject from './components/passOrReject.vue'
import showDetails from './components/showDetails.vue'
export default {
    title: '装修申请审批',
    mixins: [table],
    name: 'applicationForApproval',
    components: {
        PassOrReject,
        showDetails,
    },
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'shipper', attribute: { placeholder: '报装人', size: 'small' } },
                { input_type: 'select', key: 'applyStatus', attribute: { placeholder: '审批状态', size: 'small' }, options: [] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
            ],
            columns: [
                // 表头数据
                { label: '报装人', prop: 'shipper' },
                { label: '报装人手机号码', prop: 'shipperPhone', width: '150' },
                { label: '所属楼栋', prop: 'buildingName' },
                { label: '所属楼层', prop: 'floorName' },
                { label: '所属租赁单元', prop: 'roomName', width: '150' },
                { label: '装修开始时间', prop: 'startTime', width: '190' },
                { label: '装修结束时间', prop: 'endTime', width: '190' },
                { label: '施工单位', prop: 'companyName', width: '150' },
                { label: '施工负责人', prop: 'decPerson' },
                { label: '负责人电话', prop: 'decPhone', width: '150' },
                { label: '装修人数', prop: 'decPersonNumber' },
                { label: '装修人员信息', prop: 'decPersonList', width: '200' },
                { label: '提交时间', prop: 'updateTime', width: '150' },
                { label: '审批状态', prop: 'applyStatusName' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'renovation::applicationForApproval::show' },
                        { text: '通过/驳回', method: 'passOrReject', limit: 'renovation::applicationForApproval::passOrReject' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,

        }
    },
    mounted() {
        let length = this.conditionData.findIndex((e) => e.key === 'applyStatus')
        this.util.rqOptionsList({
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'apply_status' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        }).then(res => {
            let index = res.findIndex(item => item.label == '已验收')
            res.splice(index, 1)
            this.conditionData[length].options = res
        })
    },
    methods: {
        getInit() {
            getDecApplyInfoList(this.filterData)
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
        },
        passOrReject(row) {
            this.$refs.rejectRefs.init(row)
        },
        lookRoom(row) {
            this.$refs.detailRefs.init(row)
        },
        editDataFormat(row, btn) {
            if (row.applyStatus == 0 && btn.text == '通过/驳回' || row.applyStatus != 0 && btn.text == '查看') {
                return true
            } else {
                return false
            }
        },
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
</style>

