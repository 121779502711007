<template>
  <div class="entry_left entry">
    <Slot/>
    <rental-vacancy-rate class="flow"></rental-vacancy-rate>
    <Slot/>
    <energy-consumption class="flow"></energy-consumption>
    <Slot/>
    <equipment-statistics class="flow"></equipment-statistics>
  </div>
</template>

<script>
import Slot from "@/components/Slot"
import rentalVacancyRate from './rentalVacancyRate/index.vue'
import EnergyConsumption from './energyConsumption/index.vue'
import EquipmentStatistics from './equipmentStatistics/index.vue'
export default {
  data () {
    return {
      parkName: '',
      parkArr: []
    }
  },
  created () {
  },
  mounted(){
    this.$emit('loading')
  },
  methods: {
  },
  watch: {
    '$store.state.parkObjClick': function () {
      const parkObj = this.$store.state.parkObjClick
      this.parkName = parkObj.parkName
    },
    '$store.state.token': function () {
      // this.$message.error({ message: '监听加载:全国入口左侧:' + this.$store.state.token, duration: 6000, showClose: true })
      this.getExcellentPark()
    }
  },
  components: {
    Slot,
    rentalVacancyRate,
    EnergyConsumption,
    EquipmentStatistics
  }
}
</script>
<style lang="scss" scoped>
.entry_left{
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}
.flow{
  height: 33%;
  width: 100%;
  flex: 1;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
// .flow:last-child{
//   height: 40%;
// }
</style>
