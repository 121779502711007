<template>
  <!-- 维修工单、维保工单、告警工单 下的弹窗汇总 -->
  <div>
    <!-- 指派 -->
    <assign-order ref="assignOrder"  v-on="$listeners" />

    <!-- 转单 -->
    <turn-order ref="turnOrder"  v-on="$listeners"/>

    <!-- 结单 -->
    <result-order ref="resOrder"  v-on="$listeners"/>
  </div>
</template>

<script>
import assignOrder from './childComp/assignOrder.vue'
import turnOrder from './childComp/turnOrder.vue'
import resultOrder from './childComp/resultOrder.vue'
export default {
  components: {
    assignOrder, turnOrder,resultOrder
  },
}
</script>

<style scoped>
</style>