<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columnsfix" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @exportFun="exportFun" 
    @rowAssignOrder="rowAssignOrder" @rowTurnOrder="rowTurnOrder" @rowResOrder="rowResOrder" @rowReset="rowReset" @rowDetail="normalEvent" @rowCheckOrder="rowCheckOrder" :editDataFormat="editDataFormatFix"></ja-table>

    <!-- 涉及到的弹窗 :派单、转单、结单 -->
    <window-box ref="windowBox" :pageActiveObj="pageTypeObj[this.pageType]"/>

    <detail ref="detail" :pageType="pageType"/>
  </div>
</template>
<script>

import table from '@/views/mixins/table'
import mixinIndex from '../common/mixinIndex'
import windowBox from './comp/windowBox.vue'
import Detail from './Detail.vue'


export default {
  title: '维修工单',
  mixins: [table, mixinIndex,],
  name: 'index',
  components: {
    windowBox, Detail
  },
  data() {
    return {
      pageType: 'repair',
    }
  },
  methods: {
  
  }
}
</script>