<template>
<div class="allwh">
  <div class="personWrapper" >
    <div class="left upload-demo" >
      <div class="img-wrap" v-loading="loadingImg" >
        <el-image :src="roles && roles.headPortrait?roles.headPortrait:'/images/home/faceImg.png'" fit="cover" @click="previewImg"></el-image>
      </div>
      <el-upload
        :action="$uploadUrl+'/oss/upload'"
        accept=".png,.jpg,.jpeg"
        :headers="headers"
        :on-error="imgErrorMethod"
        :on-success="imgSuccessMethod"
        :before-upload="beforeUploadImg"
        :file-list="fileList"
        :show-file-list="false"
        >
        <el-button
          size="small"
          type="primary"
          :disabled="loadingImg"
        >上传</el-button>
        <template #tip>
          <div class="el-upload__tip">
            只能上传JPG、PNG文件且不超过2MB
          </div>
        </template>
      </el-upload>
    </div>
    <div class="userInfo">
      <div class="userBox" v-if="roles">
        <div class="personItem">个人资料</div>
        <div class="personItem1"><span>帐号：</span> {{roles.username}}</div>
        <div class="personItem1"><span>真实姓名：</span> {{roles.realName}}</div>
        <div class="personItem1"><span>所属单位：</span> {{roles.companyName}}</div>
        <div class="personItem1"><span>所属部门：</span> {{roles.deptName}}</div>
      </div>
      <el-button
        class="change-password" type="primary"
        @click="changePassword"
        v-if="isAuth('user::password::update')"
      >
        修改密码
      </el-button>

    <!-- 修改密码 -->
    <el-dialog 
      v-dialogDrag 
      :close-on-click-modal="false"
      title="修改密码 "
      v-model="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      width="30%"
      :modal='false'
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="PasswordForm"
      >
        <el-form-item
          label="原密码"
          :label-width="formLabelWidth"
          prop="oldPassword"
        >
          <el-input
            v-model="ruleForm.oldPassword"
            autocomplete="off"
            type="password"
            auto-complete="new-password"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
           prop="newPassword"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="ruleForm.newPassword"
            type="password"
            autocomplete="off"
            auto-complete="new-password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          :label-width="formLabelWidth"
          auto-complete="new-password"
           prop="checkPass"
        >
          <el-input
            v-model="ruleForm.checkPass"
            type="password"
            autocomplete="off"
            placeholder="请再次输入确认密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="submitPasswordForm" type="primary">保存</el-button>
          <el-button
            @click="dialogFormVisible = false"
          >取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  </div>
    <el-dialog v-dialogDrag :close-on-click-modal="false"  :visible.sync="dialogVisible" width="60%"  append-to-body>
      <img width="100%" :src="roles && roles.headPortrait?roles.headPortrait:'/images/home/faceImg.png'" alt="" v-if="dialogVisible">
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {resetPassword , userPersonUpload} from '@/api'
export default {
  title: '个人中心',
  data() {
     var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.PasswordForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.newPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      fileList: [],
      dialogFormVisible: false,
      ruleForm: {
        newPassword: '',
        oldPassword: '',
        checkPass: ''
      },
      rules: {
        newPassword: [{ validator: validatePass,required: true, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2,required: true, trigger: 'blur' }],
        oldPassword: [{ required: true, message: '请填写原密码', trigger: 'blur' }],
      },
      formLabelWidth: '80px',
      headers:{},
      loadingImg: false ,
      dialogVisible:false,
    }
  },
  computed: {
    ...mapGetters(['roles'])
  },
  created(){
    const token = this.$store.state.token
    this.headers = {
      Authorization : token.token_type + ' ' + token.access_token
    }
  },
  methods: {
    previewImg(){
      this.dialogVisible = true
    },
    changePassword() {
      // this.ruleForm={}
    if (this.$refs["PasswordForm"] !== undefined) {
      this.$nextTick(function(){
         this.$refs["PasswordForm"].resetFields()
      })
     }
      this.dialogFormVisible = true
    },
    //提交修改表单
    submitPasswordForm(){
      this.$refs["PasswordForm"].validate((valid) => {
        let _this=this
          if (valid) {
            resetPassword(this.ruleForm).then(res=>{
              if(res.code==200){
                this.$message({
                  message: '密码修改成功！',
                  type: 'success',
                  onClose:function () {
                     _this.dialogFormVisible = false;
                  }
                });
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
      });
    },
    // 图片上传之前限制
    beforeUploadImg (file) {
      
      let fileSize = 0
      let fileSizeText = '文件大小不能超过2MB'
      fileSize = !(file.size / 1024 / 1024 > 2)
      // fileSizeText = '图片大小不能超过10M8'
      if (!fileSize) {
        this.$message.warning(fileSizeText)
        return falses
      }
      this.loadingImg = true
    },
    // 图片上传失败
    imgErrorMethod () {
      this.$message.error('图片上传失败，请重试')
      this.loadingImg = false
      return false
    },
    // 图片上传成功
    imgSuccessMethod (response) {
      let img = response.data
      userPersonUpload({headPortrait: img }).then(res => {
        this.$store.commit("SET_ROLES", {...this.roles,headPortrait:img}); // payload 为参数
        this.$message.success('成功更换图片')
        this.loadingImg = false
      }).catch(err=>{
        this.loadingImg = false
        this.$message.warning('图片更新失败')
      })
    },
  }
}
</script>

<style scoped lang="scss">
.allwh{
  padding: 10px 15px;
  margin-bottom: 15px;
}

.personWrapper {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 30px 0;
  display: flex;
  .upload-demo {
    width: 20%;
    text-align: center;
    .img-wrap {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin:0 auto 10px;
      // width: 50%;
       /deep/ .el-image {
        cursor: pointer;
        width:100%;
        height: 100%;
        border-radius: 50%;
        >.el-image__inner{
          width:100%;
          height: 100%;
          border-radius: 50%;
          border: 5px solid #02a7f0;
        }
      }
    }

    .el-button {
      width: 100px;
    }

    .el-upload__tip {
      width: 70%;
      margin: 10px auto;
      line-height: 25px;
    }
  }
  .userInfo {
    width: 100%;
    padding: 0px 10px;
    .userBox {
      box-shadow: 1px 1px 2px #888888;
      font-size: 12px;
      line-height: 20px;
      color: #303133;
      width: 50%;
      span{
        color: #909399;
      }
      padding: 10px;
      .personItem {
        border-bottom: 1px solid #C0C4CC;
        margin-bottom: 20px;
        padding-bottom: 5px;
      }

      .personItem1 {
        margin-bottom: 8px;
      }
    }

    .change-password {
      margin-top: 20px;
      // border: 1px solid #0000ff;
      // height: 50px;
      // line-height: 50px;
      // padding-left: 40px;
      // cursor: pointer;
    }
  }
}
</style>
