<template>
  <!--  普通的表单 -->
  <div>
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :visible.sync="showEditAll"
      width="45%"
      :title="(isAdd?'新增':'编辑')+' '"
    >
      <ja-form
        :rules="rules"
        ref="jaForm"
        :label-width="labelWidth"
        v-model="rowForm"
        :formArray="rowFormData"
        :loadingForm="loadingForm"
        @tenantChange="tenantChange"
        @reset="showEditAll = false"
        @save="saveForm"
        v-if="showEditAll"
      >
      </ja-form>
    </el-dialog>
    <!-- 租户 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showTenant"
      width="60%"
    >
      <tenant @turnMeterReading="turnMeterReading"></tenant>
    </el-dialog>
  </div>
</template>
<script>
import { addTenantEmployee, editTenantEmployee } from '@/api'
import tenant from './tenant'

export default {
  inject: ['labelWidth', 'getInit'],
  components: {
    tenant
  },
  data() {
    return {
      showTenant:false,
      rowFormData: [
        { input_type: 'input', label: '租户', key: 'tenantName', col: 11, focusMethod:"tenantChange" },
        { input_type: 'input', label: '人员姓名', key: 'userName', col: 11 },
        {
          input_type: 'radio',
          label: '性别',
          key: 'sex',
          col: 11,
          options: [
            { label: '男', value: 1 },
            { label: '女', value: 0 },
            { label: '未知', value: 2 }
          ]
        },
        // { input_type: 'text2', label: '登录用户名', key: 'loginUserName', col: 11, },
        { input_type: 'input', label: '职位', key: 'position', col: 11 },
        { input_type: 'input', label: '手机号', key: 'mobile', col: 11 },
        {
          input_type: 'radio',
          label: '状态',
          key: 'status',
          col: 11,
          options: [
            { label: '在职', value: 0 },
            { label: '离职', value: 1 }
          ]
        },
        // {
        //   input_type: 'switch',
        //   labelEmpty: true,
        //   key: 'loginByPhone',
        //   col: 11,
        //   activeValue: 0,
        //   inactiveValue: 1,
        //   rightBox: { text: '开通手机号作为帐号' }
        // }
      ],
      rowForm: {},
      rules: {
        tenantName: [
          { required: true, message: '请选择租户', trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入人员姓名', trigger: 'blur' }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        mobile: [
          {
            required: true,
            trigger: 'blur',
            validator: this.util.formRules.checkPhone()
          }
        ],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {}
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData })
    console.log('111', this.$store.state)
  },

  methods: {
    append() {
      this.isAdd = true
      this.rowFormData[this.indexObj.tenantName].disabled=false;
      this.rowForm = {}
      this.showEditAll = true
    },
    rowEdit(row) {
      console.log('row---', row)
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      this.rowFormData[this.indexObj.tenantName].disabled=true;
      this.showEditAll = true
    },
    // 开始时间禁用
    disabledDateStart(val) {
      let now = new Date().getTime()
      let time = val.getTime()
      return time < now
      // let oneDay = 24 * 3600 * 1000
      // return time < now - oneDay
    },
    saveForm() {
      // console.log('要提交的表单', this.rowForm)
      this.loadingForm = true
      let rqMethod = this.isAdd ? addTenantEmployee : editTenantEmployee
      rqMethod(this.rowForm)
        .then((res) => {
          this.loadingForm = false
          this.$message.success('提交成功')
          this.showEditAll = false
          this.getInit()
        })
        .catch((error) => {
          this.loadingForm = false
        })
    },
    //点击租户弹框
    tenantChange(){
      this.showTenant=true
    },
    /**选择租户 */
    turnMeterReading(val){
      console.log("val-----------",val)
      this.showTenant=false
      this.$set(this.rowForm,'tenantName',val.tenantName)
      this.rowForm.tenantId=val.id
    }
  }
}
</script>