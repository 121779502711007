<template>
  <div>
    <JaTable v-model="filterData" :conditionData="conditionData" :columns="alarmThresholdColumn" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @rowDetail="normalEvent" @getInit="getInit" @search="search" @reset="reset"></JaTable>

    <detail ref="detail" />
  </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import table from '@/views/mixins/table'
import Detail from './childComp/Detail'
import { linkageRecord,linkCondition} from '@/api/module/alarm.js'

export default {
  title: '联动记录',
  mixins: [table],
  name: 'linkageRecord',
  components: {
    JaTable,
    Detail
  },
  data() {
    return {
      // 搜索
      conditionData: [
        {
          input_type: 'date',
          type:'datetimerange',
          attribute: {
            size: 'small'
          },
          startPlaceholder:"触发开始时间",
          endPlaceholder:"触发结束时间",
          value: 'time',
        },
        {
          input_type: 'select',
          attribute: {
            placeholder: '触发条件',
            size: 'small'
          },
          key: 'conditionId',
          options: []
        },
        {
          input_type: 'input',
          attribute: {
            placeholder: '触发设备',
            size: 'small'
          },
          key: 'deviceName'
        },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' }
      ],
      // 表格
      alarmThresholdColumn: [
        { label: '触发时间', prop: 'createTime' },
        { label: '联动类型', prop: 'linkageTypeName' },
        { label: '触发设备', prop: 'triggerDeviceName' },
        { label: '触发条件', prop: 'triggerConditionName' },
        { label: '判断规则', prop: 'ruleName',width:'70px' },
        { label: '触发值', prop: 'triggerValue',width:'75px' },
        { label: '联动设备', prop: 'linkageDeviceName' },
        { label: '动作', prop: 'linkageActionName' },
        { label: '动作值', prop: 'linkageValue',width:'75px'  },
        {
          label: '操作',
          input_type: 'button',
          width: 160,
          list: [
            {
              text: '详情',
              method: 'rowDetail',
            }
          ]
        }
      ],
      tableData: [],
      total: 0,
      // 搜索参数
      filterData: {
        pageIndex: 1,
        pageSize: 10
      }
    }
  },
  async created() {
    const {data:linkConditionResult} =  await linkCondition()
    const linkConditionOption = linkConditionResult.map(item=>{
      return {label:item.conditionName,value:item.id}
    })
    this.conditionData.forEach(item=>{
      if(item.key==='conditionId') item.options = linkConditionOption
    })
  },
  methods: {
    getInit() {
      this.loading = true
      linkageRecord(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    search() {
      if (this.filterData.time) {
        this.filterData.startTime = this.filterData.time[0]
        this.filterData.endTime = this.filterData.time[1]
      }
      this.getInit()
    },
    formatter(val, key) {
      if (key === 'status') {
        return ['启用', '停用'][val || 0]
      }
      return val || ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .cellImg {
  display: flex;
  margin: auto;
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
::v-deep .errorImg {
  width: 100%;
}
</style>
