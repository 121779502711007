<template>
  <div class="chartone">
    <div class="header">
      <div class="headerleft">设备状态总览</div>
    </div>

    <div class="chart">
      <ja-init-chart ref="chart" class="jachart" :options="options" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getLightingInfo } from '@/api'
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  name: 'water',
  components: {
    JaInitChart
  },
  data() {
    return {
      options: {},
      loading: true,
    }
  },
  created() {},
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState({
      buildingFloor: (state) => state.visualsystem.buildingFloor
    })
  },
  watch: {
    buildingFloor(newVal) {
      this.init(newVal)
    }
  },
  methods: {
    handleResize() {
      this.$refs.chart.myChart.resize()
    },
    async init(i) {
      this.loading = false
      let { data: res } = await getLightingInfo({
        buildingId: i.buildingId,
        floorId: i.floorId
      })
      this.options = {
        title: [
          {
            text: '照明设备总数',
            left: '17%',
            top: '70%',
            textAlign: 'center',
            textStyle: {
              fontSize: '14',
              fontWeight: '400',
              color: '#fff',
              textAlign: 'center'
            }
          },
          {
            text: '在线设备数量',
            left: '51%',
            top: '70%',
            textAlign: 'center',
            textStyle: {
              fontSize: '14',
              fontWeight: '400',
              color: '#fff',
              textAlign: 'center'
            }
          },
          {
            text: '离线设备数量',
            left: '85%',
            top: '70%',
            textAlign: 'center',
            textStyle: {
              fontSize: '14',
              fontWeight: '400',
              color: '#fff',
              textAlign: 'center'
            }
          }
        ],
        series: [
          {
            type: 'liquidFill',
            radius: '40%',
            center: ['18%', '45%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#18DFFF'
                  },
                  {
                    offset: 1,
                    color: '#0273FF'
                  }
                ],
                globalCoord: false
              }
            ],
            data: [res.deviceTotal / res.deviceTotal, res.deviceTotal / res.deviceTotal], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: 'RGBA(51, 66, 127, 0.7)'
            },
            label: {
              normal: {
                formatter: function () {
                  return res.deviceTotal // 显示具体数字
                },
                textStyle: {
                  fontSize: 14, // 数字字体大小
                  color: '#FFF' // 数字颜色
                }
              }
            },
            outline: {
              // show: false
              borderDistance: 0,
              itemStyle: {
                borderWidth: 2,
                borderColor: '#031F1E07'
              }
            }
          },
          {
            //第二个球的填充
            type: 'liquidFill',
            radius: '30%',
            center: ['51%', '50%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#20EA77'
                  },
                  {
                    offset: 1,
                    color: '#049A8E'
                  }
                ],
                globalCoord: false
              }
            ],
            data: [res.onLineDevice / res.deviceTotal, res.onLineDevice / res.deviceTotal], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: 'RGBA(51, 66, 127, 0.7)'
            },
            label: {
              normal: {
                formatter: function () {
                  return res.onLineDevice // 显示具体数字
                },
                textStyle: {
                  fontSize: 14, // 数字字体大小
                  color: '#FFF' // 数字颜色
                }
              }
            },
            outline: {
              // show: false
              borderDistance: 0,
              itemStyle: {
                borderWidth: 2,
                borderColor: '#031F1E07'
              }
            }
          },
          {
            type: 'liquidFill',
            radius: '25%',
            center: ['85%', '53%'],
            color: [
              {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#FFD177'
                  },
                  {
                    offset: 1,
                    color: '#FF9B00'
                  }
                ],
                globalCoord: false
              }
            ],
            data: [res.offLineDevice / res.deviceTotal, res.offLineDevice / res.deviceTotal], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: 'RGBA(51, 66, 127, 0.7)'
            },
            label: {
              normal: {
                formatter: function () {
                  return res.offLineDevice // 显示具体数字
                },
                textStyle: {
                  fontSize: 14, // 数字字体大小
                  color: '#FFF' // 数字颜色
                }
              }
            },
            outline: {
              // show: false
              borderDistance: 0,
              itemStyle: {
                borderWidth: 2,
                borderColor: '#031F1E07'
              }
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .header {
  left: 0 !important;
  top: 20px !important;
}
.chartone {
  width: 100%;
  height: 100%;
  position: relative;
  .header {
    position: absolute;
    left: 5px;
    top: 15px;
    height: 26px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .chart {
    margin-top: 20px;
    height: 100%;
    width: 100%;
  }
}
</style>