<template>
  <div class="dswindow">
    <div class="electrical">
      <div class="title">
        {{loopData.circuitName}}
      </div>
      <ul class="col">
        <li>总设备数： <span class="big">{{loopData.totalNumberOfDevices}}</span> 个</li>
        <li>异常设备： <span>{{loopData.numberOfAbnormalDevices}}</span> 个</li>
      </ul>
      <ul class="col">
        <li>当前状态： {{currentStatus[loopData.switching]}}</li>
        <li v-if="loopData.reverseStateTime">下次{{loopData.switching== 
"true"?"关灯":"开灯"}}时间： {{loopData.reverseStateTime}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentStatus:{true:'开',false:'关'}
    }
  },
  props: {
    loopData:{
      type:Object,
      default:()=>{
        return {}
      }
    },
  },
  mounted() {
    this.$emit('loading')
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;
  .electrical {
    width:378px;
    height: 105px;
    background: url('~@/assets/images/gd/deng_bg2@2x.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 16px;
    font-size: 15px;
    font-family: MicrosoftYaHei;
    color: #3acbe0;
    .title{
      font-size: 18px;
      color: #fff;
      padding-bottom: 4px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
    }
    .col{
      display: flex;
      font-size: 15px;
      padding-top: 6px;
      li{
        span{
          color:#fdcc30;
          vertical-align:middle;
        }
        .big{
          font-size: 24px;
        }
      }
    }
    .col:nth-of-type(1){
      li{
        width: 50%;
      }
    }
    .col:nth-of-type(2){
      justify-content: space-between;
    }
    .item{
      padding-top: 10px;
      font-size: 15px;
    }
  }
}
</style>