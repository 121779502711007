<template>
  <!--  普通的表单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="(isAdd?'新增':'编辑')+' '">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll">
    </ja-form>
  </el-dialog>
</template>
<script>
import { addFireTraining, editFireTraining } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  data() {
    return {
      rowFormData: [
        { input_type: 'input', label: '巡更计划名称', key: 'planName', col: 11, },
        { input_type: 'radio', label: '巡更计划名称', key: 'planType', col: 11, options:[]},
        { input_type: "select", label: '巡更路线', key: 'pathId', col: 22, options: [] },
        { input_type: "date", label: '计划开始时间', key: 'planStartTime', col: 11, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
        { input_type: "date", label: '计划结束时间', key: 'planEndTime', col: 11, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
        { input_type: 'radio', label: '执行频率', key: 'executionFrequency', col: 22, options: [{label:"日巡",value:0},{label:"周巡",value:1}]},
        { input_type: 'switch', label: '是否启用计划', key: 'enablePlan', col: 22, },
        { input_type: 'number', label: '工单提前创建时间', key: 'advanceCreateTime', col: 22,position:"right",min:1 },
      ],
      rowForm: {},
      rules: {
        trainingSubject: [{ required: true, message: '请完善培训科目', trigger: 'blur' }],
        trainingObject: [{ required: true, message: '请选择培训对象', trigger: 'change' }],
        trainingTime: [{ required: true, message: '请选择培训对象', trigger: 'change' }],
        participantNumber: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkNumber({ min: 1, isInt: true }) }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},

    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
    let index2 = this.rowFormData.findIndex(item => item.key == 'planType')
    this.util.rqOptionsList({ who: [this.rowFormData[index2]], rqUrl: "/sys/dictData/plan_type",resRule: 'data' })
  },

  methods: {
    append() {
      this.isAdd = true
      this.rowForm = {}
      this.showEditAll = true
    },
    rowEdit(row) {
      console.log('row---', row)
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      this.showEditAll = true
    },
    // 开始时间禁用
    disabledDateStart(val) {
      let now = (new Date()).getTime()
      let time = val.getTime()
      return time < now 
      // let oneDay = 24 * 3600 * 1000
      // return time < now - oneDay
    },
    saveForm() {
      // console.log('要提交的表单', this.rowForm)
      this.loadingForm = true
      let rqMethod = this.isAdd ? addFireTraining : editFireTraining
      rqMethod(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },


  }
}
</script>