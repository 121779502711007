<template>
  <div class="dswindow">
    <div class="electrical">
      <div class="title">
        异常设备总览（{{buildingFloor.buildingName}}{{buildingFloor.floorName}}）
      </div>
      <div class="illustrate">
        <div class="left">
          当前故障数量： <span>{{deviceInfor.numberOfFaults}}</span> 个
        </div>
        <div class="right">
          处理率： <span>{{deviceInfor.processRate}}</span> %
        </div>
      </div>
      <div class="number_of_failures">
       平均等待时长： <span>{{deviceInfor.averageWaitingTime}}</span> 分钟
      </div>
    </div>
    
  </div>
</template>

<script>
import { floorEquipmentInfor} from '@/api';
import { mapGetters } from "vuex"
export default {
  data () {
    return {
      filterData:{
        buildingId:"",
        floorId:""
      },
      deviceInfor:{}
    }
  },
  computed: {
    ...mapGetters(['buildingFloor'])
  },
  watch: {
    //楼栋楼层id发生变化时重新加载
    buildingFloor(){
      this.filterData.buildingId=this.buildingFloor.buildingId;
      this.filterData.floorId=this.buildingFloor.floorId;
      this.init()
    }
  },
  mounted() {
    this.$emit('loading')
  },
  methods: {
    init(){
      floorEquipmentInfor(this.filterData).then(res=>{
        this.deviceInfor=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;
  .electrical {
    width:378px;
    height: 134px;
    background: url('~@/assets/images/gd/deng_bg1@2x.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 20px;
    color: #3acbe0;
    font-size: 18px; 
    font-family: MicrosoftYaHei;
    .title{
      font-size:18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #fff;
    }
    .number_of_failures{
      line-height: 50px;
      font-size: 16px;
    }
    .illustrate{
      display: flex;
      font-size: 16px;
      padding-top:14px;
      align-items: center;
      .left{
        width: 66%;
        height: 28px;
      }
      .left:nth-of-type(1) span{
        font-size: 24px;
      }
      .right{
        width: 40% ;
      }
    }
    span{
        color:#fdcc30;
        font-size: 15px;
    }
  }
}
</style>