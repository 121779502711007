<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
      :total="total" :loading="loading" :showIndex="true" @handleSelectionChange="handleSelectionChange"
      @append="append" @rowEdit="rowEdit" @rowfieldEdit="rowfieldEdit" @rowDelete="rowDelete" @getInit="getInit"
      :valueFormat="formatter" :editDataFormat="editDataFormat" :btnTextFormat="btnTextFormat" @search="search"
      @rowIsStart="rowIsStart" @reset="reset">
    </ja-table>
    <!-- 新增/编辑 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="50%" :title="titleText">
      <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData"
        @reset="showEditAll = false" @save="saveForm" @selectPagination="selectPagination" v-if="showEditAll"
        v-loading="loading_form.isShow" :element-loading-text="loading_form.text"
        :element-loading-spinner="loading_form.icon" :element-loading-background="loading_form.bg"
        :element-loading-custom-class="loading_form.formLoading">
      </ja-form>
    </el-dialog>
    <!-- 字段编辑 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showFieldEdit" width="90%" :title="fieldForm_title">
      <ja-form :rules="{}" :label-width="labelWidth" v-model="fieldForm" :formArray="fieldFormData"
        @reset="showFieldEdit = false" @save="saveFieldForm" @rowAdd_field="rowAdd_field" @rowEdit_field="rowEdit_field"
        @rowDelete_field="rowDelete_field" v-if="showFieldEdit" v-loading="loading_form.isShow"
        :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon"
        :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading">
        <template #title1>
          <p class="title">查询字段配置</p>
        </template>
        <template #title2>
          <p class="title">显示字段配置</p>
        </template>
      </ja-form>
      <!-- 字段编辑里的某一项的编辑 -->
      <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showFieldItem" width="50%" title="字段配置编辑" append-to-body>
        <ja-form :rules="field_rules[fieldIem_type]" :label-width="labelWidth" v-model="fieldIemForm"
          :formArray="fieldIemDatas[fieldIem_type]" @reset="showFieldItem = false" @save="saveForm_fieldIem"
          v-if="showFieldItem">
        </ja-form>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import table from "@/views/mixins/table"
import {
  getQueryTable,
  addQueyTable,
  delQueryTable,
  editQueryTable,
  controlTableItem,
  getQueryTableDetail,
  addQueryTableDetail
} from '@/api'
export default {
  title: '表管理',
  mixins: [table],
  name: 'index',
  data () {
    return {
      labelWidth: `${this.util.setFontSize(150)}px`,
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      conditionData: [
        {
          input_type: 'select',
          key: 'dataSourceType',
          attribute: {
            placeholder: '数据源类型',
            size: 'small'
          },
          options: []
        },
        {
          input_type: 'input',
          key: 'search',
          attribute: {
            placeholder: '表ID/表名',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        },
        {
          text: '新增',
          input_type: 'button',
          method: 'append',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-plus',
            plain: true
          },
          rightPos: true
        }
      ],
      columns: [
        {
          label: '表ID',
          prop: 'tableId',
          width: 200
        },
        {
          label: '表名',
          prop: 'tableName',
          width: 120,
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '数据源类型',
          prop: 'dataSourceType'
        },
        {
          label: '表类型',
          prop: 'tableType'
        },
        {
          label: '标题',
          prop: 'title',
          width: 120,
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '数据源',
          prop: 'dataSource',
          width: 150
        },
        {
          label: '是否分页',
          prop: 'pagination'
        },
        {
          label: '分页显示列表',
          prop: 'pageList',
          width: 120,
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '状态',
          prop: 'delFlag'
        },
        {
          label: '备注',
          prop: 'remark',
          width: 120,
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '操作',
          input_type: 'button',
          width: 200,
          attribute: {
            fixed: "right"
          },
          list: [
            {
              text: '表编辑',
              method: 'rowEdit'
            },
            {
              text: '字段编辑',
              method: 'rowfieldEdit'
            },
            {
              method: 'rowIsStart'
            },
            {
              text: '删除',
              method: 'rowDelete'
            }
          ]
        }
      ],
      tableData: [],
      loading: false,
      total: 30,
      // 新增/编辑
      titleText: '编辑',
      rowFormData: [
        {
          input_type: 'input',
          label: '表ID',
          key: 'tableId'
        },
        {
          input_type: 'input',
          label: '表名',
          key: 'tableName',
          type: 'textarea',
          col: 22,
          maxlength: 10000,
          showWordLimt: true,
          rows: 5
        },
        {
          input_type: 'select',
          label: '数据源类型',
          key: 'dataSourceType'
        },
        {
          input_type: 'select',
          label: '表类型',
          key: 'tableType'
        },
        {
          input_type: 'input',
          label: '标题',
          key: 'title'
        },
        {
          input_type: 'select',
          label: '数据源',
          key: 'dataSource'
        },
        {
          input_type: 'radio',
          label: '是否分页',
          key: 'pagination',
          method: 'selectPagination'
        },
        {
          input_type: 'select',
          label: '状态',
          key: 'delFlag'
        },
        {
          input_type: 'input',
          label: '分页显示列表',
          key: 'pageList'
        },
        {
          label: '备注',
          key: 'remark',
          input_type: 'input',
          type: 'textarea',
          col: 22,
          maxlength: 500,
          showWordLimt: true,
          rows: 3
        }
      ],
      rules: {
        tableId: [{
          required: true,
          message: '请输入表ID',
          trigger: 'blur'
        }],
        tableName: [{
          required: true,
          message: '请输入表名',
          trigger: 'blur'
        }],
        dataSourceType: [{
          required: true,
          message: '请选择数据源类型',
          trigger: 'change'
        }],
        tableType: [{
          required: true,
          message: '请选择表类型',
          trigger: 'change'
        }],
        dataSource: [{
          required: true,
          message: '请选择数据源',
          trigger: 'change'
        }],
        pagination: [{
          required: true,
          message: '请选择是否分页',
          trigger: 'change'
        }],
        delFlag: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      },
      rowForm: {},
      showEditAll: false, // 显示新增/编辑弹窗
      // 一整个字段编辑
      fieldForm_title: '字段编辑',
      fieldFormData: [
        {
          col: 24,
          customize: 'title1',
          input_type: 'customize',
          noMarginBottom: true
        },
        {
          input_type: 'table',
          key: 'first',
          col: 24,
          showPage: false,
          noSearch: true,
          showIndex: true,
          table_MaxHeight: 600,
          columns: [
            // {label: '系统ID', prop: 'id', column_type: 'input',width:120 },
            // {label: '表ID', prop: 'tableId', column_type: 'input',width:120 },
            {
              label: '查询字段',
              prop: 'fieldName',
              column_type: 'input'
            },
            {
              label: '字段类型',
              prop: 'fieldType',
              column_type: 'select'
            },
            {
              label: '字典',
              prop: 'dict',
              column_type: 'input'
            },
            {
              label: '字典sql',
              prop: 'dictSql',
              column_type: 'input',
              attribute: {
                fixed: "right",
                align: 'center'
              }
            },
            {
              label: '对应查询字段',
              prop: 'columnList',
              column_type: 'input'
            },
            {
              label: '备注',
              prop: 'remark',
              column_type: 'input',
              width: 150,
              attribute: {
                fixed: "right",
                align: 'center'
              }
            },
            {
              label: '查询模式',
              prop: 'queryMode',
              column_type: 'select'
            },
            {
              label: '删除标识',
              prop: 'delFlag',
              column_type: 'select',
              options: [{
                label: '未删除',
                value: 0
              }, {
                label: '已删除',
                value: 1
              }]
            },
            {
              label: '操作',
              input_type: 'button',
              width: 150,
              attribute: {
                fixed: "right",
                align: 'center'
              },
              list: [
                {
                  text: '增加',
                  method: 'rowAdd_field',
                  type: 'first'
                },
                {
                  text: '编辑',
                  method: 'rowEdit_field',
                  type: 'first'
                },
                {
                  text: '删除',
                  method: 'rowDelete_field',
                  type: 'first'
                }
              ]
            }
          ]
        },
        {
          col: 24,
          customize: 'title2',
          input_type: 'customize',
          noMarginBottom: true
        },
        {
          input_type: 'table',
          key: 'second',
          col: 24,
          showPage: false,
          noSearch: true,
          showIndex: true,
          table_MaxHeight: 600,
          columns: [
            {
              label: '字段名',
              prop: 'columnName',
              column_type: 'input',
              width: 120
            },
            {
              label: '字段类型',
              prop: 'columnType',
              column_type: 'select',
              width: 120
            },
            {
              label: '格式化',
              prop: 'formatter',
              column_type: 'input',
              width: 120
            },
            {
              label: '字典类型',
              prop: 'dict',
              column_type: 'input',
              width: 120
            },
            {
              label: '字典sql',
              prop: 'dictSql',
              column_type: 'input',
              width: 120
            },
            {
              label: '备注',
              prop: 'remark',
              column_type: 'input',
              width: 120
            },
            {
              label: '字段标题',
              prop: 'title',
              column_type: 'input',
              width: 120
            },
            {
              label: '删除标识',
              prop: 'delFlag',
              column_type: 'select',
              width: 120,
              options: [{
                label: '未删除',
                value: 0
              }, {
                label: '已删除',
                value: 1
              }]
            },
            {
              label: '显示排序',
              prop: 'sortNum',
              column_type: 'input',
              width: 120,
              attribute: {
                sortable: true
              }
            },
            {
              label: '是否隐藏',
              prop: 'hidden',
              column_type: 'select',
              width: 120
            },
            {
              label: '操作',
              input_type: 'button',
              width: 150,
              attribute: {
                fixed: "right",
                align: 'center'
              },
              list: [
                {
                  text: '增加',
                  method: 'rowAdd_field',
                  type: 'second'
                },
                {
                  text: '编辑',
                  method: 'rowEdit_field',
                  type: 'second'
                },
                {
                  text: '删除',
                  method: 'rowDelete_field',
                  type: 'second'
                }
              ]
            }
          ]
        }
      ],
      fieldForm: {},
      showFieldEdit: false,
      // 字段编辑里面某一项的编辑
      fieldIemForm: {},
      showFieldItem: false,
      fieldIemDatas: {
        first: [
          // { input_type: 'input', label: '系统ID', key: 'id', },
          // { input_type: 'input', label: '表ID', key: 'tableId',disabled:true},
          {
            input_type: 'input',
            label: '查询字段',
            key: 'fieldName'
          },
          {
            input_type: 'select',
            label: '字段类型',
            key: 'fieldType'
          },
          {
            input_type: 'input',
            label: '字典',
            key: 'dict'
          },
          {
            input_type: 'input',
            label: '字典sql',
            key: 'dictSql',
            type: 'textarea',
            col: 24,
            styleWidth: '90% !important',
            rows: 4
          },
          {
            input_type: 'input',
            label: '备注',
            key: 'remark',
            col: 24,
            styleWidth: '90% !important'
          },
          {
            input_type: 'input',
            label: '对应查询字段',
            key: 'columnList'
          },
          {
            input_type: 'select',
            label: '查询模式',
            key: 'queryMode'
          },
          {
            input_type: 'select',
            label: '删除标识',
            key: 'delFlag',
            options: [{
              label: '未删除',
              value: 0
            }, {
              label: '已删除',
              value: 1
            }]
          }
        ],
        second: [
          {
            input_type: 'input',
            label: '字段名',
            key: 'columnName'
          },
          {
            input_type: 'select',
            label: '字段类型',
            key: 'columnType'
          },
          {
            input_type: 'input',
            label: '格式化',
            key: 'formatter'
          },
          {
            input_type: 'input',
            label: '字典类型',
            key: 'dict'
          },
          {
            input_type: 'input',
            label: '字典sql',
            key: 'dictSql',
            type: 'textarea',
            col: 24,
            styleWidth: '90% !important',
            rows: 4
          },
          {
            input_type: 'input',
            label: '备注',
            key: 'remark',
            col: 24,
            styleWidth: '90% !important'
          },
          {
            input_type: 'input',
            label: '字段标题',
            key: 'title'
          },
          {
            input_type: 'select',
            label: '删除标识',
            key: 'delFlag',
            options: [{
              label: '未删除',
              value: 0
            }, {
              label: '已删除',
              value: 1
            }]
          },
          {
            input_type: 'input',
            label: '显示排序',
            key: 'sortNum'
          },
          {
            input_type: 'select',
            label: '是否隐藏',
            key: 'hidden'
          }
        ]
      },
      fieldIem_type: '', // fisrt 或者 second
      fieldItem_activeIndex: '', // 字段编辑某一项 活跃索引
      field_rules: {
        first: {
          fieldName: [{
            required: true,
            message: '请输入查询字段',
            trigger: 'blur',
            text: '查询字段'
          }],
          fieldType: [{
            required: true,
            message: '请输入字段类型',
            trigger: 'blur',
            text: '字段类型'
          }],
          columnList: [{
            required: true,
            message: '请输入对应查询字段',
            trigger: 'blur',
            text: '对应查询字段'
          }],
          queryMode: [{
            required: true,
            message: '请选择查询模式',
            trigger: 'change',
            text: '查询模式'
          }],
          delFlag: [{
            required: true,
            message: '请选择删除标识',
            trigger: 'change',
            text: '删除标识'
          }]
        },
        second: {
          // second
          columnName: [{
            required: true,
            message: '请输入字段名',
            trigger: 'blur',
            text: '字段名'
          }],
          columnType: [{
            required: true,
            message: '请选择字段类型',
            trigger: 'change',
            text: '字段类型'
          }],
          title: [{
            required: true,
            message: '请输入字段标题',
            trigger: 'blur',
            text: '字段标题'
          }],
          delFlag: [{
            required: true,
            message: '请选择删除标识',
            trigger: 'change',
            text: '删除标识'
          }],
        }
      }
    }
  },
  created () {
    this.loading = false
    // 表编辑
    this.util.rqOptionsList({
      who: this.rowFormData[3],
      rqUrl: "/sys/dictData/table_type",
    }) // tableType  表类型
    this.util.rqOptionsList({
      who: [this.rowFormData[2], this.conditionData[0]],
      rqUrl: "/sys/dictData/table_source_type",
    }) // dataSourceType 数据源类型
    this.util.rqOptionsList({
      who: this.rowFormData[6],
      rqUrl: "/sys/dictData/table_pagination",
    }) // pagination 是否分页
    this.util.rqOptionsList({
      who: this.rowFormData[7],
      rqUrl: "/sys/dictData/query_status",
    }) // delFlag 状态
    this.util.rqOptionsList({
      who: this.rowFormData[5],
      rqUrl: "/sys/dictData/table_data_source",
    }) // dataSource 数据源
    // 字段编辑 - first
    this.util.rqOptionsList({
      who: [this.fieldFormData[1].columns[1], this.fieldIemDatas.first[1]],
      rqUrl: "/sys/dictData/field_type",
    }) // fieldType 字段类型
    this.util.rqOptionsList({
      who: [this.fieldFormData[1].columns[6], this.fieldIemDatas.first[6]],
      rqUrl: "/sys/dictData/query_model",
    }) // queryMode 查询模式
    // 字段编辑 - second
    this.util.rqOptionsList({
      who: [this.fieldFormData[3].columns[1], this.fieldIemDatas.second[1]],
      rqUrl: "/sys/dictData/column_type",
    }) // columnType 字段类型
    this.util.rqOptionsList({
      who: [this.fieldFormData[3].columns[9], this.fieldIemDatas.second[9]],
      rqUrl: "/sys/dictData/column_hidden",
    }) // columnType 字段类型
  },
  methods: {
    editDataFormat (row, btn) {
      // console.log(row, btn)
      // if ((row.status === 2 || row.status === 3) && btn.text === '预约') {
      //   return 'ahh'
      // } else {
      //   return true
      // }
      // return '哈哈'
      return true
    },
    btnTextFormat (row, btn) {
      if (row.delFlag === 0) {
        return '禁用'
      } else if (row.delFlag == 1) {
        return '启用'
      } else {
        return btn.text
      }
    },
    handleSelectionChange (val) {
      console.log('val', val)
    },
    formatter (val, key) {
      if (key === 'delFlag') {
        return ['启用', '禁用'][val]
      }
      if (key === 'pagination') {
        if (val == 'true') {
          return '是'
        } else {
          return '否'
        }
      }
      return val
    },
    rowIsStart (row, info, btn) {
      console.log('点击了启用禁用', row)
      controlTableItem(row.tableId).then(res => {
        this.$message.success('触发成功')
        this.getInit()
      })
    },
    getInit () {
      this.loading = true
      getQueryTable({
        pageSize: this.filterData.pageSize,
        pageIndex: this.filterData.pageIndex,
        search: this.filterData.search,
        dataSourceType: this.filterData.dataSourceType,
      }).then(res => {
        const data = res.data
        this.loading = false
        this.tableData = data.data
        this.total = data.total
      })
    },
    // 删除
    rowDelete (row) {
      this.$confirm(`是否确认删除${row.tableId}表？`).then(res => {
        delQueryTable(row.tableId).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      }).catch(() => {})
    },
    // 表新增、编辑 的是否 分页
    selectPagination () {
      const type = this.rowForm.pagination
      if (type == 'false') {
        this.$set(this.rowFormData[8], 'hide', true)
      } else {
        this.$set(this.rowFormData[8], 'hide', false)
      }
      console.log('选择了分页==》', type, this.rowFormData[8])
    },
    // 保存
    saveForm () {
      this.loading_form.isShow = true
      if (this.isAdd) {
        addQueyTable(this.rowForm).then(res => {
          this.$message.success('新增成功')
          this.showEditAll = false
          this.getInit()
          this.loading_form.isShow = false
        }).catch(error => {
          this.loading_form.isShow = false
          console.log(error)
        })
      } else {
        editQueryTable(this.rowForm).then(res => {
          this.$message.success('修改成功')
          this.showEditAll = false
          this.getInit()
          this.loading_form.isShow = false
        }).catch(error => {
          console.log(error)
          this.loading_form.isShow = false
        })
      }
    },
    append () {
      this.isAdd = true
      this.titleText = '新增'
      this.rowForm = {}
      this.rowFormData[0].disabled = false
      this.showEditAll = true
    },
    rowEdit (row) {
      this.isAdd = false
      this.titleText = '编辑'
      row.delFlag = row.delFlag.toString()
      this.rowForm = {
        ...row
      }
      this.selectPagination()
      this.rowFormData[0].disabled = true
      this.showEditAll = true
    },
    // 字段编辑
    rowfieldEdit (row) {
      this.titleText = '编辑'
      const obj = {
        first: [{}],
        second: [{}],
        tableId: row.tableId
      }
      this.fieldForm = obj
      this.fieldForm_title = `字段编辑 - ${row.tableId}`
      this.showFieldEdit = true
      this.loading_form.isShow = true
      getQueryTableDetail(row.tableId).then(res => {
        // console.log('字段详情==》',res)
        const data = res.data
        if (data.queryFields.length > 0) obj.first = data.queryFields
        if (data.queryColumns.length > 0) obj.second = data.queryColumns
        this.fieldForm = obj
        this.loading_form.isShow = false
      }).catch(() => {
        this.loading_form.isShow = false
      })
    },
    checkoutFieldForm (objForm, title, rules) {
      const key_arr = Object.keys(rules)
      const first_arr = objForm
      // console.log('key_arr===>',key_arr,'first_arr===>',first_arr)
      for (let i = 0; i < first_arr.length; i++) {
        for (let j = 0; j < key_arr.length; j++) {
          const val = first_arr[i][key_arr[j]]
          // console.log(first_arr[i],'==》'+val,!first_arr[i].hasOwnProperty(key_arr[j]),!val,'值val===:',val!==0 ,)
          if (!first_arr[i].hasOwnProperty(key_arr[j]) || (!val && val !== 0)) {
            this.$message.warning(`${title}：请完善第${i + 1}行的“${rules[key_arr[j]][0].text}”`)
            j = key_arr.length
            i = first_arr.length
            return false
          }
        }
      }
      return true
    },
    // 提交字段表单
    saveFieldForm () {
      // console.log('提交表单字段',this.fieldForm)
      const isOkFirst = this.checkoutFieldForm(this.fieldForm.first, '查询字段配置', this.field_rules.first)
      if (!isOkFirst) return
      const isOkSecond = this.checkoutFieldForm(this.fieldForm.second, '显示字段配置', this.field_rules.second)
      if (!isOkSecond) return
      this.loading_form.isShow = true
      addQueryTableDetail({
        tableId: this.fieldForm.tableId,
        queryFieldList: this.fieldForm.first,
        queryColumnList: this.fieldForm.second,
      }).then(res => {
        this.getInit()
        this.showFieldEdit = false
        this.loading_form.isShow = false
      }).catch(() => {
        this.loading_form.isShow = false
      })
    },
    // 字段编辑——增加
    rowAdd_field (row, info, btn) {
      this.fieldForm[btn.type].splice(info.$index + 1, 0, {})
    },
    // 字段编辑——编辑
    rowEdit_field (row, info, btn) {
      console.log('点击了编辑==', btn.type)
      this.fieldIem_type = btn.type
      this.fieldItem_activeIndex = info.$index
      this.fieldIemForm = {
        ...row
      }
      this.showFieldItem = true
    },
    // 字段编辑——删除
    rowDelete_field (row, info, btn) {
      if (info.$index == 0 && this.fieldForm[btn.type].length == 1) {
        this.$message.warning('仅有一行，禁止删除')
        return
      } else {
        this.fieldForm[btn.type].splice(info.$index, 1)
      }
    },
    // 字段某一项，字段配置编辑
    saveForm_fieldIem () {
      console.log('fieldForm===>', this.fieldForm)
      this.fieldForm[this.fieldIem_type].splice(this.fieldItem_activeIndex, 1, this.fieldIemForm)
      this.showFieldItem = false
    }
  }
}

</script>

<style scoped>
  .title {
    font-size: 17px;
    font-weight: 600;
  }

</style>
