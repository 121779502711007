<template>
  <div class="entry_right entry">
    <Slot/>
    <energy-consumption class="flowr"></energy-consumption>
    <Slot/>
    <NewvehicleAnalysis class="flowr"></NewvehicleAnalysis>
    <Slot/>
    <maintenance-analysis class="flowr"></maintenance-analysis>
  </div>
</template>

<script>
import EnergyConsumption from './energyConsumptionThan/index.vue'
import VehicleAnalysis from './vehicleAnalysis/index.vue'
import MaintenanceAnalysis from './maintenanceAnalysis/index.vue'
import NewvehicleAnalysis from '../../../home/visualManagement/wisdom/wisdomDnergy/vehicleAnalysis/vehicleAnalysis.vue'
import Slot from "@/components/Slot"
export default {
  data () {
    return {
      parkName: '',
      parkArr: []
    }
  },
  created () {
  },
  mounted(){
    this.$emit('loading')
  },
  methods: {
  },
  watch: {
    '$store.state.parkObjClick': function () {
      const parkObj = this.$store.state.parkObjClick
      this.parkName = parkObj.parkName
    },
    '$store.state.token': function () {
      // this.$message.error({ message: '监听加载:全国入口左侧:' + this.$store.state.token, duration: 6000, showClose: true })
      this.getExcellentPark()
    }
  },
  components: {
    Slot,
    EnergyConsumption,
    VehicleAnalysis,
    MaintenanceAnalysis,
    NewvehicleAnalysis
  }
}
</script>
<style lang="scss" scoped>
.entry_right{
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}
.flowr{
  height: 33%;
  width: 100%;
  flex: 1;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
