<template>
  <div class="entry bg" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="leftgif"></div>
    <div class="rightgif"></div>
    <div class="left">
      <div class="flow">
        <sectorChart :equipmentData="equipmentData" v-if="loading" />
      </div>
      <div class="flow" @click="openWaterPolo(0)">
        <alarmChart :alarmList="deviceInfoList" v-if="loading" />
      </div>
      <div class="flow">
        <interview :columns="repairColumns" title="维修详情列表" :tableData="repairTableData" />
      </div>
    </div>
    <div class="right">
      <div class="flow" @click="openWaterPolo(1)">
        <waterPolo />
      </div>
      <div class="flow">
        <vehicleAnalysis />
      </div>
      <div class="flow" @click="openWaterPolo(2)">
        <interview :columns="interviewColumns" title="访问单位排行" :tableData="interviewTableData" :typeIndex="true" />
      </div>
    </div>
    <div class="systembtn">
      <systemButton title="智慧人行" />
    </div>
    <div class="bottom"></div>

    <Pop ref="dialog0" title="告警事件详情" :typeIndex="false" :columns="columns" :getDatamethod="pedestrianPage"
      :systemurl="systemurl">
    </Pop>
    <Pop ref="dialog1" title="访客总览" :typeIndex="false" :columns="columns" :getDatamethod="visitorOverviewPage"
      :systemurl="systemurl">
    </Pop>
    <Pop ref="dialog2" title="公司访客详情" :typeIndex="false" :columns="columns" :getDatamethod="visitingUnitPage"
      :systemurl="systemurl">
    </Pop>
  </div>
</template>

<script>
import headers from '../../../../dsWindow/IOC/header/index.vue'
import sectorChart from './sectorChart/sectorChart.vue'
import alarmChart from '../components/alarmChart/index.vue'
import interview from '../components/interview/index.vue'
import waterPolo from './waterPolo/waterPolo.vue'
import Pop from '../components/pop/index.vue'
import vehicleAnalysis from './vehicleAnalysis/vehicleAnalysis.vue'
import { threeStatistics, pedestrianAlarm, pedestrianPage, visitorOverviewPage, visitingUnitPage, visitingUnit, repairOrder } from '@/api'
import systemButton from '../components/systemButton/index.vue'

export default {
  title: '智慧人行',
  name: 'peopleRow',
  components: {
    headers,
    sectorChart,
    alarmChart,
    waterPolo,
    vehicleAnalysis,
    interview,
    Pop,
    systemButton
  },
  data() {
    return {
      equipmentData: [
        { name: '门禁设备', value: 100, fangOnline: 1, fangOffOnline: 2, options: {} },
        { name: '道闸设备', value: 100, fangOnline: 1, fangOffOnline: 2, options: {} },
        { name: '智能梯控', value: 100, fangOnline: 1, fangOffOnline: 2, options: {} },
      ],
      deviceInfoList: [
        { name: '非法闯入', value: 1 },
        { name: '设备异常', value: 2 },
        { name: '非法入侵', value: 3 }
      ],
      //访问表头
      interviewColumns: [
        { label: '访问单位', prop: 'visitingUnit' },
        { label: '访问人次', prop: 'userSessions', width: '100px', type: '0' },
      ],
      //维修表头
      repairColumns: [
        { label: '报修类别', prop: 'repairCategory' },
        { label: '所在位置', prop: 'location' },
        { label: '工单状态', prop: 'status', type: 1 }
      ],
      //维修表格数据
      repairTableData: [],
      //访问表格数据
      interviewTableData: [],
      pedestrianPage,//告警事件详情
      visitorOverviewPage,//访客总览
      visitingUnitPage,//公司访客详情
      columns: [],
      systemurl: '',
      dataInterval: null,
      loading: false
    }
  },
  mounted() {
    this.$emit('loading')
    this.startDataInterval()
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
  },
  methods: {
    startDataInterval() {
      this.init()
      this.dataInterval = setInterval(() => {
        this.loading = false
        this.init()
      }, this.TIMER_INTERVAL);
    },
    init() {
      this.threeStatistics()
      this.pedestrianAlarm()
      this.visitingUnit()
      this.repairOrder()
    },
    async threeStatistics() {
      //获取设备总览数据
      const { data } = await threeStatistics()
      this.loading = true;
      this.equipmentData[0].value = data.accessControlEquipment || 0
      this.equipmentData[0].fangOnline = data.accessControlOnline || 0
      this.equipmentData[0].fangOffOnline = data.offlineAccessControl || 0
      this.equipmentData[1].value = data.gateEquipment || 0
      this.equipmentData[1].fangOnline = data.gateEquipmentOnline || 0
      this.equipmentData[1].fangOffOnline = data.gateEquipmentOffline || 0
      this.equipmentData[2].value = data.escalatorControl || 0
      this.equipmentData[2].fangOnline = data.escalatorControlOnline || 0
      this.equipmentData[2].fangOffOnline = data.offlineEscalatorControl || 0
      //配置图标数据
      this.equipmentData.map(item => {
        item.options = {
          color: ['#FFDD30', '#54FFD2'],
          series: [
            {
              // 内圆刻度
              type: 'gauge',
              startAngle: '0',
              endAngle: '360',
              radius: '40%',
              splitNumber: 30,
              center: ['50%', '50%'],
              z: 4,
              axisLine: {
                show: false
              },
              pointer: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                length: 2,
                lineStyle: {
                  color: '#002f64 ',
                  width: 1
                }
              },
              axisLabel: {
                show: false
              },
              detail: {
                show: false
              }
            },
            {
              name: '',
              type: 'pie',
              radius: ['30%', '65%'],
              avoidLabelOverlap: false,
              startAngle: '0',
              endAngle: '360',
              hoverOffset: 0,
              label: {
                show: false,
                position: 'left'
              },
              emphasis: {
                label: {
                  show: false
                }
              },
              labelLine: {
                show: false
              },
              data: [item.fangOnline, item.fangOffOnline]
            }
          ]
        }
      })
    },
    //获取告警数据
    async pedestrianAlarm() {
      const { data } = await pedestrianAlarm()
      this.loading = true;
      this.deviceInfoList = data.map(item => {
        return {
          value: item.alarmCount,
          name: item.alarmName
        }
      })
    },
    async visitingUnit() {
      const { data } = await visitingUnit()
      this.interviewTableData = data.length ? data : data.slice(0, 9)
    },
    //获取维修列表数据
    async repairOrder() {
      const { data } = await repairOrder()
      this.repairTableData = data.length ? data : data.slice(0, 9)
    },
    //打开弹窗
    openWaterPolo(i) {
      const key = 'dialog' + i
      const obj = {
        0: [
          { label: '事件名称', prop: 'eventName' },
          { label: '触发时间', prop: 'triggerTime' },
          { label: '状态', prop: 'status', type: 3 }
        ],
        1: [
          { label: '开门方式', prop: 'openType' },
          { label: '访客类型', prop: 'imageType' },
          { label: '通行时间', prop: 'swingTime' },
          { label: '状态', prop: 'status', type: 4 }
        ],
        2: [
          { label: '访问公司', prop: 'visitCompany' },
          { label: '访客姓名', prop: 'personName' },
          { label: '申请时间', prop: 'swingTime' }
        ]
      }
      this.columns = obj[i]
      this.$refs[key].showDialog();
    },
    openDialog() {
      this.$refs.dialog.showDialog();
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';

.bg {
  height: 100%;
  position: relative;
  overflow: hidden;

  .scene {
    width: 100%;
    height: 100%;
    transform: scale(1);
  }
}

.entry {
  width: 100%;
  position: relative;

  // height: 5vw;
  // 导航栏
  .top {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.png');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .leftgif,
  .rightgif {
    position: absolute;

    height: 90vh;
    width: 24%;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    z-index: 1;
    pointer-events: none;
  }

  .leftgif {
    background: url('~@/assets/gd/left.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: 10px;
  }

  .rightgif {
    right: 10px;
    background: url('~@/assets/gd/right.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .left,
  .right {
    position: absolute;

    height: 90vh;
    width: 24%;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;

    .flow {
      height: 33%;
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .left {
    background: url('~@/assets/gd/left.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: 10px;
  }

  .right {
    right: 10px;
    background: url('~@/assets/gd/right.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .bottom {
    height: 38px;
    width: 66.5%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }

  .systembtn {
    width: 36%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 85px;
  }
}
</style>
