<template>
    <div class="allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
            :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset"
            @rowDetail="normalEvent">
        </ja-table>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import { failPage } from '@/api'
export default {
    title: "门禁事件",
    name: 'entranceGuard',
    mixins: [table],
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'deviceCode', attribute: { placeholder: '设备编码', size: 'small' } },
                { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
                { input_type: 'input', key: 'remark', attribute: { placeholder: '事件描述', size: 'small' } },
                { input_type: 'date', key: 'time', value: 'time', rangeSeparator: '-', type: 'daterange', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', exportObj: { rqParmas: this.rqexport, rqUrl: '/access/failPageExport', rqMethod: 'post' } }
            ],
            columns: [
                { label: '设备编码', prop: 'deviceCode' },
                { label: '设备名称', prop: 'deviceName' },
                { label: '事件描述', prop: 'remark' },
                { label: '时间', prop: 'swingTime' },
                // { label: '操作', prop: 'deviceName' },
            ],
            tableData: []
        }
    },
    mounted() {
    },
    methods: {
        rqexport() {
            let obj = { ...this.filterData }
            obj.pageIndex = this.total <= 5000 ? 1 : this.filterData.pageIndex
            obj.pageSize = 5000
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        rqParmas2() {
            let obj = this.rqParmas()
            console.log(obj,'obj');
            if (obj.time) {
                obj.startTime = obj.time[0]
                obj.endTime = obj.time[1]
                delete obj.time
            }
            return obj
        },
        getInit() {
            this.loading = true
            let obj = this.rqParmas2()
            failPage(obj).then(res => {
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            }).catch(err => {
                this.loading = false
            })
        },
        detail(row){
            this.$refs.detail.rowDetail(row)
        }
    }
}
</script>
  
<style lang="scss" scoped>
::v-deep .cellImg {
    display: flex;
    margin: auto;

    >img {
        max-height: 23px;
        width: 35px;
        display: flex;
        margin: auto;
    }
}

::v-deep .errorImg {
    width: 100%;
}</style>
  