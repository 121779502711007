// ========================================================================
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home'
import Login from '../views/login/index'
import security from '../views/dsWindow/security/index'
import electricaldata from '../views/dsWindow/electrical/electricaldata/index.vue'
import electricalbox from '../views/dsWindow/electrical/electricalbox/index.vue'
import conditioningdata from '../views/dsWindow/conditioning/conditioningdata'
import airconditionbox from '../views/dsWindow/conditioning/airconditionbox'
import blowerbox from '../views/dsWindow/conditioning/blowerbox'
import waterpump from '../views/dsWindow/conditioning/waterpump'
import waterpumpbox from '../views/dsWindow/conditioning/waterpumpbox'
import electricvalvebox from '../views/dsWindow/conditioning/electricvalvebox'
import guangzhouRadio from '../views/dsWindow/IOC/index.vue'



import emptyPage from '../views/emptyPage'
import autoRoute from './model/index'

Vue.use(VueRouter)
// console.log("autoRoute----",autoRoute)



const v = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return v.call(this, location).catch(err => err)
}
// //  公共路由
export const constantRoutes = [
  {
    path: '/',
    // redirect: '/home/homeIndex'
    redirect: '/home/cockpit'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    redirect: '/home/404',
  },
]
const route = [
  ...autoRoute.children
]
const routes = [
  {
    path: '/',
    // redirect: '/home/homeIndex'
    redirect: '/home/cockpit'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path:'/deshunRadio',
    name:'deshunRadio',
    component: () => import('@/views/home/visualManagement/index.vue'),
    children:[
      {
        path:'/deshunRadio/wisdom',
        component: () => import('@/views/home/visualManagement/wisdom/index.vue'),
        children:[
          {
            name:'智慧楼宇',
            path: '/deshunRadio/wisdom/wisbuilding',
            component: () => import('@/views/home/visualManagement/wisdom/wisbuilding/index.vue'),
          },
          {
            name: '智慧安防',
            path: '/deshunRadio/wisdom/wissecurity',
            component: () => import('@/views/home/visualManagement/wisdom/wissecurity/index.vue'),
          },
          {
            name: '智慧能源',
            path: '/deshunRadio/wisdom/wisdomEnergy',
            component: () => import('@/views/home/visualManagement/wisdom/wisdomEnergy/index.vue'),
          },
          {
            name: '智慧人行',
            path: '/deshunRadio/wisdom/wisdomDnergy',
            component: () => import('@/views/home/visualManagement/wisdom/wisdomDnergy/index.vue'),
          },
          {
            name: '综合态势主页',
            path: '/deshunRadio/wisdom/wismanagement',
            component: () => import('@/views/home/visualManagement/wisdom/wismanagement/index.vue'),
          },
        ]
      }
    ]
  },
  {
    path: '/ds/security',
    name: 'security',
    component: security
  },
  {
    path: '/ds/chartNEW',
    name: 'chartNEW',
    component: () => import('@/views/dsWindow/security/chartNEW.vue')
  }, 
  {
    path: '/ds/accesscontrolcard',
    name: 'accesscontrolcard',
    meta: {
      title: `安防系统-门禁卡片`
    },
    component: () => import('@/views/dsWindow/security/accesscontrolCard/index.vue')
  },
  {
    path: '/ds/electricaldata',
    name: 'electricaldata',
    component: electricaldata
  },
  {
    path: '/ds/electricalbox',
    name: 'electricalbox',
    component: electricalbox
  },
  {
    path: '/ds/conditioningdata',
    name: 'conditioningdata',
    component: conditioningdata
  },
  {
    path: '/ds/airconditionbox',
    name: 'airconditionbox',
    meta: {
      title: `暖通系统-空调`
    },
    component: airconditionbox
  },
  {
    path: '/ds/blowerbox',
    name: 'blowerbox',
    meta: {
      title: `暖通系统-风机`
    },
    component: blowerbox
  },
  {
    path: '/ds/sensorca',
    name: 'sensorCard',
    meta: {
      title: `暖通系统-传感器`
    },
    component: () => import('@/views/dsWindow/conditioning/sensorCard/index.vue')
  },
  {
    path: '/ds/waterpump',
    name: 'waterpump',
    component: waterpump
  },
  {
    path: '/ds/waterpumpbox',
    name: 'waterpumpbox',
    component: waterpumpbox
  },
  {
    path: '/ds/electricvalvebox',
    name: 'electricvalvebox',
    component: electricvalvebox
  },
  {
    path: '/ds/electricalCard',
    name: 'electricalCard',
    meta: {
      title: `能源系统-电卡片`
    },
    component: () => import('@/views/dsWindow/electrical/electricalCard/index.vue')
  },
  {
    path: '/ds/waterCard',
    name: 'waterCard',
    meta: {
      title: `能源系统-水电卡片`
    },
    component: () => import('@/views/dsWindow/electrical/waterCard/index.vue')
  },
  {
    path: '/ds/guangzhouRadio',
    name: 'guangzhouRadio',
    meta: {
      title: `广电大屏`
    },
    component: guangzhouRadio
  },
  {
    path: '/ds/guangzhouRadio/left',
    name: 'guangzhouRadioLeft',
    meta: {
      title: `广电左`
    },
    component: () => import('@/views/dsWindow/IOC/left/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/right',
    name: 'guangzhouRadioRight',
    meta: {
      title: `广电右`
    },
    component: () => import('@/views/dsWindow/IOC/right/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/midPower',
    name: 'powerConsumption',
    meta: {
      title: `广电实时功耗`
    },
    component: () => import('@/views/dsWindow/IOC/mid/powerConsumption/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/midPeople',
    name: 'admissionNum',
    meta: {
      title: `实时园区人数`
    },
    component: () => import('@/views/dsWindow/IOC/mid/admissionNum/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/midCar',
    name: 'carNum',
    meta: {
      title: `实时车辆数`
    },
    component: () => import('@/views/dsWindow/IOC/mid/carNum/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/midIndoor',
    name: 'indoor',
    meta: {
      title: `广电室内`
    },
    component: () => import('@/views/dsWindow/IOC/mid/indoor/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/lighting/abnormalCard',
    name: 'abnormalCard',
    meta: {
      title: `照明系统-异常设备总览`
    },
    component: () => import('@/views/dsWindow/lighting/abnormalCard/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/lighting/abnormalstoreyCard',
    name: 'abnormalstoreyCard',
    meta: {
      title: `照明系统-异常设备总览（A塔1楼）`
    },
    component: () => import('@/views/dsWindow/lighting/abnormalstoreyCard/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/lighting/loopCard',
    name: 'loopCard',
    meta: {
      title: `照明系统-回路区域卡片`
    },
    component: () => import('@/views/dsWindow/lighting/loopCard/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/lighting/deviceCard',
    name: 'deviceCard',
    meta: {
      title: `照明系统-设备卡片`
    },
    component: () => import('@/views/dsWindow/lighting/deviceCard/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/lighting/loopButton',
    name: 'deviceLoopBtn',
    meta: {
      title: `照明系统-回路按钮`
    },
    component: () => import('@/views/dsWindow/lighting/loopButton/index.vue')
  },
  {
    path: '/ds/guangzhouRadio/lighting/loopConfig',
    name: 'deviceConfig',
    meta: {
      title: `照明系统-回路配置`
    },
    component: () => import('@/views/dsWindow/lighting/loopConfig/index.vue')
  },
  {
    path: '*',
    redirect: '/home/404',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    // redirect: '/home/homeIndex',
    redirect: '/home/cockpit',
    children: [
      ...route,
      {
        path: '*',
        meta: {
          show: false
        },
        name: '404',
        component: emptyPage
      },
    ]
  },
  {
    path: '/ds/animationTesting',
    name: 'animationTesting',
    meta: {
      title: `大屏图表动画测试`
    },
    component: () => import('@/views/dsWindow/IOC/animationTesting/index.vue')
  },
]

// console.log('routes----------yu', routes)
const router = new VueRouter({
  mode: 'hash',
  routes,
})

export default router
