<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="工单详情" :width="`50%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='8' v-if="showDetail" :loadingForm="loadingForm">
        <template #planDetailVos>
          <div class="planDetailVos">
            <ul class="titleList">
              <li v-for="(item,index) in statusList" :key="index"><span :style="{'background':item.color}"></span>{{item.text}}</li>
            </ul>
            <div class="main">
              <el-tag :type="statusList.find(v=>v.value == item.status).type" effect="dark" size="medium" v-for="(item,index) in detailData.planDetailVos" :key="index" @click="clickTag(item)">{{item.deviceName}}</el-tag>
            </div>
            <br>
            <span>设备异常描述：{{exceptionDesc || "无"}}</span>
          </div>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import detailObj from '../common/childComp/Detail'
import { rqInspectOrderItem } from '@/api'

export default {
  props: {
    pageType: String,
  },
  data() {
    return {
      showDetail: false,
      detailOptions: detailObj[this.pageType],
      detailData: {},
      loadingForm: false,
      exceptionDesc:"",
      statusList: [
        { text: '未维保设备', color: '#909399', value: 0, type: 'info' },
        { text: '设备正常', color: '#67c23a', value: 1, type: 'success' },
        { text: '设备异常', color: '#e6a23c', value: 2, type: 'warning' },
      ]
    }
  },
  methods: {
    rowDetail(row) {
      this.detailData = {}
      this.showDetail = true
      this.loadingForm = true
      rqInspectOrderItem(row.id).then(res => {
        let data = res.data
        data.solveImgArr = data.solveImg?.split(",")
        let {status:approvedResultStatus,employee:approvedResultEmployee,time:approvedResultTime,result:approvedResultResult } = data.approvedResult
        let list = (data.flowListVos || []).map(v => {
          return {
            ...v,
            dealText: `【${v.stepValue}】 ${v.operationEmployeeName} ${v.result}`
          }
        })
        this.detailData = { ...data, flowListVos: list, approvedResultStatus, approvedResultEmployee, approvedResultTime,  approvedResultResult }
        console.log(this.detailData);
        this.exceptionDesc = this.detailData.planDetailVos[0].exceptionDesc
        this.loadingForm = false
      }).catch(err => {
        this.loadingForm = false
      })
    },

    clickTag({exceptionDesc}){
      this.exceptionDesc = exceptionDesc
    }
  }
}
</script>

<style lang="scss" scoped>
.planDetailVos {
  ul.titleList {
    display: flex;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      margin-right: 50px;
      span {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  .main {
    .el-tag {
      margin-right: 30px;
      &:hover{
        cursor: pointer;
      }
    }
  }
}
</style>