<template>
  <div class="box_bg">
    <div class="box_bggif"></div>
    <div class="boxleft">
      <div class="header">
        {{ title }}
      </div>
    </div>
    <div class="mid">
      <div class="midtop">
        <div>
          CO₂浓度:<p :class="indoorData.co2>=700? 'red':''">{{indoorData.co2 || ''}}</p>
          <span>ppm</span>
        </div>
        <div>
          环境湿度:<p :class="envHumidityFilter ? 'red':''">{{indoorData.envHumidity || ''}}</p>
          <span>%</span>
        </div>
        <div>
          PM10浓度:<p :class="indoorData.pm10>=150? 'red':''">{{indoorData.pm10 || ''}}</p>
          <span>ug/m³</span>
        </div>
      </div>
      <div class="midbottom">
        <div>
          PM2.5浓度:<p :class="indoorData.fineParticulateMatter>=755? 'red':''">{{indoorData.fineParticulateMatter || ''}}</p>
          <span>ug/m³</span>
        </div>
        <div>
          TVOC浓度:<p :class="indoorData.fineParticulateMatter>=0.6? 'red':''">{{indoorData.tcov || ''}}</p>
          <span>ug/m³</span>
        </div>
        <div>
          环境温度:<p :class="{'red':temperatureClass}">{{indoorData.envTemperature || ''}}</p>
          <span>°C</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title: '室内环境测量',
  data() {
    return {
      title: '室内环境测量',
    }
  },
  props: {
    indoorData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  computed:{
    temperatureClass(){
     if(this.indoorData.status == 1){
      return this.indoorData.envTemperature >= 27 ? true : false
     }else if(this.indoorData.status == 4){
      return this.indoorData.envTemperature <= 20 ? true : false
     }else{
      return false
     }
    },
    envHumidityFilter(){
     if(this.indoorData.status == 1){
      return (this.indoorData.envHumidity < 40 || this.indoorData.envHumidity > 60)? true : false
     }else if(this.indoorData.status == 4){
      return (this.indoorData.envHumidity < 35 || this.indoorData.envHumidity > 60) ? true : false
     }else{
      return false
     }
    },
  },
  created() {},
  mounted() {
    this.$emit('loading')
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';
.box_bg {
  background-image: url('~@/assets/gd/ds_card2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 10px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

.box_bggif {
  background-image: url('~@/assets/gd/ds_card2.gif');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index:11;
}
.boxleft {
  width: 100%;
  .header {
    padding: 20px 0 0 30px;
    font-size: 19px;
    text-align: left;
    color: #fff;
  }
}
.mid {
  width: 100%;
  display: flex;
  font-size: 12px;
  line-height: 12px;
  font-family: MicrosoftYaHei;
  color: #37eaff;
  letter-spacing: 1px;
  text-shadow: 3px 5px 2px rgba(5, 19, 35, 0.3);
  .midtop,.midbottom {
    width: 50%;
    margin-left: 10px;
    font-size: 12px;
    > div {
      display: flex;
      margin-top: 12px;
      p {
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #ffce14;
        text-shadow: none;
      }
      span {
        margin-left: 5px;
        font-family: MicrosoftYaHei;
        color: #8aeeff;
        letter-spacing: 1px;
        text-shadow: 3px 5px 2px rgba(5, 19, 35, 0.3);
      }
    }
  }
}
.midbox {
  text-align: center;
  color: #02ffac;
  font-size: 36px;
}
.red{
  color: red!important;
}
</style>
