<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="详情" :width="`50%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='8' v-if="showDetail"></ja-detail>
    </el-dialog>
  </div>
</template>
<script>
// import detailObj from '../common/childComp/Detail'

export default {
  props: {
    pageType: String,
  },
  data() {
    return {
      showDetail: false,
      detailOptions: [
        { key: 'title', label: '访客信息', isTitle: true, col: 24 },
        { key: 'nickname', label: '访客姓名', },
        { key: 'a', label: '性别', },
        { key: 'a', label: '所属公司', },
        { key: 'a', label: '身份证号', },
        { key: 'a', label: '手机号码', },
        { key: 'a', label: '车牌号码', },
        { key: 'a', label: '预约次数', },
        { type: 'img', key: 'a', label: '人脸像片',col:24 },
      ],
      detailData: {},
      initFileList: []
    }
  },
  methods: {
    rowDetail(row) {
      // console.log('查看详情---',row,)
      this.detailData = { ...row }
      this.showDetail = true
    },
  }
}
</script>