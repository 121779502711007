<template>
  <div class="dswindow">
    <div class="electrical">
      <el-scrollbar ref="scrollContainer" :vertical="false" class="" @wheel.native.prevent="handleScroll">
        <ul class="button_list">
          <li v-for="(item,i) in btnList" :key="i" :class="{'curr':item.switching=='true'}" @click="openCircuit(item)" @mouseover="mouseOver(item)"
        @mouseleave="mouseLeave(item)"></li>
        </ul>
      </el-scrollbar>
    </div>
    <!-- 启用失败弹窗提示 -->
    <el-dialog v-dialogDrag
        :modal-append-to-body="false"
        :visible.sync="showFailedToEnable"
        center
        width="36%"
        title="启用失败提示"
    >
        <ul class="enable_prompts">
            <li>选中回路</li>
            <li>原因</li>
        </ul>
        <ul class="enable_prompts" v-for="(item,i) in errorMessage" :key="i">
            <li>{{item.selectCircuit}}</li>
            <li>{{item.deviceName}}已启用{{item.circuitName}}回路</li>
        </ul>
        <div class="enable_prompts_tail">
            <!-- <el-button @click="download">下载</el-button> -->
            <el-button type="primary" @click="already">已知晓</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getCircuitManagement,circuitetSwitchPrompt,circuitetSwitching} from '@/api'
export default {
  data () {
    return {
      btnList:[
        {id:1},
      ],
      open:true,
      showFailedToEnable:false,
      errorMessage:[],
      filterData:{}
    }
  },
  props:{
    url:{
      type:String,
      default:''
    }
  },
  computed: {
    ...mapGetters(['buildingFloor']),
    scrollWrapper() {
        return this.$refs.scrollContainer.$refs.wrap
    },
  },
  watch: {
    //楼栋楼层id发生变化时重新加载
    buildingFloor(){
      this.filterData={
        buildingId:this.buildingFloor.buildingId,
        floorId:this.buildingFloor.floorId,
        pageIndex:1,
        pageSize:1000
      }
      this.init()
    }
  },
  mounted() {
    this.$emit('loading')
    if(!this.buildingFloor) return
     this.filterData={
      buildingId:this.buildingFloor.buildingId,
      floorId:this.buildingFloor.floorId,
      pageIndex:1,
      pageSize:100
    }
    this.init()
  },
  methods: {
    init(){
      getCircuitManagement(this.filterData).then(res => {
          const { data, total } = res.data
          this.btnList = data
      }).catch(err => {
      })
    },
    openCircuit(item){
        let state=item.switching
        //是开回路的操作，需要调用接口验证
        if(state=='false'){
            circuitetSwitchPrompt({circuitId:[item.id]}).then(res=>{
                if(res.data.list && res.data.list.length>0){
                    this.showFailedToEnable=true;
                    this.errorMessage=res.data.list
                }else{
                    circuitetSwitching({switching:'true',ids:[item.id]}).then(async res=>{
                        await this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        let obj = {
                          deviceNumbers: item.deviceNumbers,
                          operationStatus: "mouseClick",
                          switching: 'true'
                        }
                        lightingIframe.window.postMessage(obj, this.url);
                        this.init()
                    }) 
                }
            })
        }else{
            circuitetSwitching({switching:'false',ids:[item.id]}).then(async res=>{
                await this.$message({
                    message: res.msg,
                    type: 'success'
                })
                let obj = {
                  deviceNumbers: item.deviceNumbers,
                  operationStatus: "mouseClick",
                  switching: 'false',
                }
                lightingIframe.window.postMessage(obj, this.url);
                this.init()
            }) 
        }
    },
    /**已知晓 */
    already(){
      this.showFailedToEnable=false;
    },
    mouseOver(item){
      item.mouseStatus=true
      this.$emit("displayStatus",item)
      if(this.url){
        let obj={
          deviceNumbers:item.deviceNumbers,
          operationStatus:"mouseOver"
        }
        lightingIframe.window.postMessage(obj,this.url);
      }
    },
    mouseLeave(item){
      item.mouseStatus=false
      this.$emit("displayStatus",item)
      if(this.url){
        let obj={
          deviceNumbers:item.deviceNumbers,
          operationStatus:"mouseLeave"
        }
        lightingIframe.window.postMessage(obj,this.url);
      }
    },
    handleScroll(e) {
      const eventDelta = e.wheelDelta || -e.deltaY * 40
      const $scrollWrapper = this.scrollWrapper
      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4
      this.$nextTick(() => {
        this.$refs.scrollContainer.update();
      });

    },
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;
  //  background: #041E41;
  .electrical {
     background: url('~@/assets/images/gd/huilu_bg.png') no-repeat;
     background-size: 100% 100%;
     box-sizing: border-box;
    .button_list{
      width: 327px;
      height: 56px;
      display: flex;
      // overflow-x: auto;
      // overflow-y: hidden;
      padding: 15px 20px;
     
      // justify-content: space-between;
      li{
        width: 17px;
        height: 21px;
        background: url('~@/assets/images/gd/loop_switch_c.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
        box-sizing: border-box;
        flex-shrink: 0;
        cursor: pointer;
      }
      .curr{
        background: url('~@/assets/images/gd/loop_switch_o.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .enable_prompts{
    display: flex;
    text-align: center;
    li{
        width: 50%;
        line-height: 30px;
      }
  }
  .enable_prompts:nth-of-type(1){
      font-size: 18px;
      font-weight: bold;
  }
  .enable_prompts_tail{
      text-align: right;
      padding-top: 30px;
  }
}
</style>