<template>
    <el-main>
        <div id="player" width="100%" height="700"></div>
    </el-main>
</template>
<script>
import PlayerManager from '@/api/module/icc/PlayerManager'

export default {
  data () {
    return {
      player: null
    }
  },
  created () {
  },
  mounted () {
    this.init()
    // 选择视频
    this.$bus.$on('TreeClick', deviceNumber => {
      if (!deviceNumber) return this.$message.warning('视频请求失败')
      this.monitorView(deviceNumber)
    })
  },
  methods: {
    init () {
      this.player = new PlayerManager({
        el: "player", /** 实时预览容器id，创建多个播放器，传入不同的容器id即可 **/
        pztEl: "ws-pan-tilt", // 云台控制容器id
        type: 'real', /** real - 实时预览  record - 录像回放 **/
        maxNum: 1, /** 最多显示的路数，可根据实际情况设置，可设置1 4 9 16 25等5种路数 **/
        num: 1, /** 初始化显示的路数 **/
        showControl: false, /** 默认是否显示工具栏 **/
        streamServerIp: process.env.VUE_APP_IP, // 流媒体服务 Ip 地址
        useNginxProxy: false // 拉流模式 【true-代理模式拉流，false-直连拉流】
      })
    },
    monitorView (deviceNumber) {
      this.realPlay(deviceNumber)
    },
    /* 播放 */
    realPlay (deviceNumber) {
      const { player } = this
      player.playRealVideo({
        channelList: [{
          "id": deviceNumber,
          "isOnline": 1
        }]
      })
    }
  },
  beforeDestroy () {
    this.$bus.$off('TreeClick')
    this.$bus.$off('getFirstTreeData')
  }

}
</script>
<style lang="scss" scoped>
.el-main {
    min-height: 400px;
    height: 82vh;
    background: #fff;
    padding: auto 10px;
}
.video_box {
    width: 100%;
    height: 100%;
    min-height: 60%;
    position: relative;
}
@media screen and (max-width: 991px) {
    #player {
        width: 100%;
        height: 98%;
    }
}
@media screen and (min-width: 992px) {
    #player {
        width: 100%;
        height: 98%;
    }
}
.video_box_hender {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    height: 1.563vw;
    width: 95%;
    position: absolute;
    left: 50%;
    top: 6px;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
        .right_color {
            width: 0.521vw;
            height: 0.521vw;
            display: inline-block;
            border-radius: 50%;
            margin: 0 10px;
        }
    }
}
.stateColor1 {
    background-color: red;
}
.stateColor2 {
    background-color: blue;
}
</style>
