<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">今日告警</div>
    </div>

    <div class="charts" @mouseenter="stopAnimation" @mouseleave="startAnimation">
      <ja-init-chart class="jachart" :options="options" />
      <div class="chartimg"></div>
      <ul>
        <li v-for="(item,index) in alarmList" :key="index">
          <span>{{ item.value }}</span>
        </li>
        <div v-if="alarmList.length == 0">
          截至当前暂无设备告警信息！
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  props:{
    alarmList:{
      type:Array,
      default:()=>[]
    }
  },
  components: {
    JaInitChart
  },
  data() {
    return {
      // alarmList: []
      options:{},
      splitNumber: 20,
      isAdd: true,
      dataInterval:null
    }
  },
  created() {
    this.init()
    this.startAnimation()
  },
  beforeDestroy() {
    clearInterval(this.dataInterval);
  },
  watch:{
    alarmList(){
      this.init()
    }
  },
  methods: {
    stopAnimation() {
      clearInterval(this.dataInterval);
    },
    startAnimation() {
      this.dataInterval = setInterval(() => {
        if (this.isAdd == true) {
          this.splitNumber = this.splitNumber += 2
        } else if (this.isAdd == false) {
          this.splitNumber = this.splitNumber -= 2
        }
        if (this.splitNumber > 100) {
          this.isAdd = false
        } else if (this.splitNumber < 20) {
          this.isAdd = true
        }
        this.options.series[0].splitNumber = this.splitNumber;  // 控制旋转速度

      }, 150); // 每隔30毫秒更新一次
    },
    async init() {
      this.loading = false
      this.options = {
        legend: {
          type: 'plain',
          orient: 'vertical',
          top: '11%',
          left: '58%',
          icon: 'diamond',
          itemGap: 12,
          itemWidth: 7, // 设置宽度
          itemHeight: 7, // 设置高度
          textStyle: {
            color: '#DAFFE8',
            fontSize: this.ChartSize(14)
          }
        },
        color: ['#7DFF89', '#397AFF', '#FFEED5', '#FFBE8F', '#9776FF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '90',
            endAngle: '-269.9999',
            radius: '61%',
            splitNumber:20,
            center:['27%','45%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: '#002f64 ',
                width: 2
              }
            },
            axisLabel: {
              show: false
            },
            axisLine: {
                show: false,
                lineStyle: {
                    color: [
                        [0.1, '#7DFF89'],
                        [0.3, '#397AFF'],
                        [0.5, '#FFEED5'],
                        [0.8, '#FFBE8F'],
                        [1, '#9776FF']
                    ],
                    width: 10
                }
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['46%', '55%'],
            avoidLabelOverlap: false,
            hoverOffset:0,
            top: '-10%',
            left: '-46%',
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: this.alarmList
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  .charts{
    margin-top: 50px;
  }
  ul{
    right:65px!important;
    li{
      width: 220px!important;
    }
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 50px;
    height: 100%;
    position: relative;
    top: -5px;
    display: flex;
    .chartimg {
      z-index: 1;
      position: absolute;
      left: 34px;
      top: 18px;
      width: 126px;
      height: 126px;
      background-image: url('~@/assets/gd/chartbg2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
.jachart {
      z-index: 2;
      width: 360px;
      height: 180px;
    }
    ul {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 14px;
      width: 170px;
      padding-top: 21px;
      padding-right: 40px;
      position: absolute;
      right: 65px;
      >div{
        font-size: 14px;
        text-align: center;
        margin-top: 25px;
        width: 100%;
        color: #eee;
        width: 220px;
        padding: 20px 0;
      }
      li {
        font-size: 12.6px;
        color: rgba(255, 255, 255, 0.7);
        width: 220px;
        height: 21px;
        line-height: 21px;
        background-image: url('~@/assets/gd/ds_chatli.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 5px;
        text-align: right;
        padding-bottom: 12.5px;
        padding-right: 10px;
        align-items: center;
        span {
          color: #ffffff;
        }
      }
    }
  }
}
</style>