import echarts from '/node_modules/echarts';
function CreatecarChart(x, data) {
    let options = {
        grid: {
            left: '1%',
            right: '5%',
            bottom: '1%',
            height: '80%',
            containLabel: true
        },
        graphic: {
            elements: [
                {
                    type: 'text',
                    left: '5',
                    top: '15',
                    style: {
                        text: '单位 / 个',
                        fontSize: 13,
                        fill: '#818a98'
                    }
                }
            ]
        },
        xAxis: {
            type: 'category',
            data: x,
            axisLine: {
                lineStyle: {
                    color: '#1A3E47',
                    width: 1
                }
            },
            axisLabel: {
                color: '#fff',
                fontSize: 12
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: true,
                alignWithLabel: true,
                inside: true,
                lineStyle: {
                    // 设置刻度线样式
                    color: '#fff', // 刻度线颜色
                    width: 2 // 刻度线宽度
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false
            },
            axisLabel: {
                // interval: 8,
                color: '#818a98',
                fontSize: 12
            },
            splitLine: {
                lineStyle: {
                    color: '#1A3E47'
                }
            },
            axisTick: {
                show: false
            }
        },
        series: [
            {
                name: '人流量',
                data: data,
                animationDuration: 1500,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                label: {
                    normal: {
                        show: true,
                        color: '#ffffff',
                        textStyle: {
                            fontSize: 14
                        }
                    }
                },
                emphasis: {
                    focus: 'series', // 选中状态
                    itemStyle: {
                        color: '#FFCE14' // 选中时的颜色
                    },
                    label: {
                        color: '#FFCE14'
                    }
                },
                itemStyle: {
                    normal: {
                        color: '#18ff74',
                        borderColor: '#fff',
                        borderType: 'solid',
                        lineStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#18ff74' },
                                { offset: 1, color: '#12924b' }
                            ])
                        }
                    }
                },
                areaStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                {
                                    offset: 0,
                                    color: 'rgba(24,255,116,0.3)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(0,179,244,0)'
                                }
                            ],
                            false
                        ),
                        shadowColor: 'rgba(18,146,75, 0.9)',
                        shadowBlur: 20
                    }
                }
            },
            {
                type: 'bar',
                animation: false,
                barWidth: 1,
                hoverAnimation: false,
                data: data,
                tooltip: {
                    show: false
                },
                itemStyle: {
                    normal: {
                        color: '#1A3E47',
                        label: {
                            show: false
                        }
                    }
                },
                emphasis: {
                    focus: 'series', // 选中状态
                    itemStyle: {
                        color: '#FFCE14' // 选中时的颜色
                    }
                }
            }
        ]
    }
    return options
}

export default CreatecarChart