<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="详情" :width="`45%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='10' v-if="showDetail">
        <template #qpcode="">
          <div class="BigBox">
            <el-image fit="contain" class="cellImg" :src="detailData.qrCode" ref="cellImg" alt="" :preview-src-list="[detailData.qrCode]">
              <div slot="error" class="errorImg el-image__error">
                {{imgText}}
              </div>
              
            </el-image>
            <div class="right">
              <el-button type="primary" plain size="small" @click="refresh(detailData.id)">刷新二维码</el-button>
              <el-button type="primary" plain size="small" @click="print(detailData.qrCode)">打印二维码</el-button>
              <p>上次更新时间：{{detailData.qrCodeRefreshTime}}</p>
            </div>
          </div>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import { refreshQRcode } from '@/api'

export default {
  data() {
    return {
      showDetail: false,
      detailOptions: [
        // { key: 'a', label: '设备信息', isTitle: true, col: 22 },
        { key: 'pointName', label: '巡更点名称', },
        { key: 'pointCode', label: '巡更点编号', },
        { key: 'buildingName', label: '所属楼栋', },
        { key: 'floorName', label: '所属楼层', },
        { key: 'position', label: '巡更点位置', },
        { key: 'remark', label: '点位描述',col: 22, },

        { label: '巡更二维码', type: 'customize', customize: 'qpcode', col: 22, alignItems: 'flex-start' },

      ],
      detailData: {},
      initFileList: [],
      imgText:''
    }
  },
  methods: {
    rowDetail(row) {
      console.log('查看详情---',row,)
      this.detailData = { ...row, brandCode: `${row.brand}${row.model}`,qrCode:row.qrCodeUrl }
      this.showDetail = true
    },
    refresh(id) {
      this.detailData.qrCode = ''
      this.imgText = '加载中...'
      refreshQRcode(id).then(({data}) => {
        let {refreshTime,url} = data
        this.detailData.qrCode =url 
        this.detailData.qrCodeRefreshTime = refreshTime
        console.log(this.detailData);
        this.imgText = '加载失败'
      }).catch(err=>{
        this.imgText = '加载失败'
      })
    },
    // 打印二维码
    print(imgSrc){
      let iframe = document.createElement('IFRAME')
      let doc = null
      iframe.setAttribute('class', 'print-iframe')
      iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:-500px;top:-500px;')
      document.body.appendChild(iframe)
      doc = iframe.contentWindow.document
      // 取一个不重复的方法名称，可以随机字符串
      doc.___imageLoad___ = function () {
          iframe.contentWindow.print()
          if (navigator.userAgent.indexOf('MSIE') > 0) {
              document.body.removeChild(iframe)
          }
      }
      doc.write('<div style="height: 100%;width: 100%;">' + `<img src="${imgSrc}" style="max-height:100%;max-width: 100%;" onload="___imageLoad___()"/>` + '</div>')
      doc.close()
      iframe.contentWindow.focus()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .cellImg {
  height: 120px;
  width: 120px;
  background: #f5f7fa;
  > img {
    height: 120px;
    display: flex;
    margin: auto;
  }
}
::v-deep .errorImg {
  width: 100%;
}
.BigBox {
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
  .right {
    margin-left: 20px;
  }
}
</style>