<template>
  <div class="box_bg">
    <WPanelEntry
      title="房屋出租率"
      :selectData="false"
      v-loading="loading"
      element-loading-text="页面加载中,请稍等..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <slot>
        <NoData v-if="noDataText" :text="noDataText" />
        <div class="charts">
          <ja-init-chart
            class="jachart"
            :options="options"
            :chartPlay="true"
            :chartPlaymodule="true"
            v-if="!noDataText"
          />
        </div>
      </slot>
    </WPanelEntry>

    <!-- <chartDialog
      ref="dialog"
      :title="title"
      :columns="columns"
      :getDatamethod="getElectricityMeter"
    >
      传递图表给弹窗
      <template v-slot:chart>
        <ja-init-chart class="jachart" :options="options" v-if="!noDataText" />
      </template>
    </chartDialog> -->
  </div>
</template>

<script>
import echarts from '/node_modules/echarts'; 
import { getElectricityMeter,getcomprehensive } from '@/api'
import JaInitChart from '@/components/ja-init-chart/index'
import NoData from '@/components/noData'
import WPanelEntry from '../../WPanelEntry/index.vue'
import chartDialog from '../../chartDialog/index.vue'
export default {
  title: '房屋出租率',
  name: 'rentalVacancyRate',
  components: {
    JaInitChart,
    NoData,
    WPanelEntry,
    chartDialog
  },
  data() {
    return {
      options: {},
      info:{},
      loading: true,
      title: '房屋计租率',
      getElectricityMeter,
      columns: [
        { label: '乙方', prop: 'deviceCode' },
        { label: '单元', prop: 'lastReading' },
        { label: '合同期限', prop: 'currentReading' }
      ],
      selectedDataIndex: 0,
      selectedData: '',
      interval: null,
      xAxisData: [],
      noDataText:'',
      dataInterval:null
    }
  },
  mounted() {
    this.startDataInterval()
    this.updateSelectedData()
    // this.interval = setInterval(this.updateSelectedData, 5000);
  },
  beforeDestroy() {
    if(this.dataInterval){
      clearInterval(this.dataInterval);
    }
  },
  methods: {
     startDataInterval(){
      this.init()
      this.dataInterval = setInterval(() => {
            this.init()
      }, this.TIMER_INTERVAL);
    },
    async init() {
       await getcomprehensive().then((res) => {
        this.info = res.data
        this.xAxisData = res.data.rentalRate.reverse()
        this.loading = false
      })
      let dateData=this.info.yearAndMonth.reverse()
      let   datacoords = [
          {
            coords: [],
          },
        ];
      for (var i = 0; i < dateData.length; i++) {
        datacoords[0].coords.push([dateData[i],  this.xAxisData[i]]);
      }
      let lineData=this.xAxisData.map((item,i)=>{
       let obj={
            yAxis: item+6,
            xAxis: dateData[i],
            itemStyle: {
                normal: {
                    color: '#12FDF0',
                },
            },
        }
        return obj
      })
      this.options = {
        grid: {
          left: '1%',
          right: '5%',
          bottom: '1%',
          height: '80%',
          containLabel: true
        },
        graphic: {
          elements: [
            {
              type: 'text',
              left: '5',
              top: '15',
              style: {
                // text: '单位 / 个',
                fontSize: 13,
                fill: '#818a98'
              }
            }
          ]
        },
        xAxis: {
          type: 'category',
          data:dateData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: '#1A3E47',
              width: 1
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 12
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
            inside: true, 
            lineStyle: {
              // 设置刻度线样式
              color: '#fff', // 刻度线颜色
              width: 2 // 刻度线宽度
            }
          }
        },
        yAxis: {
          max:100,
          type: 'value',
          axisLine: {
            show: false
          },
          axisLabel: {
            // interval: 8,
            color: '#818a98',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: '#1A3E47'
            }
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '人流量',
            data: this.xAxisData,
            animationDuration: 1500,
            type: 'line',
            smooth: false,
            symbol: 'circle',
            symbolSize: 6,
            label: {
              normal: {
                distance:10, 
                show: true,
                color: '#ffffff',
                textStyle: {
                  fontSize: 14
                }
              }
            },
            emphasis: {
              focus: 'series', // 选中状态
              itemStyle: {
                color: '#FFCE14' // 选中时的颜色
              },
              label: {
                color: '#FFCE14'
              }
            },
            itemStyle: {
              normal: {
                color: '#39f7f7',
                borderType: 'solid',
                lineStyle: {
                  color: "#23BEFC"
                }
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#1CA7FF20'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,179,244,0)'
                    }
                  ],
                  false
                ),
                shadowColor: '#1CA7FF00',
                shadowBlur: 20
              }
            }
          },
          {
            type: 'bar',
            animation: false,
            barWidth: 1,
            hoverAnimation: false,
            data: this.xAxisData.map(item=>item+6),
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                color: '#074E53',
                label: {
                  show: false
                }
              }
            },
            emphasis: {
              focus: 'series', // 选中状态
              itemStyle: {
                color: '#FFCE14' // 选中时的颜色
              }
            },
            markPoint: {
                symbol: 'circle',
                symbolSize: 2,
                label: {
                    show: false,
                },
                data: lineData
            },
          },
          {
            showSymbol: false,
            name: "",
            type: "lines",
            polyline: true,
            smooth: false,
            coordinateSystem: "cartesian2d",
            zlevel: 1,
            effect: {
              show: true,
              smooth: true,
              period: 6,
              symbolSize: 6,
               symbol:'triangle',
              trailLength :0.5
            },
            lineStyle: {
              color: "#39f7f7",
              width: 1,
              opacity: 0.2,
              curveness: 0.2,
              cap: "round",
            },
            data:  datacoords,
          },
        ]
      }
    },
    openDialog() {
      this.$refs.dialog.showDialog()
    },
    updateSelectedData() {
      this.selectedDataIndex = this.selectedDataIndex % this.xAxisData.length
      this.selectedData = this.xAxisData[this.selectedDataIndex]
      // this.init();
    }
  },
  watch: {
    // '$store.state.token': function () {
    //   if (this.$store.state.token) {
    //     this.noDataText = ''
    //     this.init()
    //   } else {
    //     this.noDataText = '-- 请求数据缺少token --'
    //     this.loading = false
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.box_bg {
  // background-image: url('~@/assets/images/screen/human_bg.png');
}
// .entry_left {
.charts {
  width: 100%;
  height: 100%;
  display: inline-block;
}
// }
</style>
