<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="50%"
                   :title="title"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="{}"
                     v-model="rowForm"
                     :formArray="detailOptions"
                     :allDisabled="true"
                     v-if="show">

            </ja-form>
            <div class="dialog-footer">
                <el-button @click="saveReject">驳 回</el-button>
                <el-button type="primary"
                           @click="savePass">通 过</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { editDecApplyInfoList } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'input', label: '报装人', key: 'shipper', labelWidth: '120px' },
                { input_type: 'input', label: '报装人手机号码', key: 'shipperPhone', labelWidth: '120px' },
                { input_type: 'input', label: '所属楼栋', key: 'buildingName', labelWidth: '120px' },
                { input_type: 'input', label: '所属楼层', key: 'floorName', labelWidth: '120px' },
                { input_type: 'input', label: '所属租赁单元', key: 'roomName', labelWidth: '120px' },
                { input_type: 'input', label: '装修开始时间', key: 'startTime', labelWidth: '120px' },
                { input_type: 'input', label: '装修结束时间', key: 'endTime', labelWidth: '120px' },
                { input_type: 'input', label: '施工单位', key: 'companyName', labelWidth: '120px' },
                { input_type: 'input', label: '施工负责人', key: 'decPerson', labelWidth: '120px' },
                { input_type: 'input', label: '负责人电话', key: 'decPhone', labelWidth: '120px' },
                { input_type: 'input', label: '装修人数', key: 'decPersonNumber', labelWidth: '120px' },
                { input_type: 'input', label: '装修人员信息', key: 'decPersonList', labelWidth: '120px' },


                { input_type: 'input', label: '提交时间', key: 'createTime', labelWidth: '120px' },
            ],
            title: null,
            rowForm: {},
            show: false,
        }
    },
    methods: {
        init(row) {
            this.title = '装修申请审批'
            this.rowForm = { ...row }
            this.show = true

        },
        savePass() {
            let { id } = this.rowForm
            editDecApplyInfoList({ id, applyStatus: 1 }).then(() => {
                this.$message.success('已通过')
                this.getInit()
                this.show = false
            })
        },
        saveReject() {
            let { id } = this.rowForm
            editDecApplyInfoList({ id, applyStatus: 2 }).then(() => {
                this.$message.success('已驳回')
                this.getInit()
                this.show = false
            })
        }
    }
}
</script>
<style scoped>
.dialog-footer {
    display: flex;
    justify-content: flex-end;
}
</style>
