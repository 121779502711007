<template>
  <div>
    <JaTable v-model="filterData" :conditionData="conditionData" :columns="alarmThresholdColumn" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @rowDetail="normalEvent" @getInit="getInit" @search="search" @reset="reset"></JaTable>

    <detail ref="detail" />
  </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import table from '@/views/mixins/table'
import Detail from './childComp/Detail'
import { alarmRecordList,getAlarmType,getCategory, } from '@/api/module/alarm.js'
import { reqDictData } from '@/api'

export default {
  title: '告警记录',
  mixins: [table],
  name: 'alarmThreshold',
  components: {
    JaTable,
    Detail
  },
  data() {
    return {
      // 搜索
      conditionData: [
        {
          input_type: 'date',
          type:'datetimerange',
          attribute: {
            size: 'small'
          },
          startPlaceholder:"告警开始",
          endPlaceholder:"告警结束",
          value: 'time',
        },
        {
          input_type: 'select',
          attribute: {
            placeholder: '告警类型',
            size: 'small'
          },
          key: 'alarmTypeId',
          options: []
        },
        // {
        //   input_type: 'select',
        //   attribute: {
        //     placeholder: '告警等级',
        //     size: 'small'
        //   },
        //   key: 'alarmLevel',
        //   options: []
        // },
        {
          input_type: 'select',
          attribute: {
            placeholder: '处理状态',
            size: 'small'
          },
          key: 'status',
          options: [{label:"待处理",value:0},{label:"已处理",value:1}]
        },
        {
          input_type: 'input',
          attribute: {
            placeholder: '告警名称',
            size: 'small'
          },
          key: 'alarmName'
        },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' }
      ],
      // 表格
      alarmThresholdColumn: [
        { label: '告警时间', prop: 'alarmTime' },
        { label: '告警名称', prop: 'alarmName' },
        { label: '告警类型', prop: 'alarmLevelName' },
        { label: '设备大类', prop: 'categoryName' },
        // { label: '告警等级', prop: 'alarmLevelName' },
        { label: '告警设备', prop: 'alarmDeviceName' },
        { label: '所在楼栋', prop: 'alarmBuildingName' },
        { label: '所在楼层', prop: 'alarmFloorName' },
        // { label: '告警位置', prop: 'alarmPosition' },
        { label: '告警描述', prop: 'alarmDesc' },
        { label: '处理状态', prop: 'statusName' },
        {
          label: '操作',
          input_type: 'button',
          width: 160,
          list: [
            {
              text: '详情',
              method: 'rowDetail',
              limit: 'envMonitoring::alarmThreshold::detail'
            }
          ]
        }
      ],
      tableData: [],
      total: 0,
      // 搜索参数
      filterData: {
        pageIndex: 1,
        pageSize: 10
      }
    }
  },
  async created() {
    const {data:alarmTypeResult} =  await getAlarmType()
    const {data:alarmLevelResult} =  await reqDictData('/event_level_edit')
    const alarmOption = alarmTypeResult.map(item=>{
      return {label:item.alarmType,value:item.alarmType }
    })
    const alarmLevelOption = alarmLevelResult.map(item=>{
      return {label:item.dictLabel,value:item.dictValue}
    })
    this.conditionData.forEach(item=>{
      if(item.key==='alarmTypeId') item.options = alarmOption
      if(item.key==='alarmLevel') item.options = alarmLevelOption
    })
  },
  methods: {
    getInit() {
      this.loading = true
      alarmRecordList(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.tableData.forEach(item => {
            if(item.status == 1){
              item.statusName = '已处理'
            }
          })
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    search() {
      if (this.filterData.time) {
        this.filterData.startTime = this.filterData.time[0]
        this.filterData.endTime = this.filterData.time[1]
      }
      this.getInit()
    },
    formatter(val, key) {
      if (key === 'status') {
        return ['启用', '停用'][val || 0]
      }
      return val || ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .cellImg {
  display: flex;
  margin: auto;
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
::v-deep .errorImg {
  width: 100%;
}
</style>
