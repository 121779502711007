<template>
    <div class="iocbox">
       <IOC></IOC>
    </div>
</template>
<script>
import IOC from '../../../../dsWindow/IOC/index.vue'
export default {
    title: '综合管理态势',
    name: 'wismanagement',
    components:{
        IOC
    },
    data () {
        return {
        }
    },
}
</script>
<style lang="scss" scoped>
.iocbox {
   height: 100%;
   width: 100%;
}
</style>
