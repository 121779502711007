<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="查看详情" :width="`50%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width="8" v-if="showDetail">
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showDetail: false,
      detailOptions: [
        { label: '联动类型', key: 'linkageTypeName' },
        { key: 'a', label: '触发设备', isTitle: true, col: 22 },
        {
          type: 'table',
          noSearch: true,
          showPage: false,
          key: 'triggerDevice',
          col: 24,
          columns: [
            { label: '设备小类', prop: 'deviceSubcategoryName' },
            { label: '设备名称', prop: 'deviceName' },
            { label: '所在楼栋', prop: 'deviceBuildingName' },
            { label: '所在楼层', prop: 'deviceFloorName' },
            { label: '解发条件', prop: 'conditionName' },
            { label: '判断规则', prop: 'ruleName' },
            { label: '条件值', prop: 'triggerValue' }
          ]
        },
        { key: 'a', label: '联动设备', isTitle: true, col: 22 },
        {
          type: 'table',
          noSearch: true,
          showPage: false,
          key: 'linkageDevice',
          col: 24,
          columns: [
            { label: '设备小类', prop: 'deviceSubcategoryName' },
            { label: '设备名称', prop: 'deviceName' },
            { label: '所在楼栋', prop: 'deviceBuildingName' },
            { label: '所在楼层', prop: 'deviceFloorName' },
            { label: '动作', prop: 'actionName' },
            { label: '动作值', prop: 'linkageValue' }
          ]
        }
      ],
      detailData: {},
      categoryLink_idObj: {}
    }
  },
  methods: {
    rowDetail(row) {
      this.showDetail = false
      this.detailData = row
      this.showDetail = true
    }
  }
}
</script>
