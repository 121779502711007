<template>
    <!-- nowFilterData: filterData -->
    <div :class="['app-container',isFromForm?'fromForm formTable':'formTable']">
        <!-- 操作栏 -->
        <div class="form" v-if="!noSearch">
            <el-form :inline="true" v-bind="formAttribute || {}">
                <div style="display: flex;justify-content:space-between;">
                    <div :class="[corIndex==0?'left':'right']" style="display:flex;" v-for="(corItem,corIndex) in ['左边','右边']" :key="corIndex">
                        <el-form-item v-for="(item,index) in conditionData" :key="index" :label="hasLabel(item)?item.label:''" :prop="item.prop" v-if="corIndex==0 ?((item.normalBtn?!({...normalBtnList[item.normalBtn],...item}.pos):!item.rightPos) && !item.hide && (item.limit?isAuth(item.limit):true)):((item.normalBtn?{...normalBtnList[item.normalBtn],...item}.pos:item.rightPos) && !item.hide && (item.limit?isAuth(item.limit):true))">
                            <template v-if="item.slotName">
                                <slot :name="item.slotName"></slot>
                            </template>
                           
                            <template v-else>
                                <div v-if="item.input_type === 'text'" :style="{width:item.styleWidth}">{{item.text}}</div>
                                <!-- 下拉 -->
                                <!-- <p style="color: red">{{item.key}} - {{nowFilterData}}</p> -->
                                <el-select v-if="item.input_type === 'select'" v-model="nowFilterData[item.key]" v-bind="item.attribute?{size: 'small',...item.attribute}: {size: 'small'}" v-on="item.events || {}" @change="itemEvent(item.method, item.key, nowFilterData[item.key],item)" clearable filterable :style="{width:item.styleWidth}">
                                    <el-option v-for="dict in item.options" :key="dict.value" :label="dict.label" :value="dict.value" :class="nowFilterData[item.key] === dict.value ? 'selected' : ''" />
                                </el-select>
                                <!-- 搜索 -->
                                <el-input v-if="item.input_type === 'input'" v-model="nowFilterData[item.key]" :maxlength="item.maxlength?item.maxlength:50" v-bind="item.attribute?{size: 'small',...item.attribute}: {size: 'small'}" @input="item.valueType=='number'?nowFilterData[item.key]=nowFilterData[item.key].replace(/[^\d]/g,''):''" @keyup.enter.native="getInit" :style="{width:item.styleWidth}" />
                                
                                <!-- 日期 -->
                                <template v-if="item.input_type === 'date'">
                                    <el-date-picker @change="changeTime(item.label, item.method)" v-model="nowFilterData[item.value]" v-bind="item.attribute?{size: 'small',...item.attribute} : {size: 'small'}" :type="item.type||'date'" :disabled="item.disabled" :format="item.format" :value-format="item.valueFormat ||'yyyy-MM-dd HH:mm:ss'" :placeholder="item.placeholder||'选择日期'" :start-placeholder="item.startPlaceholder||'开始时间'" :end-placeholder="item.endPlaceholder||'结束时间'" :range-separator="item.rangeSeparator ? item.rangeSeparator: '至' " :default-time="item.type == 'datetimerange'?(item.defaultTime||['00:00:00','23:59:59']):(item.defaultTime)" :picker-options="item.pickerOptions" :style="{width:item.styleWidth}">
                                    </el-date-picker>
                                </template>

                                <!-- 按钮 -->
                                <el-button class="search-button" v-if="(item.normalBtn ||item.input_type === 'button')&&(item.limit?isAuth(item.limit):true)" :size="item.normalBtn?normalBtnList[item.normalBtn].size:(item.size || 'small')" v-bind="item.normalBtn?{...normalBtnList[item.normalBtn],...item,...item.attribute}:item.attribute" @click="itemEvent(item.normalBtn?{...normalBtnList[item.normalBtn],...item}.method:item.method,item)">
                                    {{item.normalBtn?(item.text || item.label||normalBtnList[item.normalBtn].text):(item.text || item.label)}}
                                </el-button>

                                <!-- 多层选择框 -->
                                <el-cascader v-if="item.input_type === 'cascader'" style="width:45%" v-model="nowFilterData[item.key]" :props="item.props||{value:'label'}" :options="item.options" :show-all-levels="item.showAllLevels||true" :placeholder="item.placeholder||`请选择${item.label}`" @change="itemEvent(item.method,item)" v-on="item.events || {}" v-bind="item.attribute" :style="{width:item.styleWidth}"></el-cascader>
                            </template>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="right">
                <slot name="right"></slot>
            </div>
        </div>
        <div class="table">
            <!-- 表头 -->

            <div class="tableHead" v-if="tableHeadButtons && tableHeadButtons.length>0">
                <el-button v-for="(item,index) in tableHeadButtons" :key="index" :size="item.size || 'small'" v-bind="item.attribute?{size: 'small',...item.attribute} : {size: 'small'}" @click="itemEvent(item.method,item)">
                    {{item.text || item.label}}
                </el-button>
            </div>
            <slot name="tableTop"></slot>
            <slot name="tableSlot" v-if="isTableSlot"></slot>

            <!-- 表格内容 -->
            <!-- <p style="color: green">{{selectId}}</p> -->
            <el-table class="ja-table" :show-summary="showSummary" :highlightCurrentRow="highlightCurrentRow" :summary-method="getSummaries" v-loading="loading" :data="tableData" :border="border" :row-key="rowKey" :max-height="table_MaxHeight" :tree-props="treeProps" @select="handleSelectItem" @select-all="handleSelectionAll" @selection-change="handleSelectionChange" @current-change="handleCurrentChange" v-bind="tableAttribute || {}" v-on="tableEvents || {}" ref="jaTable" v-else>
                <el-table-column type="selection" v-if="showSelect"></el-table-column>
                <el-table-column type="index" :align="align || 'center'" :label="index_label?index_label:'序号'" v-if="showIndex" :width="60" :index="indexMethod" :fixed="indexFiexd"></el-table-column>
                <el-table-column v-for="(item,index) in columns" :key="index" :label="item.label" :prop="item.prop" :width="item.width"  :align="item.align?item.align:'center'" :fixed="item.list?'right':item.fixed" v-bind="{showOverflowTooltip:true,align:'center',...item.attribute}" v-on="item.events || {}" v-if="!item.hide">

                    <!-- 多级表头 临时，后面要删 -->
                    <el-table-column
                    v-for="subCol in item.children"
                    :key="subCol.prop"
                    :prop="subCol.prop"
                    :label="subCol.label"
                    :width="subCol.width"
                    :align="item.align?item.align:'center'"
                    :header-align="item.align?item.align:'center'"
                    v-bind="item.columnAttrs || {}"
                    show-overflow-tooltip>

                    <template v-slot:header="{column}">
                    <span>{{ column.label }}</span>
                    </template>

                    <template v-slot="scope">
                    <div v-if="subCol.type === 'node'" class="OPZTable__cell__node">
                        <slot :name="`cell${item.prop}`" v-bind="scope" />
                    </div>
                    </template>

                    </el-table-column>
                    <!-- 多级表头 end -->
                   
                    <template slot-scope="scope">
                        <!-- switch -->
                        <!-- <span>{{scope.row}}</span> -->
                        <el-switch @change="itemEvent(item.method,scope.row,$event[0])" v-if="item.input_type === 'switch'" :disabled="item.disabled" :active-value="item.activeValue" :inactive-value="item.inactiveValue" v-model="scope.row[item.prop]"></el-switch>
                        <slot v-if="item.slotName" :name="item.slotName" :row="scope.row" :prop="item.prop" :$index="scope.$index"></slot>
                        <template v-else-if="item.column_type && (allDisabled || scope.row[`${item.prop}Disabled`]||item.disabled)">
                            <div v-if="item.column_type==='select'">
                                {{ scope.row[item.prop] | optionsFormat(item) }}
                            </div>
                            <div v-else>{{scope.row[item.prop]}}</div>
                        </template>
                        <!-- 输入框 -->
                        <template v-else-if="item.column_type==='input'">
                            <!-- TODO -->
                            <!-- <input :style="{width:item.styleWidth}" :type="item.input_type?item.input_type:'text'" v-model="scope.row[item.prop]" :disabled=" allDisabled || scope.row[`${item.prop}Disabled`]||item.disabled || scope.row.status===2? true:false" class="input_text" @input="$emit('inputEvent',scope.row)" v-on="item.events || {}" v-bind="item.attribute||{}" :maxlength="item.maxlength?item.maxlength:50" :placeholder="item.placeholder||''" @change="itemEvent(item.method, scope.$index)"> -->
                            <el-input :style="{width:item.styleWidth}" :type="item.input_type?item.input_type:'text'" v-model="scope.row[item.prop]" :disabled=" allDisabled || scope.row[`${item.prop}Disabled`] || item.disabled || scope.row.status===2? true:false" class="input_text" @input="$emit('inputEvent',scope.row)" v-on="item.events || {}" v-bind="item.attribute||{}" :maxlength="item.maxlength?item.maxlength:50" :placeholder="item.placeholder||''" @change="itemEvent(item.method, scope.$index)" :clearable="item.clearable == false ? false : true">
                            </el-input>
                        </template>
                        <template v-else-if="item.column_type==='number'">
                             <el-input-number :style="{width:item.styleWidth}" v-model="scope.row[item.prop]" :disabled="allDisabled || scope.row[`${item.prop}Disabled`]||item.disabled" :min="item.min" :max="item.max" :precision="item.precision || '2'" v-bind="item.attribute||{}" @blur="$emit(item.blurMethod,item, scope.row,scope.$index)" @change="itemEvent(item.method, scope.$index)" :clearable="item.clearable == false ? false : true"></el-input-number>
                        </template>
                        <!-- 时间 -->
                        <template v-else-if="item.column_type==='time'">
                            <el-time-picker :is-range="item.isRange" v-model="scope.row[item.prop]" :disabled="allDisabled || scope.row[`${item.prop}Disabled`]||item.disabled" :picker-options="item.pickerOptions" :value-format="item.valueFormat" :format="item.format" :default-value="item.defaultValue" :placeholder="item.placeholder||'选择时间'" :start-placeholder="item.startPlaceholder||'开始时间'" :end-placeholder="item.endPlaceholder||'结束时间'" :range-separator="item.rangeSeparator ? item.rangeSeparator: '至' ">
                            </el-time-picker>
                        </template>
                        <!-- 日期 -->
                        <template v-else-if="item.column_type==='date'">
                            <el-date-picker @change="itemEvent(item.method, item, scope.row)" :type="item.type||'date'" :disabled="allDisabled || scope.row[`${item.prop}Disabled`]||item.disabled" v-model="scope.row[item.prop]" style="width: 100%;" :format="item.format" :value-format="item.valueFormat ||'yyyy-MM-dd HH:mm:ss'" :placeholder="item.placeholder||'选择日期'" :start-placeholder="item.startPlaceholder||'开始时间'" :end-placeholder="item.endPlaceholder||'结束时间'" :range-separator="item.rangeSeparator ? item.rangeSeparator: '至' " :default-time="item.defaultTime && item.type == 'datetimerange'?item.defaultTime||['00:00:00','23:59:59']:item.defaultTime||'00:00:00'" :picker-options="item.pickerOptions">
                            </el-date-picker>
                        </template>

                        <!-- 下拉框 -->
                        <!-- <span style="color:blueviolet">{{scope.row.options}}</span> -->
                        
                        <template v-else-if="item.column_type==='select' && item.aloneCont==='device_action_id'">
                            <el-select @focus="itemEvent(item.method2, item, scope.row)" @change="itemEvent(item.method, item, scope.row)" :filterable="item.filterable|| true" :allow-create="item.allowCreate||false" clearable :multiple="item.multiple||false" :disabled="allDisabled || scope.row[`${item.prop}Disabled`]||item.disabled" v-model="scope.row[item.prop]" :placeholder="item.placeholder||item.label" :loading="item.loading?item.loading:false" :loading-text="item.loadingText?item.loadingText:'加载中...'" :no-match-text="item.noMatchText?item.noMatchText:'无匹配的项'" :no-data-text="item.noDataText?item.noDataText:'无数据'">
                                <el-option v-for="(list,index2) in scope.row.options" :key="index2+0.01" :label="list.device_action" :value="list.action_id" :disabled="list.disabled"></el-option>
                            </el-select>
                        </template>

                        <!-- <template v-else-if="item.column_type==='select' && item.aloneCont==='device_action_id'">
              <span style="color:blueviolet">{{scope.row['device_action_id']}} - {{scope.row['device_action']}}</span>
              <el-select @focus="itemEvent(item.method2, item, scope.row)"
                         @change="itemEvent(item.method, item, scope.row)"
                         :filterable="item.filterable|| true"
                         :allow-create="item.allowCreate||false"
                         clearable
                         :multiple="item.multiple||false"
                         :disabled="item.disabled"
                         v-model="scope.row[item.prop]"
                         :placeholder="item.placeholder||item.label"
                         :loading="item.loading?item.loading:false"
                         :loading-text="item.loadingText?item.loadingText:'加载中...'"
                         :no-match-text="item.noMatchText?item.noMatchText:'无匹配的项'"
                         :no-data-text="item.noDataText?item.noDataText:'无数据'">
                <el-option v-for="(list,index2) in item.options"
                           :key="index2+0.01"
                           :label="list.label"
                           :value="list.value"></el-option>
              </el-select>
            </template> -->
                        
                        <template v-else-if="item.column_type==='select' && item.operating!='focus'">
                            <el-select @change="itemEvent(item.method, item, scope.row[item.prop] , index,scope.$index)" :filterable="item.filterable|| true" :allow-create="item.allowCreate||false" clearable :multiple="item.multiple||false" :disabled="allDisabled || scope.row[`${item.prop}Disabled`]||item.disabled" v-model="scope.row[item.prop]" :placeholder="item.placeholder||item.label" :loading="item.loading?item.loading:false" :loading-text="item.loadingText?item.loadingText:'加载中...'" :no-match-text="item.noMatchText?item.noMatchText:'无匹配的项'" :no-data-text="item.noDataText?item.noDataText:'无数据'">
                                <el-option v-for="(list,index2) in (item.options || scope.row[`${item.prop}Options`])" :key="index2+0.01" :label="list.label" :value="list.value" :disabled="list.disabled"></el-option>
                            </el-select>
                        </template>
                        <!-- <span v-else>{{valueFormat(scope.row[item.prop], item.prop, scope.row)}}</span> -->
                        <!-- 按钮(多) -->
                        <template v-else >
                            <span v-if="(item.isButton || item['input_type']==='button') && item.list">
                                <!-- lxs插入21/11/30 -->
                                <slot name="operateSlot" :scope="scope.row"></slot>
                                <template v-for="(button,index) in item.list">
                                    <el-button  :size="button.size || 'mini'" :type=" button.type ||'text'" :plain="button.plain" :key="index" :disabled="(allDisabled || item.disabled) || (button.disabled || false)" :icon="button.icon ? button.icon : ''" :circle="button.circle?true:false" :class="button.class||''" v-if="editDataFormat(scope.row, button, scope) && (button.limit?isAuth(button.limit):true)" @click="valueEvent(scope.row, button.method, scope, button)">
                                        <!-- {{button.text || ''}} -->
                                        {{ button.Format ? btnTextFormat(scope.row, button) : button.text || '' }}
                                        <!-- {{button.text || btnTextFormat(scope.row, button) || ''}} -->
                                    </el-button>
                                </template>
                            </span>
                            <!-- 默认的显示text -->
                            <span v-if="(item['input_type']==='text' || !item['input_type']) && !item.isButton">{{valueFormat(scope.row[item.prop], item.prop, scope.row)}}
                                <i v-if="(scope.row.status !== 2)&&(scope.row.source === 1 && item['prop'] === 'payProject')" class="el-icon-delete" style="color:red;margin-left: 10px;cursor:pointer" @click="$emit('iDeleteEvent',scope.row)"></i>
                            </span>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <!-- lxs插入21/11/30 -->
            <slot name="tableBottomSlot"></slot>
        </div>
        <!-- 分页 -->
        <div class="ja-pagination" v-if="showPage">
            <div>
                <slot name="bottomLeft"></slot>
            </div>
            <el-pagination :class="{'hidden':pgHide}" :background="pgBackground" :pager-count="pagerCount" :current-page.sync="nowFilterData.pageIndex" :page-size.sync="nowFilterData.pageSize" :layout="layout" :page-sizes="pageSizes" :total="total" @size-change="sizeChange" @current-change="currentChage" v-bind="pgAttribute" v-on="pgEvents">
            </el-pagination>
        </div>
    </div>
</template>

<script>
/**
 *  conditionData: 表格上方的搜索/选择操作栏：input，下拉选择，按钮
 * columns: 表头数据
 */
import { outPutCommonExcell } from '@/api'

export default {
    name: 'ja-table',
    data () {
        return {
            nowFilterData: this.value,
            // 规定顶部常见按钮样式
            normalBtnList: {
                search: {
                    type: 'primary',
                    icon: 'el-icon-search',
                    text: '查询',
                    size: 'small',
                    method: 'search'
                },
                add: {
                    type: 'primary',
                    icon: 'el-icon-plus',
                    text: '新增',
                    size: 'small',
                    plain: true,
                    method: 'append'
                },
                delete: {
                    type: 'danger',
                    icon: 'el-icon-delete',
                    text: '删除',
                    size: 'small',
                    plain: true,
                    pos: 'right',
                    method: 'deleteRows'
                },
                reset: {
                    type: 'default',
                    text: '重置',
                    size: 'small',
                    plain: true,
                    method: 'reset'
                },
                download: {
                    type: 'warning',
                    icon: 'el-icon-download',
                    text: '导出',
                    size: 'small',
                    plain: true,
                    method: 'outPutExcellFun'
                },
                import: {
                    type: 'success',
                    icon: 'el-icon-upload2',
                    text: '导入',
                    size: 'small',
                    plain: true,
                    method: 'importForm'
                },
                /**
                 * 2021年11月16日
                 * 添加审核设置    做打杂
                 */
                toExamine: {
                    type: 'primary',
                    text: '审核设置',
                    size: 'small',
                    method: 'toExamineEvent'

                },
                /**
                 * 2021年11月16日
                 * 添加批量审核
                 */
                batchToExamine: {
                    type: 'primary',
                    text: '批量通过审核',
                    size: 'small',
                    method: 'batchToExamineEvent'
                }

            }
        }
    },
    props: {
        // 单选高亮
        highlightCurrentRow: {
            type: Boolean,
            default: false
        },
        // 表单表头序号的名称
        index_label: {
            type: String,
            default: '序号'
        },
        // 表格默认选中
        selectId: {
            type: String,
            default: ''
        },
        // 表单属性
        formAttribute: {
            type: Object,
            default: () => { }
        },
        // 是否没有搜索头
        noSearch: {
            type: Boolean,
            default: false
        },
        // 是否有分页
        showPage: {
            type: Boolean,
            default: true
        },
        // 条件数组
        conditionData: {
            type: Array,
            default: () => []
        },
        align: {
            type: String,
            default: 'center'
        },
        // 条件选定参数
        value: {
            type: Object,
            default: function () {
                return {
                    pageIndex: 1,
                    pageSize: 5
                }
            }
        },
        // 按钮根据判断条件显示的文本
        btnTextFormat: {
            type: Function,
            default: function (row, button) {
                return button.text
            }
        },
        // 是否显示序号
        showIndex: {
            type: Boolean,
            default: true
        },
        // table加载动画
        loading: {
            type: Boolean,
            ault: false
        },
        // 尾部是否统计
        showSummary: {
            type: Boolean,
            ault: false
        },
        // 尾部统计条件
        chooseArr: {
            type: Array,
            default: () => []
        },
        // table的border
        border: {
            type: Boolean,
            default: false
        },
        showSelect: {
            type: Boolean,
            default: false
        },
        // table属性
        tableAttribute: {
            type: Object,
            default: () => { }
        },
        // table事件集合
        tableEvents: {
            type: Object,
            default: () => { }
        },
        // table的title
        columns: {
            type: Array,
            default: () => []
        },
        // table数据
        tableData: {
            type: Array,
            default: () => []
        },
        // table内容格式转换
        valueFormat: {
            type: Function,
            default: function (val, key, row, scope) {
                // console.log('val--->', val, key)
                return val
            }
        },
        // 检测当前行显示的内容（如：根据条件显示待处理、已处理）
        // 可根据自定义的key值判断
        editDataFormat: {
            type: Function,
            default: function (row, item) {
                return true
            }
        },
        // 是否隐藏分页
        pgHide: {
            type: Boolean,
            default: false
        },
        // 是否为分页按钮添加背景色
        pgBackground: {
            type: Boolean,
            default: true
        },
        // table数据总数
        total: {
            required: false,
            type: Number
        },
        // 每页显示条目个数
        pageSizes: {
            type: Array,
            default () {
                return [10, 20, 30, 50]
            }
        },
        // 组件布局，子组件名用逗号分隔
        layout: {
            type: String,
            default: 'total, sizes, prev, pager, next, jumper'
        },
        //最大页码
        pagerCount: {
            type: Number,
            default: 7
        },
        // 分页属性
        pgAttribute: {
            type: Object,
            default: () => { }
        },
        // 分页时间集合
        pgEvents: {
            type: Object,
            default: () => { }
        },
        treeProps: {
            type: Object,
            default: () => { }
        },
        rowKey: {
            type: String
        },
        // 是否来自于表单页
        isFromForm: {
            type: Boolean,
            default: false
        },
        // 表格的最大高度
        table_MaxHeight: {
            type: Number
        },
        // 表格顶部的按钮
        tableHeadButtons: {
            type: Array
        },
        // 序列号 列，是否左浮动
        indexFiexd: {
            type: Boolean,
            default: true
        },
        // 是否导出excell表格
        outPutExcell_obj: {
            type: Object,
            default: null
        },
        // 是否使用表格插槽。就是不用表格了
        isTableSlot: {
            type: Boolean,
            default: false
        },
        // 是否禁用。针对表单的
        allDisabled: {
            type: Boolean,
            default: false
        }
    },
    created () {
        this.init()
        if (this.selectId) {
            const val = this.selectId
            const idArr = val.split(',')
            console.log(idArr)
            this.tableData.forEach((item) => {
                const id = item.id.toString()
                console.log(id)
                if (idArr.indexOf(id) != -1) {
                    this.$refs.jaTable.toggleRowSelection(item, true)
                }
            })
        }
    },
    methods: {
        // 初始化
        async init () {
            // 加动态下拉框
            for (let i = 0; i < this.conditionData.length; i++) {
                const el = this.conditionData[i]
                if (el.input_type === 'select' && typeof el.options === 'function') {
                    const res = await el.options()
                    el.options = res
                    this.$forceUpdate()
                }
            }
        },
        // 导出的方法
        outPutExcellFun (item) {
            console.log('ja-table 的导出', item, item.rqParmas())
            if (item.rqUrl) {
                console.log(item.rqUrl);
                outPutCommonExcell({
                    url: item.rqUrl,
                    data: item.rqParmas() || "",
                    method: item.rqMethod || 'get'
                }).then((res) => {
                    this.util.dealExcelRespose(res)
                })
            }
            return
        },
        // 序列号
        indexMethod (index) {
            const a = this.nowFilterData.pageIndex
            const b = this.nowFilterData.pageSize
            return (a - 1) * b + (index + 1)
        },
        // 查询table数据
        // getInit () {
        //   console.log('===================', this.nowFilterData)
        //   this.$emit('getInit', this.nowFilterData)
        // },
        // 处理顶部 下拉框的
        dealConditionData (itemData, val) {
            const relevance = itemData.relevance
            const { sonKey, This, childrenKey, dealOptionsParma, otherSonKey } = relevance // 关联儿子的字段,key
            let index = This.conditionData.findIndex(item => item.key === sonKey) // 找到了 关联到的 那个索引
            This.conditionData[index].options = []
            This.$set(This.filterData, sonKey, '')
            if (otherSonKey && otherSonKey.length > 0) { // 如果除了直接关联的，其它的也涉及到，也得清除掉
                otherSonKey.forEach(item => {
                    let i = This.conditionData.findIndex(v => v.key == item) // 找到了 关联到的 那个索引
                    if (i > -1) {
                        This.conditionData[i].options = []
                        This.$set(This.filterData, item, '')
                    }
                })
            }
            if (relevance.rqParam) {// 说明是异步的
                if (val) {
                    this.util.rqOptionsList({ who: This.conditionData[index], ...relevance.rqParam, rqData: relevance.rqParam.rqData() })
                }
            } else { // 说明是拿父级的 children 字段
                let options = itemData.options
                let sonObj = options.find(item => item.value == val)
                if (sonObj) {
                    let childOptions = sonObj[childrenKey || 'children']
                    if (dealOptionsParma) {
                        This.conditionData[index].options = this.dealOptions({ optionsArr: childOptions, ...dealOptionsParma })
                    } else {
                        This.conditionData[index].options = childOptions
                    }
                }
            }
        },
        // 真正的处理
        dealTableFormSelect_item (objData) {
            console.log('缉拿---',objData)
            const { itemData, ItemIndex, isFromEdit, val, sonKey, This, childrenKey, dealOptionsParma, otherSonKey, rowFormDataFun, rowFormFun, rqParam, } = objData
            let RowFormData = rowFormDataFun()
            let index = RowFormData.findIndex(item => item.prop === sonKey) // 找的儿子
            let RowForm = rowFormFun()[ItemIndex]
            // console.log('RowForm------',RowForm,RowFormData,index,)
            const KEY = `${sonKey}Options`
            if (!isFromEdit) {
                // RowFormData[index].options = []
                RowForm[KEY] = []
                This.$set(RowForm, sonKey, '')
                if (otherSonKey && otherSonKey.length > 0) {
                    otherSonKey.forEach(item => {
                        RowForm[`${item}Options`] = []
                    })
                }
            }
            if (rqParam) {// 说明是异步的
                if (val) {
                    this.util.rqOptionsList({ ...rqParam, rqData: rqParam.rqData({ ...RowForm }) })
                        .then(res => {
                            RowForm[KEY] = res
                            // console.log('*********',RowForm)

                        })
                }
            } else { // 说明是拿父级的 children 字段
                // console.log('dela------',itemData.key,itemData,val)
                let options = itemData.options
                let sonObj = options.find(item => item.value == val)
                // console.log('sonObj------',sonObj)
                if (sonObj) {
                    let childOptions = sonObj[childrenKey || 'children']
                    if (dealOptionsParma) {
                        RowForm[KEY] = this.dealOptions({ optionsArr: childOptions, ...dealOptionsParma })
                    } else {
                        RowForm[KEY] = childOptions
                    }
                }
            }
        },
        // 处理表格里嵌套的 表单下拉框
        dealTableFormSelect (itemData, val, isFromEdit, ItemIndex) {
            const relevance = itemData.relevance
            const { sonArr } = relevance
            if (sonArr && sonArr.length > 0) {
                sonArr.forEach(item => {
                    // console.log('进来111')
                    this.dealTableFormSelect_item({ itemData, ItemIndex, isFromEdit, val, ...relevance, ...item })
                })
            } else {
                this.dealTableFormSelect_item({ itemData, ItemIndex, isFromEdit, val, ...relevance })
            }
        },
        // button事件
        itemEvent (method, row, val = "", itemData = { input_type: "" }, rowIndex) {
            // console.log('进来啦',method,row, val,itemData,rowIndex)
            if (method) {
                this.$emit(method, row, val, itemData, rowIndex)
            }
            if (method == 'outPutExcellFun' && row.normalBtn == 'download') {
                this.outPutExcellFun(row.exportObj || {})
                return
            }
            if (itemData.relevance) { // 是否有关联的 子列表，要变化
                this.dealConditionData(itemData, val,)
            }
            if (row.relevance) { // 如果是表格的
                // console.log('row----->',row)
                this.dealTableFormSelect(row, val, undefined, rowIndex)
            }

            // console.log(method, row, val, itemData)
            // 判断改变的是否是select,如果是
            if (itemData.input_type == 'select') {
                this.conditionData.forEach(async (item, index) => {
                    if (item.handleSelectContent) {
                        item.options = await item.handleSelectContent(
                            itemData,
                            row,
                            val,
                            item
                        )
                        this.$forceUpdate()
                    }
                })
            }
        },
        // 每页显示条目个数
        sizeChange (val) {
            this.nowFilterData = {
                ...this.nowFilterData,
                pageIndex: 1,
                pageSize: val
            }
            setTimeout(() => {
                this.$emit('getInit', this.nowFilterData)
            }, 0)
        },
        // 勾选种某一行
        handleSelectItem (selection, row) {
            this.$emit('handleSelectItem', selection, row)
        },
        handleCurrentChange (currentRow, oldCurrentRow) {
            this.$emit('handleCurrentChange', currentRow, oldCurrentRow)
        },
        // 选中事件
        handleSelectionChange (val) {
            this.$emit('handleSelectionChange', val)
        },
        handleSelectionAll (val) {
            this.$emit('handleSelectionAll', val)
        },
        // 改变当前页
        currentChage (val) {
            this.nowFilterData = {
                ...this.nowFilterData,
                pageIndex: val
            }
            setTimeout(() => {
                this.$emit('getInit', this.nowFilterData)
            }, 0)
        },
        // table的某项事件
        valueEvent (row, method, scope, button) {
            method = method || 'valueEvent'
            this.$emit(method, row, scope, button)
        },
        // 是否需要显示label
        hasLabel (item) {
            // 有label并且不是按钮时
            if (item.label && item.input_type !== 'button') {
                // 传入不需要的label时
                if (item.noLabel) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        // 下拉框，日期框，按钮皆可触发事件，根据label自行选择
        changeTime (label, method) {
            this.$emit('getCondition', { label })
            // 自定义方法
            if (method) {
                this.$emit(method)
            }
        },
        // 表格尾部统计  
        /**
         * 1.传入showSummary  Boolean 以打开统计
         * 2.传入chooseArr Array [第一个为统计行标题，......被统计标题],为空默认统计全部数字列
         */
        getSummaries (param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                const showIndex = this.showIndex ? 1 : 0
                if (index === showIndex) {
                    sums[index] = this.chooseArr[0] ? this.chooseArr[0] : '合计';
                    return;
                }
                if (this.chooseArr.length <= 0 || this.chooseArr.indexOf(column.label) >= 0) {
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        // 统计保留两位小数
                        sums[index] = sums[index].toFixed(2);
                    } else {
                        sums[index] = '';
                    }
                }
            });
            return sums;

        }
    },
    watch: {
        value (val) {
            this.nowFilterData = val
        },
        nowFilterData (val) {
            this.$emit('input', val)
        }
        // selectId (val) {
        //   console.log(val)
        //   const idArr = val.split(",")
        //   console.log(idArr)
        //   this.tableData.forEach(item => {
        //     console.log(idArr.indexOf(item.id))
        //     if (idArr.indexOf(item.id) != -1) {
        //       this.$refs.multipleTable.toggleRowSelection(item, true)
        //     }
        //   })
        // }
    },
    filters: {
        optionsFormat: (val, item) => {
            if (item.options && item.options.length > 0) {
                let obj = {}
                item.options.forEach(item => [
                    obj[item.value] = item.label
                ])
                return obj[val]
            } else {
                return val
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';
/deep/ .el-input__prefix,
/deep/ .el-input__suffix {
    top: -2px;
}
/deep/ .el-table__empty-block {
    border-bottom: 1px solid #ebeef5 !important;
}
.ja-pagination {
    display: flex;
    padding: 10px 15px;
    // margin-top: 15px;
    margin: 0px 15px;
    background-color: $theme-color;
    justify-content: space-between;
}
.form {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 15px;
    background-color: $theme-color;
    justify-content: space-between;
    margin: 15px;
    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
        width: auto;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: auto;
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .right {
        display: flex;
        align-items: center;
    }
}
.formTable {
    /deep/ .el-date-editor .el-range-separator {
        width: 25px;
    }
    /deep/ .el-range-editor.el-input__inner {
    }
}
.table {
    padding: 10px 15px;
    margin: 0px 15px;
    background-color: $theme-color;
    .el-table::before {
        height: 0;
    }
}

/deep/ .el-table__header .cell {
    padding-left: 0.729vw;
    color: #909399;
}
.app-container {
    padding-bottom: 20px;
}
// .app-container{
//   background-color: $theme-color;
//   /deep/ .el-table{
//     background: transparent;
//     tr, th{
//       background: transparent;
//     }
//   }
// }
.fromForm {
    margin: 0 auto;
    .table {
        margin: 0 auto;
        padding: 0;
        font-size: 14px;
        .el-table {
            .has-gutter {
                th {
                    padding: 0;
                }
            }
        }
    }
}
.tableHead {
    display: flex;
    padding-right: 10px;
    justify-content: flex-end;
    padding-bottom: 15px;
}
.formTable {
    &.fromForm {
        /deep/ .el-table thead tr th {
            padding: 0;
        }
        /deep/ .el-select {
            .el-input__inner {
                height: 40px;
                line-height: 40px;
            }
        }
    }
    /deep/ .el-select {
        width: 100%;
    }
    .form {
        /deep/ .el-form.el-form--inline {
            width: 100%;
        }
    }
    .input_text {
        max-width: 100%;
        border: 1px solid #dcdfe6;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        -webkit-transition: border-color 0.2s
            cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        &:disabled {
            background: #f5f7fa;
        }
    }
}
.outputBtn {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: top;
    line-height: 40px;
    font-size: 14px;
}
/deep/ .el-button--primary.is-plain {
    background: #ecf5ff;
}
/deep/ .el-button--primary.is-plain:focus,
.el-button--primary.is-plain:hover {
    background: #409eff;
}
/deep/ .el-table__fixed {
    height: 100% !important;
}

// 兼容
// /deep/ .el-date-editor--datetimerange.el-input, /deep/ .el-date-editor--datetimerange.el-input__inner { width: auto;}
</style>
