<template>
    <!-- 照明系统-场景管理 -->
    <div class="allwh">
        <div class="allwh-wrapper">
            <ja-table ref="tst"
                      v-model="filterData"
                      :conditionData="conditionData"
                      @rowDetail="rowDetail"
                      :columns="columns"
                      :tableData="tableData"
                      :total="total"
                      :loading="loading"
                      :valueFormat="valueFormat"
                      @append="append"
                      @rowEdit="rowEdit"
                      @rowDelete="rowDelete"
                      @getInit="getInit"
                      @search="search"
                      @importForm="normalEvent({
                        ref: 'importForm',
                        method: 'importForm',
                        url: '/files/场景管理导入模版.xlsx',
                        filename: '场景管理导入模版',
                        uploadURL: '/sceneManagement/upload'
                        })"
                      @reset="reset">
                      <template #operateSlot="{scope}">
                         <el-switch
                            style="margin-right:10px"
                            active-value="true"
                            v-if="isAuth('subsystemModule::sceneManage::switch')"
                            inactive-value="false"
                            @change="changeSwitching($event,scope)"
                            v-model="scope.switching"
                            active-color="#13ce66"
                            >
                        </el-switch> 
                      </template>
                    <template #sceneDescription="{row}">
                        <div :class="[row.scenarioPlanning==1?'sceneType':'']">
                            {{row.sceneDescription}}
                        </div>
                    </template>
            </ja-table>
            <!-- 导入 -->
            <import-form ref="importForm"
                     @openResult="openResult" />
            <!-- 导入校验 -->
            <check-result ref="checkResult"
                        :results="results" />
            <!-- 新增/编辑 -->
            <!-- reset: 取消 -->
            <!-- saveForm: 保存操作 -->
            <el-dialog v-dialogDrag
                       :close-on-click-modal="false"
                       :visible.sync="showEditAll"
                       width="50%"
                       :title="titleText">
                <ja-form :rules="rules"
                         :allDisabled="allDisabled"
                         :label-width="labelWidth"
                         v-model="rowForm"
                         ref="jaForm"
                         :formArray="rowFormData"
                         @thePlanType="thePlanType"
                         @reset="showEditAll = false"
                         @save="saveForm"
                        @associativeCircuits='associativeCircuits'
                         v-if="showEditAll"
                         v-loading="loading_form.isShow"
                         :element-loading-text="loading_form.text"
                         :element-loading-spinner="loading_form.icon"
                         :element-loading-background="loading_form.bg"
                         :element-loading-custom-class="loading_form.formLoading">
                </ja-form>
            </el-dialog>
             <!-- 选择回路弹窗 -->
            <el-dialog v-dialogDrag
                :close-on-click-modal="false"
                :visible.sync="showCircuitPopUp"
                width="50%"
                @close="closeCircuit"
                :title="'选择'+scenarioName+'关联回路'"
                v-if="showCircuitPopUp"
            >
                <ja-table 
                    showSelect
                    :showPage="false"
                    v-model="circuitData"
                    ref="tableCircuit"
                    :conditionData="conditionCircuitData"
                    :columns="circuitColumns"
                    :tableData="circuitTableData"
                    :table_MaxHeight="400"
                    :loading="circuitLoading"
                    @handleSelectItem="handleSelectItem"
                    @handleSelectionAll="handleSelectItem"
                    @search="circuitSearch"
                    @reset="circuitReset"
                    @getInit="getInitCircuit">
                </ja-table>
                <div class="bottom_box">
                    <span class="selected">已选中{{selectCircuit}}个</span>
                    <el-button  @click="closeCircuit">取消</el-button>
                    <el-button type="primary" @click="confirmCircui">确认</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import table from "@/views/mixins/table";
import checkResult from '@/views/home/components/common/checkResult.vue'
import importForm from '@/views/home/lettingRate/common/importForm.vue'
import { getSceneManagement, addSceneManagement, editSceneManagement, 
deleteSceneManagement,getSceneNumber,getCircuitManagement,sceneSwitchPrompt, sceneSwitching} from '@/api'

export default {
    title: '场景管理',
    mixins: [table],
    name: 'sceneManage',
    components: {
        checkResult,
        importForm
    },
    data() {
        return {
            filterData: { pageIndex: 1, pageSize: 10 }, // 默认页码
            circuitColumns:[
                {label:'回路名称',prop:'circuitName'},
                {label:'回路编号',prop:'circuitNumber'}
            ],
            circuitTableData:[
                
            ],
            conditionCircuitData:[
                {
                    input_type: 'select', key: 'buildingId', attribute: { placeholder: '所属楼栋', size: 'small' }, options: [],
                },
                {
                    input_type: 'select', key: 'floorId', attribute: { placeholder: '所属楼层', size: 'small' }, options: [],
                },
                { normalBtn: 'search', limit: 'default:view'},
                { normalBtn: 'reset' },
            ],
            circuitData:{ pageIndex: 1, pageSize: 1000},
            circuitNameList:[],
            scenarioName:'',
            selectCircuit:0,
            conditionData: [
                {
                    input_type: 'select', key: 'scenarioPlanning', attribute: { placeholder: '计划类型', size: 'small' }, options: [
                        {label:'周期计划',value:2},{label:'临时计划',value:1}
                    ],
                },
                {
                    input_type: 'select', key: 'switching', attribute: { placeholder: '计划状态', size: 'small' }, options: [
                        {label:'启用',value:'true'},
                        {label:'停用',value:'false'}
                    ],
                },
                {
                    input_type: 'input', key: 'sceneDescription', attribute: { placeholder: '场景描述', size: 'small' }
                },
                {
                    input_type: 'input', key: 'sceneNumber', attribute: { placeholder: '场景编号', size: 'small' }
                },
                {
                    input_type: 'input', key: 'circuitName', attribute: { placeholder: '回路名称', size: 'small' }
                },
                {
                    input_type: 'input', key: 'circuitNumber', attribute: { placeholder: '回路编号', size: 'small' }
                },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'subsystemModule::sceneManage::add'},
                { normalBtn: 'import', text: '导入', limit: 'subsystemModule::sceneManage::import'},
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqexport, rqUrl: '/sceneManagement/export', rqMethod: 'post' }, limit: 'subsystemModule::sceneManage::export' },
            ],
            columns: [
                { label: '场景编号', prop: 'sceneNumber' },
                { label: '场景描述', prop: 'sceneDescription', attribute: { showOverflowTooltip: true, align: 'center' },slotName:'sceneDescription'},
                { label: '关联回路', prop: 'circuitName' },
                { label: '开始时间', prop: 'startTime', width: 80 },
                { label: '结束时间', prop: 'endTime', width: 80 },
                { label: '生成策略', prop: 'generationStrategy', attribute: { showOverflowTooltip: true, align: 'center' } },
                { label: '场景计划', prop: 'scenarioPlanning', attribute: { showOverflowTooltip: true, align: 'center' }},
                {
                    label: '操作',
                    input_type: 'button',
                    width: 240,
                    attribute: { fixed: "right", align: 'center' },
                    list: [
                        { text: '查看', method: 'rowDetail' , limit: 'subsystemModule::sceneManage::see'},
                        { text: '编辑', method: 'rowEdit', limit: 'subsystemModule::sceneManage::edit' }, 
                        { text: '删除', method: 'rowDelete', limit: 'subsystemModule::sceneManage::del'},
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 0,
            // 新增/编辑
            titleText: '编辑',
            rowFormData_base: [
                { input_type: 'input', label: '场景编号', key: 'sceneNumber', maxlength: 50 },
                { input_type: 'input', label: '场景描述', key: 'sceneDescription', maxlength: 20 },
                { input_type: 'radio', label: '计划类型', key: 'scenarioPlanning', options:[{label:'周期计划',value:2},{label:'临时计划',value:1}], method:"thePlanType", },
                { input_type: 'radio', label: '生成策略', key: 'generationStrategy',  options: [{label:'每日',value:2},{label:'仅工作日',value:1}], },
                { input_type: 'radio', label: '初始状态', key: 'initialState', hide:true, options: [{label:'开灯',value:"true"},{label:'关灯',value:"false"}],  },
                { label: '关联回路', input_type: 'input',type:'textarea', key: 'circuitName',col:24, focusMethod:"associativeCircuits", maxlength:"10000"},
                { label: '开启时间', input_type: 'time', key: 'startTime',valueFormat:"HH:mm", format:"HH:mm"},
                {  label: '关闭时间', input_type: 'time', key: 'endTime',valueFormat:"HH:mm", format:"HH:mm"},
            ],
            rowFormData: [],
            rules: {                                                         
                sceneNumber: [{ required: true, trigger: 'change',message: '请输入场景编号', }],
                sceneDescription: [{ required: true, trigger: 'change',message: '请输入场景描述'}],
                scenarioPlanning: [{ required: true, trigger: 'change',message: '请选择计划类型'}],
                generationStrategy: [{ required: true, trigger: 'change', message: '请选择生成策略'}],
                initialState: [{ required: true, trigger: 'change', message: '请选择初始状态'}],
                circuitName: [{ required: true, message: '请选择回路', trigger: 'change' }],
                startTime: [{ required: true, message: '请选择开启时间', trigger: 'change' }],
                endTime: [{ required: true, message: '请选择关闭时间', trigger: 'change' }],
            },
            rowForm: {
                circuitIds:[]
            },
            showEditAll: false, // 显示新增/编辑弹窗
            labelWidth: `${this.util.setFontSize(140)}px`,
            indexObj: {},
            conditionCircuitIndexObj:{},
            allDisabled:false,
            showCircuitPopUp:false,
            results:{},
            circuitLoading:false,
            deepCircuitNameList:[]

        }
    },
    watch: {
        'rowForm.scenarioPlanning'(val){
            if(val==1){
                this.rowFormData[this.indexObj.initialState].hide=false;
                this.rowFormData[this.indexObj.generationStrategy].hide=true;
                this.rules.endTime[0].required=false;
            }else{
                this.rowFormData[this.indexObj.generationStrategy].hide=false;
                this.rowFormData[this.indexObj.initialState].hide=true;
                this.rules.endTime[0].required=true;
            }  
        }
    },
    created() {
        this.rowFormData = [...this.rowFormData_base]
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
        this.conditionCircuitIndexObj = this.util.getArrItemIndex({ arr: this.conditionCircuitData, })
    },
    mounted() {
        const rowParamObj = { pageIndex: 1, pageSize: 2000 }
        // 表单里的
        let buildingData=this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id', resRule: 'data.data' }) // 楼栋选择
        let floorData=this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.floorId], rqUrl: "/floor/allList", rqData: rowParamObj, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId', resRule: 'data' }) // 楼层选择
        buildingData.then(res=>{
            this.conditionCircuitData[this.conditionCircuitIndexObj.buildingId].options=res
        })
        floorData.then(res=>{
            this.conditionCircuitData[this.conditionCircuitIndexObj.floorId].options=res
        })

    },
    methods: {
        rqexport() {
            let obj = { ...this.filterData }
            obj.pageIndex = this.total <= 2000 ? 1 : this.filterData.pageIndex
            obj.pageSize = 2000
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        getInit: function (item) { // 查询设备列表
            this.loading = true
            getSceneManagement(this.filterData).then(res => {
                this.loading = false
                const { data, total } = res.data
                this.total = total
                this.tableData = data
            }).catch(err => {
                this.loading = false
            })
        },
        rowDelete: function (row) { // 删除
            if(row.switching=="true"){
                this.$alert('请先关闭该场景计划再进行删除操作', '无法删除', {
                    confirmButtonText: '确定',
                    callback: action => {
                       
                    }
                });
            }else{
                this.$confirm('是否确认删除该场景信息').then(res => { // 删除设备列表信息
                    deleteSceneManagement(row.id).then(res => {
                        this.$message.success(res.msg)
                        this.getInit()
                    })
                })
            }
        },
        saveForm: function () { // 保存
            this.loading_form.isShow = true
            if (this.isAdd) {
                addSceneManagement(this.rowForm).then(res => { // 新增设备列表信息
                    this.loading_form.isShow = false
                    this.$message.success(res.msg)
                    this.showEditAll = false
                    this.getInit()
                }).catch(error => {
                    this.loading_form.isShow = false
                })
            } else {
                editSceneManagement(this.rowForm).then(res => { // 更新设备列表信息
                    this.loading_form.isShow = false
                    this.$message.success(res.msg)
                    this.showEditAll = false
                    this.getInit()
                }).catch(error => {
                    this.loading_form.isShow = false
                })
            }
        },
        /**获取场景编码 */
        getSceneNumber(){
            getSceneNumber().then(res=>{
               let sceneNumber=res.data
               this.$set(this.rowForm,'sceneNumber',sceneNumber)
            })
        },
        append: function () {
            this.getSceneNumber()
            this.circuitNameList=[];
            this.allDisabled=false
            this.isAdd = true
            this.titleText = '新增'
            this.rowForm = {}
            this.rowFormData[this.indexObj.sceneNumber].disabled=false;
            this.$set(this.rowForm,'scenarioPlanning','0')
            this.showEditAll = true
        },
        rowEdit: function (row) { // 编辑
            this.allDisabled=false;
            this.circuitNameList=[];
            this.rowForm = {}
            this.isAdd = false
            this.titleText = '编辑'
            this.rowForm = { ...row }
            this.showEditAll = true
            //回路名称拼接编号
            if(this.rowForm.circuitIds && this.rowForm.circuitIds.length>0){
                let circuitName=this.rowForm.circuitName?this.rowForm.circuitName.split(','):[]
                this.rowForm.circuitName=circuitName.map((item,i)=>{
                    let arr=this.rowForm.circuitNumber.split(',')
                    this.circuitNameList.push({
                        id:this.rowForm.circuitIds[i],
                        circuitName:item,
                        circuitNumber:arr[i]
                    })
                    return item+'('+arr[i]+')'
                }).join(',')
               this.deepCircuitNameList=this._.cloneDeep(this.circuitNameList)  // 拷贝一份回路弹窗点了取消时用
            }
            this.rowFormData[this.indexObj.sceneNumber].disabled=true;
            
        },
        rowDetail(row){
            this.rowEdit(row)
            this.titleText = '查看'
            this.allDisabled=true
        },
        /**获取关联回路**/
        getInitCircuit(){
            this.circuitLoading=true;
            this.circuitTableData=[];
            getCircuitManagement(this.circuitData).then(res=>{
                this.circuitTableData=res.data.data;
                this.circuitLoading=false;
                this.$nextTick(()=>{
                    this.circuitTableData.forEach(row=>{
                        if(this.circuitNameList && this.circuitNameList.length>0){
                            this.circuitNameList.forEach(selected =>{
                                if(selected.id === row.id){
                                this.$refs.tableCircuit.$refs.jaTable.toggleRowSelection(row,true);
                                }
                            })
                        }
                    })
                })
            }).catch(err=>{
                this.circuitLoading=false;
            })
        },
        /**计划类型 */
        thePlanType(item,val){
          
        },
        /**点击关联回路框 */
        associativeCircuits(){
            this.showCircuitPopUp=true;
            this.selectCircuit=this.rowForm.circuitIds ? this.rowForm.circuitIds.length:0;
            this.scenarioName=this.rowForm.sceneDescription?this.rowForm.sceneDescription:"";
            this.circuitData={ pageIndex: 1, pageSize: 1000},
            this.getInitCircuit()
        },
         /**选择回路 */
        handleSelectItem(list){
            this.circuitNameList=list
            this.selectCircuit=this.circuitNameList?this.circuitNameList.length:0;
        },
        /**关闭回路弹窗 */
        closeCircuit(){
            if (this.isAdd && (!this.rowForm.circuitIds || this.rowForm.circuitIds.length==0)){
                this.circuitNameList=[]
            }else{
                this.circuitNameList=this.deepCircuitNameList; // 等于编辑时点取消回路弹窗时存放原来内容
            }
            this.showCircuitPopUp=false
        },
        /**回路搜索 */
        circuitSearch(){
            this.circuitData.pageIndex = 1
            this.getInitCircuit()
        },
        /**回路弹窗重置 */
        circuitReset() {
            this.circuitData = {
                pageIndex: 1,
                pageSize: 1000
            }
            this.getInitCircuit()
        },
        /**点击回路确认 */
        confirmCircui(){
            if(this.circuitNameList.length>0){
               let circuitIds=[];
               let circuitNameList=this.circuitNameList.map(item=>{
                    circuitIds.push(item.id)
                    return item.circuitName+'('+item.circuitNumber+')'
                }).join(',')
                this.rowForm.circuitIds=circuitIds;
                this.$set(this.rowForm,'circuitName',circuitNameList)
                this.deepCircuitNameList=this.circuitNameList
                this.showCircuitPopUp=false
            }else{
                this.$message.error('至少要选择一个回路！')
            }  
        },
        /**转换表格内容 */
        valueFormat(val,key){ 
            const scenarioPlanningObj={1:'临时计划',2:'周期计划'};
            const initialStateObj={false:'关灯',true:'开灯'};
            const generationStrategyObj={1:'仅工作日',2:'每日'};
            if(key=='scenarioPlanning'){
                return scenarioPlanningObj[val]
            }else if(key=='initialState'){
                return initialStateObj[val]
            }else if(key=='endTime'){
                return val?val:'--'
            }else  if(key=='generationStrategy'){
                return val?generationStrategyObj[val] :'--'
            }else {
                return val
            }
        },
        /**导入校验 */
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
        /**点击单个开关按钮 */
        changeSwitching(value,row){
            row.switching = !row.switching //开关按钮保持点击前状态
            this.sceneSwitchPrompt(value,row.id)
        },
        /**开启场景验证 */
        sceneSwitchPrompt(value,id){
            if(value=='true'){
                sceneSwitchPrompt({sceneId:id}).then(res=>{
                    if(res.data.length>0){
                        let popupType=res.data[0].popupType;
                        let hintText = res.data;
                        let circuitName=[];
                        let loopName=hintText.map(item=>{
                            circuitName.push(item.sceneName)
                            return item.circuitName
                            }).join(',')
                        circuitName=this._.uniq(circuitName).join(',')
                        // 等于2是否启用弹窗
                        if(popupType==2){
                            this.$confirm(`当前场景下存在回路${loopName}有已启用场景${circuitName}，本次打开将自动关闭之前启用的场景；本次临时计划结束后，之前启用的场景将被恢复`, '启用提示', {
                                showClose:false,
                                confirmButtonText: '确定启用',
                                cancelButtonText: '我再想想',
                                type: ''
                                }).then(() => {
                                    sceneSwitching({id:id,switching:value}).then(res=>{
                                        this.getInit()
                                        this.$message({
                                            type: 'success',
                                            message: res.msg
                                        });
                                    })
                                }).catch(() => {
                    
                                });
                            
                        }else{
                            this.$alert(`当前场景下存在回路${loopName}有已启用场景${circuitName}，请先关闭已启用场景再重新打开。`, '启用失败提示',{
                                showClose:false,
                                confirmButtonText: '已知晓',
                                callback: action => {
                                    
                                }
                            });
                        }
                    }else{
                        sceneSwitching({id:id,switching:value}).then(res=>{
                                this.getInit()
                                this.$message({
                                    type: 'success',
                                    message: res.msg
                                });
                        })
                    }   
                })
            }else{
                sceneSwitching({id:id,switching:value}).then(res=>{
                        this.getInit()
                        this.$message({
                            type: 'success',
                            message: res.msg
                        });
                })
            }
        },

    }
}
</script>

<style scoped lang="scss">
.bottom_box{
    text-align: right;
    .selected{
        padding: 0 15px;
        color: #fb3939;
    }
}
.sceneType{
     color: #fb3939;
}
</style>
