<template>
  <router-view/>
</template>

<script>
export default {
  title: "应急中心",
  name: 'SysEmergency'
}
</script>

<style scoped>

</style>
