<template>
  <!--  指派 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="50%" :title="(isAdd?'新增':'编辑')+'计划'">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll">
      <template #frequency>
        <frequency :planTimeReqList.sync="rowForm.planTimeReqList" :executionFrequency.sync="rowForm.executionFrequency" v-if="isAdd ? true : rowForm.executionFrequency !== undefined" />
      </template>
      <template #minute>
        <div class="timeBox">
          <span>分钟</span>
        </div>
      </template>

    </ja-form>
  </el-dialog>
</template>
<script>
import frequency from './frequency'
import { addPlan, updatePlan } from '@/api'

export default {
  inject: ['labelWidth', 'getInit',],
  components: {
    frequency
  },
  data() {
    return {
      rowForm: {},
      // 基础
      rowFormData_base: [
        { input_type: "input", label: '巡更计划名称', key: 'planName', col: 11, },
        { input_type: "radio", label: '计划类型', key: 'planType', col: 11, options: [], },
        { input_type: "select", label: '巡更路线', key: 'pathId', col: 22, options: [] },
      ],
      // 周期计划
      rowFormData_0: [
        { input_type: "date", label: '计划开始时间', key: 'planStartTime', col: 11, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
        { input_type: "date", label: '计划结束时间', key: 'planEndTime', col: 11, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd', valueFormat: 'yyyy-MM-dd' },
        { input_type: "customize", label: '执行频率', key: 'executionFrequency', col: 22, customize: 'frequency', },
        { input_type: "number", label: '工单提前创建时间', key: 'advanceCreateTime', placeholder: '时间', type: 'number', col: 12,max:30,min:0, rightBox: { input_type: 'customize', customize: 'minute', }, isFlex: true },
        { input_type: 'switch', key: 'enablePlan', label: '是否启用计划', activeValue: 0, inactiveValue: 1, col: 22, },
      ],
      // 临时计划
      rowFormData_1: [
        { input_type: "date", type: 'datetime', label: '执行时间', key: 'executionTime', col: 22, pickerOptions: { disabledDate: this.disabledDateStart }, format: 'yyyy-MM-dd HH:mm', valueFormat: 'yyyy-MM-dd HH:mm' },
        { input_type: "number", label: '工单提前创建时间', key: 'advanceCreateTime', placeholder: '时间', type: 'number', col: 22, rightBox: { input_type: 'customize', customize: 'minute', }, isFlex: true },

      ],
      rowFormData: [],
      rules: {
        planName: [{ required: true, message: '请输入巡更计划名称', trigger: 'blur' }],
        planType: [{ required: true, message: '请选择计划类型', trigger: 'change' }],
        pathId: [{ required: true, message: '请选择巡更路线', trigger: 'change' }],
        planStartTime: [{ required: true, trigger: 'change', validator: this.validatorStartTime }],
        planEndTime: [{ required: true, trigger: 'change', validator: this.validatorEndTime }],
        executionTime: [{ required: true, trigger: 'change', validator: this.validator_executionTime }],
        advanceCreateTime: [{ required: true, validator: this.util.formRules.checkNumber({ isIncludeMin: false, min: 0, isInt: true }), trigger: 'change' }],
        executionFrequency: [{ required: true, message: '请完善执行频率', trigger: 'change' }],
        enablePlan: [{ required: true, message: '请选择计划结束时间', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},
    }
  },
  async created() {
    this.rowFormData = [...this.rowFormData_base]
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
    this.util.rqOptionsList({ who: [this.rowFormData[this.indexObj.pathId]], rqUrl: "/patrol/path/all", resRule: 'data',  labelName: 'pathName', valueName: 'id', isReturnAll: true })
    console.log("init....", this.rowFormData);
  },
  watch: {
    'rowForm.planStartTime': function () {
      if (this.$refs.jaForm) {
        this.$refs.jaForm.$refs.formbox.validateField('planStartTime');
        if (this.rowForm.planEndTime) {
          this.$refs.jaForm.$refs.formbox.validateField('planEndTime');
        }
      }
    },
    'rowForm.planType': function (val) {
      if (val == 0) {
        this.rowFormData = [...this.rowFormData_base, ...this.rowFormData_0]
      } else if (val == 1) {
        this.rowFormData = [...this.rowFormData_base, ...this.rowFormData_1]
      } else {
        this.rowFormData = [...this.rowFormData_base]
      }
    },
    'rowForm.planEndTime': function () {
      if (this.$refs.jaForm) {
        this.$refs.jaForm.$refs.formbox.validateField('planEndTime');
      }
    },
  },
  methods: {
    // 新增
    append() {
      this.isAdd = true
      this.rowForm = {
        planType: '0'
      }
      this.showEditAll = true
      this.$nextTick(() => {
        this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
        this.$refs.jaForm.$refs.formbox.clearValidate()
      })
    },
   
    // 编辑
    async rowEdit(row) {
      this.isAdd = false
      // this.rowForm = {}
      this.showEditAll = true
      this.loadingForm = true
      this.rowForm = {...row}
      // this.rowForm.pathId = this.rowForm.pathId+''
      if (this.rowForm.planType == 1) { //临时计划
        this.$set(this.rowForm, 'executionTime', `${row.temporaryStartDate} ${row.temporaryStartTime}`);
      }
      this.$nextTick(() => {
        this.util.setOptionsInit({ arr: this.rowFormData, This: this, obj: this.rowForm, ref: 'jaForm' })
        this.$refs.jaForm.$refs.formbox.clearValidate()
        console.log(55,this.rowForm,this.rowFormData);
      })
        this.loadingForm = false
    },
    // 保存
    saveForm() {
      this.loadingForm = true
      const rqMethod = this.isAdd ? addPlan : updatePlan
      const rqObj = { ...this.rowForm }
      if(rqObj.planType == 1) rqObj.enablePlan = 0  // 计划类型是临时计划时默认开启计划
      rqObj.advanceCreateTime = Number(rqObj.advanceCreateTime)
      if (rqObj.planType == 1) { //临时计划
        rqObj.temporaryStartDate = rqObj.executionTime.slice(0, 10)
        rqObj.temporaryStartTime = rqObj.executionTime.slice(11)
      }
      rqMethod(rqObj).then(res => {
        this.loadingForm = false
        this.$message.success(res.msg || '提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },

    // 开始时间禁用
    disabledDateStart(val) {
      let now = (new Date()).getTime()
      let time = val.getTime()
      let oneDay = 24 * 3600 * 1000
      return time < now - oneDay
    },
    // 校验开始时间
    validatorStartTime(rule, value, callback) {
      if (value === '' || !value) {
        callback(new Error('请完善计划开始时间'));
      } else {
        callback()
      }
    },
    validatorEndTime(rule, value, callback) {
      if (value === '' || !value) {
        callback(new Error('请完善计划结束时间'));
      } else {
        let planStartTime = new Date(this.rowForm.planStartTime).getTime()
        let time = new Date(value).getTime()
        if (time < planStartTime) {
          callback(new Error('结束时间不能小于开始时间'))
        } else {
          callback()
        }
      }
    },
    // 校验 执行时间
    validator_executionTime(rule, value, callback) {
      if (value === '' || !value) {
        callback(new Error('请完善执行时间'));
      } else {
        let now = new Date().getTime()
        let minutes_3 = 3 * 60 * 1000
        let time = new Date(value).getTime()
        if (!(time > (now + minutes_3))) {
          callback(new Error('请选择3分钟之后的时间'))
        } else {
          callback()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btns {
  margin-left: 20px;
  display: flex;
  align-items: center;
  .ipTestWord {
    margin-left: 10px;
  }
}
.timeBox {
  display: flex;
  align-items: center;
  span {
    margin: 0 10px;
  }
  .el-switch,
  & > span {
    flex-shrink: 0;
  }
}
</style>