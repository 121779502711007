<template>
  <div>
    <el-container>
      <left-list></left-list>
      <mian-video></mian-video>
      <!-- <div class="right">
        <right-chart></right-chart>
      </div> -->
    </el-container>
  </div>
</template>
<script>
import LeftList from './components/LeftList.vue'
import MianVideo from './components/MianVideo.vue'
// import rightChart from './right/rightChart.vue'
export default {
  title: '照明系统',
  name: 'lghtingSystem',
  components: {
    LeftList,
    MianVideo,
    // rightChart
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.el-container {
    position: relative;
    margin: 0 15px;
}
:-webkit-full-screen .el-container{
  margin: 0!important;
}
.right {
  position: absolute;
  background: url('~@/assets/gd/right.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 90%;
  width: 24%;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
}
</style>
