<template>
    <div class="tabsBox">
        <el-tabs v-model="activeName">
            <el-tab-pane label="全部物资" name="1">
                <all-materials v-if="activeName === '1'"></all-materials>
            </el-tab-pane>
            <el-tab-pane label="领取记录" name="2">
                <receiving-records v-if="activeName === '2'"></receiving-records>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import allMaterials from './components/allMaterials.vue'
import receivingRecords from './components/receivingRecords.vue'
export default {
    title: '物资管理',
    name: 'materialLists',
    components:{
        receivingRecords,
        allMaterials,
    },
    data () {
        return {
            activeName: '1'
        }
    },
    
}
</script>
<style lang="scss" scoped>
.tabsBox {
    padding: 10px 18px;
    overflow: hidden;
}
.el-tabs {
    padding: 10px 10px;
    background-color: #fff !important;
    /deep/.el-tabs__header > .el-tabs__nav-wrap > .el-tabs__nav-scroll {
        background-color: #fff !important;
    }
}
</style>