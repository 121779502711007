import http from './plugin/ajax'

// ==================告警设置=======================
// 分页查询告警设置列表
export const alarmConfigList = (params) => http.get('/alarmConfig', {params})
// 告警工单启用停用
export const commitAlarmConfig = (params) => http.post(`/alarmConfig/handleStatus/${params}`)
// 获取告警工单
export const getAlarmConfig = (params) => http.post(`/alarmRecord/page`,params)
// 处理告警工单
export const getAlarmhandle = (params) => http.post(`/alarmRecord/handle/${params}`)
// 告警记录转告警工单
export const commitAlarm = (params) => http.post(`/alarmRecord/handleStatus/${params}`)
// 查询告警类型
export const getAlarmType = () => http.get('/alarmType')
// 获取设备大类
export const getCategory = (params) => http.get('/device/category',{params})
// 根据告警类型ID获取告警消息模板
export const getTemplateByAlarmTypeId = (params) => http.get('/msg/template',{params})
// 修改告警设置信息
export const updateAlarmConfig = (data) => http.put('/alarmConfig', data)
// 添加告警设置信息
export const addAlarmConfig = (data) => http.post('/alarmConfig', data)
// 删除告警设置
export const delAlarmConfig = (id) => http.delete(`/alarmConfig/${id}`)

// 告警记录查询
export const alarmRecordList = (params) => http.get('/alarmRecord', {params})


// ==================告警联动=======================

// 分页查询告警联动信息
export const linkageConfigList = (params) => http.get('/linkage/config', {params})
// 获取所有联动类型
export const linkageTypeAll = () => http.get('/linkage/type')
// 告警联动删除
export const delLinkageConfig = (id) => http.delete(`/linkage/config/${id}`)

// 获取可选触发设备列表
export const triggerDeviceList = (params) => http.get('/linkage/device/trigger/select', {params})
// 获取可选联动设备列表
export const linkDeviceList = (params) => http.get('/linkage/device/select', {params})

// 根据联动类型获取触发条件 linkageTypeId
export const linkCondition = (params) => http.get('/linkage/condition', {params})
// 根据联动类型获取联动动作 linkageTypeId
export const linkageAction = (params) => http.get('/linkageAction', {params})


// 修改告警设置信息
export const updateLinkageConfig = (data) => http.put('/linkage/config', data)
// 添加告警设置信息
export const addLinkageConfig = (data) => http.post('/linkage/config', data)


// ==================联动记录=======================
// 分页查询联动记录信息
export const linkageRecord = (params) => http.get('/linkage/record', {params})