<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowRecord="getInfo"  @rowDelete="rowDelete">
      <template #img='{row}'>
        <el-image :preview-src-list="[row.visitorFaceImg]" fit="contain" class="cellImg" :src="row.visitorFaceImg" alt="" v-if="row.visitorFaceImg ">
          <div slot="error" class="errorImg el-image__error">
            加载失败
          </div>
        </el-image>
      </template>
    </ja-table>

    <!-- 详情 -->
    <detail ref="detail"/>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import { rqVistorCountList } from '@/api'
import Detail from './childComp/Detail.vue'
 
export default {
  title: "访客列表",
  mixins: [table],
  components:{
    Detail,
  },
  name: 'index',
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', key: 'time', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
        { input_type: 'select', key: 'sex', attribute: { placeholder: '性别', }, options: [{label:"男",value:"男"},{label:"女",value:"女"}] },
        { input_type: 'input', key: 'name', attribute: { placeholder: '访客姓名', } },
        { input_type: 'input', key: 'phone', attribute: { placeholder: '访客手机', } },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset', },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/visitor/info/export', rqMethod: 'post' }, limit: 'traffic::visitorList::export' }
      ],
      columns: [
        { label: '访客姓名', prop: 'visitorName', },
        { label: '性别', prop: 'visitorGender', },
        { label: '访客公司名称', prop: 'visitorCompanyName', width: 150, attribute: { showOverflowTooltip: true, align: 'center' } },
        { label: '访客手机', prop: 'visitorPhone',width: 160, },
        { label: '访客照片', prop: 'visitorFaceImg', width: 100, slotName: 'img' },
        { label: '增加时间', prop: 'visitorAddTime', width: 160, },
        { label: '预约次数', prop: 'appointNumber' },
        { label: '到访次数', prop: 'visitNumber', },
       
        {
          label: '预约记录', input_type: 'button', width: 160, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            // { text: '详情', method: 'rowDetail', limit: 'traffic::visitorList::detail'},
            { text: '查询', method: 'rowRecord', limit: 'traffic::visitorList::detail'},
            // { text: '删除', method: 'rowDelete', limit: 'traffic::visitorList::delete'},
          ]
        }
      ],
      tableData: []
    }
  },
  methods: {
     rqParmas2() {
      let obj = this.rqParmas()
      if (obj.time) {
        obj.startTime = obj.time[0]
        obj.endTime = obj.time[1]
        delete obj.time
      }
      return obj
    },
    getInit() {
      this.loading = true
      let obj = this.rqParmas2()
      rqVistorCountList(obj).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    getInfo({visitorId}){
      this.$router.push({path:'/home/visitors/visitorApplyList',query: {visitorId}})
    },
     // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该条数据，删除后无法恢复！','操作确认').then((res) => {
        delPark(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .cellImg {
  display: flex;
  margin: auto;
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
::v-deep .errorImg {
  width: 100%;
}
</style>