<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="查看详情" :width="`45%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='10' v-if="showDetail">
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      showDetail: false,
      detailOptions: [
        { key: 'a', label: '报装信息', isTitle: true, col: 22 },
        { key: 'applyCode', label: '申请编号', },
        { key: 'applyPhone', label: '联系方式', },
        { key: 'installTypeName', label: '报装类型', },
        { key: 'startTime', label: '开始时间', },
        { key: 'endTime', label: '结束时间', },
        { key: 'applyDescription', label: '申请说明', col: 22 },
        { key: 'createTime', label: '提交时间', },

        { key: 'a', label: '审核信息', isTitle: true, col: 22 },
        { key: 'statusName', label: '审批状态', },
        { key: 'auditEmployeeName', label: '审批人', },
        { key: 'auditTime', label: '审批时间', col: 22 },
        { key: 'rejectReason', label: '驳回理由', },
      ],
      detailData: {},
    }
  },
  methods: {
    rowDetail(row) {
      // console.log('查看详情---',row,)
      this.detailData = { ...row }
      this.showDetail = true
    },
  }
}
</script>
<style lang="scss" scoped>
.something {
  padding-left: 60px;
  > li {
    display: flex;
    width: 70%;
    justify-content: space-between;
  }
}
.imgs{
  padding-left: 60px;
}
</style>
