<template>
  <!-- 社区信息 -->
  <div class="allwh">
    <ja-table
      ref="tst"
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      :showIndex="true"
      @handleSelectionChange="handleSelectionChange"
      @rowDetail="normalEvent"
      @append="normalEvent({ref:'addEdit',method:'append'})"
      @rowEdit="normalEvent"
      @getInit="getInit"
      :valueFormat="formatter"
      :editDataFormat="editDataFormat"
      @search="search"
      @reset="reset"
      @allocation="allocation"
    >
      <template #parkName="{row}">
        <div class="firstcell el-button--text" @click="seeDetails(row)">{{row.parkName}}</div>
      </template>
    </ja-table>
    <!-- 新增 -->
    <allocation ref="addEdit" @closeAllocation="closeAllocation" :show="show"/>
    <!-- 详情 -->
    <detail ref="detail"/>

  </div>
</template>

<script>
import table from "@/views/mixins/table"
import allocation from './childComp/allocation'
import Detail from "./childComp/Detail"
import { getParkList } from '@/api'

export default {
  title: '社区信息',
  mixins: [table],
  name: 'index',
  components: {allocation,Detail},
  data () {
    return {
      labelWidth: `${this.util.setFontSize(150)}px`,
      filterData: {pageIndex: 1,pageSize: 10},
      conditionData:[
        { input_type: 'select', key: 'provinceId', attribute: {placeholder: '省',size: 'small'},options:[],method:'selectFun',
          relevance:{
            sonKey:'cityId', // 必填
            otherSonKey:['districtId'], // 若还涉及到孙子字段
            This:this, // 必填
            // childrenKey:'children',// 非异步的 情况下。拿的是自己的 的哪个字段.默认 是 children 。
            dealOptionsParma:{ // 若还要对子元素的值，进行处理。（如果本来就是 {label,value} 字段，就不必写 dealOptionsParma 字段）
              labelName:'label',valueName:'value',isReturnAll:true
            }
        }},
        { input_type: 'select',key: 'cityId',relevance:{sonKey:'districtId',This:this}},
        { input_type: 'select',key: 'districtId',},
        {
          input_type: 'input',
          key: 'search',
          styleWidth: '200px',
          attribute: {
            placeholder: '社区名称 / 社区地址 / 社区管理员',
            size: 'small'
          }
        },
        // { input_type: 'select',key: 'settleFlag',attribute: {placeholder: '审核状态',size: 'small'},options: [{ label: '未结清', value: 0 },{ label: '已结清', value: 1 }]},

        { normalBtn:'search',},
        { normalBtn:'reset'},
        // { normalBtn:'download'},
        // { normalBtn:'add'},
      ],
      columns: [
        {
          label: '社区名称',
          prop: 'parkName',
          slotName:"parkName",
        },
        {
          label: '省',
          prop: 'province'
        },
        {
          label: '市',
          prop: 'city'
        },
        {
          label: '区',
          prop: 'district',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '社区地址',
          prop: 'address',
          attribute: {
            showOverflowTooltip: true,
            align: 'center'
          }
        },
        {
          label: '社区管理员',
          prop: 'admin',
        },
        {
          label: '联系电话',
          prop: 'phone'
        },
        { label: '操作',input_type: 'button',width:140,attribute:{fixed:"right",align:'center'},
          list: [
            // { text: '编辑', method: 'rowEdit' },
            // { text: '删除', method: 'rowDelete' },
            { text: '配置节假日', method: 'allocation',limit:'park::info::holiday' },
            // { text: '提交变更', method: 'rowUser' },
            // { text: '审核变更', method: 'rowUser' },
          ]
        }
      ],
      tableData: [
        { orderNumber: 'qsqsw123212332', number: 'zxc2134321', applier: '张三', detail: '玻璃托+规格型号+数量；玻璃托+规格型号+数量', total: '12000', time: '2020-10-01 12:00:00', remark: '采购', state: '是' },
      ],
      loading: false,
      total: 300,
      show:false
    }
  },
  created(){

  },
  watch:{

  },
  mounted() {
     let index1 = this.conditionData.findIndex(item=>item.key == 'provinceId' )
        // this.util.rqOptionsList 函数，会再封装。针对 index，暂时先这样
        // 涉及到 refs 的，必须写在 mounted
        this.util.rqOptionsList({who:[this.conditionData[index1]],rqUrl:"/sys/region",labelName:'label',valueName:'value',resRule:'data',isReturnAll:true})
  },
  methods: {
    editDataFormat(row,btn){
      if((row.status==2|| row.status==3) && btn.text=='预约'){
        return false
      }else{
        return true
      }
    },
    handleSelectionChange (val) {
      console.log('val', val)
    },
    formatter (val, key) {
      if (key === 'status') {
        return ['预约中', '预约成功','已取消'][val || 0]
      }
      return val || ''
    },
    outPut(){
      let rqobj={
        queryParam: this.filterData.queryParam?this.filterData.queryParam:'',
        settleFlag: this.filterData.settleFlag?this.filterData.settleFlag:'',
        startTime: this.filterData.time ? this.$moment(this.filterData.time[0]).format("YYYY-MM-DD HH:mm:ss") : '',
        endTime: this.filterData.time ? this.$moment(this.filterData.time[1]).format("YYYY-MM-DD HH:mm:ss") : ''
      }
      reqExportInStorageRecord(rqobj).then(res=>{
        this.util.dealExcelRespose(res,'入库记录')
      })
    },
    getInit () {
      this.loading=true
      getParkList(this.filterData).then(res => {
        console.log(res)
        this.loading=false
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    // 节假日配置
    allocation(){
      // console.log("节假配置")
      this.show=true
    },
    // 关闭弹窗
    closeAllocation(val){
      this.show=val
    },
    seeDetails(row){
      // console.log(row)
      this.$refs.detail.showDetailFun(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.firstcell{
  cursor: pointer;
}
</style>
