<template>
  <div class="dswindow">
    <div class="airconditionbox">
      <div class="btn"></div>
      <div class="top">
        <div>
          <p>{{ formData.deviceName }}</p>
          <span>{{ formData.switchControl == 1 ? '阀门开' : '阀门关' }}</span>
          <el-switch v-model="formData.switchControl" active-value="1" inactive-value="0" inactive-color="#13ce66"
            :width="28" @change="switchChange(formData.switchControl, 'C_COU')">
          </el-switch>
        </div>
        <span v-if="formData.faultCodes && formData.faultCodes != 0">故障代码: {{ formData.faultCodes }}</span>
      </div>
      <div class="mid">
        <div>
          室内温度
          <p>{{ formData.indoorTemperature }}</p>
        </div>
        <div @click="click">
          设定温度
          <p>
            <el-input-number class="number" :disabled="formData.switchControl != '1'" :controls="false" :precision="0"
              v-model="formData.setInput" @blur="change(formData.setInput, 'TSP_COU')" :min="16" :max="30"
              :step="1"></el-input-number>
            °C
          </p>
        </div>
      </div>
      <div class="airconditionbtn">
        <div v-for="item in airconditionconfig.btnone" :key="item.id" @click="setAircondition(item.id)">
          <img :src="item.id == formData.modeSetting ? item.icont : item.iconf" />
          <span :class="item.id == formData.modeSetting ? 'select' : ''" v-if="item.text">{{ item.text }}</span>
        </div>
      </div>

      <div class="footer">
        <p>风速：</p>
        <div @click="click">
          <el-radio-group v-model="formData.windSetting" :disabled="formData.switchControl != '1'" size="medium"
            @change="switchChange(formData.windSetting, 'SPEED_COU')">
            <el-radio label="1">自动</el-radio>
            <el-radio label="2">低</el-radio>
            <el-radio label="3">中</el-radio>
            <el-radio label="4">高</el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import A_iconf from '@/assets/gd/A_iconf.png'
import cold_iconf from '@/assets/gd/cold_iconf.png'
import heat_iconf from '@/assets/gd/heat_iconf.png'
import water_iconf from '@/assets/gd/water_iconf.png'
import fan_iconf from '@/assets/gd/fan_iconf.png'
import A_icont from '@/assets/gd/A_icont.png'
import cold_icont from '@/assets/gd/cold_icont.png'
import heat_icont from '@/assets/gd/heat_icont.png'
import water_icont from '@/assets/gd/water_icont.png'
import fan_icont from '@/assets/gd/fan_icont.png'
import off_icont from '@/assets/gd/off_icont.png'
import { getAirList, updateAir } from '@/api'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      // 空调参数
      airconditionconfig: {
        btnone: [
          { id: 1, text: '制冷', iconf: cold_iconf, icont: cold_icont, isSelect: true },
          { id: 2, text: '抽湿', iconf: water_iconf, icont: water_icont, isSelect: false },
          { id: 3, text: '送风', iconf: fan_iconf, icont: fan_icont, isSelect: false },
          { id: 4, text: '制热', iconf: heat_iconf, icont: heat_icont, isSelect: false },
          { id: 5, text: '自动', iconf: A_iconf, icont: A_icont, isSelect: false }
        ]
      },
      formData: {
        switchControl: '',//开关控制
        faultCodes: '',//故障代码
        indoorTemperature: '',//室内温度
        setInput: '',//温度设定
        modeSetting: '',//模式设定
        windSetting: '',//风速设定
        deviceName: ''//设备名称
      },
      deviceNumber: ''
    }
  },
  mounted() {
    this.$emit('loading')
    if (this.$route.query.deviceNumber) {
      if (this.$store.state.token) {
        this.init(this.$route.query.deviceNumber)
      }
    } else {
      this.formData.deviceName = '未获取到设备'
    }
  },
  watch: {
    '$store.state.token': {
      deep: true,
      handler(val) {
        this.init(this.$route.query.deviceNumber)
      }
    }
  },
  methods: {
    init(deviceNumber) {
      this.deviceNumber = deviceNumber
      //获取面板数据
      getAirList({ deviceNumber }).then(res => {
        if (res.code == 200) {
          this.formData = res.data
        }
      })
    },
    //判断开关是否为开
    click() {
      if (this.formData.switchControl != '1') {
        this.$message({
          message: '请先打开开关',
          type: 'error',
          customClass: 'messageStyle'
        })
      }
    },
    //阀门开关 && 更新设置
    switchChange(value, fixedValue) {
      if (value === '') {
        this.$message({
          message: '设定温度不能为空',
          type: 'error',
          customClass: 'messageStyle'
        })
        return
      }
      updateAir({ deviceNumber: this.deviceNumber, value, fixedValue }).then(res => {
        console.log(res.msg, this.formData.faultCodes);
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: 'success',
            customClass: 'messageStyle'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error',
            customClass: 'messageStyle'
          })
        }
      })
    },
    //模式切换
    setAircondition(id) {
      if (this.formData.switchControl == '1') {
        this.formData.modeSetting = id
        this.airconditionconfig.btnone.forEach(item => {
          if (item.id == id) {
            item.isSelect = true
          } else {
            item.isSelect = false
          }
        })
        this.switchChange(id, 'MODE_COU')
      } else {
        this.$message({
          message: '请先打开开关',
          type: 'error',
          customClass: 'messageStyle',
        })
      }
    }
  }
}
</script>

<style lang="scss">
.messageStyle {
  padding: 30px!important;
  .el-message__icon {
    font-size: 100px !important;
    line-height: 100px !important;
  }

  .el-message__content {
    font-size: 100px !important;
    line-height: 100px !important;
  }
}

.dswindow {
  width: 100%;
  height: 100%;
  padding-top: 4vw;
  padding-right: 4vw;

  .airconditionbox {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url('~@/assets/gd/ds_aircondition.png') no-repeat;
    background-size: 100% 100%;
    padding: 7vw 6vw;

    .btn {
      background: url('~@/assets/gd/ds_closebtn.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -3.5vw;
      top: -4vw;
      width: 7.5vw;
      height: 8.5vw;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      >div {
        display: flex;
        align-items: flex-end;

        p {
          font-size: 3.5vw;
          line-height: 3.2vw;
          font-weight: bold;
          margin-right: 6.2vw;
          color: #fff;
        }

        .el-switch__core {
          margin-left: 2vw;
          height: 2.6vw;
          bottom: 0;
          position: absolute;
        }

        .el-switch__core:after {
          top: 0;
          width: 2.4vw;
          height: 2.4vw;
        }

        .el-switch.is-checked .el-switch__core::after {
          margin-left: -2vw;
        }
      }

      span {
        font-size: 2.2vw;
        line-height: 2.6vw;
        font-weight: normal;
        color: #fff;
      }
    }

    .mid {
      width: 57vw;
      margin: 0 auto;
      margin-top:11vw;
      display: flex;
      justify-content: space-between;

      div {
        color: #fff;
        font-size: 4vw;
        line-height: 4vw;
        font-weight: 700;

        p {
          font-size: 6.5vw;
          line-height: 6.5vw;
          margin-top: 4vw;
          color: #02d1ff;
        }

        .number {
          height: 6vw;
          width: 9vw;
          background: none;
          border: none;

          input {
            height: 6vw;
            width: 9vw;
            background: none;
            border: none;
            font-size: 7vw;
            font-weight: 700;
            color: #fff;
            padding: 0;
          }
          .is-disabled{
            input{
              color: #bbb!important;
            }
          }
        }
      }

      div:last-child p {
        color: #fff;
      }
    }

    .airconditionbtn {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 60vw;
      height: 15vw;
      margin: 0 auto;
      margin-top: 11vw;

      div {
        display: flex;
        align-items: flex-end;
        width: 19vw;
        height: 3.5vw;
        margin-bottom: 3.5vw;
        color: #07ebff;

        img {
          width: 4vw;
          height: 4vw;
        }

        span {
          margin-left: 2vw;
          font-size: 3.5vw;
          line-height: 3.5vw;
        }

        .select {
          color: #ffce14;
        }
      }

      div:hover {
        cursor: pointer;
      }
    }

    .footer {
      width: 65vw;
      margin: 0 auto;
      margin-top: 5vw;
      color: #fff;
      display: flex;
      justify-content: space-between;
      font-size: 3.5vw;

      div {
        width: 50vw;
        display: flex;
        flex-wrap: wrap;

        .el-radio {
          display: flex;
          align-items: flex-end;
          width: 10vw !important;
          height: 3.5vw !important;
          margin-right: 3.5vw;
          color: #07ebff !important;
          margin-bottom: 2.5vw !important;
        }

        .el-radio__label {
          font-size: 3vw !important;
        }

        .el-radio__input.is-checked .el-radio__inner {
          border: #ffce14 !important;
          background: #ffce14 !important;
          color: #ffce14 !important;
        }

        .el-radio__input.is-checked+.el-radio__label {
          color: #ffce14;
        }

        .el-radio__inner {
          width: 2.8vw;
          height: 2.8vw;
        }

        .el-radio__inner::after {
          width: 1.5vw !important;
          height: 1.5vw !important;
        }
      }
    }
  }
}</style>