<template>
  <div class="contain">
    <iframe
      :src="webUrl"
      width="100%"
      height="100%"
      scrolling="auto"
      frameborder="0"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  beforeRouteEnter(to, from, next) {
    console.log('to====>', to)
    next((vm) => {
      vm.webUrl = to.params.outLink || sessionStorage.getItem('outLink')
    })
  },
  title: '嵌套网页框',
  // name: 'DevicePandect',
  name: 'web',
  data() {
    return {
      webUrl: ''
    }
  },
  components: {},
  created() {},
  methods: {}
}
</script>

<style scoped lang="scss">
@import '~@/style/mixin.scss';
// 全屏状态
:-webkit-full-screen .contain {
  margin: 0 !important;
  height: 100vh;
  width: 1918px;
  iframe {
    width: 1918px;
    height: 100vh;
    transform: scale(1); /* 100 / 1920 = 0.0521 */
  }
}
.contain {
  // padding: 10px 15px;
  // height: calc(100% - 100px);
  margin: 15px 15px;
  background-color: $theme-color;
  height: 82vh;
  position: relative;
  overflow: hidden;
  iframe {
    width: 1918px;
    height: 929px;
    transform: scale(0.82); /* 100 / 1920 = 0.0521 */
    transform-origin: top left;
  }
}
</style>
