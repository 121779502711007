<template>
    <transition name="fade">
        <div class="boxdia" v-if="dialogVisible" @click.self="closeDialog">
            <div class="dialog">
                <div class="dialog_title">
                    {{ title }}
                </div>
                <div class="btn" @click="closeDialog"></div>
                <div class="interview">
                    <interview :title="title" :columns="columns" :header="false" :typeIndex="typeIndex" :tableData="tableData"></interview>
                </div>
                <div class="page">
                    <!-- :hide-on-single-page="filterData.total < 9 ? true:false" -->
                    <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="filterData.pageIndex"
                    :page-size="filterData.pageSize"
                    :page-count="3"
                    layout="total, prev, pager, next, slot"
                    :total="filterData.total"
                    >
                    <div class="jump">
                        <input v-model="filterData.pageIndex" @input="jumpPage()" />/
                        {{
                        Math.ceil(filterData.total / filterData.pageSize) == 0
                            ? 1
                            : Math.ceil(filterData.total / filterData.pageSize)
                        }}
                    </div>
                    </el-pagination>

                    <div class="goSystem" v-show="this.systemurl" @click="goSystem()">跳转</div>
                </div>
            </div>
        </div>
    </transition>
</template>
  
<script>
import interview from '../interview/index.vue'
export default {
    components:{
        interview
    },
    props: {
        title: {
            type: String,
            default: '图表名称'
        },
        columns: {
            type: Array,
            default: []
        },
        systemurl: {
            type: String,
            default: ''
        },
        typeIndex: {
            type: Boolean,
            default: false
        },
        dialogtype:{
            type:String,
            default:'电表'
        },
        getDatamethod:{
            type: Function,
            default: ()=>{}
        },
        yearAndMonth:{
            type:String,
            default:''
        },
        deviceCode:{
            type:String,
            default:''
        }
    },
    watch:{
        yearAndMonth(newValue){
            this.filterData.yearAndMonth = newValue
        },
        deviceCode(newValue){
            this.filterData.deviceCode = newValue
        },
        dialogtype(newValue){
            this.filterData.type = newValue
        }
    },
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            loading: false,
            rowForm: {},
            filterData: {
                // 部分接口分页是currentPage，部分是pageNum,故都传
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                yearAndMonth:this.yearAndMonth,
                type:this.dialogtype,
                deviceCode:this.deviceCode
            }
        }
    },
    methods: {
        showDialog(i,type='') {
            this.dialogVisible = true
            this.getInit(i,type)
        },
        closeDialog() {
            this.dialogVisible = false
        },
        getInit(i,type) {
            this.loading = true
            // 获取数据
            this.getDatamethod(this.filterData).then(({ data }) => {
                let dataList = data.data
                if (dataList[0]?.lastReading) {
                    dataList.map((item) => {
                        item.lastReading = Number(item.lastReading).toFixed(2)
                    })
                }
                if(type=='能耗空间排行'){
                    dataList[0].status = i <= 3 ? 1 : 5
                }
                this.tableData = dataList
                this.filterData.total = data.total
                this.loading = false
            })
        },
        searchInit() {
            this.loading = true
            // 获取数据
            this.getDatamethod(this.filterData).then(({ data }) => {
                let dataList = data.data
                if (dataList[0]?.lastReading) {
                    dataList.map((item) => {
                        item.lastReading = Number(item.lastReading).toFixed(2)
                    })
                }
                this.tableData = dataList
                this.filterData.total = data.total
                this.filterData.pageIndex = 1
                this.loading = false
            })
        },
        handleSizeChange(val) {
            this.filterData.pageSize = val
            this.getInit()
        },
        handleCurrentChange(val) {
            this.filterData.pageIndex = val
            this.getInit()
        },
        jumpPage() {
            this.filterData.pageIndex = this.filterData.pageIndex.replace(/\D/g, '') //过滤非数字
            if (
                this.filterData.pageIndex >=
                Math.ceil(this.filterData.total / this.filterData.pageSize)
            ) {
                this.filterData.pageIndex = Math.ceil(
                    this.filterData.total / this.filterData.pageSize
                )
                this.getInit()
            } else {
                this.getInit()
            }
        },
        goSystem() {
            if (this.systemurl) {
                this.$router.push(this.systemurl)
            }
        }
    }
}
</script>
  
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.boxdia {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 100;

    .dialog {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        width: 1552px;
        height: 800px;
        padding: 150px 240px 0 240px;
        background-image: url('~@/assets/gd/ds_chartwindow.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;

        .dialog_title {
            position: absolute;
            font-size: 22px;
            top: 112px;
            left: 50%;
            transform: translate(-50%, 0);
            color: #fff;
        }

        .btn {
            position: absolute;
            height: 44px;
            width: 38px;
            top: 60px;
            right: 120px;
            background-image: url('~@/assets/gd/ds_closebtn.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            z-index: 100;
        }

        .interview{
            width: 100%;
            height: 80%;
        }
        .page {
            position: absolute;
            bottom: 80px;
            right: 20%;
            display: flex;
            min-width: 30%;
            overflow: auto;
            margin-top: 5px;
            /deep/ .el-pager li {
                background: none;
                color: #ffffff70;
            }
            /deep/ .el-pager .active {
                color: #1d76e6;
            }
            /deep/ .el-pagination{
                padding-right: 70px;
            }
            /deep/ .el-pagination button {
                background: none;
                color: #ffffff70;
            }
            /deep/ .el-pagination__total {
                display: none;
            }
            .jump {
                color: #ffffff70;
                font-size: 13px;
                vertical-align: top;
                margin: 0;
                display: inline-block;
                input {
                    width: 50px;
                    height: 24px;
                    border-radius: 5px;
                    border: 1px solid #ffffff70;
                    background-color: #012856;
                    margin-right: 5px;
                }
            }
            .goSystem {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
                color: #fff;
                text-align: center;
                font-size: 13px;
                width: 50px;
                height: 24px;
                line-height: 24px;
                background: linear-gradient(180deg, #57bef5 0%, #006bff 100%);
                border-radius: 2px;
                opacity: 0.7;
                cursor: pointer;
            }
        }
    }

}</style>