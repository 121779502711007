<template>
  <transition name="fade">
    <div class="boxdia" v-if="dialogVisible" @click.self="closeDialog">
      <div class="dialog">
        <div class="dialog_title">
          {{ title }}
        </div>
        <div class="btn" @click="closeDialog"></div>
        <!-- 接收传递的图表 -->
        <div class="left">
          <div class="title">园区人数走势</div>
          <div class="chartBox">
            <ja-init-chart
              class="jachart"
              :options="options"
              :chartPlay="true"
              :chartPlaymodule="true"
            />
          </div>
          <div class="title">能耗对比</div>
          <div class="chartBox">
            <div class="chartMsg">
              <div><span :class="[middlePopUpsData.beforeYesterdayEnergy>middlePopUpsData.yesterdayEnergy?'yellow':'blue']"></span>前日</div>
              <div><span :class="[middlePopUpsData.beforeYesterdayEnergy<middlePopUpsData.yesterdayEnergy?'yellow':'blue']"></span>昨日</div>
            </div>
            <ja-init-chart
              class="jachart"
              :options="options3"
            />
          </div>
        </div>
        <div class="left">
          <div class="title">园区车辆走势</div>
          <div class="chartBox">
             <ja-init-chart
              class="jachart"
              :options="options2"
              :chartPlay="true"
              :chartPlaymodule="true"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
import CreatepersonChart from './personChart'
import CreatecarChart from './carChart'
import CreateenergyChart from './energy'
export default {
  props: {
    title: {
      type: String,
      default: '图表名称'
    },
    middlePopUpsData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  components:{
    JaInitChart
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      options:{},
      options2:{},
      options3:{},
    }
  },
  created(){
    this.init()
    console.log(CreatepersonChart);
  },
  methods: {
    showDialog(i) {
      this.dialogVisible = true
    },
    closeDialog() {
      this.dialogVisible = false
    },
     async init() {
      this.loading = false
      let personTimeSteam=[]; // 人时间数组
      let personWeatherList=[]; // 人流量数据
      let carTimeSteam=[]; // 车时间数组bottomTotal
      let carWeatherList=[]; // 车流量数据
      this.middlePopUpsData.weatherVO.map(item=>{
        if(item.type==2){
          personTimeSteam=item.timeSteam;
          personWeatherList=item.weatherList;
        }else if(item.type==3){
          carTimeSteam=item.timeSteam;
          carWeatherList=item.weatherList; 
        }
      })
      this.options = CreatepersonChart(personTimeSteam,personWeatherList)
      this.options2 = CreatecarChart(carTimeSteam,carWeatherList)
      
      let beforeYesterdayEnergy=this.middlePopUpsData.beforeYesterdayEnergy // 前日能源数据
      let yesterdayEnergy=this.middlePopUpsData.yesterdayEnergy // 昨日能源数据
      this.options3 = CreateenergyChart(beforeYesterdayEnergy,yesterdayEnergy, this)
    },
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.boxdia {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  .dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 1552px;
    height: 800px;
    padding: 150px 240px 0 240px;
    background-image: url('~@/assets/gd/ds_chartwindow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    .dialog_title {
      position: absolute;
      font-size: 22px;
      top: 112px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fff;
    }
    .btn {
      position: absolute;
      height: 44px;
      width: 38px;
      top: 60px;
      right: 120px;
      background-image: url('~@/assets/gd/ds_closebtn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 100;
    }
  }
  .left {
    // background: linear-gradient(to bottom, #1d1d1d, #262626);
    width: 50%;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 18px 30px 19px 30px;
    .title {
      background-image: url('~@/assets/gd/ds_dialogtitle.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #fff;
      width: 178px;
      height: 30px;
      line-height: 26px;
      padding: 0 10px;
    }
    .chartBox {
      position: relative;
      height: 240px;
      width: 100%;
      margin-bottom: 10px;
      .chartMsg{
        width: 139px;
        height: 23px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 10%;
        top: -28px;
        >div{
          border: 1px solid #DAFFE820;
          color: #9EE0FF;
          width: 67px;
          height: 24px;
          line-height: 24px;
          font-size: 11px;
          display: flex;
          align-items: center;

          span{
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: 0 10px 0 8px;
          }
          .blue{
            background-color: #49F8FF;
          }
          .yellow{
            background-color: #ECE71B;
          }
        }
      }
    }
    .l_box{
      background-color: #004278;
      opacity: 0.5;
      margin-top: 20px;
      height: 240px;
      width: 100%;
      border-radius: 2px;
    }
  }

}
</style>