<template>
    <ul class="content"
        v-loading="show">
        <li v-for="(key,index) in Object.keys(listObj)"
            :key="index">
            <div class="top">
                <div class="box-icon">
                    <img :src="listObj[key].icon"
                         alt=""
                         class="icon">
                </div>
                <p class="title">{{listObj[key].title}}</p>
            </div>
            <div class="bottom">
                <div class="box">
                    <p class="text">{{listObj[key].leftLabel}}</p>
                    <p :class="['num']">{{listObj[key].leftValue || 0}}</p>
                </div>
                <div class="box">
                    <p class="text">{{listObj[key].rightLabel}}</p>
                    <p :class="['num']">{{listObj[key].rightValue || 0}}</p>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import icon1 from '@/assets/images/home/tmpowerconsumption.png'
import icon2 from '@/assets/images/home/tmtotalalarm.png'
import icon3 from '@/assets/images/home/tmtraffic.png'
import icon4 from '@/assets/images/home/totalassets.png'
import { getHeaderList, getenergyCard } from '@/api'
export default {
    data() {
        return {
            listObj: [
                { id: 1, title: '设备（个）', leftLabel: 'IOT设备总数', rightLabel: '离线设备', icon: icon1 },
                { id: 2, title: '能源（电）(kW·h)', leftLabel: '昨日总耗', rightLabel: '本月累计', icon: icon3 },
                { id: 3, title: '能源（水）(m³)', leftLabel: '昨日总耗', rightLabel: '本月累计', icon: icon3 },
                { id: 4, title: '能源（冷）(kW·h)', leftLabel: '昨日总耗', rightLabel: '本月累计', icon: icon3 },
                { id: 5, title: '入驻租户（人）', leftLabel: '入驻人员总数', rightLabel: '上月入驻数量', icon: icon4 },
            ],
            show: false
        }
    },
    mounted() {
        this.getInit()
    },
    methods: {
        async getInit() {
            let { data: card } = await getenergyCard()
            this.show = true
            getHeaderList().then(res => {
                res.data.map(item => {
                    if (item) {
                        for (let i = 0; i < this.listObj.length; i++) {
                            if (item.id == this.listObj[i].id) {
                                item.leftValue=Math.round(item.leftValue)
                                item.rightValue=Math.round(item.rightValue)
                                this.listObj[i] = { ...this.listObj[i], ...item }
                                break
                            }
                        }
                    }
                })
                this.listObj[1] = { ...this.listObj[1], leftValue: Math.round(card.electricYesterdayEnergy), rightValue: Math.round(card.electricMonthEnergy) }
                this.listObj[2] = { ...this.listObj[2], leftValue: Math.round(card.waterYesterdayEnergy), rightValue: Math.round(card.waterMonthEnergy) }
                this.listObj[3] = { ...this.listObj[3], leftValue: Math.round(card.coolYesterdayEnergy), rightValue: Math.round(card.coolMonthEnergy) }
                this.$forceUpdate()
                this.show = false
            }).catch((err) => {
                return err
            })
           
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/theme.scss';

ul.content {
    color: #606266;
    display: flex;
    width: 100%;
    justify-content: space-between;
    li {
        width: 100%;
        background: white;
        margin-right: 20px;
        border-radius: 20px;
        &:last-child {
            margin-right: 0;
        }
        .top {
            display: flex;
            align-items: center;
            margin: 30px 0;
            .icon {
                margin: 0 20px 0 40px;
            }
        }
        .bottom {
            display: flex;
            margin: 0 0 20px;
            > .box {
                flex: 1;
                text-align: center;
                > p {
                    line-height: 1.5;
                    &.text {
                        font-size: 15px;
                    }
                    &.num {
                        font-size: 27px;
                        font-weight: bold;
                        color: $theme_color_333;
                    }
                }
            }
        }
        .red{
            color: red!important;
        }
    }
}
</style>