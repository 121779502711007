<template>
  <!--  结单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" title="结单审核">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
  </el-dialog>
</template>
<script>
import pageTypeObj from '../differPage'
import { agreeAduitOrder, rejectAduitOrder } from '@/api'
export default {
  inject: ['labelWidth', 'getInit', 'pageType'],
  data() {
    return {
      rowFormData: [
        { input_type: 'radio', label: '审核结果', key: 'status', col: 22, options: [{ label: '同意结单', value: 0 }, { label: '驳回结单', value: 1 }] },
        { input_type: 'input', label: '驳回理由', key: 'reason', type: 'textarea', col: 22, rows: 5 },
      ],
      rowForm: {},
      rules: {
        status: [{ required: true, message: '请选择', trigger: 'change' }],
        // reason: [{ required: true, message: '', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      rowFormData_indexObj: {},
    }
  },
  created() {
    this.rowFormData_indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },
  watch: {
    'rowForm.status': function (val) {
      let arr = [{ required: true, message: '请完善驳回理由', trigger: 'change' }]
      if (val == 1) {
        this.$refs.jaForm.$refs.formbox.validateField('reason')
        this.$set(this.rules, 'reason', arr)
      } else {
        this.$refs.jaForm.$refs.formbox.clearValidate('reason')
        this.$set(this.rules, 'reason', [])
      }

    }
  },
  methods: {
    rowEdit(row,) {
      this.rowForm = { id: row.id }
      this.showEditAll = true
    },
    saveForm() {
      this.loadingForm = true
      // const rqMethod = pageTypeObj[this.pageType].resOrder_fun
      if(this.rowForm.status == 0){
        agreeAduitOrder(this.rowForm.id).then(res => {
          this.loadingForm = false
          this.$message.success(res.msg || '提交成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          this.loadingForm = false
        })
      } else {
        rejectAduitOrder({ id: this.rowForm.id, reason: this.rowForm.reason || '' }).then(res => {
          this.loadingForm = false
          this.$message.success(res.msg || '提交成功')
          this.showEditAll = false
          this.getInit()
        }).catch(error => {
          this.loadingForm = false
        })
      }

    },
  }
}
</script>