<template>
  <!--人员布控列表 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="normalEvent({ref:'addEdit',method:'append'})" @rowEdit="normalEvent" @rowDelete="rowDelete" @getInit="getInit" @search="search" @reset="reset">
      <template #pictureUrl="{row}">
        <el-image style="width: 50px; height: 50px" :src="row.pictureUrl" :preview-src-list="[row.pictureUrl]" fit="cover"></el-image>
      </template>
    </ja-table>
    <!-- 新增 -->
    <add-edit ref="addEdit" />

  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import { rqTenantEmployee,delTenantEmployee } from '@/api'
export default {
  title: '租户人员列表',
  mixins: [table],
  components: {
    AddEdit,
  },
  data() {
    return {
      conditionData: [
        { input_type: 'select', key: 'tenantId', attribute: { placeholder: '租户名称', size: 'small' }, options: [] },
        { input_type: 'input', key: 'userName', attribute: { placeholder: '人员姓名', size: 'small' } },

        { normalBtn: 'search', limit: 'default:view'},
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增', },
      ],
      columns: [
        { label: '人员姓名', prop: 'userName', },
        { label: '性别', prop: 'sexName', },
        { label: '登陆用户名', prop: 'loginUserName', },
        { label: '人员类型', prop: 'type', },
        { label: '职位', prop: 'position', },
        { label: '租户名称', prop: 'tenantName', },
        { label: '手机号', prop: 'mobile', },
        { label: '状态', prop: 'statusName', },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '编辑', method: 'rowEdit', limit:'occupantManage::householdStaff::edit'},
            { text: '删除', method: 'rowDelete', limit:'occupantManage::householdStaff::delete' },
          ]
        },
      ],
      indexObj: {},

    }
  },
  mounted() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    // 计划类型下拉列表
    // const addEdit = this.$refs.addEdit   addEdit.rowFormData[addEdit.indexObj.tenantId]
    this.util.rqOptionsList({ who: [this.conditionData[this.indexObj.tenantId], ], rqUrl: "/tenant/selectList", resRule: 'data',  labelName: 'tenantName', valueName: 'id'})
  },
  methods: {
    getInit() {
      this.loading = false
      const rqData = this.rqParmas()
      rqTenantEmployee(rqData).then(res => {
        const { total, data } = res.data
        this.total = total
        this.tableData = data
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该条数据？','操作确认').then(res => {
        delTenantEmployee(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
  }
}
</script>
