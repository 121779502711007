<template>
    <el-main>
        <div id="player" width="100%" height="100%"></div>
    </el-main>
</template>
<script>
import PlayerManager from '@/api/module/icc/PlayerManager'

export default {
  props:{
    deviceNumber:{
      typeof:String,
      default:''
    }
  },
  data () {
    return {
      player: null
    }
  },
  mounted () {
    this.init()
  },
  watch:{
    deviceNumber(newval,oldval){
      this.deviceNumber = newval
      // this.monitorView(this.deviceNumber)
    }
  },
  methods: {
    init () {
      this.player = new PlayerManager({
        el: "player", /** 实时预览容器id，创建多个播放器，传入不同的容器id即可 **/
        pztEl: "ws-pan-tilt", // 云台控制容器id
        type: 'real', /** real - 实时预览  record - 录像回放 **/
        maxNum: 1, /** 最多显示的路数，可根据实际情况设置，可设置1 4 9 16 25等5种路数 **/
        num: 1, /** 初始化显示的路数 **/
        showControl: false, /** 默认是否显示工具栏 **/
        streamServerIp: process.env.VUE_APP_IP, // 流媒体服务 Ip 地址
        useNginxProxy: false, // 拉流模式 【true-代理模式拉流，false-直连拉流】
        receiveMessageFromWSPlayer: (methods, data) => {
          switch (methods) {
            case 'initializationCompleted':
              this.monitorView(this.deviceNumber)
              break;
          }
        }
      })
    },
    monitorView (deviceNumber) {
      this.realPlay(deviceNumber)
    },
    /* 播放 */
    realPlay (deviceNumber) {
      console.log(deviceNumber);
      const { player } = this
      player.playRealVideo({
        channelList: [{
          "id": deviceNumber,
          "isOnline": 1
        }]
      })
    }
  },
  beforeDestroy () {
    this.$bus.$off('TreeClick')
    this.$bus.$off('getFirstTreeData')
  }

}
</script>
<style lang="scss" scoped>
.el-main {
    // min-height: 400px;
    height: 100%;
    width: 100%;
    padding: 0;
}

@media screen and (max-width: 991px) {
    #player {
        width: 100%;
        height: 100%;
    }
}
@media screen and (min-width: 992px) {
    #player {
        width: 100%;
        height: 100%;
    }
}
</style>
