<template>
    <div class="box">
        <el-aside>
            <div class="buttonList">
                <el-input class="listBtn" v-model="groupName" placeholder="巡更组名称" @input="search"></el-input>
                <el-button class="listBtn" @click="reset">重置</el-button>
                <el-button class="listBtn" type="primary" icon="el-icon-plus" @click="addGroUp" >新增组</el-button>
                <el-button class="listBtn" type="primary" @click="setIntervals">间隔时间设置</el-button>
            </div>
            <table-new :showPage="false" @handRowClick="handRowClick" highlightCurrentRow noSearch :showIndex="false" :columns="columns" :tableData="tableData" :loading="loading" @getInit="getInit">
                <template #tabelIco="scope">
                    <span @click.stop="lookGroUp(scope.row)">查看</span>
                    <span @click.stop="editGroUp(scope.row)">编辑</span>
                    <span @click.stop="deleteGroUp(scope.row)">删除</span>
                    <!-- <i class="el-icon-edit" @click.stop="editGroUp(scope.row)" ></i>
                    <i class="el-icon-delete" @click.stop="deleteGroUp(scope.row)"></i> -->
                </template>
            </table-new>
            <div class="page">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="PageData.pageIndex"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="PageData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </el-aside>

        <add-edit-group ref="groUp" :maxOrder="maxOrder"></add-edit-group>

        <look-group ref="lookgroUp" :maxOrder="maxOrder"></look-group>

        <set-intervals ref="setIntervals"></set-intervals>
    </div>

</template>
<script>
import LookGroup from './LookGroup.vue'
import AddEditGroup from './AddEditGroup.vue'
import setIntervals from './SetIntervals.vue'
import TableNew from './tabelNew'
import table from '@/views/mixins/table'
import { patrolTeamPage, deletePatrolTeam } from '@/api'
export default {
    name: '巡更组列表',
    components: {
        AddEditGroup,
        setIntervals,
        LookGroup,
        TableNew,
    },
    mixins: [table,],
    data () {
        return {
            groupName:'',
            PageData: {
                pageIndex: 1,
                pageSize: 10,
            },
            columns: [
                { label: '巡更组编号', prop: 'groupCode'},
                { label: '巡更组名称', prop: 'groupName'},
                { label: '关联设备', prop: 'deviceNames'},
                {
                    label: '操作', slotName: 'tabelIco',
                },
            ],
            tableData: [],
            //计数
            currentRow: 0,
            //定时器
            timeoutObj: null,
            tableDataLength: null,
            loading: false,
            switchValue: null,
            maxOrder: null
        }
    },
    mounted () {
        //监听巡航开关
        this.$bus.$on('getSwitchValue', (val, time) => {
            this.switchValue = val
            if (val) {
                this.setTime(time)
            } else {
                this.clearTime()
            }
        })
    },
    methods: {
        getInit () {
            this.loading = true
            patrolTeamPage(this.PageData).then(res => {
                if (res.code !== 200) return this.$message.error('巡更组查询失败')
                this.tableData = res.data.data
                this.total = res.data.total
                this.maxOrder = res.data.length + 1
                // this.tableData.map(e => {
                //     if (e.deviceOrderList) {
                //         e.deviceOrderList.map((a, v) => {
                //             if (v % 2 === 0) {
                //                 a.url = 'ws://10.19.147.22:559/EUrl/q2jQie4'
                //             }
                //         })
                //     }

                // })
                this.tableDataLength = this.tableData.length - 1
                this.loading = false
                this.$bus.$emit('currentRowEvent', this.currentRow)
                this.$bus.$emit('setvideoStream', this.tableData[this.currentRow])
            })
        },
        handleSizeChange(val) {
        this.PageData.pageSize = val
        this.getInit()
        },
        handleCurrentChange(val) {
        this.PageData.pageIndex = val
        this.getInit()
        },
        //打开巡航  TODO
        setTime (time) {
            console.log(time);
            this.timeoutObj = setInterval(() => {
                if (this.currentRow < this.tableDataLength) {
                    this.currentRow++
                    this.$bus.$emit('currentRowEvent', this.currentRow)
                } else {
                    this.currentRow = 0
                    this.$bus.$emit('currentRowEvent', this.currentRow)
                }
                this.$bus.$emit('setvideoStream', this.tableData[this.currentRow])
            }, time);
        },
        buttonClick () {
            this.currentRow = 2
            console.log(this.currentRow);
        },
        //关闭巡航  TODO
        clearTime () {
            if (this.timeoutObj) {
                clearInterval(this.timeoutObj)
                this.timeoutObj = null
                this.switchValue = false
                this.$bus.$emit('closePatrol', false)
            }
        },
        //新增组
        addGroUp () {
            this.clearTime()
            this.$refs.groUp.init(true)
        },
        //查看组
        lookGroUp(row){
            this.clearTime()
            this.$refs.lookgroUp.init(row)
        },
        //编辑组
        editGroUp (row) {
            this.clearTime()
            this.$refs.groUp.init(false, row)
        },
        //删除组
        deleteGroUp (item) {
            this.clearTime()

            this.$confirm('此操作会改巡更组, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deletePatrolTeam(item.id).then(res => {
                    if (res.code !== 200) return this.$message.error('删除失败')
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        //间隔时间设置
        setIntervals () {
            this.clearTime()
            this.$refs.setIntervals.init()
        },
        handRowClick (row) {
            let length = this.tableData.findIndex(e => e.id === row.id)
            if (!this.switchValue) {
                this.currentRow = length
                this.$bus.$emit('setvideoStream', row)
            }

        },
        search(){
            this.loading = true
            patrolTeamPage({pageIndex:this.PageData.pageIndex,pageSize:this.PageData.pageSize,groupName:this.groupName}).then(res => {
                if (res.code !== 200) return this.$message.error('巡更组查询失败')
                this.tableData = res.data.data
                this.total = res.data.total
                this.maxOrder = res.data.length + 1
                this.tableDataLength = this.tableData.length - 1
                this.loading = false
                this.$bus.$emit('currentRowEvent', this.currentRow)
                this.$bus.$emit('setvideoStream', this.tableData[this.currentRow])
            })
        },
        reset(){
            this.groupName = ''
            this.init()
        }
    },
    beforeDestroy () {
        this.$bus.$off('getSwitchValue')
    }


}
</script>
<style lang="scss" scoped>
.box{
    width: 100%;
    .page{
        float: right;
    }
}
.el-aside {
    width: 100% !important;
    position: relative;
    margin: 0 10px;
    height: 80vh;
    background-color: #fff;
    padding: 10px 5px;
}
.buttonList {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /deep/ .el-input{
        width: 150px;
    }
    /deep/ .el-input__inner{
        height: 35px!important;
    }
    .listBtn{
        padding: 0 10px!important;
        height: 35px!important;
    }
}
.el-icon-delete,
.el-icon-edit:hover {
    cursor: pointer;
}
.el-icon-delete,
.el-icon-edit {
    color: #409eff;
    margin: 0 5px;
}
.box{
    span{
        color: #409eff;
        margin-right: 5px;
    }
    span:hover{
        cursor: pointer
    }
}
</style>    