<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowDelete="rowDelete" >
    </ja-table>

  </div>
</template>
<script>
import table from '@/views/mixins/table'


export default {
  title: '操作日志',
  mixins: [table,],
  name: 'index',
  components: {
  },
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
        { input_type: 'select', key: 'tag', attribute: { placeholder: '日志类型', size: 'small' }, options: [], },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
      ],
      columns: [
        { label: '用户名称', prop: 'tagName' },
        { label: 'IP地址', prop: 'title' },
        { label: '日志类型', prop: 'contentText' },
        { label: '联系方式', prop: 'author', },
        { label: '状态', prop: 'author', },
        { label: '描述', prop: 'author', },
        { label: '记录时间', prop: 'author', },
      ],
      con_indexObj: {},
    }
  },
  mounted() {
    this.con_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.util.rqOptionsList({ who: [this.conditionData[this.con_indexObj.tag],], rqUrl: "/sys/dictData/news_tag", rqMethod: 'get', resRule: 'data' })
  },
  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      let data = this.rqParmas()
    },
  }
}
</script>
