import http from './plugin/ajax'

/*
    能源统计
**/

export const getAnnualConsumption = () => http.get('/iot/energyConsumptionAmount') //年度用量

//==========水电明细=========
export const getWaterElList = (data) => http.post('/iotElectricityWaterMeter/list', data) //查询列表
export const editWaterElList = (data) => http.put('/iotElectricityWaterMeter', data) //修改

export const waterPage = (data) => http.post('/energyQuery/waterPage', data) //水抄表分页查询

export const electricityPage = (data) => http.post('/energyQuery/electricityPage', data) //电抄表分页查询

export const coolPage = (data) => http.post('/energyQuery/coolPage', data) //冷抄表分页查询

export const energyThreeStatistics = (data) => http.post('/energyQuery/threeStatistics', data) //能耗分析统计
export const energySort = (data) => http.post('/energyQuery/energySort', data) //驻户能源排名
export const spaceEnergySort = (data) => http.post('/energyQuery/spaceEnergySort', data) //空间能源排名
export const classifyEnergySort = (data) => http.get('/energyQuery/classifyEnergySort/'+ data) //电表专业分类排名

