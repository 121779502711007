<template>
  <div class="entry bg" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="leftgif"></div>
    <div class="rightgif"  @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave"></div>
    <div class="left">
      <div class="flow">
        <sectorChart :equipmentData="equipmentData" v-if="loading" />
      </div>
      <div class="flow" @click="openeQuipmentAlarm">
        <alarmChart :alarmList="deviceInfoList" v-if="loading" />
      </div>
      <div class="flow">
        <interview :columns="repairColumns" title="维修详情列表" :tableData="repairTableData" v-if="loading" />
      </div>
    </div>
    <div class="right"  @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
      <div class="flow" >
        <titleChart :formData="topValue" @checkChart="checkChart" v-if="loading"  ref="titleChart"/>
      </div>
      <div class="flow">
        <vehicleAnalysis ref="vehicleAnalysis" :alarmList="bodyValue" @clickOn="clickOn" :typeData="type"
          v-if="loading" />
      </div>
      <div class="flow">
        <interview :columns="interviewColumns" title="能耗空间排行" :tableData="interviewTableData" :typeIndex="true"
          v-if="loading" @rowClick="rowClick" />
      </div>
    </div>
    <div class="systembtn">
      <systemButton title="智慧能源" />
    </div>
    <div class="bottom"></div>

    <Pop ref="dialog" title="告警事件详情" :typeIndex="false" dialogtype="2" :columns="columns" :getDatamethod="equipmentAlarm"
      :systemurl="systemurl">
    </Pop>
    <Pop ref="dialog1" title="空间抄表详情" :columns="columns" :getDatamethod="getEnergySpace" :deviceCode="deviceCode"
      :dialogtype="formData.type" :systemurl="systemurl">
    </Pop>
    <Pop ref="dialog2" :title="dialog2Title" :columns="columns" :getDatamethod="getEnergyTrends"
      :yearAndMonth="formData.yearAndMonth" :dialogtype="formData.type" :systemurl="systemurl">
    </Pop>
  </div>
</template>

<script>
import headers from '../../../../dsWindow/IOC/header/index.vue'
import sectorChart from './sectorChart/sectorChart.vue'
import alarmChart from '../components/alarmChart/index.vue'
import interview from '../components/interview/index.vue'
import titleChart from './titleChart/titleChart.vue'
import Pop from '../components/pop/index.vue'
import vehicleAnalysis from './vehicleAnalysis/vehicleAnalysis.vue'
import { getEnergy, equipmentAlarm, getEnergyTrends, getEnergySpace, getSpecificEnergy } from '@/api'
import systemButton from '../components/systemButton/index.vue'

export default {
  title: '智慧能源',
  name: 'wisdomDnergy',
  components: {
    headers,
    sectorChart,
    alarmChart,
    vehicleAnalysis,
    interview,
    Pop,
    titleChart,
    systemButton
  },
  data() {
    return {
      equipmentData: [
        { name: '电表设备', label: '', value: 100, fangOnline: 1, fangOffOnline: 2, options: {} },
        { name: '水表设备', label: '', value: 100, fangOnline: 1, fangOffOnline: 2, options: {} },
        { name: '冷量表设备', label: '', value: 100, fangOnline: 1, fangOffOnline: 2, options: {} },
      ],
      deviceInfoList: [],
      // 能耗表头
      interviewColumns: [
        { label: '空间', prop: 'space' },
        { label: '耗电量(kW·h)', prop: 'energy', width: '130px' }
      ],
      //维修表头
      repairColumns: [
        { label: '设备名称', prop: 'workOrderCategory' },
        { label: '所在位置', prop: 'position' },
        { label: '工单状态', prop: 'status', type: 1 }
      ],
      //维修表格数据
      repairTableData: [],
      //能耗表格数据
      interviewTableData: [],
      equipmentAlarm,
      getEnergyTrends,
      getEnergySpace,
      columns: [],
      form: {
        type: 2,
        pageIndex: 1,
        pageSize: 10,
      },
      formData: {//水冷电 类型
        type: "电表",
        yearAndMonth: ''
      },
      dialog2Title: '',//能源趋势弹窗标题
      dialog2Data: {},//能源趋势弹窗数据
      dialogData: {},//告警事件详情
      dialog1Data: {},//空间抄表详情
      deviceCode: '',//空间的设备码
      type: { name: '电', ulit: 'kW·h' },//水电冷名称与单位
      topValue: {},
      bodyValue: [],
      systemurl: '',
      dataInterval: null,
      loading: false
    }
  },
  mounted() {
    this.$emit('loading')
    this.startDataInterval()
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
  },
  methods: {
    startDataInterval() {
      this.init()
      this.dataInterval = setInterval(() => {
        this.loading = false
        this.init()
      }, this.TIMER_INTERVAL);
    },
    async init() {
      //获取左边的数据
      const { data } = await getEnergy()
      this.loading = true
      const statistics = [
        {
          label: `${data.totalElectricityMeter || 0}kW·h`,
          class: Number(data.totalElectricityMeter) <= 0 ? 'red' : '',
          value: data.totalElectricity,
          fangOffOnline: data.offlineElectricity,
          fangOnline: data.onlineElectricity
        },
        {
          label: `${data.totalWaterMeter || 0}m³`,
          class: Number(data.totalWaterMeter) <= 0 ? 'red' : '',
          value: data.totalWater,
          fangOffOnline: data.offlineWater,
          fangOnline: data.onlineWater,
        },
        {
          label: `${data.totalCoolMeter || 0}kW·h`,
          class: Number(data.totalCoolMeter) <= 0 ? 'red' : '',
          value: data.totalCooling,
          fangOffOnline: data.offlineCooling,
          fangOnline: data.onlineCooling,
        }
      ]
      this.threeStatistics(statistics)
      this.pedestrianAlarm(data.alarmList)
      this.repairTableData = data.repairList.length ? data.repairList : data.repairList.slice(0, 9)
      this.checkChart({ name: '电表', ulit: 'kW·h' })
    },
    threeStatistics(data) {
      //配置图标数据
      this.equipmentData.map((item, index) => {
        item.label = data[index].label
        item.value = data[index].value
        item.class = data[index].class
        item.fangOnline = data[index].fangOnline
        item.fangOffOnline = data[index].fangOffOnline
        item.options = {
          color: ['#FFDD30', '#54FFD2'],
          series: [
            {
              // 内圆刻度
              type: 'gauge',
              startAngle: '0',
              endAngle: '360',
              radius: '40%',
              splitNumber: 30,
              center: ['50%', '50%'],
              z: 4,
              axisLine: {
                show: false
              },
              pointer: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                length: 2,
                lineStyle: {
                  color: '#002f64 ',
                  width: 1
                }
              },
              axisLabel: {
                show: false
              },
              detail: {
                show: false
              }
            },
            {
              name: '',
              type: 'pie',
              radius: ['30%', '65%'],
              startAngle: '0',
              endAngle: '360',
              avoidLabelOverlap: false,
              hoverOffset: 0,
              label: {
                show: false,
                position: 'left'
              },
              emphasis: {
                label: {
                  show: false
                }
              },
              labelLine: {
                show: false
              },
              data: [item.fangOnline, item.fangOffOnline]
            }
          ]
        }
      })
    },
    //获取告警数据
    pedestrianAlarm(data) {
      this.deviceInfoList = data.map(item => {
        return {
          value: item.numberOfAlarms,
          name: item.alarmTypeName
        }
      })
    },
    //打开告警弹窗
    openeQuipmentAlarm() {
      this.columns = [
        { label: '事件名称', prop: 'eventName' },
        { label: '触发时间', prop: 'eventTime' },
        { label: '状态', prop: 'status', width: '120px' }
      ]
      this.$refs.dialog.showDialog();
    },
    //打开能耗空间排行弹窗
    rowClick(val) {
      this.deviceCode = val.deviceCode
      this.columns = [
        { label: '空间名称', prop: 'spaceName' },
        { label: '抄表时间', prop: 'meterReadingTime' },
        { label: '状态', prop: 'status', width: '120px', type: 5 }
      ]
      this.$refs.dialog1.showDialog(val.index, '能耗空间排行');
    },
    //切换水冷电
    async checkChart(i) {
      this.formData.type = i.name
      const formData = new FormData()
      formData.append('type', i.name)
      const { data } = await getSpecificEnergy(formData)
      this.topValue = {
        lastMonth: data.lastMonth,
        sequentialGrowth: data.sequentialGrowth
      } //耗电量
      this.bodyValue = []
      data.trends.map(item => {
        this.bodyValue.unshift(item)
      })
      this.type = i
      this.interviewTableData = data.energySpace.length <= 9 ? data.energySpace : data.energySpace.slice(0, 9)
      this.interviewColumns[1].label = '耗' + i.name.slice(0, 1) + '量' + '（' + i.ulit + '）'
      //重置能耗趋势动画轮播
      this.$refs.vehicleAnalysis.setTime()
    },
    //能源趋势弹窗
    async clickOn(data) {
      this.dialog2Title = data.date + '月' + this.formData.type + '详情'
      const date = new Date().getFullYear() + '-' + data.date
      this.formData.yearAndMonth = this.$moment(date).format('YYYY-MM')
      this.columns = [
        { label: '设备名称', prop: 'spaceName' },
        { label: '抄表日期', prop: 'date' },
        { label: '上期读数', prop: 'lastReading' },
        { label: '本期读数', prop: 'thisReading' },
        { label: '本期用量', prop: 'currentReading' },
      ]
      this.$refs.dialog2.showDialog();
    },
    openDialog() {
      this.$refs.dialog.showDialog();
    },
    /**右侧鼠标移上去 */
    handleMouseEnter(){
      this.$refs.titleChart.handleMouseEnter()
    },
     /**右侧鼠出去去 */
    handleMouseLeave(){
      this.$refs.titleChart.handleMouseLeave()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';

.bg {
  height: 100%;
  position: relative;
  overflow: hidden;

  .scene {
    width: 100%;
    height: 100%;
    transform: scale(1);
  }
}

.entry {
  width: 100%;
  position: relative;

  // height: 5vw;
  // 导航栏
  .top {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.png');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .leftgif,
  .rightgif {
    position: absolute;

    height: 90vh;
    width: 24%;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    z-index: 1;
    pointer-events: none;
  }

  .leftgif {
    background: url('~@/assets/gd/left.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: 10px;
  }

  .rightgif {
    right: 10px;
    background: url('~@/assets/gd/right.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .left,
  .right {
    position: absolute;
    height: 90vh;
    width: 24%;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;

    .flow {
      height: 33%;
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .left {
    background: url('~@/assets/gd/left.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: 10px;
  }

  .right {
    right: 10px;
    background: url('~@/assets/gd/right.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .bottom {
    height: 38px;
    width: 66.5%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }

  .systembtn {
    width: 36%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 85px;
  }
}
</style>
