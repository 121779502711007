<template>
  <div class="content">
    <div class="top">
      <el-tabs v-model="activeMenu" @tab-click="handleClick" class="menu">
        <el-tab-pane v-for="(item,index) in menuList" :key="index" :label="item.label" :name="item.value">
        </el-tab-pane>
      </el-tabs>
      <p class="link">查看详情>></p>
    </div>
    <div class="list">
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <div class="info">
            <p class="p1">{{item.title}}</p>
            <div class="p2">
              <span>申请人：{{item.createBy}}</span>
              <span>申请时间：{{item.crateTime}}</span>
            </div>
          </div>
          <div class="btn">
            <el-button type="primary" size="small" v-if="isAuth('cockpit::listBox::audit')">去审批</el-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: 'approval',
      menuList: [
        { label: '我的审批', value: 'approval' },
        { label: '待办工单', value: 'order' },
        { label: '系统消息', value: 'msg' },
      ],
      list: [
        // { title: '访客审批', createBy: '王卫国', crateTime: '2021-08-08 16:00:56', },
        // { title: '维修工单维修确认', createBy: '王卫国', crateTime: '2021-08-08 16:00:56', },
        // { title: '访客审批', createBy: '王卫国', crateTime: '2021-08-08 16:00:56', },
        // { title: '维修工单维修确认', createBy: '王卫国', crateTime: '2021-08-08 16:00:56', },
        // { title: '访客审批', createBy: '王卫国', crateTime: '2021-08-08 16:00:56', },
      ],
    }
  },
  methods:{
      handleClick(){
          
      }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/theme.scss';

.content {
  color: #606266;

  width: 100%;
  background: white;
  .top {
    display: flex;
    align-items: center;
    margin: 30px 30px 0 30px;
    justify-content: space-between;
    .link {
      font-size: 14px;
      color: $theme_color_333;
      cursor: pointer;
      &:hover{
          color: #409EFF;
      }
    }
    .menu {
      width: 64%;
      /deep/ .el-tabs__header {
        margin-bottom: 0;
      }
      /deep/ .el-tabs__nav-scroll {
        background: white !important;
      }
    }
  }
  .list {
    ul {
      margin: 10px 30px 30px;
      li {
          display: flex;
          align-items: center;
          justify-content: space-between;
            border-bottom: 1px solid #dcdfe6;
        .info {
            margin: 20px 0 10px;
          .p1 {
            color: #5e6d82;
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 15px;
          }
          .p2{
              font-size: 14px;
              color: grey;
              >span:first-child{
                  margin-right: 40px;
                  display: inline-block;
              }
          }
        }
      }
    }
  }
}
</style>