<template>
  <router-view />
</template>

<script>
export default {
  title: '租户管理',
  name: 'OccupantManage'
}
</script>

<style scoped>

</style>
