<template>
    <div class="allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
            :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset"
            @rowDetail="normalEvent" @rowEdit="detail">
            <template #status="{ row }">
                <span>
                    {{ filterStatus(row.status)}}
                </span>
            </template>
        </ja-table>
        <Detail ref="detail" />
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import Detail from './Detail.vue'
import { getVisitorPage } from '@/api'
export default {
    title: "访客记录",
    name: 'visitor',
    mixins: [table],
    components: { Detail },
    data() {
        return {
            conditionData: [
                { input_type: 'date', key: 'time', value: 'time', rangeSeparator: '-', type: 'daterange', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
                { input_type: 'input', key: 'visitName', attribute: { placeholder: '访客姓名', size: 'small' } },
                { input_type: 'input', key: 'phoneNumber', attribute: { placeholder: '手机号', size: 'small' } },
                { input_type: 'input', key: 'name', attribute: { placeholder: '被访人', size: 'small' } },
                { input_type: 'input', key: 'departmentName', attribute: { placeholder: '被访单位', size: 'small' } },
                { input_type: 'select', key: 'status', attribute: { placeholder: '审批状态', size: 'small' },options:[
                    { value: '0', label: '待审批' },
                    { value: '1', label: '审批拒绝' },
                    { value: '2', label: '审批通过' },
                    { value: '3', label: '已过期' }
                ] },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', exportObj: { rqParmas: this.rqexport, rqUrl: '/visitor/getVisitorExport', rqMethod: 'post' } }
            ],
            columns: [
                { label: '拜访日期', prop: 'visitDate' },
                { label: '访客姓名', prop: 'visitName' },
                { label: '手机号', prop: 'phoneNumber' },
                { label: '被访人', prop: 'name' },
                { label: '被访单位', prop: 'departmentName' },
                { slotName:'status', label: '审批状态', prop: 'status' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 140,
                    attribute: {
                        fixed: 'right',
                        showOverflowTooltip: false,
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'rowEdit', limit: 'wisdomPeople::visitor::look' },
                    ]
                }
            ],
            tableData: [],//列表数据
            DictList: [],//字典数据
        }
    },
    computed: {
        typeFilter() {
            return (key) => {
                let value = ''
                this.DictList.some(item => {
                    if (item.dictValue === key) {
                        value = item.dictLabel
                        return true
                    }
                })
                return value
            }
        },
        filterStatus(){
            return (key) => {
                // '状态0：待审批1：审批拒绝2：审批通过3：已过期',
                const arr = ['待审批', '审批拒绝', '审批通过', '已过期']
                return arr[key]
            }
        }
    },
    methods: {
        rqexport() {
            let obj = { ...this.filterData }
            obj.pageIndex = this.total <= 5000 ? 1 : this.filterData.pageIndex
            obj.pageSize = 5000
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        rqParmas2() {
            let obj = this.rqParmas()
            if (obj.time) {
                obj.startTime = obj.time[0]
                obj.endTime = obj.time[1]
                delete obj.time
            }
            return obj
        },
        async getInit() {
            this.loading = true
            let obj = this.rqParmas2()
            const { data } = await getVisitorPage(obj)
            this.tableData = data.data
            this.total = data.total
            this.loading = false
        },
        detail(row){
            this.$refs.detail.rowDetail(row)
        }
    }
}
</script>
  
<style lang="scss" scoped>
::v-deep .cellImg {
    display: flex;
    margin: auto;

    >img {
        max-height: 23px;
        width: 35px;
        display: flex;
        margin: auto;
    }
}

.red{
    color: #d50e21;
}
.green{
    color: #99f23b;
}

::v-deep .errorImg {
    width: 100%;
}
</style>
  