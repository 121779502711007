<template>
  <div class="chartone">
    <div class="header">
      <div class="headerleft">设备状态总览</div>
    </div>

    <div class="chart">
      <div>
        <ja-init-chart ref="chart" class="jachart" :options="options" />
        <div>{{optionsData.fang}}</div>
        <p>传感器设备</p>
        <p>离线:{{optionsData.fangOnline}}</p>
        <p>在线:{{optionsData.fangOffOnline}}</p>
      </div>
      <div>
        <ja-init-chart ref="chart" class="jachart" :options="options2" />
        <div>{{optionsData.air}}</div>
        <p>空调设备</p>
        <p>离线:{{optionsData.ariOnline}}</p>
        <p>在线:{{optionsData.airOffOnline}}</p>
      </div>
      <div>
        <ja-init-chart ref="chart" class="jachart" :options="options3" />
        <div>{{optionsData.thermostat}}</div>
        <p>温控器设备</p>
        <p>离线:{{optionsData.thermostatOnline}}</p>
        <p>在线:{{optionsData.thermostatOffOnline}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getHvacsecurity } from '@/api'
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  name: 'water',
  components: {
    JaInitChart
  },
  data() {
    return {
      options: {},
      options2: {},
      options3: {},
      loading: true,
      optionsData:[],
    }
  },
  created() {
    // this.init()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapState({
      buildingFloor: (state) => state.visualsystem.buildingFloor
    })
  },
  watch: {
    buildingFloor(newVal) {
      this.init(newVal)
    }
  },
  methods: {
    handleResize() {
      this.$refs.chart.myChart.resize()
    },
    async init(i) {
      let {data:res} = await getHvacsecurity({
        buildingId: i.buildingId,
        floorId: i.floorId
      })
      this.optionsData = res.device

      this.loading = false
      this.options = {
        color: ['#FFDD30', '#54FFD2', '#1872FF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '90',
            endAngle: '-269.9999',
            radius: '40%',
            splitNumber: 30,
            center: ['50%', '50%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 2,
              lineStyle: {
                color: '#002f64 ',
                width: 1
              }
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['30%', '65%'],
            avoidLabelOverlap: false,
            hoverOffset: 0,
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: [res.device.fangOnline, res.device.fangOffOnline]
          }
        ]
      }

      this.options2 = {
        color: ['#FFDD30', '#54FFD2', '#1872FF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '90',
            endAngle: '-269.9999',
            radius: '40%',
            splitNumber: 30,
            center: ['50%', '50%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 2,
              lineStyle: {
                color: '#002f64 ',
                width: 1
              }
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['30%', '65%'],
            avoidLabelOverlap: false,
            hoverOffset: 0,
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: [res.device.ariOnline, res.device.airOffOnline]
          }
        ]
      }

      this.options3 = {
        color: ['#FFDD30', '#54FFD2', '#1872FF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '90',
            endAngle: '-269.9999',
            radius: '40%',
            splitNumber: 30,
            center: ['50%', '50%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 2,
              lineStyle: {
                color: '#002f64 ',
                width: 1
              }
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['30%', '65%'],
            avoidLabelOverlap: false,
            hoverOffset: 0,
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: [res.device.thermostatOnline, res.device.thermostatOffOnline]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .header {
  left: 0 !important;
  top: 20px !important;
}
.chartone {
  width: 100%;
  height: 100%;
  position: relative;
  .header {
    position: absolute;
    left: 5px;
    top: 15px;
    height: 26px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .chart {
    margin-top: 50px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    >div{
      color: #fff;
      font-size: 12px;
      text-align: center;
      p{
        margin-top: 5px;
      }
    }
    .jachart {
      margin-bottom: 5px;
      width: 100px;
      height: 100px;
      background-image: url('~@/assets/gd/chartbg1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>