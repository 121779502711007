<template>
    <div class="title_chart">
        <div class="chartbtn">
            <div :class="chartIndex == index ? 'selected' : ''" @click="checkChart(index)" v-for="(item, index) in chartBtn"
                :key="index">
                {{ item.title }}
            </div>
        </div>
        <div class="chart_div">
            <div class="div_img">
                <img :src="url[chartIndex]">
            </div>
            <div class="div_bg">
                <div>上月耗{{ chartBtn[chartIndex].title }}<span :style="{color:formData.lastMonth <=0 ? 'red' : '#fdcf42'}">{{ formData.lastMonth }}</span>{{ chartBtn[chartIndex].ulit }}</div>
                <div>
                    <span :class="{'green':formData.sequentialGrowth < 0,'red':formData.sequentialGrowth > 0}">{{ formData.sequentialGrowth === null ? '——' : Math.abs(formData.sequentialGrowth)+'%' }}</span>
                    <img :src="formData.sequentialGrowth <= 0 ? imgUrl[1] : imgUrl[0]">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            url: [
                require('../../../../../../assets/images/gd/electricity@2x.png'),
                require('../../../../../../assets/images/gd/cold.png'),
                require('../../../../../../assets/images/gd/water@2x.png')
            ],
            imgUrl: [
                require('../../../../../../assets/gd/build_bg_btnup.png'),
                require('../../../../../../assets/gd/build_bg_btn.png')
            ],
            chartBtn: [{ title: '电', ulit: 'kW·h' }, { title: '冷', ulit: 'kW·h' }, { title: '水', ulit: 'm³' }],
            chartIndex: 0,
            timer:null
        };
    },
    props: {
        formData: {
            type: Object,
            default: ()=>{
                return {}
            }
        }
    },
    created () {
        this.carousel()
    },
    methods: {
        checkChart(i) {
            const arr = ['电表','冷量表','水表']
            this.chartIndex = i;
            this.$emit('checkChart', {name:arr[i],ulit:this.chartBtn[i].ulit});
        },
        //轮播水电
        carousel(){
            this.timer=setInterval(() => {
                this.chartIndex++
                if(this.chartIndex>=3){
                this.chartIndex=0
                }
                this.checkChart(this.chartIndex)
            }, 3000);
        },
        handleMouseEnter(){
            clearInterval(this.timer)
        },
        handleMouseLeave(){
            this.carousel()
        }
    }
};
</script>
<style lang='scss' scoped>
.title_chart {
    width: 100%;
    height: 100%;
    padding-top: 60px;
    position: relative;

    .chartbtn {
        position: absolute;
        top: 30px;
        right: 20px;
        display: flex;
        z-index: 20;

        div {
            width: 66px;
            height: 24px;
            border: none;
            color: #fff;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            background-image: url('~@/assets/gd/ds_chatbtn.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }

        div:hover {
            cursor: pointer;
            background-image: url('~@/assets/gd/ds_chatbtnx.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }

        div.selected {
            background-image: url('~@/assets/gd/ds_chatbtnx.png');
        }
    }

    .chart_div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .div_img {
            width: 120px;
            height: 110px;
            margin-left: 20px;
            margin-right: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .div_bg {
            div {
                width: 234px;
                height: 45px;
                padding-left: 20px;
                font-size: 18px;
                position: relative;
                display: flex;
                align-items: center;
                color: #41d8cc;
                margin-bottom: 30px;
                background-image: url('~@/assets/images/gd/title_chart@2x.png');

                img{
                    position: absolute;
                    width: 50px;
                    height: 30px;
                    left: calc(50% - 25px);
                }
            }

            .red{
                color: red;
            }
            .green{
                color: #20ec97;
            }
        }
    }
}
</style>