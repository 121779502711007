<template>
  <div>
    <JaTable
      :conditionData="conditionData"
      :columns="occupantInfoColumn"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      v-model="filterData"
      @append="append"
      @rowEdit="rowEdit"
      @rowDelete="rowDelete"
      @delTenant="delTenant"
      @getInit="getInit"
      @search="search"
      :showIndex="true"
      @reset="reset" 
      @getContract="getContract"
      :editDataFormat="editDataFormat"
      @turnMeterReading="turnMeterReading"
      @exportFun="exportFun"
      :valueFormat="formatter"
      @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/租户导入模板.xlsx', filename: '租户导入模板', uploadURL: '/tenant/upload'})"
    >
      <template #tenantName="{row}">
        <div
          class="firstcell el-button--text"
          @click="seeDetails(row)"
        >{{row.newTenantName}}</div>
      </template>
    </JaTable>
    <AddEdit
      :show.sync="show"
      ref="addEdit"
      :allDisabled.sync="allDisabled"
      v-if="show"
      @closeForm="closeForm"
      :editRowForm="rowForm"
      :title="title" 
    />
     <!-- 导出弹窗 -->
    <import-form ref='importForm' @openResult="openResult" />
     <!-- 校验结果 弹窗 -->
    <check-result ref="checkResult" :results="results" />
  </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import {  occupantInfoColumn } from './occupantInfoColumn'
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit'
import importForm from '../common/importForm'
import checkResult from '../common/checkResult'
import {
  getstatusType,
  getDictData,
  addTenant,
  editTenant,
  exportTenant,
  rqTenant,
  delTenant,
  auditTenant,
  submitTenant
} from '@/api'

export default {
  title: '租户信息',
  mixins: [table],
  name: 'OccupantInfo',
  components: {
    JaTable,
    AddEdit,
    importForm,
    checkResult
  },
  data() {
    return {
      results: null,
      conditionData:[
  {
    text: '租户名称',
    input_type: 'input',
    key: 'tenantName',
    attribute: {
      placeholder: '租户名称',
      size: 'small'
    }
  },
  {
    text: '编号',
    input_type: 'input',
    key: 'tenantCode',
    attribute: {
      placeholder: '编号',
      size: 'small'
    }
  },
  {
    text: '租户类型',
    input_type: 'select',
    key: 'category',
    attribute: {
      placeholder: '租户类型',
      size: 'small',
      clearable: true
    },
    options: function () {
      return getDictData('tenant_category').then((res) => {
        if (res.code == 200) {
          let data = res.data.map((item) => {
            return {
              label: item.dictLabel,
              value: item.dictValue
            }
          })
          return data
        }
      })
    } 
  },
  {
    text: '租户属性',
    input_type: 'select',
    key: 'attribute',
    attribute: {
      placeholder: '租户属性',
      size: 'small',
      clearable: true
    },
    options: function () {
      return getDictData('tenant_attribute').then((res) => {
        if (res.code == 200) {
          let data = res.data.map((item) => {
            return {
              label: item.dictLabel,
              value: item.dictValue
            }
          })
          return data
        }
      })
    } 
  },
  {
    text: '纳税人识别号',
    input_type: 'input',
    key: 'unifiedSocialCreditCode',
    attribute: {
      placeholder: '纳税人识别号',
      size: 'small'
    }
  },
  {
    text: '身份证号',
    input_type: 'input',
    key: 'idCard',
    attribute: {
      placeholder: '身份证号',
      size: 'small'
    }
  },
  {
    text: '联系方式',
    input_type: 'input',
    key: 'contactNumber',
    attribute: {
      placeholder: '联系方式',
      size: 'small'
    }
  },
  { normalBtn: 'search', limit: 'default:view' },
  { normalBtn: 'reset' },
  { normalBtn: 'add',limit:'resident::manage::add' },
  { normalBtn: 'import', text: '导入', limit: 'resident::manage::import' },
  { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/tenant/exportnew', rqMethod: 'post' }, limit: 'resident::manage::export'}
],

      occupantInfoColumn,
      tableData: [],
      total: 0,
      show: false,
      editId: '',
      title: '',
      rowForm: {},
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      }
    }
  },
  created() {},
  methods: {
    getInit() {
      this.loading = true

      // 查询园区列表信息
      rqTenant(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    append() {
      this.title = '新增'
      this.show = true
    },
    rowEdit(item, details) {
      if (details == 'details') {
        this.title = '详情'
        this.allDisabled = true
      } else {
        this.title = '编辑'
      }

      this.rowForm = item
      this.show = true
    },
    getContract(row) {
      this.$router.push({
        path: '/home/occupantManage/occupantContract',
        query: {
          tenantName: row.tenantName,
          id: row.id,
          tenantCode: row.tenantCode
        }
      })
    },
    rowDelete(row) {
      if(Number(row.accountBalance) !== 0)return this.$message.warning(`无法注销,该用户存在余额`)
      // TODO: 服务器出现问题，不能开发
      this.$confirm(
        '租户注销后，该租户的相关数据将不再可用，请谨慎操作。'
      ).then((res) => {
        delTenant(row.id).then((res) => {
          this.$message.success('已终止')
          this.getInit()
        })
      })
    },
    // 删除租户
    delTenant(row) {
      if(Number(row.accountBalance) !== 0)return this.$message.warning(`无法删除,该用户存在余额`)
      this.$confirm(
        '确认删除该租户信息？'
      ).then((res) => {
        delTenant(row.id).then((res) => {
          this.$message.success('已删除')
          this.getInit()
        })
      })
    },
    closeForm(show) {
      this.show = show
    },
    // 点击了抄表
    turnMeterReading(row) {
      this.$router.push({
        path: '/home/occupantManage/occupantTable',
        query: {
          tenantName: row.tenantName,
          id: row.id,
          tenantCode: row.tenantCode
        }
      })
    },
    // 点击标题
    seeDetails(row) {
      this.rowEdit(row, 'details')
    },
    editDataFormat(row, btn) {
        if (row.statusNum != 1 && btn.text == '审核') {
            return false
        } else if (row.statusNum != 0 && btn.text == '提交' || row.statusNum != 0  && btn.text == '删除') {
            return false
        } else if (row.statusNum != 2 && btn.text == '终止') { 
            return false
        } else if (row.statusNum == 0 && btn.text == '合同' || row.statusNum == 0  && btn.text == '抄表') {
            return false
        } else if (row.statusNum == 1 && btn.text == '编辑' || row.statusNum == 1 && btn.text == '合同' || row.statusNum == 1 && btn.text == '抄表') {
          return false
        } else {
            return true
        }
    },
    // 导出 
    exportFun(){
      let data =this.rqParmas()
      exportTenant(data).then(res => {
        this.util.dealExcelRespose(res)
      })
        },
    // 导入
    openResult ({ data, uploadFlag,res}) {
        console.log(data,uploadFlag,res);
        this.results = { data, uploadFlag }
        this.$nextTick(() => {
            this.$refs.checkResult.rowDetail()
        })
        },
    formatter(val, key) {
      if (key === 'energyDevices') {
        if(val?.length>0){
         return val.map(item=>{
            return item.deviceName+'('+item.deviceCode+')'
          }).join()
        }else{
          return '未绑定'
        }
      }
      return val
    }
  }
}
</script>

<style scoped>
</style>
