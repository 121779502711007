<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="详情" :width="`45%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='10' v-if="showDetail">
        <template #type="{ row }">
          <span type="text">{{ typeFilter(row.status) }}</span>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      showDetail: false,
      detailOptions: [
        { isTitle: true, label: '访客信息', col: 22 },
        { key: 'visitName', label: '访客姓名', },
        { key: 'phoneNumber', label: '手机号', },
        { key: 'visitUnit', label: '所属单位', },
        { key: 'visitCarNumber', label: '车牌号', },
        { key: 'belongings', label: '携带物品', },
        { key: 'applyTime', label: '申请时间', },
        { isTitle: true, label: '被访人信息', col: 22 },
        { key: 'name', label: '被访人', },
        { key: 'departmentName', label: '被访单位', },
        { key: 'visitDate', label: '拜访日期', },
        { isTitle: true, label: '审批信息', col: 22 },
        { slotName: 'type', label: '审批意见', key: 'approvalOpinion' },
        { key: 'remark', label: '备注', },
        { key: 'approvalName', label: '审批人' },
        { key: 'approvalTime', label: '审批时间' },

      ],
      detailData: {},
      initFileList: [],
      imgText: ''
    }
  },
  computed:{
    typeFilter(){
      return (val)=>{
        const arr = ['待审批', '审批拒绝', '审批通过', '已过期']
        return arr[val]
      }
    }
  },
  methods: {
    rowDetail(row) {
      this.detailData = { ...row, brandCode: `${row.brand}${row.model}` }
      this.showDetail = true
    },
  }
}
</script>
<style lang="scss" scoped>
.BigBox {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;

  .right {
    margin-left: 20px;
  }

  img {
    height: 120px;
    display: flex;
  }
}
</style>