<template>
    <!-- 照明系统-照明管理 -->
    <div class="allwh">
        <div class="allwh-wrapper">
            <ja-table ref="tst"
                v-model="filterData"
                showSelect
                :conditionData="conditionData"
                :columns="columns"
                :tableData="tableData"
                :total="total"
                :loading="loading"
                @getInit="getInit"
                @search="search"
                @bulkDisable="bulkDisable"
                @bulkEnable="bulkEnable"
                isTableSlot
                @reset="reset">
                <template #tableSlot>
                    <el-table
                    :data="tableData"
                    v-loading="loading"
                    @sort-change="sortChange"
                    @selection-change="selectionChange"
                    style="width: 100%">
                    <el-table-column
                    type="selection"
                    width="55">
                    </el-table-column>
                    <el-table-column
                    v-for="(item,i) in columns"
                    :key=i
                    :label="item.label"
                    v-bind="{...item.attribute}"
                    :prop="item.prop">
                        <template  slot="header"  slot-scope="{column}">
                            <template v-if="item.prop=='totalRunningTime'">
                                {{column.label}}
                                <el-tooltip class="item" effect="dark" 
                                content="运行时长按照系统计算，数据仅供参考!" placement="right-start">
                                    <i class="el-icon-question"
                                        style="font-size:20px"
                                    ></i>
                                </el-tooltip>
                            </template>
                            <template v-else>
                                {{column.label}}
                            </template>
                        </template>
                        <template slot-scope="{row}">
                            <template v-if="item.prop=='switching'" >
                                <el-switch
                                    v-if="isAuth('subsystemModule::lightingManage::switch')"
                                    style="margin-right:10px"
                                    active-value="true"
                                    inactive-value="false"
                                    v-model="row.switching"
                                    @change="changeSwitching($event,row)"
                                    active-color="#13ce66"
                                    >
                                </el-switch>
                            </template>
                            <template v-else-if="item.prop=='runStatus'">
                                <div :class="['run_status_normal',row.runStatus==2?'run_status_abnormal':'']">
                                    {{runStatusList[row[item.prop]]}} <i class="el-icon-question" v-if="row.runStatus==4" @click="clickPrompt(row)"></i>
                                </div>
                            </template>
                            <template v-else-if="item.prop=='installLocation'">
                                <div>
                                    {{row.buildingName?row.buildingName:''}}{{row.floorName?row.floorName:''}}
                                </div>
                            </template>
                            <template v-else>
                                {{row[item.prop]}}
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                </template>
            </ja-table>
        </div>
    </div>
</template>

<script>
import table from "@/views/mixins/table"
import { getLightList,setSwitching} from '@/api'
export default {
    title: '照明管理',
    mixins: [table],
    name: 'lightingManage',
    components: {

    },
    data() {
        return {
            filterData: { pageIndex: 1, pageSize: 10, sortColumn:'create_time', sort:'desc'}, // 默认页码
            conditionData: [
                {
                    input_type: 'select', key: 'buildingId', attribute: { placeholder: '所属楼栋', size: 'small' }, options: [],
                },
                {
                    input_type: 'select', key: 'floorId', attribute: { placeholder: '所属楼层', size: 'small' }, options: [],
                },
                {
                    input_type: 'select', key: 'spaceId', attribute: { placeholder: '所属空间', size: 'small' }, options: [],
                },
                { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
                { input_type: 'input', key: 'deviceCode', attribute: { placeholder: '设备编码', size: 'small' } },
                { input_type: 'select', key: 'runStatus', attribute: { placeholder: '状态', size: 'small' },
                    options:[
                        {label:'正常',value:2},
                        {label:'离线',value:1},
                        {label:'异常',value:4}
                    ]
                 },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { input_type: 'button', text: '批量启用', method:"bulkEnable", limit: 'subsystemModule::lightingManage::batchActivation'},
                { input_type: 'button', text: '批量禁用',  method:"bulkDisable",limit: 'subsystemModule::lightingManage::batchDisable'},
            ],
            columns: [
                { label: '设备编码', prop: 'deviceCode' },
                { label: '设备名称', prop: 'deviceName', attribute: { showOverflowTooltip: true, align: 'center' } },
                { label: '设备型号', prop: 'model' },
                { label: '设备位置', prop: 'installLocation', width: 80 },
                { label: '运行状态', prop: 'runStatus', width: 80, attribute:{sortable:'custom'}},
                // { label: '总运行时长（小时）',prop:"totalRunningTime",slotName:'monitorTime',  attribute: { sortable:'custom', align: 'center',} },
                {
                    label: '操作',
                    prop: 'switching',
                    attribute: { fixed: "right", align: 'center' },
                }
            ],
            tableData: [
                
            ],
            runStatusList:{2:'正常', 1:'离线', 3:'超时', 4:'异常'},
            loading: false,
            total: 0,
            indexObj: {},
            conditionIndexObj:{},
            allDisabled:false,
            websock:null,
            selectedList :[]

        }
    },
    watch: {

    },
    created() {
        this.conditionIndexObj = this.util.getArrItemIndex({ arr: this.conditionData,});
    },
    mounted() {
        const rowParamObj = { pageIndex: 1, pageSize: 200 }
        // 顶部搜索框
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id', resRule: 'data.data' }) // 楼栋选择
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.floorId], rqUrl: "/floor/allList", rqData: rowParamObj, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId', resRule: 'data' }) // 楼层选择
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.spaceId], rqUrl: "/spaceTemp/query", rqData: rowParamObj, rqMethod: 'post', labelName: 'spaceCode', valueName: 'id' }) // 空间选择
    },
    methods: {
        getInit: function (item) { // 查询设备列表
            this.loading = true
            getLightList(this.filterData).then(res => {
                this.loading = false
                const { data, total } = res.data
                this.total = total
                this.tableData = data
            }).catch(err => {
                this.loading = false
            })
        },
        reset(){
            this.filterData = { pageIndex: 1, pageSize: 10, sortColumn:'create_time', sort:'desc'}
            this.getInit()
        },
        /**排序方法 */
        sortChange({order, prop}){
            this.filterData.sortColumn = prop === "runStatus" ? "run_status" : "create_time"
            this.filterData.sort = order === "ascending" ? 'asc' : 'desc'
            this.getInit()
        },
        valueFormat(val,key){
             const runStatusList={2:'正常', 1:'离线', 3:'超时', 4:'异常'}
             if(key=='runStatus'){
                return runStatusList[val]
             }else {
                return val
             }
        },
        /**多选择发生变化时 **/
        selectionChange(list){
            this.selectedList = list;

        },
        /**开关接口 */
        setSwitching(value,deviceNumbers){
            let obj={
                deviceNumbers:deviceNumbers,
                switching:value
           }
           setSwitching(obj).then(res=>{
                if(res.data.body){
                    this.$message({
                        message: res.data.body,
                        type: 'error'
                    })
                }else{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                }
                this.getInit()
           })
        },
        /**点击开关 */
        changeSwitching(value,row){
           row.switching = !row.switching //开关按钮保持点击前状态
           this.setSwitching(value,[row.deviceNumber])
        },
        /**批量启用 */
        bulkEnable(){
            if(this.selectedList.length==0){
                this.$message({
                    message: '至少要选择一个设备哦！',
                    type: 'warning'
                })
                return
            }
            let list=this.selectedList.map(item=>{
                return item.deviceNumber
            })
            this.setSwitching('true',list)
        },
        /**批量禁用 */
        bulkDisable(){
            if(this.selectedList.length==0){
                this.$message({
                    message: '至少要选择一个设备哦！',
                    type: 'warning'
                })
                return
            }
            let list=this.selectedList.map(item=>{
                return item.deviceNumber
            })
            this.setSwitching('false',list)
        },
        /**点击提示icon */
        clickPrompt(row){
                this.$message.error(row.abnormalInformation);
        },
    }
}
</script>

<style scoped>
.run_status_normal{
    color: #f49931;
}
.run_status_abnormal{
    color: #82bf41;
}
</style>
