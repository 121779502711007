<template>
  <!-- 表格 -->
  <div class="content">
    <title-box title="本月告警等级分析" />
    <title-top title="本月告警总数" subTitle="告警严重性分析" num="0" />
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'

export default {
  components: {
    JaInitChart, TitleBox, titleTop
  },
  data() {
    return {
      options: null
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      this.options = {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        radar: [
          {
            indicator: [
              { text: '紧急', max: 100 },
              { text: '重要', max: 100 },
              { text: '告警', max: 100 },
            ],
            center: ['50%', '40%'],
            radius: this.util.setFontSize(80, 1920),
            splitLine: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            axisName: {
              show:false,
              formatter: '【{value}】',
              color: '#428BD4'
            },
          },

        ],
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item'
            },
            areaStyle: {},
       
            data: [
              {
                value: [],
              }
            ]
          },
        ]

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
}
.echart {
  height: 100%;
  width: 100%;
}
</style>