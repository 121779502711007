<template>
    <div class="allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
            :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset"
            @rowDetail="normalEvent">
            <!-- 安装位置 -->
            <template #position="{ row }">
                <span>
                    {{ row.buildingName + row.floorName }}
                </span>
            </template>
        </ja-table>
    </div>
</template>
  
<script>
import table from '@/views/mixins/table'
import { getHVACRecords } from '@/api'

export default {
    title: "暖通操控记录",
    name: 'heatingControl',
    mixins: [table],
    data() {
        return {
            conditionData: [
                { input_type: 'date', key: 'time', value: 'time', rangeSeparator: '-', type: 'daterange', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqexport, rqUrl: '/device/info/export', rqMethod: 'post' }, limit: 'heatingControl::detailedList::export' },
            ],
            columns: [
                { label: '设备名称', prop: 'deviceName' },
                { slotName: 'position', label: '安装位置', prop: 'buildingName' },
                { label: '调节参数', prop: 'operate' },
                { label: '运行状态', prop: 'operateStatus' },
                { label: '操作人', prop: 'createBy' },
                { label: '操作时间', prop: 'createTime' }
            ],
            tableData: [],
            total: 0,
            loading: false,
        }
    },
    methods: {
        rqParmas() {
            let obj = { ...this.filterData }
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        rqexport() {
            let obj = { ...this.filterData }
            obj.pageIndex = this.total <= 2000 ? 1 : this.filterData.pageIndex
            obj.pageSize = 2000
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        // 获取初始化数据
        async getInit() {
            this.loading = true
            const { data } = await getHVACRecords(this.rqParmas())
            this.tableData = data.data
            this.total = data.total
            this.loading = false
        },
    }
}
</script>
  
<style scoped lang="scss">
.dialog_form{
    /deep/ .el-form-item {
    width: 100%;

    .el-form-item__content {
        width: 70%;
    }

    .el-select{
        width: 100%;
    }
}
}

.inactive {
    color: #f8b636;
}

.active {
    color: #83bf48;
}

.error {
    color: #d50e21;
}

.dialog_span {
    margin-right: 10px;
}

.dialog_footer {
    display: flex;
    justify-content: center;
}
</style>
  