<template>
    <div>
        <ja-table
            ref="tst"
            v-model="filterData"
            :conditionData="conditionData"
            :columns="columns"
            :tableData="tableData"
            :total="total"
            :loading="loading"
            @getInit="getInit"
            @search="search"
            @reset="reset"
            @lookRoom="lookRoom"
        >
        </ja-table>
        <show-passed ref="showPassed"></show-passed>
    </div>
</template>

<script>
import { getVehicleTrafficList } from '@/api'
import table from '@/views/mixins/table'
import showPassed from './showPassed.vue'
import {myMinxin} from '../mixin/index'
export default {
    title: '租户车辆',
    mixins: [table,myMinxin],
    name: 'residentVehicle',
    components: {
        showPassed,
    },
    created(){
        this.openServeSelect()
        this.setButtonLimit('passRecord::residentVehicle::show')
    },
    methods: {
        getInit() {
            let obj = this.getFilterData(this.filterData)
            this.loading = true
            // 获取楼层信息列表
            getVehicleTrafficList({...obj,type: 2})
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        lookRoom(row) {
            this.$refs.showPassed.init(row,true)
        }
    }
}
</script>

