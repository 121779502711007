<template>
  <!--人员布控列表 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="normalEvent({ref:'addEdit',method:'append'})" @rowDetail="normalEvent" @rowEdit="normalEvent" @rowDelete="rowDelete" @getInit="getInit" @search="search" @reset="reset">
      <template #pictureUrl="{row}">
        <el-image style="width: 50px; height: 50px" :src="row.pictureUrl" :preview-src-list="[row.pictureUrl]" fit="cover"></el-image>
      </template>
    </ja-table>
    <!-- 新增 -->
    <add-edit ref="addEdit" />
      <!-- 详情 -->
     <detail ref="detail" />

  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import Detail from './childComp/Detail.vue'
import { pointList,deleteById} from '@/api'
export default {
  title: '巡更点管理',
  mixins: [table],
  components: {
    AddEdit,
    Detail
  },
  data() {
    return {
      conditionData: [
        {
            input_type: 'select', key: 'buildingId', attribute: { placeholder: '楼栋', size: 'small' }, options: [], styleWidth: '100px',
        },
        { input_type: 'select', key: 'floorId', attribute: { placeholder: '楼层', size: 'small' }, options: [], styleWidth: '100px' },
        { input_type: 'input', key: 'pointName', attribute: { placeholder: '巡更点名称', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增', },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqexport, rqUrl: '/patrol/point/export', rqMethod: 'post' }}
      ],
      columns: [
        { label: '巡更点名称', prop: 'pointName', },
        { label: '巡更点编号', prop: 'pointCode', },
        { label: '所属楼栋', prop: 'buildingName', },
        { label: '所属楼层', prop: 'floorName', },
        { label: '巡更点位置', prop: 'position', },
        { label: '创建人', prop: 'createBy', },
        { label: '创建时间', prop: 'createTime', },
        { label: '点位描述', prop: 'remark', },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '详情', method: 'rowDetail', },
            { text: '编辑', method: 'rowEdit', },
            { text: '删除', method: 'rowDelete', },
          ]
        },
      ],
      indexObj: {},
      conditionIndexObj:{}
    }
  },
  mounted() {
     this.conditionIndexObj = this.util.getArrItemIndex({ arr: this.conditionData,})
     const rowParamObj = { pageIndex: 1, pageSize: 200 }
     this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id', resRule: 'data.data' }) // 楼栋选择
    this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.floorId], rqUrl: "/floor/allList", rqData: rowParamObj, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId', resRule: 'data' }) // 楼层选择
  },
  methods: {
    rqexport() {
      let obj = { ...this.filterData }
      obj.pageIndex = this.total <= 2000 ? 1 : this.filterData.pageIndex
      obj.pageSize = 2000
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = false
      const rqData = this.rqParmas()
      pointList(rqData).then(res => {
        const { total, data } = res.data
        this.total = total
        this.tableData = data
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该条数据？').then(res => {
        deleteById(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },
  }
}
</script>
