import http from '../plugin/ajax'
const API = {

  /**
   * 请求实时视频流地址
   */
  getRealmonitor(realmonitorParam) {
    return new Promise((resolve,reject) => {
      http.post('/monitorView/getUrlTransFormNew', realmonitorParam).then(res => {
        console.info(res)
        resolve(res.data)
      }).catch(res => {
        reject(res)
      })
    })
  },
  /**
   * 根据时间查询设备上的录像
   * @param recordByTimeParam.recordType 录像类型：1=一般录像，2=报警录像
   * @param recordByTimeParam.recordSource 录像来源：1=全部，2=设备，3=中心
   * @param recordByTimeParam.streamType 码流类型：1=主码流, 2=辅码流 使用辅码流 码率低更加流畅
   * @param recordByTimeParam.channelId 通道code
   * @param recordByTimeParam.startTime 开始时间
   * @param recordByTimeParam.endTime 结束时间
   */
  getRecordRtspByTime(recordByTimeParam) {
    return new Promise((resolve,reject) => {
      http.post('/monitorView/replayByTime', recordByTimeParam).then(res => {
        resolve(res.data)
      }).catch(res => {
        reject(res)
      })
    })
  },

  /**
   * 根据文件形式回放录像
   * @param recordByFileParam.recordType 录像类型：1=一般录像，2=报警录像
   * @param recordByFileParam.streamType 码流类型：1=主码流, 2=辅码流 使用辅码流 码率低更加流畅
   * @param recordByFileParam.recordSource 录像来源：1=全部，2=设备，3=中心
   * @param recordByFileParam.channelId 通道code
   * @param recordByFileParam.startTime 开始时间 timestamp到秒
   * @param recordByFileParam.endTime 结束时间 timestamp到秒
   */
  getRecordRtspByFile(recordByFileParam) {
    return new Promise((resolve, reject) => {
      http.post('/monitorView/replayByFile', recordByFileParam).then(res => {
        resolve(res.data)
      }).catch(res => {
        reject(res)
      })
    })
  },
  /**
   *  查询中心录像上的录像文件信息
   * @param recordsParam.recordType 录像类型：1=一般录像，2=报警录像
   * @param recordsParam.streamType 码流类型：1=主码流, 2=辅码流 使用辅码流 码率低更加流畅
   * @param recordsParam.recordSource 录像来源：1=全部，2=设备，3=中心
   * @param recordsParam.channelId 通道code
   * @param recordsParam.startTime 开始时间 timestamp到秒
   * @param recordsParam.endTime 结束时间 timestamp到秒
   */
  getRecords(recordsParam) {
    return new Promise((resolve,reject) => {
      http.post('/monitorView/getRecords', recordsParam).then(res => {
        resolve(res.data)
      }).catch(res => {
        reject(res)
      })
    })
  }
}
export default API
