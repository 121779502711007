<template>
  <div class="bg box_bg">
    <div class="header" v-if="header">
      <div class="headerleft">{{ title }}</div>
    </div>

    <div class="right_table">
      <el-table
        :data="tableData"
        style="width: 100%;height: 100%;"
        @row-click="rowClick"
        :row-class-name="rowClassName"
        size="mini"
        header-cell-class-name="table_head"
        :highlight-current-row="true"
      >
        <el-table-column v-if="typeIndex" type="index" label="排行" width="80px"></el-table-column>
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :key="item.prop"
          :class-name="item.tdstyle"
          :show-overflow-tooltip="true"
          :width="item.width"
          v-for="item in columns"
        >
          <template slot-scope="scope">
            <span
              v-if="item.prop == 'status'"
              :class="color || filterColor(scope.row[item.prop],item.prop)"
            >
              {{ filterStatus(scope.row[item.prop],item.type) }}
            </span>
            <span
                v-else-if="item.prop == 'energy'"
                :class="color || filterColor(scope.row[item.prop], item.prop)"
              >
                {{ scope.row[item.prop] }}
            </span>
            <span
              v-else-if="item.prop == 'openType'"
              :class="color || filterColor(scope.row[item.prop],scope.$index)"
            >
              {{ typeFilter(scope.row[item.prop]) }}
            </span>
            <span
               v-else-if="item.prop == 'imageType'"
              :class="color || filterColor(scope.row[item.prop],scope.$index)"
            >
              {{ typeOfPerson[scope.row[item.prop]] }}
            </span>
            <span v-else :class="color || filterColor(scope.row[item.prop],scope.$index)">
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
import { reqDictData } from '@/api'
export default {
  name: 'temperature',
  components: {
    JaInitChart
  },
  props:{
    //标题
    title:{
      type:String,
      default:''
    },
    //表格列数据
    columns:{
      type:Array,
      default:[]
    },
    //表格数据
    tableData:{
      type:Array,
      default:[]
    },
    //是否开启排行
    typeIndex:{
      type:Boolean,
      default:false
    },
    //是否又标题头部
    header:{
      type:Boolean,
      default:true
    },
    //是否开启变换颜色
    color:{
      type:Boolean,
      default:false
    },
  },
  data () {
    return {
      dictList:[],
      typeOfPerson:{
        1:"内部人员", 
        2:"访客" 
      }  
    }
  },
  watch:{
    tableData(newValue){
      this.tableData = newValue
    }
  },
  computed:{
    //转换状态和排名数据
    filterStatus(){
      return (key,type)=>{
        const status = {
          0:key,
          1:['待派单', '待接单', '处理中', '结单审核中', '已结单', '接单超时', '处理超时', '挂起中', '已接单'][key],
          2:['提交工单', '人工派单', '系统派单', '确认接单', '挂起工单', '重启工单', '完成工单', '审核通过', '审核驳回', '转单', '接单超时', '处理超时'][key],
          3:['待处理','已通知','已处理'][key],
          4:key,
          5:{1:'过高', 5: '正常'}[key]
        }
        return status[type]
      }
    },
    //处理状态和排名的字体颜色
    filterColor(){
      return (key,index)=>{
        let status;
        if (index === 'status') {
          status = {
            2:'td_green',
            4:'td_green',
            1:'td_red',
            3:'td_yellow'
          }
          return status[key] || ''
        } 
        if (index === 'energy') {
          return  key <= 0 ? 'td_red' : ''
        }
        if(typeof key === 'number'){
          status = ['td_red', 'td_yellow', 'td_green']
          return status[index]
        }
      }
    },
    typeFilter() {
        return (key) => {
            let value = ''
            this.dictList.some(item => {
                if (item.dictValue === key) {
                    value = item.dictLabel
                    return true
                }
            })
            return value
        }
    }
  },
 async created () {
     const { data: list } = await reqDictData('/dahua_open_type')
            this.dictList = list
  },
  methods:{
    rowClassName({ row, rowIndex }) {
      row.index = rowIndex + 1;
    },
    rowClick(row){
      this.$emit('rowClick',{...row,index:row.index})
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  /deep/ .el-table__cell {
    padding: 8px 0 !important;
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    height: 26px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .right_table {
    height: 100%;
    margin-top: 5px;
    display: flex;
    transition: transform 0.5s ease-in-out;
    /deep/ .el-table::before {
      background: none !important;
    }
    /deep/ .table_head {
      color: #fff;
      background-color: #00408a !important;
      border-bottom: #0057ae !important;
      box-shadow: inset 0px -1px 0px 0px rgba(0, 120, 255, 0.3) !important;
      box-shadow: 0px -1px 0px 0px rgba(0, 174, 255, 1) !important;
    }

    /deep/ .table_head .cell {
      padding-left: 25px;
    }

    /deep/ .table_head .cell::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 10px;
      width: 8px;
      height: 8px;
      background-image: url('~@/assets/gd/ds_td.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    /deep/ .el-table__body-wrapper,
    .el-table {
      font-size: 12px !important;
      background: #002f64 !important;
    }

    /deep/ .el-table__row {
      font-size: 12px !important;
      padding: 30px !important;
      line-height: 10px !important;
      height: 10px !important;
    }

    /deep/ .el-table__row:nth-child(odd) {
      background: #002f64 !important;
      color: #fff;
    }

    /deep/ .el-table__row:nth-child(odd) td {
      border-bottom: #002f64 !important;
    }

    /deep/ .el-table__row:nth-child(even) {
      background: #004278 !important;
      color: #fff;
    }

    /deep/ .el-table__row:nth-child(even) td {
      border-bottom: #004278 !important;
    }

    /deep/ .el-table__row td {
      background-size: 96px 8px;
      background-repeat: no-repeat;
      background-position: left bottom;
    }

    /deep/ .el-table__cell {
      padding: 2px 0;
    }

    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td,
    /deep/ .current-row > td.el-table__cell {
      background-color: transparent !important;
    }
  }

  .td_green {
    color: #00ff98;
    font-weight: 700;
  }
  .td_red {
    color: #f02731;
    font-weight: 700;
  }
  .td_yellow {
    color: #ffc800;
    font-weight: 700;
  }
}
</style>