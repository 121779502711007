<template>
  <div class="edit">
    <el-dialog
      v-dialogDrag
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="chiShow"
      width="50%"
      @close="close"
      :before-close="close"
    >
      <ja-form
        :rules="rules"
        v-model="rowFormData"
        :formArray="rowForm"
        @reset="close"
        :allDisabled="allDisabled"
        @deleteEquipment="deleteEquipment"
        @selectRoom="selectRoom"
        @save="saveForm"
        labelWidth="120px"
      >
      </ja-form>
    </el-dialog>
    <!-- 选择能源设备 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" width="70%" title="新增设备" :visible.sync="isSelectRoom" v-if="isSelectRoom">
        <select-room @selectRow="selectRow2" :selectedPersons="[...selectedRooms]" v-if="isSelectRoom" :isOnePerson="false" :rqParmasFather="rqParmasFather"/>
    </el-dialog>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import { editOccupantData } from '../occupantInfoColumn'
import { addTenant, editTenant, getDictData } from '@/api'
import selectRoom from './selectEquipment/selectEquipment.vue'
export default {
  name: 'EditOccupant',
  inject: ['getInit'],
  components: {
    JaForm,
    selectRoom
  },
  data() {
    return {
      rowForm: [
        {
          input_type: 'input',
          label: '租户名称',
          key: 'tenantName',

          col: 22
        },
        {
          input_type: 'select',
          label: '租户类型',
          key: 'category',
          options: function () {
            return getDictData('tenant_category').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        {
          input_type: 'select',
          label: '租户属性',
          key: 'attribute',
          options: function () {
            return getDictData('tenant_attribute').then((res) => {
              if (res.code == 200) {
                let data = res.data.map((item) => {
                  return {
                    label: item.dictLabel,
                    value: item.dictValue
                  }
                })
                return data
              }
            })
          }
        },
        // {
        //   input_type: 'input',
        //   label: '纳税人识别号',
        //   key: 'unifiedSocialCreditCode',

        //   isInit: true,
        //   col: 22,
        //   handleSelectContent: function (
        //     parentData,
        //     key,
        //     val,
        //     currData,
        //     nowFilterData
        //   ) {
        //     // if (key == 'attribute') {
        //     // console.log("纳税人识别号----", nowFilterData.attribute)
        //     if (nowFilterData.attribute == 'company') {
        //       this.relationDisplay = false
        //     } else {
        //       this.relationDisplay = true
        //     }
        //     // }
        //   }
        // },
        {
          input_type: 'input',
          label: '身份证号码',
          key: 'idCard',
          isInit: true,

          col: 22,
          handleSelectContent: function (
            parentData,
            key,
            val,
            currData,
            nowFilterData
          ) {
            // if (nowFilterData.attribute == 'attribute') {
            if (nowFilterData.attribute == 'person') {
              this.relationDisplay = false
            } else {
              this.relationDisplay = true
            }
            // }
          }
        },
        {
          input_type: 'input',
          label: '负责人',
          key: 'linkMan',

          col: 22
        },
        {
          input_type: 'input',
          label: '联系方式',
          key: 'contactNumber',

          col: 22
        },
        {
            input_type: 'table', col: '20', label: '绑定能源', noSearch: true, showPage: false, key: 'energyDevices', isRequired: false, border: true, width: '150px',
            tableAttribute: { showSummary: true, showIndex: false },
            columns: [
                { label: '设备名称', prop: 'deviceName' },
                { label: '设备编码', prop: 'deviceCode' },
                {
                    label: '操作', input_type: 'button', list: [
                        { text: '删除', method: 'deleteEquipment', },
                    ]
                }
            ]
        },
        { input_type: 'button', text: '新增设备', readonly: true, col: 2, colMarginLeft: '2%', method: 'selectRoom', key: 'addDevices'},
        {
          input_type: 'title',
          text: '开票信息',
          col: 24,
          noMarginBottom: true
        },
        {
          input_type: 'input',
          label: '名称',
          key: 'accountName',
          col: 22
        },
        {
          input_type: 'input',
          label: '地址、电话',
          key: 'accountContact',
          col: 22
        },
        {
          input_type: 'input',
          label: '开户行及账号',
          key: 'accountBankInfo',
          col: 22
        },
        {
          input_type: 'input',
          label: '备注',
          key: 'remark',
          type: 'textarea',
          col: 22
        }
      ],
      rowFormData: {
        attribute: 'person',
        operationType: 'save'
      },
      rules: {
        tenantName: [
          { required: true, message: '请输入租户名称', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择租户类型', trigger: 'change' }
        ],
        attribute: [
          { required: true, message: '请选择租户属性', trigger: 'change' }
        ],
        linkMan: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
        // unifiedSocialCreditCode: [
        //   {
        //     required: true,
        //     validator: (rule, value, callback) => {
        //       const value_rule =
        //         /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/
        //       if (value === undefined || value === '') {
        //         // 如果为空
        //         return callback(new Error('请输入纳税人识别号'))
        //       } else if (
        //         !value_rule.test(value) &&
        //         value !== '' &&
        //         value !== undefined
        //       ) {
        //         return callback(new Error('请输入正确纳税人识别号'))
        //       } else if (value.length > 18) {
        //         return callback(new Error('纳税人识别号不能大于18位数'))
        //       } else {
        //         return callback()
        //       }
        //     },
        //     trigger: 'blur'
        //   }
        // ],
        idCard: [
          {
            required: true,
            validator: this.util.formRules.checkIdCard(),
            trigger: 'blur'
          }
        ],
        contactNumber: [
          {
            required: true,
            phoneType: 1,
            validator: this.util.formRules.checkPhone(),
            trigger: 'blur'
          }
        ]
      },

      chiShow: this.show,
      saveTenantName:"",
      isSelectRoom: false,
      selectedRooms: [],
    }
  },
  props: {
    title: String,
    editRowForm: {
      type: Object,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      default: false
    },
    allDisabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(val) {
      this.chiShow = val
    }
  },
  created() {
    this.resetRowFormData()
    //点击详情时，禁用绑定能源设备
    if(this.allDisabled){
      let index1=this.rowForm.findIndex(item=>{
        return item.key=='energyDevices'
      })
      let index2=this.rowForm.findIndex(item=>{
        return item.key=='addDevices'
      })
      this.rowForm[index1].columns[2].hide=true;
      this.rowForm[index2].hide=true;

    }
  },
  methods: {
    /** 保存表单 */
    saveForm: function () {
      // this.loading_form.isShow = true
      // console.log("this.title--------------------7655")
      this.rowFormData.energyDevices=this.rowFormData.energyDevices?.map(item=>{
        return item.id
      }).join()
      if (this.title == '新增') {
        addTenant(this.rowFormData)
          .then((res) => {
            this.$message.success(res.msg)
            this.getInit()
            this.chiShow = false
            // this.loading_form.isShow = false
          })
          .catch((error) => {
            // this.loading_form.isShow = false
            // this.$message.error(error)
          })
      } else {
          editTenant(this.rowFormData)
          .then((res) => {
            this.$message.success(res.msg)
            this.getInit()
            this.chiShow = false
            // this.loading_form.isShow = false
          })
          .catch((error) => {
            // this.loading_form.isShow = false
            // this.$message.error(error)
          })
      }
    },
    /** 关闭弹出框 */
    close() {
      this.$emit('closeForm', false)
      // this.allDisabled=false
      this.$emit('update:allDisabled', false)
    },
    resetRowFormData() {
      if (this.title == '新增') {
        this.selectedRooms = []
        this.rowFormData = {}
        ;(this.rowFormData.attribute = 'person'),
          (this.rowFormData.operationType = 'save')
      } else {
        this.rowFormData = {
          ...this.editRowForm
        }
        this.saveTenantName=this.editRowForm.tenantName  // 保存编辑时候的租户名称
        this.rowFormData.operationType = 'save'
        delete this.rowFormData.status
        this.selectedRooms = this.rowFormData.energyDevices??[]
      }
    },
    //打开设备列表
    selectRoom () {
        this.isSelectRoom = true
    },
    // 选择设备
    selectRow2 ({ list }) {
        this.isSelectRoom = false
        this.selectedRooms = [...list]
        this.$set(this.rowFormData, 'energyDevices', list)
    },
    //删除设备
    deleteEquipment (item) {
        this.$confirm('此操作会删除绑定, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            let rows = [...this.rowFormData.energyDevices]
            let length = rows.findIndex(e => e.id === item.id)
            if (length >= 0) {
                console.log('1')
                rows.splice(length, 1)
                this.$set(this.rowFormData, 'energyDevices', rows)
                this.selectedRooms = rows
            }
            this.$message({
                type: 'success',
                message: '删除成功!'
            });

        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    rqParmasFather(){
      return {
        category: 112
      }
    }
  }
}
</script>

<style scoped>
</style>
