<template>
  <div class="systembox">
    <div @click="goSystem(item.url)" v-for="item in systemList" :class="{'current':title == item.title ? true : false }">
      <div :class="{'currentgif': title == item.title ? true : false }"></div>
      <span>{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            systemList: [
                { title: '首页', url: '/deshunRadio/wisdom/wismanagement' },
                { title: '智慧安防', url: '/deshunRadio/wisdom/wissecurity' },
                { title: '智慧人行', url: '/deshunRadio/wisdom/wisdomDnergy' },
                { title: '智慧能源', url: '/deshunRadio/wisdom/wisdomEnergy' },
                { title: '智慧楼宇', url: '/deshunRadio/wisdom/wisbuilding' },
                // { title: '智慧停车', url: '/home/visualManagement/wisdom/wiscar' },
                // { title: '智慧消防' },
            ],
        }
    },
    methods:{
        goSystem(url) {
            if (url) {
                this.$router.push(url);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.systembox{
    width: 100%;
    height: 85px;
    display: flex;
    justify-content: space-around;
    > div {
      position: relative;
      width: 85px;
      height: 85px;
      background: url('~@/assets/gd/ds_systembtn.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      span {
        text-align: center;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 17px;
        line-height: 22px;
        color: #ffffff;
        letter-spacing: 0.86px;
        text-shadow: 0 1px 1px #002d59;
        font-weight: 700;
      }
    }
    > div:hover {
      background: url('~@/assets/gd/ds_systembtnx.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .current{
      z-index: 10;
      background: url('~@/assets/gd/ds_systembtnx.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
    }
    .currentgif{
      width: 85px;
      height: 85px;
      z-index: 11;
      background: url('~@/assets/gd/ds_systembtnx.gif');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
    }
}
</style>