<template>
  <!-- 月度用电 -->
  <div class="content">
    <titleBox title="月度用电" />
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import titleBox from './titleBox.vue'

export default {
  components: {
    JaInitChart, titleBox
  },
  data() {
    return {
      options: null,
    }
  },

  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      const xData = this.util.numberRange(1, 12, null, null, '月').map(v => v.label);
      this.options = {
        color: ['#5470c6', '#91cc75', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          top: '2%',
          right: '10%',
          itemGap: this.util.setFontSize(30, 1920),
          textStyle: { //图例文字的样式
            fontSize: this.util.setFontSize(15, 1920),
          },
          data: ['自用层用电', '非自用层用电',],
        },
        grid: {
          top: '18%',
          bottom: '25%',
          right: '10%'
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow'
            },
            axisTick: {
              show: false,
            },
            // boundaryGap:false
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '（kW·h）',
            nameGap: 20,
            nameTextStyle: {
              fontSize: this.util.setFontSize(16, 1920),
            },
            axisLabel: {
              fontSize: this.util.setFontSize(14, 1920),
            },
          },
          {
            show: false,
            type: 'value',
            nameGap: 20,
            nameTextStyle: {
              fontSize: this.util.setFontSize(16, 1920),
            },
            // min: 0,
            // max: 250,
            // interval: 50,
            axisLabel: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '自用层用电',
            type: 'bar',
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
            ]
          },
          {
            name: '非自用层用电',
            type: 'bar',
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ]
          },
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;
  border-radius: 20px;
  width: 100%;
  height: 500px;
  background: white;
  .selections {
    .selectBox {
      width: 120px;
    }
    .typeSelect {
      margin-left: 20px;
    }
  }
}
.echart {
  height: 100%;
  width: 100%;
}
</style>