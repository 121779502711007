<template>
    <div>
        <el-container>
            <left-list></left-list>
        </el-container>
    </div>
</template>
<script>
import LeftList from './components/LeftList.vue'
export default {
    title: '视频巡更',
    name: 'videoTour',
    components: {
        LeftList,
    },
    data () {
        return {
        }
    },
}
</script>
<style lang="scss" scoped>
.el-container {
    margin: 10px 20px 0 10px;
}

</style>
