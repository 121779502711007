/** 用户管理搜索栏 */
export const userQueryColumn = [
    { input_type: 'input', key: 'username', attribute: { placeholder: '人员姓名', size: 'small' } },
    { input_type: 'input', key: 'mobile', attribute: { placeholder: '手机号码', size: 'small' } },
    {
        input_type: 'select',
        key: 'status',
        attribute: { placeholder: '全部状态', size: 'small' },
        options: [
            { label: '正常', value: 0 },
            { label: '禁用', value: 1 }
        ]
    },
    { normalBtn: 'search', key: 'search' },
    { normalBtn: 'add', key: 'add', limit: 'userManage::index::add' },
    { normalBtn: 'reset', key: 'reset' }
]

export const userQueryColumnMap = function() {
    const map = {};
    userQueryColumn.forEach((element, index) => {
        map[element.key] = index
    });
    return map
}()

/** 用户管理表头栏 */
export const userColumn = [
    { label: '工号', width: 100, prop: 'jobNum' },
    { label: '姓名', prop: 'username', attribute: { showOverflowTooltip: true, align: 'center' } },
    { label: '登录用户名', width:120, prop: 'loginName', attribute: { showOverflowTooltip: true, align: 'center' } },
    { label: '人员类型',width:100, prop: 'employeeType' },
    { label: '所属公司', width: 150, prop: 'companyName', attribute: { showOverflowTooltip: true, align: 'center' } },
    { label: '部门', prop: 'deptName', width: 150, attribute: { showOverflowTooltip: true, align: 'center' } },
    { label: '手机号', prop: 'mobile', attribute: { showOverflowTooltip: true, align: 'center' } },
    { label: '状态', prop: 'statusLabel' },
    {
        label: '操作',
        input_type: 'button',
        width: 230,
        attribute: { fixed: "right", align: 'center' },
        list: [
            { text: '查看', method: 'showUser', limit: 'userManage::index::check' },
            { text: '禁用', method: 'disabled', limit: 'userManage::index::disabled' },
            { text: '启用', method: 'enable', limit: 'userManage::index::enable' },
            { text: '编辑', method: 'rowEdit', limit: 'userManage::index::rowEdit' },
            { text: '删除', method: 'rowDelete', limit: 'userManage::index::rowDelete' },
            { text: '重置密码', method: 'resetPassword', limit: 'userManage::index::resetPassword' }
        ],

    }
]
