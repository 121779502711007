<template>
    <div style="width:100%; margin:0 15px" v-loading="loading">
            <div class="headle">
                <span class="left">{{titleLeft}}</span>
                <span>{{titleRight}}</span>
            </div>
            <div class="datePicker">
                <el-date-picker
                v-model="years"
                type="month"
                size="mini"
                value-format="yyyy-MM"
                @change="changeYears"
                :picker-options="pickerOptions"
                placeholder="选择月">
                </el-date-picker>
            </div>
            <div ref="wrap"
                 class="wrap"></div>
  
    </div>
</template>
<script>
export default {
    props: {
        titleLeft: {
            type: String,
            default: "左边"
        },
        titleRight: {
            type: String,
            default: "右边"
        },
        seriesBGColor: {
            type: String,
            default: "#02a9ec"
        },
        type: {
            type: [Number,String],
            default: ''
        },
        requestApi:{
            type:Function,
            default:()=>{}
        }
    },
    title: '排行',
    async mounted() {
        await this.changeYears()
    },
    data() {
        return {
            show: false,
            years:this.$moment().format('YYYY-MM'),
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            loading:true,
        }
    },
    methods: {
        setOptions(data1, data2) {
            let options = {
                tooltip: {
                },
                xAxis: {
                    show: false,
                },
                dataZoom: [{
                    type: "slider",
                    show: data2.length>5?true:false,
                    yAxisIndex: [0,1],
                    width:16, //组件宽度
                    // height: 100,//组件高度
                    // backgroundColor: "#1A53DD", //两边未选中的滑动条区域的颜色
                    // borderRadius: 5,
                    // borderColor: "#093683", //滚动条边框颜色
                    // fillerColor: "#093683", //选中的滑动条区域的颜色
                    left: "0%", //滚动条位置
                    start: 0, //数据窗口范围的起始百分比
                    end: data2.length>5?60:100, //数据窗口范围的结束百分比
                    realtime: true, //是否实时更新
                    showDetail:false
                },
                {
                    start: 0,
                    type: "inside",
                    yAxisIndex: [0,1],
                    zoomLock: false,
                    moveOnMouseWheel: true,
                    zoomOnMouseWheel: false
                }
             ],
                yAxis: [
                    {
                        type: 'category',
                        inverse: true,
                        data: data2,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show:false,
                        },
                    }, 
                    {
                        type: 'category',
                        inverse: true, // 反向坐标
                        posotion: 'right',
                        data: data1,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        }
                    },
                ],
                series: [
                    {
                        name: '用量',
                        type: 'bar',
                        data: data1,
                        color: this.seriesBGColor,
                        barWidth: 10,
                        label: {
                            normal: {
                                show: true,
                                //label 的position位置可以是top bottom left,right,也可以是固定值
                                //在这里需要上下统一对齐,所以用固定值
                                position: [0, '-160%'],
                                rich: { //富文本
                                    white: { //自定义颜色
                                        color: '#333333',
                                    },
                                    green: {
                                        color: '#70DDA7',
                                    },
                                    yellow: {
                                        color: '#CCB877',
                                    },
                                    red: {
                                        color: '#BC3C47',
                                    },
                                    gray: {
                                        color: '#727CBA'
                                    }
                                },
                                formatter: function(data) {
                                    //富文本固定格式{colorName|这里填你想要写的内容}
                                    let index = data.dataIndex+1
                                    if (index == 1) {
                                        return '{start1|}{red|' +index + '  ' + data2[data.dataIndex] + '}'
                                    } else if (index == 2) {
                                        return '{start1|}{yellow|' +index + '  ' + data2[data.dataIndex] + '}'
                                    } else if (index == 3) {
                                        return '{start1|}{green|' +index + '  ' + data2[data.dataIndex] + '}' 
                                    } else {
                                        return '{start2|}{white|' +index + '  ' + data2[data.dataIndex] + '}'
                                    }

                                },
                            }
                        },
                    }
                ],
                grid: {
                    left: '8%',
                    top: 20,
                    right: '20%'
                },
            }
            const myChart = this.$echarts.init(this.$refs.wrap)
            myChart.setOption(options)
            // window.addEventListener('resize', () => {
            //     myChart.resize();
            // })
        },
        /**改变年月 */
        async changeYears(){
            this.loading=true
            let param = this.type?{yearMonth:this.years,type:this.type}:this.years  // 专业电表用电月排名的type是空的
            console.log()
            let res = await this.requestApi(param)
            this.loading=false
            this.getInit(res)
        },
        getInit(res) {
            let data1 = []
            let data2 = []
            res.data.map(item => {
                data1.push(Number(item.total).toFixed(2))
                data2.push(item.name)
            })
            this.setOptions(data1, data2)
        }
    }

}
</script>
<style lang="scss" scoped>
.headle {
    border-bottom: 1px solid black;
    width: 86%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 14px;
    .left {
        font-weight: bold;
    }
}
.wrap {
    width: 86%;
    height: 300px;
}
.datePicker{
    text-align: right;
    padding-right: 70px;
    /deep/ .el-date-editor{
        margin: 10px 0;
        width: 120px;
    }
}
</style>