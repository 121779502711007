<template>
  <!--  指派 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" title="转单">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
  </el-dialog>
</template>
<script>
import pageTypeObj from '../differPage'
import { turnOrderItem } from '@/api'
export default {
  inject: ['labelWidth', 'getInit', 'pageType'],
  data() {
    return {
      rowFormData: [
        { input_type: 'title', text: '当前信息', col: 24, noMarginBottom: true, },
        { input_type: 'text2', label: '所属部门', key: 'solveDeptName', col: 11, },
        { input_type: 'text2', label: '人员姓名', key: 'solveEmployeeNames', col: 11, },
        { input_type: 'text2', label: '处理时限', key: 'solveLimitTime2', col: 22, rightBox: { text: '分钟' }, isFlex: true, },
        { input_type: 'text2', label: '人数限制', key: 'finishDetailDesc', col: 11, },
        { input_type: 'title', text: '转单设置', col: 24, noMarginBottom: true, },
        {
          label: '所属部门', input_type: 'select', key: 'solveDeptId', options: [],
          relevance: {
            sonKey: 'solveEmployeeIds', This: this, type: 'select', // 必填。而 rqParam 是异步请求，必填字段
            rqParam: { rqUrl: "/employee/dept/deptIds/repair", rqData: () => { return {deptIds:[this.rowForm.solveDeptId],orderId:this.rowForm.id} }, rqMethod: 'post', labelName: 'username', valueName: 'id', resRule: 'data' }, // 
          }
        },
        { label: '人员姓名', input_type: 'select', key: 'solveEmployeeIds', multiple: true, options: [],multipleLimit:0	 }, // 要注意，options 必写
        { input_type: "input", label: '处理时限', key: 'solveLimitTime', col: 11, rightBox: { text: '分钟' }, isFlex: true, },
      ],
      rowForm: {},
      rules: {
        solveDeptId: [{ required: true, message: '请选择部门', trigger: 'change' }],
        solveEmployeeIds: [{ required: true, message: '请选择人员', trigger: 'change' }],
        solveLimitTime: [{ required: true, message: '请完善处理时限', trigger: 'blur' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      index_obj: {},
    }
  },
  created() {
    this.index_obj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },

  methods: {
    rowEdit(row,) {
      this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
      this.rowFormData[this.index_obj.solveDeptId].options = [...this.$parent.$parent.allDepts]
      // 限制选择人生
      // console.log(row,row.finishDetail.total,row.finishDetail.desc);
      // this.rowFormData[this.index_obj.solveEmployeeIds].multipleLimit = 5

      const rqMethod = pageTypeObj[this.pageType].rqDetail
      this.loadingForm = true
      this.showEditAll = true
      rqMethod(row.id).then(res => {
        const data = res.data
        this.rowForm = {
          id: data.id,
          status: data.status,
          solveDeptName: data.solveDeptName,
          solveEmployeeNames: data.solveEmployeeNames,
          solveLimitTime2: data.solveLimitTime,
          classify: data.classify,
          finishDetailDesc:`${data.finishDetail.finish}/${data.finishDetail.total}`
        }
        this.$nextTick(() => {
          this.$refs.jaForm.$refs.formbox.clearValidate()
        })
        this.loadingForm = false
      }).catch(err => {
        this.loadingForm = false
      })

    },
    saveForm() {
      let obj = { ...this.rowForm }
      obj.solveEmployeeIds = obj.solveEmployeeIds.join(',')
      this.loadingForm = true
      // const rqMethod = pageTypeObj[this.pageType].assignOrder_fun
      const rqMethod = turnOrderItem
      this.loadingForm = true
      rqMethod(obj).then(res => {
        this.loadingForm = false
        this.$message.success(res.msg || '提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },
  }
}
</script>