<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">能耗趋势</div>
    </div>

    <div class="charts" @mousemove="mouseoverFun" @mouseout="mouseoutFun">
      <ja-init-chart ref="chart" class="jachart" :options="options" />
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
import chartcar from '@/assets/gd/ds_chartcarbg.png'

export default {
  props: {
    alarmList: {
      type: Array,
      default: () => []
    },
    typeData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  components: {
    JaInitChart
  },
  data() {
    return {
      options: {},
      time: '',
      count:0,
      yeaMonthArr:[]
    }
  },
  watch:{
    alarmList(){
      this.init()
      this.clickOn()
    },
    typeData(newVla){
      this.typeData = newVla
    }
  },
  beforeDestroy() {
    clearInterval(this.time)
  },
  created () {
    this.generateYearMonth()
  },
  methods: {
    async init() {
      this.loading = false
      const dateTime = []
      const old = []
      const now = []
      const chainBase = []
      this.alarmList.map(item=>{
        dateTime.push(item.date+'月')
        now.push(item.thisDosage)
        old.push(item.lastDosage)
        chainBase.push(item.chainBase)
      })
      this.options = {
        tooltip: {
          type: 'item',
          trigger: 'axis',
          backgroundColor: 'transparent',
          formatter: (params)=> {
            const date = this.yeaMonthArr[params[0].dataIndex]
            const number = params[4]?.data ? params[4]?.data : -0
            const stra = number < 0 ? '下降' : '增长'
            const data = stra+Math.abs(number)
              let str = `<div style = "font-size: 12px;background-image:url(${require('../../../../../../assets/images/gd/bubble@2x.png')});background-size: 100% 100%;width: auto;height: auto;padding:6px;padding-bottom:14px;">
              <div style = "width: auto;background-color: rgba(12, 30, 67, 0.8);color:#ca9b37;margin: 0 auto;">${date}<br/> 总计用${this.typeData.name}量<span style="color:${Number(params[1].data) <= 0? 'red':'#ca9b37'}">${params[1].data}</span>${this.typeData.ulit}<br/> 上期用${this.typeData.name}量<span style="color:${Number(params[0].data) <= 0 ? 'red' : '#ca9b37'}">${params[0].data}</span>${this.typeData.ulit}<br/> 环比<span>${data}</span>%</div>
              </div>`
              return str
          },
          axisPointer: {
            // type: 'cross',  鼠标移动上去显示 虚刻度线
            crossStyle: {
              color: '#999'
            },
            z: 20
          },
          position: function (point, params, dom, rect, size) {
              // 固定在顶部
              let index=params[0]['dataIndex'];
              let y=point[0]-30
              if(index==5){
                y=point[0]-100
              }
              return [y, 0];
          }
        },
        grid: {  //调整统计图上下左右边距
          width: '70%',
          left: 'center',
          bottom: 50,

        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: dateTime,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            },
            axisLine: {   // X轴线的颜色
              lineStyle: {
                color: '#1A3E47'
              }
            }
          }
        ],
        yAxis: [
          {
            splitLine: {   //控制刻度横线的显示
              show: false
            },
            type: 'value',
            position: 'left',
            // max: 20,
            min: 0,
            minInterval: 5,
            // name:"推\n送\n班\n级\n数",
            nameLocation: "center",
            nameGap: 35,
            nameRotate: 0,
            nameTextStyle: {
              fontSize: 16,
            },
            axisLabel: {
              formatter: '{value} ',
              show: true,
              textStyle: {
                color: '#27d0d8',
                fontSize: 12
              }
            },
            axisLine: {  // Y轴线的颜色、和轴线的宽度
              lineStyle: {
                color: '#797979',
                width: 2
              }
            }
          },
          {
            splitLine: {   //控制刻度横线的显示
              show: false
            },
            type: 'value',
            position: 'right',
            minInterval: 5,
            nameLocation: "center",
            nameGap: 35,
            nameRotate: 0,
            nameTextStyle: {
              fontSize: 16,
            },
            axisLabel: {
              formatter: (params)=>{
                return params
              },
              show: true,
              textStyle: {
                color: '#27d0d8',
                fontSize: 12
              }
            },
            axisLine: {  // Y轴线的颜色、和轴线的宽度
              lineStyle: {
                color: '#797979',
                width: 2
              }
            }
          }
        ],
        series: [
          {
            name: '去年',
            type: 'bar',
            yAxisIndex: 0,
            data: old,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(21,136,209,0.1)',
              image: 'image://' + chartcar,
            },
            itemStyle: {   //双Y轴A柱的柱体颜色
              normal: {
                color: '#D8FF23'
              }
            }
          },
          {
            name: '今年',
            type: 'bar',
            yAxisIndex: 0,
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(21,136,209,0.1)',
              image: 'image://' + chartcar,
            },
            data: now,
            itemStyle: {   //双Y轴B柱的柱体颜色
              normal: {
                color: '#00A5FF'
              }
            }
          },
          {
            //分隔
            name: '今年',
            tooltip: ' ',
            type: 'pictorialBar',
            itemStyle: {
              color: '#295C71'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 3,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [19, 2],
            symbolPosition: 'start',
            symbolOffset: [12, 0],
            data: now,
            z: 10
          },
          {
            //分隔
            name: '去年',
            tooltip: ' ',
            type: 'pictorialBar',
            itemStyle: {
              color: '#295C71'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 3,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [19, 2],
            symbolPosition: 'start',
            symbolOffset: [-12, 0],
            data: old,
            z: 10
          },
          {
            name: '环比',
            data: chainBase,
            type: 'line',
            yAxisIndex: 1,
            axisLine: { 
              onZero : false,
             },
            itemStyle: {
              color: '#218c69'
            },
            smooth: true
          }
        ]
      }
    },
    parmasFun(myChart,params,methods){
      const pointInPixel = [params.offsetX, params.offsetY];
      //点击第几个柱子
      let index;
      if (myChart.containPixel('grid', pointInPixel)) {
        index = myChart.convertFromPixel({seriesIndex: 0}, [params.offsetX, params.offsetY])[0];
      }
      if (index !== undefined) {
        clearInterval(this.time)
        if(methods === 'mouseout'){
          this.setTime(myChart)
        }
        if(methods === 'mouseover'){    
          return
        }
        this.$emit(methods,this.alarmList[index])
      }
    },
    mouseoverFun(){
      clearInterval(this.time)
    },
    mouseoutFun(){
      const myChart = this.$echarts.init(this.$refs.chart.$refs.wrap) 
      this.setTime(myChart)
    },
    clickOn(){
      const myChart = this.$echarts.init(this.$refs.chart.$refs.wrap) 
      myChart.getZr().on('click',(params) => {
        this.parmasFun(myChart,params,'clickOn')
      })
      this.setTime(myChart)
    },
    setTime(myChart){
      clearInterval(this.time)
       // 代码高亮
       this.time = setInterval(() => {
          this.count === this.alarmList.length ? this.count = 0 : ''
          // 展示提示框
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: this.count
          });
          this.count++
      },3000)
    },
    /**生成最近6个年月 */
    generateYearMonth(){
      const moment = this.$moment;
      for (let i = 0; i < 6; i++) {
        const date = moment().subtract(i+1, 'months');
        const month = date.month() + 1; // 月份从0开始，需要加1
        const year = date.year();
        let yeaMonth=year+'-'+month
        this.yeaMonthArr.unshift(yeaMonth);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  // .charts {
  //   margin-top: 50px;
  // }

  ul {
    right: 65px !important;

    li {
      width: 220px !important;
    }
  }
}

.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .header {
    height: 26px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .icons {
      width: 12px;
      height: 8px;
    }

    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    .jachart {
      z-index: 2;
      width: 100%;
      height: 90%;
    }

  }
}
</style>