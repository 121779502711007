import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

let noRuter
//优先级更高执行监听
if (window.top === window) {
} else {
  // 当前页面在一个iframe中
  if (window.location.href.includes('deshunRadio')){
  }else{
    localStorage.clear();
    noRuter = true
    window.addEventListener('message', e => {
      if (e.origin.indexOf('http://121.37.251.55:8280') != -1 && e.data.type == 'settoken') {
        store.commit('setToken', JSON.parse(e.data.token))
        localStorage.setItem('token', e.data.token);
      }
    }, false)
  }
}

if (noRuter != true){
  router.beforeEach((to, from, next) => {
    // next()
    // return
    if (to.fullPath.includes('deshunRadio')) {
      store.commit('SET_IOCURL', to.fullPath)
    }
    const token = localStorage.getItem('token')
    if (token) { // 如果已经登录
      // console.log('----store',store.getters,'to',to)
      if (!store.getters.roles) {
        // console.log('路由-=====  角色 ---用户信息',store.getters.roles)
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(res => {
          sessionStorage.setItem('permissions', JSON.stringify(res.permissions))
          store.dispatch('GenerateRoutes').then(accessRoutes => {
            // console.log('最终生成的路由表====》',accessRoutes)
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          })
        }).catch(err => {
          store.dispatch('FedLogOut').then(() => {
            // Message.error(err)
          })
        })
      } else {
        if (to.fullPath.includes('home')) {
          next()
          return
        }
        next()
      }
    } else { // 如果没有token
      console.log('to==>', to)
      let isChart = to.fullPath.slice(0, 3)
      if (isChart == '/ds') { //没登录的情况下根据地址判断是否前往图表窗口页，只有前往图表页才放行
        next()
      } else {
        if (to.name == '404') {
          next('/login')
        }
        if (to.fullPath.includes('login')) {
          next()
          return
        }
        next('/login')
      }
    }
  })

  router.afterEach((to, from) => {
    store.commit('setLoading', true)
  })

}
