import pageTypeObj from './differPage'
import { getOrderList, endOrderItem, resetOrderItem } from '@/api'
import { getAlarmConfig } from '@/api/module/alarm.js'

export default {
  provide() {
    return {
      pageType: this.pageType
    }
  },
  data() {
    return {
      labelWidth: `${this.util.setFontSize(150)}px`,
      filterData: { pageIndex: 1, pageSize: 10 },
      conditionData: [
        { input_type: 'select', key: 'status', attribute: { placeholder: '工单状态', size: 'small' }, options: [], styleWidth: '100px' },
        { input_type: 'input', key: 'orderCodeOrName', attribute: { placeholder: '提单人/工单号', size: 'small' } },
        { input_type: 'input', key: 'submitEmployeeCompany', attribute: { placeholder: '所属公司', size: 'small' } },
        { input_type: 'input', key: 'solveEmployeeNames', attribute: { placeholder: '接单人', size: 'small' } },
        { label: '选择日期', input_type: 'date', type: 'daterange', value: 'time', format: 'yyyy-MM-dd', startPlaceholder: '开始日期', endPlaceholder: '结束日期', attribute: { unlinkPanels: true }, valueFormat: 'yyyy-MM-dd', },
        { normalBtn: 'search', limit: 'default:view'},
        { normalBtn: 'reset' },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/work/info/getListExport', rqMethod: 'post' } }
        // { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '', rqMethod: 'post' }, limit: '' },
      ],
      tableData: [{}],
      loading: false,
      total: 0,
      results: null,
      columns: [
        { label: '工单号', prop: 'workOrderCode' },
        { label: '工单类型', prop: 'classifyValue' },
        { label: '提单人', prop: 'submitEmployeeName' },
        { label: '所属公司', prop: 'submitEmployeeCompany' },
        { label: '提单时间', prop: 'orderSubmitTime' },
        { label: '接单人', prop: 'solveEmployeeNames' },
        { label: '工单状态', prop: 'statusValue' },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '派单', method: 'rowAssignOrder', },
            { text: '转单', method: 'rowTurnOrder', },
            { text: '结单', method: 'rowResOrder', },
            // { text: '重启', method: 'rowReset', },
            // { text: '审核', method: 'rowCheckOrder', },
            { text: '详情', method: 'rowDetail', },
          ]
        }
      ],

      // 维修列表
      columnsfix: [
        { label: '工单号', prop: 'workOrderCode' },
        { label: '工单类型', prop: 'classifyValue' },
        { label: '提单人', prop: 'submitEmployeeName' },
        { label: '所属公司', prop: 'submitEmployeeCompany' },
        { label: '提单时间', prop: 'orderSubmitTime' },
        { label: '接单人', prop: 'solveEmployeeNames' },
        { label: '工单状态', prop: 'statusValue' },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '派单', method: 'rowAssignOrder', },
            { text: '转单', method: 'rowTurnOrder', },
            { text: '结单', method: 'rowResOrder', },
            // { text: '重启', method: 'rowReset', },
            // { text: '审核', method: 'rowCheckOrder', },
            { text: '详情', method: 'rowDetail', },
          ]
        }
      ],
      pageTypeObj, // 区别页面 的对象，
      allDepts: [],  // 所有部门
    }
  },
  created() {
    this.util.rqOptionsList({ who: this.conditionData[0], rqUrl: "/sys/dictData/work_order_status", resRule: 'data' })
    this.util.rqOptionsList({ rqUrl: "/department/all", resRule: 'data', labelName: 'deptName', valueName: 'deptId' }).then(res => {
      this.allDepts = res
    })
    this.deal_condition_limt()
  },
  methods: {
    // 处理 顶部导出的接口 以及 按钮权限
    deal_condition_limt() {
      // // 导出地址
      // let exportItem = this.conditionData.find(v => v.normalBtn === 'download')
      // this.$set(exportItem.exportObj, 'rqUrl', pageTypeObj[this.pageType].export_url)
      // this.$set(exportItem, 'limit', pageTypeObj[this.pageType].limitObj['导出'])
      // 权限
      let btns = this.columns[this.columns.length - 1].list
      btns.forEach(item => {
        this.$set(item, 'limit', pageTypeObj[this.pageType].limitObj[item.text])
      })
    },
    // 要请求的参数
    rqParmas() {
      let obj = { ...this.filterData }
      obj.classify = pageTypeObj[this.pageType].value
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      delete obj.time
      return obj
    },
    async getInit() {
      // console.info('-----------' + this.typeObj.id)
      this.loading = true
      let data = this.rqParmas()
      if (this.pageType == 'alarm'){
        let { data: res } = await getAlarmConfig(data)
        this.loading = false
        this.tableData = res.data
        this.tableData.forEach(item => {
          if(item.status == 1){
            item.status = '待处理'
          }else{
            item.status = '已处理'
          }
        })
        this.total = res.total
      }else{
        const rqMethod = getOrderList
        rqMethod(data).then(res => {
          // console.log(res)
          this.loading = false
          this.tableData = res.data.data
          this.total = res.data.total
        }).catch(err => {
          this.loading = false
        })
      }
    },
    openResult({ data, uploadFlag }) {
      this.results = { data, uploadFlag }
      this.$nextTick(() => {
        this.$refs.checkResult.rowDetail()
      })
    },
    // 指派工单
    rowAssignOrder(row) {
      this.$refs.windowBox.$refs.assignOrder.rowEdit(row)
    },
    // 转单
    rowTurnOrder(row) {
      this.$refs.windowBox.$refs.turnOrder.rowEdit(row)
    },
    // 审核
    rowCheckOrder(row) {
      this.$refs.windowBox.$refs.resOrder.rowEdit(row)
    },
    // 结单
    rowResOrder(row) {
      this.$confirm(`确定工单【 ${row.workOrderCode} 】结单吗，结单后无法修改状态`, '结单')
        .then((res) => {
          // this.pageTypeObj[this.pageType].resetOrder_fun(row.id).then((res) => {
          endOrderItem(row.id).then((res) => {
            this.getInit()
            this.$message.success(res.msg || '结单成功')
          })
        })
    },
    // 重启
    rowReset(row) {
      this.$confirm(`确定重启工单【 ${row.workOrderCode} 】 ，重启后继续计时！`, '挂起')
        .then((res) => {
          // this.pageTypeObj[this.pageType].resetOrder_fun(row.id).then((res) => {
            resetOrderItem({ id: row.id, classify: row.classify }).then((res) => {
            this.getInit()
            this.$message.success(res.msg || '已重启')
          })
        })
    },
    // 导出
    exportFun() {
      let data = this.rqParmas()
      const rqMethod = pageTypeObj[this.pageType].exportTable_fun
      rqMethod(data).then(res => {
        this.util.dealExcelRespose(res)
      })
    },
    editDataFormat(row, btn) {
      if (row.status !== 0 && btn.text === '派单') {
        return false
      } else if ((btn.text === '转单' || btn.text === '结单') && (row.status !== 1 && row.status !== 2 && row.status !== 5 && row.status !== 6)) {
        return false
      } else if (row.status !== 7 && btn.text === '重启') {
        return false
      } else if ((row.status !== 3 && row.status === 4) && btn.text === '审核') {
        return false
      } else if ((row.status === 5 || row.status === 0 || row.status === 1 || row.status === 6 || row.status === 2) && btn.text === '审核' ) {
        return false
      }else {
        return true
      }
    },

    editDataFormatFix(row, btn) {
      // 0-待派单：派单、详情
      // 1-待接单：结单、详情
      // 2-处理中：转单、结单、详情
      // 3-结单审核中/4-已结单：详情
      // 5-接单超时/6-处理超时：派单、结单、详情
      // 7-挂起中：详情
      if(btn.text==='详情'){
        return true
      }
      if([1,2,5,6].indexOf(row.status)!=-1 && btn.text === '结单'){
        return true
      }
      if([0,5,6].indexOf(row.status)!=-1 && btn.text === '派单'){
        return true
      }
      if([3,4].indexOf(row.status)!=-1 && btn.text === '已结单'){
        return true
      }
      if([2].indexOf(row.status)!=-1 && btn.text === '转单'){
        return true
      }

      return false
    },
  },
}