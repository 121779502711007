const visualsystem = {
    state: {
        systemUrl:'',
        buildingFloor:{}
    },

    mutations: {
        SET_systemUrl: (state, url) => {
            state.systemUrl = url
        },
        SET_buildingFloor: (state,obj) => {
            state.buildingFloor = obj
        }
    },

    actions: {
      
    }
}

export default visualsystem
