import { getWorrkConfig, delWorrkConfig } from '@/api'

let common = {
  columns: [
    // { label: '报修类型', prop: 'applyCode' },
    { label: '派单类型', prop: 'sendOrderTypeValue' },
    { label: '接单人', prop: 'employeeNames' },
    { label: '处理时限', prop: 'processTimeLimit_' },
    { label: '转单时间', prop: 'takeOrderTimeLimit_' },
    {
      label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
      list: [
        { text: '编辑', method: 'rowEdit', limit: 'workOrderManage::orderConfig::edit' },
        { text: '删除', method: 'rowDelete', limit: 'workOrderManage::orderConfig::delete' },
      ]
    }
  ],
  rowFormData: [
    { input_type: 'radio', label: '派单类型', key: 'sendOrderType', col: 22, options: [{ label: '自动派单', value: 1 }, { label: '手动派单', value: 0 }],method:'radioEvent'},
    { input_type: 'select', label: '接单人设置(多选)', key: 'employeeIdList', col: 22, multiple: true, options: [] },
    { input_type: "input", label: '处理时限', key: 'processTimeLimit', col: 11, rightBox: { text: '分钟' }, isFlex: true, },
    {
      input_type: 'select', label: '自动派单', key: 'takeOrderTimeLimit', col: 22, rightBox: { text: '内,用户没有接单，则可进入手动派单' }, isFlex: true, options: [
        { label: '1分钟', value: '1' }, { label: '30分钟', value: "30" }, { label: '60分钟', value: "60" }, { label: '90分钟', value: "90" },
      ]
    },
  ],
  rowFormData2: [
    { input_type: 'input', label: '接单人数', key: 'acceptEmployeeLimit', col: 11, rightBox: { text: '人' }, isFlex: true, },
    { input_type: 'radio', label: '派单类型', key: 'sendOrderType', col: 22, options: [{ label: '自动派单', value: 1 }, { label: '手动派单', value: 0 }],method:'radioEvent'},
    { input_type: 'select', label: '接单人设置(多选)', key: 'employeeIdList', col: 22, multiple: true, options: [] },
    { input_type: "input", label: '处理时限', key: 'processTimeLimit', col: 11, rightBox: { text: '分钟' }, isFlex: true, },
    {
      input_type: 'select', label: '自动派单', key: 'takeOrderTimeLimit', col: 22, rightBox: { text: '内,用户没有接单，则可进入手动派单' }, isFlex: true, options: [
        { label: '1分钟', value: '1' }, { label: '30分钟', value: "30" }, { label: '60分钟', value: "60" }, { label: '90分钟', value: "90" },
      ]
    },
  ],
  rules: {
    workOrderTypeId: [{ required: true, message: '请选择报修类型', trigger: 'change' }],
    sendOrderType: [{ required: true, message: '请选择派单类型', trigger: 'change' }],
    employeeIdList: [{ required: true, message: '请选择接单人', trigger: 'change' }],
    processTimeLimit: [
      { required: true, message: '请完善处理时限', trigger: 'blur' },
      {
        pattern: /^(0|[1-9][0-9]*)$/,
        message: "请输入数字",
        trigger: "blur"
      }
    
    ],
    acceptEmployeeLimit: [
      { required: true, message: '请填写接单人数', trigger: 'blur' },
      {
        pattern: /^(0|[1-9][0-9]*)$/,
        message: "请输入数字",
        trigger: "blur"
      }
    
    ],
    takeOrderTimeLimit: [{ required: true, message: '请设置时长', trigger: 'change' }],
  }

}

export default {
  repair: {
    typeText: '维修',
    columns: [{ label: '报修类型', prop: 'workOrderTypeValue' }, ...common.columns],
    getInit: getWorrkConfig,
    rowDelete: delWorrkConfig,
    rowFormData: [
      { input_type: 'select', label: '报修类型', key: 'workOrderTypeId', col: 11, options: [] },
      ...common.rowFormData2
    ],
    rules: { ...common.rules },
  },
  inspect: {
    typeText: '维保',
    columns: [{ label: '维保类型', prop: 'workOrderTypeValue' }, ...common.columns],
    getInit: getWorrkConfig,
    rowDelete: delWorrkConfig,
    rowFormData: [
      { input_type: 'select', label: '维保类型', key: 'workOrderTypeId', col: 11, options: [] },
      ...common.rowFormData
    ],
    rules: { ...common.rules },
  },
  alarm: {
    typeText: '告警',
    columns: [{ label: '告警类型', prop: 'workOrderTypeValue' }, ...common.columns],
    getInit: getWorrkConfig,
    rowDelete: delWorrkConfig,
    rowFormData: [
      { input_type: 'select', label: '告警类型', key: 'workOrderTypeId', col: 11, options: [] },
      ...common.rowFormData
    ],
    rules: { ...common.rules },
  },
  scan: {
    typeText: '巡更',
    columns: [{ label: '巡更工单', prop: 'workOrderTypeValue' }, ...common.columns],
    getInit: getWorrkConfig,
    rowDelete: delWorrkConfig,
    rowFormData: [
      { input_type: 'select', label: '告警类型', key: 'workOrderTypeId', col: 11, options: [] ,hide:true},
      ...common.rowFormData
    ],
    rules: { ...common.rules },
  },
}