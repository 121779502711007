<template>
    <div>
        <el-container>
            <left-list></left-list>
            <mian-video title="停车系统" url="http://121.37.251.55:8280/#/?model=广电地下"></mian-video>
        </el-container>
    </div>
</template>
<script>
import LeftList from '../components/LeftList.vue'
import MianVideo from '../components/MianVideo.vue'
export default {
    title: '停车系统',
    name: 'parkingSystem',
    components: {
        LeftList,
        MianVideo,
    },
    data () {
        return {

        }
    },
}
</script>
<style lang="scss" scoped>
.el-container {
    position: relative;
    margin: 0 15px;
}
:-webkit-full-screen .el-container{
  margin: 0!important;
}
</style>
