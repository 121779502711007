<template>
  <div class="dswindow" v-if="dialogVisible">
    <span class="shutDown" @click="dialogVisible=false"></span>
    <div class="electrical" 
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" 
    v-loading="loading">
      <el-scrollbar style="height: 88%" :vertical="false" class="scroll-container loop_config_content">
        <ul class="switch_button">
          <li>回路按钮：</li>
          <li>
              <el-radio-group v-model="form.id" @input="changeRadio">
                <el-radio
                  :label="item.id"
                  v-for="item in lightbuttonArr"
                  :key="item.id"
                  >{{ item.circuitName }}</el-radio
                >
              </el-radio-group>
          </li>
        </ul>
        <el-checkbox-group v-model="form.deviceIds">
          <el-checkbox
            :label="item.deviceId"
            v-for="item in diaStyleList"
            :key="item.deviceId"
            >
            {{ item.deviceName }}
            </el-checkbox
          >
        </el-checkbox-group>
      </el-scrollbar>
      <div  class="dialog-footer">
        <el-button type="primary" @click="lightConfig">确 定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getCircuitManagement,getLightList,editCircuitManagement} from '@/api'
export default {
  data () {
    return {
      lightbuttonArr: [
        { circuitName: "按钮A", },
        { circuitName: "按钮B", },
        { circuitName: "按钮C", },
      ],
      diaStyleList:[
        {deviceName:'灯泡02',value:2},
        {deviceName:'灯泡02',value:3},
        {deviceName:'灯泡02',value:4},
        {deviceName:'灯泡02',value:5},
        {deviceName:'灯泡02',value:6},
        {deviceName:'灯泡02',value:7},
        {deviceName:'灯泡02',value:8},
        {deviceName:'灯泡02',value:9},

      ],
      form: { deviceIds: [], id: "" },
      dialogVisible:false,
      loading:true,
      currentCircuit:[] // 选中当前回路的数据
    }
  },
  computed: {
    ...mapGetters(['buildingFloor'])
  },
  watch: {
    //楼栋楼层id发生变化时关闭弹窗
    buildingFloor(){
      this.dialogVisible=false;
      let filterData={
        buildingId:this.buildingFloor.buildingId,
        floorId:this.buildingFloor.floorId,
        pageIndex:1,
        pageSize:1000
      }
      this.init(filterData)
      this.getLightList(filterData)
    }
  },
  mounted() {
    if(!this.buildingFloor) return
    let filterData={
      buildingId:this.buildingFloor.buildingId,
      floorId:this.buildingFloor.floorId,
      pageIndex:1,
      pageSize:1000
    }
    this.init(filterData)
    this.getLightList(filterData)
  },
  methods: {
    /**初始化回路数据 */
    init(filterData){
      this.form.deviceIds=[];
      this.form.id=''
      this.currentCircuit=[]
      getCircuitManagement(filterData).then(res => {
          const { data, total } = res.data
          this.lightbuttonArr = data
          this.loading=false
      }).catch(err => {
        this.loading=false
      })
    },
    /**获取照明设备 */
    getLightList(filterData){
        if(filterData.buildingId && filterData.floorId){
            getLightList(filterData).then(res=>{
                this.diaStyleList=res.data.data;
            })
          }
    },
    /**点击回路 */
    changeRadio(val){
      this.currentCircuit= this.lightbuttonArr.filter(item=>{
        return item.id==val? item:''
      })
      let data=this.currentCircuit[0].deviceIds?this.currentCircuit[0].deviceIds:[]
      this.$set(this.form,'deviceIds',data)
    },
    /**点击确定按钮 */
    lightConfig(){
        if(!this.form.id || this.form.deviceIds.length==0){
          this.$message.warning("回路或设备都不能为空！")
          return
        }
         this.loading=true;
        let obj= this.currentCircuit[0];
        obj.deviceIds=this.form.deviceIds
       editCircuitManagement(obj).then(res => { // 更新设备列表信息
          this.$message.success(res.msg)
          this.loading=false;
          this.dialogVisible = false
          this.$emit("lightConfig")
      }).catch(error => {
          this.loading=false;
      })
      
    },
    /**其他组件调用，开启弹窗 */
    showDialog(i) {
      this.dialogVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  position: relative;
  .shutDown{
      display: inline-block;
      width: 25px;
      height: 30px;
      background: url('~@/assets/images/gd/shutDown.png') no-repeat;
      background-size: 100% 100%;
      right: -10px;
      top: -10px;
      position: absolute;
      z-index: 10;
      cursor: pointer;
    }
  .electrical {
    width: 478px;
    height: 326px;
    padding:20px 34px;
    position: relative;
    background: url('~@/assets/images/gd/loop_config.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    // overflow-y: auto;
    overflow: hidden;
    color: #fff;
    ::v-deep .el-form-item__label{
      color: #fff;
    }
    ::v-deep .el-checkbox__label{
      color: #fff;
    }
    ::v-deep .el-radio__label{
      color: #fff;
    }
    ::v-deep .is-checked  .el-radio__label{
      color: #F7E100;
    }
    ::v-deep .el-radio__input.is-checked .el-radio__inner{
      border-color: #F7E100;
      background: #F7E100;
    }
    ::v-deep .el-radio__inner::after {
      width: 6px;
      height: 6px;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #F7E100;
      background: #F7E100;
    }
    ::v-deep .el-button--primary{
      background: url('~@/assets/images/gd/confirm.png') no-repeat;
      color: #BAE9FF;
      width: 118px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      background-size: 100% 100%;
      box-sizing: border-box;
    }
    ::v-deep .el-form-item__content{
      line-height: 28px;
    }
    ::v-deep .el-radio{
      line-height: 28px;
    }
    ::v-deep .el-checkbox{
      margin-bottom: 12px;
    }
    .switch_button{
      font-size: 16px;
      display: flex;
      padding-bottom: 12px;
      li:nth-of-type(1){
        padding-top: 8px;
        font-size: 14px;
        width: 80px;
      }
      li:nth-of-type(2){
        width: 328px;
      }
    }
    .loop_config_content{
      min-height: 240px;
    }
    .dialog-footer{
      text-align: center;
    }
    ::v-deep .el-scrollbar__wrap{
      overflow: auto;
    }
  }
}
</style>