// WSPlayer 已经注册到window对象中，无需重复引用。或者根据实际情况引入
// 调试模式下使用源码时需要将下面的注释放开
// import WSPlayer from '../../../WSPlayer/WSPlayer'
import API from "./api";

let WSPlayerConstructor = WSPlayer;
if(WSPlayer.WSPlayer) {
  WSPlayerConstructor = WSPlayer.WSPlayer
}

/**
 * WSPlayer是核心组件
 * API 封装了接口
 */
class PlayerManager {
  constructor(opt) {
    // 播放器所在的容器ID
    this.el = opt.el;
    // 实时预览播放器
    this.realPlayer = null;
    // 录像回放播放器
    this.recordPlayer = null;
    this.player = null;
    // 实时预览还是录像回放播放器
    this.type = "real";
    // 窗口的数量
    this.playNum = 1;
    // 当前选中的窗口的索引
    this.playIndex = 0;
    // 当前选中窗口正在播放视频的通道
    this.currentChannelId = "";
    // 索引对应窗口，保存当日录像信息
    this.recordList = [];

    /**
     * 兼容老版本字段 【已废弃】
     * 在 ws/wss 直连模式下, serverIp 表示MTS流媒体服务 IP
     * 在 代理 模式下, serverIp 表示的是 代理服务器的IP 地址
     */
    this.serverIp = window.location.host

    /**
     * V1.2.7 版本新增字段
     * @desc 代理 模式下，代理服务器的IP 地址
     * 如果代理服务有端口则拼接端口
     */
    this.proxyServerIp = opt.proxyServerIp || "124.160.33.135"
    /**
     * V1.2.7 版本新增字段
     * @desc 流媒体服务 IP 地址
     * 如果拉流的 9100 9320 端口发生修改，则需要后面拼接修改后的端口
     */
    this.streamServerIp = opt.streamServerIp || "124.160.33.135"
    /**
     * V1.2.7 版本新增字段
     * @desc 模式类型 是直连模式/代理模式
     */
    this.useNginxProxy = typeof opt.useNginxProxy === 'boolean' ? opt.useNginxProxy : true

    // 初始化播放器
    switch(opt.type) {
      case "real":
        this.initRealPlayer(opt);
        break;
      case "record":
        this.initRecordPlayer(opt);
        break
    }
  }

  /**
   * 初始化实时预览播放器
   */
  initRealPlayer(opt) {
    if(!this.serverIp && !this.streamServerIp && !this.proxyServerIp) {
      return;
    }
    this.playNum = opt.num;
    this.type = "real";
    this.realPlayer = new WSPlayerConstructor({
      el: this.el,
      type: 'real',
      serverIp: this.serverIp, // V1.2.7 版本已废弃[兼容老版本]
      proxyServerIp: this.proxyServerIp, // V1.2.7 版本新增该配置 代理服务器的ip 【ws/wss直连时不传】
      streamServerIp: this.streamServerIp, // V1.2.7 版本新增该配置 流媒体服务器的ip
      config: {
        num: opt.num,
        maxNum: opt.maxNum,
        showControl: opt.showControl, // 默认是否显示工具栏
        useNginxProxy: this.useNginxProxy // V1.2.7 版本新增该配置 直连时需要改为 false, 代理模式为 true
      },
      receiveMessageFromWSPlayer: opt.receiveMessageFromWSPlayer || this.__receiveMessageFromWSPlayer.bind(this),
      getRealRtsp: API.getRealmonitor, // 获取实时预览rtsp接口
      getTalkRtsp: API.getTalkRtsp, // 获取对讲rtsp接口
    })
    console.info("===========", API)
    this.player = this.realPlayer;
    // 初始化云台控制组件
    if(opt.pztEl) {
      this.realPlayer.initPanTilt({
        // 云台控制容器的id
        el: opt.pztEl,
        // 云台区域控制接口
        setPtzDirection: API.setPtzDirection,
        // 云台镜头控制接口
        setPtzCamera: API.setPtzCamera,
        // 云台三维定位接口
        controlSitPosition: API.controlSitPosition
      })
    }
  }

  /**
   * 初始化录像回放播放器
   */
  initRecordPlayer(opt) {
    if(!this.serverIp && !this.streamServerIp && !this.proxyServerIp) {
      return;
    }
    this.playNum = opt.num;
    this.type = "record";
    this.recordPlayer = new WSPlayerConstructor({
      el: this.el,
      type: 'record',
      serverIp: this.serverIp, // V1.2.7 版本已废弃[兼容老版本]
      proxyServerIp: this.proxyServerIp, // V1.2.7 版本新增该配置 代理服务器的ip 【ws/wss直连时不传】
      streamServerIp: this.streamServerIp, // V1.2.7 版本新增该配置 流媒体服务器的ip
      config: {
        num: opt.num,
        maxNum: opt.maxNum,
        showControl: opt.showControl, // 默认是否显示工具栏
        useNginxProxy: this.useNginxProxy // V1.2.7 版本新增该配置 直连时需要改为 false, 【代理模式可不传】
      },
      receiveMessageFromWSPlayer: opt.receiveMessageFromWSPlayer || this.__receiveMessageFromWSPlayer.bind(this),
      getRecords: API.getRecords, // 获取录像列表接口
      getRecordRtspByTime: API.getRecordRtspByTime, // 根据时间形式获取录像rtsp接口
      getRecordRtspByFile: API.getRecordRtspByFile, // 根据文件形式获取录像rtsp接口
    })
    this.player = this.recordPlayer;
  }

  /**
   * 播放实时预览视频
   * @param opt.channelList: {Array<Object>} 必填，通道列表
   * @param opt.streamType: {Number|String} 选填，码流类型，不填默认播放辅码流1，若不存在辅码流1，则自动切换到主码流  1-主码流 2-辅码流1 3-辅码流2
   * @param opt.windowIndex: {Number} 选填，指定从哪个窗口开始播放。不填默认从选中的窗口开始播放
   */
  // channelList: [{
  //     id: channelCode, // {String} 通道编码 -- 用于预览，必填
  //     isOnline: true, // {Boolean} 是否在线，非在线无法播放 -- 用于预览，必填
  //     deviceCode: deviceCode, // {String} 设备编码 -- 用于对讲，对讲必填，无对讲功能可不填
  //     deviceType: deviceType, // {String} 设备类型 -- 用于对讲，对讲必填，无对讲功能可不填
  //     channelSeq: channelSeq, // {String|Number} 通道序号 -- 用于对讲，对讲必填，无对讲功能可不填
  //     cameraType: cameraType, // {String|Number} 摄像头类型 -- 用于云台，云台必填，无云台功能可不填
  //     capability: capability, // {String} 能力集 -- 用于云台，选填
  // }]
  playRealVideo(opt) {
    this.realPlayer && this.realPlayer.playRealVideo(
      opt.channelList,
      opt.streamType,
      opt.windowIndex
    )
  }

  /**
   * 播放录像回放
   * @param opt.channelList {Array<Object>} 通道集合 必填
   * @param opt.startTime {String|Number} 开始时间  必选  timestamp到秒
   * @param opt.endTime {String|Number} 结束时间  必选  timestamp到秒
   * @param opt.recordSource {String|Number} 录像来源  必选 2表示设备录像  3表示中心录像
   * @param opt.streamType {String|Number} 码流类型 可选
   * @param opt.recordType {String|Number} 录像类型 可选
   */
  // channelList: [{
  //     id: channelCode, // {String} 通道编码 -- 用于回放，必填
  // }]
  playRecordVideo(opt) {
    this.recordPlayer && this.recordPlayer.getRecordList(opt)
  }
  // /**
  //  * 播放录像回放
  //  * @param {Array} channelList  通道集合 必填
  //  * @param {String} item.startTime  开始时间  必选  传入字符串
  //  * @param {String} item.endTime  结束时间  必选  传入字符串
  //  * @param {String|Number} item.recordSource  录像来源  必选 2表示设备录像  3表示中心录像
  //  * @param {String|Number} item.streamType  码流类型 可选
  //  * @param {String|Number} item.recordType  录像类型 可选
  //  */
  // // channelList: [{
  // //     id: channelCode, // {String} 通道编码 -- 用于回放，必填
  // // }]
  // playRecordVideo(channelList) {
  //     // 将开始时间和结束时间转成时间戳（到秒）
  //     console.log(channelList, "channelLsit")
  //     this.recordPlayer && this.recordPlayer.getRecordList(channelList.map(item => {
  //         item.startTime = dayjs(item.startTime, "YYYY-MM-DD HH:mm:ss").format("X")
  //         item.endTime = dayjs(item.endTime, "YYYY-MM-DD HH:mm:ss").format("X")
  //         return item
  //     }))
  // }

  // playRecordVideo(data) {
  //     data.startTime = dayjs(data.startTime, "YYYY-MM-DD HH:mm:ss").format("X")
  //     data.endTime = dayjs(data.endTime, "YYYY-MM-DD HH:mm:ss").format("X")
  //     this.recordPlayer && this.recordPlayer.getRecordList(data)
  // }

  /**
   * 录像暂停
   * 只有正在播放的录像调用才有效
   */
  pause() {
    this.recordPlayer && this.recordPlayer.pause();
  }

  /**
   * 录像暂停后播放
   * 只有暂停后的录像调用才有效
   */
  play() {
    this.recordPlayer && this.recordPlayer.play();
  }

  /**
   * 倍速播放
   * @param {number} speed 速率 0.125 0.25 0.5 1 1.25 1.5 2 4 8 共7种速率
   */
  playSpeed(speed) {
    this.recordPlayer && this.recordPlayer.playSpeed(speed);
  }

  /**
   * 关闭播放器
   * @param {number} index 可选，关闭指定索引的窗口的播放器，不传则表示关闭所有播放器
   */
  close(index) {
    this.player && this.player.close(index);
  }

  /**
   * 设置全屏
   */
  setFullScreen() {
    this.player.setFullScreen();
  }

  /**
   * 设置窗口自适应还是拉伸
   * @param {string} playerAdapter selfAdaption 自适应 | stretching 拉伸
   */
  setPlayerAdapter(playerAdapter) {
    this.player.setPlayerAdapter(playerAdapter);
  }

  /**
   * 控制视频播放器显示的路数: 1 4 9 16 25，不会超过最大显示路数
   * @param {number} number
   */
  setPlayerNum(number) {
    this.player.setPlayerNum(number);
  }

  /**
   * 设置选中的播放器的索引
   * @param {number} index
   */
  setSelectIndex(index) {
    this.player.setSelectIndex(index);
  }

  /**
   * 录像跳转播放
   * @param {string} time HH:mm:ss格式
   */
  jumpPlayByTime(time) {
    this.player.jumpPlayByTime(time);
  }

  // ----------------- 播放器事件 ------------------------
  __receiveMessageFromWSPlayer(method, data) {
    switch(method) {
      // ------------- 公共事件 ---------------------
      case "initializationCompleted":
        // 初始化完成，可调用播放方法（适用于动态加载解码库）
        break;
      case "realSuccess": // 实时预览成功
        console.log("实时预览成功")
        break;
      case "realError": // 实时预览失败
        console.log("实时预览失败", err)
        break;
      case "talkError": // 对讲失败
        console.log("对讲失败");
        break;
      case "recordSuccess": // 录像回放成功
        console.log("录像回放成功");
        break;
      case "recordSuccess": // 录像回放失败
        console.log("录像回放失败", err);
        break;
      case "selectWindowChanged": // 选中的窗口发生改变
        this.currentChannelId = data.channelId;
        this.playIndex = data.playIndex;
        break;
      case "windowNumChanged": // 播放器显示的路数发生改变
        this.playNum = data;
        break;
      case "closeVideo": // 视频关闭
        // 点击关闭按钮引发的视频关闭进行提示
        // 切换视频引发的视频关闭不进行提示
        if(!data.changeVideoFlag) {
          console.log(`窗口${data.selectIndex}的视频已关闭`)
        }
        break;
      case "statusChanged": // 视频状态发生改变
        break;
      case "errorInfo": // 错误信息提示
        console.log(data, "可打印查看错误消息");
        // data = {
        //     errorCode: xxx,
        //     errorMsg: "",
        //     errorData: {
        //         channelList: [],
        //         apiErrorInfo: {},
        //         method: "",
        //         arguments: [],
        //     },
        // }
        console.error(data)
        break;
    }
  }
}

export default PlayerManager
