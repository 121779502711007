/** 租户信息操作栏 */
import { getDictData, getstatusType} from '@/api'
import util from '@/util/util'

/** 租户信息表头栏 */
export const occupantInfoColumn = [
  { label: '租户名称', prop: 'tenantName', width: '150px',slotName:"tenantName",name:"tenantName"  },
  { label: '编号', prop: 'tenantCode', width: '150px' },
  { label: '租户类型', prop: 'categoryLabel', width: '150px'  },
  { label: '租户属性', prop: 'attributeLabel', width: '80px'  },
  { label: '租户负责人', width: '110px', prop: 'linkMan' },
  // { label: '纳税人识别号', prop: 'unifiedSocialCreditCode', width: '160px' },
  { label: '身份证号', prop: 'idCard', width: '160px' },
  { label: '绑定能源设备', prop: 'energyDevices'},
  { label: '联系方式', prop: 'contactNumber', width: '160px'  },
  {
    label: '操作',
    input_type: 'button',
    width: 220,
    list: [
      { text: '编辑', method: 'rowEdit',limit:'resident::manage::update' },
      { text: '删除', method: 'delTenant',limit:'resident::manage::del' },
    ]
  }
]

/** 新增/修改租户信息 */
export const editOccupantData = [
  {
    input_type: 'input',
    label: '租户名称',
    key: 'tenantName',
    
    col: 22
  },
  {
    input_type: 'select',
    label: '租户类型',
    key: 'category',
    options: function () {
      return getDictData('tenant_category').then((res) => {
        if (res.code == 200) {
          let data = res.data.map((item) => {
            return {
              label: item.dictLabel,
              value: item.dictValue
            }
          })
          return data
        }
      })
    } 
  },
  {
    input_type: 'select',
    label: '租户属性',
    key: 'attribute',
    options: function () {
      return getDictData('tenant_attribute').then((res) => {
        if (res.code == 200) {
          let data = res.data.map((item) => {
            return {
              label: item.dictLabel,
              value: item.dictValue
            }
          })
          return data
        }
      })
    } 
  },
  // {
  //   input_type: 'input',
  //   label: '纳税人识别号',
  //   key: 'unifiedSocialCreditCode',
    
  //   isInit:true,
  //   col: 22,
  //   handleSelectContent: function (parentData, key, val, currData, nowFilterData,) {
  //     // if (key == 'attribute') {
  //       // console.log("纳税人识别号----", nowFilterData.attribute)
  //       if (nowFilterData.attribute=="company"){
  //         this.relationDisplay = false
  //       }else{
  //         this.relationDisplay = true
  //       }
  //     // }
  //   }
  // },
  {
    input_type: 'input',
    label: '身份证号码',
    key: 'idCard',
    isInit: true,
    
    col: 22,
    handleSelectContent: function (parentData, key, val, currData, nowFilterData,) {
      // if (nowFilterData.attribute == 'attribute') {
        if (nowFilterData.attribute == "person") {
          this.relationDisplay = false
        } else {
          this.relationDisplay = true
        }
      // }
    }
  },
  {
    input_type: 'input',
    label: '负责人',
    key: 'linkMan',
    
    col: 22
  },
  {
    input_type: 'input',
    label: '联系方式',
    key: 'contactNumber',
    
    col: 22
  },
  {
    input_type: 'input',
    label: '备注',
    key: 'remark',
    type: 'textarea',
    col: 22
  }
]

/** 新增/修改租户信息校验规则 */
export const editOccupantRules = {
  tenantName: [
    { required: true, message: '请输入租户名称', trigger: 'blur' }
  ],
  category: [
    { required: true, message: '请选择租户类型', trigger: 'change' }
  ],
  attribute: [
    { required: true, message: '请选择租户属性', trigger: 'change' }
  ],
  // unifiedSocialCreditCode: [
  //   { required: true, message: '请输入纳税人识别号', trigger: 'blur' }
  // ],
  idCard: [
    { required: true, message: '请输入身份证号码', trigger: 'blur' }
  ],
  contactNumber: [
    { required: true, message: '请输入联系方式', trigger: 'blur',
    validator: util.formRules.checkPhone(),
    phoneType:3
     }
  ]
}
