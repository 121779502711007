<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
      :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset"
      @rowDelete="rowDelete" @append="normalEvent({ ref: 'addEdit', method: 'append' })" @rowEdit="normalEvent"
      @importForm="
        normalEvent({
          ref: 'importForm',
          method: 'importForm',
          url: '/files/出租率导入模版.xlsx',
          filename: '出租率导入模版',
          uploadURL: '/sysRentalRate/upload'
        })">
    </ja-table>
    <import-form ref="importForm" @openResult="openResult" />
    <check-result ref="checkResult" :results="results" />
    <add-eidt ref="addEdit" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEidt from './childComp/AddEdit.vue'
import importForm from './common/importForm.vue'
import checkResult from './common/checkResult.vue'

import {
  sysRentalRatePage,
  sysRentalRateDelete
} from '@/api'

export default {
  title: '出租率管理',
  mixins: [table],
  name: 'index',
  components: {
    AddEidt,
    importForm,
    checkResult
  },
  data() {
    return {
      conditionData: [
        {
          input_type: 'date', 
          type: 'month',
          value: 'yearAndMonth',
          pickerOptions:{disabledDate:this.disabledDate},
          valueFormat:'yyyy-MM',
          attribute: { placeholder: '月份', size: 'small' }
        },
        { normalBtn: 'search' },
        { normalBtn: 'reset' },
        { normalBtn: 'add', limit: 'lettingRate::operate::add' },
        { normalBtn: 'import', text: '导入' }
      ],
      columns: [
        { label: '月份', prop: 'yearAndMonth' },
        { label: '出租率(%)', prop: 'rentalRate' },
        {
          label: '操作',
          input_type: 'button',
          width: 140,
          attribute: {
            fixed: 'right',
            showOverflowTooltip: false,
            align: 'center'
          },
          list: [
            { text: '编辑', method: 'rowEdit', limit: 'lettingRate::operate::edit' },
            { text: '删除', method: 'rowDelete', limit: 'lettingRate::operate::delete' }
          ]
        }
      ],
      tableData: [],
      filterData: {
        pageIndex: 1,
        pageSize: 10,
        yearAndMonth:''
      },
      results:{}
    }
  },
  methods: {
    getInit() {
      this.loading = true
      sysRentalRatePage(this.filterData).then((res) => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确定删除？', '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sysRentalRateDelete(row.id).then((res) => {
          if (res.code == 200) {
            this.getInit()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    openResult({ data, uploadFlag }) {
      this.results = { data, uploadFlag }
      this.$nextTick(() => {
        this.$refs.checkResult.rowDetail()
      })
    },
    disabledDate(time) {
        // 定义禁止选择的时间范围
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth());
        const targetDate = new Date(time.getFullYear(), time.getMonth());
        return targetDate > today; // 禁用今天之前的日期
    }
  }
}
</script>
