<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowDetail="normalEvent">
    </ja-table>

  </div>
</template>
<script>
import table from '@/views/mixins/table'
import { getAccessInfo } from '@/api'
export default {
  title: "通行记录",
  components: {
  },
  mixins: [table],
  name: 'index',
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', key: 'time', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' }
      ],
      columns: [
        { label: '时间', prop: 'swingTime' },
        { label: '姓名', prop: 'personName' },
        { label: '卡号', prop: 'cardNumber' },
        { label: '通道名称', prop: 'channelName' },
        { label: '部门名称', prop: 'deptName' },
        { label: '设备名称', prop: 'deviceName' },
        { label: '开门结果', prop: 'openResult' },
        { label: '开门失败原因', prop: 'remark' }
      ],
      tableData: []
    }
  },
  mounted(){
  },
  methods: {
    rqParmas2() {
      let obj = this.rqParmas()
      if (obj.time) {
        obj.startTime = obj.time[0]
        obj.endTime = obj.time[1]
        delete obj.time
      }
      return obj
    },
    getInit() {
      this.loading = true
      let obj = this.rqParmas2()
      getAccessInfo(obj).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .cellImg {
  display: flex;
  margin: auto;
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
::v-deep .errorImg {
  width: 100%;
}
</style>
