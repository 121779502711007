<template>
  <!--  普通的表单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="(isAdd?'新增':'编辑')+' '">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll">
    </ja-form>
  </el-dialog>
</template>
<script>
import { addPoint, updatePoint ,getFloorList} from '@/api'

export default {
  inject: ['labelWidth', 'getInit'],
  data() {
    return {
      rowFormData: [
        { input_type: 'input', label: '巡更点名称', key: 'pointName', col: 11, },
        { input_type: 'text2', label: '巡更点编号', key: 'pointCode', col: 11,},
        {
          label: '所属楼栋', input_type: 'select', key: 'buildingId',
          relevance: { sonKey: 'floorId', This: this, type: 'select', otherSonKey: ['floorId'], rqParam: { rqUrl: "/floor/query", rqData: () => { return { pageIndex: 1, pageSize: 200, buildingId: this.rowForm.buildingId } }, rqMethod: 'post', labelName: 'floorName', valueName: 'id' }, }
        },
        {
          label: '所属楼层', input_type: 'select', key: 'floorId', options: [],
        },
        { input_type: 'input', label: '巡更点位置', key: 'position', col: 22, },
        { input_type: 'input', label: '点位描述', key: 'remark', col: 22, },
      ],
      rowForm: {},
      rules: {
        pointName: [{ required: true, message: '请完善巡更点名称', trigger: 'blur' }],
        buildingId: [{ required: true, message: '请选择所属楼栋', trigger: 'change' }],
        floorId: [{ required: true, message: '请选择所属楼层', trigger: 'change' }],
        position: [{ required: true, message: '请完善巡更点位置', trigger: 'blur' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {}
    }
  },
  async created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
  },

  mounted(){
    // 表单里的
     const rowParamObj = { pageIndex: 1, pageSize: 200 }
     this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id' }) // 楼栋选择
  },

  methods: {
    async append() {
      console.log("add ");
      this.isAdd = true
      this.rowForm = {}
      this.showEditAll = true
    },
    rowEdit(row) {
      console.log('edit---', row)
      this.changeBuild(null,row.buildingId)
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      this.showEditAll = true
    },
    // 楼栋
    async changeBuild(val,buildingId) {
      this.rowForm.floorId = ""
      console.log(val,buildingId);
      let {data} =  await getFloorList({ pageIndex: 1, pageSize: 200,buildingId })
      this.rowFormData[3].options = data?.data?.map(({id,floorName})=>{return {label:floorName,value: id}})
    },
    saveForm() {
      // console.log('要提交的表单', this.rowForm)
      this.loadingForm = true
      let rqMethod = this.isAdd ? addPoint : updatePoint
      rqMethod(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },


  }
}
</script>