<template>
    <div class="allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
            :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset"
            @rowDetail="normalEvent">
            <template #typeText="{ row }">
                <span type="text">{{ typeFilter(row.openType) }}</span>
            </template>
            <template #typeOpen="{ row }">
                <span type="text" :class="{red:row.openResult === '0',green:row.openResult === '1'}">{{ row.openResult === '0' ? '失败' : '成功' }}</span>
            </template>
        </ja-table>
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import { getAccessControlRecord, getBuildingAll, reqDictData } from '@/api'
export default {
    title: "通行记录",
    name: 'trafficRecord',
    mixins: [table],
    data() {
        return {
            conditionData: [
                { input_type: 'select', key: 'buildingId', attribute: { placeholder: '所属楼栋', size: 'small' }, options: [] },
                { input_type: 'input', key: 'personName', attribute: { placeholder: '人员姓名', size: 'small' } },
                { input_type: 'input', key: 'deptName', attribute: { placeholder: '所在单位', size: 'small' } },
                { input_type: 'input', key: 'generalDevice', attribute: { placeholder: '通行设备', size: 'small' } },
                {
                    input_type: 'select', key: 'subcategory', attribute: { placeholder: '设备小类', size: 'small' }, options: [
                        { label: '通行闸', value: '126' },
                        { label: '智能门禁', value: '127' },
                        { label: '智能梯控', value: '198' }
                    ]
                },
                { input_type: 'select', key: 'openType', attribute: { placeholder: '通行方式', size: 'small' }, options: [
                    ] },
                {
                    input_type: 'select', key: 'openResult', attribute: { placeholder: '状态', size: 'small' }, options: [
                        { label: '全部', value: '' },
                        { label: '成功', value: '1' },
                        { label: '失败', value: '0' }
                    ]
                },
                { input_type: 'date', key: 'time', value: 'time', rangeSeparator: '-', type: 'daterange', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd',  },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', exportObj: { rqParmas: this.rqexport, rqUrl: '/access/getAccessControlRecordExport', rqMethod: 'post' } }
            ],
            columns: [
                { label: '人员姓名', prop: 'personName' },
                { label: '所在单位', prop: 'deptName' },
                { label: '通行设备', prop: 'generalDevice' },
                { label: '设备小类', prop: 'subcategoryName' },
                { label: '通行时间', prop: 'swingTime' },
                { slotName: 'typeText', label: '通行方式', prop: 'openType' },
                { slotName: 'typeOpen', label: '运行状态', prop: 'openResult' },
            ],
            tableData: [],//列表数据
            DictList: [],//字典数据
            filterData:{
                time: [this.StartDate(), this.EndDate()]
            }
        }
    },
    mounted() {
        this.initData()
    },
    computed: {
        typeFilter() {
            return (key) => {
                let value = ''
                this.DictList.some(item => {
                    if (item.dictValue === key) {
                        value = item.dictLabel
                        return true
                    }
                })
                return value
            }
        },
    },
    methods: {
        EndDate() {
            const today = new Date();
            return `${today.getFullYear()}-${this.formatNumber(today.getMonth() + 1)}-${this.formatNumber(today.getDate())}`;
        },
        StartDate() {
            const sevenDaysAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
            return `${sevenDaysAgo.getFullYear()}-${this.formatNumber(sevenDaysAgo.getMonth() + 1)}-${this.formatNumber(sevenDaysAgo.getDate())}`;
        },
        rqexport() {
            let obj = { ...this.filterData }
            obj.pageIndex = this.total <= 5000 ? 1 : this.filterData.pageIndex
            obj.pageSize = 5000
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        async initData() {
            //获取楼栋下拉数据
            const { data } = await getBuildingAll()
            this.conditionData[0].options = data.map(item => {
                return {
                    label: item.buildingName,
                    value: item.buildingId
                }
            })
            const { data: list } = await reqDictData('/dahua_open_type')
            this.DictList = list
            this.conditionData[5].options = list.map(item => {
                return {
                    label: item.dictLabel,
                    value: item.dictValue
                }
            })
        },
        rqParmas2() {
            let obj = this.rqParmas()
            if (obj.time) {
                obj.startTime = obj.time[0]
                obj.endTime = obj.time[1]
                delete obj.time
            }
            return obj
        },
        async getInit() {
            this.loading = true
            let obj = this.rqParmas2()
            const { data } = await getAccessControlRecord(obj)
            this.tableData = data.data
            this.total = data.total
            this.loading = false
        },
        formatNumber(num) {
            return num < 10 ? `0${num}` : num;
        },
    }
}
</script>
  
<style lang="scss" scoped>
::v-deep .cellImg {
    display: flex;
    margin: auto;

    >img {
        max-height: 23px;
        width: 35px;
        display: flex;
        margin: auto;
    }
}

.red{
    color: #d50e21;
}
.green{
    color: #99f23b;
}

::v-deep .errorImg {
    width: 100%;
}
</style>
  