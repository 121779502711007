<template>
  <div class="box_bg">
    <div class="boxleft">
      <div class="header">
        {{ title }}
      </div>
    </div>
    <div class="mid">
      <div class="midtop">
        <div>
          CO₂浓度:<p :class="envSensors.co2>=700? 'red':''">{{envSensors.co2 == 0?'':envSensors.co2}}</p>
          <span>ppm</span>
        </div>
        <div>
          环境湿度:<p :class="(envSensors.envHumidity<=35 || envSensors.envHumidity>=60) ? 'red':''">{{envSensors.envHumidity == 0?'':envSensors.envHumidity}}</p>
          <span>%</span>
        </div>
        <div>
          PM10浓度:<p>{{envSensors.pm10== 0?'':envSensors.pm10}}</p>
          <span>ug/m³</span>
        </div>
      </div>
      <div class="midbottom">
        <div>
          PM2.5浓度:<p :class="envSensors.fineParticulateMatter>=755? 'red':''">{{envSensors.fineParticulateMatter== 0?'':envSensors.fineParticulateMatter}}</p>
          <span>ug/m³</span>
        </div>
        <div>
          TVOC浓度:<p :class="envSensors.fineParticulateMatter>=0.6? 'red':''">{{envSensors.tcov== 0?'':envSensors.tcov}}</p>
          <span>ug/m³</span>
        </div>
        <div>
          环境温度:<p :class="{'red':temperatureClass}">{{envSensors.envTemperature== 0?'':envSensors.envTemperature}}</p>
          <span>°C</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    envSensors:{
      type:Object,
      default:()=>{}
    }
  },
  title: '环境测量',
  data() {
    return {
      title: '环境测量',
      status:''//'模式设定0无效；1制冷；2抽湿；3送风；4制热；5自动',
    }
  },
  computed:{
    temperatureClass(){
     if(this.envSensors.status == 1){
      return this.envSensors.envTemperature >= 27 ? true : false
     }else if(this.envSensors.status == 4){
      return this.envSensors.envTemperature <= 20 ? true : false
     }else{
      return false
     }
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';
.box_bg {
  background-image: url('~@/assets/gd/ds_card2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 50px 10px;
  padding: 0 10px;
  height: 180px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.boxleft {
  width: 100%;
  .header {
    margin-top: 10px;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #fff;
  }
}
.mid {
  width: 100%;
  display: flex;
  font-size: 14px;
  line-height: 14px;
  font-family: MicrosoftYaHei;
  color: #37eaff;
  letter-spacing: 1px;
  text-shadow: 3px 5px 2px rgba(5, 19, 35, 0.3);
  .midtop,.midbottom {
    width: 50%;
    margin-left: 10px;
    > div {
      display: flex;
      margin-top: 12px;
      p {
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #ffce14;
        text-shadow: none;
      }
      span {
        margin-left: 5px;
        font-family: MicrosoftYaHei;
        color: #8aeeff;
        letter-spacing: 1px;
        text-shadow: 3px 5px 2px rgba(5, 19, 35, 0.3);
      }
    }
  }
}

.midbox {
  text-align: center;
  color: #02ffac;
  font-size: 36px;
}

.red{
  color: red!important;
}
</style>
