<template>
    <div>
        <el-dialog v-dialogDrag
                   :close-on-click-modal="false"
                   width="50%"
                   :title="title"
                   :visible.sync="show"
                   v-if="show">
            <ja-form :rules="rules"
                     v-model="rowForm"
                     :formArray="detailOptions"
                     :allDisabled="allDisabled"
                     @save="saveForm"
                     @reset="show = false"
                     v-if="show">
            </ja-form>
            </ja-detail>
        </el-dialog>
    </div>
</template>
<script>
import {editDecInspectItemsInfo,addDecInspectItemsInfo} from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            //表头名称
            detailOptions: [
                { input_type: 'select', label: '巡查项类型', key: 'patrolType', labelWidth: '120px', col: 24, options: [] },
                { input_type: 'input', type: 'textarea', label: '巡查内容', key: 'remark', labelWidth: '120px', col: 24, maxlength: '200' },
            ],
            rules: {
                remark: [{ required: true, message: '请输入维保内容', trigger: 'blur' }],
                patrolType: [{ required: true, message: '请选择维保项类型', trigger: 'blur' }],
            },
            title: null,
            rowForm: {},
            show: false,
            allDisabled: false,
            isAdd: true
        }
    },
    methods: {
        init(state, row) {
            let index = this.detailOptions.findIndex((e) => e.key === 'patrolType')
            this.util.rqOptionsList({
                who: [this.detailOptions[index]],
                rqData: { pageIndex: 1, pageSize: 10, dictType: 'patrol_type' },
                rqUrl: '/sys/dictData/query',
                rqMethod: 'post',
                labelName: 'dictLabel',
                valueName: 'dictValue',
            })
            this.title = '新增巡查项'
            this.isAdd = true
            this.allDisabled = false
            this.rowForm = {}
            this.show = true
            if (state === 1) {
                this.isAdd = false
                this.rowForm = { ...row }
                this.title = '编辑巡查项'
            }

        },
        saveForm() {
            if (this.isAdd) {
                addDecInspectItemsInfo(this.rowForm).then(() => {
                    this.getInit()
                    this.show = false
                    this.$message.success('新增成功')
                })
            } else {
                editDecInspectItemsInfo(this.rowForm).then(() => {
                    this.getInit()
                    this.show = false
                    this.$message.success('编辑成功')
                })
            }
        }
    }
}
</script>
