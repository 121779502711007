<template>
    <div>
        <el-dialog :title="isAdd?'新增组':'巡更组编辑'" :visible.sync="show" width="40%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgb(36 33 33 / 90%)">
            <ja-form v-if="show" :rules="EditRules" v-model="rowFormData" :formArray="EditrowForm" @selectRoom="selectRoom" @reset="close" @save="saveForm" @deleteEquipment="deleteEquipment" labelWidth="120px">
            </ja-form>
        </el-dialog>
        <!-- 选择房间 -->
        <el-dialog v-dialogDrag :close-on-click-modal="false" width="50%" title="新增设备" :visible.sync="isSelectRoom" v-if="isSelectRoom">
            <select-room @selectRow="selectRow2" :selectedPersons="[...selectedRooms]" v-if="isSelectRoom" :isOnePerson="false" />
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
import selectRoom from './selectEquipment/selectEquipment.vue'
import { addPatrolTeam, editPatrolTeam } from '@/api'
export default {
    name: '新增修改组',
    inject: ['getInit',],
    components: {
        JaForm,
        selectRoom,
    },
    props: {
        maxOrder: {
            type: Number,
            default: 1
        }
    },
    data () {
        return {
            AddrowForm: [
                {
                    input_type: 'input',
                    col: 13,
                    label: '组名',
                    key: 'groupName',
                    width: '50px'
                },
                {
                    input_type: 'number',
                    col: 24,
                    label: '顺序',
                    key: 'order',
                    width: '50px'
                },
            ],
            EditrowForm: [
                {
                    input_type: 'input',
                    col: 13,
                    label: '组名',
                    key: 'groupName',
                    width: '50px'
                },
                {
                    input_type: 'table', col: '20', label: '设备列表', noSearch: true, showPage: false, key: 'deviceOrderList', isRequired: true, border: true, width: '150px',
                    tableAttribute: { showSummary: true, showIndex: false },
                    columns: [
                        { label: '设备名称', prop: 'deviceName' },
                        {
                            label: '操作', input_type: 'button', list: [
                                { text: '删除', method: 'deleteEquipment', },
                            ]
                        }
                    ]
                },
                { input_type: 'button', text: '新增设备', readonly: true, col: 2, colMarginLeft: '2%', method: 'selectRoom', },
            ],
            rowFormData: {},
            AddRules: {
                groupName: [
                    { required: true, message: '组名不能为空', trigger: 'blur' }
                ],
            },
            EditRules: {
                groupName: [
                    { required: true, message: '组名不能为空', trigger: 'blur' }
                ],
                deviceOrderList: [
                    { required: true, message: '设备不能为空', trigger: 'blur' }
                ],
            },
            isAdd: true,
            show: false,
            loading: false,
            isSelectRoom: false,
            // 房间弹窗
            selectedRooms: [],
        }
    },
    methods: {
        init (isAdd, row) {
            this.show = true
            this.isAdd = isAdd
            this.loading = false
            if (this.isAdd) {
                this.selectedRooms = []
                this.rowFormData = {
                    // order: this.maxOrder
                }
                this.AddrowForm[1].max = this.maxOrder
                this.AddrowForm[1].min = 1
            } else {
                this.selectedRooms = []
                this.EditrowForm[1].max = this.maxOrder - 1
                this.EditrowForm[1].min = 1
                //重构数据
                if (row) {
                    let obj = { groupName: row.groupName, id: row.id, deviceOrderList: row.deviceOrderList ? row.deviceOrderList : [] } //order: row.orderNum,
                    this.rowFormData = obj
                    this.selectedRooms = row.deviceOrderList ? [...row.deviceOrderList] : []
                }
            }
        },
        //取消
        close () {
            this.show = false
            this.rowFormData = {}
        },
        //保存新增/编辑
        saveForm () {
            if (this.isAdd) {
                //新增
                let obj = this.rowFormData
                let arrList = []
                obj.deviceOrderList.map(e => {
                    arrList.push(e.id ? e.id : e.deviceId)
                })
                obj.deviceId = arrList
                addPatrolTeam(this.rowFormData).then(res => {
                    if (res.code !== 200) return this.$message.error('新增组失败')
                    this.show = false
                    this.$message.success("新增组成功")
                    this.getInit()
                })
            } else {
                let obj = this.rowFormData
                let arrList = []
                obj.deviceOrderList.map(e => {
                    arrList.push(e.id ? e.id : e.deviceId)
                })
                obj.deviceIds = arrList
                //编辑
                editPatrolTeam(obj).then(res => {
                    this.show = false
                    this.$message.success("修改组成功")
                    this.getInit()
                })
            }
        },
        //打开设备列表
        selectRoom () {
            this.isSelectRoom = true
        },
        // 选择设备
        selectRow2 ({ list }) {
            this.isSelectRoom = false
            this.selectedRooms = [...list]
            this.$set(this.rowFormData, 'deviceOrderList', list)
        },
        //删除设备
        deleteEquipment (item) {
            this.$confirm('此操作会删除绑定, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let rows = [...this.rowFormData.deviceOrderList]
                let length = rows.findIndex(e => e.deviceId === item.deviceId)
                if (length >= 0) {
                    rows.splice(length, 1)
                    this.$set(this.rowFormData, 'deviceOrderList', rows)
                    this.selectedRooms = rows
                }
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
    }
}
</script>