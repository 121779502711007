<template>
  <div class="page">
    
    <!-- <ul class="btn_list">
        <span>子系统快捷入口：</span>
      <li v-for="(item,i) in btnList" :key="i">
        <el-button size="mini">
          <a :href="item.href" target="_blank" rel="noreferrer">{{item.name}}</a>
        </el-button>
        
      </li>
    </ul> -->
    <Header />
    <!-- 待办/消息相关、设备类型统计 -->
   
    <div class="middle">
      <parking-statistics class="box" />
     
      <device-echart class="box" />
    </div>
    <!-- 月度能耗 -->
    <energy class="energy" style="margin-bottom: 20px" />
    <!-- 车位统计、告警等级、告警类型-->
    <!-- <div class="middle2">
      <list-box class="box" />
      <alarm-level class="box mid" />
      <alarm-type class="box" />
    </div> -->

  </div>
</template>

<script>
import Header from './childComp/Header.vue'
import ListBox from './childComp/ListBox.vue'
import DeviceEchart from './childComp/DeviceEchart.vue'
import ParkingStatistics from './childComp/ParkingStatistics.vue'
import AlarmLevel from './childComp/AlarmLevel.vue'
import AlarmType from './childComp/AlarmType.vue'
import Energy from './childComp/Energy.vue'

export default {
  title: "驾驶舱",
  data () {
    return {
      btnList:[
        {
          name:"电表",
          href:"http://www.zonvinfo.com/default"
        },
        {
          name:"水表",
          href:"https://www.100123100.com/"
        },
        {
          name:"监控",
          href:"https://120.236.119.130:50443/portal/cas/loginPage?service=https://120.236.119.130:50443/portal/cas/loginPage"
        },
        {
          name:"停车场",
          href:"https://yun.jslife.com.cn/jportal/index.html#/login"
        }
      ]
    }
  },
  components: {
    Header, ListBox, DeviceEchart, ParkingStatistics, AlarmLevel, AlarmType, Energy
  }
}
</script>

<style lang="scss" scoped>
.boxCommon {
  margin: 15px 0 15px 0;
  border-radius: 20px;
  background: white;
}
.page {
  margin: 15px;
  .btn_list{
    display: flex;
    background: #fff;
    border-radius:10px;
    padding: 10px;
    align-items: center;
    margin: 15px 0;
    li{
      margin-right: 20px;
      a{
        text-decoration: none;
          color: #333; 
      }
    }
  }
  .middle {
    display: flex;
    justify-content: space-between;
    .box {
      width: calc((100% - 20px) / 2);
      @extend .boxCommon;
      height: 480px;
    }
  }
  .middle2 {
    display: flex;
    justify-content: space-between;
    .box {
      flex: 1;
      @extend .boxCommon;
      height: 400px;
      margin-top: 0;
    }
    .mid {
      margin: 0 20px;
    }
  }
}

</style>
