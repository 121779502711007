<template>
  <!--  结单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="title+'工单派单范围'">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
  </el-dialog>
</template>
<script>
import defferObj from '../defferPage'
import { assignRangeWorkConfig } from '@/api'
export default {
  inject: ['getInit',],
  props: {
    activePage: String,
  },
  data() {
    return {
      labelWidth: `${this.util.setFontSize(180)}px`,
      defferObj,
      rowFormData: [
        { input_type: 'select', label: '接单人设置(多选)', key: 'deptIds', col: 20, multiple: true, options: [] },
      ],
      rowForm: {},
      rules: {
        deptIds: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      title: '',
    }
  },
  created() {
    this.util.rqOptionsList({ who: this.rowFormData[0], rqUrl: "/department/all", resRule: 'data', labelName: 'name', valueName: 'deptId' })
  },
  methods: {
    dealDiffer() {
      let type = this.$parent.activePage
      this.title = this.defferObj[type].typeText
    },
    // 新增
    append() {
      this.dealDiffer()
      this.$set(this.rowForm, 'deptIds', this.$parent.deptIds)
      this.showEditAll = true
      // console.log('新增')
    },
    saveForm() {
      console.log('要提交的表单', this.rowForm,)
      this.loadingForm = true
      const rqMethod = assignRangeWorkConfig
      rqMethod({ deptIds: this.rowForm.deptIds.join(','), classify: this.$parent.activePageValue }).then(res => {
        this.loadingForm = false
        this.$message.success('提交成功')
        this.showEditAll = false
        this.$parent.resetDeptIds()
      }).catch(error => {
        this.loadingForm = false
      })
    },
  }
}
</script>