<template>
  <div>
    <JaTable
      :conditionData="conditionData"
      :columns="occupantInfoColumn"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      v-model="filterData"
      @getInit="getInit"
      @search="search"
      :showIndex="true"
      @reset="reset" 
      @turnMeterReading="turnMeterReading"
      :editDataFormat="editDataFormat"
    >
      <template #tenantName="{row}">
        <div
          class="firstcell el-button--text"
          @click="seeDetails(row)"
        >{{row.tenantName}}</div>
      </template>
    </JaTable>
  </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import table from '@/views/mixins/table'
import {
  getDictData,
  rqTenant,
} from '@/api'

export default {
  title: '租户信息',
  mixins: [table],
  name: 'OccupantInfo',
  components: {
    JaTable,
  },
  data() {
    return {
      results: null,
      conditionData:[
  {
    text: '租户名称',
    input_type: 'input',
    key: 'tenantName',
    attribute: {
      placeholder: '租户名称',
      size: 'small'
    }
  },
  {
    text: '编号',
    input_type: 'input',
    key: 'tenantCode',
    attribute: {
      placeholder: '编号',
      size: 'small'
    }
  },
  {
    text: '租户类型',
    input_type: 'select',
    key: 'category',
    attribute: {
      placeholder: '租户类型',
      size: 'small',
      clearable: true
    },
    options: function () {
      return getDictData('tenant_category').then((res) => {
        if (res.code == 200) {
          let data = res.data.map((item) => {
            return {
              label: item.dictLabel,
              value: item.dictValue
            }
          })
          return data
        }
      })
    } 
  },
  { normalBtn: 'search', limit: 'default:view' },
  { normalBtn: 'reset' },
],

      occupantInfoColumn:[
  { label: '租户名称', prop: 'tenantName', width: '150px',slotName:"tenantName",name:"tenantName"  },
  { label: '编号', prop: 'tenantCode', width: '150px' },
  { label: '租户类型', prop: 'categoryLabel' },
  { label: '租户属性', prop: 'attributeLabel' },
  { label: '租户负责人', width: '110px', prop: 'linkMan' },
  // { label: '纳税人识别号', prop: 'unifiedSocialCreditCode', width: '160px' },
  { label: '身份证号', prop: 'idCard', width: '160px' },
  { label: '联系方式', prop: 'contactNumber' },
  // { label: '状态', prop: 'status' },
  {
    label: '操作',
    input_type: 'button',
    width: 220,
    list: [
      { text: '选择', method: 'turnMeterReading'}
    ]
  }
],
      tableData: [],
      total: 0,
      show: false,
      editId: '',
      title: '',
      rowForm: {},
      allDisabled: false,
      filterData: {
        // 默认页码
        pageIndex: 1,
        pageSize: 10
        // cityId:''
      }
    }
  },
  created() {},
  methods: {
    getInit() {
      this.loading = true

      // 查询园区列表信息
      rqTenant(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
          this.tableData = this.tableData.map((item) => {
            const obj = item
            obj.imgList = []
            obj.imgList[0] = item.image
            return obj
          })
        }
      })
    },
    
    editDataFormat(row, btn) {
        if (row.statusNum != 1 && btn.text == '审核') {
            return false
        } else if (row.statusNum != 0 && btn.text == '提交' || row.statusNum != 0  && btn.text == '删除') {
            return false
        } else if (row.statusNum != 2 && btn.text == '终止') { 
            return false
        } else if (row.statusNum == 0 && btn.text == '合同' || row.statusNum == 0  && btn.text == '抄表') {
            return false
        } else if (row.statusNum == 1 && btn.text == '编辑' || row.statusNum == 1 && btn.text == '合同' || row.statusNum == 1 && btn.text == '抄表') {
          return false
        } else {
            return true
        }
    },
    /**
     * 选择
     */
    turnMeterReading(val){
      this.$emit("turnMeterReading",val)
    }
  }
}
</script>

<style scoped>
</style>
