<template>
    <!-- 水抄表 -->
    <div class="allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :showSummary="false"
            :chooseArr="['总计用量', '本期用量（kW·h）']" :columns="columns" :tableData="tableData" :total="total" :loading="loading"
            @getInit="getInit" @search="search" @reset="reset" :showIndex="false" @showDetail="showDetail" @importForm="
                normalEvent({
                    ref: 'importForm',
                    method: 'importForm',
                    url: '/files/冷量表抄表导入.xlsx',
                    filename: '冷量表抄表导入模板',
                    uploadURL: '/energyQuery/upload'
                })
                ">
            <template #typeText="{ row }">
                <span type="text" :class="{ red: row.currentReading <= 0}">{{row.currentReading }}</span>
            </template>
            <template #tableBottomSlot>
                <div class="tableSum">
                    <div>
                        总计用量
                    </div>
                    <div>
                        {{ currentReadingSum.toFixed(2) }}
                    </div>
                </div>
            </template>
        </ja-table>
        <import-form ref="importForm" @openResult="openResult" />
        <check-result ref="checkResult" :results="results" />
    </div>
</template>

<script>
import { coolPage } from '@/api'
import table from '@/views/mixins/table'
import importForm from '../common/importForm.vue'
import checkResult from '../common/checkResult'

export default {
    title: '冷量抄表',
    mixins: [table],
    name: 'index',
    components: {
        importForm,
        checkResult
    },
    data() {
        return {
            filterData: {},
            conditionData: [
                {
                    input_type: 'input',
                    key: 'deviceCode',
                    lable: '设备编码',
                    attribute: {
                        placeholder: '设备编码',
                        size: 'small'
                    }
                },
                {
                    input_type: 'input',
                    key: 'deviceName',
                    lable: '设备名称',
                    attribute: {
                        placeholder: '设备名称',
                        size: 'small'
                    }
                },
                {
                    input_type: 'select', key: 'buildingId', attribute: { placeholder: '所属楼栋', size: 'small' }, options: [],
                },
                {
                    input_type: 'select', key: 'floorId', attribute: { placeholder: '所属楼层', size: 'small' }, options: [],
                },
                { input_type: 'date', key: 'time', value: 'time', rangeSeparator: '-', type: 'monthrange', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM' },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'import', text: '导入', limit: 'coldMeterReading::operate::import' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqexport, rqUrl: '/energyQuery/coolExport', rqMethod: 'post' }, },//limit: 'coldMeterReading::detailedList::export'
            ],
            columns: [
                { label: '设备编码', prop: 'deviceCode' },
                { label: '设备名称', prop: 'deviceName' },
                { label: '设备位置', prop: 'position' },
                { label: '上次表底', prop: 'lastReading' },
                { label: '本次抄表', prop: 'thisReading' },
                { label: '本次抄表时间', prop: 'updateTime' },
                { slotName: 'typeText', label: '本期用量（kW·h）', prop: 'currentReading' },
            ],
            tableData: [],
            loading: false,
            total: 30,
            results: '',
            rowForm: {},
            currentReadingSum: 0
        }
    },
    created() {
        this.conditionIndexObj = this.util.getArrItemIndex({ arr: this.conditionData, });
    },
    mounted() {
        const rowParamObj = { pageIndex: 1, pageSize: 200 }
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id', resRule: 'data.data' }) // 楼栋选择
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.floorId], rqUrl: "/floor/allList", rqData: rowParamObj, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId', resRule: 'data' }) // 楼层选择
    },
    methods: {
        rqexport() {
            let obj = { ...this.filterData }
            obj.pageIndex = this.total <= 2000 ? 1 : this.filterData.pageIndex
            obj.pageSize = 2000
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        rqParmas2() {
            let obj = this.rqParmas()
            if (obj.time) {
                obj.startTime = obj.time[0]
                obj.endTime = obj.time[1]
                delete obj.time
            }
            return obj
        },
        getInit() {
            this.loading = true
            coolPage(this.rqParmas2()).then(({ data }) => {
                this.tableData = data.data
                this.currentReadingSum = 0
                this.tableData.map(item => {
                    item.lastReading = Number(item.lastReading).toFixed(2)
                    item.thisReading = Number(item.thisReading).toFixed(2)
                    item.currentReading = Number(item.currentReading).toFixed(2)
                    this.currentReadingSum += Number(item.currentReading) > 0 ? Number(item.currentReading) : 0
                })
                this.total = data.total
                this.loading = false
            })
        },
        getSummary({ columns, data }) {
            console.log(columns, data, ' columns, data');
        },
        //查看
        showDetail(row) {
            this.$refs.showInformation.init(row)
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-col .el-form-item .el-input__inner {
    height: 40px;
}

/deep/ .el-select {
    width: 100% !important;
}

.red{
    color: red!important;
}

.tableSum {
    padding: 0.625vw 0;
    display: flex;
    justify-content: space-between;
    background: rgb(245, 247, 250);

    div {
        height: 28px;
        line-height: 28px;
        text-align: center;
        padding: 0 0.521vw;
        width: 222px;
        font-size: 14px;
        color: rgb(96, 98, 102);
    }

    div:last-child {
        width: 220px;
    }
}
</style>
