<template>
    <div class="allwh">
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :editDataFormat="editDataFormat"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @rowEdit="normalEvent"
                  @rowDetail="normalEvent">
        </ja-table>
        <!-- 新增/编辑 -->
        <add-edit ref="addEdit" />
        <detail ref="detail" />
    </div>
</template>
<script>
import { rqTransire } from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit.vue'
import Detail from './components/Detail.vue'

export default {
    title: '放行条管理',
    mixins: [table,],
    name: 'index',
    components: {
        AddEdit, Detail
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'applyDeptName', attribute: { placeholder: '店铺名称', size: 'small' } },
                { input_type: 'input', key: 'applyEmployeeName', attribute: { placeholder: '申请人', size: 'small' }, },
                { input_type: 'select', key: 'type', attribute: { placeholder: '放行条类型', size: 'small' }, options: [] },
                { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
                {
                    input_type: 'select', key: 'status', attribute: { placeholder: '审批状态', size: 'small' }, options: [
                        { label: '审核中', value: 0 },
                        { label: '已同意', value: 1 },
                        { label: '已驳回', value: 2 },
                    ]
                },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '店铺名称', prop: 'applyDeptName' },
                { label: '申请人', prop: 'applyEmployeeName' },
                { label: '申请人联系方式', prop: 'applyEmployeePhone', width: 140 },
                { label: '放行条类型', prop: 'typeName' },
                { label: '放行时间', prop: 'transitTime' },
                { label: '物品搬运人', prop: 'transireEmployeeName', width: 140 },
                { label: '身份证号码', prop: 'transireEmployeeIdCard', },
                { label: '搬运人联系方式', prop: 'transireEmployeePhone', width: 140 },
                { label: '放行申请原因', prop: 'applyDescription', width: 180 },
                { label: '提交时间', prop: 'createTime', width: 140 },
                { label: '审批状态', prop: 'statusName' },
                {
                    label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
                    list: [
                        { text: '查看', method: 'rowDetail', limit: 'propertyServices::releaseNote::rowDetail' },
                        { text: '通过/驳回', method: 'rowEdit', limit: 'propertyServices::releaseNote::rowEdit' },
                    ]
                }
            ],
            tableData: [{status:0}]
        }
    },
    mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'type')
        this.util.rqOptionsList({ who: [this.conditionData[length]], rqUrl: "/sys/dictData/transire_type", rqMethod: 'get', resRule: 'data' })
    },
    methods: {
        rqParmas() {
            let obj = { ...this.filterData }
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        getInit() {
            this.loading = false
            let data = this.rqParmas()
            rqTransire(data).then(res => {
                this.loading = false
                this.tableData = res.data.data
                this.total = res.data.total
            })

        },
        editDataFormat(row, btn) {
            if ((row.status === 0 && btn.text == '通过/驳回') || (row.status != 0 && btn.text == '查看')) {
                return true
            }
        }
    }
}
</script>
