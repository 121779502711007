<template>
    <div>
        <el-dialog title="间隔时间设置" :visible.sync="show" width="40%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgb(36 33 33 / 90%)">
            <ja-form v-if="show" :rules="rules" v-model="rowFormData" :formArray="rowForm" @reset="close" @save="saveForm" labelWidth="120px">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import { SetIntervalsQuery, getCruiseTime } from '@/api'
export default {
    name: '设置间隔时间',
    data () {
        return {
            rowFormData: {
                groupName: 3
            },
            rules: {
                groupName: [
                    { required: true, trigger: 'change', validator: this.util.formRules.checkNumber({ min: 1, isInt: true, max: 60 }) }
                ],
            },
            show: false,
            rowForm: [
                {
                    input_type: 'number',
                    col: 15,
                    label: '间隔时间',
                    key: 'groupName',
                    width: '50px',
                    rightBox: { input_type: 'text', text: '分钟' },
                    min: 1,
                    max: 60
                }
            ],
            loading: false,
        }
    },
    methods: {
        init () {
            this.show = true
            this.loading = true
            getCruiseTime().then(res => {
                this.$set(this.rowFormData, 'groupName', res.data.configValue)
                this.loading = false
            })
        },
        close () {
            this.show = false
        },
        saveForm () {
            this.loading = true
            SetIntervalsQuery(this.rowFormData.groupName).then(res => {
                if (res.code !== 200) return this.$message.error('间隔时间设置失败')
                this.$message.success(res.data)
                this.loading = false
                this.show = false
                
            })
        }
    }
}
</script>