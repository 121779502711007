<template>
    <!--  普通的表单 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="40%"
        :title="(isAdd ? '新增' : '编辑') + '出租率'">
        <ja-form :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData"
            :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"></ja-form>
    </el-dialog>
</template>
<script>
import { sysRentalRateAdd, sysRentalRateUpdate } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            rowFormData: [
                { input_type: 'date', type: 'month', label: ' 出租月份', key: 'yearAndMonth', col: 11, valueFormat:'yyyy-MM', pickerOptions:{disabledDate:this.disabledDate}},
                { input_type: 'input', label: '出租率', key: 'rentalRate', col: 11, minlength: 1, maxlength: 100 }
            ],
            rowForm: {},
            rules: {
                yearAndMonth: [
                    { required: true, message: '不能为空', trigger: 'blur' },
                ],
                rentalRate: [
                    { required: true, trigger: 'blur', message: '不能为空' },
                    { required: true, trigger: 'change', type: 'number', validator: this.util.formRules.checkNumber({ fixedNum: 2, max: 100 }) }
                ],
            },
            loadingForm: false,
            labelWidth: `${this.util.setFontSize(140)}px`,
            showEditAll: false, // 显示新增/编辑弹窗,
            isAdd: false,
            copyRowForm: []
        }
    },
    methods: {
        append() {
            this.isAdd = true
            this.rowForm = {
                planStatus: 0,
                spaceStatus: 0
            }
            this.showEditAll = true
            this.allDisabled = false
        },
        rowEdit(row) {
            this.isAdd = false
            this.titleText = '编辑'
            this.rowForm = { ...row }
            this.showEditAll = true
            this.allDisabled = false
        },
        saveForm() {
            console.log(111);
            this.loadingForm = true
            let rqMethod = this.isAdd ? sysRentalRateAdd : sysRentalRateUpdate
            rqMethod(this.rowForm)
                .then(() => {
                    this.loadingForm = false
                    this.$message.success('提交成功')
                    this.showEditAll = false
                    this.getInit()
                })
                .catch((error) => {
                    this.loadingForm = false
                })
        },
        disabledDate(time) {
            // 定义禁止选择的时间范围
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth());
            const targetDate = new Date(time.getFullYear(), time.getMonth());
            return targetDate > today; // 禁用今天之前的日期
        }
    }
}
</script>
