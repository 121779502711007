<template>
  <div class="visualization" ref="visualization">
    <div class="vi_btn" @click="screenfullBtn">
      {{isFullscreen?'全屏':'退出'}}
    </div>
    <router-view/>
  </div>

</template>

<script>
import screenfull from 'screenfull'
export default {
  title: "综合态势",
  name:'comprehensiveSituation',
   data () {
        return {
            isFullscreen:true,
        }
    },
    methods:{
        screenfullBtn(){
            this.isFullscreen = !this.isFullscreen
            screenfull.toggle(this.$refs.visualization)
        },
    },
    mounted(){
      this.$emit('loading')
    }
}
</script>
<style lang="scss" scoped>
// 全屏状态
:-webkit-full-screen .vi_btn {
  width: 100px!important;
  height: 32px!important;
  font-size: 15px!important;
  line-height: 32px!important;
}
.visualization {
  position: relative;
  .vi_btn {
    background-image: url('~@/assets/gd/ds_qpbtn.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    position: absolute;
    width: 100px;
    height: 32px;
    line-height: 32px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    right: 30px;
    top: 14px;
    z-index: 999;
  }
}
</style>