<template>
  <!-- 年度能耗分析 -->
  <div class="content">
    <titleBox title="年份" v-model="activeYear" :selectObj="selectObj" />
    <ul class="list">
      <li v-for="(key,index) in Object.keys(yearList)" :key="index">
        <div class="titleBox" :style="{'background':yearList[key].bg,'color':yearList[key].color}">{{yearList[key].text}}</div>
        <div class="info">
          <span class="num" :style="{'color':yearList[key].bg}">{{resObj[key]}}</span>
          <span class="unit" :style="{'color':yearList[key].bg}" v-if="yearList[key].unit">{{yearList[key].unit}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import titleBox from './titleBox.vue'
export default {
  components: {
    titleBox,
  },
  data() {
    return {
      // 年份
      activeYear: '',
      selectObj: {
        options: [
          { label: '2019', value: '2019' },
          { label: '2020', value: '2020' },
          { label: '2021', value: '2021' },
          { label: '全部', value: 'all' },
        ]
      },
      // 年度分析
      yearList: {
        month_electricity: { text: '上月用电', unit: 'KW·H', bg: '#F59A23', },
        year_electricity: { text: '年度用电 ', unit: 'KW·H', bg: '#F59A23', },

        month_water: { text: '上月水耗', unit: 'm³', bg: '#f56c6c' },
        year_water: { text: '年度水耗', unit: 'm³', bg: '#f56c6c' },

        month_coldWind: { text: '上月计冷风量', unit: 'm³/s', bg: '#008080' },
        year_coldWind: { text: '年度计冷水量', unit: 'm³/s', bg: '#008080' },

        month_coldWater: { text: '上月计冷水量', unit: 'KW', bg: '#7089FF' },
        year_coldWater: { text: '年度计冷水量', unit: 'KW', bg: '#7089FF' },

        yaer_building: { text: '单位建筑面积电耗/年', unit: 'KW·H', bg: '#5470C6' },
        month_percentage1: { text: '上月同比', bg: '#5470C6' },
        month_percentage2: { text: '上月环比', bg: '#5470C6' },

      },
      // 假如 resObj 是从后端请求回来的数据
      resObj: {
        month_electricity: '10000',
        year_electricity: '10000',

        month_water: '10000',
        year_water: '10000',

        month_coldWind: '10000',
        year_coldWind: '10000',

        month_coldWater: '10000',
        year_coldWater: '10000',

        yaer_building: '10000',
        month_percentage1: '+12%',
        month_percentage2: '+24.4%',
      }
    }
  },
  watch: {
    activeYear: {
      immediate: true,
      handler(v) {
        console.log("年份====", v)
      }
    }
  },
  methods: {

  }

}
</script>

<style lang='scss' scoped>
.content {
  ul.list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    > li {
      width: calc(100%/6);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      &:hover {
        transform: scale(1.3);
      }
      .titleBox {
        width: 180px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 15px;
        font-size: 14px;
        color: white;
      }
      .info {
        margin-top: 15px;

        .num {
          font-size: 16px;
        }
        .unit {
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>