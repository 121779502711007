import http from './plugin/ajax'

// =========  工单管理  ===========

// // 工单配置
export const getWorrkConfig = (data) => http.post('/work/config', data)   // 查询
export const addWorrkConfig = (data) => http.post('/work/config/add', data)   // 新增
export const editWorrkConfig = (data) => http.put('/work/config', data)   // 编辑
export const delWorrkConfig = (data) => http.delete('/work/config/' + data)   // 删除
export const assignRangeWorkConfig = (data) => http.put('/work/config/send', data)   // 派单范围配置
export const getRangeWorkConfig = (data) => http.get('/work/config/getSend/' + data)  // 获取派单范围配置
export const getWorkConfigReciver = (data) => http.post('/employee/dept/deptIds', data) // 接单人 下拉列表


// 维修工单、维保工单、告警工单
export const getOrderList = (data) => http.get('/work/info/list', { params: data })   // 查询
export const assignOrderList = (data) => http.get('/work/info/dispatch', { params: data })   // 指派
export const turnOrderItem = (data) => http.get('/work/info/shift', { params: data })   // 转单
export const endOrderItem = (data) => http.get('/work/info/end/' + data)   // 结单
export const resetOrderItem = (data) => http.get('/work/info/reset', { params: data })   // 重启
export const agreeAduitOrder = (data) => http.get('/work/info/adopt/' + data)   // 审核- 同意
export const rejectAduitOrder = (data) => http.get('/work/info/reject', { params: data })   // 审核- 驳回

export const rqRepairOrderItem = (data) => http.get('/work/info/' + data)  // 维修工单 详情
export const rqInspectOrderItem = (data) => http.get('/work/info/inspection/' + data)  // 维保工单 详情

export const rqScanOrderItem = (data) => http.get('/work/info/patrol/' + data)  // 巡更工单 详情


export const getAcceptNum = (id) => http.get(`/work/info/repair/accept/detail/${id}`)  // 接单人数 维修工单

// 按楼栋查询异常设备总览信息
export const buildingEquipmentInfor = (data) => http.post('/work/info/deviceBuilding', data)  
// 按楼层查询异常设备总览信息
export const floorEquipmentInfor = (data) => http.post('/work/info/deviceFloor', data)  