<template>
  <div class="dswindow">
    <div class="waterpump">
      <p>系统状况详情</p>
      <ul>
        <li>
          <div>楼层总管供水温度:<span>19.8℃</span></div>
          <div>市政总管供水温度:<span>19.8℃</span></div>
        </li>
        <li>
          <div>楼层总管回水温度:<span>19.8℃</span></div>
          <div>市政总管回水温度:<span>19.8℃</span></div>
        </li>
        <li>
          <div>楼层总管供水压力:<span>10MPa</span></div>
          <div>市政总管供水压力:<span>10MPa</span></div>
        </li>
        <li>
          <div>楼层总管回水压力:<span>10MPa</span></div>
          <div>市政总管回水压力:<span>10MPa</span></div>
        </li>
        <li>
          <div>楼层总管流量:<span>32.0T/h</span></div>
          <div>市政总管流量:<span>32.0T/h</span></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$emit('loading')
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;
  .waterpump {
    width: 100%;
    height: 100%;
    background: url('~@/assets/images/gd/waterpump_bgc.png');
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 23px;
    p {
      text-align: left;
      margin-bottom: 18px;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
    }
    > ul > li {
      display: flex;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 16px;
      color: #fff;
      > div {
        width: 50%;
        margin-bottom: 9px;
      }
    }
    span {
      color: #3aa1ff;
      margin-left: 5px;
    }
  }
}
</style>