<template>
  <div class="dswindow">
    <div class="waterpumpbox">
      <p>水泵详情信息</p>
      <div>转速（r/min）：<span>138</span></div>
      <div>压力(Pa)：<span>800</span></div>
      <div>
        <div>开关:</div>
        <el-switch
          v-model="waterpumpopen"
          active-color="#3AA1FF"
          inactive-color="#aaa"
        >
        </el-switch>
      </div>
      <div>
        <div>电控阀:</div>
        <el-switch
          v-model="electricvalveopen"
          active-color="#3AA1FF"
          inactive-color="#aaa"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 电动阀
      electricvalveopen: false,
      // 水泵
      waterpumpopen: false
    }
  },
  mounted() {
    this.$emit('loading')
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;
  .waterpumpbox {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('~@/assets/images/gd/electricalbox_bg.png');
    background-size: 100% 100%;
    padding: 31px 23px;
    box-sizing: border-box;
    p {
      text-align: left;
      font-size: 18px;
      color: #fff;
    }
    span {
      color: #3aa1ff;
    }
    > div {
      color: #fff;
      margin-top: 20px;
      display: flex;
      align-items: center;
      .el-switch {
        margin-left: 20px;
      }
    }
  }
}
</style>