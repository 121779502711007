<template>
  <div class="dswindow">
    <div class="electrical">
      <div>
        <div>电压:<span>220V</span></div>
        <div>电流:<span>1.5A</span></div>
        <div>视在功率:<span>224KVA</span></div>
        <div>功率因数:<span>1</span></div>
        <div>直流屏电压:<span>124V</span></div>
        <div>电池电压:<span>2.0V</span></div>
        <div>开关状态:<span>开</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$emit('loading')
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;
  .electrical {
    width:100%;
    height: 100%;
    background: url('~@/assets/images/gd/electrical_bg.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 21px;
    font-size: 18px;
    > div > div {
      display: flex;
      box-sizing: border-box;
      color: #fff;
      margin-top: 9px;
    }
    > div > div:first-child {
      margin: 0;
    }
    span {
      color: #3aa1ff;
      margin-left: 5px;
    }
  }
}
</style>