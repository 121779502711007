<template>
    <div class="allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
            :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset"
            @rowDetail="normalEvent">
            <!-- 模式 -->
            <template #modeSetting="{ row }">
                <span>
                    {{ filterMode(row.modeSetting) }}
                </span>
            </template>
            <!-- 运行状态 -->
            <template #deviceStatus="{ row }">
                <span :class="classFilter(row.deviceStatus)">
                    {{ filterStatus(row.deviceStatus) }}
                </span>
            </template>
            <!-- 安装位置 -->
            <template #position="{ row }">
                <span>
                    {{ row.buildingName + row.floorName }}
                </span>
            </template>
            <!-- 总开关 -->
            <template #switchControl="{ row }">
                <span>
                    {{ filterControl(row.switchControl) }}
                </span>
            </template>
            <!-- 温差 -->
            <template #temperatureDifference="{ row }">
                <span :class="{error:Math.abs(row.temperatureDifference) > 5}">
                    {{ Math.abs(row.temperatureDifference) }}
                </span>
            </template>
            <!-- 风速 -->
            <template #windSetting="{ row }">
                <span>
                    {{ filterWind(row.windSetting) }}
                </span>
            </template>
            <template #button="{ row }">
                <el-button type="text" @click="detail(row)" fixed='right'
                    :disabled="row.deviceStatus != '1'">操作面板</el-button>
            </template>
        </ja-table>
        <el-dialog width="40%" :visible.sync="dialogFormVisible">
            <template #title>
                <span class="dialog_span">{{ title }}</span>
                <el-switch v-model="submitForm.switchControl" active-value="1" inactive-value="0" @change="switchChange">
                </el-switch>
            </template>
            <el-form class="dialog_form" ref="submitForm" :model="submitForm" inline :disabled="submitForm.switchControl === '0'">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="模式">
                            <el-select v-model="submitForm.modeSetting" placeholder="请选择" @change="modeChange">
                                <el-option v-for="item in optionMode" :key="item.value" :label="item.label"
                                    :value="item.value" :disabled="item.value === '0'">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="submitForm.switchControl == '1'">
                        <el-form-item label="风速">
                            <el-select v-model="submitForm.windSetting" :disabled="submitForm.modeSetting == 5" placeholder="请选择">
                                <el-option v-for="item in optionWind" :key="item.value" :label="item.label"
                                    :value="item.value" :disabled="item.value === '0'">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="submitForm.switchControl == '1'">
                    <el-col :span="12" v-if="submitForm.modeSetting !== '3' && submitForm.modeSetting !== '0'">
                        <el-form-item label="温度">
                            <el-input-number class="number" :disabled="submitForm.modeSetting == 5" :precision="0" v-model="submitForm.setInput" :min="16" :max="30" :step="1"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog_footer">
                <el-button type="primary" @click="submit">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import table from '@/views/mixins/table'
import { getAirPage, submitUpdateAir } from '@/api'

export default {
    title: "空调管理",
    name: 'airConditioner',
    mixins: [table],
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
                { input_type: 'input', key: 'deviceCode', attribute: { placeholder: '设备编码', size: 'small' } },
                {
                    input_type: 'select', key: 'deviceStatus', attribute: { placeholder: '运行状态', size: 'small' },
                    options: [
                        { label: '在线', value: '1' },
                        { label: '离线', value: '0' },
                        { label: '故障', value: '故障' },
                    ]
                },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' }
            ],
            columns: [
                { label: '设备名称', prop: 'deviceName' },
                { label: '设备编码', prop: 'deviceCode' },
                { slotName: 'position', label: '安装位置', prop: 'buildingName' },
                { slotName: 'switchControl', label: '总开关', prop: 'switchControl' },
                { slotName: 'modeSetting', label: '模式', prop: 'modeSetting' },
                { label: '室内温度', prop: 'indoorTemperature' },
                { label: '设定温度', prop: 'setInput' },
                { slotName: 'temperatureDifference', label: '温差', prop: 'temperatureDifference' },
                { slotName: 'windSetting', label: '风速', prop: 'windSetting' },
                { slotName: 'deviceStatus', label: '运行状态', prop: 'deviceStatus' },
                { slotName: 'button', label: '操作', width: 140, attribute: { fixed: 'right', showOverflowTooltip: false, align: 'center' } },
            ],
            tableData: [],
            total: 0,
            loading: false,
            dialogFormVisible: false,
            title: '',
            submitForm: {
                switchControl: 0,
                setInput: 16,
                windSetting: '',
                modeSetting: ''
            },
            // 1制冷；2抽湿；3送风；4制热；5自动
            optionMode: [
                { label: '无效', value: '0' }, 
                { label: '制冷', value: '1' }, 
                { label: '抽湿', value: '2' },
                { label: '送风', value: '3' },
                { label: '制热', value: '4' },
                { label: '自动', value: '5' }
            ],
            //1自动；2低速；3中速；4-高速
            optionWind: [
                { label: '无效', value: '0' }, 
                { label: '自动', value: '1' },
                { label: '低速', value: '2' },
                { label: '中速', value: '3' },
                { label: '高速', value: '4' }
            ],
            setInput:'',
            modeSetting:"",
            isTrue:false,
        }
    },
    computed: {
        filterMode() {
            return (key) => {
                // 模式设定 0无效 1制冷；2抽湿；3送风；4制热；5自动
                const arr = ['无效', '制冷', '抽湿', '送风', '制热', '自动']
                return arr[key]
            }
        },
        filterStatus() {
            return (key) => {
                // 设备状态inactive=离线；active=在线 其他是故障码
                const arr = { 0: '离线', 1: '在线' }
                return arr[key] || key
            }
        },
        filterControl() {
            return (key) => {
                // 控制开关0=关闭；1=开启
                const arr = ['关闭', '开启']
                return arr[key]
            }
        },
        filterWind() {
            return (key) => {
                // 风速设定0无效；1自动；2低速；3中速；4-高速'
                const arr = ['无效', '自动', '低速', '中速', '高速']
                return arr[key]
            }
        },
        classFilter() {
            return (key) => {
                const obj = {
                    '0': 'inactive',
                    '1': 'active',
                }
                return obj[key] || 'error'
            }
        }
    },
    methods: {
        // 获取初始化数据
        async getInit() {
            this.loading = true
            const { data } = await getAirPage(this.filterData)
            this.tableData = data.data
            this.total = data.total
            this.loading = false
        },
        detail({ deviceName, switchControl, setInput, windSetting, modeSetting, id }) {
            this.dialogFormVisible = true
            this.title = deviceName
            this.submitForm = {
                id,
                switchControl, 
                setInput, 
                windSetting, 
                modeSetting
            }
            if(switchControl == 0){
                this.isTrue = true
                this.submitForm.modeSetting = ''
            }
            this.modeSetting = modeSetting
            this.setInput = setInput
        },
        //空调模式切换
        modeChange(val){
            this.modeSetting = val
            if (val == 5) {
                this.submitForm.windSetting = '1'
                this.submitForm.setInput = 26
            }else if (val == 2) {
                this.submitForm.windSetting = '4'
                this.submitForm.setInput = this.setInput - 4
            }
        },
        //开关切换
        switchChange(val){
            if (this.isTrue && val === '1') {
                this.submitForm.modeSetting = '1'
                this.modeSetting = '1'
                this.submitForm.windSetting = '1'
                this.submitForm.setInput = 26
            }
            if (val === '0') {
                this.submitForm.modeSetting = ''
            }else if(!this.isTrue && val === '1'){
                this.submitForm.modeSetting = this.modeSetting
            }
            this.isTrue = false
        },
        submit(){
            if (this.submitForm.modeSetting == 3) {
                delete this.submitForm.setInput
            }
            submitUpdateAir(this.submitForm).then(data =>{
                if (data.code === 200) {
                    this.$message({
                        message: data.msg,
                        type: 'success'
                    });
                }
            }).catch(err=>{
            }).finally(() => {
                this.dialogFormVisible = false
                this.getInit()
            })
        }
    }
}
</script>
  
<style scoped lang="scss">
.dialog_form {
    /deep/ .el-form-item {
        width: 100%;

        .el-form-item__content {
            width: 70%;
        }

        .el-select {
            width: 100%;
        }

        .number {
            width: 60%;
            .el-input__inner{
                padding-left: 0;
            }
        }
    }
}

.inactive {
    color: #f8b636;
}

.active {
    color: #83bf48;
}

.error {
    color: #d50e21;
}

.dialog_span {
    margin-right: 10px;
}

.dialog_footer {
    display: flex;
    justify-content: center;
}
</style>
  