import http from './plugin/ajax'

// 社区管理-添加
export const addPark = (data) => http.post('/park', data)
export const getParkList = (data) => http.post('/park/list', data)
export const updatePark = (data) => http.put('/park', data)
export const delPark = (data) => http.delete('/park/' + data)
export const getDepartment = (data) => http.get('/department', { params: data })
    // 楼栋管理-
export const addBuilding = (data) => http.post('/building/add', data)
export const updateBuilding = (data) => http.put('/building/upData', data)
export const delBuilding = (data) => http.delete('/building/delete/' + data)
export const getBuildingList = (data) => http.post('/building/list', data)
export const getBuildingAll = () => http.get('/building/all')

// 楼层列表
export const getFloorList = (data) => http.post('/floor/query', data)

// =========  空间管理  ===========
export const spaceManageList = (data) => http.post('/spaceTemp/query', data) // 空间列表
export const addSpaceItem = (data) => http.post('/spaceTemp/add', data) // 新增空间
export const delSpaceItem = (data) => http.delete('/spaceTemp/delete/' + data) // 删除空间
export const editSpaceItem = (data) => http.put('/spaceTemp/upData', data) // 更新空间

// 房间管理-房间列表
export const getSpaceList = (data) => http.get('/room/getSpaceList', { params: data }) //根据id查询空间详情

// 已指派管理员
export const getAdminList = (data) => http.post('/park/admin/list', data);
export const delAdminList = (data) => http.delete('/park/admin', { data: data })
export const getDepTree = (data) => http.get('/department/tree', { params: data }) // 部门树
export const getUserInfo = (data) => http.get('/employee', { params: data }) // 部门树
export const addParkAdmin = (data) => http.post('/park/admin', data) // 部门树

// 获取节假日配置
export const getHolidayList = (data) => http.get('/holiday/list', { params: data })
    // 提交节假日配置/holiday
export const addHolidayt = (data) => http.post('/holiday', data);
