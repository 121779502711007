<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" :editDataFormat="editDataFormat" @getInit="getInit" @search="search" @reset="reset"  @rowEdit="normalEvent" @rowDetail="normalEvent">
      <template #img='{row}'>
        <el-image :preview-src-list="[row.faceImg]" fit="contain" class="cellImg" :src="row.faceImg" alt="" v-if="row.faceImg ">
          <div slot="error" class="errorImg el-image__error">
            加载失败
          </div>
        </el-image>
      </template>
    </ja-table>
    <!-- 新增 弹窗 -->
    <add-edit ref="addEdit" />
    <!-- 查看/审批 -->
    <detail ref="detail" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'
import Detail from './childComp/Detail'
import { rqVisitorApplyList } from '@/api'

export default {
  title: "访客预约记录",
  mixins: [table],
  components: { AddEdit, Detail },
  name: 'index',
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', key: 'time', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
        {
          input_type: 'select', key: 'status', attribute: { placeholder: '访客状态', }, options: []
        },
        { input_type: 'input', key: 'name', attribute: { placeholder: '人员姓名', } },
        { input_type: 'input', key: 'phone', attribute: { placeholder: '手机号码', } },
        { input_type: 'input', key: 'staffName', attribute: { placeholder: '被访人姓名', } },

        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset', },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/visitor/apply/record/export', rqMethod: 'post' }, limit: 'visitor::apply::download' }
      ],
      columns: [
        { label: '访客姓名', prop: 'name', },
        { label: '性别', prop: 'gender', },
        { label: '访客公司名称', prop: 'company', width: 150, attribute: { showOverflowTooltip: true, align: 'center' } },
        { label: '访客手机', prop: 'phone',width: 160, },
        { label: '来访事由', prop: 'visitReason' },
        { label: '访问类型', prop: 'visitType', },
        { label: '被访人公司', prop: 'staffCompany',width: 200, },
        { label: '被访人姓名', prop: 'staffName',width: 160, },
        { label: '预约时间', prop: 'visitTime', width: 160,},
        { label: '到访时间', prop: 'arrivedTime', width: 160, },
        { label: '访客照片', prop: 'faceImg', width: 100, slotName: 'img' },
        { label: '访客状态', prop: 'status', },
        {
          label: '操作', input_type: 'button', width: 120,
          list: [
            { text: '详情', method: 'rowDetail', limit: 'visitors::visitorApplyList::detail'},
            // { text: '审批', method: 'rowEdit', limit: 'visitors::visitorApplyList::examine'},
          ]
        }
      ],
      tableData: [],
      conditionData_indexObj: {},
    }
  },
  mounted() {
      let index = this.conditionData.findIndex(item => item.key == 'status')
      this.util.rqOptionsList({ who: [this.conditionData[index]], rqUrl: "/sys/dictData/visitor_apply_status", resRule: 'data' })
  },
  methods: {
    // editDataFormat(row, btn) {
    //   if ((row.status != 2) && btn.text == '审批' || (row.status != 3) && btn.text == '重发消息' || (row.applyType != '0') && btn.text == '重发消息') {
    //     return false
    //   } else {
    //     return true
    //   }
    // },
    rqParmas2() {
      let obj = this.rqParmas()
      if (obj.time) {
        obj.startTime = obj.time[0]
        obj.endTime = obj.time[1]
        delete obj.time
      }
      return obj
    },
    getInit() {
      this.loading = true
      let obj = this.rqParmas2()
      if(this.$route.query.visitorId){
        obj.visitorId = this.$route.query.visitorId
        // 清除路由参数
        this.$router.push({ query: {} });
      }
      rqVisitorApplyList(obj).then(res => {
        // this.tableData = [{}]
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>