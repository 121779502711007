import http from './plugin/ajax'

// 分页查询出租率
export const sysRentalRatePage = (data) => http.post('/sysRentalRate/page', data)  

// 添加出租率
export const sysRentalRateAdd = (data) => http.put('/sysRentalRate/add', data)   

// 修改出租率
export const sysRentalRateUpdate = (data) => http.post('/sysRentalRate/update', data)  

// 按id删除
export const sysRentalRateDelete = (data) => http.delete('/sysRentalRate/'+ data)

// 综合态势-查询出租率
export const sysRentalRateList = (data) => http.post('/sysRentalRate/comprehensiveList', data)  



