<template>
    <router-view />
</template>
  
<script>
export default {
    title: "门禁管理",
    name:'accessControl'
}
</script>
  
<style scoped></style>
  