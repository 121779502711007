<template>
  <div class="popup entry">
    <div class="content">
      <div class="content-item">
        <!-- <p><img class="address" :src="positon" alt="">{{info.city}}</p> -->
        <p v-if="info.type"><img
            :src="require('@/assets/images/header/'+info.type+'.png')"
            alt=""
          >{{info.type}}</p>
        <!-- <p><img :src="require('../../../assets/images/header/多云.png')" alt="">{{info.weather}}</p> -->
        <!-- <p>温度：<em>{{info.temp}}</em></p> -->
        <p>温度：<em>{{info.wenDu?info.wenDu:'18~27℃'}}</em></p>
        <p>空气质量：<em>{{info.quality?info.quality:'优'}}</em></p>
      </div>
      <div class="content-item heade_text">
        <!-- <img src="~@/assets/images/screen/dslogo.png" alt="" class="icons"> -->
        {{title}}
      </div>
      <div class="content-item textAlignRight">
        <p class="date">{{date}}</p>
        <p class="time">{{time}}</p>
        <p>
          <span>星期{{weekDay}}</span>
        </p>
      
        <!-- <p
          class="signOut"
          @click="screen"
        ><span>全屏</span></p> -->
      </div>
    </div>
  </div>
</template>

<script>
// import { getWeather, currentUser} from '@/api'
import { dateFormat } from '@/util/common.js'
import logo from '@/assets/images/screen/dslogo.png'
import weather from '@/assets/images/header/weather.png'
import positon from '@/assets/images/header/positon.png'
import point from '@/assets/images/header/point.png'
export default {
  data() {
    return {
      logo: logo,
      positon: positon,
      weather: weather,
      point: point,
      city: '',
      date: '',
      time: '',
      week: ['日', '一', '二', '三', '四', '五', '六'],
      weekDay: '',
      info: {
        type: '多云'
      },
      fullscreen: false,
    }
  },
  mounted() {
    this.currentTime()
  },
  props: {
    title: {
      type: String,
      default: '大屏数据看板'
    },
    parkId:{
      type:Number,
      default:1
    }
  },
  created() {
    this.currentTime()
    if (this.$store.state.token) {
      // this.getWeatherInfo()
      // this.currentUser()
    }
  },
  methods: {
    getDateTime: function () {
      // 获取当前时间
      const date = new Date()
      this.date = dateFormat('YYYY-mm-dd', date)
      this.time = dateFormat('HH:MM:SS', date)
      const d = new Date().getDay()
      this.weekDay = this.week[d]
    },
    currentTime() {
      setInterval(this.getDateTime, 500)
    },
    getWeatherInfo: function () {
      // 获取天气信息
      // getWeather().then((res) => {
      //   this.info = res.data
      // })
    },
    screen() {
      let element = document.documentElement
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/style/mixin.scss';
.entry {
  overflow: inherit;
  .popup {
    height: 100%;
    .content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px 20px 40px;
    }
    .content-item {
      flex: 1;
      color: #bac3ce;
      // &:first-child {
      //   display: flex;
      //   align-items: center;
      //   justify-content: flex-start;
      // }
      // &:last-child {
      //   display: flex;
      //   align-items: center;
      //   justify-content: flex-end;
      // }
      .line {
        width: 2px;
        height: 20px;
        display: inline-block;
        background-color: $theme_background_fff;
      }
      p {
        position: relative;
        display: flex;
        align-content: center;
        display: inline-block;
        font-size: 19px;
        font-weight: normal;
        padding: 0 7px;
        margin-left: 35px;
       
        &:first-child {
          padding-left: 0;
          margin-left: 0;
        }
        &:nth-child(2){
          margin-left: 15px;
        }
        &:last-child {
          padding-right: 0;
        }
        &.date {
          font-size: 19px;
        }
        &.time {
          font-size: 19px;
          padding-right: 0;
        }
        span {
          line-height: 18px;
          font-size: 19px;
          display: block;
        }
        // em {
        //   color: $theme_color_1cc2ff;
        // }
        img {
          width: 30px;
          height: 30px;
          vertical-align: middle;
          margin-right: 20px;
          &.address {
            margin-top: -5px;
          }
        }
        &.signOut {
          // 退出账号
          span {
            font-size: $theme_fontsize_16;
            padding: 6px 20px;
            // border-radius: 6px;
            background-image: url("~@/assets/images/screen/button.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            box-sizing: border-box;
            cursor: pointer;
            color: #01CDFD;
          }
        }
      }
    }
    .heade_text {
      position: relative;
      box-sizing: border-box;
      // flex: 0 0 28%;
      font-size: 40px;
      line-height: 40px;
      font-weight: bold;
      width: 100%;
      text-align: center;
      color: $theme_color_fff;
      letter-spacing: 6px;
      text-shadow: 0 8px 9px rgba(0,0,0,0.50);
      img{
        position: absolute;
        width: $theme_fontsize_30;
        height: $theme_fontsize_30;
        top: 58%;
        left: 50px;
      }
    }
  }
  .textAlignRight {
    // text-align: right;
    p{
      &:first-child{
        margin-left: 180px!important;
      }
      &:last-child{
        margin-left: 25px!important;
      }
    }
   
  }

  ::v-deep .el-input__inner {
    background-color: transparent !important;
    //   border-color: rgba(255, 255, 255, 0.5);
    color: #d9d9d9;
    height: 40px;
  }
  /**修改边框和字体颜色 */
  ::v-deep .el-select {
    position: relative;
    width: 150px;
    .el-input {
      input {
        height: 40px;
        border-color: rgba(44, 137, 229, 0.5);
        color: #fff;
      }
    }
  }
  /**修改下拉图标颜色 */
  ::v-deep .el-input__suffix {
    .el-input__suffix-inner {
      .el-icon-arrow-up:before {
        color: rgba(44, 137, 229, 0.5);
        padding-left: 0.11rem;
      }
    }
  }
  ::v-deep .el-select-dropdown{
    border: 1px solid #1b518f;
  }
  ::v-deep .el-scrollbar__wrap{
    text-align: center;
    background-color: #04193a;
    .el-select-dropdown__item{
      background-color: #04193a;
      color: #fff;
    }
  }
  ::v-deep .el-popper[x-placement^=bottom] .popper__arrow::after{
    border-bottom-color: #04193a;
  }
  ::v-deep .el-popper[x-placement^=bottom] .popper__arrow{
     border-bottom-color: #1b518f;
  }

}
</style>
