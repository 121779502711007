<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">实时道闸进出统计</div>
    </div>

    <div class="charts"  @click="showDialog">
      <ja-init-chart class="jachart" :options="options" :isRetrieving="false"/>
    </div>


    <div class="boxdia" v-if="barrierdialogVisible">
      <div class="dialog">
        <div class="dialog_title">
          {{ title }}
        </div>
        <div class="btn" @click="closeDialog"></div>
        <ja-init-chart class="boxchart" :options="dialogoptions"/>
      </div>
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
import Dialog from '../../components/Dialog/index.vue'
export default {
  props:{
    alarmList:{
      type:Array,
      default:()=>[]
    }
  },
  components: {
    JaInitChart,
    Dialog
  },
  data() {
    return {
      title:'实时道闸进出统计',
      options:{},
      dialogoptions:{},
      //弹窗数据
      straightData:[],
      burdenData:[],
      logY:['0:00','2:00','4:00','6:00','8:00','10:00'],
      alarmList: [],
      //图表数据
      eastData: [],
      southData: [],
      westData: [],
      northData: [],
      dateTime:['0:00','2:00','4:00','6:00','8:00','10:00','12:00','14:00','16:00','18:00','20:00','22:00'],
      barrierdialogVisible:false
    }
  },
  created() {
    this.init()
  },
  computed: {
    maxData() {
      let bgData = []
      for (let i = 0; i < this.dateTime.length; i++) {
        bgData.push(this.eastData[i] + this.southData[i] + this.westData[i] + this.northData[i])
      }
      let max = Math.max(...bgData)
      max = Math.ceil(max / 10) * 10
      let arr = []
      bgData.forEach((item) => {
        arr.push(max)
      })
      return arr
    }
  },
  methods: {
    async init() {
      let bgData = []
      for (let i = 0; i < this.dateTime.length; i++) {
        bgData.push(this.eastData[i] + this.southData[i] + this.westData[i] + this.northData[i])
      }
      this.options = {
        tooltip: {
          show: true,
          type: 'item',
          position: function (point, params, dom, rect, size) {
            return [point[0] - 30, point[1] - 70] //返回x、y（横向、纵向）两个点的位置
          },
          backgroundColor: 'transparent',
          formatter: function (params) {
            if (params.data.tooltip != 'none') {
              let str = `<div style = "font-size: 16px;background-image:url(${require('../../../../../../assets/images/screen/echarts_popup.png')});background-size: 100% 100%;width: 123px;height: 58px;">
              <div style = "width: 70%;margin: 0 auto;">${params.name}<br/> ${
                params.seriesName
              }: ${params.data}</div></div>`
              return str
            } else {
              return '' // 返回空字符串，禁用提示框
            }
          }
        },
        // legend: {
        //   data: ['入场', '出场'],
        //   top: '7%',
        //   left: '70%',
        //   itemWidth: 19,
        //   itemHeight: 12,
        //   textStyle: {
        //     color: '#01CDFD',
        //     fontSize: 12
        //   }
        // },
        xAxis: {
          axisTick: {
            show: false
          },
          data: this.dateTime,
          splitLine: { show: false },
          splitArea: { show: false },
          axisLine: {
            lineStyle: {
              color: '#1A3E47'
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize:9
          }
        },
        yAxis: {
          axisTick: {
            show: true,
            lineStyle: {
              color: '#4A957F'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#818a98',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: '#999', // 分隔线的颜色
              width: 0 // 分隔线的宽度
            }
          },
          // max:this.maxData[0]
        },
        grid: {
          left: '0%',
          right: '1%',
          bottom: '1%',
          height: '95%',
          containLabel: true
        },
        series: [
          {
            name: '东',
            type: 'bar',
            stack: 'total',
            barCategoryGap: '40%',
            data: this.eastData,
            itemStyle: {
              normal: {
                color: '#00A5FF'
              }
            }
          },
          {
            name: '南',
            type: 'bar',
            stack: 'total',
            barCategoryGap: '40%',
            data: this.southData,
            itemStyle: {
              normal: {
                color: '#D8FF23'
              }
            },
          },
          {
            name: '西',
            type: 'bar',
            stack: 'total',
            barCategoryGap: '40%',
            data: this.westData,
            itemStyle: {
              normal: {
                color: '#00A5FF'
              }
            }
          },
          {
            name: '北',
            type: 'bar',
            stack: 'total',
            barCategoryGap: '40%',
            data: this.northData,
            itemStyle: {
              normal: {
                color: '#D8FF23'
              }
            },
          },
          // {
          //   name: '占位',
          //   tooltip: 'none',
          //   type: 'pictorialBar',
          //   barWidth: '40%',
          //   barGap: '-100%',
          //   silent: true,
          //   symbol: 'image://' + this.chartcar,
          //   // symbolRepeat: 'fixed',
          //   symbolClip: true,
          //   symbolSize: [50, '100%'],
          //   data: this.maxData
          // },
          {
            //分隔
            tooltip: ' ',
            type: 'pictorialBar',
            itemStyle: {
              color: '#295C71'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 3,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [20, 2],
            symbolPosition: 'start',
            symbolOffset: [0, 2],
            data: bgData,
            z: 10
          }
        ]
      }
    },
    showDialog() {
      this.barrierdialogVisible = true
      this.dialogoptions = {
        yAxis: {
          type: 'category',
          position:'left',
          data: this.logY,
          axisTick: {
            show: true,
            length:20,
            alignWithLabel: true,
            lineStyle:{
              color:'rgba(0, 90, 190, 0.8)'
            }
          },
          axisLine: {
            show: true, // 显示 Y 轴的轴线
            onZero: false,
            lineStyle: {
              color: '#aaa',
            }
          },
          axisLabel: {
            margin:30,
            color: 'rgba(0, 90, 190, 0.8)',
            fontSize:16
          },
        },
        xAxis: {
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show:false,
            color: '#fff',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: '#999', // 分隔线的颜色
              width: 0 // 分隔线的宽度
            }
          },
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          height: '95%',
          containLabel: true
        },
        series: [
          {
            name: '正',
            type: 'bar',
            stack: 'total',
            barCategoryGap: '40%',
            data: this.straightData,
            itemStyle: {
              normal: {
                color: '#00A5FF'
              }
            },
            label: {
                normal: {
                    show: true,
                    position: 'right',
                    textStyle: {
                        fontSize:16,
                        color: '#aaa'
                    },
                }
            },
          },
          {
            name: '负',
            type: 'bar',
            stack: 'total',
            barCategoryGap: '40%',
            data: this.burdenData,
            itemStyle: {
              normal: {
                color: '#D8FF23'
              }
            },
            label: {
                normal: {
                    show: true,
                    position: 'left',
                    textStyle: {
                        fontSize:16,
                        color: '#aaa'
                    },
                    formatter: function(data) {
                        return Math.abs(data.value)
                    },
                }
            },
          },
          {
            //分隔
            tooltip: ' ',
            type: 'pictorialBar',
            itemStyle: {
              color: '#295C71'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 8,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [2, 52],
            symbolPosition: 'start',
            symbolOffset: [0, 0],
            data: this.burdenData,
            z: 10
          },
           {
            //分隔
            tooltip: ' ',
            type: 'pictorialBar',
            itemStyle: {
              color: '#295C71'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 8,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [2, 52],
            symbolPosition: 'start',
            symbolOffset: [16, 0],
            data: this.straightData,
            z: 10
          }
        ]
      }
    },
    closeDialog() {
      this.barrierdialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  .charts{
    margin-top: 40px;
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    margin-top: 20px;
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 10px;
    height: 100%;
    position: relative;
    top: -5px;
    display: flex;
    .jachart {
      z-index: 2;
      width: 100%;
      height: 200px;
    }
  }

  .boxdia {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 100;
  .dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 1552px;
    height: 800px;
    padding: 150px 240px 0 240px;
    background-image: url('~@/assets/gd/ds_chartwindow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    .dialog_title {
      position: absolute;
      font-size: 22px;
      top: 112px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fff;
    }
    .btn {
      position: absolute;
      height: 44px;
      width: 38px;
      top: 60px;
      right: 120px;
      background-image: url('~@/assets/gd/ds_closebtn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 100;
    }
    .boxchart{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 30px 10px 70px 20px;
    }
  }
}
}
</style>