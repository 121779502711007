<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">维修详情列表</div>
    </div>

    <div class="right_table">
      <el-table
        :data="visibleData"
        style="width: 100%"
        size="mini"
        header-cell-class-name="table_head"
        highlight-current-row="true"
      >
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :key="item.prop"
          :class-name="item.tdstyle"
          :width="item.width"
          v-for="item in columns"
        >
          <template slot-scope="scope">
            <span
              v-if="item.prop == 'statusValue'"
              :class="tdColor(scope.row[item.prop])"
            >
              {{ scope.row[item.prop] }}
            </span>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getLightingMaintenance } from '@/api'
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  name: 'temperature',
  components: {
    JaInitChart
  },
  data() {
    return {
      columns: [
        { label: '报修类别', prop: 'workOrderCategoryValue' },
        { label: '所在位置', prop: 'position' },
        { label: '状态', prop: 'statusValue' }
      ],
      tableData: [
      ],
      visibleData: [],
      currentIndex: 0,
      itemsToShow: 6, // 一次显示的数据项数量
      intervalId: null, // 用于存储定时器的引用
    }
  },
  created() {
    // this.init()
    // this.visibleData = this.currentItem // 初始化可见数据
    // this.initCarousel()
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    clearInterval(this.intervalId);
  },
  methods: {
    init() {
      // this.getData()
    },
    async getData(i) {
      let { data: res } = await getLightingMaintenance({ buildingId:i.buildingId,floorId:i.floorId})
      this.tableData = res

      this.tableData.forEach(item => {
        item.workOrderCategoryValue = item.workOrderCategory == 0? '物业报修' : '租户报修'
        delete item.workOrderCategory
        switch(item.status){
          case 0:
            item.statusValue = '待派单';
            break;
          case 1:
            item.statusValue = '待接单';
            break;
          case 2:
            item.statusValue = '处理中';
            break;
          case 3:
            item.statusValue = '结单审核中';
            break;
          case 4:
            item.statusValue = '已结单';
            break;
          case 5:
            item.statusValue = '接单超时';
            break;
          case 6:
            item.statusValue = '处理超时';
            break;
          case 7:
            item.statusValue = '挂起中';
            break;
          case 8:
            item.statusValue = '已接单';
            break;
        }
        delete item.status
      })

      await clearInterval(this.intervalId);

      if(this.tableData.length >= 6){
        this.visibleData = this.currentItem // 初始化可见数据
        this.initCarousel()
      }else{
        this.visibleData = this.tableData
      }
    },
    tdColor(i) {
      switch (i) {
        case '处理中':
          return 'td_green'
        case '待派单':
          return 'td_red'
        case '结单审核中':
          return 'td_yellow'
      }
    },
    // 初始化轮播
    initCarousel() {
      this.intervalId = setInterval(() => {
        if(this.tableData.length == 0){
          this.currentIndex = 0
        }else{
          this.currentIndex = (this.currentIndex + 1) % this.tableData?.length
        }
        this.visibleData = this.currentItem
      }, 1000) // 1秒切换一次，根据需要调整间隔时间
    }
  },
  computed: {
    // 计算属性用于计算当前显示的数据
    currentItem() {
      return this.tableData?.slice(
        this.currentIndex,
        this.currentIndex + this.itemsToShow
      )
    },
    ...mapState({
      buildingFloor: (state) => state.visualsystem.buildingFloor
    })
  },
  watch: {
    buildingFloor(newVal) {
      this.getData(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  /deep/ .el-table__cell {
    padding: 8px 0 !important;
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    height: 26px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .right_table {
    margin-top: 5px;
    display: flex;
    transition: transform 0.5s ease-in-out;
    /deep/ .el-table::before {
      background: none !important;
    }
    /deep/ .table_head {
      color: #fff;
      background-color: #00408a !important;
      border-bottom: #0057ae !important;
      box-shadow: inset 0px -1px 0px 0px rgba(0, 120, 255, 0.3) !important;
      box-shadow: 0px -1px 0px 0px rgba(0, 174, 255, 1) !important;
    }

    /deep/ .table_head .cell {
      padding-left: 25px;
    }

    /deep/ .table_head .cell::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 10px;
      width: 8px;
      height: 8px;
      background-image: url('~@/assets/gd/ds_td.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    /deep/ .el-table__body-wrapper,
    .el-table {
      background: #002f64 !important;
    }

    /deep/ .el-table__row {
      font-size: 10px !important;
      padding: 30px !important;
      line-height: 10px !important;
      height: 10px !important;
    }

    /deep/ .el-table__row:nth-child(odd) {
      background: #002f64 !important;
      color: #fff;
    }

    /deep/ .el-table__row:nth-child(odd) td {
      border-bottom: #002f64 !important;
    }

    /deep/ .el-table__row:nth-child(even) {
      background: #004278 !important;
      color: #fff;
    }

    /deep/ .el-table__row:nth-child(even) td {
      border-bottom: #004278 !important;
    }

    /deep/ .el-table__row td {
      background-size: 96px 8px;
      background-repeat: no-repeat;
      background-position: left bottom;
    }

    /deep/ .el-table__cell {
      padding: 2px 0;
    }

    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td,
    /deep/ .current-row > td.el-table__cell {
      background-color: transparent !important;
    }
  }

  .td_green {
    color: #00ff98;
    font-weight: 700;
  }
  .td_red {
    color: #f02731;
    font-weight: 700;
  }
  .td_yellow {
    color: #ffc800;
    font-weight: 700;
  }
}
</style>