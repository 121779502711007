<template>
  <div class="header">
    <!-- <img src="~@/assets/images/screen/box_icon.png" alt="" class="icons"> -->
    <div class="headerleft">{{title}}</div>
    <!-- <img src="~@/assets/images/screen/box_icon.png" alt="" class="icons"> -->
    <div class="headerRight" v-if="total">
      <i></i>
      <span>{{total}}</span>
    </div>
    <!-- <img :src="rightIcon" class="rightIcon"> -->
  </div>
</template>

<script>
// import leftIcon from '@/assets/images/homeicon/left.png'
// import rightIcon from '@/assets/images/homeicon/right.png'
export default {
  name: "WPeader",
  data () {
    return {
      // leftIcon: leftIcon,
      // rightIcon: rightIcon
    }
  },
  props: {
    title: {
      type: String
    },
    total: {
      type: String
    },
    icon: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style/mixin.scss";
@import "./index.scss";
.right{
  .header{
    top: 11px;
  }
}

  .header{
    height: 26px;
    width: 290px;
    padding-left:15px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: $theme_color_fff;
    position: absolute;
    left: 15px;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft{
      flex-shrink: 0;
      line-height: 28px;
      font-size: $theme_fontsize_18;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
    .headerRight{
      width: 100%;
      text-align: right;
      padding-left: 16px;
      font-size: $theme_fontsize_16;
      i {
        width: 10px;
        height: 10px;
        background-color: #00FFE4;
        border-radius: 50%;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
</style>
