<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">车位使用总览</div>
    </div>

    <div class="charts">
      <ja-init-chart class="jachart" :options="options" />
      <div class="chartimg">
        <div class="leftData">
          <p>总车位</p>
          <p>0</p>
        </div>
        <div class="rightData">
          <div>
            <p>月保车位</p>
            <p>0</p>
          </div>
          <div>
            <p>临停车位</p>
            <p>0</p>
          </div>
          <div>
            <p>空闲车位</p>
            <p>0</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  props:{
    alarmList:{
      type:Array,
      default:()=>[]
    }
  },
  components: {
    JaInitChart
  },
  data() {
    return {
      alarmList: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = false
      this.options = {
        // legend: {
        //   type: 'plain',
        //   orient: 'vertical',
        //   top: '11%',
        //   right: '20%',
        //   icon: 'diamond',
        //   itemGap: 12,
        //   itemWidth: 7, // 设置宽度
        //   itemHeight: 7, // 设置高度
        //   textStyle: {
        //     color: '#DAFFE8',
        //     fontSize: 14
        //   }
        // },
        color: ['#7DFF89', '#FFBE8F', '#008EFF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '90',
            endAngle: '-269.9999',
            radius: '61%',
            splitNumber:24,
            center:['27.5%','50%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: '#002f64 ',
                width: 2
              }
            },
            axisLabel: {
              show: false
            },
            axisLine: {
                show: false,
                lineStyle: {
                    color: [
                        [0.1, '#7DFF89'],
                        [0.3, '#FFBE8F'],
                        [0.5, '#008EFF']
                    ],
                    width: 10
                }
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['50%', '60%'],
            avoidLabelOverlap: false,
            hoverOffset:0,
            top: '0%',
            left: '-45%',
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: [32,6,7]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  .charts{
    margin-top: 40px;
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    margin-top: 20px;
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 10px;
    height: 100%;
    position: relative;
    top: -5px;
    display: flex;
    .chartimg {
      z-index: 1;
      position: absolute;
      left: 34px;
      top: 18px;
      width: 246px;
      height: 136px;
      background-image: url('~@/assets/gd/chartbg3.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .leftData{
        z-index: 999;
        position: absolute;
        top: 50%;
        left: 6%;
        transform: translate(0,-40%);
        width: 100px;
        text-align: center;
        p:first-child{
          font-size: 12px;
          color: #C2E4FF;
        }
        p:last-child{
          margin-top: 5px;
          font-size: 18px;
          color: #6FEAFF;
        }
      }
      .rightData{
        width: 90px;
        z-index: 999;
        position: absolute;
        top: 15%;
        left: 50%;
        >div{
          margin-bottom: 23px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p:first-child{
          font-size: 13px;
          color: #76B0FF;
          }
          p:last-child{
            font-size: 18px;
            color: #6FEAFF;
            text-shadow: 0px 0px 6px rgba(91,211,255,0.56);
          }
        }
        >div:nth-child(2){
          p:first-child{
            margin-left: 10px;
          }
        }
      }
    }
    .jachart {
      z-index: 2;
      width: 360px;
      height: 180px;
    }
  }
}
</style>