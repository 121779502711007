<template>
    <div>
        <div class="table-title">
            巡更记录查找
        </div>
        <div class="tableList">
            <ja-table v-model="fromData"
                      :columns="columns"
                      :tableData="tableData"
                      :pageSizes="[10,20,30]"
                      :conditionData="conditionData"
                      :total="total"
                      :loading="loading"
                      :showIndex="true"
                      @getInit="getInit"
                      @search="search"
                      @reset="reset"></ja-table>
        </div>
    </div>
</template>

<script>
import table from '@/views/mixins/table'
import { getPatrolRecord } from '@/api'
export default {
    mixins: [table,],
    name: '巡更记录列表',
    data() {
        return {
            columns: [
                { label: '巡更线路', prop: 'line', width: '200px' },
                { label: '计划类型', prop: 'planType', width: '100px' },
                { label: '计划模式', prop: 'planMode', width: '100px' },
                { label: '计划开始时间', prop: 'startTime', width: '200px' },
                { label: '计划结束时间', prop: 'endTime', width: '200px' },
                { label: '地点', prop: 'placeName', width: '100px' },
                { label: '维保时间', prop: 'patrolTime', width: '100px' },
                { label: '设备名称', prop: 'deviceName', width: '100px' },
                { label: '部门', prop: 'departmentName', width: '100px' },
                { label: '巡更人', prop: 'userName', width: '100px' },
                { label: '完成情况', prop: 'planState', width: '100px' },
            ],
            conditionData: [
                { input_type: 'date', type: 'daterange', value: 'time' },
                { input_type: 'select', key: 'planState', options: [{label:'准时',value:'准时'},{label:'未到',value:'未到'},{label:'错序',value:'错序'}], attribute: { placeholder: '完成情况', size: 'small' } },
                { input_type: 'input', key: 'userName', attribute: { placeholder: '巡更人', size: 'small' } },
                { normalBtn: 'search', size: 'small', text: '' },
                { normalBtn: 'reset', size: 'small', text: '' },
            ],
            fromData: {
                pageIndex: 1,
                pageSize: 10
            }
        }
    },
    watch: {
        'fromData.time': {
            handler(newVal, oldVla) {
                if (!newVal) return false
                this.fromData.startTime = newVal[0]
                this.fromData.endTime = newVal[1]
            },
            deep: true,
        }
    },
    methods: {
        getInit() {
            this.loading = true
            getPatrolRecord(this.fromData).then(res => {
                this.loading = false
                let data = res.data.data
               
                this.tableData = data
                this.total = res.data.total
            })
        },
        reset() {
            this.fromData = {
                pageIndex: 1,
                pageSize: 6
            }
            this.getInit()
        }
    }

}
</script>
<style lang="scss" scoped>
.table-title {
    position: relative;
    top: 15px;
    left: 0;
    font-size: 12px;
}
</style>