<template>
  <div class="box_bg">
    <div class="box_bggif"></div>
    <div class="header">
      <div class="headerleft">{{title}}</div>
    </div>
    <div class="midbox">
      {{num}}<span>{{unit}}</span>
    </div>
  </div>
</template>

<script>
export default {
  title: '设备总数',
  data() {
    return {
      title:'设备总数',
      num:'5727',
      unit:'台'
    }
  },
  props: ['realCar'],
  created() {
  },
  mounted(){
    this.$emit('loading')
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
@import "~@/style/mixin.scss";
.box_bg {
  background-image: url('~@/assets/gd/ds_card2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 22px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
}
.box_bggif {
  background-image: url('~@/assets/gd/Lable-Light-Point.gif');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 22px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  z-index:11;
}
.header {
  padding: 13px 0 13px 0;
  font-size: 19px;
  text-align: center;
  color: #fff;
}
.midbox{
  text-align: center;
  color: #02D1FF;
  font-size: 32px;
  span{
    font-size: 16px;
    color: #F6FFF9;
    opacity: 0.7;
    margin-left: 7.5px;
  }
}
</style>
