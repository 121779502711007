<template>
    <!-- 照明系统-照明日志 -->
    <div class="allwh">
        <div class="allwh-wrapper">
            <ja-table ref="tst"
                      v-model="filterData"
                      :conditionData="conditionData"
                      :columns="columns"
                      :tableData="tableData"
                      :total="total"
                      :loading="loading"
                      @getInit="getInit"
                      @search="search"
                      @reset="reset">
            </ja-table>
        </div>
    </div>
</template>

<script>
import table from "@/views/mixins/table"
import { getlightingLog} from '@/api'

export default {
    title: '照明日志',
    mixins: [table],
    name: 'sceneManage',
    components: {

    },
    data() {
        return {
            filterData: { pageIndex: 1, pageSize: 10,time:[] }, // 默认页码
            conditionData: [
                { input_type: 'date', type: 'daterange', key: 'time', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqexport, rqUrl: '/lightingLog/export', rqMethod: 'post' }, limit: 'subsystemModule::lightingLog::export'},
            ],
            columns: [
                { label: '操作人', prop: 'operator' },
                { label: '操作类型', prop: 'operationType', attribute: { showOverflowTooltip: true, align: 'center' } },
                { label: '变更前', prop: 'beforeChange' },
                { label: '变更后', prop: 'afterChange', width: 80 },
                { label: 'ip地址', prop: 'ipAddress', width: 120 },
                { label: '时间', prop: 'time', attribute: { showOverflowTooltip: true, align: 'center' } }
            ],
            tableData: [],
            loading: false,
            total: 0,
            // 新增/编辑
            titleText: '编辑',
            showEditAll: false, // 显示新增/编辑弹窗
            indexObj: {},
            conditionIndexObj:{},
            allDisabled:false

        }
    },
    watch: {

    },
    created() {
        
    },
    mounted() {
        

    },
    methods: {
        rqexport() {
            let obj = { ...this.filterData }
            obj.pageIndex = this.total <= 2000 ? 1 : this.filterData.pageIndex
            obj.pageSize = 2000
            if (obj.date) {
                obj.dateStart = obj.date[0]
                obj.dateEnd = obj.date[1]
                delete obj.date
            }
            return obj
        },
        getInit: function (item) { // 查询设备列表
            this.loading = true
            if(this.filterData.time){
                this.filterData.startTime=this.filterData.time[0]?this.filterData.time[0]:'';
                this.filterData.endTime=this.filterData.time[1]?this.filterData.time[1]:'';
            }
            getlightingLog(this.filterData).then(res => {
                this.loading = false
                const { data, total } = res.data
                this.total = total
                this.tableData = data
            }).catch(err => {
                this.loading = false
            })
        },
        normalEvent(row){
            
            this.rowEdit(row)
            this.allDisabled=true
        },
        rqParmas() {
            let obj = { ...this.filterData }
            if (obj.date) {
                obj.dateStart = obj.date[0]
                obj.dateEnd = obj.date[1]
                delete obj.date
            }
            return obj
        }
    }
}
</script>

<style scoped>
</style>
