<template>
  <!--  普通的表单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" @close="close" :visible.sync="showEditAll" width="65%"
    :title="titleText">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData"
      :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" :noButton="noButton" v-if="showEditAll">
      <template #noticeImg="">
        <div class="BigBox">
          <el-upload 
            ref="upload"
            :action="$uploadUrl + '/oss/upload'" 
            accept=".png,.jpg,.jpeg"
            :headers="headers"
            :on-progress="imgErrorMethod"
            :on-success="imgSuccessMethod"
            :before-upload="beforeUploadImg"
            :on-change="getimg"
            :file-list="fileList"
            :auto-upload="false"
            :http-request="postImg"
            :show-file-list="false">
            <el-button size="small" type="primary" :disabled="updateimg">上传</el-button>
            <template #tip>
              <div class="cropper">
                <vue-cropper
                      ref="cropper"
                      :img="option.img"
                      :outputSize="option.outputSize"
                      :autoCrop="option.autoCrop"
                      :autoCropWidth="option.autoCropWidth"
                      :autoCropHeight="option.autoCropHeight"
                      :enlarge="option.enlarge"
                      :fixedBox="option.fixedBox"
                      :fixed="option.fixed"
                      :fixedNumber="option.fixedNumber"
                     >
                </vue-cropper>
              </div>
              <div class="el-upload__tip">
                仅支持960×334 px ，不超过100kb。
                <el-button size="small" type="primary" @click="cropImage" :disabled="imgdisabled">确认裁剪</el-button>
              </div>
            </template>
          </el-upload>
        </div>
      </template>
    </ja-form>
  </el-dialog>
</template>
<script>
import { VueCropper } from "vue-cropper";
import { postImage, addNotice, updateNotice } from '@/api'
export default {
  components: {
    VueCropper
  },
  inject: ['getInit'],
  data() {
    return {
      noButton:false,
      imgdisabled:true,
      updateimg:false,
      fileList:[],
       option: {
        name: '',             //图片名称
        img: '',             //裁剪图片的地址
        outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
        autoCrop: true,      //是否默认生成截图框
        fixed:true,
        fixedNumber: [1, 0.36],
        autoCropWidth: 300,  //默认生成截图框宽度
        autoCropHeight: 108, //默认生成截图框高度
        fixedBox: false,
        enlarge: 1,          //图片根据截图框输出比例倍数
      },
      labelWidth: '85px',
      rowFormData: [
        { input_type: 'input', label: '公告标题', key: 'title', col: 11 },
        {
          input_type: 'checkbox', label: '公告位置(多选)', key: 'location', col: 13, labelWidth: '120px',
          options: [
            { label: '内部', value: '1' },
            { label: '租户', value: '2' },
            { label: '访客', value: '3' }
          ],
        },
        {
          input_type: 'radio', label: '公告时效', key: 'timeLimit', col: 11,
          options: [
            { label: '长期', value: '1' },
            { label: '固定', value: '2' },
          ],
        },
        { input_type: 'date',type:'datetimerange',key: 'time', valueFormat:'yyyy-MM-dd HH:mm', pickerOptions: { disabledDate: this.disabledDateStart }, hide:true, col: 11, },
        {
          input_type: 'input',
          label: '公告内容',
          key: 'content',
          placeholder:' ',
          type: 'textarea',
          col: 22,
          rows: 1,
          maxlength: 200
        },
        { label: '公告图片', input_type: 'customize', customize: 'noticeImg', key:'imgUrl',col: 22, alignItems: 'flex-start' },
      ],
      rowForm: {},
      rules: {
        title: [{ required: true, message: '请公告标题名称', trigger: 'change' }],
        location: [{ required: true, message: '请选择公告位置', trigger: 'change' }],
        timeLimit: [{ required: true, message: '请选择公告时效', trigger: 'change' }],
        imgUrl: [{ required: true, message: '请上传裁剪后图片', trigger: 'change' }],
        time: [{ required: true, message: '请选择时间', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},
      newFile:null
    }
  },
  created() {
    const token = this.$store.state.token
    this.headers = {
      Authorization: token.token_type + ' ' + token.access_token
    }
  },

  mounted() {
  },
  watch:{
     'rowForm.timeLimit': {
      handler: function (val) {
        if (val == 1) {
          this.$set(this.rowFormData[3], 'hide', true)
          this.$refs.jaForm.$refs.formbox.validateField('imgUrl')
        } else {
          this.$set(this.rowFormData[3], 'hide', false)
        }
      },
    },
  },
  methods: {
    // 开始时间禁用
    disabledDateStart(time) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      // 检查日期是否在今天之前
      return time.getTime() < today.getTime();
    },
    //上传文件之前
    beforeUploadImg(file,fileList) {
      let fileSize = 0
      let fileSizeText = '文件大小不能超过2MB'
      fileSize = !(file.size / 1024 / 1024 > 2)
      // fileSizeText = '图片大小不能超过10M8'
      if (!fileSize) {
        this.$message.warning(fileSizeText)
        return falses
      }
    },
    //生成图片file格式
    cropImage() {
      this.$refs.cropper.getCropBlob(async data => {
        const file = new File([data], this.option.name, { type: data.type });
        let formData = new FormData();
        formData.append('file',file, this.option.name)
        let {data:res} = await postImage(formData)
        this.rowForm.imgUrl = res
        this.imgdisabled = true
        this.$message.success('获取裁剪后图片地址成功')
        this.$refs.jaForm.$refs.formbox.validateField('imgUrl')
      })
    },
    //获取图片进裁剪区
    getimg(file) {
      const fileReader = new FileReader();
      fileReader.onload = (e => {
        this.option.img = e.target.result
        this.option.name = file.name
      })
      fileReader.readAsDataURL(file.raw);
      this.imgdisabled = false
    },
    //新增
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {
        location: [],
        imgUrl:'',
      }
      this.rowFormConfig(false)
      this.showEditAll = true
    },
    rowEdit(row) {
      this.isAdd = false
      this.titleText = '编辑'
      this.option.img = row.imgUrl
      this.option.name = row.imgUrl.substring(row.imgUrl.lastIndexOf('/') + 1) //截取文件名最后一个/后面的内容
      console.log(this.option);
      this.rowForm = { ...row }
      this.rowFormConfig(false)
      this.showEditAll = true
      this.imgdisabled = false
    },
    rowDetail(row){
      this.isAdd = false
      this.titleText = '详情'
      this.option.img = row.imgUrl
      this.rowForm = { ...row }
      this.rowFormConfig(true)
      this.showEditAll = true
    },
    //表格配置项变化
    rowFormConfig(ifconfig){
       this.rowFormData.forEach(i => {
        i.disabled = ifconfig? true :false
        if (i.options) {
          i.options.forEach(j => {
            j.disabled = ifconfig ? true : false
          })
        }
      })
      this.noButton = ifconfig ? true : false
      this.updateimg = ifconfig ? true : false
      this.option.autoCrop = ifconfig ? false :true
    },
    close(){
      this.imgdisabled = true
      this.showEditAll = false
      this.rowForm = {}
      this.option.img = ''
    },
    async saveForm() {
      this.loadingForm = true
      if (this.rowForm.time) {
        this.rowForm.startTime = this.rowForm.time[0]
        this.rowForm.endTime = this.rowForm.time[1]
        delete this.rowForm.time
      }
      if (this.isAdd) {
        let { data: msg } = await addNotice(this.rowForm)
        console.log(msg);
        this.$message.success('新增成功')
      } else {
        let { data: msg } = await updateNotice(this.rowForm)
        console.log(msg);
        this.$message.success('修改成功')
      }
      this.$parent.getInit()
      this.showEditAll = false
      this.loadingForm = false
      this.rowForm = {}
      this.option.img = ''
    },
  }
}
</script>

<style scoped>
.cropper{
  width:100%;
  height: 500px;
}
</style>