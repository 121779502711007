import http from './plugin/ajax'

// ========= 首页 api ============

// ****** 切换账号
// 查询 账号列表
export const rqIndexUserList  = (data) => http.get('/auth/user/exchange',data)
// 提交 账号选择
export const editIndexUser  = (data) => http.post('/auth/user/exchange',data)

