<template>
  <!--  普通的表单 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" :title="(isAdd?'新增':'编辑')+' '">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm"  @reset="showEditAll = false" @save="saveForm" v-if="showEditAll">
    </ja-form>
  </el-dialog>
</template>
<script>
import { addPath, updatePath,pointAllList } from '@/api'

export default {
  inject: ['labelWidth', 'getInit'],
  data() {
    return {
      rowFormData: [
        {
          input_type: 'input',
          label: '巡更路线名称',
          key: 'pathName',
          col: 11
        },
        {
          input_type: 'text2',
          label: '巡更路线编号',
          key: 'pathCode',
          col: 11
        },
        {
          label: '路线点位',
          input_type: 'select',
          key: 'pointIds',
          multiple:true,
          method: 'selectPoint',
          col: 22
        },
        // {
        //   input_type: 'table',
        //   col: '24',
        //   label: '路线点表',
        //   noSearch: true,
        //   showPage: false,
        //   key: 'pointsList',
        //   border: true,
        //   tableAttribute: { showSummary: true },
        //   columns: [
        //     { label: '路线点位', prop: 'pathDesc' },
        //     {
        //       label: '操作',
        //       input_type: 'button',
        //       width: 160,
        //       attribute: { fixed: 'right', align: 'center' },
        //       list: [{ text: '删除', method: 'rowDelete' }]
        //     }
        //   ]
        // },
        { input_type: 'number', label: '最少拍照数', key: 'imgMinLimit',max:100,min:1, col: 22 },
        { input_type: 'input', label: '路线描述', key: 'pathDesc', col: 22 }
      ],
      rowForm: {pointIds:[]},
      rules: {
        pathName: [
          { required: true, message: '请完善巡更路线名称', trigger: 'blur' }
        ],
        imgMinLimit: [
          { required: true, message: '请完善最少拍照数', trigger: 'change' }
        ],
        pointIds: [
          { required: true, message: '请选择路线点位', trigger: 'change' }
        ],
        floorId: [
          { required: true, message: '请选择所属楼层', trigger: 'change' }
        ],
        position: [
          { required: true, message: '请完善巡更点位置', trigger: 'blur' }
        ]
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
    }
  },

  mounted() {
    pointAllList().then(({data})=>{
      this.rowFormData[2].options = data.map(({id,pointName})=>{return{label:pointName,value:id+""}})
    })
  },

  methods: {
    async append() {
      console.log('add ')
      this.isAdd = true
      this.rowForm = {}
      this.showEditAll = true
    },
    rowEdit(row) {
      this.isAdd = false
      this.titleText = '编辑'
      this.rowForm = { ...row }
      this.rowForm.pointIds = this.rowForm.pointIds.split(',')
      console.log('edit---', this.rowFormData[2].options,this.rowForm)
      this.showEditAll = true
    },
   
    saveForm() {
      // console.log('要提交的表单', this.rowForm)
      this.loadingForm = true
      let rqMethod = this.isAdd ? addPath : updatePath
      let params = {...this.rowForm}
      params.pointIds = this.rowForm.pointIds.toString()
      rqMethod(params)
        .then((res) => {
          this.loadingForm = false
          this.$message.success('提交成功')
          this.showEditAll = false
          this.getInit()
        })
        .catch((error) => {
          this.loadingForm = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.pointIdsListClass {
  border: 1px solid yellow;
  width: 80%;
  margin: 0 auto;
}
</style>