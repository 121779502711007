<template>
  <div class="content">
    <el-radio-group class="top" v-model="activeTime">
      <el-radio v-for="(item,index) in timeList" :key="index" :label="item.value">{{item.label}}</el-radio>
    </el-radio-group>
    <div class="main">
      <ul class="list">
        <li v-for="(item,index) in list" :key="index">
          <span>第{{index+1}}次</span>
          <el-select v-model="item[key]" :placeholder="optionsObj[key].placeholder" v-for="(key,boxIndex) in Object.keys(optionsObj)" :key="boxIndex" class="selectBox" v-show="activeShowList.includes(key)">
            <el-option v-for="el_item in optionsObj[key].options" :key="el_item.value" :label="el_item.label" :value="el_item.value">
            </el-option>
          </el-select>
          <el-time-select class="timeBox" v-model="item.planTime" :picker-options="{ start: '00:00', step: '00:30',end: '23:30' }"  placeholder="选择时间" v-show="activeShowList.includes('planTime')">
          </el-time-select>
          <i class="el-icon-circle-plus iconBox" @click="addItem"></i>
          <i class="el-icon-remove iconBox" @click="removeItem(index)" v-show="list.length >1 "></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 执行频率的数组
    planTimeReqList: {
      type: Array,
      default() {
        return [{ season: '', month: '', day: '', week: '', planTime: '', }]
      }
    },
    // 执行频率的类型
    executionFrequency: {
      type: Number,
      default() {
        return 0
      }
    } 
  },
  data() {
    return {
      activeTime: 0,
      activeShowList: [],
      timeList: [
        { label: '日检', value: 0, show: ['planTime'] },
        { label: '周检', value: 1, show: ['week', 'planTime'] },
        // { label: '年检', value: 0, show: ['month', 'day', 'planTime'] },
        // { label: '季检', value: 1, show: ['season', 'month', 'day', 'planTime'] },
        // { label: '月检', value: 2, show: ['day', 'planTime'] },
      ],

      list: [
        // { season: '', month: '', day: '', week: '', time: '', }
      ],
      optionsObj: {
        season: { placeholder: '季度', options: [], },
        month: { placeholder: '月份', options: [] },
        day: { placeholder: '日', options: [] },
        week: { placeholder: '周', options: [] },
      },
    }
  },
  watch: {
    activeTime: {
      immediate: true,
      handler(newVal) {
        let item = this.timeList.find(v => v.value == newVal)
        this.activeShowList = item.show
        this.$emit('update:executionFrequency', newVal)
      }
    },
    list: {
      deep: true,
      handler(val) {
        this.$emit('update:planTimeReqList', val)
      }
    },
  },
  created() {
    this.activeTime = this.executionFrequency
    console.log('this.activeTime===',this.activeTime)
    this.dealOptions()
    this.list = this.planTimeReqList
  },
  methods: {
    dealOptions() {
      this.util.numberRange(1, 4, this.optionsObj.season, null, '季度', true)
      this.util.numberRange(1, 28, this.optionsObj.day, null, '日',)
      this.util.numberRange(1, 12, this.optionsObj.month, null, '月')
      this.util.numberRange(1, 7, this.optionsObj.week, '周', null, true)
      this.$set(this.optionsObj.week.options[6], 'label', "周日")
    },
    addItem() {
      this.list.push({ season: '', month: '', day: '', week: '', planTime: '', })
    },
    removeItem(index) {
      this.list.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .main {
    ul.list {
      margin-top: 10px;
      li {
        span {
          flex-shrink: 0;
        }
        .timeBox {
          margin-left: 8px;
          width: 120px;
        }
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .selectBox {
          width: 100px;
          margin-left: 8px;
        }
        .iconBox {
          font-size: 23px;
          margin-left: 10px;
          color: #606266;
          &:hover {
            cursor: pointer;
            color: #409eff;
          }
        }
      }
    }
  }
}
</style>