import http from './plugin/ajax'

// 通讯报装
export const rqMsgService = (data) => http.get('/routeInstall', { params: data })   // 查询 列表
export const getApprovalMsgService = (data) => http.put('/routeInstall/audit', data)   // 审批 一项

// 客户调查 - 题库
export const addQuestion = (data) => http.post('/question', data)   // 添加
export const editQuestion = (data) => http.put('/question', data)    // 修改
export const rqQuestion = (data) => http.post('/question/list', data)   // 查询 列表
export const delQuestion = (data) => http.delete('/question/' + data)   // 删除 一项

// 客户调查 - 问卷
export const add_Questionnaire = (data) => http.post('/questionnaire', data)   // 添加
export const edit_Questionnaire = (data) => http.put('/questionnaire', data)    // 修改
export const rq_Questionnaire = (data) => http.post('/questionnaire/list', data)   // 查询 列表
export const del_Questionnaire = (data) => http.delete('/questionnaire/' + data)   // 删除 一项

// 物品放行
export const rqTransire = (data) => http.post('/transire/list', data)   // 查询 列表
export const approveTransire = (data) => http.put('/transire', data)    // 修改

// 建议反馈
export const getProposalList = (data) => http.post('/mindex/listAll', data)   // 查询 列表
export const addFeedbackContent = (data) => http.put('//mindex', data)    // 回复

//延时经营
export const getExtendedOperationList = (data) => http.post('/deferred/list', data)   // 查询 列表
export const editExtendedOperation = (data) => http.put('/deferred', data)    // 修改
