import http from './plugin/ajax'
// 实时监控
//请求treeData
export const getTreeData = () => http.get('/monitorView/cameraTree')
//视频通道
export const monitorView = (data) => http.get('/monitorView', { params: data })

//视频巡更
//获取设备列表
export const getEquipmentList = (data) => http.get('/patrol/deviceList', { params: data })
//新增巡更组
export const addPatrolTeam = (data) => http.post('/patrol/add', data)
//编辑巡更组
export const editPatrolTeam = (data) => http.post('/patrol/upData', data)
//巡更组列表
export const patrolTeamList = (data) => http.post('/patrol/list', data)
//巡更组列表-分页
export const patrolTeamPage = (data) => http.post('/patrol/page', data)
// 视频巡更指派设备
export const patrolTeamView = (id, protocol) => http.get(`/patrol/views/${id}/${protocol}`)
//删除组
export const deletePatrolTeam = (data) => http.get(`/patrol/rem/${data}`)
//设置间隔时间
export const SetIntervalsQuery = (data) => http.get(`/patrol/setTime/${data}`)
//获取巡航时间
export const getCruiseTime = () => http.get('/sys/config/key/patrol_time')

//电子巡更
//巡更记录
export const getPatrolRecord = (data) => http.post('/patrol/line/record', data)
//巡更路线
export const getPatrolRoute = () => http.get('/patrol/line/list')
//更新路线图片
export const updataRouteUrl = (data) => http.post('/patrol/line/addImage', data)


// 客流分析
//人脸客流统计
export const getLists = (data) => http.post('/passenger/list', data)

// 人脸客流同环比
export const getDay = (data) => http.post('/passenger/list/day', data) //按日
export const getMoth = (data) => http.post('/passenger/list/moth', data) //按月
export const getHour = (data) => http.post('/passenger/list/hour', data) //按小时