<template>
  <p class="noDataText">{{text}}</p>
</template>

<script>
export default {
  name: 'noData',
  title: '暂无数据',
  props: ['text']
}
</script>

<style lang="scss" scoped>
@import "~@/style/mixin.scss";
p {
  font-size: $theme_fontsize_16;
  color: $theme_color_acacac;
  text-align: center;
  padding-top: 18%;
}
</style>
