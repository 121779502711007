<template>
  <transition name="fade">
    <div class="boxdia" v-if="dialogVisible" @click.self="closeDialog">
      <div class="dialog">
        <div class="dialog_title">
          {{ title }}
        </div>
        <div class="videobox" v-if="dialogVisible">
          <div id="videoer" width="100%" height="100%"></div>
        </div>
        <div class="btn" @click="closeDialog"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import PlayerManager from '@/api/module/icc/PlayerManager'
import { getCruiseTime } from '@/api'
export default {
  props: {
    title: {
      type: String,
      default: '巡更组标题名称'
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      videoList:[],
      groupSize: 4, // 每组的大小
      currentIndex: 0, // 当前组索引
      videoTimer:null,
      firstOpen:false
    }
  },
  computed: {
    groups() {
      const start = this.currentIndex * this.groupSize;
      const end = start + this.groupSize;
      let list = [...this.videoList]
      if(this.videoList.length <= 4){
        return this.videoList
      }
      return list.slice(start, end);
    },
  },
  methods: {
    init () {
      this.player = new PlayerManager({
        el: "videoer", /** 实时预览容器id，创建多个播放器，传入不同的容器id即可 **/
        pztEl: "ws-pan-tilt", // 云台控制容器id
        type: 'real', /** real - 实时预览  record - 录像回放 **/
        maxNum: 4, /** 最多显示的路数，可根据实际情况设置，可设置1 4 9 16 25等5种路数 **/
        num: 4, /** 初始化显示的路数 **/
        showControl: true, /** 默认是否显示工具栏 **/
        streamServerIp: process.env.VUE_APP_IP, // 流媒体服务 Ip 地址
        useNginxProxy: false, // 拉流模式 【true-代理模式拉流，false-直连拉流】
        receiveMessageFromWSPlayer: (methods, data) => {
          switch (methods) {
            case 'initializationCompleted':
              this.realPlay(this.groups)
              this.firstOpen = true
              break;
            case 'windowNumChanged': // 播放器显示的路数发生改变
              this.firstOpen = true
              // console.log(data, "返回显示的窗口数量")
              break;
            }
        }
      })
    },
    showDialog(i) {
      this.dialogVisible = true
      this.videoList = i
      this.$nextTick(async () => {
        this.init()
        console.log(this.firstOpen);
        if(this.firstOpen){
          this.realPlay(this.groups)
        }
        // i.forEach((item,index) => {
        //   if(index < 4){
        //   }
        // });
        if(this.videoList.length > 4){ //巡更组有4个视频以上才开启轮播
          let {data} = await getCruiseTime()
          let time = data.configValue
          this.videoTimer = setInterval(() => {
            if(this.player.playNum == 1){ //当打开某个通道后终止轮播
              clearInterval(this.videoTimer);
              return;
            }
            this.currentIndex++;
            if (this.currentIndex * this.groupSize >= this.videoList.length) {
              this.currentIndex = 0;
            }
            this.player.close()
            this.player.setSelectIndex(0);
            this.realPlay(this.groups)
          }, time * 1000 * 60); // 轮播时间切换一次
        }
      });
    },
    closeDialog() {
      this.dialogVisible = false
      clearInterval(this.videoTimer);
    },
    /* 播放 */
    realPlay (deviceNumber) {
      let channelList = []
      deviceNumber.forEach(item => {
        channelList.push({id:item.deviceNumber, "isOnline": 1})
      })
      const { player } = this
      player.playRealVideo({
        channelList: channelList
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.boxdia {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 100;
  .dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 1552px;
    height: 800px;
    padding: 150px 240px 0 240px;
    background-image: url('~@/assets/gd/ds_chartwindow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    .dialog_title {
      position: absolute;
      font-size: 22px;
      top: 112px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fff;
    }
    .videobox{
      width: 100%;
      height: 90%;
      margin: 10px;
      box-sizing: border-box;
      z-index: 10;
    }
    .btn {
      position: absolute;
      height: 44px;
      width: 38px;
      top: 60px;
      right: 120px;
      background-image: url('~@/assets/gd/ds_closebtn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 100;
    }
  }
}
</style>