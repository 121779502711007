<template>
  <div class="dswindow">
    <div class="electricvalvebox">
      <p>电动阀设置</p>
      <div class="electricvalveswitch">
        <span>开关:</span>
        <el-switch
          @change="electricvalveClick"
          v-model="electricvalveopen"
          active-color="#3AA1FF"
          inactive-color="#aaa"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 电动阀
      electricvalveopen: false,
    }
  },
  methods:{
    electricvalveClick(){
      if(this.electricvalveopen){
        // this.windowDom.src = 'http://121.37.251.55:8280/file/modelImage/ddf.gif'
      }else{
        // this.windowDom.src = 'http://121.37.251.55:8280/file/modelImage/ddf.png'
      }
    }
  },
  mounted() {
    this.$emit('loading')
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;
  .electricvalvebox {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('~@/assets/images/gd/video_bg.png');
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 30px;
    color: #fff;
    p {
      text-align: center;
      font-size: 24px;
    }
    span {
      width: 50px;
      font-size: 18px;
      line-height: 18px;
    }
    .electricvalveswitch {
      width: 80%;
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      align-items: center;
      .el-switch {
        margin-left: 10px;
      }
    }
  }
}
</style>