import http from './plugin/ajax'

// 內部车辆管理
export const addInternalVehicle = (data) => http.post('/throughCarInterior', data)
export const updateInternalVehicle = (data) => http.put('/throughCarInterior', data)
export const delInternalVehicle = (data) => http.delete('/throughCarInterior/' + data)
export const getInternalVehicleList = (data) => http.post('/throughCarInterior/list', data)

/**车辆统计 */
//查询车位
export const getParkingSpace = () => http.post('/sysCarStatistics/parkingNumber')
//车辆统计列表
export const getStatisticsList = (data) => http.post('/sysCarStatistics/list', data)

/**车辆通行记录 */
//车辆通行记录列表
export const getVehicleTrafficList = (data) => http.post('/sysVehicleRecord/list', data)
