<template>
  <!-- 表格 -->
  <div class="content">
    <title-box title="本月告警类型分析" />
    <title-top title="本月告警总数" subTitle="园区告警类型总数分析" num="0"/>
    <div class="echart" v-if="options">
      <ja-init-chart :options="options" />
    </div>
  </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'

export default {
  components: {
    JaInitChart, TitleBox, titleTop
  },
  data() {
    return {
      options: null
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      let initData = [
        // { value: 3, name: '设备告警' },
        // { value: 2, name: '人员黑名单告警' },
        // { value: 13, name: '温度异常告警' },
        // { value: 1, name: '消防告警' },
        // { value: 9, name: '环境异常告警' },
        // { value: 9, name: '其它告警' },
      ]
      this.options = {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: 'Area Mode',
            type: 'pie',
            avoidLabelOverlap: false,
            radius: ['20%', '40%'],
            center: ['50%', '30%'],
            // roseType: 'area',
            itemStyle: {
              borderRadius: 5
            },
            colorBy: 'data',
            label: {
              fontSize: this.util.setFontSize(15, 1920),
              formatter: '{b}({c})',
            },
            labelLine: {
              length: this.util.setFontSize(15, 1920),
              length2: this.util.setFontSize(20, 1920),
              maxSurfaceAngle: this.util.setFontSize(80, 1920),
            },

            data: initData
          }
        ]

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
}
.echart {
  height: 100%;
  width: 100%;
}
</style>