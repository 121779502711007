<template>
    <!-- 表格 -->
    <div class="content">
        <title-box title="月度能耗统计分析"
                   :isRight="true">
            <template #right>
                <div class="selections">
                    <el-select v-model="activeYear"
                               placeholder="年份"
                               @change="selectYear"
                               class="selectBox">
                        <el-option v-for="item in years"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="activeType"
                               placeholder="类型"
                               @change="selectType"
                               class="selectBox typeSelect">
                        <el-option v-for="item in typeList"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </template>
        </title-box>
        <div class="echart"
             v-if="options">
            <ja-init-chart :options="options"
                           ref="jaChart" />
        </div>
    </div>
</template>
<script>
import JaInitChart from "@/components/ja-init-chart/index"
import TitleBox from './common/TitleBox.vue'
import titleTop from './common/titleTop.vue'
import { getUsageStatistics,getThreeStatistics } from '@/api'
export default {
    components: {
        JaInitChart, TitleBox, titleTop
    },
    data() {
        return {
            options: null,
            // 年份
            activeYear: '',
            years: [],
            // 类型
            activeType: '1',
            typeList: [
                { label: '电', value: '1' },
                { label: '水', value: '2' },
                { label: '冷', value: '3' },
            ],
            ydata: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
            electricityUsage:{
                year:[],
                lastyear:[]
            },
            waterUsage:{
                year:[],
                lastyear:[]
            },
            coolUsage:{
                year:[],
                lastyear:[]
            }
        }
    },
    created() {
        let currentYear = new Date().getFullYear()
        this.activeYear = currentYear
        let yearRange = [];
        //年份可选范围为2023至当前年
        for (let year = 2023; year <= currentYear; year++) {
            yearRange.push({ label: year, value: year });
        }
        this.years = yearRange
        this.getInit()
    },
    methods: {
        async getInit() {
            let {data:res} = await getThreeStatistics({year:this.activeYear,lastYear:this.activeYear-1})
            res.electricityUsage.map(item => {
                this.electricityUsage.year.push(Number(item).toFixed(2))
            })
            res.lastYearElectricityUsage.map(item => {
                this.electricityUsage.lastyear.push(Number(item).toFixed(2))
            })
            res.waterUsage.map(item => {
                this.waterUsage.year.push(Number(item).toFixed(2))
            })
            res.lastYearWaterUsage.map(item => {
                this.waterUsage.lastyear.push(Number(item).toFixed(2))
            })
            res.coolUsage.map(item => {
                this.coolUsage.year.push(Number(item).toFixed(2))
            })
            res.lastYearCoolUsage.map(item => {
                this.coolUsage.lastyear.push(Number(item).toFixed(2))
            })
            this.setOptions()
        },
        selectYear(val) {
            this.activeYear = val
            this.getInit()
        },
        selectType(val) {
            this.activeType = val
            this.setOptions()
        },
        async setOptions() {
            const activeYear = this.activeYear
            let legendData = []
            let thisData = []
            let lastData = []
            let unit = ''
            switch(this.activeType){
                case '1':
                    unit = 'kW·h'
                    legendData = ['当月电耗', '去年同月电耗']
                    thisData = this.electricityUsage.year
                    lastData = this.electricityUsage.lastyear
                    break;
                case '2':
                    unit = '(m³)'
                    legendData = ['当月水耗', '去年同月水耗']
                    thisData = this.waterUsage.year
                    lastData = this.waterUsage.lastyear
                    break;
                case '3':
                    unit = 'kW·h'
                    legendData = ['当月冷量', '去年同月冷量']
                    thisData = this.coolUsage.year
                    lastData = this.coolUsage.lastyear
                    break;
            }
            this.options = {
                color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        },
                        lineStyle: {
                            type: "dashed"
                        },
                    },
                    formatter: function (params) {
                        let str = activeYear == 'all' ? '全部' : `${activeYear}年${params[0].axisValueLabel}<br/>`;
                        params.forEach((item, idx) => {
                            if (idx < params.length ) {
                                 switch(this.activeType){
                                    case '1':
                                        str += `${item.marker} ${item.seriesName}: ${Number(item.data).toFixed(2)}  kwh<br/>`
                                        break;
                                    case '2':
                                        str += `${item.marker} ${item.seriesName}: ${Number(item.data).toFixed(2)}  (m³)<br/>`
                                        break;
                                    case '3':
                                        str += `${item.marker} ${item.seriesName}: ${Number(item.data).toFixed(2)}  kwh<br/>`
                                        break;
                                }
                            }
                        })
                        // let length = params[0].dataIndex - 1
                        // if (length < 0) {
                        //     str += `较上月环比：${(params[0].value - (this.oldYdata[this.oldYdata.length - 1])).toFixed(2)} ${this.activeType == 2 ? '度' : '(m³)'}<br/>`
                        // } else {

                        //     str += `较上月环比：${(params[0].value - (this.ydata[0][length])).toFixed(2)} ${this.activeType == 2 ? '度' : '(m³)'}<br/>`
                        // }
                        return str
                    }.bind(this)
                },
                legend: {
                    top: '5%',
                    itemGap: this.util.setFontSize(30, 1920),
                    textStyle: { //图例文字的样式
                        fontSize: this.util.setFontSize(15, 1920),
                    },
                    data: legendData,
                },
                grid: {
                    top: '18%',
                    bottom: '28%',
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.ydata,
                        axisPointer: {
                            type: 'none',
                        },
                        offset: this.util.setFontSize(8, 1920),
                        axisLabel: {
                            fontSize: this.util.setFontSize(15, 1920),
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: unit,
                        axisTick: {
                            show: false,
                        },
                        axisPointer: {
                            label: {
                                show: true,
                            }
                        },
                        nameGap: 20,
                        nameTextStyle: {
                            fontSize: this.util.setFontSize(16, 1920),
                        },
                        min: 0,
                        // max: this.yMax,
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            fontSize: this.util.setFontSize(14, 1920),
                        },
                    }
                ],
                series: [
                    {
                        name: legendData[0],
                        type: 'bar',
                        activeYear: activeYear,
                        data: thisData
                    },
                    {
                        name: legendData[1],
                        type: 'bar',
                        data: lastData
                    },
                ]
            }
            //添加一个新的刻度线 实现折线在柱状图的中间显示
            this.options.xAxis[1] = {
                type: 'value',
                max: this.options.xAxis[0].data.length,
                show: false,
                axisPointer: {
                    label: {
                        show: false,
                    }
                },
            }
            // this.options.series[2].data = this.options.series[2].data.map((x, i) => [(0.25 + i), x])
            this.$nextTick(() => {
                this.$refs.jaChart.drawInit(this.options)
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.content {
    overflow: hidden;
    border-radius: 20px;
    width: 100%;
    height: 550px;
    background: white;
    overflow: hidden;
    .selections {
        .selectBox {
            width: 120px;
        }
        .typeSelect {
            margin-left: 20px;
        }
    }
}
.echart {
    height: 100%;
    width: 100%;
}
</style>