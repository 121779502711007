<template>
    <el-dialog :visible.sync="show" width="40%" title="查看人员信息">
        <ja-form :rules="rules" allDisabled v-model="rowForm" :formArray="rowFormData" v-if="show" :editDataFormat="editDataFormat">
        </ja-form>
    </el-dialog>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
    components: {
        JaForm,
    },
    data () {
        return {
            rules: {},
            rowFormData: [
                { input_type: 'input', label: '登录用户名', key: 'loginName', disabled: true },
                {
                    input_type: 'select', label: '所属公司', key: 'companyId', options: [],
                    relevance: {
                        sonKey: 'deptId', This: this, type: "select", rqParam: true, // 必填。而 rqParam 是异步请求，必填字段
                        rqParam: { rqUrl: "/department", rqData: () => { return { params: { pageIndex: 1, pageSize: 200, companyId: this.rowForm.companyId } } }, labelName: 'deptName', valueName: 'id' },
                    }
                },
                { input_type: 'select', label: '所属部门', key: 'deptId', options: [] },
                { input_type: 'input', label: '昵称', key: 'username' },
                { input_type: 'input', label: '工号', key: 'jobNum', styleWidth: 'auto' },
                { input_type: 'input', label: '手机号', key: 'mobile', styleWidth: 'auto' },
                { input_type: 'input', label: '职位', key: 'positionName' },
                { input_type: 'input', label: '性别', key: 'gender' },
                { input_type: 'input', label: '人员类型', key: 'employeeType' },
                { input_type: 'input', label: '邮箱', key: 'email' },
                { input_type: 'input', label: '状态', key: 'status' },
                { input_type: 'input', label: '描述', key: 'remark', type: 'textarea', col: 22, maxlength: 200 }
            ],
            show: false,
            titleText: '新增',
            rowForm: {},
        }
    },
    mounted () {
        let index = this.rowFormData.findIndex(item => item.key == 'companyId')
        const paramObj = { params: { pageIndex: 1, pageSize: 200 } }
        this.util.rqOptionsList({ who: [this.rowFormData[index]], rqUrl: "/company", rqData: paramObj, rqMethod: 'get', labelName: 'companyName', valueName: 'id' }) // 所属公司
    },
    methods: {
        init (row) {
            if (row && row.companyId) {
                let index = this.rowFormData.findIndex(item => item.key == 'deptId')
                const paramObj = { params: { pageIndex: 1, pageSize: 200, companyId: row.companyId } }
                this.util.rqOptionsList({ who: [this.rowFormData[index]], rqUrl: "/department", rqData: paramObj, rqMethod: 'get', labelName: 'deptName', valueName: 'id' }) // 所属公司
            }
            this.show = true
            this.rowForm = { ...row }
            this.rowForm.gender = ['女', '男'][this.rowForm.gender]
            this.rowForm.status = ['正常', '停用'][this.rowForm.status]
            this.rowForm.employeeType = ['内部人员', '外部人员'][this.rowForm.input]
        }
    }
}
</script>
