<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="详情" :width="`45%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='10' v-if="showDetail">
        <template #faceImg="">
          <div class="BigBox">
            <img :src="detailData.faceImg" alt="" srcset="">
          </div>
        </template>
        <template #qpcode="">
          <div class="BigBox">
            <img :src="detailData.qrUrl" alt="" srcset="">
          </div>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      showDetail: false,
      detailOptions: [
        { key: 'visitRecordId', label: '访客记录ID', },
        { key: 'personId', label: '访客ID', },
        { key: 'name', label: '访客姓名', },
        { key: 'phone', label: '手机号码', },
        { key: 'gender', label: '访客性别', },
        { key: 'company', label: '访客公司', },
        { key: 'visitReason', label: '来访事由', },
        // { key: 'visitType', label: '访问来源', },
        { key: 'visitType', label: '访问类型', },
        { key: 'visitStaff', label: '被访人ID', },
        { key: 'staffName', label: '被访人姓名', },
        { key: 'unit', label: '访问单元', },
        { key: 'staffCompany', label: '被访公司', },
        { key: 'location', label: '访问地址', },
        { key: 'arrivedTime', label: '到访时间', },
        { key: 'icCard', label: 'IC卡号', },
        { key: 'status', label: '访客状态'},
        { label: '照片', type: 'customize', customize: 'faceImg', alignItems: 'flex-start' },
        { label: '通行二维码', type: 'customize', customize: 'qpcode', alignItems: 'flex-start' },
      ],
      detailData: {},
      initFileList: []
    }
  },
  methods: {
    rowDetail(row) {
      // console.log('查看详情---',row,)
      this.detailData = { ...row }
      this.showDetail = true
    },
  }
}
</script>
<style lang="scss" scoped>
.BigBox {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  .right {
    margin-left: 20px;
  }
  img {
    height: 120px;
    display: flex;
  }
}
</style>