<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset" @rowDetail="normalEvent">
      <template #img='{row}'>
        <el-image :preview-src-list="[row.snapshotImg]" fit="contain" class="cellImg" :src="row.snapshotImg" alt="" v-if="row.snapshotImg ">
          <div slot="error" class="errorImg el-image__error">
            加载失败
          </div>
        </el-image>
      </template>
    </ja-table>

     <detail ref="detail" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import { getVisitorPassRecord ,companyList} from '@/api'
import Detail from './Detail.vue'
export default {
  title: "通行记录",
  components: {
    Detail
  },
  mixins: [table],
  name: 'index',
  data() {
    return {
      conditionData: [
        { input_type: 'date', type: 'daterange', key: 'time', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd' },
        { input_type: 'select', key: 'companyId', attribute: { placeholder: '公司名称', }, options: [] },
        { input_type: 'select', key: 'personType', attribute: { placeholder: '人员类型'} ,options: [{label:"员工",value:"员工"},{label:"访客",value:"访客"}] },
        { input_type: 'input', key: 'search', attribute: { placeholder: '人员姓名', } },

        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset', },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas2, rqUrl: '/person/access/export', rqMethod: 'post' }, limit: 'traffic::accessRecord::export' }
      ],
      columns: [
        { label: '姓名', prop: 'name' },
        { label: '人员类型', prop: 'personType', attribute: { showOverflowTooltip: false, } },
        { label: '所属公司', prop: 'companyName', width: 180, attribute: { showOverflowTooltip: false, } },
        { label: '性别', prop: 'gender', width: 150, attribute: { showOverflowTooltip: false, } },
        { label: '设备名称', prop: 'equipmentName', attribute: { showOverflowTooltip: false, } },
        { label: '通行类型', prop: 'accessType', },
        { label: '通行事件', prop: 'accessEvents', },
        { label: '通行时间', prop: 'accessTime', width: 160, },
        { label: '通行照片', prop: 'snapshotImg', width: 100, slotName: 'img' },
        {
          label: '操作', input_type: 'button', width: 120,
          list: [
            { text: '详情', method: 'rowDetail', limit: 'traffic::accessRecord::detail'},
          ]
        }
      ],
      tableData: []
    }
  },

  mounted(){
     companyList().then(res=>{
        this.conditionData[1].options =  res.data.map( element => {return { value: element.companyId, label: element.companyName }});
      })
  },
  methods: {
    rqParmas2() {
      let obj = this.rqParmas()
      if (obj.time) {
        obj.startTime = obj.time[0]
        obj.endTime = obj.time[1]
        delete obj.time
      }
      return obj
    },
    getInit() {
      this.loading = true
      let obj = this.rqParmas2()
      getVisitorPassRecord(obj).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .cellImg {
  display: flex;
  margin: auto;
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
::v-deep .errorImg {
  width: 100%;
}
</style>