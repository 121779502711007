// 维修工单、维保工单、告警工单  ===== 不一样的地方
// import { assignRepairOrder, rqRepairOrderItem } from '@/api'
// 获取详情
import { rqRepairOrderItem, rqInspectOrderItem ,rqScanOrderItem} from '@/api'
// import { exportMeter } from '@/api'

export default {
  // 维修
  repair: {
    value: 0,
    // // 接口
    // getInit_fun: getRepairOrderList, // 请求表格 
    // export_url: '/wc/toiler/export',   // 导出表格   
    // assignOrder_fun: assignRepairOrder,   // 指派 
    // turnOrder_fun: exportMeter, // 转单 
    // resOrder_fun: exportMeter, // 结单 
    // resetOrder_fun: exportMeter, // 重启 
    rqDetail: rqRepairOrderItem, //详情

    // 权限控制
    limitObj: {
      '派单': 'workOrderManage::repair::assign',
      '详情': 'workOrderManage::repair::detail',
      '转单': 'workOrderManage::repair::reassignment',
      '结单': 'workOrderManage::repair::statement',
      '重启': 'workOrderManage::repair::restart',
      '审核': 'workOrderManage::repair::examine',
    }
  },

  // 维保
  inspection: {
    value: 2,
    rqDetail: rqInspectOrderItem, //详情
    limitObj: {
      '派单': 'workOrderManage::inspection::assign',
      '详情': 'workOrderManage::inspection::detail',
      '转单': 'workOrderManage::inspection::reassignment',
      '结单': 'workOrderManage::inspection::statement',
      '重启': 'workOrderManage::inspection::restart',
      '审核': 'workOrderManage::inspection::examine',
    }
  },

  // 告警
  alarm: {
    value: 1,
    limitObj: {
      '派单': 'workOrderManage::alarm::assign',
      '详情': 'workOrderManage::alarm::detail',
      '转单': 'workOrderManage::alarm::reassignment',
      '结单': 'workOrderManage::alarm::statement',
      '重启': 'workOrderManage::alarm::restart',
      '审核': 'workOrderManage::alarm::examine',
    }
  },
   // 维保
   scan: {
    value: 3,
    rqDetail: rqScanOrderItem, //详情
    limitObj: {
      '派单': 'workOrderManage::scan::assign',
      '详情': 'workOrderManage::scan::detail',
      '转单': 'workOrderManage::scan::reassignment',
      '结单': 'workOrderManage::scan::statement',
      '重启': 'workOrderManage::scan::restart',
      '审核': 'workOrderManage::scan::examine',
    }
  },
}