<template>
  <div class="bg box_bg">
    <!-- <div class="chartbtn">
      <div
        :class="chartIndex == index ? 'selected' : ''"
        @click="checkChart(index)"
        v-for="(item, index) in chartBtn"
      >
        {{ item.title }}
      </div>
    </div> -->
    <WPanelEntry
      title="车辆进出情况分析"
      :selectData="false"
      v-loading="loading"
      element-loading-text="页面加载中,请稍等..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <slot>
        <div class="charts" @click="openDialog">
            <ja-init-chart class="chart" :options="options" />
        </div>
      </slot>
    </WPanelEntry>

    <chartDialog
      ref="dialog"
      :title="title"
      :conditionData="conditionData"
      :columns="columns"
      :getDatamethod="getVehicleTrafficList"
      :systemurl="systemurl"
      :searchType="'day'"
      tablename='车辆通行记录'
    >
      <!-- 传递图表给弹窗 -->
      <template v-slot:chart>
        <ja-init-chart
          class="jachart"
          :options="options"
          v-if="!noDataText"
          @getMaxY="getMaxY"
        />
      </template>
    </chartDialog>
  </div>
</template>

<script>
import chartcar from '@/assets/gd/ds_chartcarbg.png'
import { getVehicleTrafficList, vehicleStatistics} from '@/api'
import WPanelEntry from '../../WPanelEntry'
import JaInitChart from '@/components/ja-init-chart/index'
import NoData from '@/components/noData'
import chartDialog from '../../chartDialog/index.vue'
export default {
  title: '车辆进出情况分析',
  name: 'vehicleAnalysis',
  components: {
    JaInitChart,
    NoData,
    WPanelEntry,
    chartDialog
  },
  data() {
    return {
      chartcar, //背景图
      options: {},
      loading: false,
      chartIndex: 0,
      chartBtn: [{ title: '入场' }, { title: '出场' }],
      inData: [],
      outData: [],
      echartData: [],
      dateTime:[],
      title: '车辆进出情况分析',
      noDataText:'',
      systemurl: '/home/passModule/passRecord',
      getVehicleTrafficList,
      MaxY: '',
      conditionData: [
        {
          input_type: 'date',
          type: 'daterange',
          value: 'time',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          input_type: 'input',
          key: 'deviceCode',
          lable: '设备识别码',
          attribute: {
            placeholder: '设备识别码',
            size: 'small'
          }
        },
        { normalBtn: 'search' }
      ],
      columns: [
        { label: '车牌号码', prop: 'carNumber' },
        { label: '停车场', prop: 'intime' },
        { label: '停车类型', prop: 'intime' },
        { label: '停车时长(分)', prop: 'stopTime' }
      ],
      dataInterval:null
    }
  },
  computed: {
    maxData() {
      let bgData = []
      for (let i = 0; i < 6; i++) {
        bgData.push(this.inData[i] + this.outData[i])
      }
      let max = Math.max(...bgData)
      max = Math.ceil(max / 10) * 11
      let arr = []
      bgData.forEach((item) => {
        arr.push(max)
      })
      return arr
    }
  },
  created() {
    this.startDataInterval()
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
  },
  methods: {
    startDataInterval(){
      this.init()
      this.dataInterval = setInterval(() => {
            this.init()
      }, this.TIMER_INTERVAL);
    },
    async init() {
      let {data:res} = await vehicleStatistics({})
      this.dateTime = res.time
      this.inData = res.entryData
      this.outData = res.exitData
      let bgData = []
      for (let i = 0; i < 6; i++) {
        bgData.push(this.inData[i] + this.outData[i])
      }
      this.options = {
        tooltip: {
          show: true,
          type: 'item',
          position: function (point, params, dom, rect, size) {
            return [point[0] - 30, point[1] - 70] //返回x、y（横向、纵向）两个点的位置
          },
          backgroundColor: 'transparent',
          formatter: function (params) {
            if (params.data.tooltip != 'none') {
              let str = `<div style = "font-size: 16px;background-image:url(${require('../../../../../assets/images/screen/echarts_popup.png')});background-size: 100% 100%;width: 123px;height: 58px;">
              <div style = "width: 70%;margin: 0 auto;">${params.name}<br/> ${
                params.seriesName
              }: ${params.data}</div></div>`
              return str
            } else {
              return '' // 返回空字符串，禁用提示框
            }
          }
        },
        // legend: {
        //   data: ['入场', '出场'],
        //   top: '7%',
        //   left: '70%',
        //   itemWidth: 19,
        //   itemHeight: 12,
        //   textStyle: {
        //     color: '#01CDFD',
        //     fontSize: 12
        //   }
        // },
        xAxis: {
          axisTick: {
            show: false
          },
          data: this.dateTime,
          splitLine: { show: false },
          splitArea: { show: false },
          axisLine: {
            lineStyle: {
              color: '#1A3E47'
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 12
          }
        },
        yAxis: {
          axisTick: {
            show: true,
            lineStyle: {
              color: '#4A957F'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#818a98',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: '#999', // 分隔线的颜色
              width: 0 // 分隔线的宽度
            }
          },
          max:this.maxData[0]
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '1%',
          height: '80%',
          containLabel: true
        },
        series: [
          {
            // 背景色
            name: '入场',
            type: 'bar',
            stack: 'total',
            // symbol: 'fixed',
            // symbolSize: [15, 5],
            // symbolMargin: 2,
            // symbolRepeat: 'repeat',
            barCategoryGap: '75%',
            data: this.inData,
            itemStyle: {
              normal: {
                color: '#00A5FF'
              }
            }
          },
          {
            // 背景色
            name: '出场',
            type: 'bar',
            stack: 'total',
            // symbol: 'fixed',
            // symbolSize: [15, 5],
            // symbolMargin: 2,
            // symbolRepeat: 'repeat',
            barCategoryGap: '75%',
            data: this.outData,
            itemStyle: {
              normal: {
                color: '#D8FF23'
              }
            }
          },
          {
            name: '占位',
            tooltip: 'none',
            type: 'pictorialBar',
            barWidth: '40%',
            barGap: '-100%',
            silent: true,
            symbol: 'image://' + this.chartcar,
            // symbolRepeat: 'fixed',
            symbolClip: true,
            symbolSize: [50, '100%'],
            data: this.maxData
          },
          {
            //分隔
            tooltip: ' ',
            type: 'pictorialBar',
            itemStyle: {
              color: '#295C71'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 3,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [16, 2],
            symbolPosition: 'start',
            symbolOffset: [0, 0],
            data: bgData,
            z: 10
          }
        ]
      }
    },
    handle(i, item) {
      this.active1 = i
      this.date = item.id
      this.init()
    },
    checkChart(i) {
      this.chartIndex = i
      switch (i) {
        case 0:
          this.echartData = this.inData
          this.init()
          break
        case 1:
          this.echartData = this.outData
          this.init()
          break
      }
    },
    openDialog() {
      this.$refs.dialog.showDialog()
    },
    getMaxY(i) {
      this.MaxY = i
    }
  },
}
</script>
<style lang="scss" scoped>
.charts {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.box_bg {
  // background-image: url("~@/assets/images/screen/office_box3.png");
  position: relative;
  .chartbtn {
    position: absolute;
    top: 17px;
    right: 15px;
    display: flex;
    z-index: 20;
    div {
      width: 66px;
      height: 24px;
      border: none;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      background-image: url('~@/assets/gd/ds_chatbtn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    div:hover {
      cursor: pointer;
      background-image: url('~@/assets/gd/ds_chatbtnx.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    div.selected {
      background-image: url('~@/assets/gd/ds_chatbtnx.png');
    }
  }
}
// .entry {
.personStat_title {
  display: inline-flex;
  color: #2eb5ee;
  font-size: 16px;
  // padding-top: 20px;
  width: 90%;
  margin: 0 auto;
  justify-content: flex-end;
  li {
    span {
      display: inline-block;
      width: 30px;
      background: #19236c;
      text-align: center;
      margin-right: 8px;
      height: 30px;
      border-radius: 4px;
      line-height: 28px;
      font-size: 14px;
      cursor: pointer;
    }
    .curr {
      background: #226ca3;
      color: #f8fdff;
    }
  }
}

// }
</style>

