<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @lookRoom="lookRoom"
                  @importForm="normalEvent({ref:'importForm',method:'importForm', url: '/files/装修验收记录导入模板.xlsx', filename: '装修验收记录导入模板', uploadURL: '/decSignInRecord/upload'})">
        </ja-table>
        <show-details ref="detailRefs"></show-details>
        <import-form ref='importForm'
                     @openResult="openResult" />
        <check-result ref="checkResult"
                      :results="results" />
    </div>
</template>

<script>
import { getDecSignInRecordList } from '@/api'
import table from '@/views/mixins/table'
import checkResult from '@/views/home/components/common/checkResult.vue'
import importForm from '@/views/home/components/common/importForm.vue'
import showDetails from './components/showDetails.vue'
export default {
    title: '装修验收记录',
    mixins: [table],
    name: 'acceptanceRecord',
    components: {
        showDetails,
        checkResult,
        importForm
    },
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
                { normalBtn: 'import', text: '导入', limit: 'renovation::acceptanceRecord::import' }
            ],
            columns: [
                // 表头数据
                { label: '所属楼栋', prop: 'buildingName' },
                { label: '所属楼层', prop: 'floorName' },
                { label: '所属租赁单元', prop: 'roomName' },
                { label: '施工单位', prop: 'companyName' },
                { label: '施工负责人', prop: 'decPerson' },
                { label: '负责人手机号码', prop: 'decPhone' },
                { label: '验收人员', prop: 'signInPerson' },
                { label: '验收时间', prop: 'createTime' },
                { label: '验收内容', prop: 'remark' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'renovation::acceptanceRecord::show' },
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30,

        }
    },
    methods: {
        getInit() {
            getDecSignInRecordList({ ...this.filterData, submitStatus: 1 })
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })
        },
        lookRoom(row) {
            this.$refs.detailRefs.init(row)
        },
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
    }
}
</script>

<style scoped>
.download {
    width: 5vw;
}
</style>

