<template>
    <div class="allwh">
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :editDataFormat="editDataFormat"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :showIndex="true"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @rowEdit="normalEvent"
                  @rowDetail="normalEvent">
        </ja-table>

        <!-- 新增/编辑 -->
        <add-edit ref="addEdit" />
    </div>
</template>
<script>
import { getProposalList} from '@/api'
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit.vue'

export default {
    title: '建议反馈',
    mixins: [table,],
    name: 'index',
    components: {
        AddEdit
    },
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'introducerEmployeeName', attribute: { placeholder: '反馈人', size: 'small' } },
                { input_type: 'select', key: 'feedbackDirection', attribute: { placeholder: '建议反馈方向', size: 'small' }, options: [], },
                { input_type: 'date', type: 'daterange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd', attribute: { 'unlink-panels': true } },
                { input_type: 'select', key: 'statusType', attribute: { placeholder: '回复状态', size: 'small' }, options: [{ label: '等待回复', value: 0 }, { label: '已回复', value: 1 },], },
                { normalBtn: 'search', },
                { normalBtn: 'reset' },
            ],
            columns: [
                { label: '事件编号', prop: 'complaintNumber' },
                { label: '反馈人', prop: 'introducerEmployeeName' },
                { label: '反馈人联系方式', prop: 'introducerEmployeePhone', width: 140 },
                { label: '建议反馈方向', prop: 'feedbackDirectionName', width: 140 },
                { label: '建议反馈描述', prop: 'complaintsContent', width: 180 },
                { label: '提交时间', prop: 'complaintsTime', width: 140 },
                { label: '回复状态', prop: 'statusValue', },

                { label: '回复人', prop: 'feedbackEmployeeName', },
                { label: '回复方式', prop: 'replyWayName' },

                { label: '回复时间', prop: 'updateTime', width: 140 },
                {
                    label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
                    list: [
                        { text: '查看', method: 'rowDetail', limit: 'propertyServices::InvestmentSupervision::rowDetail' },
                        { text: '回复', method: 'rowEdit', limit: 'propertyServices::InvestmentSupervision::rowEdit' },
                    ]
                }
            ],
            tableData: []
        }
    },
    mounted() {
        let length = this.conditionData.findIndex(item => item.key == 'feedbackDirection')
        this.util.rqOptionsList({
            who: [this.conditionData[length]],
            rqUrl: '/sys/dictData/feedback_direction_type',
            labelName: 'dictLabel',
            valueName: 'dictValue',
            resRule: 'data'
        })
    },
    methods: {
        rqParmas() {
            let obj = { ...this.filterData }
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            delete obj.time
            return obj
        },
        getInit() {
            let data = this.rqParmas()
            getProposalList(data).then(res => {
                this.total = res.data.total
                this.tableData = res.data.data
                this.loading = false
            })

        },
        editDataFormat(row, btn) {
            if ((row.statusType === 0 && btn.text == '回复') || (row.statusType === 1 && btn.text == '查看')) {
                return true
            }
        },
    }
}
</script>
