<template>
    <div class="allwh">
        <ja-table ref="tst" 
            v-model="filterData"
            :conditionData="conditionData"
            :columns="columns"
            :tableData="tableData"
            :total="total"
            :loading="loading"
            :showIndex="true"
            :btnTextFormat="btnTextFormat"
            @getInit="getInit"
            @rowStart="rowStart"
            @rowEdit="rowEdit"
            @addEdit="addEdit"
            @rowDetail="rowDetail"
            @search="search"
            @reset="reset">
        </ja-table>
        <add-edit ref="addEdit" />
    </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './components/AddEdit.vue'
import { getNoticeList, openNotice } from '@/api'
export default {
    title: '公告通知',
    mixins: [table,],
    components:{
        AddEdit
    },
    name: 'noticeManger',
    data() {
        return {
            conditionData: [
                { input_type: 'date', type: 'datetimerange', value: 'time', startPlaceholder: '开始日期', endPlaceholder: '结束日期', valueFormat: 'yyyy-MM-dd HH:mm' },
                {
                    input_type: 'select',
                    key: 'location',
                    attribute: { placeholder: '公告位置', size: 'small' },
                    options: [
                        { label: '内部', value: '1' },
                        { label: '租户', value: '2' },
                        { label: '访客', value: '3' }
                    ],
                },
                { normalBtn: 'search', limit: '' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: '' ,method:'addEdit'},
                // default: view
                // noticeManger:: notice:: add
            ],
            columns: [
                { label: '标题', prop: 'title' },
                { label: '公告内容', prop: 'content', },
                { label: '公告位置', prop: 'locationName' },
                { label: '公告时效', prop: 'startAndEndTime' },
                { label: '创建人', prop: 'createBy' },
                {
                    label: '操作', input_type: 'button', width: 140, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
                    list: [
                        { text: '启用', method: 'rowStart', Format:true, limit: '' },
                        { text: '编辑', method: 'rowEdit', limit: '' },
                        { text: '详情', method: 'rowDetail', limit: '' },
                        // noticeManger:: notice:: start
                        // noticeManger:: notice:: edit
                        // noticeManger:: notice:: detail
                    ]
                }
            ],
        }
    },
    methods: {
        btnTextFormat(row, btn) {
            if (row.status == 0) {
                return '启用'
            } else if (row.status == 1) {
                return '停用'
            } else {
                return btn.text
            }
        },
        rqParmas() {
            let obj = { ...this.filterData }
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        getInit() {
            this.loading = false
            let data = this.rqParmas()
            getNoticeList(data).then(res => {
                this.loading = false
                this.tableData = res.data.data
                this.tableData.forEach(item => {
                    item.time = []
                    if(item.startTime || item.endTime){
                        item.time[0] = item.startTime
                        item.time[1] = item.endTime
                        item.timer = item.time[0] + '~' + item.time[1]
                    }
                    item.locationName = ''
                    item.location.forEach(i => {
                        if(i == 1){
                            item.locationName += '内部 '
                        }else if(i == 2){
                            item.locationName += '租户 '
                        }else{
                            item.locationName += '访客 '
                        }
                    })
                   
                })
                this.total = res.data.total
            })
        },
        // 删除
        rowDelete(row) {
            // this.$confirm('是否确认删除计划？', '删除', { type: 'warning' }).then(res => {
            //     delInspection(row.id).then(res => {
            //         this.$message.success("已删除")
            //         this.getInit()
            //     })
            // })
        },
        // 添加
        addEdit(){
            this.$refs.addEdit.append()
        },
        // 详情
        rowDetail(row){
            this.$refs.addEdit.rowDetail(row)
        },
        // 编辑
        rowEdit(row){
            if(row.status == 1){
                this.$message.error('启用状态下不能编辑')
                return false
            }
            this.$refs.addEdit.rowEdit(row)
        },
        // 启用/停用
        async rowStart(row){
            let timenum = Date.parse(row.endTime)
            let thistime = Date.now()
            if(thistime > timenum){
                this.$message.warning('请先修改公告时效')
                return false
            }
            let {data:res} = await openNotice({ id: row.id, status: row.status == 0 ? 1 : 0 })
            this.$message.success(res)
            this.getInit()
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .cellImg {
    display: flex;
    margin: auto;

    >img {
        max-height: 23px;
        width: 35px;
        display: flex;
        margin: auto;
    }
}

/deep/ .errorImg {
    width: 100%;
}
</style>