<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">今日告警</div>
    </div>

    <div class="charts">
      <ja-init-chart class="jachart" :options="options" />
      <div class="chartimg"></div>
      <ul>
        <li v-for="(item,index) in deviceInfoList" :key="index">
          <span>{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getHvacsecurity } from '@/api'
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  name: 'temperature',
  components: {
    JaInitChart
  },
  computed: {
    ...mapState({
      buildingFloor: (state) => state.visualsystem.buildingFloor
    })
  },
  watch: {
    buildingFloor(newVal) {
      this.init(newVal)
    }
  },
  data() {
    return {
      deviceInfoList: [],
      options: {}
    }
  },
  created() {
    // this.init()
  },
  methods: {
    async init(i) {
      let {data:res} = await getHvacsecurity({
        buildingId: i.buildingId,
        floorId: i.floorId
      })

      let List = res.alarmList.map(item=>{
        return {name:item.alarmTypeName,value:item.numberOfAlarms}
      })
      this.deviceInfoList = List

      this.loading = false
      this.options = {
        legend: {
          type: 'plain',
          orient: 'vertical',
          top: '11%',
          right: '20%',
          icon: 'diamond',
          itemGap: 12,
          itemWidth: 7, // 设置宽度
          itemHeight: 7, // 设置高度
          textStyle: {
            color: '#DAFFE8',
            fontSize: this.ChartSize(14)
          }
        },
        color: ['#7DFF89', '#397AFF', '#FFEED5', '#FFBE8F', '#9776FF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '90',
            endAngle: '-269.9999',
            radius: '61%',
            splitNumber:20,
            center:['27%','45%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: '#002f64 ',
                width: 2
              }
            },
            axisLabel: {
              show: false
            },
            axisLine: {
                show: false,
                lineStyle: {
                    color: [
                        [0.1, '#7DFF89'],
                        [0.3, '#397AFF'],
                        [0.5, '#FFEED5'],
                        [0.8, '#FFBE8F'],
                        [1, '#9776FF']
                    ],
                    width: 10
                }
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['46%', '55%'],
            avoidLabelOverlap: false,
            hoverOffset:0,
            top: '-10%',
            left: '-46%',
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: this.deviceInfoList
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  .charts{
    margin-top: 50px;
  }
  ul{
    right:105px!important;
    li{
      width: 225px!important;
    }
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    height: 26px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 10px;
    height: 100%;
    position: relative;
    top: -5px;
    display: flex;
    .chartimg {
      z-index: 1;
      position: absolute;
      left: 34px;
      top: 18px;
      width: 126px;
      height: 126px;
      background-image: url('~@/assets/gd/chartbg2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
.jachart {
      z-index: 2;
      width: 360px;
      height: 180px;
    }
    ul {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 14px;
      width: 144px;
      padding-top: 21px;
      padding-right: 40px;
      position: absolute;
      right: 20px;

      li {
        font-size: 12.6px;
        color: rgba(255, 255, 255, 0.7);
        width: 150px;
        height: 21px;
        line-height: 21px;
        background-image: url('~@/assets/gd/ds_chatli.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 5px;
        text-align: right;
        padding-bottom: 12.5px;
        padding-right: 10px;
        align-items: center;
        span {
          color: #ffffff;
        }
      }
    }
  }
}
</style>