<template>
  <div>
    <JaTable  :btnTextFormat="btnTextFormat" v-model="filterData" :conditionData="conditionData" :columns="column" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @StartEdit="StartEdit" @append="append" @rowEdit="rowEdit" @rowDelete="rowDelete" @search="search" @reset="reset"></JaTable>

    <!-- 新增/编辑 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="50%" :title="titleText">
      <ja-form ref="jaForm" v-if="showEditAll" :rules="rules" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" @reset="showEditAll = false" @save="saveForm" @changeAlarmType="changeAlarmType" v-loading="loading_form.isShow" :element-loading-text="loading_form.text" :element-loading-spinner="loading_form.icon" :element-loading-background="loading_form.bg" :element-loading-custom-class="loading_form.formLoading">
      </ja-form>
    </el-dialog>
  </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import table from '@/views/mixins/table'
import { alarmConfigList, commitAlarmConfig,getAlarmType,getCategory,getTemplateByAlarmTypeId ,addAlarmConfig,updateAlarmConfig,delAlarmConfig} from '@/api/module/alarm.js'
import { reqDictData } from '@/api'

export default {
  title: '告警设置',
  mixins: [table],
  name: 'alarmConfigList',
  components: {
    JaTable
  },
  data() {
    return {
      // 搜索
      conditionData: [
        // {
        //   input_type: 'select',
        //   attribute: {
        //     placeholder: '告警类型',
        //     size: 'small'
        //   },
        //   key: 'alarmTypeId',
        //   options: []
        // },
        {
          input_type: 'select',
          attribute: {
            placeholder: '告警等级',
            size: 'small'
          },
          key: 'alarmLevel',
          options: []
        },
        // {
        //   input_type: 'input',
        //   attribute: {
        //     placeholder: '告警名称',
        //     size: 'small'
        //   },
        //   key: 'configName'
        // },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' },
        { normalBtn: 'add' }
      ],
      // 表格
      column: [
        // { label: '告警名称', prop: 'configName' },
        { label: '告警类型', prop: 'configName' },
        { label: '设备类型', prop: 'subcategory' },
        // { label: '告警编码', prop: 'alarmCode' },
        { label: '告警等级', prop: 'alarmLevel' },
        // { label: '通知模板', prop: 'templateName' },
        // { label: '告警描述', prop: 'messageDescribe' },
        { label: '告警阈值', prop: 'newthresholds' },
        {
          label: '操作',
          input_type: 'button',
          width: 160,
          list: [
            {
              text: '编辑',
              method: 'rowEdit'
            },
            {
              text: '启用',
              method: 'StartEdit',
              Format: true,
            },
            {
              text: '删除',
              method: 'rowDelete'
            }
          ]
        }
      ],
      tableData: [],
      total: 0,
      // 搜索参数
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
      rowFormData: [
        // { input_type: 'input', label: '告警名称', key: 'configName' },
        { input_type: 'input', label: '告警类型', key: 'configName', options: [],disabled:true },
        { input_type: 'select', label: '设备类型', key: 'subcategory', options: [
          { label: "智能水表", value: '智能水表' },
          { label: "智能电表", value: '智能电表' },
          { label: "超声波热量表", value: '超声波热量表' },
        ] },
        // { input_type: 'input', label: '告警编码', key: 'alarmCode', options: [] },
        { input_type: 'select', label: '告警等级', key: 'alarmLevel', col:8,options:[]},
        { input_type: 'input', type: 'number', label: '阈值设置', key: 'minThresholds',col:8, placeholder: '仅填写正整数' },
        { input_type: 'text',text:'~',col:1,class:'text_center'},
        { input_type: 'input',type: 'number', label: '', key: 'thresholds',col:4, placeholder:'仅填写正整数'},
        // { input_type: 'radio', label: '启用状态', key: 'status', options: [{label:"启用",value:0},{label:"禁用",value:1}], },
        // { input_type: 'select', label: '通知模版', key: 'templateId', options: [],},
        { input_type: 'input', type: 'textarea', col: 22, showWordLimt: true, label: '告警描述', key: 'messageDescribe'},
      ],
      rowForm:{},
      rules: {
        // configName: [{ required: true, message: '请输入告警名称', trigger: 'blur' }],
        // alarmTypeId: [{ required: true, message: '请选择告警类型', trigger: 'change' }],
        subcategory: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
        // alarmCode: [{ required: true, message: '请输入告警编码', trigger: 'blur' }],
        alarmLevel: [{ required: true, message: '请选择告警等级', trigger: 'change' }],
        minThresholds: [{ required: true, message: '请选择阈值', trigger: 'change' }],
        thresholds: [{ required: true, message: '请输填写阈值', trigger: 'change' }],
      },
      titleText: '编辑',
      showEditAll: false, // 显示新增/编辑弹窗
      labelWidth: `${this.util.setFontSize(140)}px`
    }
  },
  async created() {
    const {data:alarmTypeResult} =  await getAlarmType()
    const {data:alarmLevelResult} =  await reqDictData('/event_level_edit')
    const {data:deviceData} =  await getCategory({pageIndex: 1, pageSize: 200, isAll: '1'})
    const deviceTypeResult = deviceData.data
    const alarmOption = alarmTypeResult.map(item=>{
      return {label:item.alarmType,value:item.id}
    })
    const alarmLevelOption = alarmLevelResult.map(item=>{
      return {label:item.dictLabel,value:item.dictValue}
    })
    const deviceTypeOption = deviceTypeResult.map(item=>{
      return {label:item.categoryName,value:item.id}
    })

    this.conditionData.forEach(item=>{
      if(item.key==='alarmTypeId') item.options = alarmOption
      if(item.key==='alarmLevel') item.options = alarmLevelOption
    })
    this.rowFormData.forEach(item=>{
      if(item.key==='alarmTypeId') item.options = alarmOption
      if(item.key==='alarmLevel') item.options = alarmLevelOption
      if(item.key==='category') item.options = deviceTypeOption
    })
  },
  methods: {
    btnTextFormat(row, btn) {
      if (row.status == 1) {
        return '启用'
      } else if (row.status == 0) {
        return '停用'
      } else {
        return btn.text
      }
    },
    getInit() {
      this.loading = true
      alarmConfigList(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.tableData.forEach(item => {
            item.newthresholds = item.minThresholds + '~' + item.thresholds
          })
          console.log(this.tableData);
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该告警设置', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
        delAlarmConfig(row.id).then((res) => {
          this.$message.success('删除成功')
          this.getInit()
        })
      })
    },
    saveForm() {
      // 保存
      if(Number(this.rowForm.minThresholds) > Number(this.rowForm.thresholds)){
        this.$message.error('第二个数字不能小于第一个数字')
        return
      }
      this.loading_form.isShow = true
      this.rowForm.subcategoryName = this.rowForm.subcategory
      if (this.isAdd) {
        console.log(this.rowForm);
        addAlarmConfig(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success(res.msg)
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            this.loading_form.isShow = false
          })
      } else {
        updateAlarmConfig(this.rowForm)
          .then((res) => {
            this.loading_form.isShow = false
            this.$message.success(res.msg)
            this.showEditAll = false
            this.getInit()
          })
          .catch((error) => {
            this.loading_form.isShow = false
          })
      }
    },
    // 启用停用
    async StartEdit(row){
      let data = await commitAlarmConfig(row.id)
      if(data.code == 200){
        this.$message.success('操作成功')
      }
      this.getInit()
    },
    append() {
      this.titleText = '新增'
      this.isAdd = true
      this.rowForm = {templateId:"1", configName: '能源告警' }
      this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
      this.showEditAll = true
    },
    rowEdit(row) {
      console.log(row);
      let data = JSON.parse(JSON.stringify(row))
      // 编辑
      data.category = parseInt(data.category)
      this.titleText = '编辑'
      this.isAdd = false
      this.rowForm = data
      this.showEditAll = true
    },
    changeAlarmType(){
      this.rowForm.templateId = ''
    }
  },

  // watch:{
  //   'rowForm.alarmTypeId':{
  //     async handler(alarmTypeId){
  //       let options = []
  //       if(alarmTypeId){
  //         const {data:templateResult} =  await getTemplateByAlarmTypeId({pageIndex:1,pageSize:10})
  //         templateResult.data.forEach(item=>{
  //           item.label = item.groupNames
  //           item.value = item.id
  //           console.log(item);
  //         })
  //         options = templateResult.data
  //         console.log(options);
  //       }
  //       this.rowFormData.forEach(item=>{
  //         if(item.key==='templateId') 
  //         item.options = options
  //       })
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
/deep/ .text_center{
  text-align: center!important;
}
</style>
