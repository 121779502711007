<template>
  <div class="chartone">
    <div class="header">
      <div class="headerleft">设备状态总览</div>
    </div>

    <div class="chart"  @mouseenter="stopAnimation" @mouseleave="startAnimation">
      <div>
        <ja-init-chart ref="chart" class="jachart" :options="options" />
        <p>{{chartData.oneName}}</p>
        <p>在线:{{chartData.oneOn}}</p>
        <p>离线:{{chartData.oneOff}}</p>
      </div>
      <div>
        <ja-init-chart ref="chart" class="jachart" :options="options2" />
        <p>{{chartData.twoName}}</p>
        <p>在线:{{chartData.twoOn}}</p>
        <p>离线:{{chartData.twoOff}}</p>
      </div>
      <div>
        <ja-init-chart ref="chart" class="jachart" :options="options3" />
        <p>{{chartData.threeName}}</p>
        <p>在线:{{chartData.threeOn}}</p>
        <p>离线:{{chartData.threeOff}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  props:{
    chartData:{
      type:Object,
      default:()=>{}
    }
  },
  components: {
    JaInitChart
  },
  data() {
    return {
      options: {},//图表1
      options2: {},//图表2
      options3: {},//图表3
      chartData:{
        oneName:'',
        oneOn:'',
        oneOff:'',
        twoName:'',
        twoOn:'',
        twoOff:'',
        threeName:'',
        threeOn:'',
        threeOff:'',
      },
      loading: true,
      dataInterval:null
    }
  },
  async created() {
    await this.init()
    window.addEventListener('resize', this.handleResize)
    this.startAnimation()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    this.stopAnimation()
  },
  methods: {
    handleResize() {
      this.$refs.chart.myChart.resize()
    },
    async init() {
      this.loading = false
      this.options = {
        color: ['#FFDD30', '#54FFD2', '#1872FF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '0',
            endAngle: '360',
            radius: '40%',
            splitNumber: 30,
            center: ['50%', '50%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 2,
              lineStyle: {
                color: '#002f64 ',
                width: 1
              }
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['30%', '65%'],
            startAngle: '0',
            endAngle: '360',
            avoidLabelOverlap: false,
            hoverOffset: 0,
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: [this.chartData.oneOn, this.chartData.oneOff]
          }
        ]
      }

      this.options2 = {
        color: ['#FFDD30', '#54FFD2', '#1872FF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '0',
            endAngle: '360',
            radius: '40%',
            splitNumber: 30,
            center: ['50%', '50%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 2,
              lineStyle: {
                color: '#002f64 ',
                width: 1
              }
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['30%', '65%'],
            startAngle: '0',
            endAngle: '360',
            avoidLabelOverlap: false,
            hoverOffset: 0,
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: [this.chartData.twoOn, this.chartData.twoOff]
          }
        ]
      }

      this.options3 = {
        color: ['#FFDD30', '#54FFD2', '#1872FF'],
        series: [
          {
            // 内圆刻度
            type: 'gauge',
            startAngle: '0',
            endAngle: '360',
            radius: '40%',
            splitNumber: 30,
            center: ['50%', '50%'],
            z: 4,
            axisLine: {
              show: false
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 2,
              lineStyle: {
                color: '#002f64 ',
                width: 1
              }
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            }
          },
          {
            name: '',
            type: 'pie',
            radius: ['30%', '65%'],
            startAngle: '0',
            endAngle: '360',
            avoidLabelOverlap: false,
            hoverOffset: 0,
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: [this.chartData.threeOn, this.chartData.threeOff]
          }
        ]
      }
    },
    stopAnimation() {
      clearInterval(this.dataInterval);
    },
    startAnimation() {
      this.dataInterval = setInterval(() => {
        this.options.series[1].startAngle += 1;  // 控制旋转速度
        this.options.series[1].startAngle %= 360; // 限制 startAngle 在 0~360 之间，防止溢出

        this.options2.series[1].startAngle += 1;  // 控制旋转速度
        this.options2.series[1].startAngle %= 360; // 限制 startAngle 在 0~360 之间，防止溢出

        this.options3.series[1].startAngle += 1;  // 控制旋转速度
        this.options3.series[1].startAngle %= 360; // 限制 startAngle 在 0~360 之间，防止溢出
      }, 30); // 每隔30毫秒更新一次
    },
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .chartone{
  .header {
    left: 0 !important;
    top: 20px !important;
  }
  .chart{
    margin-top: 100px!important;
  }
}
.chartone {
  width: 100%;
  height: 100%;
  position: relative;
  .header {
    position: absolute;
    left: 0;
    top: 15px;
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .chart {
    margin-top: 70px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    >div{
      color: #fff;
      font-size: 12px;
      text-align: center;
      p{
        margin-top: 5px;
      }
    }
    .jachart {
      margin-bottom: 5px;
      width: 100px;
      height: 100px;
      background-image: url('~@/assets/gd/chartbg1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>