<template>
  <div :class="paddBottom ? 'borderPannelBottom' : 'borderPannel'">
    <div class="headerPannel">
      <WPeader :title="title" :icon="icon" :total="total" />
    </div>

    <div class="innerPannel">
      <ul class="threadLabel" v-if="isLine">
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div :class="selectData ? 'selectData' : 'selectDataNone'">
        <el-select
         v-if="isSelectDate"
          v-model="seleteDateValue"
          style="width:200px"
          :placeholder="seleteDate[0]"
          @change="selectDate"
          popper-append-to-body="false"
        >
        <el-option v-for="(item,index) in seleteDate" :key="index" :label="item" :value="item"></el-option>
        </el-select>
        <el-select
          v-if="isYearData"
          v-model="yearValue"
          style="width:200px"
          :placeholder="yearData[0]"
          @change="selectYearData"
          popper-append-to-body="false"
        >
        <el-option v-for="(item,index) in yearData" :key="index" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <slot />
      <ul class="threadLabel threadLabel_bottom" v-if="isLine">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
import WPeader from "./header"
export default {
  name: "WPanel",
  data () {
    return {
      seleteDate: [
        {
          value: 1,
          label: '2021-03'
        },
        {
          value: 2,
          label: '2021-04'
        },
        {
          value: 3,
          label: '2021-05'
        }
      ], // 显示时间的数组
      seleteDateValue: '',
      yearValue: '',
      yearData: []
    }
  },
  props: {
    title: {
      type: String
    },
    icon: {
      type: String
    },
    total: {
      type: String
    },
    isSelectDate: {
      type: Boolean,
      default: false
    },
    isYearData: {
      type: Boolean,
      default: false
    },
    isLine: {
      type: Boolean,
      default: false
    },
    paddBottom: {
      type: Boolean,
      default: false
    },
    selectData: {
      type: Boolean,
      default: true
    }
  },
  components: {
    WPeader
  },
  created () {
    this.getMonths()
  },
  methods: {
    // 生成年月
    getMonths () {
      var dataArr = []
      var yearArr = []
      var data = new Date()
      var year = data.getFullYear()
      var mou = data.getMonth() + 1
      var endDate = 2017 // 自定义截止年份时间
      var rate = year - endDate
      var monthNum = (rate - 1) * 12 + 10 + mou // 取到的月份
      data.setMonth(data.getMonth() + 1, 1) // 获取到当前月份,设置月份
      for (var i = 0; i < monthNum; i++) {
        data.setMonth(data.getMonth() - 1) // 每次循环一次 月份值减1
        var m = data.getMonth() + 1
        m = m < 10 ? "0" + m : m
        dataArr.push(data.getFullYear() + "-" + m)
      }
      // 获取前三年 ，年份
      for (var j = year; j > year - 3; j--) {
        yearArr.push(j)
      }
      this.seleteDate = dataArr
      this.yearData = yearArr
    },
    selectDate (e) {
      this.$emit('selectDate', e)
    },
    selectYearData (e) {
      this.$emit("selectYearData", e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
.entry {
  .threadLabel{
    display: flex;
    align-items: center;
    li:nth-of-type(1){
      width: 4px;
      height: 11px;
      background: #02C0FC;
    }
    li:nth-of-type(2){
      width: 100%;
      height: 1px;
      background: #02C0FC;
    }
    li:nth-of-type(3){
      width: 4px;
      height: 11px;
      background: #02C0FC;
    }
  }
  .selectDataNone {
    display: none;
  }
  .selectData{
    text-align: right;
    margin-top: 20px;
  }
}
</style>
