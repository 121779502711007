<template>
    <div class="allwh">
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData"
            :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset"
            @rowDetail="normalEvent">
            <!-- 安装位置 -->
            <template #position="{ row }">
                <span>
                    {{ row.buildingName + row.floorName }}
                </span>
            </template>
            <template #co2="{ row, prop }">
                <span :class="[filterColor(row,prop)]">
                    {{ row[prop] }}
                </span>
            </template>
            <template #fineParticulateMatter="{ row, prop }">
                <span :class="[filterColor(row,prop)]">
                    {{ row[prop] }}
                </span>
            </template>
            <template #pm10="{ row, prop }">
                <span :class="[filterColor(row,prop)]">
                    {{ row[prop] }}
                </span>
            </template>
            <template #tcov="{ row, prop }">
                <span :class="[filterColor(row,prop)]">
                    {{ row[prop] }}
                </span>
            </template>
            <template #envTemperature="{ row, prop }">
                <span :class="[filterColor(row,prop)]">
                    {{ row[prop] }}
                </span>
            </template>
            <template #envHumidity="{ row, prop }">
                <span :class="[filterColor(row,prop)]">
                    {{ row[prop] }}
                </span>
            </template>
        </ja-table>
    </div>
</template>
  
<script>
import table from '@/views/mixins/table'
import { getEnvSensorsPage } from '@/api'

export default {
    title: "传感器管理",
    name: 'sensor',
    mixins: [table],
    data() {
        return {
            conditionData: [
                { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
                { input_type: 'input', key: 'deviceCode', attribute: { placeholder: '设备编码', size: 'small' } },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' }
            ],
            columns: [
                { label: '设备名称', prop: 'deviceName' },
                { label: '设备编码', prop: 'deviceCode' },
                { slotName: 'position', label: '安装位置', prop: 'buildingName' },
                { slotName: 'co2', label: 'CO₂浓度(ppm)', prop: 'co2' },
                { slotName: 'fineParticulateMatter', label: 'PM2.5浓度(ug/m³)', prop: 'fineParticulateMatter' },
                { slotName: 'pm10', label: 'PM10浓度(ug/m³)', prop: 'pm10' },
                { slotName: 'tcov', label: 'TVOC浓度(ug/m³)', prop: 'tcov' },
                { slotName: 'envTemperature', label: '环境温度(°C)', prop: 'envTemperature' },
                { slotName: 'envHumidity', label: '环境湿度(%)', prop: 'envHumidity' },
            ],
            tableData: [],
            total: 0,
            loading: false,
        }
    },
    computed:{
        filterColor(){
            return (row,key)=>{
                const value = row[key];
                // 制冷≥27 制热≤20）
                const obj = {
                    'co2': value >= 700,
                    'fineParticulateMatter': value >= 755,
                    'pm10':  value >= 755,
                    'tcov':  value >= 0.6,
                    'envTemperature':  row.modeFeedback === '1' ? value >= 27 : value >= 20,
                    'envHumidity':  value > 60 || value < 35,
                }
                return obj[key] && 'error'
            }
        }
    },
    methods: {
        // 获取初始化数据
        async getInit() {
            this.loading = true
            const { data } = await getEnvSensorsPage(this.filterData)
            this.tableData = data.data
            this.total = data.total
            this.loading = false
        },
    }
}
</script>
  
<style scoped lang="scss">
.dialog_form{
    /deep/ .el-form-item {
    width: 100%;

    .el-form-item__content {
        width: 70%;
    }

    .el-select{
        width: 100%;
    }
}
}

.inactive {
    color: #f8b636;
}

.active {
    color: #83bf48;
}

.error {
    color: #d50e21;
}

.dialog_span {
    margin-right: 10px;
}

.dialog_footer {
    display: flex;
    justify-content: center;
}
</style>
  