<template>
    <div class="chartone">
        <div class="header">
            <div class="headerleft">实时访客总览</div>
        </div>

        <div class="chart" @mouseenter="stopAnimation" @mouseleave="startAnimation">
            <div v-for="(item, index) in optionsList" :key="index">
                <ja-init-chart ref="chart" class="jachart" :options="item" />
                <p>{{ optionsValue[index] }}</p>
            </div>
            <div>
                <ja-init-chart ref="chart" class="jachart" :options="options1" />
                <p v-for="item in itemList" :key="item.name">
                    <span>{{ item.name }}</span>
                    <span>{{ item.value }}</span>
                </p>
            </div>
        </div>
    </div>
</template>
  
<script>
import JaInitChart from '@/components/ja-init-chart/index'
import { visitorOverview } from "@/api"
export default {
    name: 'water',
    components: {
        JaInitChart
    },
    props: {
        equipmentData: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loading: true,
            optionsList: [],
            optionsValue: [
                '东门',
                '西门',
                '南门'
            ],
            options1: {},
            itemList: [
                { name: '总人数', value: 0 },
                { name: '访客数', value: 0 },
                { name: '租户', value: 0 },
            ],
            dataInterval: null,
            animationInterval:null
        }
    },
    created() {
        this.startDataInterval()
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
        if (this.dataInterval) {
            clearInterval(this.dataInterval);
        }// 
        this.stopAnimation()
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        stopAnimation() {
            clearInterval(this.animationInterval);
        },
        startAnimation() {
            this.animationInterval = setInterval(() => {
                this.options1.series[1].startAngle += 1;
                this.options1.series[1].startAngle %= 360;
            }, 30); // 每隔30毫秒更新一次
        },
        handleResize() {
            this.$refs.chart.myChart.resize()
        },
        async startDataInterval() {
            await this.init()
            this.startAnimation()
            this.dataInterval = setInterval(() => {
                this.init()
            }, this.TIMER_INTERVAL);
        },
        async init() {
            const { data } = await visitorOverview()
            this.itemList[0].value = data.totalPassengers
            this.itemList[1].value = data.householdNumber
            this.itemList[2].value = data.visitorsNumber
            this.optionsList = data.data.map((item, index) => {
                this.optionsValue[index] = item.deviceName
                return {
                    title: {
                        text: item.countNumber,
                        top: '60%',
                        left: 'center',
                        textStyle: {
                            color: '#FFFF',
                            fontSize: 14,
                            fontWeight: '900',
                            textShadow: '0px 0px 16px rgba(18,253,240,0.5)'
                        }
                    },
                    series: [
                        {
                            type: 'liquidFill',
                            center: ['50%', '50%'],
                            radius: '90%',
                             color: [
                                {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#18DFFF'
                                        },
                                        {
                                            offset: 1,
                                            color: '#0273FF'
                                        }
                                    ],
                                    globalCoord: false
                                }
                            ],
                            data: [item.proportion * 100 / 100, item.proportion * 100 / 100, item.proportion * 100 / 100, item.proportion * 100 / 100], // data个数代表波浪数
                            backgroundStyle: {
                                borderWidth: 1,
                                color: 'RGBA(51, 66, 127, 0.7)'
                            },
                            label: {
                                normal: {
                                    formatter: function () {
                                        return Math.floor(item.proportion * 100) + '%' // 显示具体数字
                                    },
                                    textStyle: {
                                        fontSize: 14, // 数字字体大小
                                        color: '#FFF' // 数字颜色
                                    }
                                }
                            },
                            outline: {
                                // show: false
                                borderDistance: 0,
                                itemStyle: {
                                    borderWidth: 2,
                                    borderColor: '#031F1E07'
                                }
                            }
                        }
                    ]
                }
            })
            this.options1 = {
                color: ['#FFDD30', '#54FFD2'],
                series: [
                    {
                        // 内圆刻度
                        type: 'gauge',
                        startAngle: '90',
                        endAngle: '-269.9999',
                        radius: '40%',
                        splitNumber: 30,
                        center: ['50%', '50%'],
                        z: 4,
                        axisLine: {
                            show: false
                        },
                        pointer: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            length: 2,
                            lineStyle: {
                                color: '#002f64 ',
                                width: 1
                            }
                        },
                        axisLabel: {
                            show: false
                        },
                        detail: {
                            show: false
                        }
                    },
                    {
                        name: '',
                        type: 'pie',
                        radius: ['30%', '65%'],
                        startAngle: '0',
                        endAngle: '360',
                        // center: ['50%', '50%'],
                        avoidLabelOverlap: false,
                        hoverOffset: 0,
                        label: {
                            show: false,
                            position: 'left'
                        },
                        emphasis: {
                            label: {
                                show: false
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [data.visitorsNumber, data.householdNumber]
                    }
                ]
            }
        }
    }
}
</script>
  
<style lang="scss" scoped>
:-webkit-full-screen .header {
    left: 0 !important;
    top: 20px !important;
}

.chartone {
    width: 100%;
    height: 100%;
    position: relative;

    .header {
        position: absolute;
        left: 5px;
        top: 15px;
        height: 26px;
        width: 50%;
        padding-left: 10px;
        line-height: inherit;
        display: flex;
        overflow: hidden;
        color: #fff;
        align-items: center;
        background-image: url('~@/assets/gd/ds_charttitle.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .icons {
            width: 12px;
            height: 8px;
        }

        .headerleft {
            flex-shrink: 0;
            line-height: 28px;
            font-size: 18px;
            font-weight: 400;
            position: relative;
            padding: 0 3px;
            color: #ffffff;
        }
    }

    .chart {
        margin-top: 80px;
        height: auto;
        width: 100%;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;

        >div {
            color: #fff;
            font-size: 12px;
            text-align: center;
            margin-top: 30px;
            height: auto;
            width: auto;

            p {
                margin-top: 5px;
                display: flex;
                justify-content: center;
                width: 100px;
            }

            & span:nth-of-type(1) {
                margin-right: 6px;
            }
        }

        .jachart {
            margin: 0 auto;
            margin-bottom: 5px;
            width: 70px;
            height: 70px;
        }

        & div:last-of-type {
            margin-top: 0;
            border-radius: 10px;

            .jachart {
                width: 100px;
                height: 100px;
                background-image: url('~@/assets/gd/chartbg1.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            p {
                display: flex;
                justify-content: space-between;
            }
        }

    }
}
</style>