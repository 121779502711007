var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allwh"},[_c('ja-table',{ref:"tst",attrs:{"conditionData":_vm.conditionData,"columns":_vm.columns,"tableData":_vm.tableData,"showSummary":false,"chooseArr":['总计用量', '本期用量（m³）'],"total":_vm.total,"loading":_vm.loading,"showIndex":false},on:{"getInit":_vm.getInit,"search":_vm.search,"reset":_vm.reset,"showDetail":_vm.showDetail,"importForm":function($event){return _vm.normalEvent({
                ref: 'importForm',
                method: 'importForm',
                url: '/files/水表抄表导入.xlsx',
                filename: '水表抄表导入模板',
                uploadURL: '/waterMeter/upload'
            })}},scopedSlots:_vm._u([{key:"typeText",fn:function({ row }){return [_c('span',{class:{ red: row.currentReading <= 0 },attrs:{"type":"text"}},[_vm._v(_vm._s(row.currentReading))])]}},{key:"tableBottomSlot",fn:function(){return [_c('div',{staticClass:"tableSum"},[_c('div',[_vm._v(" 总计用量 ")]),_c('div',[_vm._v(" "+_vm._s(_vm.currentReadingSum.toFixed(2))+" ")])])]},proxy:true}]),model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}}),_c('import-form',{ref:"importForm",on:{"openResult":_vm.openResult}}),_c('check-result',{ref:"checkResult",attrs:{"results":_vm.results}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }