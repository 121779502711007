const getters = {
  token_data: state => state.user.token_data,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  rq_routes: state => state.permission.rqRoutes,
  active_url: state => state.permission.menu_activeUrl,
  menu_title: state => state.permission.menu_title,
  address_list: state => state.user.addressList,
  buildingFloor: state => state.visualsystem.buildingFloor
}
export default getters
