<template>
  <div class="u-menu">
    <!-- 左侧菜单 -->
    <el-menu :default-active="active_url"
             class="el-menu-vertical-demo"
             background-color="rgba(0,0,0,0)"
             text-color="#fff"
             unique-opened
             :default-openeds="defaultOpeneds"
             ref="elMenu"
             @select="selectMenu">
      <!-- 菜单 -->
      <template v-for="(n, index) in rq_routes">
        <!-- 首页 -->
        <el-menu-item :index="'/home/' + n.path"
                      :key="index"
                      v-if="!n.children || n.children.length==0">
          <div @click="goHome(n.path,n.children && n.children.length>0)"
               :class="[n.children && n.children.length>0?'':'onlyMenu',active_url.includes('/home/' + n.path) && active_url !=='/home/' + n.path ?'fakeActiveLine':'']">
            <i class="el-submenu__icon-arrow el-icon-arrow-down mine"
               v-if="n.children && n.children.length>0"></i>
            <span class="word">

              <svg-icon icon-class="chart"
                        class="svgIcon" /> {{ n.title }}
            </span>
          </div>
        </el-menu-item>

        <el-submenu :index="'/home/' + n.path"
                    :key="index"
                    v-else>
          <!-- 一级菜单 -->
          <template slot="title">
            <div @click="goHome(n.path,n.children && n.children.length>0)"
                 :class="[n.children && n.children.length>0?'':'onlyMenu']">
              <!-- //本地菜单栏 的 图标 chart -->
              <i class="el-submenu__icon-arrow el-icon-arrow-down mine"
                 v-if="n.children && n.children.length>0"></i>
              <span class="word">
                <svg-icon :icon-class="n.icon"
                          class="svgIcon"
                          v-if="n.icon" /> {{ n.title }}
              </span>
            </div>
          </template>

          <template>
            <!-- 二级菜单 -->
            <template v-for="(m, l) in n.children">
              <el-submenu :index="'/home/' + n.path + '/' + m.path"
                          :key="l"
                          v-if="m.children && m.children.find(item=>item.meta && item.meta.show)">
                <template>
                  <template slot="title">
                    <span class="word">
                      <svg-icon :icon-class="m.icon"
                                class="svgIcon"
                                v-if="m.icon" /> {{m.title }}
                    </span>
                  </template>

                  <!-- 三级菜单 -->
                  <el-menu-item :index="'/home/' + n.path + '/' + m.path + '/' + r.path"
                                v-for="(r, t) in m.children"
                                :key="t" :class="[active_url.includes('/home/' + n.path + '/' + m.path + '/' + r.path)?'fakeActiveLine':'']" >
                    <span slot="title"
                          class="word">
                      <svg-icon :icon-class="r.icon"
                                class="svgIcon"
                                v-if="r.icon" /> {{ r.title }}
                    </span>
                  </el-menu-item>
                </template>
              </el-submenu>

              <template v-else>
                <el-menu-item :index="'/home/' + n.path + '/' + m.path"
                              :key="l" :class="[active_url.includes('/home/' + n.path + '/' + m.path)?'fakeActiveLine':'']">
                  <span slot="title"
                        class="word">
                    <svg-icon :icon-class="m.icon"
                              class="svgIcon"
                              v-if="m.icon" /> {{ m.title }}
                  </span>
                </el-menu-item>
              </template>

            </template>
          </template>
        </el-submenu>
      </template>

    </el-menu>
  </div>
</template>

<script>
// import router from "@/router"
import { mapGetters } from "vuex"
// import IconFont from "./icon-font.vue"
import route_rules from '@/router/route_rule'
import { rqIndexUserList, editIndexUser } from '@/api'

export default {
  name: "uMenu",
  // components: {
  //   IconFont
  // },
  data () {
    return {
      defaultOpeneds: [],
      routes: [],
      active: "",
      a: [
        "el-icon-s-home",
        "el-icon-office-building",
        "el-icon-s-custom",
        "el-icon-lock",
        "el-icon-notebook-1",
        "el-icon-setting"
      ],
      rowFormData: [
        {
          input_type: 'radio',
          label: '用户：',
          key: 'username',
          col: 24,
          options: [
            // {label:'admin1234',value:1},
            // {label:'liajng888',value:0},
          ]
        }
      ],
      rules: {
        username: [
          { required: true, message: '请选择要切换的用户', trigger: 'change' }
        ]
      },
      rowForm: {},
      showSelectRadio: false, // 显示新增/编辑弹窗
      btnBox: [
        { label: '确定', method: 'save', type: 'primary', monitor: true, col: 8, isCenter: true },
        { label: '取消', method: 'reset', col: 8, isCenter: true }
      ],
      showMenu: true,
    }
  },
  computed: {
    ...mapGetters(["permission_routes", "rq_routes", "active_url"])

  },
  created () {
    const active = sessionStorage.getItem("menu") || ""
    this.$store.commit('SET_ACTIVEURL', active)
    this.recursion(this.$store.getters.permission_routes[3])
    // this.$store.commit('INIT_ROUTES')
    console.log('所有的用户信息==》',)
  },
  mounte () {
  },
  watch: {
    '$store.state.showMenu': function (value) {
      this.showMenu = value
    }
  },
  methods: {
    selectMenu (val) {
      // console.log('---------', val)
      const arr = val.split('/')
      const filePath = arr[arr.length - 1]
      if (route_rules[filePath]) {
        let url = route_rules[filePath].path.slice(6)
        const name = url.replace(/\//ig, '_')
        const rqRoutesArr = this.$store.getters.rq_routes
        const url_Val = val.replace(/\/home\//ig, '')
        let obj_ = this.getRouteObj({ arr: rqRoutesArr, url: url_Val })
        this.$router.push({ name: name, params: { outLink: obj_.meta.outLink } })
        sessionStorage.setItem("outLink", name == 'iframe_web'?obj_.meta.outLink:"" )
        // console.log('现在活跃的路径是----',this.$store.getters.active_url)
      } else {
        this.$message.error('页面不存在，或无权限访问该页面')
        this.$router.replace('/home/404');
      }
    },
     // 找到这个路由Item 对象
    getRouteObj({ arr = [], url = '' }) {
      let index, urlArr
      if (url && url.includes('/')) {
        urlArr = url.split('/')
        index = arr.findIndex(v => v.path == urlArr[0])
      } else {
        index = arr.findIndex(v => v.path == url)
      }
      if (index > -1) {
        if (arr[index].children && arr[index].children.length > 0) {
          let nextPath = url.slice(urlArr[0].length + 1)
          return this.getRouteObj({ arr: arr[index].children, url: nextPath })
        } else {
          return arr[index]
        }
      } else {
        return false
      }
    },
    recursion (arr) {
      // console.log('arr====>', arr)
      arr.children = arr.children.filter(
        (item) => !item.meta || (item.meta && item.meta.show !== false)
      )
      arr.children.forEach((item) => {
        if (item.children && item.children.length) {
          this.recursion(item)
        } else {
          delete item.children
        }
      })
    },
    goHome (v, isFather) {
      // console.log('点击了---一级目录', v, isFather)
      if (isFather) {

      } else {
        this.selectMenu("/home/" + v)
      }
    },
  }
}
</script>
<style scoped lang="scss">
@import '~@/style/mixin.scss';
.u-menu {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $uMenu_bg;
  overflow: auto;
  height: 100%;

  .aside-header {
    width: 100%;
    height: 85px;
    text-align: center;
    line-height: 85px;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 1);
    color: #ffffff;
  }
  .hidden-aside {
    width: 7.8%;
    font-size: 12px;
    font-weight: 500;
  }

  /deep/ .el-menu {
    border-right: none;
  }

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    // width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    width: 0;
  }
  // 在以已经展开父级的情况下 鼠标移上，背景颜色不变动
  @mixin noHoverBg($fatherColor: '') {
    &.is-opened,
    &.is-active {
      background: $fatherColor !important;
      > div.el-submenu__title,
      div.onlyMenu {
        &:hover {
          background: $fatherColor !important;
        }
      }
    }
  }

  // 鼠标移上去时的样式
  .hoverItemBg {
    &:hover {
      background: $uMenu_hover_item_bg !important;
    }
  }
  // 选中时的样式
  .activeItemBg {
    &.is-active, &.fakeActiveLine {
      background: $uMenu_active_item_bg !important;
      border-left: 4px solid $uMenu_active_item_bdleft;
      padding-left: 76px !important;
    }
  }
  /deep/ .submenu__title:hover {
    background-color: $uMenu_hover_item_bg;
  }
  /deep/ ul[role='menubar'] {
    //一级
    > li.el-submenu[role='menuitem'] {
      @include noHoverBg($uMenu_firstTitle_Opening_bg);
      padding: 0 0;
      > div {
        @extend .hoverItemBg;
        padding-left: 59px !important;
      }
      > ul[role='menu'] {
        // 二级
        background: $uMenu_secondTitle_bg !important;
        > li.el-submenu[role='menuitem'] {
          @include noHoverBg($uMenu_secondTitle_bg);
          > div {
            padding-left: 80px !important;
            @extend .hoverItemBg;
          }
          > ul[role='menu'] {
            // 三级
            background: $uMenu_thirdTitle_bg !important;
            > li[role='menuitem'] {
              @extend .hoverItemBg;
              @extend .activeItemBg;
              padding-left: 80px !important;
            }
          }
        }
        > li.el-menu-item[role='menuitem'] {
          //二级
          @extend .hoverItemBg;
          @extend .activeItemBg;
          background: $uMenu_secondTitle_bg !important;
          padding-left: 80px !important;
        }
      }
    }
    > li.el-menu-item[role='menuitem'] {
      //唯独自己的一级标题
      @include noHoverBg($uMenu_active_item_bg);
      padding: 0 0 !important;
      &.is-active {
        border-left: 4px solid $uMenu_active_item_bdleft;
        div {
          padding-left: 55px !important;
        }
      }
      > div {
        @extend .hoverItemBg;
        padding-left: 59px !important;
      }
    }
    li.el-menu-item,
    li.el-submenu > div.el-submenu__title {
      //设置菜单栏每一项的高度、字体大小
      // font-size: 17px !important;
      // height: 64px !important;
      // line-height: 64px !important;
      .word {
        position: relative;
      }
    }
    .el-submenu__icon-arrow {
      // 层级右边箭头的颜色
      color: white !important;
    }
  }
}
/deep/ .svg-icon.svgIcon {
  fill: $uMenu_svg-icon !important;
  color: $uMenu_svg-icon !important;
}
.svgIcon {
  // margin:0 36px 0 18px;
  font-size: 20px;
  position: absolute;
  left: -38px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.topBox {
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  color: white;
  // font-weight: 600;
  font-size: 16px;
  &:hover {
    font-weight: bold;
  }
  cursor: pointer;
  .svgIcon {
    width: 20px;
  }
}
</style>
