<template>
  <div class="entry bg" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="left">
     <div class="flow">
      <parkingChart v-if="loading"/>
     </div>
     <div class="flow" @click="openAlarmdialog">
      <alarmChart :alarmList="alarmList" v-if="loading"/>
     </div>
     <div class="flow" @click="openVehicledialog">
      <vehicleChart v-if="loading"/>
     </div>
    </div>

    <div class="midwindow">
      <carNum />
    </div>

    <div class="right">
     <div class="flow">
      <revenueChart v-if="loading"/>
     </div>
     <div class="flow">
      <barrierChart v-if="loading"/>
     </div>
     <div class="flow">
        <tableBox :tableData="patrolData" :title="'实时通行情况'" :columns="rightcolums" v-if="loading"/>
     </div>
    </div>
    <div class="systembtn">
      <systemButton title="智慧停车"/>
    </div>
    <div class="bottom"></div>

    <Dialog
      ref="dialog"
      :title="alarmTitle"
      :columns="columns"
      :getDatamethod="getEquipmentAlarm"
      :systemurl="systemurl"
      :dialogtype="1"
    >
    </Dialog>


  </div>
</template>

<script>
import headers from '../../../../dsWindow/IOC/header/index.vue'
import carNum from './carNum/carNum.vue'
import parkingChart from './parkingChart/parkingChart.vue'
import vehicleChart from './vehicleChart/vehicleChart.vue'
import revenueChart from './revenueChart/revenueChart.vue'
import barrierChart from './barrierChart/barrierChart.vue'
import alarmChart from '../components/alarmChart/index.vue'
import tableBox from '../components/tableBox/index.vue'
import Dialog from '../components/Dialog/index.vue'
import systemButton from '../components/systemButton/index.vue'
import { getWisdomsecurity,getEquipmentAlarm } from '@/api'

export default {
  title:'智慧停车',
  name:'wiscar',
  components: {
    headers,
    alarmChart,
    parkingChart,
    vehicleChart,
    revenueChart,
    barrierChart,
    carNum,
    tableBox,
    Dialog,
    systemButton
  },
  data() {
    return {
      loading:false,
      systemurl:'',
      alarmList:[],//今日告警
      alarmTitle:'告警事件详情',
      tableData:[],//维修详情列表
      patrolData:[],//实时巡更计划列表
      leftcolums:[],
      rightcolums:[
        { label: '车牌号', prop: 'wordOrderName' },
        { label: '通行设备', prop: 'time' },
        { label: '状态', prop: 'statusValue' }
      ],//实时通行情况
      //传递到弹窗里的配置
      columns:[],
      getEquipmentAlarm:getEquipmentAlarm,
    }
  },
  methods: {
    //打开告警弹窗
    async openAlarmdialog(){
      this.alarmTitle = '告警事件详情'
      this.columns = [
        { label: '事件名称', prop: 'eventName' },
        { label: '触发时间', prop: 'eventTime' },
        { label: '状态', prop: 'status' }
      ],
      this.$refs.dialog.showDialog();
    },
    //打开车辆趋势弹窗
    async openVehicledialog(){
      this.alarmTitle = '预约车辆详情'
      this.columns = [
        { label: '访客名称', prop: 'eventName' },
        { label: '车牌号码', prop: 'eventTime' },
        { label: '访问空间', prop: 'eventKJ' },
        { label: '状态', prop: 'status' }
      ],
      this.$refs.dialog.showDialog();
    },
    async getDate(){
      let {data:res} = await getWisdomsecurity()
      console.log(res);
      let alarm = [
          {
              // "alarmTypeName": "CO告警",
              // "numberOfAlarms": 22
          },
          {
              // "alarmTypeName": "月保到期",
              // "numberOfAlarms": 2
          },
          {
              // "alarmTypeName": "设备异常",
              // "numberOfAlarms": 32
          },
          {
              // "alarmTypeName": "积水告警",
              // "numberOfAlarms": 19
          },
          {
              // "alarmTypeName": "通行闸离线",
              // "numberOfAlarms": 12
          }
      ]
      this.alarmList = alarm.map(item=>{
        return {name:item.alarmTypeName,value:item.numberOfAlarms}
      })
      this.tableData = res.repairList
      this.patrolData = [
        // {wordOrderName:'粤A-12345',
        //   time:'2023-09-18 17:32:00',
        //   statusValue:'通过',}
      ]



      this.loading = true
    }
  },
  mounted() {
    this.$emit('loading')
  },
  created(){
    this.getDate()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';
.bg {
  height: 100%;
  position: relative;
  overflow: hidden;
  .scene {
            width: 100%;
            height: 100%;
            transform: scale(1);
        }
}
.entry {
  width: 100%;
  position: relative;
  // height: 5vw;
  // 导航栏
  .top {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.png');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .midwindow{
    position: absolute;
    right: 26%;
    top: 90px;
    width: 200px;
    height: 100px;
  }
  .left {
    position: absolute;
    background: url('~@/assets/gd/left.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    left: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    .flow {
      height: 33%;
      width: 100%;
      flex: 1;
      display: flex;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .right {
    position: absolute;
    background: url('~@/assets/gd/right.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    right: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    padding-top: 20px;
    .flow {
      height: 33%;
      width: 100%;
      flex: 1;
      display: flex;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .bottom {
    height: 38px;
    width:66.5%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 1;
  }
  .systembtn {
    width: 36%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 85px;
  }
}
</style>
