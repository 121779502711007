<template>
    <!-- 设备管理-设备列表 -->
    <!-- rowDetail: 详情操作->详情面板 -->
    <!-- append: 新增操作->新增面板 -->
    <!-- rowEdit: 编辑操作->编辑面板 -->
    <!-- rowDelete: 删除操作->删除面板 -->
    <!-- getInit: 获取ajax数据 -->
    <!-- outPut_: 导出数据 单独导出 -->
    <!-- outPutExcell_obj：导出数据 封装导出-->
    <!-- editDataFormat: 检测当前行显示的内容（如：根据条件显示待处理、已处理）+ 可根据自定义的key值判断 -->
    <!-- handleSelectionChange -->
    <div class="allwh">
        <div class="allwh-wrapper">
            <ja-table ref="tst"
                      v-model="filterData"
                      :conditionData="conditionData"
                      @rowDetail="normalEvent"
                      :columns="columns"
                      :tableData="tableData"
                      :total="total"
                      :loading="loading"
                      @append="append"
                      @rowEdit="rowEdit"
                      @rowDelete="rowDelete"
                      @getInit="getInit"
                      @search="search"
                      @reset="reset">
            </ja-table>

            <!-- 新增/编辑 -->
            <!-- reset: 取消 -->
            <!-- saveForm: 保存操作 -->
            <el-dialog v-dialogDrag
                       :close-on-click-modal="false"
                       :visible.sync="showEditAll"
                       width="50%"
                       :title="titleText">
                <ja-form :rules="rules"
                         :label-width="labelWidth"
                         v-model="rowForm"
                         ref="jaForm"
                         :formArray="rowFormData"
                         @reset="showEditAll = false"
                         @save="saveForm"
                         v-if="showEditAll"
                         v-loading="loading_form.isShow"
                         :element-loading-text="loading_form.text"
                         :element-loading-spinner="loading_form.icon"
                         :element-loading-background="loading_form.bg"
                         :element-loading-custom-class="loading_form.formLoading">
                    <template slot="electricMajor">
                        <el-autocomplete
                        class="inline-input"
                        v-model="rowForm.electricMajor"
                        :fetch-suggestions="querySearchAsync"
                        placeholder="请输入内容"
                        ></el-autocomplete>
                    </template>
                </ja-form>
            </el-dialog>

            <detail ref="detail" />
        </div>
    </div>
</template>

<script>
import table from "@/views/mixins/table"
import util from '@/util/util.js'
import { deviceInfoGet, deviceInfoAdd, deviceInfoEdit, deviceInfoDelete,getMeterType } from '@/api'
import Detail from './Detail.vue'

export default {
    title: '设备列表',
    mixins: [table],
    name: 'deviceList',
    components: {
        Detail
    },
    data() {
        return {
            filterData: { pageIndex: 1, pageSize: 10 }, // 默认页码
            conditionData: [
                {
                    input_type: 'select', key: 'category', attribute: { placeholder: '设备大类', size: 'small' }, options: [],
                    relevance: { sonKey: 'subcategory', This: this, rqParam: { rqUrl: "/device/category", rqData: () => { return { params: { pageIndex: 1, pageSize: 200, isAll: '2', parentId: this.filterData.category } } }, labelName: 'categoryName', valueName: 'id' }, }
                },
                { input_type: 'select', key: 'subcategory', attribute: { placeholder: '设备小类', size: 'small' }, options: [] },
                 {
                    input_type: 'select', key: 'buildingId', attribute: { placeholder: '所属楼栋', size: 'small' }, options: [],
                },
                {
                    input_type: 'select', key: 'floorId', attribute: { placeholder: '所属楼层', size: 'small' }, options: [],
                },
                { input_type: 'input', key: 'deviceName', attribute: { placeholder: '设备名称', size: 'small' } },
                { input_type: 'input', key: 'deviceNumber', attribute: { placeholder: '设备识别码', size: 'small' } },
                 { input_type: 'input', key: 'deviceCode', attribute: { placeholder: '设备编码', size: 'small' } },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'add', limit: 'device::manage::add' },
                { normalBtn: 'reset' },
            ],
            conditionDataMap: { category: 0, subcategory: 1 },
            columns: [
                { label: '设备名称', prop: 'deviceName', attribute: { showOverflowTooltip: true, align: 'center' } },
                { label: '设备识别码', prop: 'deviceNumber' },
                { label: '设备编码', prop: 'deviceCode' },
                { label: '设备大类', prop: 'categoryName' },
                { label: '设备小类', prop: 'subcategoryName' },
                { label: '所属楼栋', prop: 'buildingName', width: 80 },
                { label: '所属楼层', prop: 'floorName', width: 80 },
                { label: '设备状态', prop: 'runStatus', width: 80 },
                { label: '设备品牌', prop: 'brand' },
                { label: '设备型号', prop: 'model' },
                { label: '检测时间', prop: 'monitorTime', attribute: { showOverflowTooltip: true, align: 'center' } },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 140,
                    attribute: { fixed: "right", align: 'center' },
                    list: [
                        { text: '详情', method: 'rowDetail' },
                        { text: '编辑', method: 'rowEdit', limit: 'device::manage::update' }, { text: '删除', method: 'rowDelete', limit: 'device::manage::del' },]// limit: 'admin::user::delete'
                }
            ],
            tableData: [],
            loading: false,
            total: 0,
            // 新增/编辑
            titleText: '编辑',
            rowFormData_base: [
                { input_type: 'input', label: '设备名称', key: 'deviceName', maxlength: 30 },
                { input_type: 'input', label: '设备识别码', key: 'deviceNumber', maxlength: 64 },
                {
                    input_type: 'select', label: '设备大类', key: 'category', options: [],
                    relevance: { sonKey: 'subcategory', This: this, type: 'select', rqParam: { rqUrl: "/device/category", rqData: () => { return { params: { pageIndex: 1, pageSize: 200, isAll: '2', parentId: this.rowForm.category } } }, labelName: 'categoryName', valueName: 'id' }, }
                },
                { input_type: 'select', label: '设备小类', key: 'subcategory', options: [] },

                { input_type: 'input', label: '倍率', key: 'rate', hide: true },
                {
                    label: '所属楼栋', input_type: 'select', key: 'buildingId', options: [],
                },
                {
                    label: '所属楼层', input_type: 'select', key: 'floorId', options: [],
                },
                { input_type: 'select', label: '绑定空间', key: 'spaceId', options: [], },
                { input_type: 'input', label: '设备位置', key: 'installLocation', maxlength: 30 },
                { input_type: 'input', label: '设备品牌', key: 'brand', maxlength: 30 },
                { input_type: 'input', label: '设备型号', key: 'model', maxlength: 30 },
                // { input_type: 'select', label: '对接协议', key: 'protocol', options: [], clearable: true },
                { input_type: 'text2', label: '设备状态', key: 'runStatus', hide: true },
                { input_type: 'customize', label: '电表专业分类', customize: 'electricMajor', key: 'electricMajor',hide: true},
                { input_type: 'number', label: '设备运行时间',  hide: true, key: 'serviceLifeDuration', maxlength: 50 },
            ],
            rowFormData: [],
            rowFormDataMap: { category: 1, subcategory: 2, buildingId: 3, floorId: 4, roomId: 5 },
            rules: {
                deviceName: [{ required: true, trigger: 'change', message: '设备名称必填'}],
                deviceNumber: [{ required: true, trigger: 'change'}],
                installLocation: [{ required: false, trigger: 'change', validator: this.util.formRules.checkText("设备位置") }],
                brand: [{ required: false, trigger: 'change', validator: this.util.formRules.checkText("设备品牌") }],
                model: [{ required: false, trigger: 'change', validator: this.util.formRules.checkEN("设备型号") }],
                category: [{ required: true, message: '请选择设备大类', trigger: 'change' }],
                subcategory: [{ required: true, message: '请选择设备小类', trigger: 'change' }],
                buildingId: [{ required: true, message: '请选择所属楼栋', trigger: 'change' }],
                rate: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkNumber({ min: 0, isInt: true, isIncludeMin: false }) }]
            },
            rowForm: {},
            showEditAll: false, // 显示新增/编辑弹窗
            labelWidth: `${this.util.setFontSize(180)}px`,
            indexObj: {},
            conditionIndexObj:{}

        }
    },
    watch: {
        'rowForm.protocol': {
            handler: function (val) {
                if (!val) {
                    this.rowFormData = [...this.rowFormData_base]
                } else {
                    let arr1 = [
                        { input_type: 'input', label: 'iP地址', key: 'ipAddress' },
                        { input_type: 'input', label: '端口号', key: 'port' },
                        { input_type: 'input', label: '网关ID', key: 'gatewayId' },
                    ]
                    // let arr2 = [
                    //     { input_type: 'input', label: 'hls视频流地址', key: 'hlsUrl', col: 22 },
                    //     { input_type: 'input', label: 'rtsp视频流地址', key: 'rtspUrl', col: 22 },
                    //     { input_type: 'input', label: 'rtmp视频流地址', key: 'rtmpUrl', col: 22 },
                    // ]
                    // if (val === '0') {
                        this.rowFormData = [...this.rowFormData_base, ...arr1]
                    // } else {
                    //     this.rowFormData = [...this.rowFormData_base, ...arr2]
                    // }
                }
            }
        },
        'rowForm.category': {
            handler: function (val) {
                this.$set(this.rowFormData[this.indexObj.rate], 'hide', val !== 112)
            }
        },
        'rowForm.subcategory':{
            handler: function (val) {
                if(this.rowForm.category==118 && val==140){
                    this.$set(this.rowFormData[this.indexObj.serviceLifeDuration], 'hide', false)
                }else {
                     this.$set(this.rowFormData[this.indexObj.serviceLifeDuration], 'hide', true)
                }
                
                if(this.rowForm.category==112 && val==125){
                    this.$set(this.rowFormData[this.indexObj.electricMajor], 'hide', false)
                }else {
                     this.$set(this.rowFormData[this.indexObj.electricMajor], 'hide', true)
                }
            }
        }
    },
    created() {
        this.rowFormData = [...this.rowFormData_base]
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
        this.conditionIndexObj = this.util.getArrItemIndex({ arr: this.conditionData,});
    },
    mounted() {
        const paramObj = { params: { pageIndex: 1, pageSize: 200, isAll: '1' } }
        const rowParamObj = { pageIndex: 1, pageSize: 200 }
        // 顶部搜索框
        this.util.rqOptionsList({ who: [this.conditionData[this.conditionDataMap.category], this.rowFormData[this.indexObj.category]], rqUrl: "/device/category", rqData: paramObj, rqMethod: 'get', labelName: 'categoryName', valueName: 'id' }) // 获取设备大类列表
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id', resRule: 'data.data' }) // 楼栋选择
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.floorId], rqUrl: "/floor/allList", rqData: rowParamObj, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId', resRule: 'data' }) // 楼层选择
        // 表单里的
        
        this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id', resRule: 'data.data' }) // 楼栋选择
        this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.floorId], rqUrl: "/floor/allList", rqData: rowParamObj, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId', resRule: 'data' }) // 楼层选择
        this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.spaceId], rqUrl: "/spaceTemp/query", rqData: rowParamObj, rqMethod: 'post', labelName: 'spaceCode', valueName: 'id' }) // 空间选择
        // this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.protocol], rqUrl: "/sys/dictData/device_protocol", resRule: 'data' }) // 对接协议


    },
    methods: {
        getInit: function (item) { // 查询设备列表
            this.loading = true
            deviceInfoGet(this.filterData).then(res => {
                this.loading = false
                const { data, total } = res.data
                this.total = total
                this.tableData = data
            }).catch(err => {
                this.loading = false
            })
        },
        rowDelete: function (row) { // 删除
            this.$confirm('是否确认删除该设备信息').then(res => { // 删除设备列表信息
                deviceInfoDelete(row.id).then(res => {
                    this.$message.success(res.msg)
                    this.getInit()
                })
            })
        },
        saveForm: function () { // 保存
            this.loading_form.isShow = true
            if (this.isAdd) {
                const rowForm = this.rowForm
                console.log(rowForm);
                const date = new Date()
                rowForm.monitorTime = util.dateFormat('YYYY-mm-dd HH:MM:SS', date)
                const paramObj = this.util.paramChange(rowForm, this.queryTableId, 'edit')

                deviceInfoAdd(paramObj).then(res => { // 新增设备列表信息
                    this.loading_form.isShow = false
                    this.$message.success(res.msg)
                    this.showEditAll = false
                    this.getInit()
                }).catch(error => {
                    this.loading_form.isShow = false
                    // this.$message.error(error)
                })
            } else {
                const paramObj = this.util.paramChange(this.rowForm, this.queryTableId, 'edit')
                deviceInfoEdit(paramObj).then(res => { // 更新设备列表信息
                    this.loading_form.isShow = false
                    this.$message.success(res.msg)
                    this.showEditAll = false
                    this.getInit()
                }).catch(error => {
                    this.loading_form.isShow = false
                    // this.$message.error(error)
                })
            }
        },
        append: function () {
            this.$set(this.rowFormData[this.indexObj.runStatus], 'hide', true)
            this.isAdd = true
            this.titleText = '新增'
            this.rowForm = {}
            this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
            this.showEditAll = true
        },
        rowEdit: function (row) { // 编辑
            row.spaceId = row.spaceId === null ? '' : Number(row.spaceId)
            this.rowForm = {}
            this.$set(this.rowFormData[this.indexObj.runStatus], 'hide', false)
            this.isAdd = false
            this.titleText = '编辑'
            this.rowForm = { ...row }
            this.showEditAll = true
            this.$nextTick(() => {
                this.util.setOptionsInit({ arr: this.rowFormData, This: this, obj: this.rowForm, ref: 'jaForm' })
            })
        },
        querySearchAsync(queryString, cb) {
            getMeterType().then(res=>{
                let restaurants=res.data?.map(item=>{
                    return {
                        value:item
                    }
                })
                cb(restaurants)
            })
        },
    }
}
</script>

<style scoped>
</style>
