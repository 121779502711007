<template>
  <!--  指派 -->
  <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="45%" title="指派">
    <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" @changeVal="changeVal"></ja-form>
  </el-dialog>
</template>
<script>
import pageTypeObj from '../differPage'
import { assignOrderList,getAcceptNum } from '@/api'

export default {
  inject: ['labelWidth', 'getInit', 'pageType'],
  data() {
    return {
      rowFormData: [
        {
          label: '所属部门', input_type: 'select', key: 'solveDeptId',
          relevance: {
            sonKey: 'solveEmployeeIds', This: this, type: 'select', // 必填。而 rqParam 是异步请求，必填字段
            rqParam: { rqUrl: "/employee/dept/deptIds/repair", rqData: () => { return {deptIds:[this.rowForm.solveDeptId],orderId:this.rowForm.id} }, rqMethod: 'post', labelName: 'username', valueName: 'id', resRule: 'data' }, // 
          },col:22
        },
        { label: '人员姓名', input_type: 'select', key: 'solveEmployeeIds', multiple: true, options: [], rightBox: { text: '接单人数0/0' }, isFlex: true,method:'changeVal', col:22}, // 要注意，options 必写
        // { label: '接单人数', input_type: 'text2', key: 'acceptPersonNum' }, 
        { input_type: "input", label: '处理时限', text: 'solveLimitTime', col: 11, rightBox: { text: '分钟' }, isFlex: true, },
      ],
      rowForm: {},
      rules: {
        solveDeptId: [{ required: true, message: '请选择部门', trigger: 'change' }],
        solveEmployeeIds: [{ required: true, message: '请选择人员', trigger: 'change' }],
        solveLimitTime: [{ required: true, message: '请完善处理时限', trigger: 'blur' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      index_Obj: {},
    }
  },
  methods: {
    async rowEdit(row,) {
      this.rowForm = { id: row.id }
      let {data} = await getAcceptNum(row.id)
      console.log('data',data);
      this.rowFormData[0].options = [...this.$parent.$parent.allDepts]
      console.log(this.rowFormData);
      this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
      this.showEditAll = true
      this.$nextTick(() => {
        this.$refs.jaForm.$refs.formbox.clearValidate()
        this.rowFormData[1].rightBox.text = `接单人数${data.acceptedNum}/${data.totalLimit}`
      })
    },
    saveForm() {
      let obj = { ...this.rowForm }
      obj.solveEmployeeIds = obj.solveEmployeeIds.join(',')
      this.loadingForm = true
      // const rqMethod = pageTypeObj[this.pageType].assignOrder_fun
      const rqMethod = assignOrderList
      rqMethod(obj).then(res => {
        this.loadingForm = false
        this.$message.success(res.msg || '派单成功')
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },

    changeVal(data,data2,data3){
      // console.log(data,data2,data3);
    }
  }
}
</script>