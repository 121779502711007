<template>
  <div class="allwh">
    <ja-table
      v-model="filterData"
      :conditionData="conditionData"
      :columns="columns"
      :tableData="tableData"
      :total="total"
      :loading="loading"
      @getInit="getInit"
      @search="search"
      @reset="reset"
      @resend="resend"
    >
      <!-- <template #msgContent="{ row }">
        <div class="table_msgContent">
          {{ row.msgContentText }}
        </div>
      </template> -->
    </ja-table>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import { recordMsg, retryMsg } from '@/api'

export default {
  title: '发送记录',
  mixins: [table],
  data() {
    return {
      columns: [
        { label: '消息类型', prop: 'msgTypeName' },
        { label: '消息内容',column_type:'htmlStr', prop: 'msgContent', width: 140 },
        //  { label: '消息内容', prop: 'msgContent', width: 250, attribute: { showOverflowTooltip: true, align: 'center' }, slotName: 'msgContent' },
        { label: '发送人', prop: 'sender' },
        { label: '接收端', prop: 'receivingType' },
        { label: '接收人', prop: 'recipient' },
        { label: '来自任务', prop: 'taskName' },
        { label: '发送时间', prop: 'sendTime' },
        { label: '发送状态', prop: 'sendStatusName' },
        { label: '状态', prop: 'readStatusName' },
        { label: '备注', prop: 'remark' },
        // { label: '备注', prop: 'remark', attribute: { showOverflowTooltip: true, align: 'center' }, },
        // {
        //   label: '操作',
        //   input_type: 'button',
        //   width: 140,
        //   attribute: { fixed: 'right', align: 'center' },
        //   list: [{ text: '重发', method: 'resend' }]
        // }
      ],
      conditionData: [
        {
          input_type: 'date',
          type: 'daterange',
          value: 'time',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          valueFormat: 'yyyy-MM-dd',
          attribute: { unlinkPanels: true }
        },
        {
          input_type: 'select',
          key: 'msgType',
          attribute: { placeholder: '消息类型', size: 'small' },
          options: [],
          styleWidth: '100px'
        },
        {
          input_type: 'select',
          key: 'receivingType',
          attribute: { placeholder: '接收端', size: 'small' },
          options: [
            { label: 'APP', value: 'APP' },
            { label: 'WEB', value: 'WEB' },
            { label: '短信', value: '短信' }
          ],
          styleWidth: '100px'
        },
        {
          input_type: 'select',
          key: 'status',
          attribute: { placeholder: '发送状态', size: 'small' },
          options: [
            { label: '发送成功', value: 0 },
            { label: '发送失败', value: 1 }
          ],
          styleWidth: '100px'
        },
        {
          input_type: 'input',
          key: 'recipient',
          attribute: { placeholder: '接收人', size: 'small' }
        },
        {
          input_type: 'select',
          key: 'readStatus',
          attribute: { placeholder: '读取状态', size: 'small' },
          options: [
            { label: '未读', value: 0 },
            { label: '已读', value: 1 }
          ],
          styleWidth: '100px'
        },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' }
      ],
      indexObj: {}
    }
  },
  created() {
    this.indexObj = this.util.getArrItemIndex({ arr: this.conditionData })
    this.util.rqOptionsList({
      who: [this.conditionData[this.indexObj.msgType]],
      rqUrl: '/sys/dictData/msg_type',
      resRule: 'data'
    })
  },
  mounted() {
    //  console.log('this.filterData',this.filterData);
  },

  methods: {
    rqParmas() {
      let obj = { ...this.filterData }
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = true
      const rqData = this.rqParmas()
      recordMsg(rqData).then((res) => {
        // console.log(res);
        this.loading = false
         this.tableData=res.data.data
        this.total = res.data.total
        // const { data } = res.data
        // this.tableData = (data || [])
        //   .map(item => {
        //     let msgContent = item.msgContent
        //     item.msgContentText = msgContent
        //       .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        //       .replace(/<[^>]+?>/g, '')
        //       .replace(/\s+/g, ' ')
        //       .replace(/ /g, ' ')
        //       .replace(/>/g, ' ')
        //     return item
        //   })
         this.tableData.map(item=>{
          item.sendStatus===1?list=[
            { text: '重发', method: 'resend'}
          ] :''
         })
      })
    },
    // 重发
    resend(row) {
      //是否重发，取决于字段sendStatus（发送状态 0-发送成功 1-发送失败），等于0时，没有重发按钮，等于1时，有重发按钮。重发时需要有消息记录ID（id）
      this.$confirm(`是否确认重发消息类型“${row.msgTypeName}”`, '操作确认', {
        type: 'warning'
      }).then((res) => {
        retryMsg(row.id).then((res) => {
          this.$message.success(res.msg || '已重发')
          this.getInit()
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
