<template>
  <div class="entry bg" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="leftgif"></div>
    <div class="left">
     <div class="flow">
      <waterChart :waterData="waterData" v-if="loading"/>
     </div>
     <div class="flow" @click="openAlarmdialog">
      <alarmChart :alarmList="alarmList" v-if="loading"/>
     </div>
     <div class="flow">
      <tableBox :tableData="tableData" :title="'维修详情列表'" :columns="leftcolums" v-if="loading"/>
     </div>
    </div>
    <div class="rightgif"></div>
    <div class="right">
     <div class="flow">
      <tableBox :tableData="patrolData" :title="'实时巡更计划'" :columns="rightcolums" v-if="loading"/>
     </div>
     <div class="flow">
      <videopatrol />
     </div>
    </div>
    <div class="systembtn">
     <systemButton title="智慧安防" />
    </div>
    <div class="bottom"></div>

    <Dialog
      ref="dialog"
      :title="alarmTitle"
      :columns="columns"
      :getDatamethod="getEquipmentAlarm"
      :systemurl="systemurl"
      :dialogtype="1"
    >
    </Dialog>


  </div>
</template>

<script>
import headers from '../../../../dsWindow/IOC/header/index.vue'
import waterChart from '../components/waterChart/index.vue'
import alarmChart from '../components/alarmChart/index.vue'
import tableBox from '../components/tableBox/index.vue'
import Dialog from '../components/Dialog/index.vue'
import videopatrol from './videopatrol/videopatrol.vue'
import systemButton from '../components/systemButton/index.vue'
import { getWisdomsecurity,getEquipmentAlarm } from '@/api'

export default {
  title:'智慧安防',
  name:'wissecurity',
  components: {
    headers,
    waterChart,
    alarmChart,
    tableBox,
    Dialog,
    videopatrol,
    systemButton
  },
  data() {
    return {
      loading:false,
      systemurl:'',
      waterData:{},//水球图设备状态
      alarmList:[],//今日告警
      alarmTitle:'告警事件详情',
      tableData:[],//维修详情列表
      patrolData:[],//实时巡更计划列表
      leftcolums:[
        { label: '报修类别', prop: 'workOrderCategoryValue' },
        { label: '所在位置', prop: 'position' },
        { label: '工单状态', prop: 'statusValue' }
      ],//
      rightcolums:[
        { label: '工单名称', prop: 'wordOrderName' },
        { label: '下发日期', prop: 'time' },
        { label: '工单状态', prop: 'statusValue' }
      ],
      //传递到弹窗里的配置
      columns:[],
      getEquipmentAlarm:getEquipmentAlarm,
      dataInterval:null
    }
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
  },
  methods: {
    startDataInterval(){
      this.getDate()
      this.dataInterval = setInterval(() => {
            this.loading=false
            this.getDate()
      }, this.TIMER_INTERVAL);
    },
    //打开告警弹窗
    async openAlarmdialog(){
      this.columns = [
        { label: '事件名称', prop: 'eventName' },
        { label: '触发时间', prop: 'eventTime' },
        { label: '状态', prop: 'status' }
      ],
      this.$refs.dialog.showDialog();
    },
    async getDate(){
      let {data:res} = await getWisdomsecurity()
      this.waterData = {
        totalMonitor:res.totalMonitor,
        onlineMonitor:res.onlineMonitor,
        offlineMonitor:res.offlineMonitor
      }
      this.alarmList = res.alarmList.map(item=>{
        return {name:item.alarmTypeName,value:item.numberOfAlarms}
      })
      this.tableData = res.repairList
      this.patrolData = res.workOrderList



      this.loading = true
    }
  },
  mounted() {
    this.$emit('loading')
  },
  created(){
    this.startDataInterval()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';
.bg {
  height: 100%;
  position: relative;
  overflow: hidden;
  .scene {
            width: 100%;
            height: 100%;
            transform: scale(1);
        }
}
.entry {
  width: 100%;
  position: relative;
  // height: 5vw;
  // 导航栏
  .top {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.png');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  
   .leftgif {
    position: absolute;
    background: url('~@/assets/gd/left.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    left: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    z-index: 1;
    pointer-events: none;
   }
  .left {
    position: absolute;
    background: url('~@/assets/gd/left.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    left: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    .flow {
      height: 33%;
      width: 100%;
      flex: 1;
      display: flex;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

   .rightgif {
    position: absolute;
    background: url('~@/assets/gd/right.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    right: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px; 
    z-index: 1;
    pointer-events: none;
  } 
  .right {
    position: absolute;
    background: url('~@/assets/gd/right.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    right: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    padding-top: 40px;
    .flow {
      height: 50%;
      width: 100%;
      flex: 1;
      display: flex;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .bottom {
    height: 38px;
    width:66.5%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 1;
  }
  .systembtn {
    width: 36%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 85px;
  }
}
</style>
