<template>
  <div ref="wrap" class="wrap"></div>
</template>
<script>
import 'echarts-liquidfill'
export default {
  name: 'jaInitChart',
  data() {
    return {
      defaultOptions: {
        textStyle: {
          color: '#303133',
          fontSize: this.util.setFontSize(30, 1920)
        },
        grid: {
          left: '10%',
          right: '10%',
          top: '10%',
          bottom: '10%'
        },
        myChart: null,
        setTimer: null
      }
    }
  },
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    isMap: {
      type: Boolean,
      default: false
    },
    mapConfig: {
      type: Object,
      default: () => {
        return {
          name: 'china',
          data: {}
        }
      }
    },
    chartPlay: {
      type: Boolean,
      default: false
    },
    chartPlaymodule: {
      type: Boolean,
      default: false
    },
    isRetrieving:{
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.drawInit(this.options)

      if (this.isRetrieving){
        this.Retrieving()
      }
    })
    if (this.chartPlay) {
      this.startAutoHighlight()
    }
    
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    clearInterval(this.setTimer)
  },
  methods: {
    async drawInit(options) {
      options = { ...this.defaultOptions, ...options }
      this.myChart = this.$echarts.init(this.$refs.wrap)
      if (this.isMap && this.mapConfig.data) {
        console.log('信息', this.mapConfig, 'options-->', options)
        this.$echarts.registerMap(this.mapConfig.name, this.mapConfig.data)
        console.log('options', options)
      }

      // window.onresize = () => {
      //   this.ids.forEach(v => {
      //     this.$echarts.init(document.getElementById(v)).resize()
      //   })
      // }
      // myChart.setOption(options)
      await this.myChart.setOption(options)
      window.onresize = this.myChart.resize
    },
    startAutoHighlight() {
      let selectedIndex = 0
      this.setTimer = setInterval(() => {
        this.myChart.dispatchAction({
          type: 'highlight', // 选中数据
          seriesIndex: 0, // 第一个系列
          dataIndex: selectedIndex
        })

        this.myChart.dispatchAction({
          type: 'showTip', // 显示提示框
          seriesIndex: 0,
          dataIndex: selectedIndex
        })

        if (this.chartPlaymodule) {
          this.myChart.dispatchAction({
            type: 'highlight', // 选中数据
            seriesIndex: 1, // 第一个系列
            dataIndex: selectedIndex
          })

          this.myChart.dispatchAction({
            type: 'showTip', // 显示提示框
            seriesIndex: 1,
            dataIndex: selectedIndex
          })
        }

        setTimeout(() => {
          this.myChart.dispatchAction({
            type: 'downplay', // 取消选中状态
            seriesIndex: 0,
            dataIndex: selectedIndex
          })

          if (this.chartPlaymodule) {
            this.myChart.dispatchAction({
              type: 'downplay', // 取消选中状态
              seriesIndex: 1,
              dataIndex: selectedIndex
            })
          }

          selectedIndex++
          if (selectedIndex >= this.options.xAxis.data.length) {
            selectedIndex = 0
          }
        }, 4900)
      }, 5000) // 每隔5秒选中下一个数据
    },
    //回传点击数据的配置
    Retrieving(){
      this.myChart.on('click', function (params) {
        // 获取点击的系列名称
        var seriesName = params.seriesName;
        // 获取点击的数据名称
        var dataName = params.name;
        // 获取点击的数据值
        var dataValue = params.value;
        console.log('点击的系列名称:', seriesName);
        console.log('点击的数据名称:', dataName);
        console.log('点击的数据值:', dataValue);
      });
    }
  },
  watch: {
    options: {
      handler(val) {
        this.$emit(
          'getMaxY',
          this.myChart.getModel().getComponent('yAxis', 0)?.axis.scale
            ._extent[1]
        )
        this.drawInit(val)
      },
      deep: true
    },
    styles: {
      handler(val) {
        this.drawInit(val)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  height: 100%;
  width: 100%;
}
</style>
