import http from './plugin/ajax'

// =========  照明系统  ===========

// 照明管理新增
export const addLightList = (data) => http.put('/lightList/add', data)   
// 照明管理修改
export const editLightList = (data) => http.post('/lightList/update', data)  
// 照明管理查询
export const getLightList = (data) => http.post('/lightList/page', data)
// 照明管理删除
export const delLightList = (data) => http.delete('/lightList/'+ data)
// 开关
export const setSwitching = (data) => http.post('/lightList/setSwitching', data)

// =========  回路管理  ===========
// 回路管理新增
export const addCircuitManagement = (data) => http.put('/circuitManagement/add', data)  
// 回路管理修改
export const editCircuitManagement = (data) => http.post('/circuitManagement/update', data)  
// 回路管理查询
export const getCircuitManagement = (data) => http.post('/circuitManagement/page', data)  
// 回路管理删除
export const delCircuitManagement = (data) => http.delete('/circuitManagement/'+ data) 
// 回路管理删除前验证 
export const verifyCircuitManagement = (data) => http.get('/circuitManagement/'+ data)  
// 开关启前验证提示
export const circuitetSwitchPrompt = (data) => http.post('/circuitManagement/switchPrompt', data)
// 开关
export const circuitetSwitching = (data) => http.post('/circuitManagement/setSwitching', data)

// =========  场景管理  ===========
// 场景管理新增
export const addSceneManagement = (data) => http.put('/sceneManagement/add', data)   
// 场景管理修改
export const editSceneManagement = (data) => http.post('/sceneManagement/update', data)   
// 场景管理查询
export const getSceneManagement = (data) => http.post('/sceneManagement/page', data) 
// 场景管理查询
export const deleteSceneManagement = (data) => http.delete('/sceneManagement/'+ data)   
//获取场景编号
export const getSceneNumber = (data) => http.get('/sceneManagement', data)  
// 开关启前验证提示
export const sceneSwitchPrompt = (data) => http.post('/sceneManagement/switchPrompt', data)
// 开关
export const sceneSwitching = (data) => http.post('/sceneManagement/setSwitching', data)
// =========  照明日志  ===========
// 场景管理新增
export const getlightingLog = (data) => http.post('/lightingLog/page', data)   
