<template>
  <div class="entry bg" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="leftgif"></div>
    <div class="rightgif"></div>
    <div class="left">
     <div class="flow">
      <sectorChart :chartData="sectorData" v-if="loading"/>
     </div>
     <div class="flow" @click="openAlarmdialog">
      <alarmChart :alarmList="alarmList" v-if="loading"/>
     </div>
     <div class="flow">
      <tableBox :tableData="tableData" :columns="leftcolums" :title="'维修详情列表'" v-if="loading"/>
     </div>
    </div>
    <div class="right">
     <div class="flow" @click="openEnvsensorsdialog">
      <temperature :envSensors="envSensors" v-if="loading"/>
     </div>
     <div class="flow">
      <lineChart :temperature="temperature" v-if="loading"/>
     </div>
     <div class="flow">
      <tableBox :tableData="spaceData" :columns="rightcolums" :title="'室温空间排行'" :classtype="false" v-if="loading"/>
     </div>
    </div>
    <div class="systembtn">
      <systemButton title="智慧楼宇" />
    </div>
    <div class="bottom"></div>

    <Dialog
      ref="dialog"
      :title="alarmTitle"
      :columns="columns"
      :getDatamethod="getEquipmentAlarm"
      :systemurl="systemurl"
    >
    </Dialog>

    <Dialog
      ref="dialog2"
      :title="envSensorsTitle"
      :columns="columns"
      :getDatamethod="getSensors"
    >
    </Dialog>


  </div>
</template>

<script>
import headers from '../../../../dsWindow/IOC/header/index.vue'
import sectorChart from '../components/sectorChart/index.vue'
import alarmChart from '../components/alarmChart/index.vue'
import lineChart from '../components/lineChart/index.vue'
import temperature from './temperature/temperature.vue'
import tableBox from '../components/tableBox/index.vue'
import Dialog from '../components/Dialog/index.vue'
import systemButton from '../components/systemButton/index.vue'
import { getWisdombuild,getEquipmentAlarm,getSensors } from '@/api'

export default {
  title:'智慧楼宇',
  name:'wisbuilding',
  components: {
    headers,
    sectorChart,
    alarmChart,
    lineChart,
    temperature,
    tableBox,
    Dialog,
    systemButton
  },
  data() {
    return {
      loading:false,
      systemurl:'',
      sectorData:{},//设备状态
      alarmList:[],//今日告警
      alarmTitle:'告警事件详情',
      envSensors:{},//环境测量
      envSensorsTitle:'异常空间详情',
      temperature:{},//室内温度趋势
      tableData:[],//维修详情列表
      leftcolums:[
        { label: '报修类别', prop: 'workOrderCategoryValue' },
        { label: '所在位置', prop: 'position' },
        { label: '工单状态', prop: 'statusValue' }
      ],//
      spaceData:[],//室温空间排行
      rightcolums:[
        { label: '排行', prop: 'top' },
        { label: '空间', prop: 'space' },
        { label: '温度(℃)', prop: 'temperature' }
      ],
      //传递到弹窗里的配置
      columns:[],
      getEquipmentAlarm:getEquipmentAlarm,
      getSensors:getSensors,
      dataInterval:null
    }
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
  },
  methods: {
    startDataInterval(){
      this.getDate()
      this.dataInterval = setInterval(() => {
        this.loading=false
            this.getDate()
      }, this.TIMER_INTERVAL);
    },
    //打开告警弹窗
    async openAlarmdialog(){
      this.columns = [
        { label: '事件名称', prop: 'eventName' },
        { label: '触发时间', prop: 'eventTime' },
        { label: '状态', prop: 'status' }
      ],
      this.$refs.dialog.showDialog();
    },
    //打开异常空间弹窗
    openEnvsensorsdialog(){
      this.columns = [
        { label: '空间名称', prop: 'space' },
        { label: '异常数值', prop: 'abnormal' },
        { label: '异常时间', prop: 'time' }
      ],
      this.$refs.dialog2.showDialog();
    },
    async getDate(){
      let {data:res} = await getWisdombuild()
      // console.log(res);
      this.sectorData = {
        oneName:'照明设备',
        oneOn:res.buildDeviceVO.lightingOnline,
        oneOff:res.buildDeviceVO.lightingOffOnline,
        twoName:'空调设备',
        twoOn:res.buildDeviceVO.ariOnline,
        twoOff:res.buildDeviceVO.airOffOnline,
        threeName:'温控器设备',
        threeOn:res.buildDeviceVO.thermostatOnline,
        threeOff:res.buildDeviceVO.thermostatOffOnline
      }
      this.alarmList = res.alarmList.map(item=>{
        return {name:item.alarmTypeName,value:item.numberOfAlarms}
      })
      this.envSensors = res.envSensors
      res.weatherVO.forEach(item => {
        if(item.type == 0){
          this.temperature.indoor = item.weatherList
        }
        if(item.type == 1){
          this.temperature.outdoor = item.weatherList
          this.temperature.timer = item.timeSteam
        }
      })
      this.tableData = res.repairList
      this.spaceData = res.temperatureInDoorVO.slice(0,6)
      for (var i = 0; i < this.spaceData.length; i++) {
          this.spaceData[i].top = i + 1;
      }
      this.loading = true
    }
  },
  mounted() {
    this.$emit('loading')
  },
  created(){
    this.startDataInterval()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';
.bg {
  height: 100%;
  position: relative;
  overflow: hidden;
  .scene {
            width: 100%;
            height: 100%;
            transform: scale(1);
        }
}
.entry {
  width: 100%;
  position: relative;
  // height: 5vw;
  // 导航栏
  .top {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.png');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

   .leftgif {
    position: absolute;
    background: url('~@/assets/gd/left.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    left: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    z-index: 1;
    pointer-events: none;
   }
  .left {
    position: absolute;
    background: url('~@/assets/gd/left.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    left: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    .flow {
      height: 33%;
      width: 100%;
      flex: 1;
      display: flex;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .rightgif {
    position: absolute;
    background: url('~@/assets/gd/right.gif');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    right: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    z-index: 1;
    pointer-events: none;
  }
  .right {
    position: absolute;
    background: url('~@/assets/gd/right.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    right: 10px;
    top: 70px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    padding: 10px;
    .flow {
      height: 33%;
      width: 100%;
      flex: 1;
      display: flex;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .bottom {
    height: 38px;
    width:66.5%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 1;
  }
  .systembtn {
    width: 36%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 85px;
  }
}
</style>
