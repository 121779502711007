<template>
    <div>
        <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :valueFormat="formatter" :editDataFormat="editDataFormat" @rowDelete="rowDelete" @getInit="getInit" @append="append" @rowEdit="rowEdit" @showUser="showUser" @disabled="disabled" @enable="enable" @resetPassword="resetPassword" @editDataFormat="editDataFormat" @search="search" @reset="reset">
        </ja-table>
        <add-edit ref="addEdit"></add-edit>
        <show-information ref="showInformation"></show-information>
    </div>
</template>

<script>
import table from "@/views/mixins/table"
import { getEmployList, employeeDelete, disabledPersonnel, enablePersonnel, resetPasswordPersonnel } from '@/api'
import { userQueryColumn, userQueryColumnMap, userColumn } from './userColumn'
import AddEdit from './components/AddEdit.vue'
import ShowInformation from './components/ShowInformation.vue'
export default {
    title: '人员管理',
    mixins: [table],
    name: 'index',
    components: {
        AddEdit,
        ShowInformation,
    },
    data () {
        return {
            conditionData: userQueryColumn,
            conditionDataMap: userQueryColumnMap,
            columns: userColumn,
            tableData: [],
            loading: false,
            total: 0,
        }
    },
    methods: {
        getInit () { // 查询用户列表
            this.loading = true
            getEmployList(this.filterData).then(({ data }) => {
                //this.tableData = data.data
                if (data.data.length > 0) {
                    this.tableData = data.data.map(item => {
                        const obj = item
                        obj.statusPhone = obj.loginByMobile === 0 // 0-开启 1-关闭
                        obj.imgList = []
                        obj.imgList[0] = item.faceImg
                        return obj
                    })
                } else {
                    this.tableData = data.data
                }
                this.total = data.total
                this.loading = false
            })
        },
        formatter (val, key) {
            if (key === 'status') {
                return ['启用', '禁用'][val || 0]
            }
            return val || ''
        },
        rowDelete (row) {
            this.$confirm('是否确定删除？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                employeeDelete(row.id).then(res => {
                    this.getInit()
                    this.loading = false
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(res=>{
                    this.$message.error('取消错误，请稍后再试')
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '操作取消'
                });
            });
        },
        //新增
        append () {
            this.$refs.addEdit.init(1);
        },
        //修改
        rowEdit (row) {
            this.$refs.addEdit.init(2, row);
        },
        //查看
        showUser (row) {
            this.$refs.showInformation.init(row);
        },
        //禁用
        disabled (row) {
            if (row.deptStatus === 1) {
                this.$confirm('您已禁用该企业部门的登录权限，启用部门状态后可恢复登录', '确认提示', {
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    return false
                })
            } else {
                this.$confirm('禁用后该人员将被限制登录，是否确定禁用？', '确认提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let id = row.id
                    disabledPersonnel(id).then(res => {
                        this.$message({
                            type: 'success',
                            message: '禁用成功'
                        });
                        this.getInit()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消禁用'
                    });
                });
            }

        },
        //启用
        enable (row) {
            if (row.deptStatus === 1) {
                this.$confirm('您已禁用该企业部门的登录权限，启用部门状态后可恢复登录', '确认提示', {
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    return false
                })
            } else {
                this.$confirm('是否确定启用？', '确认提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let id = row.id
                    enablePersonnel(id).then(res => {
                        this.getInit()
                        this.$message({
                            type: 'success',
                            message: '启用成功!'
                        });
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消启用'
                    });
                });
            }

        },
        //重置密码
        resetPassword (row) {
            this.$confirm('是否重置为初始密码？', '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                let id = row.id
                resetPasswordPersonnel(id).then(res => {
                    this.loading = false
                    this.getInit()
                    this.$message({
                        type: 'success',
                        message: '密码已重置!'
                    });
                }).catch(res=>{
                    this.$message.error('重置失败,请稍后再试')
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消重置密码'
                });
            });
        },
        editDataFormat (row, btn) {
            if (row.status == 0 && btn.text == '启用' || row.status == 1 && btn.text == '禁用') {
                return false
            } else {
                return true
            }
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
