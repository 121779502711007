<template>
  <div class="u-header facab">
    <div :class="[showMenu ? 'aside-header':'hidden-aside']">智慧园区管理平台1.0</div>
    <div class="header-right fac">
      <div class="fac" v-if="roles">
        <!-- <el-dropdown>
          <span class="el-dropdown-link" >
            {{roles.nowParkName}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
            @click.native="changeParak(item)"
            v-for="(item, i) in parkList"
            :disabled="nowParkId==item.id?true:false"
            :key="i">{{item.parkName}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
        <el-badge
          :value="messageCount"
          :max="50"
        >
          <img
            class="message"
            src="/images/home/message.png"
            @click="getMessage"
          />
        </el-badge>
        <img
          class="message"
          src="/images/home/zoom.png"
          @click="screen"
        />
        <img
          class="user-img"
          :src='roles.headPortrait'
          v-if="roles.headPortrait"
        />
        <span class="user-name">{{roles.realName }}</span>
        <div class="dropdown">
          <i
            class="el-icon-arrow-down"
            v-if="!visible"
            @click="visible = !visible"
          ></i>
          <i
            class="el-icon-arrow-up"
            v-else
            @click="visible = !visible"
          ></i>
          <div
            class="personSet"
            v-if="visible"
          >
            <ul class="ulBox">
              <li @click="personCenter">个人中心</li>
              <li @click="logout">退出账号</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import {switchPark} from '@/api'
import {mapGetters}  from 'vuex'
export default {
  name: 'uHeader',
  data() {
    return {
      weatherInfor: {},
      imgSrc: '',
      admin: '',
      crumbList: [],
      size: 0,
      showMenu: true,
      visible: false,
      visiblePark:false,
      messageCount: '',
      nowParkId:"",
      parkList:[]
    }
  },
  computed:{
    ...mapGetters([
      "roles"
    ])
  },
  watch: {
    $route(val) {
      // this.dealMenuTitle(val)
    },
    '$store.state.user.total'(val){
      this.messageCount = val
    },
  },
  created() {
    this.loading = false
    this.parkList=this.roles.parkInfoList
    this.nowParkId=this.roles.nowParkId
  },
  methods: {
    dealMenuTitle(value) {
      if (value.name == '404') {
        // this.$message.error('页面不存在,即将跳转...')
        // setTimeout(()=>{
        //   this.$router.push("/")
        // },1500)
        this.crumbList.splice(0, this.crumbList.length)
        sessionStorage.removeItem('menu')
        this.$store.commit('SET_ACTIVEURL', '*')
        return
      }
      var arrUrl = value.fullPath.split('/')
      var activePage = arrUrl[arrUrl.length - 1]
      this.crumbList = [...this.$store.getters.menu_title[activePage].titleList]
      this.size = this.crumbList.length
      var isExist = this.crumbList.findIndex((item) => item === '首页')

      if (isExist < 0) {
        this.crumbList.unshift('首页')
      }
      if (this.$store.getters.menu_title[activePage]) {
        var activeMenuPath = this.$store.getters.menu_title[activePage].path
        sessionStorage.setItem('menu', activeMenuPath)
        this.$store.commit('SET_ACTIVEURL', activeMenuPath)
      } else {
      }
    },
    logout(val) {
      this.$store.commit('SET_ACTIVEURL', '')
      this.$store.dispatch('FedLogOut').then(() => {
        localStorage.removeItem('token')
        sessionStorage.removeItem('menu')
        this.$router.push('/login')
        this.$message.success('退出成功')
      })
    },
    gopersoncenter() {
      this.$router.push('/home/personalCenter')
    },
    backHome() {
      console.log('go')
      // this.$router.push('/home/homeIndex')
    },
    personCenter() {
      this.$router.push('/home/userManage/personList')
    },
    getMessage() {
      this.$router.push('/home/msgCenter/msgCe')
    },
    screen() {
      screenfull.toggle()
    },
    changeParak(item){
      switchPark({switchParkId : item.id}).then(res=>{
        if(res.code==200){
          this.$router.replace('/home/defaultPage');
          window.location.reload()
        }
      })

    }
  }
}
</script>
<style lang="scss">
.testtooltip.el-tooltip__popper[x-placement^='bottom'] .popper__arrow {
  border-bottom-color: white !important;
}
.testtooltip.el-tooltip__popper[x-placement^='bottom'] .popper__arrow:after {
  border-bottom-color: white !important;
}
.testtooltip {
  background: white !important;
  color: #606266 !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';

.u-header {
  height: 85px;
  background-color: $theme-color;
  box-sizing: border-box;
  padding-right: 10px;
  // color: $font-color;
  font-size: 12px;
  .aside-header {
    width: 300px;
    height: 85px;
    text-align: center;
    line-height: 85px;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 1);
    color: #ffffff;
  }
  .hidden-aside {
    width: 7.8%;
    font-size: 12px;
    font-weight: 500;
  }
  // .logo {
  //   width: 300px;
  //   height: 60px;
  //   display: flex;
  //   overflow: hidden;
  //   align-items: center;
  //   font-weight: 700;
  //   font-size: 20px;
  //   color: #ffffff;
  //   // background: $theme_background;
  //   background: white;
  //   img {
  //     width: 120px;
  //     margin-left:66px;
  //   }
  // }
  .icon {
    width: 141px;
    height: 43px;
  }
  .header-right {
    > div {
      margin-right: 12px;
    }
    .toopTip {
      background: pink;
    }
    .close {
      width: 26px;
      margin-left: 20px;
      margin-right: 20px;
      cursor: pointer;
    }

    .weather-icon {
      width: 45px;
      height: 45px;
      margin-right: 5px;

      &.max {
        width: 32px;
        height: 32px;
      }
    }
    .el-dropdown-link {
      color: $font-color;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
    .message {
      margin-left: 16px;
      cursor: pointer;
    }
    .user-img {
      margin: 0px 8px 0 16px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
    .user-name {
      font-size: 16px;
      font-weight: 500;
    }
    .dropdown {
      margin-left: 8px;
      position: relative;
      cursor: pointer;

      .personSet {
        position: absolute;
        width: 150px;
        height: 90px;
        background-color: rgba(50, 50, 50, 0.9);
        top: 30px;
        right: 0px;
        z-index: 99;
        color: #ffffff;
        text-align: center;

        li {
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
/deep/ .menud {
  font-size: 13px;
  width: 140px !important;
  text-align: center;
  line-height: 50px;
}
</style>
