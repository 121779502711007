<template>
  <div class="visualization" ref="visualization">
    <!-- <div class="vi_btn" @click="screenfullBtn">
      {{ isFullscreen ? '全屏' : '退出' }}
    </div> -->
    <router-view />
  </div>
</template>

<script>
import screenfull from 'screenfull'
export default {
  title: '嵌套网页',
  name: 'iframe',
  data() {
    return {
      // isFullscreen: true
    }
  },
  methods: {
    // screenfullBtn() {
    //   this.isFullscreen = !this.isFullscreen
    //   screenfull.toggle(this.$refs.visualization)
    // }
  }
}
</script>

<style scoped lang="scss">
// // 全屏状态
// :-webkit-full-screen .vi_btn {
//   width: 100px!important;
//   height: 32px!important;
//   font-size: 15px!important;
//   line-height: 32px!important;
// }
// .visualization {
//   position: relative;
//   .vi_btn {
//     background-image: url('~@/assets/gd/ds_qpbtn.png');
//     background-repeat: no-repeat;
//     background-size: 100% 100%;
//     cursor: pointer;
//     position: absolute;
//     width: 80px;
//     height: 24px;
//     line-height: 24px;
//     font-size: 14px;
//     color: #fff;
//     text-align: center;
//     right: 30px;
//     top: 14px;
//     z-index: 999;
//   }
// }
</style>
