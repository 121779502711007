<template>
    <div>
        <ja-table ref="tst"
                  v-model="filterData"
                  :conditionData="conditionData"
                  :columns="columns"
                  :tableData="tableData"
                  :total="total"
                  :loading="loading"
                  :editDataFormat="editDataFormat"
                  @getInit="getInit"
                  @search="search"
                  @reset="reset"
                  @confirmReceipt="confirmReceipt"
                  @lookRoom="lookRoom">
        </ja-table>
        <show-receiving-records ref="receivingRef"></show-receiving-records>
    </div>
</template>

<script>
import { getReceiveList } from '@/api'
import table from '@/views/mixins/table'
import showReceivingRecords from './showReceivingRecords.vue'
export default {
    title: '领取记录',
    mixins: [table],
    name: 'toRentList',
    components: {
        showReceivingRecords,
    },
    data() {
        return {
            conditionData: [
                // 表格上方的搜索/选择操作栏
                { input_type: 'input', key: 'materialNumber', attribute: { placeholder: '物资编号', size: 'small' } },
                { input_type: 'select', key: 'materialType', attribute: { placeholder: '物资类型', size: 'small' }, options: [] },
                { input_type: 'input', key: 'materialName', attribute: { placeholder: '物资名称', size: 'small' } },
                { input_type: 'input', key: 'applicant', attribute: { placeholder: '申请人', size: 'small' } },
                { input_type: 'select', key: 'receiveStatus', attribute: { placeholder: '领取状态', size: 'small' }, options: [] },
                { normalBtn: 'search' },
                { normalBtn: 'reset' },
            ],
            columns: [
                // 表头数据
                { label: '申请人', prop: 'applicant' },
                { label: '申请时间', prop: 'createTime' },
                { label: '物资编号', prop: 'materialNumber' },
                { label: '物资类型', prop: 'materialTypeName' },
                { label: '物资名称', prop: 'materialName' },
                { label: '申请数量（个）', prop: 'forNumber' },
                { label: '物资申请原因', prop: 'remark' },
                { label: '领取状态', prop: 'receiveStatusName' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: {
                        fixed: 'right',
                        align: 'center'
                    },
                    list: [
                        { text: '查看', method: 'lookRoom', limit: 'materialLists::receivingRecords::show' },
                        { text: '确认领取', method: 'confirmReceipt', limit: 'materialLists::receivingRecords::confirmReceipt' }
                    ]
                }
            ],
            tableData: [],
            loading: false,
            total: 30
        }
    },
    mounted() {
        let index1 = this.conditionData.findIndex((e) => e.key === 'materialType')
        this.util.rqOptionsList({
            who: [this.conditionData[index1]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'material_type' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
        let index2 = this.conditionData.findIndex((e) => e.key === 'receiveStatus')
        this.util.rqOptionsList({
            who: [this.conditionData[index2]],
            rqData: { pageIndex: 1, pageSize: 10, dictType: 'receive_status' },
            rqUrl: '/sys/dictData/query',
            rqMethod: 'post',
            labelName: 'dictLabel',
            valueName: 'dictValue',
        })
    },
    methods: {
        getInit() {
            getReceiveList(this.filterData)
                .then((res) => {
                    if (res.code === 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total
                        this.loading = false
                    }
                })

        },
        lookRoom(row) {
            this.$refs.receivingRef.init(2,row)
        },
        confirmReceipt(row) {
            this.$refs.receivingRef.init(1, row)
        },
        editDataFormat(val,btn){
            if(val.receiveStatus == '1' && btn.text === '确认领取'){
                return false
            }else{
                return true
            }
        }
    }
}
</script>

