<template>
    <el-dialog :visible.sync="show"
               :close-on-click-modal="false"
               width="50%"
               :title="title">
        <ja-form :rules="rules"
                 v-if="show"
                 v-model="rowForm"
                 :formArray="rowFormData"
                 :allDisabled="allDisabled"
                 @adopt="adopt"
                 @reset="show = false"
                 @save="saveForm">

        </ja-form>
    </el-dialog>
</template>
<script>
import { editWaterElList } from '@/api'
export default {
    inject: ['getInit'],
    data() {
        return {
            rules: {
                lastReading: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }],
                thisReading: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }],
                currentReading: [{ required: true, trigger: 'blur', validator: this.util.formRules.checkNumber({ fixedNum: 2 }) }],
            },
            rowFormData: [
                { input_type: 'input', label: '设备名称', key: 'deviceName', labelWidth: '110px', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '设备识别码', key: 'deviceNumber', labelWidth: '110px', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '楼栋', key: 'buildingName', labelWidth: '110px', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '楼层', key: 'floorName', labelWidth: '110px', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '租赁单元名称', key: 'roomName', labelWidth: '110px', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '上次读数', key: 'lastReading', labelWidth: '110px', col: 12, maxlength: 30 },
                { input_type: 'input', label: '本次读数', key: 'thisReading', labelWidth: '110px', col: 12, maxlength: 30 },
                { input_type: 'input', label: '倍率', key: 'rate', labelWidth: '110px', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '抄表周期', key: 'date', labelWidth: '110px', col: 12, disabled: true, placeholder: ' ' },
                { input_type: 'input', label: '本期用量', key: 'currentReading', labelWidth: '110px', col: 12, maxlength: 30 },
            ],
            show: false,
            allDisabled: false,
            title: '账单审批',
            rowForm: {},
            totle: 0

        }
    },
    methods: {
        async init(state = 1, row) {
            this.title = '编辑水电明细'
            this.allDisabled = false
            this.show = true
            this.rowForm = {}
            this.rowForm = { ...row }
            if (state == 2) {
                this.title = row.status == 1 ? '已提交查看' : '待提交查看'
                this.allDisabled = true
            }
        },
        saveForm() {
            editWaterElList(this.rowForm).then(res => {
                this.$message.success('修改成功')
                this.getInit()
                this.show = false
            })
        },
    }
}
</script>
<style lang="scss" scoped>
/deep/.facac {
    justify-content: flex-end;
}
</style>
