import http from './plugin/ajax'

// =========  访客模块  ===========

// 访客黑名单
export const getVisitorBlackList = (data) => http.post('/visitor/black/list', data)   // 查询
export const addVisitorBlack = (data) => http.post('/visitor/black', data)   // 新增
export const delVisitorBlack = (data) => http.delete('/visitor/black/' + data)   // 删除

// 访客通行记录
export const getVisitorPassRecord = (data) => http.post('/person/access', data)   // 查询

// 门禁通行记录
export const getAccessInfo = (data) => http.post('/access/getInfo', data)   // 查询

// 查询公司信息列表
export const companyList = () => http.get('/person/access/company/list')   // 查询
// 园区下租户信息
export const companyList2 = () => http.get('/tenant/selectList')   // 查询


// 访客申请列表
export const checkVistorBlack = (data) => http.post('/visitor/apply/validation', data)   // 检验访客黑名单
export const rqVisitorApplyList = (data) => http.post('/visitor/apply/record', data)   // 查询
export const addVisitorApply = (data) => http.post('/visitor/apply', data)   // 新增
export const rqVisitorApplyItem = (data) => http.get('/visitor/apply/' + data)   // 查询单个
export const approvalVisitorApply = (data) => http.get('/visitor/apply/audit', { params: data })   // 访客审批


// 访客申请统计
export const rqVistorCountList = (data) => http.post('/visitor/info', data)   // 查询

export const failPage = (data) => http.post('/access/failPage', data)   // 门禁失败事件

export const getAccessControlRecord = (data) => http.post('/access/getAccessControlRecord', data)   // 通行记录

export const getVisitorPage = (data) => http.post('/visitor/getVisitorPage', data)   // 访客记录





