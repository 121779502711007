<template>
  <div class="dswindow">
    <!-- 安防监控窗口 -->
    <div class="securitybox">
      <div class="btn"></div>
      <!-- <p>视频预览</p> -->
      <div class="securitymsg">
        <div class="securityleft">
          <div>设备名称:{{deviceName}}</div>
          <!-- <div>设备位置:<span>5楼大厅</span></div>
          <div>设备状态:<span>在线</span></div> -->
        </div>
        <div class="securityright">
          <p>{{msg}}</p>
          <mian-video v-show="deviceNumber" :deviceNumber.sync="deviceNumber"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MianVideo from './MianVideo.vue'
import { getDevicemsg } from '@/api'
export default {
  components: {
    MianVideo
  },
  data() {
    return {
      loading: false,
      deviceName:'',
      deviceNumber:'',
      msg:''
    }
  },
  mounted() {
    this.$emit('loading')
    if(this.$route.query.deviceCode){
      if(this.$store.state.token){
        this.getDevice(this.$route.query.deviceCode)
      }
    }else{
      this.deviceName = '未获取到设备'
      this.msg = '设备连接异常...'
    }
  },
  methods:{
    async getDevice(code){
      let {data:res} = await getDevicemsg(code)
      this.deviceName = res.deviceName
      this.deviceNumber = res.deviceNumber
    }
  },
  watch:{
    '$store.state.token':{
      deep:true,
      handler(val){
        console.log('token变化了',val);
        this.getDevice(this.$route.query.deviceCode)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
    width: 100%;
    height: 100%;
    padding-top: 20px;
  .securitybox {
    position: relative;
    width: 100%;
    height:100%;
    background: url('~@/assets/gd/ds_watercard.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 22px 23px;
    font-size: 20px;
    color: #fff;
    .btn{
      background: url('~@/assets/gd/ds_closebtn.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -19px;
      top: -20px;
      width: 38px;
      height: 44px;
    }
    p {
      text-align: center;
    }
    .securitymsg {
      display: flex;
      width: 100%;
      height: 95%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 25px 30px 0 30px;
      font-size: 18px;
      .securityleft {
        width:100%;
        height: 30px;
        div {
          margin-bottom: 15px;
        }
        span {
          color: #3aa1ff;
          margin-left: 5px;
        }
      }
      .securityright {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #e9e9e9;
        > p {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translate(0, -50%);
          color: #333333;
          font-size: 34px;
        }
      }
    }
  }

}
</style>