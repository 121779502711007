<template>
  <div class="container">
    <div id="player">
      <div class="ioc">
        <div class="top entry">
          <headers title="德舜大厦可视化管理平台"></headers>
        </div>
        <div class="topgif"></div>
        <div class="topgif2"></div>
        <router-view />
      </div>
      <iframe class="scene" :src="url"></iframe>
    </div>
  </div>
</template>

<script>
import headers from '../../../dsWindow/IOC/header/index.vue'
export default {
  title:'智慧模块',
  components:{
    headers
  },
  data(){
    return{
      url:'http://121.37.251.55:8280/#/?model=GD&time' + new Date().getTime(),
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .container {
  margin: 0 !important;
  height: 100vh;
  #player {
    height: 100vh;
    width: 1918px;
    .ioc {
      width: 1918px;
      height: 100vh;
      transform: scale(1); /* 100 / 1920 = 0.0521 */
      transform-origin: top left;
    }
  }
}
.container {
  position: relative;
  // margin: 0 15px;
  .top {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.png');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 11;
  }
  .topgif {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.gif');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 11;
  }
  .topgif2 {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head-2.gif');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 12;
  }
  #player {
    min-height: 400px;
    height: 100vh;
    background-image: url('~@/assets/gd/floorbg.jpg')!important;
    position: relative;
    overflow: hidden;
    .ioc {
      position: absolute;
      width: 1918px;
      height:100vh;
      background: transparent;
      // transform: scale(0.83); /* 100 / 1920 = 0.0521 */
      transform-origin: top left;
      z-index: 10;
    }
    .scene {
          width: 100%;
          height:100%;
          top: 0;
          left: 0;
          position: absolute;
          z-index: 1;
        }
  }
}
</style>