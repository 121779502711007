<template>
    <div class="box">
        <div id="player">
            <iframe class="scene" v-if="iframeShow" name="lightingIframe" :src="url" @load="emitToken()"></iframe>
        </div>
        <!-- 配置回路按钮 -->
        <div class="lightconfig" @click="openLightConfig"></div>
        <!-- 配置回路弹窗 -->
        <div class="configwindow">
            <loopConfig ref="lightconfig"  @lightConfig="lightConfig"></loopConfig>
        </div>
        <!-- 回路列表按钮 -->
        <div class="loopButton">
            <loopButton @displayStatus="displayStatus" ref="loopButton" :url="url"></loopButton>
        </div>
        <!-- 回路当前区域卡片 -->
        <div class="loopCard" v-show="isShowLoopCard">
            <loopCard :loopData="loopData"></loopCard>
        </div>
        <!-- 右侧固定面板 -->
        <div class="rightPanel" v-if="false">
            <abnormalCard class="abnormalCard"></abnormalCard>
            <abnormalstoreyCard></abnormalstoreyCard>
        </div>
        

        <!-- <div class="systembtn">
           <div v-for="item in systemArr" :class="item.style" :key="item.text" @click="jump(item)">
            <span>{{item.text}}</span>
           </div>
        </div> -->
        <div class="bottom"></div>
    </div>
</template>
<script>
const IS_MOVE_DEVICE = document.body.clientWidth < 992
import { mapState, mapGetters } from "vuex";
import loopConfig from '../../../../dsWindow/lighting/loopConfig/index.vue'
import loopButton from '../../../../dsWindow/lighting/loopButton/index.vue'
import loopCard from '../../../../dsWindow/lighting/loopCard/index.vue'
import abnormalCard from '@/views/dsWindow/lighting/abnormalCard/index.vue'
import abnormalstoreyCard from '@/views/dsWindow/lighting/abnormalstoreyCard/index.vue'
import { setSwitching , getLightList , getPersonalmess } from "@/api";
export default {
    data () {
        return {
             systemArr:[
                {text:'安防系统',url:'/home/visualManagement/securitySystem',style:'two'},
                // {text:'能源系统',url:'/home/visualManagement/powersupplySystem',style:'two'},
                {text:'暖通系统',url:'/home/visualManagement/conditioningSystem',style:'two '},
                {text:'照明系统',url:'/home/visualManagement/lghtingSystem',style:'two clickfloor'},
                // {text:'停车系统',url:'/home/visualManagement/parkingSystem',style:'two'},
                // {text:'电梯系统',url:'',style:'two'},
                // {text:'给排水系统',url:'/home/visualManagement/waterConservancySystem',style:''},
            ],
            url:'http://121.37.251.55:8280/#/?model=A塔照明5F&time' + new Date().getTime(),
            iframeShow: true, 
            isShowLoopCard:false, 
            loopData:{},
            deviceData: []
        }
    },
    async mounted () {
       window.addEventListener('message',async  e => {
            await this.getDevicdeData()
            // 通过origin对消息进行过滤，避免遭到XSS攻击
            if (this.url.indexOf(e.origin)!=-1) {
                let {parameter}=e.data
                let deviceNumber=parameter.split("=")[1]
                let state
                this.deviceData.forEach(item => {
                    if(item.deviceNumber == deviceNumber){
                        //在线发送关灯，离线发送开灯
                        if(item.runStatus == 1){
                            this.$message({
                                message: '设备离线',
                                type: 'error',
                            })
                            return
                        }
                        state = item.switching == 'true' ? false : true
                        //灯泡匹配上才发送请求
                        this.setSwitching({ deviceNumbers: [deviceNumber], switching: state })
                    }
                })
            }
        }, false);
    },
    components:{
      loopConfig,
      loopButton,
      loopCard,
      abnormalCard,
      abnormalstoreyCard
    },
    created() {
        getPersonalmess()
    },
    methods: {
        async getDevicdeData(){
             if (this.url.indexOf('照明') != -1) {
                let { data: res } = await getLightList({
                    pageIndex: 1,
                    pageSize: 9999,
                    // category: 118,
                    // subcategory: 140
                    buildingId: this.buildingFloor.buildingId,
                    floorId: this.buildingFloor.floorId
                })
                this.deviceData = res.data
            }
        },
        async emitToken() {
            await this.getDevicdeData()
            let deviceArr = []
            this.deviceData.map(item => {
                deviceArr.push(
                    {
                        status: item.switching == 'true' ? '开灯':'关灯',
                        deviceNumber: item.deviceNumber,
                        state: item.runStatus == 2 ? '正常' : '离线'
                    }
                )
            })
            console.log(deviceArr);
            const token = localStorage.getItem('token')
            // const token = JSON.stringify(this.$store.state.token)
            // console.log(token, token == JSON.stringify(this.$store.state.token) ? true:false,JSON.stringify(this.$store.state.token));
            let obj = {
                token: token,
                operationStatus: 'tokenmsg',
                system: '照明',
                deviceArr: deviceArr
            }
            lightingIframe.window.postMessage(obj, 'http://121.37.251.55:8280');
        },
        jump(i){
            this.$router.push({path:`${i.url}`})
        },
        iframeLoad(){
            this.iframeShow = false
            this.$nextTick(function () {
                this.iframeShow = true 
            })
        },
        openLightConfig(){
            this.$refs.lightconfig.showDialog()
        },
        /**鼠标移入移除回路按钮列表 */
        displayStatus(item){
            this.loopData=item;
            this.isShowLoopCard=item.mouseStatus
        },
        /**单个设备的开关 */
        setSwitching(data){
            //根据状态发送变化后的图标地址和灯泡编码
             let obj = {
                imgurl: data.switching ? 'http://121.37.251.55:8280/file/modelImage/light.png' : 'http://121.37.251.55:8280/file/modelImage/照明系统-灯icon-关闭@2x.png',
                operationStatus: 'lightIcon',
                deviceNumbers:data.deviceNumbers[0]
            }
            setSwitching(data).then(res=>{
                if(res.code == 200){
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    lightingIframe.window.postMessage(obj, 'http://121.37.251.55:8280');
                    this.getDevicdeData()
                }
            })
        },
        /**回路配置确认按钮 */
        lightConfig(){
            // 初始化回路列表数据
            this.$refs.loopButton.init()
        }

    },
    computed: {
        ...mapGetters(['buildingFloor']),
        ...mapState({
            systemUrl: (state) => state.visualsystem.systemUrl,
        })
    }, 
    watch:{
        systemUrl(newVal){
            this.url = newVal
            this.iframeLoad()
        }
    },
}
</script>
<style lang="scss" scoped>
// 全屏状态
:-webkit-full-screen .box{
    height: 100vh;
    .system_btn{
        height: 147px;
        .btnbox{
            padding: 45px 100px;
            button{
                width: 160px;
                height: 60px;
                font-size: 26px;
                line-height: 26px;
            }
        }
    }
    #player{
        background-image: url('~@/assets/gd/floorbg.jpg')!important;
        height: 100vh;
        width:1918px;
        .scene {
        width: 1918px;
        // height:100vh;
        transform: scale(1); /* 100 / 1920 = 0.0521 */
        top: 0;
        left: 0;
        }
    }
}
.box{
    position: relative;
    height: 100%;
    width: 100%;
    .lightconfig{
        background-image: url('~@/assets/gd/ds_lightconfig.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 144px;
        height: 34px;
        position: absolute;
        top: 0;
        left: 280px;
        z-index: 10;
        cursor: pointer;
    }
    .configwindow{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 99;
    }
    .loopButton{
        position: absolute;
        right: 50%;
        bottom: 20%;
        transform: translate(50%,0);
        z-index: 10;
    }
    .loopCard{
        position: absolute;
        left: 20%;
        top: 25%;
        z-index: 10;
    }
    .rightPanel{
        position: absolute;
        right: 25%;
        top: 8%;
        z-index: 10;
        .abnormalCard{
            margin-bottom: 20px;
        }
    }
    .systembtn {
    width: 52%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-52%, 0);
    height: 90px;
    display: flex;
    justify-content: space-around;
    .clickfloor{
      background: url('~@/assets/gd/ds_systembtnx.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    > div {
      position: relative;
      width: 90px;
      height: 90px;
      background: url('~@/assets/gd/ds_systembtn.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      span {
        display: inline-block;
        position: absolute;
        width: 60px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.86px;
        text-shadow: 0 1px 1px #002d59;
        font-weight: 700;
      }
    }
    .two{
        span{
           width: 45px;
        }
      }
    > div:hover {
      background: url('~@/assets/gd/ds_systembtnx.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .bottom {
    height: 55px;
    width:100%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 1;
  }
}
// @media screen and (max-width: 991px) {
//     #player {
//         width: 90%;
//         height: 98%;
//     }
// }
// @media screen and (max-width: 1440px) {
//     #player {
//         background-color: #000;
//          .scene {
//             width: 1918px;
//             height: 929px;
//             transform: scale(0.8);
//             left: -250px;
//         }
//     }
//     :-webkit-full-screen #player{
//             background: #000;
//             height: 100vh;
//             width:1918px;
//             .scene {
//             width: 1918px;
//             height: 929px;
//             transform: scale(0.9)!important; /* 100 / 1920 = 0.0521 */
//             top: 0!important;
//             left: -8%!important;
//             }
//     }
// }
#player{
    min-height: 400px;
    height: 83vh;
    background-image: url('~@/assets/gd/floorbg.jpg')!important;
    position: relative;
    overflow: hidden;
    iframe {
    width: 1918px;
    height: 929px;
    transform: scale(0.9); /* 100 / 1920 = 0.0521 */
    // transform-origin: top left;
    position: absolute;
    top: -100px;
    left: -100px;
    }
}
.el-main {
    min-height: 400px;
    height: 82vh;
    background: #fff;
    position: relative;
    margin-right: 10px;
}
.header {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px 20px;
    .el-switch {
        .el-switch__core {
            width: 35px !important;
        }
    }
}
</style>