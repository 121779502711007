<template>
  <div class="bg box_bg">
    <div class="header">
      <div class="headerleft">预约车辆趋势</div>
    </div>

    <div class="charts">
      <ja-init-chart class="jachart" :options="options" :chartPlay="true" :chartPlaymodule="true" />
    </div>
  </div>
</template>

<script>
import echarts from '/node_modules/echarts';
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  props:{
    alarmList:{
      type:Array,
      default:()=>[]
    }
  },
  components: {
    JaInitChart
  },
  data() {
    return {
      alarmList: [],
      xAxisData: [0, 0, 0, 0, 0, 0,0],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = false
      this.options = {
        grid: {
          left: '1%',
          right: '5%',
          bottom: '1%',
          height: '80%',
          containLabel: true
        },
        graphic: {
          elements: [
            {
              type: 'text',
              left: '5',
              top: '0',
              style: {
                text: '单位 / 辆',
                fontSize: 13,
                fill: '#818a98'
              }
            }
          ]
        },
        xAxis: {
          type: 'category',
          data: ['9.16', '9.17', '9.18', '9.19', '9.20', '9.21','9.22'],
          axisLine: {
            lineStyle: {
              color: '#1A3E47',
              width: 1
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 12
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
            inside: true, 
            lineStyle: {
              // 设置刻度线样式
              color: '#fff', // 刻度线颜色
              width: 2 // 刻度线宽度
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisLabel: {
            // interval: 8,
            color: '#818a98',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: '#1A3E47'
            }
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '人流量',
            data: this.xAxisData,
            animationDuration: 1500,
            type: 'line',
            smooth: true,
            symbol: 'circle',
            label: {
              normal: {
                show: true,
                color: '#ffffff',
                textStyle: {
                  fontSize: 14
                }
              }
            },
            emphasis: {
              focus: 'series', // 选中状态
              itemStyle: {
                color: '#FFCE14' // 选中时的颜色
              },
              label: {
                color: '#FFCE14'
              }
            },
            itemStyle: {
              normal: {
                color: '#18ff74',
                borderColor: '#fff',
                borderType: 'solid',
                lineStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#18ff74' },
                    { offset: 1, color: '#12924b' }
                  ])
                }
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(24,255,116,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,179,244,0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(18,146,75, 0.9)',
                shadowBlur: 20
              }
            }
          },
          {
            type: 'bar',
            animation: false,
            barWidth: 1,
            hoverAnimation: false,
            data: this.xAxisData,
            tooltip: {
              show: false
            },
            itemStyle: {
              normal: {
                color: '#1A3E47',
                label: {
                  show: false
                }
              }
            },
            emphasis: {
              focus: 'series', // 选中状态
              itemStyle: {
                color: '#FFCE14' // 选中时的颜色
              }
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  .charts{
    margin-top: 40px;
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .header {
    margin-top: 20px;
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 10px;
    height: 100%;
    position: relative;
    top: -5px;
    display: flex;
    .jachart {
      z-index: 2;
      width: 100%;
      height: 200px;
    }
  }
}
</style>