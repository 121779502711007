<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="查看详情" :width="`50%`">
      <ja-detail
        :options="detailOptions"
        :detailData="detailData"
        :label_width="8"
        v-if="showDetail"
        :isButton="isButton"
        :btnBox="btnBox"
        @commit="commitBtn"
      >
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import { commitAlarm } from '@/api/module/alarm.js'
export default {
  inject: ['getInit'],
  data () {
    return {
      showDetail: false,
      commitid:'',
      detailOptions: [
        { label: '告警时间', key: 'alarmTime' },
        { label: '告警名称', key: 'alarmName' },
        { label: '告警类型', key: 'alarmLevelName' },
        { label: '设备大类', key: 'categoryName' },
        { label: '告警等级', key: 'alarmLevel' },
        { label: '设备', key: 'alarmDeviceName' },
        { label: '所在楼栋', key: 'alarmBuildingName' },
        { label: '所在楼层', key: 'alarmFloorName' },
        { label: '告警位置', key: 'alarmPosition' },
        // { label: '捕捉对象', key: 'catchObj' },
        // { label: '通行抓拍', key: 'catchImg', type: 'img'},
        { label: '处理状态', key: 'statusName' },
        { label: '告警描述', key: 'alarmDesc' ,col:22}
      ],
      detailData: {},
      categoryLink_idObj: {},
      isButton:true,
      btnBox: [{ label: '转告警工单', method: 'commit', type: 'primary', }]
    }
  },
  methods: {
    rowDetail (row) {
      if(row.statusName == '待处理'){
        this.commitid = row.id
        this.isButton = true
      }else{
        this.isButton = false
      }
      this.showDetail = false
      this.detailData = row
      this.showDetail = true
    },
    async commitBtn(){
      let {data:res} = await commitAlarm(this.commitid)
      console.log(res);
      this.getInit()
      this.showDetail = false
    }
  }
}
</script>
