<template>
  <div class="slotPart" :style="{height: height}">
    <p style="visibility: hidden;">{{height}}</p>
  </div>
</template>

<script>
export default {
  name: 'slotPart',
  props: {
    height: {
      type: String,
      default: '1%'
    }
  }
}
</script>
