// 巡更
import http from './plugin/ajax'

/**
 * 巡更点
 */
// 列表 
export const pointList = (data) => http.post('/patrol/point/list', data)   
// 刷新二维码
export const refreshQRcode = (id) => http.put(`/patrol/point/refresh/${id}`)    
// 新增
export const addPoint = (data) => http.post('/patrol/point', data)   
// 修改
export const updatePoint = (data) => http.put('/patrol/point', data)   
// 删除
export const deleteById = (id) => http.delete(`/patrol/point/${id}`)   

/**
 * 巡更路线
 */
// 列表 
export const pathList = (data) => http.post('/patrol/path/list', data)   
// 新增
export const addPath = (data) => http.post('/patrol/path', data)   
// 修改
export const updatePath = (data) => http.put('/patrol/path', data)    
// 删除
export const deleteByPathId = (id) => http.delete(`/patrol/path/${id}`)   
// 所有点位
export const pointAllList = () => http.get('/patrol/point/all')   
// 所有线路
export const pathAllList = () => http.get('/patrol/path/all')   
// 发布
export const pushLine = (id) => http.put(`/patrol/path/publish/${id}`)   
// 作废
export const cancellation = (id) => http.put(`/patrol/path/cancellation/${id}`)   

/**
 * 巡更计划
 */
// 列表 
export const planList = (data) => http.post('/patrol/plan/list', data)   
// 新增
export const addPlan = (data) => http.post('/patrol/plan', data)   
// 修改
export const updatePlan = (data) => http.put('/patrol/plan', data)    
// 删除
export const deleteByPlanId = (id) => http.delete(`/patrol/plan/${id}`)   
// 发布
export const pushPlan = (id) => http.put(`/patrol/plan/publish/${id}`)   
// 作废
export const cancePlan = (id) => http.put(`/patrol/plan/cancellation/${id}`)  

// 消防培训
export const rqFireTraining = (data) => http.get('/fire/training', { params: data })   // 查询 
export const editFireTraining = (data) => http.put('/fire/training', data)    // 修改 
export const addFireTraining = (data) => http.post('/fire/training', data)    // 添加 
export const delFireTraining = (data) => http.delete('/fire/training/' + data)   // 删除 一项