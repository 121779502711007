import http from './plugin/ajax'


// 租户信息
export const addTenant = (data) => http.post('/tenant', data)   // 添加 
export const editTenant = (data) => http.put('/tenant', data)    // 修改 
export const exportTenant = (data) => http.post('/tenant/export', data)  // 导出 
export const rqTenant = (data) => http.post('/tenant/list', data)   // 查询 列表
export const delTenant = (data) => http.delete('/tenant/' + data)   // 删除 一项
export const auditTenant = (data) => http.put('/tenant/audit/' + data)   //  审批 一项
export const submitTenant = (data) => http.put('/tenant/submit/' + data)   //  提交 一项

// =========  租户人员列表  ============
export const rqTenantEmployee = (data) => http.get('/tenant/tenantEmployee', { params: data })   // 查询 
export const editTenantEmployee = (data) => http.put('/tenant/tenantEmployee', data)    // 修改 
export const addTenantEmployee = (data) => http.post('/tenant/tenantEmployee', data)    // 添加 
export const delTenantEmployee = (data) => http.delete('/tenant/tenantEmployee/' + data)   // 删除 一项

export const getDictData = (data) => http.get('/sys/dictData/' + data)

export const getstatusType = (data) => http.get('/sys/dictData/status_type', { params: data })