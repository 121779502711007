import http from './plugin/ajax'

// 设备列表-新增设备列表信息
export const deviceInfoAdd = (data) => http.post('/device/info/add', data)

// 设备列表-更新设备列表信息
export const deviceInfoEdit = (data) => http.put('/device/info/edit', data)

// 设备列表-删除设备列表信息
export const deviceInfoDelete = (data) => http.delete('/device/info/' + data)
// 设备列表-电表专业分类
export const getMeterType = (data) => http.get('/device/info/getMeterType')

export const refreshQpcodeInspection = (data) => http.get('/device/info/updateQrCode/' + data)

export const deviceInfoGet = (data) => http.get('/device/info', {
    params: data
})
// 查询暖通设备
export const conditioningPost = (data) => http.post('/device/info/getHVAC', data)
//排序信息查询
export const deviceInfoPost = (data) => http.post('/device/info/page', data)

//设备台账
export const getLedgerList = (data) => http.post('/iotDeviceRecordTemp/list', data)   // 查询

// 设备分组-新增设备分组信息
export const deviceGroupAdd = (data) => http.post('/device/group/add', data)

// 设备分组-更新设备分组信息
export const deviceGroupEdit = (data) => http.put('/device/group/edit', data)

// 设备分组-删除设备分组信息
export const deviceGroupDelete = (data) => http.delete('/device/group/' + data)

// 设备类型-新增设备类型信息
export const deviceCategoryAdd = (data) => http.post('/device/category/add', data)

// 设备类型-更新设备类型信息
export const deviceCategoryEdit = (data) => http.put('/device/category/edit', data)

// 设备类型-禁用/启用设备类型信息
export const deviceCategoryDelete = (data) => http.delete('/device/category/' + data)

/** 获取设备类型 */
export const getDeviceList = () => http.get('/device/category/')

// 设备维保
export const rqInspectionList = (data) => http.post('/inspection/plan/list', data)   // 查询
export const editInspection = (data) => http.put('/inspection/plan/update', data)    // 修改
export const addInspection = (data) => http.post('/inspection/plan/add', data)    // 添加
export const rqInspectionItem = (data) => http.get('/inspection/plan/' + data)   // 查询 列表
export const delInspection = (data) => http.put('/inspection/plan/' + data)   // 删除 一项

//设备维保
export const addMaintenance = (data) => http.post('/iot/main/add', data) //新增
export const editMaintenance = (data) => http.put('/iot/main/update', data) //编辑
export const deleteMaintenance = (data) => http.delete('/iot/main/delete/' + data) //删除
export const getMaintenanceLists = (data) => http.post('/iot/main/query', data) //查询

//报修记录
export const getGraphicalPie = () => http.get('/work/info/statistics') //查询环形图

export const getGraphicalBar = () => http.get('/work/info/statistics7') //查询柱状图

export const getWorkOrderList = (data) => http.post('/work/info/list', data) //查询工单列表


//报修统计
export const getStatistics = (data) => http.get('/deviceStatistics', { params: data })//查询列表数据
export const getHeadData = (data) => http.get('/deviceStatistics/reportStatistics', { params: data })//查询头部信息
export const getThreshold = (data) => http.get(`/sys/config/key/${data}`)//查询阈值
export const setThreshold = (data) => http.put('/sys/config', data)//修改阈值
