<template>
  <!--人员布控列表 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="normalEvent({ref:'addEdit',method:'append'})" @rowDetail="normalEvent" @rowEdit="normalEvent" @rowDelete="rowDelete" @getInit="getInit" @search="search" @reset="reset" 
    @cancellation="cancellation" @push="push" :editDataFormat="editDataFormat">
      <template #pictureUrl="{row}">
        <el-image style="width: 50px; height: 50px" :src="row.pictureUrl" :preview-src-list="[row.pictureUrl]" fit="cover"></el-image>
      </template>
    </ja-table>
    <!-- 新增 -->
    <add-edit ref="addEdit" />

  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import { pathList,deleteByPathId,cancellation,pushLine} from '@/api'
export default {
  title: '巡更线路',
  mixins: [table],
  components: {
    AddEdit,
  },
  data() {
    return {
      conditionData: [
        { input_type: 'select', key: 'status', attribute: { placeholder: '路线状态', size: 'small' },options:[{label:'待发布',value:0},{label:'已发布',value:1}] },
        { input_type: 'input', key: 'pathName', attribute: { placeholder: '巡更路线名称', size: 'small' } },
        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增', },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqexport, rqUrl: '/patrol/path/export', rqMethod: 'post' }}

      ],
      columns: [
        { label: '巡更路线名称', prop: 'pathName', },
        { label: '巡更路线编号', prop: 'pathCode', },
        { label: '路线点位总数', prop: 'pointNum', },
        { label: '最少拍照数', prop: 'imgMinLimit', },
        { label: '创建人', prop: 'createBy', },
        { label: '创建时间', prop: 'createTime', },
        { label: '路线状态', prop: 'statusName', },
        { label: '线路描述', prop: 'pathDesc', },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '作废', method: 'cancellation', },
            { text: '编辑', method: 'rowEdit', },
            { text: '发布', method: 'push', },
            { text: '删除', method: 'rowDelete', },
          ]
        },
      ],
      indexObj: {},

    }
  },
 
  methods: {
    rqexport() {
      let obj = { ...this.filterData }
      obj.pageIndex = this.total <= 2000 ? 1 : this.filterData.pageIndex
      obj.pageSize = 2000
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = false
      const rqData = this.rqParmas()
      pathList(rqData).then(res => {
        const { total, data } = res.data
        this.total = total
        this.tableData = data
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该条数据？').then(res => {
        deleteByPathId(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },

    push({id}){
      this.$confirm('是否确认发布该条数据？').then(res => {
        pushLine(id).then(res => {
          this.$message.success(res.msg)
          this.getInit()
        })
      })
    },
    cancellation({id}){
      this.$confirm('是否确认作废该条数据？').then(res => {
        cancellation(id).then(res => {
          this.$message.success(res.msg)
          this.getInit()
        })
      })
    },

    editDataFormat(row, btn) {
      if(row.status === 1 && btn.text ==='作废'){
        return true
      }

      if(row.status === 0 && ['发布','编辑','删除',].indexOf(btn.text)!=-1){
        return true
      }

      if(row.status === 2 && btn.text ==='删除'){
        return true
      }
      return false
    },
  }
}
</script>
