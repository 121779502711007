<template>
    <div class="box">
        <div id="player">
            <iframe class="scene" v-if="iframeShow" name="iframe" :src="url" @load="emitToken()"></iframe>
        </div>

        <!-- <div class="systembtn">
           <div v-for="item in systemArr" :class="{'clickfloor':item.text == title ? true : false,'two': item.style == 'two' ? true : false}" :key="item.text" @click="jump(item)">
            <span>{{item.text}}</span>
           </div>
        </div> -->
        <div class="bottom"></div>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { deviceInfoGet, conditioningPost, getPersonalmess } from '@/api'
export default {
    props: {
        url: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            systemArr: [
                { text: '安防系统', url: '/home/visualManagement/securitySystem', style: 'two' },
                // {text:'能源系统',url:'/home/visualManagement/powersupplySystem',style:'two'},
                { text: '暖通系统', url: '/home/visualManagement/conditioningSystem', style: 'two' },
                { text: '照明系统', url: '/home/visualManagement/lghtingSystem', style: 'two' },
                // {text:'停车系统',url:'/home/visualManagement/parkingSystem',style:'two'},
                // {text:'电梯系统',url:'',style:'two'},
                // {text:'给排水系统',url:'/home/visualManagement/waterConservancySystem',style:''},
            ],
            iframeShow: true,
            timerId:null,
            deviceData:[]
        }
    },
    created(){
        getPersonalmess()
    },
    methods: {
        async getDevicdeData(){
             if (this.url.indexOf('安防') != -1) {
                let { data: res } = await deviceInfoGet({
                    pageIndex: 1,
                    pageSize: 9999,
                    category: 110,
                    subcategory: 121,
                })
                this.deviceData = res.data
            }else if(this.url.indexOf('暖通') != -1){
                let {data} = await conditioningPost({})
                this.deviceData = data
            }
        },
        async emitToken() {
                await this.getDevicdeData()
                let deviceArr = []
                this.deviceData.map(item => {
                    deviceArr.push(
                        {
                            status:item.runStatus,
                            deviceNumber:item.deviceNumber
                        }
                    )
                })
                console.log(deviceArr);
                const token = localStorage.getItem('token')
                // const token = JSON.stringify(this.$store.state.token)
                // console.log(token, token == JSON.stringify(this.$store.state.token) ? true:false,JSON.stringify(this.$store.state.token));
                let obj = {
                    token: token,
                    operationStatus: 'tokenmsg',
                    system: this.url.indexOf('安防') != -1 ? '安防' : '暖通',
                    deviceArr: deviceArr
                }
                iframe.window.postMessage(obj, 'http://121.37.251.55:8280');
        },
        jump(i) {
            this.$router.push({ path: `${i.url}` })
        },
        iframeLoad() {
            this.iframeShow = false
            this.$nextTick(function () {
                this.iframeShow = true
            })
        }
    },
    computed:{
    ...mapGetters(['buildingFloor']),
    ...mapState({
        systemUrl: (state) => state.visualsystem.systemUrl,
    })
    },
    watch: {
        systemUrl(newVal) {
            this.url = newVal
            this.iframeLoad()
        }
    }
}
</script>
<style lang="scss" scoped>
// 全屏状态
:-webkit-full-screen .box {
    height: 100vh;

    .system_btn {
        height: 147px;

        .btnbox {
            padding: 45px 100px;

            button {
                width: 160px;
                height: 60px;
                font-size: 26px;
                line-height: 26px;
            }
        }
    }

    #player {
        background-image: url('~@/assets/gd/floorbg.jpg') !important;
        height: 100vh;
        width: 1918px;

        .scene {
            width: 1918px;
            // height:100vh;
            transform: scale(1);
            /* 100 / 1920 = 0.0521 */
            top: 0;
            left: 0;
        }
    }
}

.box {
    height: 100%;
    width: 100%;
    // .system_btn{
    //     position: absolute;
    //     height: 80px;
    //     width: 100%;
    //     box-sizing: border-box;
    //     padding-left: 250px;
    //     bottom: 0;
    //     right: 0;
    //     // background-color: #060E39;
    //     .btnbox{
    //         display: flex;
    //         justify-content: space-around;
    //         align-items: center;
    //         padding: 15px 80px;
    //         button{
    //             width: 133px;
    //             height: 50px;
    //             font-size: 22px;
    //             line-height: 22px;
    //             color: #fff;
    //             background: url('~@/assets/gd/system_btn.png') no-repeat;
    //             background-size: 100% 100%;
    //             border: none;
    //         }
    //         button:hover{
    //             cursor: pointer!important;
    //         }
    //     }
    // }

    .systembtn {
        width: 52%;
        position: absolute;
        bottom: 56px;
        left: 50%;
        transform: translate(-52%, 0);
        height: 90px;
        display: flex;
        justify-content: space-around;

        .clickfloor {
            background: url('~@/assets/gd/ds_systembtnx.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        >div {
            position: relative;
            width: 90px;
            height: 90px;
            background: url('~@/assets/gd/ds_systembtn.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;

            span {
                display: inline-block;
                position: absolute;
                width: 60px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                letter-spacing: 0.86px;
                text-shadow: 0 1px 1px #002d59;
                font-weight: 700;
            }
        }

        .two {
            span {
                width: 45px;
            }
        }

        >div:hover {
            background: url('~@/assets/gd/ds_systembtnx.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }

    .bottom {
        height: 55px;
        width: 100%;
        position: absolute;
        background: url('~@/assets/gd/foot.png');
        background-size: 100% 100%;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
    }
}

// @media screen and (max-width: 991px) {
//     #player {
//         width: 90%;
//         height: 98%;
//     }
// }
// @media screen and (max-width: 1440px) {
//     #player {
//         background-color: #000;
//          .scene {
//             width: 1918px;
//             height: 929px;
//             transform: scale(0.8);
//             left: -250px;
//         }
//     }
//     :-webkit-full-screen #player{
//             background: #000;
//             height: 100vh;
//             width:1918px;
//             .scene {
//             width: 1918px;
//             height: 929px;
//             transform: scale(0.9)!important; /* 100 / 1920 = 0.0521 */
//             top: 0!important;
//             left: -8%!important;
//             }
//     }
// }
#player {
    min-height: 400px;
    height: 83vh;
    background-image: url('~@/assets/gd/floorbg.jpg') !important;
    position: relative;
    overflow: hidden;

    iframe {
        width: 1918px;
        height: 929px;
        transform: scale(0.9);
        /* 100 / 1920 = 0.0521 */
        // transform-origin: top left;
        position: absolute;
        top: -100px;
        left: -100px;
    }
}

.el-main {
    min-height: 400px;
    height: 82vh;
    background: #fff;
    position: relative;
    margin-right: 10px;
}

.header {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px 20px;

    .el-switch {
        .el-switch__core {
            width: 35px !important;
        }
    }
}</style>