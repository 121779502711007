import echarts from '/node_modules/echarts';
function CreateenergyChart(beforeYesterdayEnergy, yesterdayEnergy,that) {
    // 显示值存起来防止被下面逻辑改了
    let beforeYesterdayEnergyRoot=beforeYesterdayEnergy
    let yesterdayEnergyRoot=yesterdayEnergy
    // 当前天或昨天值为零时设置为0.01防止图表0时渲染错乱
    if(beforeYesterdayEnergy==0){
        beforeYesterdayEnergy=0.01
    } 
    if(yesterdayEnergy==0){
        yesterdayEnergy=0.01
    }
    
    let blueArr = {
        top: [
            {
                offset: 0,
                color: '#154F8C',
            }, {
                offset: 0.25,
                color: '#1CAACB',
            },
            {
                offset: 0.5,
                color: '#1BBBDD',
            },
            {
                offset: 0.75,
                color: '#1CAACB',
            },
            {
                offset: 1,
                color: '#154F8C',
            },
        ],
        bottom: [
            {
                offset: 0,
                color: '#1963C4',
            },
            {
                offset: 0.2,
                color: '#1988CC',
            },
            {
                offset: 0.4,
                color: '#198CCD',
            },
            {
                offset: 0.6,
                color: '#1E68CD',
            },
            {
                offset: 0.7,
                color: '#1CAACB',
            },
            {
                offset: 0.1,
                color: '#1DB0CC',
            },
            {
                offset: 1,
                color: '#1176C8',
            },
        ],
        middle: [
            {
                offset: 0,
                color: '#1963C4',
            },
            {
                offset: 0.2,
                color: '#1988CC',
            },
            {
                offset: 0.4,
                color: '#198CCD',
            },
            {
                offset: 0.6,
                color: '#1E68CD',
            },
            {
                offset: 0.7,
                color: '#1CAACB',
            },
            {
                offset: 0.1,
                color: '#1DB0CC',
            },
            {
                offset: 1,
                color: '#1176C8',
            },
        ],
        shadowColor: '#43DEF1',
        textColor: '#49F8FF'
    }
    let yellowArr = {
        top: [
            {
                offset: 0,
                color: '#CCBE30',
            }, {
                offset: 0.25,
                color: '#ECE81A',
            },
            {
                offset: 0.5,
                color: '#FFF63E',
            },
            {
                offset: 0.75,
                color: '#ECE81A',
            },
            {
                offset: 1,
                color: '#CCBE30',
            },
        ],
        middle: [
            {
                offset: 0,
                color: '#848427',
            },
            {
                offset: 0.2,
                color: '#F1DF20',
            },
            {
                offset: 0.4,
                color: '#CCB600',
            },
            {
                offset: 0.6,
                color: '#B0A301',
            },
            {
                offset: 0.7,
                color: '#E5D54D',
            },
            {
                offset: 0.1,
                color: '#ABA31B',
            },
            {
                offset: 1,
                color: '#838429',
            },
        ],
        bottom: [
            {
                offset: 0,
                color: '#848427',
            },
            {
                offset: 0.2,
                color: '#F1DF20',
            },
            {
                offset: 0.4,
                color: '#CCB600',
            },
            {
                offset: 0.6,
                color: '#B0A301',
            },
            {
                offset: 0.7,
                color: '#E5D54D',
            },
            {
                offset: 0.1,
                color: '#ABA31B',
            },
            {
                offset: 1,
                color: '#838429',
            },
        ],
        shadowColor: '#F8E71C',
        textColor: '#F8E71C'
    }
    let total
    // 当前天、昨天值均零时设置total为100
    if(beforeYesterdayEnergyRoot==0 && yesterdayEnergyRoot==0){
        total=100
    }else{
        total = beforeYesterdayEnergy > yesterdayEnergy ? beforeYesterdayEnergy + (beforeYesterdayEnergy * 0.4) : yesterdayEnergy + (yesterdayEnergy * 0.4)
    }
    let options = {
        grid: {
            top: '18%',
            height: '55%',
            containLabel: true
        },
        // legend: {
        //   data: ['前天能耗', '昨天能耗'],
        //   top:'0',
        //   left: '70%',
        //   itemWidth: 19,
        //   itemHeight: 12,
        //   textStyle: {
        //     color: '#01CDFD',
        //     fontSize: 12
        //   }
        // },
        xAxis: {
            data: ['百分比',],
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
        },
        yAxis: {
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
        },
        series: [
            {
                name: '最上层立体圆',
                type: 'pictorialBar',
                symbolSize: [that.ChartSize(100), that.ChartSize(30)],
                symbolOffset: [that.ChartSize(-100), that.ChartSize(-20)],
                z: 15,
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                                {
                                    offset: 0,
                                    color: '#2866a3',
                                }, {
                                    offset: 0.2,
                                    color: '#113151',
                                },
                                {
                                    offset: 0.8,
                                    color: '#113151',
                                },
                                {
                                    offset: 1,
                                    color: '#2866a3',
                                },
                            ]),
                        shadowColor: '#2a5e91',
                        shadowBlur: 1.5,
                        shadowOffsetX: 0,
                        shadowOffsetY: 3,
                    },
                },
                data: [
                    {
                        value: total,
                        symbolPosition: 'end',
                    },
                ],
            },
            {
                name: '中间立体圆',
                type: 'pictorialBar',
                symbolSize: [that.ChartSize(100), that.ChartSize(30)],
                symbolOffset: [that.ChartSize(-100), that.ChartSize(-20)],
                z: 12,
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(1, 0, 0, 0, beforeYesterdayEnergy > yesterdayEnergy ? yellowArr.top : blueArr.top),
                        shadowColor: beforeYesterdayEnergy > yesterdayEnergy ? yellowArr.shadowColor : blueArr.shadowColor,
                        shadowBlur: 1.5,
                        shadowOffsetX: 0,
                        shadowOffsetY: 3,
                    },
                },
                data: [
                    {
                        value: beforeYesterdayEnergy,
                        symbolPosition: 'end',
                    },
                ],
            },
            {
                name: '最底部立体圆',
                type: 'pictorialBar',
                silent: true,
                symbolSize: [that.ChartSize(100), that.ChartSize(35)],
                symbolOffset: [that.ChartSize(-100), that.ChartSize(20)],
                z: 10,
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(0, 0, 1, 0, beforeYesterdayEnergy > yesterdayEnergy ? yellowArr.bottom : blueArr.bottom),

                    },
                },
                data: [beforeYesterdayEnergy],
            },
            {
                name: '最底部立体圆阴影',
                type: 'pictorialBar',
                symbolSize: [that.ChartSize(135), that.ChartSize(60)],
                symbolOffset: [that.ChartSize(-100), that.ChartSize(35)],
                z: 2,
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                                {
                                    offset: 0,
                                    color: '#112965',
                                },
                                {
                                    offset: 1,
                                    color: '#0D2B59',
                                },
                            ]),
                    },
                },
                data: [beforeYesterdayEnergy],
                label: {
                    offset: [that.ChartSize(-100), that.ChartSize(38)],
                    show: true,
                    position: 'bottom',
                    formatter: '前天能耗',
                    fontSize: that.ChartSize(14),
                    color: '#00FCFF',
                },
            },
            {
                name: '最底部立体（边框）',
                type: 'pictorialBar',
                symbolSize: [that.ChartSize(135), that.ChartSize(60)],
                symbolOffset: [that.ChartSize(-100), that.ChartSize(35)],
                z: 10,
                itemStyle: {
                    normal: {
                        color: 'transparent', // 设置填充颜色为透明
                        borderColor: '#36D6F0', // 设置边框颜色
                        borderWidth: that.ChartSize(5), // 设置边框宽度
                    },
                },
                data: [beforeYesterdayEnergy],

            },
            {
                //底部立体柱
                stack: '1',
                z: 11,
                type: 'bar',
                itemStyle: {
                    normal: {
                        // color: 'rgba(8,44,110, 0.94)',
                        color: () =>
                            new echarts.graphic.LinearGradient(0, 0, 1, 0, beforeYesterdayEnergy > yesterdayEnergy ? yellowArr.middle : blueArr.middle),
                        opacity: 0.95,
                    },
                },
                silent: true,
                barWidth: that.ChartSize(100),
                data: [beforeYesterdayEnergy],
            },
            {
                //上部立体柱
                label: {
                    offset: [that.ChartSize(0), that.ChartSize(-20)],
                    show: true,
                    position: 'top',
                    formatter: beforeYesterdayEnergyRoot + 'kW·h',
                    fontSize: that.ChartSize(14),
                    color: beforeYesterdayEnergy > yesterdayEnergy ? yellowArr.textColor : blueArr.textColor,
                    textShadowColor: '#00FF98',
                    textShadowBlur: 30,
                    textShadowOffsetX: 5,
                    textShadowOffsetY: 10,
                },
                stack: '1',
                z: 1,
                type: 'bar',
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                {
                                    offset: 0,
                                    color: '#1963C467',
                                },
                                {
                                    offset: 0.2,
                                    color: '#198ACD50',
                                },
                                {
                                    offset: 0.3,
                                    color: '#198CCD29',
                                },
                                {
                                    offset: 0.7,
                                    color: '#198CCD27',
                                },
                                {
                                    offset: 0.8,
                                    color: '#1176C841',
                                },
                                {
                                    offset: 1,
                                    color: '#1963C467',
                                },
                            ]),
                    },
                },
                silent: true,
                barWidth: that.ChartSize(100),
                barGap: '100%', // Make series be overlap
                data: [total - beforeYesterdayEnergy],
            },





            {
                name: '最上层立体圆',
                type: 'pictorialBar',
                symbolSize: [that.ChartSize(100), that.ChartSize(30)],
                symbolOffset: [that.ChartSize(100), that.ChartSize(-20)],
                z: 16,
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                                {
                                    offset: 0,
                                    color: '#2866a3',
                                }, {
                                    offset: 0.2,
                                    color: '#113151',
                                },
                                {
                                    offset: 0.8,
                                    color: '#113151',
                                },
                                {
                                    offset: 1,
                                    color: '#2866a3',
                                },
                            ]),
                        shadowColor: '#2a5e91',
                        shadowBlur: 1.5,
                        shadowOffsetX: 0,
                        shadowOffsetY: 3,
                    },
                },
                data: [
                    {
                        value: total,
                        symbolPosition: 'end',
                    },
                ],
            },
            {
                name: '中间立体圆',
                type: 'pictorialBar',
                symbolSize: [that.ChartSize(100), that.ChartSize(30)],
                symbolOffset: [that.ChartSize(100), that.ChartSize(-20)],
                z: 12,
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(1, 0, 0, 0, beforeYesterdayEnergy < yesterdayEnergy ? yellowArr.top : blueArr.top),
                        shadowColor: beforeYesterdayEnergy < yesterdayEnergy ? yellowArr.shadowColor : blueArr.shadowColor,
                        shadowBlur: 1.5,
                        shadowOffsetX: 0,
                        shadowOffsetY: 3,
                    },
                },
                data: [
                    {
                        value: yesterdayEnergy,
                        symbolPosition: 'end',
                    },
                ],
            },
            {
                name: '最底部立体圆',
                type: 'pictorialBar',
                silent: true,
                symbolSize: [that.ChartSize(100), that.ChartSize(35)],
                symbolOffset: [that.ChartSize(100), that.ChartSize(20)],
                z: 11,
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(0, 0, 1, 0, beforeYesterdayEnergy < yesterdayEnergy ? yellowArr.bottom : blueArr.bottom),

                    },
                },
                data: [yesterdayEnergy],
                label: {
                    offset: [that.ChartSize(100), that.ChartSize(38)],
                    show: true,
                    position: 'bottom',
                    formatter: '昨天能耗',
                    fontSize: that.ChartSize(14),
                    color: '#00FCFF',
                },
            },
            {
                name: '最底部立体圆阴影',
                type: 'pictorialBar',
                symbolSize: [that.ChartSize(135), that.ChartSize(60)],
                symbolOffset: [that.ChartSize(100), that.ChartSize(35)],
                z: 6,
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                                {
                                    offset: 0,
                                    color: '#112965',
                                },
                                {
                                    offset: 1,
                                    color: '#0D2B59',
                                },
                            ]),
                    },
                },
                data: [yesterdayEnergy],
            },
            {
                name: '最底部立体（边框）',
                type: 'pictorialBar',
                symbolSize: [that.ChartSize(135), that.ChartSize(60)],
                symbolOffset: [that.ChartSize(100), that.ChartSize(35)],
                z: 7,
                itemStyle: {
                    normal: {
                        color: 'transparent', // 设置填充颜色为透明
                        borderColor: '#36D6F0', // 设置边框颜色
                        borderWidth: that.ChartSize(5), // 设置边框宽度
                    },
                },
                data: [yesterdayEnergy],
            },
            {
                //中间立体柱
                stack: '2',
                z: 11,
                type: 'bar',
                itemStyle: {
                    normal: {
                        // color: 'rgba(8,44,110, 0.94)',
                        color: () =>
                            new echarts.graphic.LinearGradient(0, 0, 1, 0, beforeYesterdayEnergy < yesterdayEnergy ? yellowArr.middle : blueArr.middle),
                        opacity: 0.95,
                    },
                },
                silent: true,
                barWidth: that.ChartSize(100),
                data: [yesterdayEnergy],
            },
            {
                //上部立体柱
                label: {
                    offset: [that.ChartSize(0), that.ChartSize(-20)],
                    show: true,
                    position: 'top',
                    formatter: yesterdayEnergyRoot + 'kW·h',
                    fontSize: that.ChartSize(14),
                    color: beforeYesterdayEnergy < yesterdayEnergy ? yellowArr.textColor : blueArr.textColor,
                    textShadowColor: '#F8E71C',
                    textShadowBlur: 30,
                    textShadowOffsetX: 5,
                    textShadowOffsetY: 10,
                },
                stack: '2',
                z: 1,
                type: 'bar',
                itemStyle: {
                    normal: {
                        color: () =>
                            new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                {
                                    offset: 0,
                                    color: '#1963C467',
                                },
                                {
                                    offset: 0.2,
                                    color: '#198ACD50',
                                },
                                {
                                    offset: 0.3,
                                    color: '#198CCD29',
                                },
                                {
                                    offset: 0.7,
                                    color: '#198CCD27',
                                },
                                {
                                    offset: 0.8,
                                    color: '#1176C841',
                                },
                                {
                                    offset: 1,
                                    color: '#1963C467',
                                },
                            ]),
                    },
                },
                silent: true,
                barWidth: that.ChartSize(100),
                data: [total - yesterdayEnergy],
            },
        ],
    };



    return options
}

export default CreateenergyChart