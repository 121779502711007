<template>
  <!--人员布控列表 -->
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="normalEvent({ref:'addEdit',method:'append'})" @rowDetail="normalEvent" @rowEdit="normalEvent" @rowDelete="rowDelete" @getInit="getInit" @search="search" @reset="reset" @cancellation="cancellation" @push="push" :editDataFormat="editDataFormat">
    </ja-table>
    <!-- 新增 -->
    <add-edit ref="addEdit" />

  </div>
</template>
<script>
import table from '@/views/mixins/table'
import AddEdit from './childComp/AddEdit'
import { planList,deleteByPlanId,cancePlan,pushPlan} from '@/api'
export default {
  title: '巡更线路',
  mixins: [table],
  components: {
    AddEdit,
  },
  data() {
    return {
      conditionData: [
        { input_type: 'select', key: 'planType', attribute: { placeholder: '计划类型', size: 'small' }, options: [], styleWidth: '100px' },
        { input_type: 'select', key: 'status', attribute: { placeholder: '计划状态', size: 'small' },options:[{label:'待发布',value:0},{label:'已发布',value:1},{label:'已作废',value:2}] },
        { input_type: 'input', key: 'planName', attribute: { placeholder: '计划名称', size: 'small' } },

        { normalBtn: 'search', },
        { normalBtn: 'reset' },
        { normalBtn: 'add', text: '新增', },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqexport, rqUrl: '/patrol/plan/export', rqMethod: 'post' }}
      ],
      columns: [
        { label: '巡更计划名称', prop: 'planName', },
        { label: '巡更计划类型', prop: 'planTypeName', },
        { label: '巡更线路名称', prop: 'pathName', },
        { label: '计划状态', prop: 'statusName', },
        { label: '计划开始日期', prop: 'start', },
        { label: '计划结束日期', prop: 'end', },
        { label: '接单人', prop: 'acceptEmployee', },
        { label: '创建人', prop: 'createBy', },
        { label: '创建时间', prop: 'createTime', },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", align: 'center' },
          list: [
            { text: '作废', method: 'cancellation', },
            { text: '编辑', method: 'rowEdit', },
            { text: '发布', method: 'push', },
            { text: '删除', method: 'rowDelete', },
          ]
        },
      ],
      indexObj: {},

    }
  },

  mounted() {
     let index2 = this.conditionData.findIndex(item => item.key == 'planType')
     const addEdit = this.$refs.addEdit
     this.util.rqOptionsList({ who: [this.conditionData[index2],addEdit.rowFormData[addEdit.indexObj.planType]], rqUrl: "/sys/dictData/plan_type",resRule: 'data' })
  },
 
  methods: {
    rqexport() {
      let obj = { ...this.filterData }
      obj.pageIndex = this.total <= 2000 ? 1 : this.filterData.pageIndex
      obj.pageSize = 2000
      obj.startTime = obj.time ? obj.time[0] : ''
      obj.endTime = obj.time ? obj.time[1] : ''
      return obj
    },
    getInit() {
      this.loading = false
      const rqData = this.rqParmas()
      planList(rqData).then(res => {
        const { total, data } = res.data
        this.total = total
        this.tableData = data
      })
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该条数据？').then(res => {
        deleteByPlanId(row.id).then(res => {
          this.$message.success("已删除")
          this.getInit()
        })
      })
    },

    push({id}){
      this.$confirm('是否确认发布该条数据？').then(res => {
        pushPlan(id).then(res => {
          this.$message.success(res.msg)
          this.getInit()
        })
      })
    },
    cancellation({id}){
      this.$confirm('是否确认作废该条数据？').then(res => {
        cancePlan(id).then(res => {
          this.$message.success(res.msg)
          this.getInit()
        })
      })
    },

    editDataFormat(row, btn) {
      if(row.status === 1 && btn.text ==='作废'){
        return true
      }

      if(row.status === 0 && ['发布','编辑','删除',].indexOf(btn.text)!=-1){
        return true
      }

      if(row.status === 2 && btn.text ==='删除'){
        return true
      }
      return false
    },
  }
}
</script>
