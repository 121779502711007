<template>
    <div>
        <!-- 详情弹窗 -->
        <el-dialog :visible.sync="showDetail"
                   title="申请详情"
                   :width="`45%`">
            <ja-detail :options="detailOptions"
                       :detailData="detailData"
                       :label_width='10'
                       v-if="showDetail">
                <template #qpcode="">
                    <div class="BigBox">
                        <el-image fit="contain"
                                  class="cellImg"
                                  :src="detailData.qrCode"
                                  alt="">
                            <div slot="error"
                                 class="errorImg el-image__error">
                                {{imgText}}
                            </div>

                        </el-image>
                        
                        <div class="right">
                            <el-button type="primary"
                                       plain
                                       size="small"
                                       @click="refresh(detailData.id)">刷新二维码</el-button>
                            <p>上次更新时间：{{detailData.qrCodeRefreshTime}}</p>
                        </div>
                    </div>
                </template>
            </ja-detail>
        </el-dialog>
    </div>
</template>
<script>
import { refreshQpcodeInspection } from '@/api'

export default {
    data() {
        return {
            showDetail: false,
            detailOptions: [
                { key: 'a', label: '设备信息', isTitle: true, col: 22 },
                { key: 'deviceName', label: '设备名称', },
                { key: 'deviceNumber', label: '设备识别码', },
                { key: 'categoryName', label: '设备大类', },
                { key: 'buildingName', label: '所属楼栋', },
                { key: 'subcategoryName', label: '设备小类', },
                { key: 'floorName', label: '所属楼层', },
                { key: 'installLocation', label: '设备位置', },
                { key: 'spaceName', label: '绑定单元', },
                { key: 'brand', label: '设备品牌', },
                { key: 'model', label: '设备型号', },
                { key: 'protocolName', label: '对接协议', },
                { key: 'ipAddress', label: 'IP地址', },
                { key: 'port', label: '端口号', },
                { key: 'serviceLifeDuration', label: '设备运行时间', hide:true},
                { key: 'electricMajor', label: '电表专业分类', hide:true},

                { label: '维保二维码', type: 'customize', customize: 'qpcode', col: 22, alignItems: 'flex-start' },
                { key: 'a', label: '设备监测', isTitle: true, col: 22 },
                { key: 'runStatus', label: '运行状态', },
                
            ],
            detailData: {},
            initFileList: [],
            imgText: '',
            spaceList: {},
            indexObj:{}
        }
    },
    mounted() {
        const rowParamObj = { pageIndex: 1, pageSize: 200 }
        this.util.rqOptionsList({ who: this.spaceList, rqUrl: "/spaceTemp/query", rqData: rowParamObj, rqMethod: 'post', labelName: 'spaceCode', valueName: 'id' })
        this.indexObj = this.util.getArrItemIndex({ arr: this.detailOptions, })

    },
    watch: {
      'detailData.subcategory':{
            handler: function (val) {
                if(this.detailData.category==118 && val==140){
                    this.$set(this.detailOptions[this.indexObj.serviceLifeDuration], 'hide', false)
                }else {
                     this.$set(this.detailOptions[this.indexObj.serviceLifeDuration], 'hide', true)
                }

                if(this.detailData.category==112 && val==125){
                    this.$set(this.detailOptions[this.indexObj.electricMajor], 'hide', false)
                }else {
                     this.$set(this.detailOptions[this.indexObj.electricMajor], 'hide', true)
                }
            }
        }  
    },
    methods: {
        rowDetail(row) {
            this.spaceList.options.find(e => {
                if(row.spaceId == e.value){
                    row.spaceName = e.label
                }
            })
            this.detailData = { ...row}
            this.showDetail = true
        },
        refresh(id) {
            this.detailData.qrCode = ''
            this.imgText = '加载中...'
            refreshQpcodeInspection(id).then(res => {
                console.log(res);
                this.detailData.qrCode = res.data
                this.imgText = '加载失败'
            }).catch(err => {
                this.imgText = '加载失败'
            })
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .cellImg {
    height: 120px;
    width: 120px;
    background: #f5f7fa;
    > img {
        height: 120px;
        display: flex;
        margin: auto;
    }
}
/deep/ .errorImg {
    width: 100%;
}
.BigBox {
    display: flex;
    align-items: flex-end;
    margin-top: 30px;
    .right {
        margin-left: 20px;
    }
}
</style>