<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="工单详情" :width="`50%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='8' v-if="showDetail" :loadingForm="loadingForm">
        <template #uplpadfaceImg>
          <upload-file ref="Upload" :initFileList="initFileList" v-if="showDetail" :noButton="true" :hideDel="true" />
        </template>
        <template #repairImg>
          <upload-file ref="Upload" :initFileList="initFileList2" v-if="showDetail" :noButton="true" :hideDel="true" />
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import { rqRepairOrderItem } from '@/api'

export default {
  props: {
    pageType: String,
  },
  data() {
    return {
      showDetail: false,
      loadingForm: false,
      detailOptions: [],
      detailData: {},
      initFileList: [],
      initFileList2: [],

      inforArray:[
        { type:'space',top:30},
        { label: '接单人',key: 'takingName',inforArray:true},
        { label: '所属部门',key: 'takingDeptName',inforArray:true },
        { label: '维修描述', key: 'solveDescription', col: 22, inforArray:true},
        { label: '维修拍照',type: 'imgInfor',  key: 'solveImgs', col: 24, alignItems: 'flex-start',inforArray:true },
      ]
    }
  },
  methods: {
    rowDetail(row) {
      this.detailData = {}
      this.loadingForm = true
      rqRepairOrderItem(row.id).then(res => {
        let data = res.data
        
        let list = (data.flowListVos || []).map(v => {
          return {
            ...v,
            dealText: `【${v.stepValue}】 ${v.operationEmployeeName} ${v.result} `
          }
        })
        this.detailData = { ...data, flowListVos: list }
        console.log(111,this.detailData);

        this.handList(data.acceptDetail)
        this.initFileList = data.submitImgs ? this.util.backImgVideoObj(data.submitImgs) : []
        this.initFileList2 = data.solveImgs ? this.util.backImgVideoObj(data.solveImgs) : []
        this.showDetail = true
        this.loadingForm = false
      }).catch(err => {
        this.loadingForm = false
      })
    },

    handList(inforList){
      let arrStr = JSON.stringify(this.inforArray)
      let arrays = []
      for (let index = 0; index < inforList.length; index++) {
        let array = JSON.parse(arrStr)
        array.forEach(item => {
          if(item.type!='space'){
            item.indexStr = index
            item.key2 = 'acceptDetail'
            item.key3 = item.key
          }
        });
        arrays.push(...array)
      }
      const propConfig = [
        { label: '工单信息', isTitle: true, col: 24 },
        { label: '工单号', key: 'workOrderCode' },
        { label: '工单类型', key: 'classifyValue' },
        { label: '报修类别', key: 'workOrderCategoryValue' },
        { label: '报修类型', key: 'repairTypeValue' },
        { label: '所在楼栋', key: 'buildingName' },
        { label: '所在楼层', key: 'floorName' },
        { label: '详细位置', key: 'workOrderAddress' },
        { label: '报修数量', key: 'repairNumber' },
        { label: '报修描述', key: 'workOrderDescription' },
        {
          type: 'img',
          label: '现场图片',
          key: 'submitImgs',
          col: 24,
          alignItems: 'flex-start'
        },
        { label: '提单信息', isTitle: true, col: 24 },
        { key: 'submitEmployeeName', label: '提单人' },
        { key: 'submitEmployeeCompany', label: '所属公司' },
        { key: 'submitEmployeeMobile', label: '联系电话' },
        { key: 'orderSubmitTime', label: '提单时间' },
        { key: 'solveLimitTime', label: '处理时限', rightText: '分钟' },

        { key: 'title', label: '接单信息', isTitle: true, col: 24 },
        { key: 'statusValue', label: '工单状态' },
        { key: 'dispatchName', label: '派单人' },
        ...arrays,

        { key: 'title', label: '工单操作记录', isTitle: true, col: 24 },
        {
          type: 'table',
          label: '工单操作记录',
          noSearch: true,
          showPage: false,
          key: 'flowListVos',
          col: 24,
          table_MaxHeight: 500,
          columns: [
            { label: '时间  ', prop: 'createTime', width: 150 },
            { label: '人员', prop: 'operationEmployeeName', width: 100 },
            { label: '操作处理', prop: 'dealText' }
          ]
        }
      ];
      this.detailOptions = propConfig
    }
  }
}
</script>