<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="工单详情" :width="`50%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='8' v-if="showDetail">
        <template #uplpadfaceImg>
          <upload-file ref="Upload" :initFileList="[...initFileList]" v-if="showDetail" :noButton="true" :hideDel="true" />
        </template>
        <template #repairImg>
          <upload-file ref="Upload" :initFileList="[...initFileList]" v-if="showDetail" :noButton="true" :hideDel="true" />
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import detailObj from '../common/childComp/Detail'

export default {
  props: {
    pageType: String,
  },
  data() {
    return {
      showDetail: false,
      detailOptions: detailObj[this.pageType],
      detailData: {},
      initFileList: []
    }
  },
  methods: {
    rowDetail(row) {
      this.detailData = { ...row }
      this.initFileList = this.util.backImgVideoObj(row.attachmentList)
      this.showDetail = true
    },
  }
}
</script>