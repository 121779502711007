import { isString } from './validate'

// 将层级字符串转为对象变量  
// 如str=“data.p.c” 字符串，能读取到 "oldObj{data{p{c:'111'}}}" 的值 ，oldObj 是要读取的对象
const stringToVariable = function({ oldObj, str }) {
        let newObj = oldObj || {}
        let keysArr = Object.keys(oldObj)
        let strArr = str.split('.')
        if (strArr.length > 0) {
            if (strArr.length == 1) {
                return newObj[strArr[0]]
            }
            let key = keysArr.find(v => v == strArr[0])
            if (key) {
                let index = str.indexOf('.')
                let nextStr = str.slice(index + 1)
                return stringToVariable({ oldObj: newObj[key], str: nextStr })
            } else {
                return newObj
            }
        }
    }
    /**
     * 处理下拉列表的 options 
     * @param {*} optionsArr 要被处理的数组
     * @param {*} labelName 作为 options 数组 的{label:'',value:''},label 读取的是后端哪一个字段
     * @param {*} valueName 作为 options 数组 的{label:'',value:''},value 读取的是后端哪一个字段
     * @param {*} otherKeys options 数组的每一项，是否还需返回的其它值，[{keyName,keyValue}]
     * @param {*} isArrayList  若为true 返回数组，如后端 返回 [{label:'哈哈',value:'1'},{label:'苹果',value:'3'},]，能处理为数组 【'','哈哈','','苹果'】
     * @param {*} isReturnAll  是否返回一个项的所有字段
     * 
     */
    // 清除下拉列表的 options 跟 值
const dealOptions = function({ optionsArr = [], labelName, valueName, otherKeys = [], isArrayList = false, isReturnAll = false }) {
        let options = [],
            options_arr = []
        if (optionsArr.length > 0) {
            options = optionsArr.map(item => {
                let label = labelName ? (isString(labelName) ? item[labelName] : labelName(item)) : item.dictLabel
                let value = valueName ? item[valueName] : item.dictValue
                if (isArrayList) { // 若是直接 数组
                    options_arr[Number(value)] = label
                } else {
                    const obj = isReturnAll ? {...item } : {}
                    obj.label = label,
                        obj.value = value
                    if (otherKeys.length > 0) {
                        otherKeys.forEach(v => {
                            obj[v.keyName] = item[v.keyValue]
                        })
                    }
                    return obj
                }
            })
        }
        if (isArrayList) {
            options = options_arr
        }
        return options
    }
    //阿拉伯数字转中文数字
const NoToChinese = function(num) {
    if (!/^\d*(\.\d*)?$/.test(num)) {
        alert("Number is wrong!");
        return "Number is wrong!";
    }
    var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
    var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
    var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
    for (var i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
            case 0:
                re = BB[7] + re;
                break;
            case 4:
                if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                    re = BB[4] + re;
                break;
            case 8:
                re = BB[5] + re;
                BB[7] = BB[5];
                k = 0;
                break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
    }
    if (9 < num && num < 20) {
        re = re.slice(1)
    }
    if (a.length > 1) //加上小数部分(如果有小数部分) 
    {
        re += BB[6];
        for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
    }
    return re;
}

const dateFormat = function (fmt, date) {
    let ret
    const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
        }
    }
    return fmt
}


export {
    stringToVariable,
    dealOptions,
    NoToChinese,
    dateFormat
}