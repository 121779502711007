<template>
    <div>
        <el-col :span="24"
                v-loading="loading">
            <div class="headle">
                <span>{{title}}</span>
            </div>
            <div ref="wrap"
                 class="wrap">
            </div>

        </el-col>
    </div>
</template>
<script>
import { getUsageStatistics } from '@/api'
export default {
    title: '统计',
    props: {
        title: {
            type: String,
            default: "标题"
        },
    },
    data() {
        return {
            loading: false,
            ydata: [],
            oldYdata: [],
        }
    },
    methods: {
        setOptions({waterUsage,electricityUsage,coolUsage}) {
            //区分浮动显示单位
            let options = {
                color: ['#29b3d4', '#f29b36', '#555555', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                },
                grid:{
                    top:'24%'
                },
                legend: {
                    top: '5%',
                    right: '20%',
                    itemGap: this.util.setFontSize(30, 1920),
                    textStyle: { //图例文字的样式
                        fontSize: this.util.setFontSize(15, 1920),
                    },
                    data: ['用电量', '用水量','用冷量'],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisPointer: {
                            type: 'none',
                        },
                        offset: this.util.setFontSize(8, 1920),
                        axisLabel: {
                            fontSize: this.util.setFontSize(15, 1920),
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '(kW·h)',
                        position: 'left',
                        splitLine: {
                            show:false,
                        },
                        axisPointer: {
                            label: {
                                show: true,
                            }
                        },
                        nameTextStyle: {
                            fontSize: this.util.setFontSize(16, 1920),
                            align:'right'
                        },

                        axisLine: {
                            show: true
                        },
                        axisLabel: {
                            fontSize: this.util.setFontSize(14, 1920),
                        },
                    },
                    {
                        type: 'value',
                        name: '(m³)',
                        position: 'right',
                        splitLine: {
                            show:false
                        },
                        axisPointer: {
                            label: {
                                show: true,
                            }
                        },
                        nameGap: 20,
                        nameTextStyle: {
                            fontSize: this.util.setFontSize(16, 1920),
                            align:'left'
                        },

                        axisLine: {
                            show: true
                        },
                        axisLabel: {
                            fontSize: this.util.setFontSize(14, 1920),
                        },
                    }
                ],
                series: [
                    {
                        name: '用电量',
                        type: 'bar',
                        data: electricityUsage,
                    },
                    {
                        name: '用水量',
                        type: 'bar',
                        yAxisIndex: 1,
                        data: waterUsage,
                    },
                    {
                        name: '用冷量',
                        type: 'bar',
                        data: coolUsage,
                    },

                ]
            }
            //添加一个新的刻度线 实现折线在柱状图的中间显示
            options.xAxis[1] = {
                type: 'value',
                max: options.xAxis[0].data.length,
                show: false,
                axisPointer: {
                    label: {
                        show: false,
                    }
                },
            }
            const myChart = this.$echarts.init(this.$refs.wrap)
            myChart.setOption(options)
            window.addEventListener('resize', () => {
                myChart.resize();
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.headle {
    border-bottom: 1px solid black;
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}
.wrap {
    width: 100%;
    height: 400px;
}
</style>