<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showDetail" title="工单详情" :width="`50%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='8' v-if="showDetail" :loadingForm="loadingForm">
        <template #planDetailVos>
          <div class="planDetailVos">
            <ul class="titleList">
              <li v-for="(item,index) in statusList" :key="index"><span :style="{'background':item.color}"></span>{{item.text}}</li>
            </ul>
            <div class="main">
              <el-tag :type="statusList.find(v=>v.value == item.status).type" effect="dark" size="medium" v-for="(item,index) in detailData.planDetailVos" :key="index" @click="clickTag(item)">{{item.deviceName}}</el-tag>
            </div>
            <div class="item itemImg" v-if="imgUrl">
              <div class="imgBox">
                <el-image  class="img" :lazy='true' :src="imgUrl"  z-index="3000" fit='cover'></el-image>
              </div>
            </div>
            <span>设备异常描述：{{exceptionDesc || "无"}}</span>

          </div>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>
import detailObj from '../common/childComp/Detail'
import { rqScanOrderItem } from '@/api'

export default {
  props: {
    pageType: String,
  },
  data() {
    return {
      imgUrl:"",
      showDetail: false,
      detailOptions: detailObj[this.pageType],
      detailData: {},
      loadingForm: false,
      exceptionDesc:"",
      statusList: [
        { text: '未巡更', color: '#909399', value: 0, type: 'info' },
        { text: '已巡更', color: '#67c23a', value: 1, type: 'success' },
        { text: '异常记录', color: '#e6a23c', value: 2, type: 'warning' },
      ]
    }
  },
  methods: {
    rowDetail(row) {
      this.detailData = {}
      this.showDetail = true
      this.loadingForm = true
      rqScanOrderItem(row.id).then(res => {
        let data = res.data
        this.imgUrl = data.planDetailVos?.[0].img
        let {status:approvedResultStatus,employee:approvedResultEmployee,time:approvedResultTime,result:approvedResultResult } = data.approvedResult
        let list = (data.flowListVos || []).map(v => {
          return {
            ...v,
            dealText: `【${v.stepValue}】 ${v.operationEmployeeName} ${v.result}`
          }
        })
        this.detailData = { ...data, flowListVos: list, approvedResultStatus, approvedResultEmployee, approvedResultTime,  approvedResultResult }
        this.exceptionDesc = this.detailData.planDetailVos[0].exceptionDesc
        this.loadingForm = false
      }).catch(err => {
        this.loadingForm = false
      })
    },

    clickTag({exceptionDesc,img}){
      this.exceptionDesc = exceptionDesc
      this.imgUrl =img
    }
  }
}
</script>

<style lang="scss" scoped>
.planDetailVos {
  ul.titleList {
    display: flex;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      margin-right: 50px;
      span {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  .main {
    .el-tag {
      margin-right: 30px;
      &:hover{
        cursor: pointer;
      }
    }
  }
}

.item {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    line-height: 40px;
    font-size: 14px;
    .itemRight {
      flex: 1;
      line-height: 18px;
    }
    &.tableBox {
      display: flex;
      margin: 10px 0;
      .title {
        margin-top: 8px;
        margin-bottom: initial;
        font-weight: bold;
        text-align: left;
      }
    }
    &.itemImg {
      display: flex;
      .imgBox {
        display: flex;
        flex-wrap: wrap;
        .img {
          margin: 10px 10px 10px;
        }
      }
    }
    .img {
      width: 150px;
      height: 150px;
    }
    > span:nth-of-type(1) {
      width: 8em;
      text-align: right;
      margin-right: 1em;
      &.isTitle {
        text-align: left;
        font-weight: 600;
        font-size: 15px;
        color: #606266;
      }
    }
    h2 {
      width: 6em;
      margin-top: 20px;
      font-size: 18px;
      text-align: right;
    }
    .table {
      width: 100%;
      // margin-top: 20px;
      margin-left: 20%;
    }
  }
</style>