<template>
    <div>
        <el-container>
            <left-list></left-list>
            <mian-video title="给排水系统" url="http://121.37.251.55:8280/#/?model=广电地下2"></mian-video>

            <!-- 排水 -->
            <div class="drainage" v-if="!waterConservancy">
                <div class="waterpipe" v-for="item in watermsg" :style="{left:item.left+'%',top:item.top+'%'}" @click="openwaterMsg(item)">
                   <img :src="item.icon">
                </div>

                <div class="waterpipebox" v-if="waterpipeVisible">
                    <!-- 关闭按钮 -->
                    <div class="closebtn" @click="closewaterMsg"></div>
                    
                    <p>水泵信息</p>
                    <!-- 正常状态 -->
                    <div v-if="waterpipeparameter.state">
                         <div class="waterpipebox_left">
                            <div>开关:<el-switch
                                        v-model="waterpipeparameter.isopen"
                                        active-color="rgba(22, 155, 213, 1)"
                                        inactive-color="#aaa">
                                    </el-switch>
                            </div>
                            <div>水位：{{waterpipeparameter.stage}}</div>
                        </div>
                        <div class="waterpipebox_right">
                            <img :src="waterpipeparameter.isopen? wateropen:waterclose" alt="">
                        </div>
                    </div>
                    <!-- 异常状态 -->
                    <div v-else>
                        <div class="waterpipebox_unusual">
                            <p>设备连接异常...</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 供水 -->
            <div class="waterdelivery" v-else>
                <!-- 水泵 -->
                <div class="waterpumpbox">
                    <div class="waterpump">
                        <img src="@/assets/gd/waterpump.png">
                    </div>
                    <div class="parameter">
                        <p>水泵参数</p>
                        <div>
                            <div>出水压力:<span>101MPa</span></div>
                            <div>频率:<span>0.0HZ</span></div>
                            <div>设定压力:<span>101MPa</span></div>
                        </div>
                        <el-switch
                            v-model="waterpumpbtn"
                            active-color="#3AA1FF"
                            inactive-color="#aaa">
                        </el-switch>
                    </div>
                </div>
                <!-- 楼层按钮 -->
                <!-- <div class="waterdeliverybtn">
                    <el-button type="primary" round>-2层</el-button>
                    <el-button type="primary" round>27层</el-button>
                </div> -->
            </div>
                
            <div class="waterbtn">
                    <el-button round @click="waterConservancy=false" small >排水系统</el-button>
                    <el-button round @click="waterConservancy=true" small >供水系统</el-button>
            </div>
        </el-container>
    </div>
</template>
<script>
import LeftList from '../components/LeftList.vue'
import MianVideo from '../components/MianVideo.vue'
import wateropen from '@/assets/gd/waterpump_open.png'
import waterclose from '@/assets/gd/waterpump_close.png'
import watericon1 from '@/assets/gd/waterpump_icon1.png'
import watericon2 from '@/assets/gd/waterpump_icon2.png'
import watericon3 from '@/assets/gd/waterpump_icon3.png'
export default {
    title: '给排水系统',
    name: 'waterConservancySystem',
    components: {
        LeftList,
        MianVideo,
    },
    data () {
        return {
            waterConservancy:true,//ture为供水系统 false为排水系统
            waterpumpbtn:false,//水泵开关
            // 排水管数据
            watermsg:[
                {id:0,isopen:false,stage:'低',left:20,top:20,icon:watericon1,},
                {id:1,isopen:true,stage:'高',left:20,top:40,icon:watericon2,},
                {id:2,isopen:false,stage:'异常',left:20,top:60,icon:watericon3,},
            ],
            waterpipeVisible:false, //排水弹窗
            waterpipeparameter:{ //排水参数
                isopen:'',
                stage:'',
                state:''
            },
            wateropen,
            waterclose,
        }
    },
    methods:{
      openwaterMsg(item){
        this.waterpipeVisible = true
        this.waterpipeparameter.isopen = item.isopen
        this.waterpipeparameter.stage = item.stage
        if(item.stage == '异常'){this.waterpipeparameter.state = false}else{this.waterpipeparameter.state = true}
      },
      closewaterMsg(){
        this.waterpipeVisible = false
      },
    }
}
</script>
<style lang="scss" scoped>
.el-container {
    position: relative;
    margin: 0 15px;
}
:-webkit-full-screen .el-container{
  margin: 0!important;

  .waterpumpbox{
        position: absolute;
        left: 20%;
        top: 35%;
    }
    .waterbtn{
        width: 370px;
        bottom: 15%;
        right: 7.55%;
        button{
            width: 160px;
            height: 60px;
            font-size: 26px;
            line-height: 26px;
        }
    }
}

// 排水系统
.drainage{
    .waterpipe{
        position: absolute;
        img{
            width: 103px;
            height: 79px;
        }
    }

    .waterpipebox{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-30%,-60%);
            width: 678px;
            height: 383px;
            background: url('~@/assets/gd/gd_bg2.png') no-repeat;
            background-size: 100% 100%;
            padding: 23px;
            color: #fff;
            p{text-align: center;}
            .closebtn{
                width: 30px;
                height: 30px;
                position: absolute;
                right: 3%;
                top: 3%;
            }
            .waterpipebox_left{
                margin-top: 40px;
                width: 150px;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .waterpipebox_right{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-45%);
                width: 172px;
                height: 289px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .waterpipebox_unusual{
                position: relative;
                width: 552px;
                height: 289px;
                margin: 0 auto;
                margin-top: 19px;
                background-color: #e9e9e9;
                >p{
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translate(0, -50%);
                    color: #333333;
                    font-size: 34px;
                }
            }
    }
}
// 供水系统
.waterdelivery{
    .waterdeliverybtn{
        position: absolute;
        left: 18%;
        top: 30%;
        width: 150px;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        button{
            width: 130px;
            margin-bottom: 10px;
            margin: 0;
        }
    }
    .waterpumpbox{
        position: absolute;
        left: 20%;
        top: 30%;
        width: 400px;
        height: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .waterpump{
            width: 159px;
            height: 118px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .parameter{
                width: 209px;
                height: 238px;
                background: url('~@/assets/gd/gd_bg.png') no-repeat;
                background-size: 100% 100%;
                box-sizing: border-box;
                padding:23px 30px;
                color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                p{text-align: center;margin-bottom: 10px;font-size: 20px;margin-bottom: 30px;}
                >div>div{
                    width: 150px;
                    display: flex;
                    justify-content: space-between;
                    box-sizing: border-box;
                    margin-bottom: 20px;
                }
                span{
                    color: #3AA1FF;
                }
                .el-switch{
                    margin: 0 auto;
                    font-size: 15px;
                }
        }
    }
}
.waterbtn{
    width: 302px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 15%;
    right: 7.3%;
    button{
        width: 133px;
        height: 50px;
        font-size: 22px;
        line-height: 22px;
        background: linear-gradient(90deg, #21BAE4, #20BFE6, #37C3EA, #3AA1FF);
        border: none;
        border-radius: 30px;
        color: #fff;
    }
}

</style>
