<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="NewconditionData" :columns="Newcolumnsfix"
      :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search"
      @reset="reset" @exportFun="exportFun" @rowAssignOrder="rowAssignOrder" @rowTurnOrder="rowTurnOrder"
      @commitStatus="commitStatus"
      @rowResOrder="rowResOrder" @rowReset="rowReset" @rowDetail="normalEvent" @rowCheckOrder="rowCheckOrder"
      :btnTextFormat="btnTextFormat"
      :editDataFormat="editDataFormat"></ja-table>

    <!-- 涉及到的弹窗 :派单、转单、结单 -->
    <window-box ref="windowBox" :pageActiveObj="pageTypeObj[this.pageType]" />

    <detail ref="detail" :pageType="pageType" />
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import mixinIndex from '../common/mixinIndex'
import windowBox from '../common/windowBox.vue'
import Detail from './Detail.vue'
import { getAlarmhandle } from '@/api/module/alarm.js'



export default {
  title: '告警工单',
  mixins: [table, mixinIndex],
  name: 'index',
  components: {
    windowBox, Detail
  },
  data() {
    return {
      pageType: 'alarm',
      Newcolumnsfix: [
        { label: '工单号', prop: 'workOrderCode' ,width:'150px'},
        { label: '工单类型', prop: 'classifyValue' },
        { label: '提单人', prop: 'createBy' },
        { label: '提单时间', prop: 'createTime' },
        { label: '工单状态', prop: 'status' },
        {
          label: '操作', input_type: 'button', width: 160, attribute: { fixed: "right", showOverflowTooltip: false, align: 'center' },
          list: [
            { text: '处理', method: 'commitStatus', Format: true, },
            { text: '详情', method: 'rowDetail', },
          ]
        }
      ],
      NewconditionData: [
        { input_type: 'select', key: 'status', attribute: { placeholder: '工单状态', size: 'small' }, options: [
          { "value": 1, "label": "待处理" }, 
          { "value": 2, "label": "已处理" }
        ], styleWidth: '100px' },
        { input_type: 'input', key: 'keyword', attribute: { placeholder: '提单人/工单号/处理人', size: 'small' } },
        { label: '选择日期', input_type: 'date', type: 'daterange', value: 'time', format: 'yyyy-MM-dd', startPlaceholder: '开始日期', endPlaceholder: '结束日期', attribute: { unlinkPanels: true }, valueFormat: 'yyyy-MM-dd', },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas, rqUrl: '/alarmRecord/getPageExport', rqMethod: 'post' }, limit: '' },
      ],
    }
  },
  methods: {
    // btnTextFormat(row, btn) {
    //   if (row.status == '待处理') {
    //     return '处理'
    //   } else if (row.status == '已处理') {
    //     return 
    //   }
    // },
    editDataFormat(row, btn) {
      if (row.status == '待处理' && btn.text === '处理') {
        return true
      } else if(btn.text === '详情'){
        return true
      } else{
        return false
      }
    },
    async commitStatus(row){
      let {data:res} = await getAlarmhandle(row.id)
      console.log(res);
      this.getInit()
    }
  }
}
</script>