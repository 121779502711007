<template>
  <div>
    <JaTable v-model="filterData" :conditionData="conditionData" :columns="alarmThresholdColumn" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" 
    @append="normalEvent({ref:'addEdit',method:'append'})" @rowDetail="normalEvent" @getInit="getInit"  @rowEdit="normalEvent" @search="search" @reset="reset" @rowDelete="rowDelete" ></JaTable>

    <detail ref="detail" />

    <!-- 新增|编辑 -->
    <add-edit ref="addEdit" />
  </div>
</template>

<script>
import JaTable from '@/components/ja-table'
import table from '@/views/mixins/table'
import Detail from './childComp/Detail'
import AddEdit from './childComp/AddEdit'
import { linkageConfigList,linkageTypeAll,delLinkageConfig} from '@/api/module/alarm.js'

export default {
  title: '联动配置',
  mixins: [table],
  name: 'alarmLinkage',
  components: {
    JaTable,
    Detail,
    AddEdit
  },
  data() {
    return {
      // 搜索
      conditionData: [
        {
          input_type: 'date',
          type:'datetimerange',
          attribute: {
            size: 'small'
          },
          startPlaceholder:"开始时间",
          endPlaceholder:"结束时间",
          value: 'time',
        },
        {
          input_type: 'select',
          attribute: {
            placeholder: '联动类型',
            size: 'small'
          },
          key: 'type',
          options: []
        },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' },
        { normalBtn: 'add' }
      ],
      // 表格
      alarmThresholdColumn: [
        { label: '联动类型', prop: 'linkageTypeName' },
        { label: '触发设备', prop: 'triggerDeviceName' },
        { label: '触发条件', prop: 'triggerConditionName' },
        { label: '判断规则', prop: 'ruleName' },
        { label: '触发值', prop: 'triggerValue' },
        { label: '联动设备', prop: 'linkageDeviceName' },
        { label: '动作', prop: 'actionName' },
        { label: '动作值', prop: 'actionValue' },
        { label: '配置时间', prop: 'updateTime' },
        {
          label: '操作',
          input_type: 'button',
          width: 160,
          list: [
            {
              text: '详情',
              method: 'rowDetail',
              limit: 'envMonitoring::alarmThreshold::detail'
            },
            {
              text: '编辑',
              method: 'rowEdit'
            },
            {
              text: '删除',
              method: 'rowDelete'
            }
          ]
        }
      ],
      tableData: [],
      total: 0,
      // 搜索参数
      filterData: {
        pageIndex: 1,
        pageSize: 10
      },
    }
  },
  async created() {
    // const {data:linkageTypeResult} =  await linkageTypeAll()
    // const linkageTypeOption = linkageTypeResult.map(item=>{
    //   return {label:item.typeName,value:item.id}
    // })
  
    // this.conditionData.forEach(item=>{
    //   if(item.key==='type') item.options = linkageTypeOption
    // })
  },
  methods: {
    getInit() {
      this.loading = true
      linkageConfigList(this.filterData).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.data
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    search() {
      if (this.filterData.time) {
        this.filterData.startTime = this.filterData.time[0]
        this.filterData.endTime = this.filterData.time[1]
      }
      this.getInit()
    },
    // 删除
    rowDelete(row) {
      this.$confirm('是否确认删除该联动配置', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then((res) => {
          delLinkageConfig(row.id).then((res) => {
          this.$message.success(res.msg)
          this.getInit()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .cellImg {
  display: flex;
  margin: auto;
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
::v-deep .errorImg {
  width: 100%;
}
</style>
