<template>
  <div class="allwh">
    <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" :showIndex="true" @getInit="getInit" @search="search" @reset="reset">
      <template #img='{row}'>
        <el-image :preview-src-list="[row.img]" fit="contain" class="cellImg" :src="row.img" alt="" v-if="row.img ">
          <div slot="error" class="errorImg el-image__error">
            加载失败
          </div>
        </el-image>
      </template>
    </ja-table>
  </div>
</template>
<script>
import table from '@/views/mixins/table'
import { getVisitorPassRecord } from '@/api'

export default {
  title: "访客通行记录",
  mixins: [table],
  name: 'index',
  data() {
    return {
      conditionData: [
        { input_type: 'input', key: 'applyCode', attribute: { placeholder: '访客单号', } },
        { input_type: 'input', key: 'name', attribute: { placeholder: '访客姓名', } },
        { input_type: 'select', key: 'applyType', attribute: { placeholder: '申请类型', }, options: [{ value: 0, label: '内部邀约' }, { value: 1, label: '用户预约' }] },
        { input_type: 'select', key: 'visitorType', attribute: { placeholder: '业务类型', }, options: [] },
        { input_type: 'select', key: 'recordType', attribute: { placeholder: '通行类型', }, options: [{ value: 0, label: '进入' }, { value: 1, label: '离开' }] },
        { input_type: 'input', key: 'dept', attribute: { placeholder: '访客公司', } },
        { input_type: 'input', key: 'phone', attribute: { placeholder: '访客电话', } },
        { input_type: 'input', key: 'recordArea', attribute: { placeholder: '区域位置', } },
        { input_type: 'date', type: 'daterange', key: 'applyTime', value: 'applyTime', startPlaceholder: '申请开始日期', endPlaceholder: '申请结束日期', valueFormat: 'yyyy-MM-dd' },
        { input_type: 'date', type: 'daterange', key: 'visitTime', value: 'visitTime', startPlaceholder: '来访开始日期', endPlaceholder: '来访结束日期', valueFormat: 'yyyy-MM-dd' },

        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset', },
        { normalBtn: 'download', exportObj: { rqParmas: this.rqParmas2, rqUrl: '/visitor/record/export', rqMethod: 'post' }, limit: 'visitor::passRecord::download' }
      ],
      conditionData_indexObj: {},
      columns: [
        { label: '访客单号', prop: 'applyCode', width: 160, attribute: { showOverflowTooltip: false, } },
        { label: '访客姓名', prop: 'name', },
        { label: '访客公司', prop: 'dept', width: 180, attribute: { showOverflowTooltip: false, } },
        { label: '访客电话', prop: 'phone', width: 130 },
        { label: '申请类型', prop: 'applyType', },
        { label: '业务类型', prop: 'visitorType', },
        { label: '通行位置', prop: 'recordArea', },
        { label: '通行类型', prop: 'recordType', },
        { label: '附件', prop: 'img', width: 100, slotName: 'img' },
        { label: '通行时间', prop: 'recordTime', width: 170, attribute: { showOverflowTooltip: false, } },
      ],
      tableData: []
    }
  },
  created() {
    this.conditionData_indexObj = this.util.getArrItemIndex({ arr: this.conditionData, })
    this.util.rqOptionsList({ who: this.conditionData[this.conditionData_indexObj.visitorType], rqUrl: "/sys/dictData/visitor_type", resRule: 'data' })
  },
  methods: {
    rqParmas2() {
      let obj = this.rqParmas()
      if (obj.applyTime) {
        obj.applyStartTime = obj.applyTime[0]
        obj.applyEndTime = obj.applyTime[1]
        delete obj.applyTime
      }
      if (obj.visitTime) {
        obj.visitStartTime = obj.visitTime[0]
        obj.visitEndTime = obj.visitTime[1]
        delete obj.visitTime
      }
      return obj
    },
    getInit() {
      this.loading = true
      let obj = this.rqParmas2()
      getVisitorPassRecord(obj).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .cellImg {
  display: flex;
  margin: auto;
  > img {
    max-height: 23px;
    width: 35px;
    display: flex;
    margin: auto;
  }
}
::v-deep .errorImg {
  width: 100%;
}
</style>