<template>
  <transition name="fade">
    <div class="boxdia" v-if="dialogVisible" @click.self="closeDialog">
      <div class="dialog">
        <div class="dialog_title">
          {{ title }}
        </div>
        <div class="btn" @click="closeDialog"></div>
        <!-- 接收传递的图表 -->
        <div class="right">
          <!-- <div class="search">
            <div class="title">{{ title }}</div>
            <div>
              <el-date-picker
                v-model="filterData.startTime"
                type="date"
                placeholder="开始时间"
                value-format="yyyy-MM-dd"
                prefix-icon="el-icon-arrow-down"
                @change="searchInit"
              >
              </el-date-picker>
              <el-date-picker
                v-model="filterData.endTime"
                type="date"
                placeholder="结束时间"
                value-format="yyyy-MM-dd"
                prefix-icon="el-icon-arrow-down"
                @change="searchInit"
              >
              </el-date-picker>
            </div>
          </div> -->
          <div>
            <el-table
              :data="tableData"
              style="width: 100%"
              header-cell-class-name="table_head"
              :highlight-current-row="true"
            >
              <el-table-column
                :prop="item.prop"
                :label="item.label"
                :key="item.prop"
                :class-name="item.tdstyle"
                :width="item.width"
                v-for="item in columns"
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="page">
            <!-- :hide-on-single-page="filterData.total < 9 ? true:false" -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filterData.pageIndex"
              :page-size="filterData.pageSize"
              :pager-count="3"
              layout="total, prev, pager, next, slot"
              :total="filterData.total"
            >
              <div class="jump">
                <input v-model="filterData.pageIndex" @input="jumpPage()" />/
                {{
                  Math.ceil(filterData.total / filterData.pageSize) == 0
                    ? 1
                    : Math.ceil(filterData.total / filterData.pageSize)
                }}
              </div>
            </el-pagination>

            <!-- <div class="goSystem" @click="goSystem()">跳转</div> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '图表名称'
    },
    columns: {
      type: Array,
      default: []
    },
    systemurl: {
      type: String,
      default: ''
    },
    getDatamethod: {},
    dialogtype:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      loading: false,
      rowForm: {},
      filterData: {
        // 部分接口分页是currentPage，部分是pageNum,故都传
        pageIndex: 1,
        pageSize: 9,
        total: 0,
        startTime: '',
        endTime: ''
      }
    }
  },
  methods: {
    showDialog(i) {
      this.dialogVisible = true
      this.getInit()
    },
    closeDialog() {
      this.dialogVisible = false
    },
    getInit() {
      this.loading = true
      // 获取数据
      this.getDatamethod({
        pageIndex: this.filterData.pageIndex,
        pageSize: this.filterData.pageSize,
        type:this.dialogtype
      }).then(({ data }) => {
        console.log(data,'data');
        let dataList = data.data
        dataList.map((item) => {
          switch(item?.status){
            case 0:
              item.status = '待处理';
              break;
            case 1:
              item.status = '已通知';
              break;
            case 2:
              item.status = '已处理';
              break;
          }
        })
        this.tableData = dataList
        this.filterData.total = data.total
        this.loading = false
      })
    },
    searchInit() {
      this.loading = true
      // 获取数据
      this.getDatamethod(this.filterData).then(({ data }) => {
        let dataList = data.data
        dataList.map((item) => {
          item.lastReading = Number(item.lastReading).toFixed(2)
        })
        this.tableData = dataList
        this.filterData.total = data.total
        this.filterData.pageIndex = 1
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.filterData.pageSize = val
      this.getInit()
    },
    handleCurrentChange(val) {
      this.filterData.pageIndex = val
      this.getInit()
    },
    jumpPage() {
      this.filterData.pageIndex = this.filterData.pageIndex.replace(/\D/g, '') //过滤非数字
      if (
        this.filterData.pageIndex >=
        Math.ceil(this.filterData.total / this.filterData.pageSize)
      ) {
        this.filterData.pageIndex = Math.ceil(
          this.filterData.total / this.filterData.pageSize
        )
        this.getInit()
      } else {
        this.getInit()
      }
    },
    goSystem() {
      if (this.systemurl) {
        this.$router.push(this.systemurl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.boxdia {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 100;
  .dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    width: 1552px;
    height: 800px;
    padding: 150px 240px 0 240px;
    background-image: url('~@/assets/gd/ds_chartwindow.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    .dialog_title {
      position: absolute;
      font-size: 22px;
      top: 112px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fff;
    }
    .btn {
      position: absolute;
      height: 44px;
      width: 38px;
      top: 60px;
      right: 120px;
      background-image: url('~@/assets/gd/ds_closebtn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 100;
    }
  }
  .right::-webkit-scrollbar {
    width: 7px;
  }
  .right::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(7, 150, 126, 0.2);
  }
  .right::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(21, 31, 15, 0.1);
  }
  .right {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0 20px 20px;
    padding: 18px 30px 0 0;
    max-height: 570px;
    overflow-x: auto;
    overflow-y: auto;
    .page {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      width: 30%;
      overflow: auto;
      margin-top: 5px;
      /deep/ .el-pager li {
        background: none;
        color: #ffffff70;
      }
      /deep/ .el-pager .active {
        color: #1d76e6;
      }
      /deep/ .el-pagination button {
        background: none;
        color: #ffffff70;
      }
      /deep/ .el-pagination__total {
        display: none;
      }
      .jump {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);
        color: #ffffff70;
        font-size: 13px;
        input {
          width: 50px;
          height: 24px;
          border-radius: 5px;
          border: 1px solid #ffffff70;
          background-color: #012856;
          margin-right: 5px;
        }
      }
      .goSystem {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        color: #fff;
        text-align: center;
        font-size: 13px;
        width: 50px;
        height: 24px;
        line-height: 24px;
        background: linear-gradient(180deg, #57bef5 0%, #006bff 100%);
        border-radius: 2px;
        opacity: 0.7;
        cursor: pointer;
      }
    }
    .search {
      padding: 0;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
      .title {
        background-image: url('~@/assets/gd/ds_dialogtitle.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #fff;
        width: 178px;
        height: 30px;
        line-height: 26px;
        padding: 0 10px;
      }
      /deep/ .el-input__prefix {
        position: absolute;
        width: 20px;
        left: 80px;
        z-index: 0;
      }

      /deep/ .el-date-editor--date:hover .el-input__prefix .el-input__icon {
        display: none;
      }

      /deep/ .el-input__suffix {
        z-index: 1;
      }

      /deep/ .el-input__icon {
        line-height: 32px;
      }
      /deep/ .el-input--prefix .el-input__inner {
        position: relative;
        width: 110px;
        height: 30px;
        line-height: 26px;
        padding-left: 5px;
        text-align: left;
        color: rgba(255, 255, 255, 0.64);
        background: linear-gradient(
          360deg,
          rgba(0, 104, 192, 0) 0%,
          rgba(0, 204, 255, 0.15) 100%
        );
        opacity: 0.7;
        border-radius: 2px;
        border: 1px solid #00aeff;
        cursor: pointer;
      }
      .el-date-editor.el-input {
        width: 110px;
        height: 30px;
        cursor: pointer;
        margin-left: 10px;
        border: none;
      }
    }
    /deep/ .el-table::before {
      background: none !important;
    }
    /deep/ .table_head {
      color: #fff;
      background-color: #00408a !important;
      border-top: 1px solid #00aeff !important;
      border-bottom: #0057ae !important;
      box-shadow: inset 0px -1px 0px 0px rgba(0, 120, 255, 0.3) !important;
      box-shadow: 0px -1px 0px 0px rgba(0, 174, 255, 1) !important;
    }

    
    /deep/ .table_head:first-child {
      background-image: url('~@/assets/gd/ds_dialeft.png') !important;
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: right;
    }

    /deep/ .table_head:nth-last-child(2) {
      background-image: url('~@/assets/gd/ds_diaright.png') !important;
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: right;
    }

    /deep/ .table_head .cell {
      padding-left: 30px;
    }

    /deep/ .table_head .cell::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 10px;
      width: 10px;
      height: 10px;
      background-image: url('~@/assets/gd/ds_td.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    
    /deep/ .el-table__body-wrapper,.el-table{
      background: #002f64 !important;
    }

    /deep/ .el-table__row:nth-child(odd) {
      background: #002f64 !important;
      color: #fff;
    }

    /deep/ .el-table__row:nth-child(odd) td {
      border-bottom: #002f64 !important;
    }

    /deep/ .el-table__row:nth-child(even) {
      background: #004278 !important;
      color: #fff;
    }

    /deep/ .el-table__row:nth-child(even) td {
      border-bottom: #004278 !important;
    }

    /deep/ .el-table__empty-block{
      background: #002f64 !important;
    }

    /deep/ .el-table__row td:first-child {
      background-image: url('~@/assets/gd/ds_tr.png');
      background-size: 96px 8px;
      background-repeat: no-repeat;
      background-position: left bottom;
      color: #9ee0ff;
    }

    // /deep/ .el-table__row td:nth-child(2) {
    //   color: #ffc800;
    // }

    // /deep/ .el-table__row td:nth-child(3) {
    //   color: #00ff98;
    // }

    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td,
    /deep/ .current-row > td.el-table__cell {
      background-color: transparent !important;
      //   background-color: #4f8db2!important;
    }
  }
}
</style>