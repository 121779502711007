<template>
  <div class="bg box_bg">
    <div class="chartbtn">
      <!-- <div
        :class="chartIndex == index ? 'selected' : ''"
        @click="checkChart(index)"
        v-for="(item, index) in chartBtn"
      >
        {{ item.title }}
      </div> -->
    </div>
    <WPanelEntry
      title="人流量分析"
      :selectData="false"
      v-loading="loading"
      element-loading-text="页面加载中,请稍等..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0)"
    >
      <slot>
        <NoData v-if="noDataText" :text="noDataText" />
        <div class="charts" @click="openDialog">
          <ja-init-chart
            class="jachart"
            :options="options"
            :chartPlay="true"
            ref="chart"
            v-if="!noDataText"
          />
        </div>
      </slot>
    </WPanelEntry>

    <chartDialog
      ref="dialog"
      :title="title"
      :conditionData="conditionData"
      :columns="columns"
      :getDatamethod="getAccessInfo"
      :systemurl="systemurl"
      tablename="人员通行列表"
    >
      <!-- 传递图表给弹窗 -->
      <template v-slot:chart>
        <ja-init-chart class="jachart" :options="options" v-if="!noDataText" />
      </template>
    </chartDialog>
  </div>
</template>

<script>
import echarts from '/node_modules/echarts'; 
import { getAccessInfo, traffic} from '@/api'
import WPanelEntry from '../../WPanelEntry'
import JaInitChart from '@/components/ja-init-chart/index'
import NoData from '@/components/noData'
import chartDialog from '../../chartDialog/index.vue'
export default {
  title: '人流量分析',
  name: 'energyConsumption',
  components: {
    WPanelEntry,
    JaInitChart,
    NoData,
    chartDialog
  },
  data() {
    return {
      options: {},
      loading: true,
      title: '人流量分析',
      getAccessInfo,
      noDataText:'',
      conditionData: [
        {
          input_type: 'date',
          type: 'daterange',
          key: 'time',
          value: 'time',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          valueFormat: 'yyyy-MM-dd'
        },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' }
      ],
      columns: [
        { label: '人员类型', prop: 'imageType' },
        { label: '公司', prop: 'deptName' },
        { label: '通行状态', prop: 'openResult' },
      ],
      selectedDataIndex: 0,
      selectedData: '',
      interval: null,
      systemurl: '/home/traffic/accessRecordNew',
      // dayNum: [150000, 310000, 190000, 320000, 350000, 290000, 185000],
      // mounthNum: [450000, 410000, 590000, 720000, 750000, 690000, 585000],
      // yearNum: [2150000, 2310000, 2190000, 1320000, 2350000, 1290000, 2185000],
      echartData: [560, 800, 752, 586, 900, 776, 682],
      // dayDate: ['10.1', '10.2', '10.3', '10.4', '10.5', '10.6', '10.7'],
      // mounthDate: ['2023-03', '2023-04', '2023-05', '2023-06', '2023-07', '2023-08', '2023-09'],
      // yearDate: ['2017', '2018', '2019', '2020', '2021', '2022', '2023'],
      echatX:['12', '13', '14', '15', '16', '17'],
      chartBtn: [
        { title: '日' },
        // { title: '月' },
        // { title: '年' }
        ],
      chartIndex:0,
      dataInterval:null
    }
  },
  created() {
    // if (this.$store.state.token) {
    //   this.noDataText = ''
    this.startDataInterval()
    // } else {
    //   this.noDataText = '-- 请求数据缺少token --'
    //   this.loading = false
    // }
  },
  computed:{
    maxData(){
      let max = Math.max(...this.echartData);
      max = Math.ceil(max / 10) * 11;
      let arr = []
      this.echartData.forEach(item => {
        arr.push(max)
      })
      return arr
    }
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
  },
  methods: {
    startDataInterval(){
      this.init()
      this.dataInterval = setInterval(() => {
            this.init()
      }, 35000);
    },
    async init() {
      this.loading = false
      const {data} = await traffic()
      this.echatX = data.timeData || []
      this.echartData = data.dailyData || []
      this.$refs.chart.myChart.clear();
      this.$refs.chart.drawInit()
      this.setOptions()
    },
    setOptions(){
      this.options = {
        tooltip: {
          z: -1,
          show: true,
          position: function (point, params, dom, rect, size) {
            return [point[0] - 30, point[1] - 50] //返回x、y（横向、纵向）两个点的位置
          },
          type: 'item',
          // backgroundImage: url('~@/assets/images/screen/echarts_popup.png')
          backgroundColor: 'transparent',
          formatter: function (params) {
            if (params.data >= 10000) {
              var data = params.data / 10000 
            }else{
              var data = params.data
            }
            // var str =params['percent']+'%'+'<br/>'+params.data.value+'笔'
            var str =
              // `<div style = "padding-top: 10px;font-size: 16px;background-image:url(${require('../../../../../assets/images/screen/echarts_popup.png')});background-size: 100% 100%;width: 123px;height: 58px;">
              // <div style = "width: 70%;margin: 0 auto;">${params.name}<br/> ${
              //   params.seriesName
              // }: ${params.data}</div></div>`
              `
            <div style="position: absolute;top:0;width: 50px;height: 30px;">
                <div style=" width: 100%;height: 25px;line-height:25px;background-color: rgba(199,255,221,0.5);color:#fff2a8;text-align:center;border-radius:5px">${data}</div>
                <div style="position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 0;
                            height: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid rgba(199,255,221,0.5);"></div>
                <div style="position: absolute; 
                            width:6px;
                            height:6px;
                            border-radius:50%;
                            background:#F7FF41;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -8px;"></div>
            </div>
            `
            return str
          }
        },
        grid: {
          left: '1%',
          right: '8%',
          bottom: '1%',
          height: '80%',
          containLabel: true
        },
        graphic: {
          elements: [
            {
              type: 'text',
              left: '5',
              top: '15',
              style: {
                text: '单位 / 次',
                fontSize: 13,
                fill: '#818a98'
              }
            }
          ]
        },
        xAxis: {
          type: 'category',
          data: this.echatX,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#818a98',
              width: 1
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 12
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: true,
            alignWithLabel: true,
            inside: true,
            length: 7,
            lineStyle: {
              // 设置刻度线样式
              color: '#fff', // 刻度线颜色
              width: 3 // 刻度线宽度,
            }
          }
        },
        yAxis: {
          nameTextStyle: {
            color: '#fff',
            fontSize: '14px',
            verticalAlign: 'middle'
          },
          type: 'value',
          axisLabel: {
            color: '#818a98',
            fontSize: 12,
            formatter: function (value, index) {
              if (value >= 10000) {
                return value / 10000
              } else {
                return value
              }
            }
          },
          axisLine: {
            lineStyle: {
              color: '#818a98',
              fontWeight: 200
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#818a98'
            }
          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '人流量',
            data: this.echartData,
            type: 'line',
            animationDuration: 1500,
            symbol: 'circle',
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: '#04da3a',
                borderColor: '#c7ffdd',
                borderType: 'solid',
                lineStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#c7ffdd' },
                    { offset: 1, color: '#d1ff96' }
                  ])
                }
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#D1FF9636'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,179,244,0)'
                    }
                  ],
                  false
                ),
                shadowColor: '#D1FF9600',
                shadowBlur: 20
              }
            },
             z: 8,
          },
          {
              name: '占位',
              type: 'pictorialBar',
              barWidth: '30%',
              barGap: '-100%',
              silent: true,
              symbol: 'image://'+ this.chartcar,
              symbolClip: true,
              symbolSize: [42, '100%'],
              data: this.maxData
          }
        ]
      }
    },
    openDialog() {
      this.$refs.dialog.showDialog()
    },
    checkChart(i) {
      this.chartIndex = i
      const dataList = [
        this.dayNum,
        this.mounthNum,
        this.yearNum
      ]
      const dataX = [
        this.dayDate,
        this.mounthDate,
        this.yearDate
      ]
      this.echartData = dataList[i]
      this.echatX = dataX[i]
      this.setOptions()
    }
  },
  watch: {
    // '$store.state.token': function () {
    //   if (this.$store.state.token) {
    //   } else {
    //     this.noDataText = '-- 请求数据缺少token --'
    //     this.loading = false
    //   }
    // },
    // '$store.state.parkType': function () {
    //   // console.log("测试打印")
    //   this.parkType = this.$store.state.parkType
    //   this.init()
    // }
  }
}
</script>

<style lang="scss" scoped>
.box_bg {
  position: relative;
  // background-image: url('~@/assets/images/screen/office_box1.png');
  .chartbtn {
    position: absolute;
    top: 8px;
    right: 15px;
    display: flex;
    z-index: 20;
    div {
      width: 66px;
      height: 24px;
      border: none;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      background-image: url('~@/assets/gd/ds_chatbtn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    div:hover {
      cursor: pointer;
      background-image: url('~@/assets/gd/ds_chatbtnx.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    div.selected {
      background-image: url('~@/assets/gd/ds_chatbtnx.png');
    }
  }
}
.jachart {
  z-index: 0;
}
.charts {
  width: 100%;
  height: 100%;
  display: inline-block;
}
// .entry_left {
.personStat_title {
  display: flex;
  color: #2eb5ee;
  font-size: 16px;
  padding-top: 10px;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  li {
    span {
      display: inline-block;
      width: 30px;
      background: #19236c;
      text-align: center;
      margin-right: 8px;
      height: 30px;
      border-radius: 4px;
      line-height: 28px;
      font-size: 14px;
      cursor: pointer;
    }
    .curr {
      background: #226ca3;
      color: #f8fdff;
    }
  }
}
// }
</style>