<template>
    <div class="main">
        <div class="main_top">
            <div class="top">
                <span>年份</span>
                <el-select v-model="year" placeholder="请选择" size="mini" style="width: 100px" @change="threeStatistics">
                    <el-option v-for="item in yearList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <!-- 年度统计 -->
            <el-row class="totle"
                    v-loading="show">
                <el-col :span="3">
                    <div class="box1">
                        <span class="topText">上月用电</span>
                        <div class="bottomTest">
                            <span>{{totleData.lastElectricity || 0}}</span>
                            <span class="rightText">kW·h</span>
                        </div>
                    </div>
                    <div class="box1">
                        <span class="topText">上月同比</span>
                        <div class="bottomTest">
                            <span>{{totleData.electricityYoY || 0}}</span>
                            <span class="rightText">%</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="box1">
                        <span class="topText">年度用电</span>
                        <div class="bottomTest">
                            <span>{{totleData.annualElectricity || 0}}</span>
                            <span class="rightText">kW·h</span>
                        </div>
                    </div>
                    <div class="box1">
                        <span class="topText">上月环比</span>
                        <div class="bottomTest">
                            <span>{{totleData.electricityQoQ || 0}}</span>
                            <span class="rightText">%</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="box2">
                        <span class="topText">上月用水</span>
                        <div class="bottomTest">
                            <span>{{totleData.lastWater || 0}}</span>
                            <span class="rightText">m³</span>
                        </div>
                    </div>
                    <div class="box2">
                        <span class="topText">上月同比</span>
                        <div class="bottomTest">
                            <span>{{totleData.waterYoY || 0}}</span>
                            <span class="rightText">%</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="box2">
                        <span class="topText">年度用水</span>
                        <div class="bottomTest">
                            <span>{{totleData.annualWater || 0}}</span>
                            <span class="rightText">m³</span>
                        </div>
                    </div>
                    <div class="box2">
                        <span class="topText">上月环比</span>
                        <div class="bottomTest">
                            <span>{{totleData.waterQoQ || 0}}</span>
                            <span class="rightText">%</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="box3">
                        <span class="topText">上月用冷</span>
                        <div class="bottomTest">
                            <span>{{totleData.lastCool || 0}}</span>
                            <span class="rightText">kW·h</span>
                        </div>
                    </div>
                    <div class="box3">
                        <span class="topText">上月同比</span>
                        <div class="bottomTest">
                            <span>{{totleData.coolYoY || 0}}</span>
                            <span class="rightText">%</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="box3">
                        <span class="topText">年度用冷</span>
                        <div class="bottomTest">
                            <span>{{totleData.annualCold || 0}}</span>
                            <span class="rightText">kW·h</span>
                        </div>
                    </div>
                    <div class="box3">
                        <span class="topText">上月环比</span>
                        <div class="bottomTest">
                            <span>{{totleData.coolQoQ || 0}}</span>
                            <span class="rightText">%</span>
                        </div>
                    </div>
                </el-col>
            </el-row>
    
            <el-row class="ranking">
    
                <!-- 统计 -->
                <statistics title="年度能耗趋势" ref="statis"/>
            </el-row>
        </div>
        <div class="main_top main_bottom">
            <el-row>
                <el-col :span="8">
                    <ranking titleLeft="租户用电月排名" :requestApi="energySort" titleRight="单位：kW·h" type="1" seriesBGColor="#02a9ec"></ranking>
                </el-col>
                <el-col :span="8">
                    <ranking titleLeft="租户用水月排名" :requestApi="energySort" titleRight="单位：m³" type="2" seriesBGColor="#cf9ef1"></ranking>

                </el-col>
                <el-col :span="8">
                    <ranking titleLeft="租户用冷月排名" :requestApi="energySort" titleRight="单位：kW·h" type="3" seriesBGColor="#FF8E14"></ranking>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <ranking titleLeft="空间用电月排名" :requestApi="spaceEnergySort" titleRight="单位：kW·h" type="1" seriesBGColor="#AF5AFF"></ranking>
                </el-col>
                <el-col :span="8">
                    <ranking titleLeft="空间用水月排名" :requestApi="spaceEnergySort" titleRight="单位：m³" type="2" seriesBGColor="#25dd59"></ranking>

                </el-col>
                <el-col :span="8">
                    <ranking titleLeft="空间用冷月排名" :requestApi="spaceEnergySort" titleRight="单位：kW·h" type="3" seriesBGColor="#e75fc3"></ranking>
                </el-col>
            </el-row> 
            <el-row>
                <el-col :span="8">
                    <ranking titleLeft="专业电表用电月排名" :requestApi="classifyEnergySort" titleRight="单位：kW·h"  seriesBGColor="#57e7ec"></ranking>
                </el-col>
            </el-row>  
        </div>
    </div>
</template>

<script>
import Statistics from './components/statistics.vue'
import ranking from './components/ranking.vue'
import { energyThreeStatistics,energySort,spaceEnergySort,classifyEnergySort } from '@/api'
export default {
    title: "能源统计",
    components: { Statistics,ranking },
    data() {
        return {
            totleData: {
                annualElectricity: 0,
                annualWater: 0,
                monthElectricity: 0,
                monthWaterYeld: 0
            },
            yearList:[
                {label:'2023',value:2023}
            ],
            year: new Date().getFullYear(),
            charData:{},
            show: true,
            energySort:energySort,
            spaceEnergySort:spaceEnergySort,
            classifyEnergySort:classifyEnergySort,
        }
    },
    mounted() {
        this.threeStatistics()
    },
    methods: {
        threeStatistics(){
            const date = new Date().getFullYear()
            // 处理年份下拉数据
            if(this.yearList[0].value !== date){
                this.yearList.unshift({label:String(date),value:date})
            }
            // 获取年度统计数据
            energyThreeStatistics({year:this.year}).then(res => {
                this.totleData = res.data
                for (const iterator in this.totleData) {
                    if (iterator !== 'waterUsage' && iterator !== 'electricityUsage' && iterator !== 'coolUsage') {
                        this.totleData[iterator] = Number(this.totleData[iterator]).toFixed(2)
                    }
                }
                this.show = false
                const {waterUsage,electricityUsage,coolUsage} = this.totleData
                this.$refs.statis.setOptions({ waterUsage,electricityUsage,coolUsage})
            })
        }
    }

}
</script>
<style lang="scss" scoped>
.main{
    margin: 10px 20px;
}
.main_top{
    background-color: #fff;
    border-radius: 12px;
}
.main_bottom{
    padding: 16px;
    margin: 20px 0;
}
.red{
    color: red!important;
}
.top{
    padding: 20px 0;
    padding-left: 60px;
    box-sizing: border-box;

    span{
        margin-right: 40px;
        font-size: 16px;
    }

    /deep/ .el-select{
        width: 200px !important;
    }
}
.totle {
    padding: 0.5vw;
    text-align: center;
    display: flex;
    justify-content: space-between;

    .bottomTest {
        white-space: nowrap;
        display: block;
        text-align: center;
        margin: 40px 0;
        font-size: 26px;

        > .rightText {
            font-size: 15px;
        }
    }
    .topText {
        white-space: nowrap;
        width: 80%;
        height: 20%;
        display: inline-block;
        box-sizing: border-box;
        padding: 5px 0;
        border-radius: 20px;
        color: #fff;
    }
}
.box1 {
    border-radius: 12px;
    .bottomTest {
        color: #027db4;
    }
    .topText {
        background: #027db4;
    }
}
.box2 {
    border-radius: 15px;
    .bottomTest {
        color: #f59a22;
    }
    .topText {
        background: #f59a22;
    }
}
.box3 {
    border-radius: 15px;
    .bottomTest {
        color: #555555;
    }
    .topText {
        background: #555555;
    }
}
.ranking {
    margin: 0 12px;
    padding: 10px 20px;
}
</style>
