<template>
    <div>
      <el-dialog v-dialogDrag :close-on-click-modal="false" :visible.sync="showEditAll" width="55%" :title="(isAdd?'新增':'编辑')+' '">
        <ja-form :rules="rules" ref="jaForm" :label-width="labelWidth" v-model="rowForm" :formArray="rowFormData" :loadingForm="loadingForm" @reset="showEditAll = false" @save="saveForm" v-if="showEditAll"
        @addTriggerDevice="addTriggerDevice"
        @delTriggerDevice="delTriggerDevice"
        @addLinkDevice="addLinkDevice"
        @delLinkDevice="delLinkDevice"
        @linkageTypeChange="linkageTypeChange"

        @handleSelectionChange="handleSelectionChange"
        >
        </ja-form>
      </el-dialog>

      <!-- 设备弹窗 -->
      <device-list ref="deviceList" :show="deviceShow" :httpType="httpType" :linkageTypeId="rowForm.linkageTypeId" :configId='rowForm.id' @closeDeviceList="closeDeviceList" @addDevice="addDevice"></device-list>
    </div>
  </template>
  <script>
import {linkageTypeAll,linkCondition,linkageAction,addLinkageConfig,updateLinkageConfig} from '@/api/module/alarm.js'
import {reqDictData} from '@/api'
import DeviceList from './DeviceList'
  
export default {
  inject: ['labelWidth', 'getInit'],
  components:{
    DeviceList
  },
  data() {
    return {
      rowFormData: [
        { label: '联动类型', input_type: 'select', key: 'linkageTypeId', options: [
          {value:0,label:1}
        ],col:22,method:"linkageTypeChange"},
        { label: '触发设备', input_type: 'button', text:"删除",method:'delTriggerDevice',type:'danger',col:6},
        { label: '', input_type: 'button', text:"添加",method:'addTriggerDevice'},
        {
          input_type: 'table',
          noSearch: true,
          showPage: false,
          showSelect:true,
          key: 'triggerDevice',
          col: 24,
          columns: [
            { label: '设备小类', prop: 'deviceSubcategoryName' },
            { label: '设备名称', prop: 'deviceName' },
            { label: '所在楼栋', prop: 'deviceBuildingName' },
            { label: '所在楼层', prop: 'deviceFloorName' },
            { label: '解发条件', prop: 'conditionId' ,column_type: 'select',options:[]},
            { label: '判断规则', prop: 'rule' ,column_type: 'select',options:[]},
            { label: '条件值', prop: 'triggerValue',column_type: 'input' }
          ]
        },
        { label: '联动设备', input_type: 'button', text:"删除",method:'delLinkDevice',type:'danger',col:6},
        { label: '', input_type: 'button', text:"添加",method:'addLinkDevice'},
        {
          input_type: 'table',
          noSearch: true,
          showPage: false,
          showSelect:true,
          key: 'linkageDevice',
          col: 24,
          columns: [
            { label: '设备小类', prop: 'deviceSubcategoryName' },
            { label: '设备名称', prop: 'deviceName' },
            { label: '所在楼栋', prop: 'deviceBuildingName' },
            { label: '所在楼层', prop: 'deviceFloorName' },
            { label: '动作', prop: 'actionId' ,column_type: 'select',options:[]},
            { label: '动作值', prop: 'linkageValue',column_type: 'input' }
          ]
        },
    
      ],
      rowForm: {},
      rules: {
        linkageTypeId: [{ required: true, message: '请选择联动类型', trigger: 'change' }],
      },
      loadingForm: false,
      showEditAll: false, // 显示新增/编辑弹窗,
      isAdd: false,
      indexObj: {},

      deviceShow:false,
      httpType:false,

      triggerDeviceDel:[],
      linkageDeviceDel:[]
    }
  },
  async created() {
    // const {data:linkageTypeResult} =  await linkageTypeAll()
    // const linkageTypeOption = linkageTypeResult.map(item=>{
    //   return {label:item.typeName,value:item.id}
    // })
  
    // this.rowFormData.forEach(item=>{
    //   if(item.key==='linkageTypeId') item.options = linkageTypeOption
    // })
  },

  methods: {
    async append() {
      console.log("add ");
      this.isAdd = true
      this.rowForm = {triggerDevice:[],linkageDevice:[]}
      this.showEditAll = true
    },
    rowEdit({triggerDevice,linkageDevice,id,linkageTypeId}) {
      this.isAdd = false
      this.titleText = '编辑'
      triggerDevice.forEach(item=>item.httpType = true)
      linkageDevice.forEach(item=>item.httpType = false)
      this.rowForm = JSON.parse(JSON.stringify({ triggerDevice,linkageDevice,id, linkageTypeId}))
      this.getTableOption()
      this.showEditAll = true
    },
  
    saveForm() {
      if(!this.rowForm.triggerDevice || this.rowForm.triggerDevice.length===0 ){
        this.$message({
          message: '请选择 触发设备',
          type: 'warning'
        });
        return false
      }

      if(!this.rowForm.linkageDevice || this.rowForm.linkageDevice.length===0){
        this.$message({
          message: '请选择 联动设备',
          type: 'warning'
        });
        return false
      }

      this.loadingForm = true
      let rqMethod = this.isAdd ? addLinkageConfig : updateLinkageConfig
      rqMethod(this.rowForm).then(res => {
        this.loadingForm = false
        this.$message.success(res.msg)
        this.showEditAll = false
        this.getInit()
      }).catch(error => {
        this.loadingForm = false
      })
    },

    // 关闭设备弹窗
    closeDeviceList(){
      this.deviceShow = false
    },
    // 联动类型改变，清空列表,查询出列表中下拉选项
    async linkageTypeChange(){
      this.getTableOption()
      this.rowForm.triggerDevice=[]
      this.rowForm.linkageDevice=[]
    },

    // 将选中的设备添加到列表中
    addDevice(deviceList){
      if(this.httpType){
        
        // 触发设备

        this.rowForm.triggerDevice.forEach(deviceItem=>{
          deviceList =  deviceList.filter(item=>item.deviceId!=deviceItem.deviceId)
        })

        // deviceList.forEach(deviceItem=>{
        //   deviceList =  this.rowForm.triggerDevice.filter(item=>item.deviceId!=deviceItem.deviceId)
        // })
        this.rowForm.triggerDevice.push(...deviceList)
      }else{
        // 联动设备
        this.rowForm.linkageDevice.forEach(deviceItem=>{
          deviceList =  deviceList.filter(item=>item.deviceId!=deviceItem.deviceId)
        })
        // deviceList.forEach(deviceItem=>{
        //   deviceList =  this.rowForm.linkageDevice.filter(item=>item.deviceId!=deviceItem.deviceId)
        // })
        this.rowForm.linkageDevice.push(...deviceList)
      }
      this.closeDeviceList()
    },

    addTriggerDevice(){
      if(this.rowForm.linkageTypeId){
        console.log("addTriggerDevice");
        this.httpType = true
        this.deviceShow = true
      }
    },
    delTriggerDevice(){
      console.log("delTriggerDevice");
      this.triggerDeviceDel.forEach(delD=>{
         this.rowForm.triggerDevice =  this.rowForm.triggerDevice.filter(item=>item.deviceId!=delD.deviceId)
      })
    },

    addLinkDevice(){
      if(this.rowForm.linkageTypeId){
        console.log("addLinkDevice");
        this.httpType = false
        this.deviceShow = true
      }
    },
    delLinkDevice(){
      console.log("delLinkDevice");
      this.linkageDeviceDel.forEach(delD=>{
         this.rowForm.linkageDevice =  this.rowForm.linkageDevice.filter(item=>item.deviceId!=delD.deviceId)
      })
    },

    handleSelectionChange(deviceList){
      if(deviceList && deviceList.length){
        //true:触发设备,false:联动设备
        if(deviceList[0].httpType){
          this.triggerDeviceDel = deviceList
        }else{
          this.linkageDeviceDel = deviceList
        }
      }
      console.log("data1",deviceList);
    },

    // 查询出列表中下拉选项
    async getTableOption(){
      this.loadingForm = true
      const {data:linkConditionResult} =  await linkCondition({linkageTypeId:this.rowForm.linkageTypeId})
      const {data:linkageActionResult} =  await linkageAction({linkageTypeId:this.rowForm.linkageTypeId})
      const {data:linkageRuleResult} =  await reqDictData('/linkage_rule')

      const linkConditionOption = linkConditionResult.map(item=>{
        return {label:item.conditionName,value:item.id}
      })
      const linkageActionOption = linkageActionResult.map(item=>{
        return {label:item.actionName,value:item.id}
      })
      const linkageRuleOption = linkageRuleResult.map(item=>{
        return {label:item.dictLabel,value:item.dictValue}
      })

      this.rowFormData.forEach(item=>{
        if(item.input_type==='table' && item.key==='triggerDevice') {
          item.columns.forEach(col=>{if(col.column_type==='select' && col.prop==='conditionId') col.options = linkConditionOption })
          item.columns.forEach(col=>{if(col.column_type==='select' && col.prop==='rule') col.options = linkageRuleOption })
        }
        if(item.input_type==='table' && item.key==='linkageDevice') {
          item.columns.forEach(col=>{if(col.column_type==='select' && col.prop==='actionId') col.options = linkageActionOption })
        }
      })

      this.loadingForm = false
      console.log(this.rowFormData);
    }


  }
}
  </script>