<template>
    <!-- 照明系统-照明管理 -->
    <div class="allwh">
        <div class="allwh-wrapper">
            <ja-table ref="tst"
                      v-model="filterData"
                      :conditionData="conditionData"
                      @rowDetail="rowDetail"
                      :columns="columns"
                      :tableData="tableData"
                      :total="total"
                      :loading="loading"
                      @append="append"
                      @rowEdit="rowEdit"
                      @rowDelete="rowDelete"
                      @getInit="getInit"
                      @search="search"
                      @bulkEnable="bulkEnable"
                      @bulkDisable="bulkDisable"
                      :valueFormat="valueFormat"
                      showSelect
                      @handleSelectionChange="handleSelectionChange"
                      @importForm="normalEvent({
                        ref: 'importForm',
                        method: 'importForm',
                        url: '/files/回路管理导入模版.xlsx',
                        filename: '回路管理导入模版',
                        uploadURL: '/circuitManagement/upload'
                        })"
                      @reset="reset">
                      <template #operateSlot="{scope}">
                         <el-switch
                            active-value="true"
                            inactive-value="false"
                            style="margin-right:10px"
                            v-model="scope.switching"
                            v-if="isAuth('subsystemModule::circuitManage::switch')"
                            @change="changeSwitching($event,scope)"
                            active-color="#13ce66"
                            >
                        </el-switch> 
                      </template>
                    <template #slotName="{row}">
                        <div :class="['run_status_abnormal',row.runStatus==2?'run_status_normal':'']">
                            {{initialStateObj[row.runStatus]}} <i class="el-icon-question" v-if="row.runStatus==2" @click="clickPrompt(row)"></i>
                        </div>
                    </template>
                    <template #currentScene="{row}">
                        <div :class="[row.scenarioPlanning==1?'sceneType':'']">
                            {{row.currentScene}}
                        </div>
                    </template>
            </ja-table>
            <!-- 导入 -->
            <import-form ref="importForm"
                     @openResult="openResult" />
            <!-- 导入校验 -->
            <check-result ref="checkResult"
                        :results="results" />
            <!-- 新增/编辑 -->
            <!-- reset: 取消 -->
            <!-- saveForm: 保存操作 -->
            <el-dialog v-dialogDrag
                       :close-on-click-modal="false"
                       :visible.sync="showEditAll"
                       width="50%"
                       :title="titleText">
                <ja-form :rules="rules"
                         :label-width="labelWidth"
                         v-model="rowForm"
                         ref="jaForm"
                         :formArray="rowFormData"
                         @reset="showEditAll = false"
                         @save="saveForm"
                         @associateTheDevice='associateTheDevice'
                         @changBuilding="changBuildingFloor"
                         @changFloor="changBuildingFloor"
                         @removeTag="removeTag"
                         v-if="showEditAll"
                         :allDisabled="allDisabled"
                         v-loading="loading_form.isShow"
                         :element-loading-text="loading_form.text"
                         :element-loading-spinner="loading_form.icon"
                         :element-loading-background="loading_form.bg"
                         :element-loading-custom-class="loading_form.formLoading">
                </ja-form>
            </el-dialog>
            <!-- 根据楼层选择设备弹窗 -->
            <el-dialog v-dialogDrag
                :close-on-click-modal="false"
                :visible.sync="showDevicePopUp"
                width="50%"
                title="选择关联设备"
            >
                <ja-table 
                    v-if="showDevicePopUp"
                    noSearch
                    showSelect
                    :showPage="false"
                    ref="tableDevice"
                    v-model="filterData"
                    :columns="columnsDevice"
                    :tableData="deviceTableData"
                    @handleSelectionChange="handleSelectionChangeDevice"
                    @getInit="getInit">
                </ja-table>
                <div class="bottom_box">
                    <span class="total">共{{deviceTotal}}个</span>
                    <span class="selected">共选中{{selectDevice}}个设备</span>
                    <el-button type="primary" @click="invertDevice">反选</el-button>
                    <el-button type="primary" @click="confirmDevice">确认</el-button>
                </div>
            </el-dialog> 
            <!-- 启用失败弹窗提示 -->
            <el-dialog v-dialogDrag
                :close-on-click-modal="false"
                :visible.sync="showFailedToEnable"
                :show-close="false"
                center
                width="36%"
                title="启用失败提示"
            >
                <ul class="enable_prompts">
                    <li>选中回路</li>
                    <li>原因</li>
                </ul>
                <ul class="enable_prompts" v-for="(item,i) in errorMessage" :key="i">
                    <li>{{item.selectCircuit}}</li>
                    <li>{{item.deviceName}}已启用{{item.circuitName}}回路</li>
                </ul>
                <div class="enable_prompts_tail">
                    <!-- <el-button @click="download">下载</el-button> -->
                    <el-button type="primary" @click="already">已知晓</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import table from "@/views/mixins/table";
import checkResult from '@/views/home/components/common/checkResult.vue'
import importForm from '@/views/home/lettingRate/common/importForm.vue'
import { getCircuitManagement, addCircuitManagement, editCircuitManagement, 
delCircuitManagement,getLightList,circuitetSwitchPrompt,circuitetSwitching,verifyCircuitManagement } from '@/api'

export default {
    title: '回路管理',
    mixins: [table],
    name: 'circuitManage',
    components: {
        checkResult,
        importForm
    },
    data() {
        return {
            columnsDevice:[
               {label:'设备名称',prop:'deviceName'},
               {label:'设备编码',prop:'deviceCode'}
            ],
            deviceTableData:[
                
            ],
            deviceTotal:0,
            selectDevice:0,
            allDisabled:false,
            filterData: { pageIndex: 1, pageSize: 10 }, // 默认页码
            conditionData: [
                {
                    input_type: 'select', key: 'buildingId', attribute: { placeholder: '所属楼栋', size: 'small' }, options: [],
                },
                {
                    input_type: 'select', key: 'floorId', attribute: { placeholder: '所属楼层', size: 'small' }, options: [],
                },
                { input_type: 'input', key: 'circuitName', attribute: { placeholder: '回路名称', size: 'small' } },
                {
                    input_type: 'select', key: 'currentScene', attribute: { placeholder: '当前场景', size: 'small' }, options: [],
                },
                { input_type: 'select', key: 'runStatus', attribute: { placeholder: '状态', size: 'small' },
                    options:[
                        {label:'正常',value:'1'},
                        {label:'提示',value:'2'},
                    ]
                 },
                { normalBtn: 'search', limit: 'default:view' },
                { normalBtn: 'reset' },
                { normalBtn: 'add', limit: 'subsystemModule::circuitManage::add' },
                { normalBtn: 'import', text: '导入',limit: 'subsystemModule::circuitManage::import'},
                { normalBtn: 'download', text: '导出', exportObj: { rqParmas: this.rqexport, rqUrl: '/circuitManagement/export', rqMethod: 'post' }, limit: 'subsystemModule::circuitManage::export' },
                { input_type: 'button', text: '批量启用',  method:"bulkEnable",limit: 'subsystemModule::circuitManage::batchActivation'},
                { input_type: 'button', text: '批量禁用',  method:"bulkDisable",limit: 'subsystemModule::circuitManage::batchDisable'},
            ],
            conditionDataMap: { category: 0, subcategory: 1 },
            deviceNameList:[],
            columns: [
                { label: '回路编号', prop: 'circuitNumber' },
                { label: '回路名称', prop: 'circuitName' },
                { label: '回路位置', prop: 'loopPosition' },
                { label: '关联设备', prop: 'associatedDevices', width: 80 },
                { label: '运行状态', prop: 'runStatus', width: 80,slotName:'slotName' },
                { label: '当前场景', prop: 'currentScene', width: 80,slotName:'currentScene' },
                {
                    label: '操作',
                    input_type: 'button',
                    width: 200,
                    attribute: { fixed: "right", align: 'center' },
                    list: [
                        { text: '查看', method: 'rowDetail', limit: 'subsystemModule::circuitManage::see' },
                        { text: '编辑', method: 'rowEdit', limit: 'subsystemModule::circuitManage::edit' }, { text: '删除', method: 'rowDelete', limit: 'subsystemModule::circuitManage::del' },]// limit: 'admin::user::delete'
                }
            ],
            tableData: [],
            loading: false,
            total: 0,
            // 新增/编辑
            titleText: '编辑',
            rowFormData_base: [
                { input_type: 'input', label: '回路名称', key: 'circuitName', maxlength: 20 },
                {
                    label: '关联楼栋', input_type: 'select', key: 'buildingId', options: [],
                    method:'changBuilding'
                },
                {
                    label: '关联楼层', input_type: 'select', key: 'floorId', options: [], method:'changFloor'
                },
                { input_type: 'select', label: '适用场景', multiple:true, key: 'sceneManagementIds', method2:'removeTag', options: [],},
                { input_type: 'input', label: '当前场景', key: 'currentScene',hide:true},
                { input_type: 'input', label: '关联设备', key: 'associatedDevices', focusMethod:'associateTheDevice',type:'textarea',maxlength:"10000",col:22,},
            ],
            rowFormData: [],
            rules: {
                circuitName: [{ required: true, trigger: 'change',message: '请输入回路名称', }],
                associatedDevices: [{ required: true, trigger: 'change', message: '请选择设备', }],
                buildingId: [{ required: true, message: '请选择所属楼栋', trigger: 'change' }],
                floorId: [{ required: true, message: '请选择所属楼层', trigger: 'change' }],
            },
            rowForm: {},
            showEditAll: false, // 显示新增/编辑弹窗
            labelWidth: `${this.util.setFontSize(140)}px`,
            showDevicePopUp:false,
            indexObj: {},
            conditionIndexObj:{},
            sceneManagementOptions:[], //适应场景下拉数据
            showFailedToEnable:false,
            results:{},
            selectedList:[],
            errorMessage:[],
            initialStateObj:{1:'正常',2:'提示'}
        }
    },
    created() {
        this.rowFormData = [...this.rowFormData_base]
        this.indexObj = this.util.getArrItemIndex({ arr: this.rowFormData, })
        this.conditionIndexObj = this.util.getArrItemIndex({ arr: this.conditionData,})
    },
    mounted() {
        // 顶部搜索框
         const rowParamObj = { pageIndex: 1, pageSize: 200 }
        // 顶部搜索框
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id', resRule: 'data.data' }) // 楼栋选择
        this.util.rqOptionsList({ who: this.conditionData[this.conditionIndexObj.floorId], rqUrl: "/floor/allList", rqData: rowParamObj, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId', resRule: 'data' }) // 楼层选择
        // 表单里的
        this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.buildingId], rqUrl: "/building/list", rqData: rowParamObj, rqMethod: 'post', labelName: 'buildingName', valueName: 'id', resRule: 'data.data' }) // 楼栋选择
        this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.floorId], rqUrl: "/floor/allList", rqData: rowParamObj, rqMethod: 'get', labelName: 'floorName', valueName: 'floorId', resRule: 'data' }) // 楼层选择
        let sceneManagement=this.util.rqOptionsList({ who: this.rowFormData[this.indexObj.sceneManagementIds], rqUrl: "/sceneManagement/page", rqData: { pageIndex: 1, pageSize: 2000 }, rqMethod: 'post', labelName: 'sceneDescription', valueName: 'id', resRule: 'data.data' })
        // 获取适应场景下拉数据
        sceneManagement.then(res=>{
            this.sceneManagementOptions=res
            this.conditionData[this.conditionIndexObj.currentScene].options=this.sceneManagementOptions
        })
         
    }, 
    watch:{
        'rowForm.buildingId'(){
            this.getLightList()
        },
        'rowForm.floorId'(){
            this.getLightList()
        },
        'rowForm.sceneManagementIds'(){
            if(!this.rowForm.sceneManagementIds || this.rowForm.sceneManagementIds.length==0) return
            let arr=[]
            this.rowForm.sceneManagementIds.forEach(id => {
                this.sceneManagementOptions.map(item=>{
                    if(item.value==id){
                        arr.push(item)
                    }
                })
            });
        }
    },
    methods: {
        rqexport() {
            let obj = { ...this.filterData }
            obj.pageIndex = this.total <= 2000 ? 1 : this.filterData.pageIndex
            obj.pageSize = 2000
            obj.startTime = obj.time ? obj.time[0] : ''
            obj.endTime = obj.time ? obj.time[1] : ''
            return obj
        },
        getInit: function (item) { // 查询设备列表
            this.loading = true
            getCircuitManagement(this.filterData).then(res => {
                this.loading = false
                const { data, total } = res.data
                this.total = total
                this.tableData = data
            }).catch(err => {
                this.loading = false
            })
        },
        rowDelete: function (row) { // 删除
            this.$confirm('是否确认删除该回路信息').then(res => { // 删除设备列表信息
                verifyCircuitManagement(row.id).then(res=>{
                    if(res.data.list && res.data.list.length>0){
                        let prompt=res.data.list.join('、')
                        this.$confirm(`该回路已被${prompt}引用，删除回路则自动删除对应场景关联回路信息`, '提示', {
                            confirmButtonText: '我再想想',
                            cancelButtonText: '我再想想',
                            type: 'warning'
                        }).then(() => {
                           delCircuitManagement(row.id).then(res => {
                                this.getInit()
                                this.$message.success(res.msg)
                                
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消删除'
                            });          
                        });
                    }else{
                        delCircuitManagement(row.id).then(res => {
                            this.getInit()
                            this.$message.success(res.msg)
                        })
                    }
                })
                
            })
        },
        saveForm: function () { // 保存
            this.loading_form.isShow = true
            if (this.isAdd) {
                const rowForm = this.rowForm
                addCircuitManagement(rowForm).then(res => { // 新增设备列表信息
                    this.loading_form.isShow = false
                    this.$message.success(res.msg)
                    this.showEditAll = false
                    this.getInit()
                }).catch(error => {
                    this.loading_form.isShow = false
                })
            } else {
                editCircuitManagement(this.rowForm).then(res => { // 更新设备列表信息
                    this.loading_form.isShow = false
                    this.$message.success(res.msg)
                    this.showEditAll = false
                    this.getInit()
                }).catch(error => {
                    this.loading_form.isShow = false
                })
            }
        },
        append: function () {
            this.allDisabled=false
            this.isAdd = true
            this.titleText = '新增'
            this.rowForm = {}
            this.deviceTableData=[];
            this.deviceTotal=0
            this.rowFormData[this.indexObj.currentScene].hide=true;
            this.util.clearSelectOptions(this.rowFormData) //  这个 要加，清除关联的下拉列表
            this.showEditAll = true
        },
        rowEdit: function (row) { // 编辑
            this.allDisabled=false
            this.rowForm = {}
            this.isAdd = false
            this.titleText = '编辑'
            this.rowForm = { ...row }
            this.showEditAll = true
            this.rowFormData[this.indexObj.currentScene].hide=true;
            this.$nextTick(() => {
                this.util.setOptionsInit({ arr: this.rowFormData, This: this, obj: this.rowForm, ref: 'jaForm' })
            })
        },
        /**查看 */
        rowDetail(row){
            this.rowEdit(row)
             this.titleText = '查看'
            this.allDisabled=true
            this.rowFormData[this.indexObj.currentScene].hide=false;
        },
        /**获取照明设备 */
        getLightList(){
            if(this.rowForm.buildingId && this.rowForm.floorId){
                getLightList({ pageIndex: 1, pageSize: 1000 ,buildingId:this.rowForm.buildingId,floorId:this.rowForm.floorId}).then(res=>{
                    this.deviceTableData=res.data.data;
                    this.deviceTotal=res.data.total;
                })
             }
        },
        /**点击关联设备框 */
        associateTheDevice(){
            this.showDevicePopUp=true;
            this.selectDevice=this.rowForm.deviceIds?this.rowForm.deviceIds.length:0
            this.$nextTick(()=>{
                this.deviceTableData.forEach(row=>{
                    if(this.rowForm.deviceIds && this.rowForm.deviceIds.length>0){
                        this.rowForm.deviceIds.forEach(selected =>{
                            if(selected === row.deviceId){
                            this.$refs.tableDevice.$refs.jaTable.toggleRowSelection(row,true);
                            }
                        })
                    }
                })
            })
        },
        /**选择设备 */
        handleSelectionChangeDevice(list){
            this.selectDevice=list.length;
            this.deviceNameList=list;
        },
        /**反选设备 */
        invertDevice(){
           const form= this.$refs.tableDevice.$refs.jaTable
           this.deviceTableData.map(item=>{
                form.toggleRowSelection(item)
           })
        },
        /**确认设备 */
        confirmDevice(){
            if(this.deviceNameList.length>0){
                let deviceIds=[];
               let deviceNameList=this.deviceNameList.map(item=>{
                    deviceIds.push(item.deviceId)
                    return item.deviceName
                }).join(',')
                this.rowForm.deviceIds=deviceIds;
                this.$set(this.rowForm,'associatedDevices',deviceNameList)
                this.showDevicePopUp=false
            }else{
                this.$message.error('至少要选择一个设备！')
            }  
        },
        /**新增编辑弹框楼栋楼层下拉变化 */
        changBuildingFloor(){
            this.$set(this.rowForm,'associatedDevices','')
            this.$set(this.rowForm,'deviceIds',[])

        },
        /**弹框适用场景移除结果 */
        removeTag(method, monitor, val, data){
            this.$set(this.rowForm,'sceneCurrentId','')
        },
        /**导入校验 */
        openResult({ data, uploadFlag }) {
            this.results = { data, uploadFlag }
            this.$nextTick(() => {
                this.$refs.checkResult.rowDetail()
            })
        },
        /**
         * 启用失败提示
         * 已知晓 
         * */
        already(){
            this.showFailedToEnable=false;
        },
        /**开关开启前的验证 */
        circuitetSwitchPrompt(state,ids){
            //是开回路的操作，需要调用接口验证
            if(state=='true'){
                circuitetSwitchPrompt({circuitId:ids}).then(res=>{
                    if(res.data && res.data.length>0){
                        this.showFailedToEnable=true;
                        this.errorMessage=res.data
                    }else{
                        circuitetSwitching({switching:state,ids}).then(res=>{
                            if(res.data.body){
                                this.$message({
                                    message: res.data.body,
                                    type: 'error'
                                })
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'success'
                                })
                            }
                            this.getInit()
                        }) 
                    }
                })
            }else{
                circuitetSwitching({switching:state,ids}).then(res=>{
                    if(res.data.body){
                        this.$message({
                            message: res.data.body,
                            type: 'error'
                        })
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                    }
                    this.getInit()
                }) 
            }
        },
       /**批量启用 */
       bulkEnable(){
            if(this.selectedList.length==0){
                this.$message({
                    message: '至少要选择一个回路哦！',
                    type: 'warning'
                })
                return
            }
            let  ids=this.selectedList.map(item=>{
                return item.id
            })
            this.circuitetSwitchPrompt('true',ids)
       },
       /**批量禁用 */
       bulkDisable(){
            if(this.selectedList.length==0){
                this.$message({
                    message: '至少要选择一个回路哦！',
                    type: 'warning'
                })
                return
            }
            let  ids=this.selectedList.map(item=>{
                return item.id
            })
            this.circuitetSwitchPrompt('false',ids)
       },
       /**表格选项改变时 */
       handleSelectionChange(list){
            this.selectedList = list;
       },
       /**点击单个开关按钮 */
       changeSwitching(value,row){
        row.switching = !row.switching  //开关按钮保持点击前状态
        this.circuitetSwitchPrompt(value,[row.id])
       },
       /**点击提示icon */
       clickPrompt(row){
            this.$message.error(row.abnormalPrompt);
       },
       /**过滤表格数据 */
       valueFormat(val,key){
            if(key=='currentScene'){
                return val?val:'--'
            }else {
                return val
            }
       }
    }
}
</script>

<style scoped lang="scss">
.bottom_box{
    text-align: right;
    .selected{
        padding: 0 15px;
        color: #fb3939;
    }
}
.enable_prompts{
    display: flex;
    li{
        width: 50%;
        line-height: 30px;
        padding-left: 20px;
    }
}
.enable_prompts:nth-of-type(1){
    font-size: 18px;
    font-weight: bold;
    background: #efefef;
}
.enable_prompts_tail{
    text-align: right;
    padding-top: 30px;
}
.run_status_abnormal{
    color: #82bf41;
}
.run_status_normal{
    color: #f49931;
}
.sceneType{
    color: #fb3939;
}
</style>
