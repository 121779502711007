var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allwh"},[_c('ja-table',{ref:"tst",attrs:{"conditionData":_vm.conditionData,"columns":_vm.columns,"tableData":_vm.tableData,"total":_vm.total,"loading":_vm.loading,"showIndex":false},on:{"dictonaList":_vm.dictonaList,"getInit":_vm.getInit,"search":_vm.search,"reset":_vm.reset,"showDetail":_vm.showDetail,"importForm":function($event){return _vm.normalEvent({
      ref: 'importForm',
      method: 'importForm',
      url: '/files/电表抄表导入.xlsx',
      filename: '电表抄表导入模板',
      uploadURL: '/electricityMeter/upload'
    })}},model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}}),_c('show-information',{ref:"showInformation"}),_c('import-form',{ref:"importForm",on:{"openResult":_vm.openResult}}),_c('check-result',{ref:"checkResult",attrs:{"results":_vm.results}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }