<template>
    <router-view />
</template>
  
<script>
export default {
    title: "暖通系统",
    name: 'hvacSystem'
}
</script>
  
<style scoped></style>
  