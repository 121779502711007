import http from './plugin/ajax'

// 物资管理
export const getMaterialList = (data) => http.post('/materialsDetailedInfo/list', data)   // 查询
export const addMateria = (data) => http.post('/materialsDetailedInfo', data) // 新增
export const editMateria = (data) => http.put('/materialsDetailedInfo', data) // 编辑
export const deleteMateria = (data) => http.delete('/materialsDetailedInfo/' + data) // 删除

// 领用记录
export const getReceiveList = (data) => http.post('/materialsRecipientsRecords/list', data)   // 查询
export const editReceive = (data) => http.put('/materialsRecipientsRecords', data) // 编辑


//供应商管理
export const getSupplierList = (data) => http.post('/materialsSupplierInfo/list', data)   // 查询
export const addSupplier = (data) => http.post('/materialsSupplierInfo', data) // 新增
export const editSupplier = (data) => http.put('/materialsSupplierInfo', data) // 编辑
export const deleteSupplier = (data) => http.delete('/materialsSupplierInfo/' + data) // 删除
