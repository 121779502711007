import http from './plugin/ajax'

// =========  安防系统  ===========
export const getbuildList = (data) => http.get('/sysSecuritySystem/tree/build', data)
export const getSecuritySystemData = (data) => http.post('/sysSecuritySystem/detailed', data)//请求安防楼层数据
export const getSecuritySystemList = (data) => http.post('/sysSecuritySystem/floor', data)//安防分页接口
//右侧设备状态总览、今日告警、维修详情
export const getSecurityConfig = (data) => http.post('/air/security', data)
//安防门禁面板接口
export const getAccessControlStatus = (params) => http.get('/device/info/getAccessControlStatus', {params})

// =========  照明系统  ===========
export const getLightingbuildList = (data) => http.get('/sysLightingSystem/tree/build', data)
export const getLightingSystemData = (data) => http.post('/sysLightingSystem/detailed', data)//请求照明楼层数据
export const getLightingSystemList = (data) => http.post('/sysLightingSystem/floor', data)//照明分页接口
// 右侧维修工单详情
export const getLightingMaintenance = (data) => http.post('/work/info/lightingMaintenance', data)
// 右侧设备统计
export const getLightingInfo = (data) => http.post('/lightList/lightingInfo', data)
// 右侧告警
export const getLightingAlarm = (data) => http.post('/alarmRecord/lightingAlarm', data)

// =========  暖通系统  ===========
export const getHvacbuildList = (data) => http.get('/sysHvacSystem/tree/build', data)
export const getHvacSystemData = (data) => http.post('/sysHvacSystem/detailed', data)//请求暖通楼层数据
export const getHvacSystemList = (data) => http.post('/sysHvacSystem/floor', data)//暖通分页接口
//右侧设备状态总览、今日告警、维修详情
export const getHvacsecurity = (data) => http.post('/air/getHVAC', data)

export const getAirList = (params) => http.get('/air/getAirList',{params})//获取空调面板数据
export const updateAir = (params) => http.post('/air/updateAir',params)//修改空调面板数据
export const getThermostatList = (params) => http.get('/air/getThermostatList',{params})//温控器面板数据
export const getEnvSensors = (params) => http.get('/air/getEnvSensors',{params})//环境传感器数据接口
export const updateThermostat = (params) => http.post('/air/updateThermostat',params)//修改温控器接口

// =========  能源系统  ===========
export const getDistributionbuildList = (data) => http.get('/sysDistributionSystem/tree/build', data)
export const getDistributionSystemData = (data) => http.post('/sysDistributionSystem/detailed', data)//请求暖通楼层数据
export const getDistributionSystemList = (data) => http.post('/sysDistributionSystem/floor', data)//暖通分页接口

// =========  综合态势管理  ===========
export const getRealTime = () => http.get('/wisdom/getRealTime') // 环境测量、实时人数、昨日能耗、实时车辆
export const getPopUpRealTime = () => http.get('/wisdom/getPopUpRealTime') // 中间弹窗园区人数走势、园区车辆走势、能耗对比

// 右侧维修工单详情
export const getHydroelectricCooling = (data) => http.post('/work/info/hydroelectricCooling', data)
// 右侧设备统计
export const getEnergyStatistics = (data) => http.post('/energyQuery/energyStatistics', data)
// 右侧告警
export const getHydroelectricCoolingAlarm = (data) => http.post('/alarmRecord/hydroelectricCoolingAlarm', data)

export const getElectricity = (data) => http.post('/energyQuery/electricityStatistics', data)//综合态势-查询能源电表统计信息
export const getWaterStatistics = (data) => http.post('/energyQuery/waterStatistics', data)//综合态势-查询能源水表统计信息

// =========  可视化图表  =========
export const getcomprehensive = (data) => http.post('/sysRentalRate/comprehensiveList', data)// 房屋出租率
export const getworkChart = (data) => http.get('/work/info/total', data)// 物业维修分析





// 监控
export const getVideo = (data) => http.get('/securityVideo/realtime/rtsp', { params: data })//获取监控

export const getVideoList = (data) => http.get('/video/replay/replayVideo', { params: data })

// ******   智慧安防      ******

// 查看录像回放
export const getHisPreviewURL = (data) => http.get('/patrol/views/getHisPreviewURL', { params: data })

// 获取左边树
export const getCameraTree = (data) => http.get('/iot/cameraTree', data)

// 录像回放
export const getRreplayList = (data) => http.post('/replay/list', data)

//根据设备编码获取设备详细信息
export const getDevicemsg = (data) => http.get(`/iot/${data}`,)

// 时段水电明细统计
export const waterAndElectricity = (data) => http.post('/comprehensiveSituation/waterAndElectricity', data)
// 车辆进出入场统计
export const vehicleStatistics = (data) => http.post('/sysVehicleRecord/statistics', data)
// 人流量统计-按日统计，不含当日
export const traffic = (data) => http.post('/access/statistics', data)
// 设备统计
export const deviceStatistics = () => http.get('/device/info/statistics')


// 智慧人行 设备统计-门禁，道闸
export const threeStatistics = () => http.get('/device/info/threeStatistics')
//智慧人行 今日告警
export const pedestrianAlarm = () => http.get('/pedestrianAlarm')
//智慧人行 查询维修工单详情
export const repairOrder = () => http.post('/work/info/repairOrder')
//智慧人行 今日告警分页查看
export const pedestrianPage = (data) => http.post('/pedestrianAlarm/page',data)
//智慧人行 实时访客总览
export const visitorOverview = (data) => http.post('/pedestrianAlarm/visitorOverview',data)
//智慧人行 实时访客总览分页查询
export const visitorOverviewPage = (data) => http.post('/pedestrianAlarm/visitorOverviewPage',data)
//智慧人行 道闸通行趋势
export const gatePassage = () => http.post('/pedestrianAlarm/gatePassage')
//智慧人行 查询访问单位排行
export const visitingUnit = (data) => http.post('/pedestrianAlarm/visitingUnit',data)
//智慧人行 访客单位排行分页查询
export const visitingUnitPage = (data) => http.post('/pedestrianAlarm/visitingUnitPage',data)


//智慧楼宇
export const getWisdombuild = (data) => http.get('/wisdom/build', data)
export const getEquipmentAlarm = (data) => http.post('/wisdom/equipmentAlarm', data)
export const getSensors = (data) => http.post('/wisdom/sensors', data)

//智慧能源
export const getEnergy = () => http.get('/wisdom/getEnergy')
export const equipmentAlarm = (data) => http.post('/wisdom/equipmentAlarm', data)
export const getEnergyTrends = (data) => http.post('/wisdom/getEnergyTrends', data)
export const getEnergySpace = (data) => http.post('/wisdom/getEnergySpace', data)
export const getSpecificEnergy = (data) => http.post('/wisdom/getSpecificEnergy', data)


//智慧安防
export const getWisdomsecurity = (data) => http.get('/wisdom/security', data)
