<template>
  <div>
    <!-- 楼层列表 -->
    <el-aside v-if="buildingState"  v-loading="loading">
      <div class="top">
        <p>{{ buildingName }}</p>
        <div @click="changeBuilding"></div>
      </div>

      <ul class="building">
          <li
          class="building_btn"
          v-for="item in storeyArr"
          :key="item.text"
          :class="item.text == floor ? 'btn_click' : ''"
          @click="getBuildData(item)"
        >
            <div>{{item.text}}</div>
            <!-- <div>>></div> -->
          </li>
      </ul>
      <div class="bottom">
       <div class="bottom_btn">
          <button @click="changePage(-1)"></button>
          <button @click="changePage(1)"></button>
        </div>
      </div>
    </el-aside>
    <!-- 楼栋列表 -->    
    <el-aside v-else>
      <div
        class="top buildingState"
        v-for="item in buildingArr"
        @click="setStorey(item)"
      >
        <p>{{ item.text }}</p>
        <!-- <div class="StateIcon"></div> -->
      </div>
    </el-aside>
  </div>
</template>

<script>
import { getLightingbuildList,getLightingSystemList,getLightingSystemData } from '@/api'
export default {
  data(){
    return{
      storeyArr:[],
      buildingArr:[],//楼栋数据
      buildingName:'A塔',//当前楼栋名称
      buildingId:2,//当前楼栋id 默认A塔
      buildingState:true,
      PageData:{
        pageIndex: 1,//页码
        pageSize: 13,//每页条数
        pageCount:1,//总页数
     }, //分页数据
      loading: true,
      floor:'5楼'
    }
  },
  methods:{
    changeBuilding(){
      this.buildingState = false
    },
    // 设置楼栋
    async setStorey(i){
      this.loading = true
      this.PageData.pageIndex = 1
      this.buildingName = i.text
      this.buildingId = i.value
      await this.getPageSystemList()
      this.getBuildData(this.storeyArr[0]) //切换塔时设置到1楼
      this.buildingState = true
    },
    // 切换楼层数据
    async getBuildData(i) {
      this.floor = i.text
      // let {data:res} = await getSecuritySystemData({buildingId:this.buildingId,floorId:i.value})
      let currentFloor = i.buildingFloorList.filter(floor => {
        return i.floorId == floor.floorId
      })
      this.$store.commit('SET_buildingFloor', {buildingId:currentFloor[0].buildingId,
      floorId:currentFloor[0].floorId,
      buildingName:currentFloor[0].buildingName,
      floorName:currentFloor[0].floorName})
      this.$store.commit("SET_systemUrl", currentFloor[0].lightingSystemUrl + '&time' + new Date().getTime());
    },
    // 初始化
    async init(){
      let {data:res} = await getLightingbuildList()
      this.buildingArr = res
      await this.getPageSystemList()
      let item=this.storeyArr[0]
      this.$store.commit('SET_buildingFloor', {buildingId:item.buildingId,floorId:item.floorId,
      buildingName:'A塔',
      floorName:item.text})
    },
    // 获取楼栋分页数据
    async getPageSystemList(){
      this.PageData.buildingId = this.buildingId //添加楼栋id
      let {data:build} = await getLightingSystemList(this.PageData) //获取分页数据
      this.PageData.pageCount = build.total%build.pageSize == 0 ? build.total / build.pageSize : build.total / build.pageSize+1
      this.PageData.pageCount = Math.floor(this.PageData.pageCount) //获取总页码
      build.data.forEach(item => {
        let floor = item.buildingFloorList.filter(i => {
          return i.floorId == item.floorId
        })
        item.text = floor[0].floorName
      })
      this.storeyArr = build.data
      this.loading = false
    },
    // 切换楼层页码
    changePage(num){
      this.loading = true
      if(num > 0){ //大于0下一页 小于0上一页
        if(this.PageData.pageIndex < this.PageData.pageCount){ //小于总页码时
          this.PageData.pageIndex += num
          this.getPageSystemList()
        }else{
          this.loading = false
          this.$message('最后一页')
          }
      }else{
        if(this.PageData.pageIndex > 1){ //大于第一页时
          this.PageData.pageIndex += num
          this.getPageSystemList()
        }else{
          this.loading = false
          this.$message('没有上一页')
          }
      }
    },
  },
  created(){
    this.init()
  }
}
</script>

<style lang="scss" scoped>
// 全屏状态
:-webkit-full-screen .el-aside {
  height: 90vh !important;
  width: 260px !important;
  .top {
    p {
      color: #fff;
      font-size: 24px;
      line-height: 24px;
    }
    > div {
      background: url('~@/assets/gd/build_bg_btn.png') no-repeat;
      background-size: 100% 100%;
      width: 39px;
      height: 21px;
    }
    .StateIcon {
      background: url('~@/assets/gd/build_bg_btnup.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .buildingState {
    margin-bottom: 65px;
  }
  .building {
    margin-top: 28px;
    .building_btn {
      width: 150px;
      height: 42px;
      padding: 0 30px;
      margin-bottom: 15px;
    }
  }
  .bottom {
    margin-top: 20px;
    padding: 0 30px;
    .bottom_btn {
      button {
        height: 32px;
        width: 15px;
      }
    }
  }
}
.el-aside {
  width: 234px !important;
  height: 78vh;
  margin: 18px 12px;
  background: url('~@/assets/gd/gd_windowbg.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 35px 20px 0 20px;
  .top {
    background: url('~@/assets/gd/build_bg.png') no-repeat;
    background-size: 100% 100%;
    width: 166px;
    height: 61px;
    box-sizing: border-box;
    padding-left: 65px;
    padding-right: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: #fff;
      font-size: 24px;
      line-height: 24px;
    }
    > div {
      background: url('~@/assets/gd/build_bg_btn.png') no-repeat;
      background-size: 100% 100%;
      width: 39px;
      height: 21px;
    }
    .StateIcon {
      background: url('~@/assets/gd/build_bg_btnup.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .buildingState {
    margin-bottom: 40px;
  }
  .buildingState:hover {
    cursor: pointer !important;
  }
  .building {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    .building_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 136px;
      height: 32px;
      background: url('~@/assets/gd/gd_btn.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0 25px;
      margin: 0 auto;
      margin-bottom: 10px;
      div {
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
      }
    }
    .building_btn:hover {
      cursor: pointer !important;
      background: url('~@/assets/gd/gd_btnx.png') no-repeat;
      background-size: 100% 100%;
    }

    .btn_click{
      background: url('~@/assets/gd/gd_btnx.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bottom_btn {
      width: 100%;
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
      button {
        border: none;
        height: 32px;
        width: 15px;
      }
      button:first-child {
        background: url('~@/assets/gd/bottom_btnL.png') no-repeat;
        background-size: 100% 100%;
      }
      button:last-child {
        background: url('~@/assets/gd/bottom_btnR.png') no-repeat;
        background-size: 100% 100%;
      }
      button:hover {
        cursor: pointer !important;
      }
    }
  }
  
}
.buttonList {
  display: flex;
  justify-content: space-between;
}
.el-icon-delete,
.el-icon-edit:hover {
  cursor: pointer;
}
.el-icon-delete,
.el-icon-edit {
  color: #409eff;
  margin: 0 5px;
}
</style>