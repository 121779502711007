<template>
  <div class="edit">
    <el-dialog v-dialogDrag :close-on-click-modal="false" title="选设备" :visible.sync="show" width="60%" @close="close" :before-close="close">
      <ja-table ref="tst" v-model="filterData" :conditionData="conditionData" :columns="columns" :tableData="tableData" :total="total" :loading="loading" @append="append" @getInit="getInit" @search="search" @rowDelete="rowDelete" :showSelect="true" @handleSelectionChange="handleSelectionChange" @reset="reset">
        <template v-slot:right>
          <el-button @click="addDevice" type="primary" size="small" plain>添加</el-button>
        </template>
      </ja-table>
    </el-dialog>
  </div>
</template>

<script>
import JaForm from '@/components/ja-form'
import table from '@/views/mixins/table'
import { triggerDeviceList,linkDeviceList} from '@/api/module/alarm.js'
export default {
  mixins: [table],
  components: {
    JaForm
  },
  props: {
    httpType:{// true:触发设备,false:联动设备
      type:Boolean,
      default:()=>true
    },
    show: {
      type: Boolean,
      default: false
    },
    linkageTypeId: {
      type:String,
      default:()=>''
    },
    configId: {
      type:String,
      default:()=>''
    },
  },
  data() {
    return {
      filterData: {
        pageIndex: 1,
        pageSize: 10,
        deviceName:""
      },
      conditionData: [
        {
          input_type: 'input',
          key: 'deviceName',
          styleWidth: '200px',

          attribute: {
            placeholder: '设备名称',
            size: 'small'
          }
        },
        {
          text: '查询',
          input_type: 'button',
          method: 'search',
          size: 'small',
          attribute: {
            type: 'primary',
            icon: 'el-icon-search'
          }
        },
        {
          text: '重置',
          input_type: 'button',
          method: 'reset',
          size: 'small',
          attribute: {
            type: 'default'
          }
        }
      ],
      columns: [
        // 表头数据
        {
          label: '设备名称',
          prop: 'deviceName',
          width: 200,
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '设备小类',
          prop: 'subcategoryName',
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '所在楼栋',
          prop: 'buildingName',
          width: 200,
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '所在楼层',
          prop: 'floorName',
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
        {
          label: '所在位置',
          prop: 'installLocation',
          width: 200,
          attribute: { showOverflowTooltip: true, align: 'center' }
        },
      ],
      tableData: [],
      loading: false,
      total: 0,
      deviceList: []
    }
  },

  watch:{
    // httpType:{
    //   handler(val){
    //     this.getInit()
    //   }
    // },
    show:{
      handler(showVal){
        console.log("showVal ",showVal);
        this.filterData.deviceName=''
        this.getInit()
      }
    }
  },
  
  methods: {
    /** 关闭弹出框 */
    close() {
      this.$emit('closeDeviceList')
    },
    // 初始化
    getInit() {
      this.loading = true
      if(this.linkageTypeId){
        this.filterData.linkageTypeId = this.linkageTypeId
        this.filterData.configId = this.configId
        const httpMeth = this.httpType?triggerDeviceList:linkDeviceList
        httpMeth(this.filterData).then((res) => {
          if (res.code === 200) {
            this.tableData = res.data.data
            this.total = res.data.total
            this.loading = false
          }
        })
      }
    },
    // 多选
    handleSelectionChange(val) {
      this.deviceList = val
    },
    // 点击添加设备
    addDevice() {
      const deviceList = this.deviceList.map(({id,deviceName,subcategoryName,buildingName,floorName})=>{
        return {httpType:this.httpType,deviceId:id,deviceName,deviceSubcategoryName:subcategoryName,deviceBuildingName:buildingName,deviceFloorName:floorName}
      })
      this.$emit('addDevice', deviceList)
    },
  }
}
</script>

<style scoped>
</style>
