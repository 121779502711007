<template>
    <div>
        <el-dialog :title="'查看'" :visible.sync="show" width="40%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgb(36 33 33 / 90%)">
            <ja-form v-if="show" :noButton="true" :rules="EditRules" v-model="rowFormData" :formArray="EditrowForm" @reset="close" @deleteEquipment="deleteEquipment" labelWidth="120px">
            </ja-form>
        </el-dialog>
    </div>
</template>
<script>
import JaForm from '@/components/ja-form'
export default {
    name: '查看组',
    inject: ['getInit',],
    components: {
        JaForm,
    },
    props: {
        maxOrder: {
            type: Number,
            default: 1
        }
    },
    data () {
        return {
            EditrowForm: [
                {
                    input_type: 'input',
                    col: 13,
                    label: '组名',
                    key: 'groupName',
                    width: '50px',
                    disabled:'true',
                },
                {
                    input_type: 'table', col: '20', label: '设备列表', noSearch: true, showPage: false, key: 'deviceOrderList', isRequired: true, border: true, width: '150px',
                    tableAttribute: { showSummary: true, showIndex: false },
                    columns: [
                        { label: '设备名称', prop: 'deviceName' },
                    ]
                },
            ],
            rowFormData: {},
            EditRules: {
                groupName: [
                    { required: true, message: '组名不能为空', trigger: 'blur' }
                ],
                deviceOrderList: [
                    { required: true, message: '设备不能为空', trigger: 'blur' }
                ],
            },
            isAdd: true,
            show: false,
            loading: false,
        }
    },
    methods: {
        init (row) {
            this.show = true
            this.loading = false
            this.EditrowForm[1].max = this.maxOrder - 1
            this.EditrowForm[1].min = 1
            //重构数据
            if (row) {
                let obj = { groupName: row.groupName, id: row.id, deviceOrderList: row.deviceOrderList ? row.deviceOrderList : [] } //order: row.orderNum,
                this.rowFormData = obj
            }
        },
        //取消
        close () {
            this.show = false
            this.rowFormData = {}
        },
    }
}
</script>