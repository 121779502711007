<template>
  <div id="app"
       v-loading="loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <router-view :loading="loading"
                 @loading="onloading" />
  </div>
</template>
<script>
export default {
  data () {
    return {
      loading: true
    }
  },
  created () {
    if (window.top === window) {
      // 当前页面是顶层窗口
      let iocUrl = this.$store.state.permission.iocUrl
      const state = localStorage.getItem('state')
      if (state) {
        this.$store.replaceState({ ...this.$store.state, ...JSON.parse(state) })
      }
      console.log('ioc',iocUrl);
      this.$store.commit('SET_IOCURL', iocUrl)
    } else {
      // 当前页面在一个iframe中
      //获取路由链接
      let iocUrl = this.$store.state.permission.iocUrl
      const state = localStorage.getItem('state')
      // window.addEventListener('message', e => {
      //   if (e.origin.indexOf('http://121.37.251.55:8280') != -1 && e.data.type == 'settoken') {
      //     this.$store.commit('setToken', JSON.parse(e.data.token))
      //     console.log(e.data);
      //     localStorage.setItem('token', e.data.token);
      //   }
      // }, false)
      if (state) {
        this.$store.replaceState({ ...this.$store.state, ...JSON.parse(state) })
      }
      this.$store.commit('SET_IOCURL', iocUrl)
    }
    
  },
  watch: {
    loading () { // val
      // console.log(val)
    },
    '$store.state': {
      deep: true,
      handler (val) {
        val.permission.iocUrl = '' //不存综合态势地址到内存
        localStorage.setItem('state', JSON.stringify(val))
      }
    }
  },
  methods: {
    onloading () {
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
@import '~@/style/mixin.scss';
#app {
  height: 100vh;
  width: 100vw;
  // min-width: 1400px;
  // min-width: 1200px;
  color: $font-color;
}
.wrap {
  > .allwh {
    // margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
</style>
