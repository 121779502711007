<template>
    <!-- <el-main> -->
        <!-- <div class="header">
            <el-switch v-model="switchValue" @change="setSwitchValue" inactive-text="启停巡更" v-if="isAuth('videoTour::leftList:startStopPatrol')"></el-switch>
        </div> -->
        <div class="box">

            <div id="player">
                <div class="underground">
                    <iframe class="scene" v-if="iframeShow" :src="url"></iframe>
                    <!-- <div class="stop stopb">
                        <div :class="btn_b ? 'stopimg' : 'stopimgclose'"><img :src="btn_b? caropen : carclose"></div>
                        <p>北侧停车场出口</p>
                        <el-switch
                            v-model="btn_b"
                            active-color="#3AA1FF"
                            inactive-color="#aaa">
                        </el-switch>
                    </div>
                    <div class="stop stopdb">
                        <div :class="btn_db ? 'stopimg' : 'stopimgclose'"><img :src="btn_db? caropen : carclose"></div>
                        <p>东北侧停车场出口</p>
                        <el-switch
                            v-model="btn_db"
                            active-color="#3AA1FF"
                            inactive-color="#aaa">
                        </el-switch>
                    </div>
                    <div class="stop stopn">
                        <div :class="btn_n ? 'stopimg' : 'stopimgclose'"><img :src="btn_n? caropen : carclose"></div>
                        <p>南侧停车场出口</p>
                        <el-switch
                            v-model="btn_n"
                            active-color="#3AA1FF"
                            inactive-color="#aaa">
                        </el-switch>
                    </div>
                    <div class="stop stopdn revolve">
                        <div :class="btn_dn ? 'stopimg' : 'stopimgclose'"><img :src="btn_dn? caropen : carclose"></div>
                        <p>西侧停车场出口</p>
                        <el-switch
                            v-model="btn_dn"
                            active-color="#3AA1FF"
                            inactive-color="#aaa">
                        </el-switch>
                    </div> -->
                </div>
                
            </div>

             <div class="systembtn">
                <div v-for="item in systemArr" :class="item.style" :key="item.text" @click="jump(item)">
                    <span>{{item.text}}</span>
                </div>
            </div>
            <div class="bottom"></div>
        </div>
    <!-- </el-main> -->
</template>
<script>
const IS_MOVE_DEVICE = document.body.clientWidth < 992
import caropen from '@/assets/gd/stopcar_open.png'
import carclose from '@/assets/gd/stopcar_close.png'
export default {
    data () {
        return {
            btn_b:false,
            btn_db:false,
            btn_n:true,
            btn_dn:false,
             systemArr:[
                {text:'安防系统',url:'/home/visualManagement/securitySystem',style:'two'},
                {text:'能源系统',url:'/home/visualManagement/powersupplySystem',style:'two'},
                {text:'暖通系统',url:'/home/visualManagement/conditioningSystem',style:'two '},
                {text:'照明系统',url:'/home/visualManagement/lghtingSystem',style:'two'},
                {text:'停车系统',url:'/home/visualManagement/parkingSystem',style:'two clickfloor'},
                {text:'电梯系统',url:'',style:'two'},
                {text:'给排水系统',url:'/home/visualManagement/waterConservancySystem',style:''},
            ],
            caropen,
            carclose,
            url:'http://121.37.251.55:8280/#/?model=广电地下',
            iframeShow: true,  
        }
    },
    mounted () {
       
    },
    methods: {
      jump(i){
        this.$router.push({path:`${i.url}`})
      }
    },
}
</script>
<style lang="scss" scoped>
// 全屏状态
:-webkit-full-screen .box{
    height: 100vh;
    .system_btn{
        height: 147px;
        .btnbox{
            padding: 45px 100px;
            button{
                width: 160px;
                height: 60px;
                font-size: 26px;
                line-height: 26px;
            }
        }
    }
    #player{
        background: #000;
        height: 100vh;
        width:1918px;
        padding-left: 300px;
        padding-bottom: 147px;
        iframe {
        width: 1918px;
        height:100vh;
        transform: scale(1); /* 100 / 1920 = 0.0521 */
        top: 0;
        left: 0;
        }
    }
    .stopb{left: 40%;top: 5%;}
    .stopn{left: 46%;bottom: 12%;}
    .stopdb{right: 27%;top: 5%;}
    .stopdn{left: 31%;top: 33%;}
}
.box{
    height: 100%;
    width: 100%;
    // .system_btn{
    //     position: absolute;
    //     height: 80px;
    //     width: 100%;
    //     box-sizing: border-box;
    //     padding-left: 250px;
    //     bottom: 0;
    //     right: 0;
    //     background-color: #060E39;
    //     .btnbox{
    //         display: flex;
    //         justify-content: space-around;
    //         align-items: center;
    //         padding: 15px 80px;
    //         button{
    //             width: 133px;
    //             height: 50px;
    //             font-size: 22px;
    //             line-height: 22px;
    //             color: #fff;
    //             background: url('~@/assets/gd/system_btn.png') no-repeat;
    //             background-size: 100% 100%;
    //             border: none;
    //         }
    //         button:hover{
    //             cursor: pointer!important;
    //         }
    //     }
    // }
          .systembtn {
    width: 52%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-52%, 0);
    height: 90px;
    display: flex;
    justify-content: space-around;
    .clickfloor{
      background: url('~@/assets/gd/ds_systembtnx.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    > div {
      position: relative;
      width: 90px;
      height: 90px;
      background: url('~@/assets/gd/ds_systembtn.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      span {
        display: inline-block;
        position: absolute;
        width: 60px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.86px;
        text-shadow: 0 1px 1px #002d59;
        font-weight: 700;
      }
    }
    .two{
        span{
           width: 45px;
        }
      }
    > div:hover {
      background: url('~@/assets/gd/ds_systembtnx.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .bottom {
    height: 55px;
    width:100%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 1;
  }
}
@media screen and (max-width: 991px) {
    #player {
        width: 90%;
        height: 98%;
    }
}
@media screen and (max-width: 1440px) {
    #player {
        background-color: #000;
         .scene {
            width: 1918px;
            height: 929px;
            transform: scale(0.8);
            left: -250px;
        }
    }
    :-webkit-full-screen #player{
            background: #000;
            height: 100vh;
            width:1918px;
            .scene {
            width: 1918px;
            height: 929px;
            transform: scale(0.9)!important; /* 100 / 1920 = 0.0521 */
            top: 0!important;
            left: -8%!important;
            }
    }
}
#player{
    min-height: 400px;
    height: 83vh;
    background: #fff;
    position: relative;
    overflow: hidden;
    iframe {
    width: 1918px;
    height: 929px;
    transform: scale(0.9); /* 100 / 1920 = 0.0521 */
    // transform-origin: top left;
    position: absolute;
    top: -100px;
    left: -100px;
    }
}
.stop{
    height: 230px;
    box-sizing: border-box;
    width: 195px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    color: #fff;
    p{
        margin: 10px 0;
        font-size: 22px;
        text-align: center;
        color: #3AA1FF;
    }
    .stopimg{
        width: 195px;
        height: 131px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .stopimgclose{
        width: 195px;
        height: 131px;;
        img{
            margin-top: 61px;
            width: 195px;
            height: 70px;
        }
    }
}
.revolve{
    transform: rotate(-70deg);
}
.stopb{left: 49%;top: -3%;}
.stopn{left: 55%;bottom: 6%;}
.stopdb{right: 15%;top: -3%;}
.stopdn{left: 30%;top: 28%;}
</style>