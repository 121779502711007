<template>
  <div class="real-time">
    <div class="camera-position">
      <el-tree
        class="tree-wrapper"
        node-key="id"
        :default-expanded-keys="[2, 3]"
        :indent="30"
        :default-checked-keys="[5]"
        :data="treeData"
        :props="defaultProps"
        ref="tree"
        @node-click="nodeClick"
      >
      </el-tree>
    </div>
    <div class="picture-preview">
      <div class="preview-title">画面预览</div>
      <div class="camera-items">
        <div class="camera-item">
          <!-- <img src="/images/u728.png" /> -->
          <!-- <video
            id="myVideo"
            class="video-js"
            style="width: 100%;height: 100%;"
          >
            <source src="/images/3dgs.mp4" type="video/mp4" />
          </video> -->
          <div id="player"></div>
        </div>
        <div class="video-query">
          <div class="query-title">录像查询</div>
          <div class="query-time">
            <div>查询时间：</div>
            <div>
              <el-date-picker
                v-model="dateTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="changeDateTime"
                end-placeholder="结束日期"
                :picker-options="expireTimeOption"
                :default-time="default_time"
              >
              </el-date-picker>
            </div>
            <div
              style="border: 1px solid #2ba89c; padding: 8px"
              @click="getVideo"
              v-if="isAuth('comprehensiveSecurity::videoPlayback::video')"
            >
              查询
            </div>
          </div>
          <div class="query-time">
            <div>录像来源：</div>
            <el-select
              v-model="recordData.recordSource"
              placeholder="请选择"
              size="medium"
            >
              <el-option
                v-for="item in recordSource"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div>码流类型：</div>
            <el-select
              v-model="recordData.streamType"
              placeholder="请选择"
              size="medium"
            >
              <el-option
                v-for="item in streamType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCameraTree, getRreplayList, getHisPreviewURL } from '@/api'
import table from '@/views/mixins/table'
const IS_MOVE_DEVICE = document.body.clientWidth < 992
import PlayerManager from '@/api/module/icc/PlayerManager'
export default {
  mixins: [table],
  data() {
    return {
      recordSource: [
        {
          value: '1',
          label: '存储在服务器上的录像'
        },
        {
          value: '2',
          label: '存储在录像机上的录像'
        }
      ],
      streamType: [
        {
          value: '0',
          label: '所有码流'
        },
        {
          value: '1',
          label: '主码流'
        },
        {
          value: '2',
          label: '辅码流1'
        },
        {
          value: '3',
          label: '辅码流2'
        }
      ],
      recordData: {
        recordSource: '2', //录像来源
        streamType: '1' //码流类型
      },
      treeData: [],
      parkValue: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dateTime: '',
      player: null,
      deviceId: '',
      default_time: ['00:00:00'],
      timeOptionRange: '',
      expireTimeOption: {
        disabledDate: (time) => {
          const date = new Date(Date.now())
          return time > date || time < new Date(date - 7 * 24 * 60 * 60 * 1000)
        }
      },
      isPlay: false
    }
  },
  created() {
    this.getTree()
  },
  mounted() {
    this.createPlayer()
  },
  methods: {
    /**
     * 初始化播放器
     * */
    createPlayer() {
      this.player = new PlayerManager({
        el: 'player' /** 实时预览容器id，创建多个播放器，传入不同的容器id即可 **/,
        pztEl: 'ws-pan-tilt', // 云台控制容器id
        type: 'record' /** real - 实时预览  record - 录像回放 **/,
        maxNum: 1 /** 最多显示的路数，可根据实际情况设置，可设置1 4 9 16 25等5种路数 **/,
        num: 1 /** 初始化显示的路数 **/,
        showControl: true /** 默认是否显示工具栏 **/,
        streamServerIp: process.env.VUE_APP_IP, // 流媒体服务 Ip 地址
        useNginxProxy: false // 拉流模式 【true-代理模式拉流，false-直连拉流】
      })
    },
    getTree() {
      // 查询录像回放数据
      getCameraTree().then((res) => {
        const data = res.data
        this.treeData = data
      })
    },
    changePark(index) {
      this.parkValue = index
    },
    changeDateTime(time) {
      console.log(time, '选中时间')
    },
    /**
     * 点设备树
     **/
    nodeClick(data, node) {
      if (data.code != null) {
        this.deviceId = data.deviceNumber
        this.getHisPreviewURL()
      }
    },
    /**
     * 请求回放录像视频
     * */
    getHisPreviewURL() {
      if (this.deviceId == '') {
        this.$message.warning('请选择设备！')
        return false
      } else if (this.dateTime.length == 0) {
        this.$message.warning('请选择录像回放时间！')
        return false
      };
      this.recordData.startTime = moment.unix(new Date(this.dateTime[0])).format('X') / 1000,
      this.recordData.endTime = moment.unix(new Date(this.dateTime[1])).format('X') / 1000,
        //录像参数
        this.player.playRecordVideo({
          channelList: [
            {
              id: this.deviceId,
              isOnline: 1,
            }
          ],
          ...this.recordData,
        })
    },
    /**点击播放视频按钮 */
    getVideo() {
      this.getHisPreviewURL()
    }
    /**全屏 */
    // wholeFullScreen() {
    //   this.player.JS_FullScreenSingle(0).then(
    //     () => {
    //       console.log(`wholeFullScreen success`)
    //     },
    //     (e) => {
    //       console.error(e)
    //     }
    //   )
    // }
  }
}
</script>

<style scoped lang="scss">
@import '~@/style/mixin.scss';
.real-time {
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  display: flex;
  .camera-position {
    width: 300px !important;
    position: relative;
    margin: 0 10px;
    height: 87vh;
    .el-select {
      width: 100%;
      margin-bottom: 20px;
      ::v-deep .el-input__inner {
        height: 4.5vh;

        ::v-deep .el-input__icon {
          height: 37px;
        }
      }
    }
    .tree-wrapper {
      box-sizing: border-box;
      width: 95%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      height: 100%;
      padding: 10px 10px;
      background: #fff;
      /deep/ .el-tree-node__children .el-tree-node__content{
        padding-left: 15px!important;
      }
    }
    .tree-wrapper::-webkit-scrollbar {
      width: 7px;
    }
    .tree-wrapper::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(7, 150, 126, 0.2);
    }
    .tree-wrapper::-webkit-scrollbar-track {
      border-radius: 0;
      background: rgba(21, 31, 15, 0.1);
    }
  }
  .picture-preview {
    width: 80%;
    margin: 0px 16px;
    .preview-title {
      height: 5%;
      background: $theme-color;
      box-shadow: 8px 7px 20px #e4edf7;
      opacity: 1;
      border-radius: 10px;
      line-height: 45px;
      font-size: 20px;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: $font-color;
      padding-left: 20px;
    }
    .camera-items {
      height: 90%;
      .camera-item {
        width: 100%;
        height: 80%;
        opacity: 1;
        margin-top: 20px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
        ::v-deep .vjs-big-play-button {
          left: 40%;
          top: 50%;
        }
      }
      .video-query {
        height: 23%;
        background-color: $theme-color;
        cursor: pointer;
        .query-title {
          color: black;
          padding: 30px 0px 20px 30px;
        }
        .query-time {
          display: flex;
          padding: 0px 30px;
          align-items: center;
          justify-content: space-between;
          color: #929292;
          margin-bottom: 20px;

          ::v-deep .el-range__icon {
            line-height: 1.8;
          }
          ::v-deep .el-range-separator {
            line-height: 1.5;
          }
        }
      }
    }
  }
  .playback-record {
    width: 34%;
    height: 100%;
    .playback-title {
      height: 5%;
      background: $theme-color;
      box-shadow: 8px 7px 20px #e4edf7;
      opacity: 1;
      border-radius: 10px;
      line-height: 45px;
      font-size: 20px;
      font-family: Alibaba PuHuiTi;
      font-weight: 400;
      color: $font-color;
      padding-left: 20px;
    }
  }
}

// 只叶子可勾选
::v-deep .el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  #player {
    width: 100%;
    height: 98%;
  }
}
@media screen and (min-width: 992px) {
  #player {
    width: 100%;
    height: 98%;
  }
}
</style>
