<template>
  <div class="dswindow">
    <div class="aircondition">
      <p>环境参数</p>
      <div>
        <div>湿度(%):<span>30.4</span></div>
        <div>温度(°C):<span>21</span></div>
        <div>冷量(w/㎡):<span>263</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$emit('loading')
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;
  .aircondition {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url('~@/assets/images/gd/waterpump_bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 22px;
    color: #fff;
    p {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }
    > div > div {
      display: flex;
      box-sizing: border-box;
      padding: 0 17px;
      font-size: 18px;
      margin-bottom: 20px;
    }
    span {
      color: #3aa1ff;
      margin-left: 5px;
    }
  }
}
</style>