<template>
  <div class="rightchart entry">
    <chartone class="flow" />
    <chart class="flow" />
    <temperature class="flow" />
  </div>
</template>

<script>
import chart from './chart'
import temperature from './temperature'
import chartone from './chart/chartone.vue'
export default {
  components: {
    chart,
    temperature,
    chartone
  }
}
</script>

<style lang="scss" scoped>
.rightchart {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px;
  .flow {
    height: 33%;
    width: 100%;
    flex: 1;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .databox {
    width: 100%;
    height: 33%;
    display: flex;
    align-items: center;
    > div {
      background: url('~@/assets/gd/ds_bulletinbg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 368px;
      height: 141px;
      margin: 0 auto;
      padding:10px 25px;
      .title{
        width: 100%;
        color: #fff;
        font-size: 21px;
      }
      .mid{
        width: 256px;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        color: #37EAFF;
        font-size: 15px;
        .m_left{
          p:last-child{
            margin-top: 13px;
            text-align: center;
            font-size: 24px;
            color: #FF3514;
            font-weight: 700;
          }
        }
        .m_right{
          p:last-child{
            margin-top: 20px;
          }
          span{
            font-weight: 700;
            font-size: 16px;
            color: #FFCE14;
          }
        }
      }
    }
  }
}
</style>