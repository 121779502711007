<template>
  <div>
    <!-- 详情弹窗 -->
    <el-dialog :visible.sync="showDetail" title="申请详情" :width="`45%`">
      <ja-detail :options="detailOptions" :detailData="detailData" :label_width='10' v-if="showDetail">
        <template #qpcode="">
          <div class="BigBox">
            <img :src="detailData.snapshotImg" alt="" srcset="">
          </div>
        </template>
      </ja-detail>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      showDetail: false,
      detailOptions: [
        { key: 'trafficId', label: '通行ID', },
        { key: 'name', label: '人员姓名', },
        { key: 'gender', label: '性别', },
        { key: 'personType', label: '人员类型', },
        { key: 'companyName', label: '所属公司', },
        { key: 'phone', label: '手机号码', },
        { key: 'personId', label: '员工工号', },
        { key: 'temperature', label: '体温检测', },
        { key: 'mask', label: '是否戴口罩', },
        { key: 'equipmentId', label: '设备ID', },
        { key: 'equipmentName', label: '设备名称', },
        { key: 'accessType', label: '通行类型', },
        { key: 'accessEvents', label: '通行事件', },
        { key: 'accessTime', label: '通行时间', },
        { label: '照片', type: 'customize', customize: 'qpcode', col: 22, alignItems: 'flex-start' },

      ],
      detailData: {},
      initFileList: [],
      imgText:''
    }
  },
  methods: {
    rowDetail(row) {
      // console.log('查看详情---',row,)
      this.detailData = { ...row, brandCode: `${row.brand}${row.model}` }
      this.showDetail = true
    },
  }
}
</script>
<style lang="scss" scoped>
.BigBox {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  .right {
    margin-left: 20px;
  }
  img {
    height: 120px;
    display: flex;
  }
}
</style>